/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import Spinner from "../../../SharedModules/Spinner/Spinner";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import TableComponent from "../../../SharedModules/Table/Table";
import Footer from "../../../SharedModules/Layout/footer";
import { searchCMS64Action, getDropdownDataAction, RESET_CMS64 } from "../Store/Actions/CMS64Actions";
import * as CMS64Constants from "./CMS64Constants";

function EMARSearch(props) {
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [CMS64State, setCMS64State] = useState({
    CMSFormCd: "",
    CMSColCd: "",
  });
  const [emarTable, setEmarTable] = useState([]);
  const searchEmar = values => dispatch(searchCMS64Action(values));
  const getDropdownData = dropdownValues => dispatch(getDropdownDataAction(dropdownValues));

  let searchSuccess = useSelector(state => state.cms64State.searchPayload);
  const dropDownData = useSelector(state => state.cms64State.dropdownData);
  useEffect(() => {
    RESET_CMS64('searchPayload');
    searchSuccess = null;
  }, []);
  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };

  useEffect(() => {
    setspinnerLoader(false);
    if (searchSuccess && !searchSuccess.systemFailure) {
      seterrorMessages([]);
    }
    if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length > 1) {
      setShowTable(true);
      let tempSearchData = [];
      setEmarTable(searchSuccess);
    } else if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 1) {
      let searchData = searchSuccess;
      props.history.push({
        pathname: "/CMS64Update",
        state: { searchData: searchData, successMessages: [], editPage: true, fromSearch: true }
      });
    } else if (searchSuccess && searchSuccess.systemFailure) {
      let ErrorMessages = [];
      ErrorMessages.push(CMS64Constants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(ErrorMessages);
    } else if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 0) {
      let ErrorMessages = [];
      ErrorMessages.push(CMS64Constants.NO_RECORDS_FOUND);
      seterrorMessages(ErrorMessages);
      setEmarTable([]);
      setShowTable(false);
    }
  }, [searchSuccess]);
  const handleChange = name => event => {
    setCMS64State({
      ...CMS64State,
      [name]: event.target.value
    });
  };
  const EMARSearchAction = () => {
    setspinnerLoader(true);

    let searchEmarOb = {
      cms64FormCd: CMS64State.CMSFormCd,
      cms64ColCd: CMS64State.CMSColCd,
    };
    searchEmar(searchEmarOb);
  };
  const EMARReset = () => {
    setCMS64State({
      CMSFormCd: "",
      CMSColCd: "",
    });
    setEmarTable([]);
    setShowTable(false);
    seterrorMessages([]);
  };

  const headCells = [
    {
      id: "cms64FormCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: true,
      label: "CMS Form Cd",
      isDate: false,
      width: "15%"
    },
    {
      id: "cms64ColCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "CMS Column Cd",
      width: "15%"
    },
    {
      id: "begFundTyCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Begin Fund Type Code",
      isDate: false,
      width: "15%"
    },
    {
      id: "begFundSrcCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Begin Fund Source Code",
      isDate: false,
      width: "15%"
    },
    {
      id: "begMbrBpId",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Begin Benefit Plan ID",
      isDate: false,
      width: "15%"
    },
    {
      id: "begMbrCoeCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Begin Category of Eligibility Code",
      isDate: false,
      width: "15%"
    },
    {
      id: "fp_Ind",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Family Planning Indicator",
      isDate: false,
      width: "10%"
    }
  ];
  const formatSearchCriteria = row => {
    return {};
  };
  const editRow = row => event => {
    setspinnerLoader(true);
    let searchEmarOb = {
      cms64SK: row.cms64SeqNo
    };
    searchEmar(searchEmarOb);
  };

  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <h1 className="tab-heading float-left">Search CMS64</h1>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body" style={{ overflow: "hidden" }}>
          <form autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="CMSFormCd"
                  label="CMS Form Cd"
                  value={CMS64State.CMSFormCd}
                  onChange={handleChange("CMSFormCd")}
                  inputProps={{ maxlength: 15 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                /*  helperText={showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null}
                error={showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null} */
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="CMSColCd"
                  label="CMS Column Cd"
                  value={CMS64State.CMSColCd}
                  onChange={handleChange("CMSColCd")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
            </div>
          </form>

          <div className="float-right mr-4 mb-3">
            <Button
              variant="outlined"
              color="primary"
              className="btn btn-primary ml-1"
              onClick={() => {
                EMARSearchAction();
              }}
            >
              <i class="fa fa-search" aria-hidden="true"></i>
              Search
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="bt-reset btn-transparent ml-1"
              onClick={() => {
                EMARReset();
              }}
            >
              <i class="fa fa-undo" aria-hidden="true"></i> Reset
            </Button>
          </div>

          <div className="clearfix"></div>

          {showTable ? (
            <div className="tab-holder pb-1 tableNoWrap">
              <TableComponent
                print
                fixedTable
                headCells={headCells}
                formatSearchCriteria={formatSearchCriteria}
                tableData={emarTable}
                isSearch={true}
                onTableRowClick={editRow}
                defaultSortColumn={headCells[1].id}
                order={"asc"}
              />
            </div>
          ) : null}
          <Footer print />
        </div>
      </div>
    </div>
  );
}

export default withRouter(EMARSearch);
