/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from 'react';
import Bootstrap, { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import AdjustmentVerification from './AdjustmentVerification';
import { LinkOfFcn, LinkOfTcn } from '../../../../SharedModules/TabRedirections/customInputLink';
import { redirectReceiptFCN } from '../../../../SharedModules/TabRedirections/TabRedirections';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import numeral from 'numeral';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import { TRANSACTION_NUMBER_FORMAT } from '../../../../SharedModules/Errors/ErrorMsgConstant';

export default function Adjusmentaction(props) { 
  const disableField = true;

  // Field change handler
  const handleChanges = event => {
    props.handleValidation();
  };

   return (
    <div className="tab-holder mb-3">
      <fieldset className="r-custom-fieldset no-padding mb-2">
        <legend>Adjustment</legend>
        <form autoComplete="off">
          <div className="form-wrapper">
            <div className={ props.linkEnable ? "mui-custom-form override-width-22 override-m-1 has-fcn-link" : "mui-custom-form override-width-22 override-m-1" }>
              <TextField
                id="fcn-code"
                fullWidth
                label="FCN"
                InputProps={ {
                  readOnly: true, className: 'Mui-disabled', inputComponent: LinkOfFcn,
                  inputProps: { value: props.values.adjustedFcn, redirection: redirectReceiptFCN, saveFlag: props.linkEnable }
                } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
              >
              </TextField>
            </div>
              <NativeDropDown
                id="lob_A"
                className='override-width-22 override-m-1'
                label="LOB"
                inputProps={ { maxlength: 3 } }
                value={ props.values.adjustedLob }
                disabled={ disableField }
                InputLabelProps={ {
                  shrink: true
                } }
                dropdownList={ props.lobCode
                  ? props.lobCode.map((item, index) => (
                    <option key={ index } value={ item.code }>
                      { item.description }
                    </option>
                  ))
                  : null }
              />
            <div className="mui-custom-form with-date override-width-22 override-m-1">
              <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                <KeyboardDatePicker
                  maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                  formnovalidate
                  id="begin_date"
                  label="Established Date"
                  format="MM/dd/yyyy"
                  placeholder="mm/dd/yyyy"
                  InputLabelProps={ {
                    shrink: true
                  } }
                  name="begin_date"
                  value={ props.values.adjustedEstablisedDate }
                  required
                  KeyboardButtonProps={ {
                    'aria-label': 'change date'
                  } }
                  disabled={ disableField }
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className="mui-custom-form override-width-22 override-m-1 with-icon with-icon-margin">
              <TextField
                id="original-ar"
                fullWidth
                required
                label="New Original Amount"
                inputProps={ { maxlength: 12 } }
                value={ props.values.newOriginalAmount }
                onChange={ props.handleChange('newOriginalAmount') }
                InputProps={ {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  )
                } }
                InputLabelProps={ {
                  shrink: true
                } }
                onInput={ e => {
                  e.target.value = e.target.value.replace(
                    /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                    ''
                  );
                } }
                onBlur={ (e) => { props.setValues({ ...props.values, newOriginalAmount: numeral(e.target.value).format('0,0.00') }) } }
                onFocus={ () => { props.setValues({ ...props.values, newOriginalAmount: numeral(props.values.newOriginalAmount).format('0.00') }) } }
                helperText={ props.errors[0] ? props.errors[0] : null }
                error={ props.errors[0] ? props.errors[0] : null }
              ></TextField>
            </div>
          </div>
          <div className="form-wrapper">
              <NativeDropDown
                id="select-reasoncode"
                className='override-m-1 override-width-22'
                isRequired
                label="Reason Code"
                value={ props.values.adjustmentReasonCode }
                inputProps={ { maxLength: 3 } }
                onChange={ props.handleChange('adjustmentReasonCode') }
                InputLabelProps={ {
                  shrink: true
                } }
                errTxt={ props.errors[1] ? props.errors[1] : null }
                dropdownList={ props.reasonCodeDropDown
                  ? props.reasonCodeDropDown.map((item, index) => (
                    <option key={ index } value={ item.code } title={ item.longDescription }>
                      { item.description }
                    </option>
                  ))
                  : null }
              />
            <div className="mui-custom-form override-width-22 override-m-1 has-link">
              <TextField
                id="related-ft"
                fullWidth
                label="Related FT TCN"
                InputProps={ {
                  readOnly: true, className: 'Mui-disabled',
                  inputComponent: LinkOfTcn,
                  inputProps: { value: props.values.adjustedTcn, saveFlag: props.linkEnable }
                } }
                placeholder=""
                InputLabelProps={ {
                  shrink: true
                } }
              >
              </TextField>
            </div>
            <div className="mui-custom-form override-width-22 override-m-1">
              <TextField
                id='ATRnumber'
                fullWidth
                label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                placeholder=""
                value={props.values.newAtrNumber}
                onChange={props.handleChange('newAtrNumber')}
                inputProps={{ maxlength: 18 }}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={props.errors[2]&&props.errors[2] !== ''?`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`:null}
                error= {props.errors[2] &&props.errors[2] !== ''?`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${TRANSACTION_NUMBER_FORMAT}`:null}
              ></TextField>
            </div>
          </div>
        </form>
        <div className="float-right mr-4 mb-2">
          <Button className="btn btn-primary" onClick={ handleChanges }>
            Calculate
          </Button>
        </div>
        <div className="clearfix"></div>
      </fieldset>
      { props.showAdjustVerification ? (
        <AdjustmentVerification
          values={ props.values }
          calculatedData={ props?.calculatedData?.receiptVO?.receiptAdjustmentVO }
          lobCode={ props.lobCode }
          atrNumber={props?.values.newAtrNumber}
          reasonCodeDropDown={ props.reasonCodeDropDown }
        />
      ) : null }
    </div>
  );
}
