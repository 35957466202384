/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const LOB_REQUIRED = 'LOB is required.';
export const FCN_REQUIRED = 'FCN is required.';
export const NO_RECORDS_FOUND = 'No record is found. Please review your search criteria.';
export const INVALID_FORMAT = 'Please enter the data in correct format.';
export const PROCESSING_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const NO_RECORDS = 'No records found for the search criteria entered.';
export const ESTABLISHED_BEGIN_DATE = 'Established End Date must be greater than or equal to the Established Begin Date.';
export const BEGIN_DATE = 'End Date must be greater than or equal to the Begin Date.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const PAYEEID_ERROR = 'Payee Type and Payee ID Type Code are required when Payee ID is entered.';
export const PAYEENAME_ERROR = 'A minimum of 2 characters must be entered for a starts with search and a contains search.';
export const PAYEETYPE_REQUIRED = 'Payee Type is required.';
export const PAYEEIDTYPECODE_REQUIRED = 'Payee ID Type Code is required.';
export const PAYEEID_REQUIRED = 'Payee ID is required.';
export const ACTIVITY_TYPE_CODE_REQUIRED = 'Activity Type Code is required.';
export const FINANCIAL_REASON_CODE_REQUIRED = 'Financial Reason Code is required.';
export const FUND_CODE_REQUIRED = 'Fund Code is required.';
export const ORIGINAL_REQUIRED = 'Original Amount is required.';
export const ORIGINAL_AMOUNT_GREATERTHAN_ZERO = 'Original Amount must be greater than zero.';
export const ADDREFUND_DISPOSITION_REASON_CODE_REQUIRED = 'Disposition Reason Code is required.';
export const PAYMENT_AMOUNT_GREATERTHAN_ZERO = 'The Payment Amount must be greater than zero.';
export const DISPOSITION_AMOUNT_REQUIRED = 'Amount To Apply is required.';
export const DISPOSITION_GREATERTHAN_ZERO = 'Amount To Apply must be greater than zero.';
export const DISPOSITION_GREATERTHAN_RECIEPT = 'Amount To Apply must be less than or equal the Receipt Balance Amount.';
export const PAYMENT_AMOUNT_BALANCE_AMOUNT = 'Payment Amount must be less than or equal the Balance Amount.';
export const DISPOSITION_BALANCE_AMOUNT = 'Amount To Apply must be less than or equal the AR Balance Amount.';
export const FISCAL_YEAR_REQUIRED = 'Fiscal Year is required.';
export const PAYMENT_AMOUNT_SHOULDNOTGREATERTHAN_ORIGINALAMOUNT = 'Payment Amount must be less than or equal the Balance Amount.';
export const RETURN_RECEIPT_VALUES_NOT_EQUAL_ORIGINAL_VALUES = 'The Returned Receipt Number and Returned Receipt Amount must match the Original Check Number and Check Amount.';
export const SUCCESSFULLY_SAVED = 'System Successfully Saved the Information.';
export const MEMBER_ID_REQUIRED = 'Member is required when Member ID Type is selected from the select box.';
export const MEMBER_TYPE_REQUIRED = 'Member ID Type is required when Member ID is entered.';
export const CHECKNUMBER_REQUIRED = 'Check Number is required.';
export const INTERNAL_TRACKING_NUMBER_REQUIRED = 'Internal Tracking Number is required.';
export const ITEM_NUMBR_REQUIRED = 'Item Number is required.';
export const MEMBER_ID_TYPE_REQUIRED_FOR_PATIENT_LIAB = 'Member ID Type is required for Patient Liability.';
export const MEMBER_ID_REQUIRED_FOR_PATIENT_LIAB = 'Member ID is required for Patient Liability.';
export const FFP_CALCULATE_DATE_REQUIRED = 'The FFP Calculated Date is required.';
export const INVALID_DATE_FORMAT = 'Please enter the date in correct format.';
export const BALANCE_AMOUNT_ZERO = 'A Receipt with a Balance Amount of zero cannot be Reversed.';
export const BALANCE_AMOUNT_ZERO_ADJUST = 'A Receipt with a Balance Amount of zero cannot be adjusted.';
export const REASON_CODE = 'Reason Code is required.';
export const PAYEE_NOTFOUND = 'Payee/Payer does not exist in the database.';
export const EST_ENDDATE_FORMAT_INVALID = 'Established End Date entered is invalid.';
export const EST_BEGINDATE_FORMAT_INVALID = 'Established Begin Date entered is invalid.';
export const PEND_ENDDATE_FORMAT_INVALID = 'Pend End Date entered is invalid.';
export const PEND_BEGINDATE_FORMAT_INVALID = 'Pend Begin Date entered is invalid.';
export const PAYER_AMOUNT_REQUIRED = 'Payment Amount is required.';
export const RETURN_ONLY_ONCE_ERROR = 'Financial Receipts can only be voided once or Receipt has been dispositioned/used.';
