/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Bootstrap, { Button } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import '../../../FinancialPayOut/components/FPSearch/PayeeReceivablesComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import * as moment from 'moment';
import { withRouter } from 'react-router';

import PayoutApprovalSearchResults from './PayoutApprovalSearchResults';
import SearchResults from './SearchResults.json';
import * as FPSearchConstants from './PayoutApprovalSearchConstants';
import {
  getPayeeIdType,
  payeeSearch,
  updatePayoutApprovalActionPayee
} from '../../Store/Actions/AccountPayableActions';
import {
  generateUUID,
  validateDateMinimumValue,
  compareTwoDatesGreaterThanOrEqual,
  setSelectedDate,
  getUTCTimeStamp
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import { getFinancialPayoutDetails } from '../../../FinancialPayOut/Store/Actions/financialPayout/financialPayoutActions';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import Swal from 'sweetalert2';
import { Prompt } from 'react-router-dom';
import Radio from '@material-ui/core/Radio';
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';
import {Default_Establishment_ThruDate} from'../../../../SharedModules/AppConstants'
function PayeeReceivable(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;

  const [showTable, setShowTable] = React.useState(false);
  const [outstandingBalanceCheck, setOutstandingBalanceCheck] = React.useState(
    false
  );
  
  const [
    {
      showLOBError,
      showPayeeIDTypeCodeError,
      showPayeeError,
      showThruDateError,
      showFromDateError,
      showMinThruDateError,
      showMinFromDateError,
      showFromGreaterThruError
    },
    setShowError
  ] = React.useState(false);
  let errorMessagesArray = [];
  let successMessagesArray = [];

  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedThruDate, setSelectedThruDate] = React.useState(
    new Date('12/31/1999')
  );
  const [selectedLastDate, setSelectedLastDate] = React.useState('');
  const [fromDatePress, setFromDatePress] = React.useState(false);
  const [thruDatePress, setThruDatePress] = React.useState(false);
  const [lastDatePress, setLastDatePress] = React.useState(false);

  const [approvalArray, setApprovalArray] = React.useState([]);
  const [deleteArray, setDeleteArray] = React.useState([]);

  const [selectedDataForDelete, setSelectedDataForDelete] = React.useState([]);
  const [selectedDataForApproval, setSelectedDataForApproval] = React.useState(
    []
  );
  const [payeeIdTypeCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [tableDataDesc, setTableDataDesc] = React.useState([]);
  const [logInUserID, setlogInUserID] = React.useState();

  const handleFromDateChange = date => {
    setSelectedFromDate(date);
  };
  const onPayoutDetails = searchValues =>
    dispatch(getFinancialPayoutDetails(searchValues));
  const payoutDetails = useSelector(
    state => state.financialPayoutState.singlePayoutDetails
  );

  const handleFromDateText = fromDateText => {
    setSelectedFromDate(fromDateText.target.value);
    setFromDatePress(true);
  };

  const handleThruDateChange = date => {
    setSelectedThruDate(date);
  };

  const handleThruDateText = thruDateText => {
    setSelectedThruDate(thruDateText.target.value);
    setThruDatePress(true);
  };

  const handleLastDateChange = date => {
    setSelectedLastDate(date);
    setLastDatePress(false);
  };

  const handleLastDateText = lastDateText => {
    setSelectedLastDate(lastDateText.target.value);
    setLastDatePress(true);
  };
  const [allowNavigation, setAllowNavigation] = React.useState(false);

  const [values, setValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    payee: '',
    payeeIdCode: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE,
    approvalStatus: '',
    selectedFromDate: null,
    selectedThruDate: new Date('12/31/9999')
  });

  useEffect(() => {
    resetPayee();
  }, []);

  useEffect(() => {
    resetPayee();
  }, [props.value]);
  // Map function to get the Description for corresponding Code
  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };

  const handleOutstandingBalanceCheck = () => {
    setOutstandingBalanceCheck(!outstandingBalanceCheck);
  };

  const handleChange = name => event => {
    if (name === 'selectedFromDate' || name === 'selectedThruDate') {
      var updatedDateString = event != null ? setSelectedDate(event) : null;
      setValues({ ...values, [name]: updatedDateString });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const selectedArrayApproval = data => {
    setSelectedDataForApproval(data);
  };
  const selectedArrayDelete = data => {
    setSelectedDataForDelete(data);
  };

  const resetPayee = () => {
    setAllowNavigation(false);
    setOutstandingBalanceCheck(false);
    setValues({
      lob: DEFAULT_DD_VALUE,
      payee: '',
      payeeIdCode: DEFAULT_DD_VALUE,
      payeeType: DEFAULT_DD_VALUE,
      reasonCode: DEFAULT_DD_VALUE,
      approvalStatus: '',
      selectedThruDate: '12/31/9999',
      selectedFromDate: null
    });
    setShowTable(false);
    errorMessagesArray = [];
    setShowError(false);
    props.showError(errorMessagesArray);
    props.showSuccess([]);
    setSelectedThruDate(new Date('12/31/1999'));
    setSelectedFromDate(null);
  };
  const dispatch = useDispatch();
  const onPayeeTypeIdDropDown = value => dispatch(getPayeeIdType(value));
  const onSearchPayee = value => dispatch(payeeSearch(value));
  const onUpdateApproval = value =>
    dispatch(updatePayoutApprovalActionPayee(value));
  const payloadViewData = useSelector(
    state => state.accountPayableState.payeeSearchData
  );
  const approvalUpdate = useSelector(
    state => state.accountPayableState.updateApprovalDataPayee
  );

  const lobDD = props.addDropdowns && props.addDropdowns['Reference#R_LOB_CD'] ? props.addDropdowns['Reference#R_LOB_CD'] : [];
  const payeeTypeDD = props.addDropdowns && props.addDropdowns['G1#G_CMN_ENTY_TY_CD'] ? props.addDropdowns['G1#G_CMN_ENTY_TY_CD'] : [];
  const reasonDD = props.addDropdowns && props.addDropdowns['F1#F_RSN_CD'] ? props.addDropdowns['F1#F_RSN_CD'] : [];
  // user id
  const logInUserIDConst = useSelector(state => state.sharedState.logInUserID);

  useEffect(() => {
    if (logInUserIDConst) {
      setlogInUserID(logInUserIDConst);
    }
  }, [logInUserIDConst]);

  useEffect(() => {
    const value = values.payeeType;
    if (values.payeeType !== DEFAULT_DD_VALUE) {
      onPayeeTypeIdDropDown(value);
    }
  }, [values.payeeType]);

  useEffect(() => {
    if (payloadViewData && payloadViewData.systemFailure) {
      props.showLoader(false);
      setShowTable(false);
      errorMessagesArray.push(FPSearchConstants.SYS_ERROR);
      props.showError(errorMessagesArray);
      setTableData([]);
    } else if (payloadViewData && payloadViewData.respcode === '01') {
      setAllowNavigation(false);
      setShowTable(true);
      props.showLoader(false);
      errorMessagesArray = [];
      props.showError(errorMessagesArray);
      setTableData(payloadViewData.enterpriseSearchresultVO.searchResults);
    } else if (payloadViewData) {
      setShowTable(false);
      props.showLoader(false);
      if (
        payloadViewData && payloadViewData.length === 0
      ) {
        errorMessagesArray.push(FPSearchConstants.NO_RECODRS_FOUND);
        props.showError(errorMessagesArray);
      } else {
        if (
          payloadViewData &&
          payloadViewData.errorMessages &&
          payloadViewData.errorMessages.length > 0
        ) {
          payloadViewData.errorMessages.map((option, index) => {
            errorMessagesArray.push(option);
          });
        }
        props.showError(errorMessagesArray);
      }
    }
  }, [payloadViewData]);

  useEffect(() => {
    const tempPayeePayload = [];
    tableData.map((row, index) => {
      const outputData = row;
      tempPayeePayload.push({
        ...outputData,
        uid1: generateUUID(),
        uid2: generateUUID(),
        lobDesc: mapCodeDescription(row.lob, lobDD),
        payeeTypeDesc: mapCodeDescription(row.payeeType, payeeTypeDD),
        reasonCodeDesc: mapCodeDescription(row.reasonCode, reasonDD),
        resonCodeLongDesc: getLongDescription(reasonDD, row.reasonCode)
      });
    });

    setTableDataDesc(tempPayeePayload);
  }, [tableData]);

  useEffect(() => {
    if (tableDataDesc.length > 0) {
      const approvalArraySeperation = tableDataDesc.map(
        x => x.approvalIndicator
      );
      setApprovalArray(approvalArraySeperation);
      const deleteArraySeperation = tableDataDesc.map(x => {
        if (x.approvalIndicator) {
          x.deleteIndicator = false;
        }
      });
      setDeleteArray(deleteArraySeperation);
      tableDataDesc.map((value) => {
        value.checkApproveFlag = logInUserID === value.userID ? true : logInUserID === value.addedAuditUserID ? true : false;
      })
    }
  }, [tableDataDesc]);

  let payeeTypeIdDropDown = [];
  payeeTypeIdDropDown = useSelector(
    state => state.accountPayableState.payeeIdTypeCcdeData
  );

  useEffect(() => {
    if (props.lobData && props.lobData.length > 0) {
      let tempData = JSON.parse(JSON.stringify(values));
      if (props.lobData.length === 1) {
        tempData.lob = props.lobData[0].code;
      }
      setValues(tempData);
    }
  }, [props.lobData]);

  useEffect(() => {
    if (props.payeeTypeData && props.payeeTypeData.length > 0) {
      let tempData = JSON.parse(JSON.stringify(values));
      if (props.payeeTypeData.length === 1) {
        tempData.payeeType = props.payeeTypeData[0].code;
      }
      setValues(tempData);
    }
  }, [props.payeeTypeData]);

  useEffect(() => {
    if (props.reasonCodeData && props.reasonCodeData.length > 0) {
      let tempData = JSON.parse(JSON.stringify(values));
      if (props.reasonCodeData.length === 1) {
        tempData.lob = props.reasonCodeData[0].code;
      }
      setValues(tempData);
    }
  }, [props.reasonCodeData]);

  useEffect(() => {
    if (payeeTypeIdDropDown && payeeTypeIdDropDown.payeeIdTypeCode) {
      let tempData = JSON.parse(JSON.stringify(values));
      if (payeeTypeIdDropDown.payeeIdTypeCode.length === 1) {
        tempData.payeeIdCode = payeeTypeIdDropDown.payeeIdTypeCode[0].code;
      }
      setValues(tempData);
      setPayeeTypeIdCodeData(payeeTypeIdDropDown.payeeIdTypeCode);
    }
  }, [payeeTypeIdDropDown]);

  const searchPayee = () => {
    props.showSuccess([]);
    let showLOBError = false;
    let showThruDateError = false;
    let showFromDateError = false;
    let showMinThruDateError = false;
    let showMinFromDateError = false;
    let showPayeeIDTypeCodeError = false;
    let showPayeeError = false;
    const showFromGreaterThruError = false;

    if (!values.lob || values.lob === DEFAULT_DD_VALUE) {
      showLOBError = true;
      errorMessagesArray.push(FPSearchConstants.LOB_REQUIRED);
      props.showError(errorMessagesArray);
    }
    if (values.selectedThruDate) {
      if (!moment(values.selectedThruDate).isValid()) {
        showThruDateError = true;
        props.showError(errorMessagesArray);
      }
      if (!values.selectedFromDate) {
        if (validateDateMinimumValue(values.selectedFromDate)) {
          showMinThruDateError = true;
          props.showError(errorMessagesArray);
        }
      }
    }
    if (values.payeeType !== DEFAULT_DD_VALUE) {
      if (values.payeeIdCode === DEFAULT_DD_VALUE) {
        showPayeeIDTypeCodeError = true;
        errorMessagesArray.push(FPSearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED);
      }
      if (!values.payee || (values.payee && !values.payee.trim())) {
        showPayeeError = true;
        errorMessagesArray.push(FPSearchConstants.PAYEE_REQUIRED);
      }
    }

    if (values.selectedFromDate) {
      if (!moment(values.selectedFromDate).isValid()) {
        showFromDateError = true;
        props.showError(errorMessagesArray);
      }
      if (!values.selectedThruDate) {
        if (validateDateMinimumValue(selectedFromDate)) {
          showMinFromDateError = true;
          props.showError(errorMessagesArray);
        }
      }
    }
    if (
      (values.selectedThruDate && !moment(values.selectedThruDate).isValid()) ||
      (values.selectedFromDate && !moment(values.selectedFromDate).isValid())
    ) {
      errorMessagesArray.push(FPSearchConstants.DATE_INCORRECT_FORMAT);
    }
    props.showError(errorMessagesArray);

    setShowError({
      showLOBError: showLOBError,
      showThruDateError: showThruDateError,
      showFromDateError: showFromDateError,
      showMinThruDateError: showMinThruDateError,
      showMinFromDateError: showMinFromDateError,
      showFromGreaterThruError: showFromGreaterThruError,
      showPayeeIDTypeCodeError: showPayeeIDTypeCodeError,
      showPayeeError: showPayeeError
    });
    if (errorMessagesArray.length === 0) {
      props.showLoader(true);
      props.showError([]);
      showLOBError = false;
      setShowError({ showLOBError: showLOBError });
      let establismentThruDatecheck = values.selectedFromDate ? Default_Establishment_ThruDate : null;
      const value = {
        fcn: null,
        lineOfBusiness: values.lob !== DEFAULT_DD_VALUE ? values.lob : null,
        fcnSearch: 0,
        payeeSearch: 1,
        payeeType:
          values.payeeType !== DEFAULT_DD_VALUE ? values.payeeType : null,
        payeeID: values.payee,
        payeeIDTypeCode:
          values.payeeIdCode !== DEFAULT_DD_VALUE
            ? values.payeeIdCode
            : null,
        searchBy: 'searchByPayee',
        reasonCode:
          values.reasonCode !== DEFAULT_DD_VALUE ? values.reasonCode : null,
        establishedFromDate:
          values.selectedFromDate !== null
            ? moment(values.selectedFromDate).format('MM/DD/YYYY')
            : null,
        establishedThruDate:
          values.selectedThruDate !== null
            ? moment(values.selectedThruDate).format('MM/DD/YYYY')
            : establismentThruDatecheck,
        approvalStatus:
          values.selectedOption === 'Approved'
            ? 'A'
            : null || values.selectedOption === 'Unapproved'
              ? 'U'
              : null || values.selectedOption === 'Both'
                ? 'B'
                : null
      };
      setShowTable(false);
      onSearchPayee(removeLeadingTrailingSpaces(value));
    } else {
      setShowTable(false);
    }
  };
  const onSubmitConfirm = () => {
    const submitApprovalData = [];
    const approvalData = {
      approvalCode: false,
      fcn: '',
      deleteCode: false
    };
    const deleteData = {
      approvalCode: false,
      fcn: '',
      deleteCode: false
    };
    for (let i = 0; i < approvalArray.length; i++) {
      if (selectedDataForApproval.includes(tableDataDesc[i].uid1)) {
        approvalArray[i] = true;
        tableDataDesc[i].approvalIndicator = true;
        approvalData.auditUserID = logInUserID;
        approvalData.auditTimeStamp = getUTCTimeStamp();
        approvalData.addedAuditUserID = tableDataDesc[i].addedAuditUserID
          ? tableDataDesc[i].addedAuditUserID
          : logInUserID;
        approvalData.addedAuditTimeStamp = tableDataDesc[i].addedAuditTimeStamp
          ? tableDataDesc[i].addedAuditTimeStamp
          : getUTCTimeStamp();
        approvalData.fcn = tableDataDesc[i].fcn;
        approvalData.deleteCode = tableDataDesc[i].deleteIndicator;
        approvalData.approvalCode = tableDataDesc[i].approvalIndicator;
        const approve = {
          approvalCode: approvalData.approvalCode ? approvalData.approvalCode : false,
          deleteCode: approvalData.deleteCode ? approvalData.deleteCode : false,
          fcn: approvalData.fcn,
          auditUserID: approvalData.auditUserID,
          auditTimeStamp: approvalData.auditTimeStamp,
          addedAuditUserID: approvalData.addedAuditUserID,
          addedAuditTimeStamp: approvalData.addedAuditTimeStamp
        };
        submitApprovalData.push(approve);
      }
      if (approvalArray[i]) {
        tableDataDesc[i].approvalIndicator = true;
      }
    }

    for (let i = 0; i < deleteArray.length; i++) {
      if (selectedDataForDelete.includes(tableDataDesc[i].uid2)) {
        deleteArray[i] = true;
        tableDataDesc[i].deleteIndicator = true;
        deleteData.auditUserID = logInUserID;
        deleteData.auditTimeStamp = getUTCTimeStamp();
        deleteData.addedAuditUserID = tableDataDesc[i].addedAuditUserID
          ? tableDataDesc[i].addedAuditUserID
          : logInUserID;
        deleteData.addedAuditTimeStamp = tableDataDesc[i].addedAuditTimeStamp
          ? tableDataDesc[i].addedAuditTimeStamp
          : getUTCTimeStamp();
        deleteData.fcn = tableDataDesc[i].fcn;
        deleteData.deleteCode = tableDataDesc[i].deleteIndicator;
        deleteData.approvalCode = tableDataDesc[i].approvalIndicator;
        const deleteApprove = {
          approvalCode: deleteData.approvalCode ? deleteData.approvalCode : false,
          deleteCode: deleteData.deleteCode ? deleteData.deleteCode : false,
          fcn: deleteData.fcn,
          auditUserID: deleteData.auditUserID,
          auditTimeStamp: deleteData.auditTimeStamp,
          addedAuditUserID: deleteData.addedAuditUserID,
          addedAuditTimeStamp: deleteData.addedAuditTimeStamp
        };
        submitApprovalData.push(deleteApprove);
      }

      if (deleteArray[i]) {
        tableDataDesc[i].deleteIndicator = true;
      }
    }

    onUpdateApproval(submitApprovalData);
    setApprovalArray(approvalArray);
    setDeleteArray(deleteArray);
    setTableDataDesc([...tableDataDesc]);
    props.showLoader(true);

  };

  const submitForApproval = () => {
    props.showError([]);
    props.showSuccess([]);
    if (selectedDataForDelete.length > 0) {
      Swal.fire({
        customClass: {
          confirmButton: 'btn-text1 btn-transparent mr-3',
          cancelButton: 'btn1 btn-success',
          container: 'unsaved-changes-popup2'
        },
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        icon: 'warning',
        html:
          '<h4 >Are you sure you want to delete?</h4 >',
        showCancelButton: true,
        confirmButtonText: 'Cancel!',
        cancelButtonText: 'Continue! <i class="fa fa-arrow-right ml-1"></i>'
      }).then(result => {
        if (result.value) {
        } else {
          onSubmitConfirm();
        }
      });
    } else {
      onSubmitConfirm();
    }
  };

  useEffect(() => {
    if (approvalUpdate) {
      props.showLoader(false);
      successMessagesArray = [];
      errorMessagesArray = [];
      if (!approvalUpdate.systemFailure && approvalUpdate.errorMessages && approvalUpdate.errorMessages.length > 0) {
        approvalUpdate.errorMessages.map((data, index) => {
          if (data.indexOf(FPSearchConstants.SYS_ERROR) > -1) {
            errorMessagesArray.push(data);
          }
        });
      }
      if (!approvalUpdate.systemFailure && approvalUpdate.respcode === '01') {
        successMessagesArray = approvalUpdate.errorMessages;
        const value = {
          fcn: null,
          lineOfBusiness: values.lob !== DEFAULT_DD_VALUE ? values.lob : null,
          fcnSearch: 0,
          payeeSearch: 1,
          payeeType:
            values.payeeType !== DEFAULT_DD_VALUE ? values.payeeType : null,
          payeeID: values.payee,
          payeeIDTypeCode:
            values.payeeIdCode !== DEFAULT_DD_VALUE
              ? values.payeeIdCode
              : null,
          searchBy: 'searchByPayee',
          reasonCode:
            values.reasonCode !== DEFAULT_DD_VALUE ? values.reasonCode : null,
          establishedFromDate:
            values.selectedFromDate !== null
              ? moment(values.selectedFromDate).format('MM/DD/YYYY')
              : null,
          establishedThruDate:
            values.selectedThruDate !== null
              ? moment(values.selectedThruDate).format('MM/DD/YYYY')
              : null,
          approvalStatus:
            values.selectedOption === 'Approved'
              ? 'A'
              : null || values.selectedOption === 'Unapproved'
                ? 'U'
                : null || values.selectedOption === 'Both'
                  ? 'B'
                  : null
        };
        onSearchPayee(value);
        setSelectedDataForDelete([])
      }
      if (!approvalUpdate.systemFailure && approvalUpdate.respcode === '02') {
        errorMessagesArray = approvalUpdate.errorMessages;
      }
      if (approvalUpdate.systemFailure) {
        errorMessagesArray.push(FPSearchConstants.SYS_ERROR);
      }
      props.showSuccess(successMessagesArray);
      props.showError(errorMessagesArray);
    }
  }, [approvalUpdate]);

    const editLink = row => {
    setAllowNavigation(false);
    onPayoutDetails({
      fcn: row.fcn,
      searchBy: 'searchByFCN',
      lob: [null],
      fcnSearch: 1,
      payeeSearch: 0,
      payeeType: null,
      payeeID: null,
      payeeIDTypeCode: null
    });
    props.showLoader(true);
  };
  const { value } = props;
  React.useEffect(() => {
    setShowError(false);
  }, [value]);

  useEffect(() => {
    if (payoutDetails && !payoutDetails.systemFailure) {
      props.showLoader(false);
      const payoutUpdate = true;
      props.history.push({
        pathname: '/FinancialPayoutUpdate',
        state: { payoutUpdate, addSuccessData: payoutDetails.enterpriseSearchresultVO.searchResults[0] }
      });
    }
    if (payoutDetails && payoutDetails.systemFailure) {
      props.showError([FPSearchConstants.SYS_ERROR]);
    }
  }, [payoutDetails]);

  return (
    <div>
      <form noValidate autoComplete="off">
        <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
        <div className="form-wrapper">
            <NativeDropDown
              id="lob"
              isRequired
              label="LOB"
              value={values.lob}
              onChange={handleChange('lob')}
              errTxt={showLOBError ? FPSearchConstants.LOB_REQUIRED : null}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={props.lobData
                ? props.lobData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />

            <NativeDropDown
              id="payeeType"
              label="Payee Type"
              value={values.payeeType}
              onChange={handleChange('payeeType')}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={props.payeeTypeData
                ? props.payeeTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />

            <NativeDropDown
              id="payeeIdCode"
              label="Payee ID Type Code"
              value={values.payeeIdCode}
              disabled={values.payeeType === DEFAULT_DD_VALUE}
              onChange={handleChange('payeeIdCode')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showPayeeIDTypeCodeError
                  ? FPSearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED
                  : null
              }
              dropdownList={payeeIdTypeCodeData
                ? payeeIdTypeCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />

          <div className="mui-custom-form input-md">
            <TextField
              id="payee"
              fullWidth
              label="Payee ID"
              InputLabelProps={{
                shrink: true
              }}
              value={values.payee}
              onChange={handleChange('payee')}
              inputProps={{ maxLength: 15 }}
              helperText={
                showPayeeError ? FPSearchConstants.PAYEE_REQUIRED : null
              }
              error={showPayeeError ? FPSearchConstants.PAYEE_REQUIRED : null}
            />
          </div>
          {
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="mui-custom-form input-md with-select">
                <KeyboardDatePicker
                  maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                  id="date-picker-dialog"
                  fullWidth
                  label="Established From Date"
                  format="MM/dd/yyyy"
                  placeholder="mm/dd/yyyy"
                  minDate={'01/01/1964'}
                  value={values.selectedFromDate}
                  name="selectedFromDate"
                  onChange={handleChange('selectedFromDate')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  helperText={
                    showFromDateError
                      ? FPSearchConstants.DATE_INCORRECT_FORMAT
                      : null || showMinFromDateError
                        ? AppConstants.DATE_ERROR_1964
                        : null
                  }
                  error={
                    showFromDateError
                      ? FPSearchConstants.DATE_INCORRECT_FORMAT
                      : null || showMinFromDateError
                        ? AppConstants.DATE_ERROR_1964
                        : null
                  }
                />
              </div>
            </MuiPickersUtilsProvider>
          }
          {
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <div className="mui-custom-form input-md with-select">
                <KeyboardDatePicker
                  maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                  id="date-picker-dialog1"
                  fullWidth
                  label="Established Thru Date"
                  format="MM/dd/yyyy"
                  placeholder="mm/dd/yyyy"
                  minDate={'01/01/1964'}
                  value={values.selectedThruDate}
                  onChange={handleChange('selectedThruDate')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  helperText={
                    showThruDateError
                      ? FPSearchConstants.DATE_INCORRECT_FORMAT
                      : null || showMinThruDateError
                        ? AppConstants.DATE_ERROR_1964
                        : null || showFromGreaterThruError
                          ? FPSearchConstants.THRU_DATE_GREATERTHAN_FROM_DATE
                          : null
                  }
                  error={
                    showThruDateError
                      ? FPSearchConstants.DATE_INCORRECT_FORMAT
                      : null || showMinThruDateError
                        ? AppConstants.DATE_ERROR_1964
                        : null || showFromGreaterThruError
                          ? FPSearchConstants.THRU_DATE_GREATERTHAN_FROM_DATE
                          : null
                  }
                />
              </div>
            </MuiPickersUtilsProvider>
          }

            <NativeDropDown
              id="reasonCode"
              label="Reason Code"
              value={values.reasonCode}
              onChange={handleChange('reasonCode')}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={props.reasonCodeData
                ? props.reasonCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />

          <div className="mui-custom-form radio-box input-md ">
            <label className="MuiFormLabel-root small-label">Status</label>
            <div className="sub-radio">
              <Radio
                id="Approved"
                value="Approved"
                checked={values.selectedOption === 'Approved'}
                onChange={handleChange('selectedOption')}
                style={{ height: '13px', width: '13px' }}
              />
              <span className="text-black sub-label">
                <label for="Approved">Approved</label>
              </span>{' '}
              <Radio
                id="Unapproved"
                value="Unapproved"
                checked={values.selectedOption === 'Unapproved'}
                onChange={handleChange('selectedOption')}
                style={{ height: '13px', width: '13px' }}
              />
              <span className="text-black sub-label">
                <label for="Unapproved">Unapproved</label>
              </span>{' '}
              <Radio
                id="Both"
                value="Both"
                checked={values.selectedOption === 'Both'}
                onChange={handleChange('selectedOption')}
                style={{ height: '13px', width: '13px' }}
              />
              <span className="text-black sub-label">
                <label for="Both">Both</label>
              </span>
            </div>
          </div>
        </div>

        <div className="float-right mr-3">
          <Button className="btn-tab-pannel" onClick={() => searchPayee()}>
            <i class="fa fa-search" aria-hidden="true"></i>
            Search
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className="bt-tabreset btn-transparent ml-1"
            onClick={() => resetPayee()}
          >
            <i class="fa fa-undo" aria-hidden="true"></i>
            Reset
          </Button>
        </div>

        <div className="clearfix"></div>
      </form>

      <div className="tab-holder mt-3">
        {showTable ? (
          <div className="tab-holder my-3 p-0">
            <div className="table-heading">
              <h2 className="mb-3">Payout Approval or Deletion</h2>
            </div>
            {' '}
            <PayoutApprovalSearchResults
              approvalArray={approvalArray}
              setAllowNavigation={setAllowNavigation}
              deleteArray={deleteArray}
              selectedArrayRowsApproval={selectedArrayApproval}
              selectedArrayRowsDelete={selectedArrayDelete}
              tableData={tableDataDesc}
              editLink={editLink}
              values={values}
            />
            <div className="float-right">
              <Button
                className="btn btn-primary ml-1 mb-2"
                onClick={() => submitForApproval()}
              >
                Submit
              </Button>
            </div>
            <div className="clearfix"></div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default withRouter(PayeeReceivable);
