/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import { checkReasonCode, checkDropdownValue } from '../financialPayoutValidationUtilities';
import * as DateUtils from '../../../../SharedModules/DateUtilities/DateUtilities';
import Radio from '@material-ui/core/Radio';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../../SharedModules/services/service';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

export default function PaymentDetailsComponent(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const disableField = true;

  const fundCodeVal = props.fundCodeData && props.fundCodeData.find(value => value.code == props.values.collocationCode);
  const getCOSReasonCodes = props.getCOSReasonCodes;

  const cosVal= props.COSData && props.COSData.find(value=> value.code === props.values.categoryOfService);
  const rsnVal= props.reasonCodeData && props.reasonCodeData.find(value=> value.code === props.values.reasonCode);
  
  

  return (
    <div>
      <form autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form">
            <TextField
              id="standard-FCN-id"
              fullWidth
              label="FCN"
              placeholder=""
              value={props.values.fcn}
              disabled = {props.payoutParentreceipt  || props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || (disableField )}
              InputProps={{ readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : (disableField ), className: !userInquiryPrivileges ? !userInquiryPrivileges : (disableField ) ? 'Mui-disabled' : '' }}

              inputProps={{ maxlength: 14 }}
              InputLabelProps={{
                shrink: true
              }}
            >
            </TextField>
          </div>
          <div className="mui-custom-form " style={{ lineHeight: '100%' }} >
            <TextField
              id="standard-CheckNumber-id"
              fullWidth
              label="Check Number"
              placeholder=""
              inputProps={{ maxlength: 15 }}
              InputLabelProps={{
                shrink: true
              }}
              disabled = {props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || (checkDropdownValue((props.values.reasonCode) ? !checkReasonCode(props.values.reasonCode, props.disableReasonCodeData) : true) )}
              InputProps={{ readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : (checkDropdownValue((props.values.reasonCode) ? !checkReasonCode(props.values.reasonCode, props.disableReasonCodeData) : true) ), className: !userInquiryPrivileges ? !userInquiryPrivileges : (checkDropdownValue((props.values.reasonCode) ? !checkReasonCode(props.values.reasonCode, props.disableReasonCodeData) : true) ) ? 'Mui-disabled' : '' }}
              value={props.values.checkNumber}
              onChange={props.handleChange('checkNumber')}
              required={checkDropdownValue(props.values.reasonCode) ? checkReasonCode(props.values.reasonCode, props.disableReasonCodeData) : false}
              helperText={props.errors[4] ? props.errorText[4] : null}
              error={props.errors[4] ? props.errorText[4] : null}
            >
            </TextField>
          </div>
          {<MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form with-date with-select" style={{ lineHeight: '100%' }} >
              <KeyboardDatePicker
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                id="date-picker-dialogCheckDate"
                label="Check Date"
                required={checkDropdownValue(props.values.reasonCode) ? checkReasonCode(props.values.reasonCode, props.disableReasonCodeData) : false}
                disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || ((checkDropdownValue(props.values.reasonCode) ? !checkReasonCode(props.values.reasonCode, props.disableReasonCodeData) : true) )}
                InputProps={{ readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : ((checkDropdownValue(props.values.reasonCode) ? !checkReasonCode(props.values.reasonCode, props.disableReasonCodeData) : true) ), className: !userInquiryPrivileges ? !userInquiryPrivileges : ((checkDropdownValue(props.values.reasonCode) ? !checkReasonCode(props.values.reasonCode, props.disableReasonCodeData) : true) ) ? 'Mui-disabled' : '' }}
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={props.checkDate}
                onChange={props.handleCheckDate}
                InputLabelProps={{
                  shrink: true
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                helperText={props.errors[5] ? props.errorText[5] : null}
                error={props.errors[5] ? props.errorText[5] : null}
              />
            </div>
          </MuiPickersUtilsProvider>}
          {props.values.paymentStatusCode === 'P' ?
            <NativeDropDown
              id="standard-ReasonCode"
              label="Reason Code"
              isRequired
              value={props.values.reasonCode}
              onChange={props.handleChange('reasonCode')}
              placeholder=""
              inputProps={{ maxlength: 3 }}
              InputLabelProps={{
                shrink: true
              }}
              disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
              errTxt={props.errors[0] ? props.errorText[0] : null}
              dropdownList={props.dropdowns && props.dropdowns['F1#F_RSN_CD'] && 
                props.dropdowns['F1#F_RSN_CD'].length > 0
                ? props.dropdowns['F1#F_RSN_CD'].map((item, index) => (
                <option value={item.code}>
                  {item.description}
                </option>
              ))
                        : null}
            /> :  
            <NativeDropDown
              id="standard-ReasonCode"
              label="Reason Code"
              isRequired
              value={props.values.reasonCode || DEFAULT_DD_VALUE}
              onChange={props.handleChange('reasonCode')}
              placeholder=""
              inputProps={{ maxlength: 3 }}
              InputLabelProps={{
                shrink: true
              }}
              disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
              errTxt={props.errors[0] ? props.errorText[0] : null}
              dropdownList={(props.editScreen && props.reasonCodeData || props.addPayoutRsCodes).map((option, index) => (
                <option key={index} value={option.code}>
                  {option.description}
                </option>
              ))}
            />}
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form " style={{ lineHeight: '100%' }}>
            <TextField
              required
              fullWidth
              id="amount"
              label="Amount"
              placeholder=""
              inputProps={{ maxlength: 12 }}
              onInput={e => {
                e.target.value = e.target.value.replace(
                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                  ''
                );
              }}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              type="text"
              disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
              value={props.values.amount}
              onChange={props.handleChange('amount')}
              helperText={props.errors[1] ? props.errorText[1] : null}
              error={props.errors[1] ? props.errorText[1] : null}
            >
            </TextField>
          </div>
          <div className="mui-custom-form radio-box input-15">
            <label className="MuiFormLabel-root small-label no-margin">Approved</label>
            {!props.editScreen
              ? <div className="sub-radio">
                <Radio id="Approved" name="Approved" disabled={props.payoutParentreceipt  ||!userInquiryPrivileges ? !userInquiryPrivileges : disableField} value={props.values.approval} checked={props.values.approval === 'Yes'} />
                <span className="text-black" style={{ fontSize: '14px', fontWeight: '650', color: '#274463' }}><label style={{ fontSize: '14px' }} for="Approved">Yes</label></span>
                <Radio className="ml-2" name="Approved" id="ApprovedNO" disabled={props.payoutParentreceipt  ||!userInquiryPrivileges ? !userInquiryPrivileges : disableField} value={props.values.approval} checked={props.values.approval === 'No'} />
                <span className="text-black" style={{ fontSize: '14px', fontWeight: '650', color: '#274463' }}> <label style={{ fontSize: '14px' }} for="ApprovedNO">No</label></span>
              </div>
              : null}
            {props.editScreen
              ? <div className="sub-radio">
                {props.values.approval === 'Yes' ? <span className="text-blue" style={{ fontSize: '14px', verticalAlign: 'text-bottom' }}><label for="Approved">Yes</label></span> : ''}
                {props.values.approval === 'No' ? <span className="text-blue" style={{ fontSize: '14px', verticalAlign: 'text-bottom' }}><label for="ApprovedNO">No</label></span> : ''}
              </div>
              : null}
          </div>
          <div className="mui-custom-form " style={{ lineHeight: '100%' }}>
            <TextField
              id="standard-EstablishedDate"
              fullWidth
              label="Established Date"
              disabled = {props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || disableField}
              InputProps={{ readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : disableField, className: !userInquiryPrivileges ? !userInquiryPrivileges : disableField ? 'Mui-disabled' : '' }}
              placeholder=""
              inputProps={{ maxlength: 10 }}
              InputLabelProps={{
                shrink: true
              }}
              value={props.editScreen ? props.values.establishedDateStr : DateUtils.getDateInMMDDYYYYFormat(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE })))}
            >
            </TextField>
          </div>
          <div className="mui-custom-form has-link" style={{ lineHeight: '100%' }} >
            <TextField
              id="standard-RelatedFTTCN"
              fullWidth
              label="Related FT TCN"
              disabled = {props.payoutParentreceipt  || props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || disableField}
              InputProps={{
                readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : disableField, className: !userInquiryPrivileges ? !userInquiryPrivileges : disableField ? 'Mui-disabled' : '',
                startAdornment: (
                  <InputAdornment position="start">
                    <a href={CLAIMS_INQUIRY_TCN_LINK + props?.values?.relatedFTTCN} aria-label="relatedFTTCN link" target='_blank'>
                      {props.values.relatedFTTCN}
                    </a>
                  </InputAdornment>
                )
              }}
              placeholder=""
              inputProps={{ maxlength: 17 }}
              InputLabelProps={{
                shrink: true
              }}
            >
            </TextField>
          </div>
          {<MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form with-date with-select" style={{ lineHeight: '100%' }} >
              <KeyboardDatePicker
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                fullWidth
                id="date-picker-dialog-BeginDate"
                required={ props.payoutRsnCd?.includes(props.values.reasonCode) ? true : false || props.beginEndDateReqSts}
                label="Begin Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={props.costsettlementBeginDate}
                onChange={props.handleSettlementBeginDateChange}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                helperText={props.errors[7] ? props.errorText[7] : null || props.errors[6] ? props.errorText[6] : null}
                error={props.errors[7] ? props.errorText[7] : null || props.errors[6] ? props.errorText[6] : null}
              />
            </div>
          </MuiPickersUtilsProvider>}
          {<MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="mui-custom-form with-date" style={{ lineHeight: '100%' }}>
              <KeyboardDatePicker
                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                fullWidth
                id="date-picker-dialog-EndDate"
                required={ props.payoutRsnCd?.includes(props.values.reasonCode) ? true : false || props.beginEndDateReqSts}
                label="End Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                value={props.costsettlementEndDate}
                onChange={props.handleSettlementEndDateChange}
                InputLabelProps={{
                  shrink: true
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                helperText={props.errors[8] ? props.errorText[8] : null || props.errors[6] ? props.errorText[6] : null}
                error={props.errors[8] ? props.errorText[8] : null || props.errors[6] ? props.errorText[6] : null}
              />
            </div>
          </MuiPickersUtilsProvider>}
          <div className="mui-custom-form " style={{ lineHeight: '100%' }} >
            <TextField
              id="standard-CheckNumber-RA"
              fullWidth
              label="RA Number"
              placeholder=""
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              value={props.values.raNumber}
            >
            </TextField>
          </div>
          <div className="mui-custom-form " style={{ lineHeight: '100%' }} >
            <TextField
              id="standard-CheckNumber-payment"
              fullWidth
              label="Payment Status"
              placeholder=""
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              value={props.checkStatusDesc}
            >
            </TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form">
            <TextField
              id="standard-FCN1"
              fullWidth
              label="Fiscal Pend ID"
              placeholder=""
              value={props.values.fiscalPendID}
              InputProps={{ readOnly: true, className: 'Mui-disabled' }}
              inputProps={{ maxlength: 14 }}
              InputLabelProps={{
                shrink: true
              }}
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md">
            <TextField
              id="ATRNumber"
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL}
              disabled= {props.payoutParentreceipt || props?.disableATR}
              InputLabelProps={{
                shrink: true
              }}
            value={props.values?.atrNumber}
             onChange={props.handleChange('atrNumber')}
              inputProps={{ maxLength: 18 }}
              helperText={props.errors[9] ? props.errorText[9] : null || props.errors[9] ? props.errorText[9] : null}
                error={props.errors[9] ? props.errorText[9] : null || props.errors[9] ? props.errorText[9] : null}
            />
          </div>
        </div>
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-12" >
              <fieldset className="custom-fieldset">
                <legend>Account:</legend>
                <div className="form-wrapper ">
                  <div className="mui-custom-form input-md override-width-28">
                    <TextField
                      required
                      fullWidth
                      id="standard-FiscalYear"
                      type='number'
                      label="Fiscal Year"
                      placeholder=""
                      inputProps={{ maxlength: 4 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                      }}
                      onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                      disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                      value={props.values.fiscalYear}
                      onChange={props.handleChange('fiscalYear')}
                      helperText={props.errors[2] ? props.errorText[2] : null}
                      error={props.errors[2] ? props.errorText[2] : null}
                    >
                    </TextField>
                  </div>
                  {props.values.paymentStatusCode === 'P' ?
                    <NativeDropDown
                      className='override-width-28'
                      id="standard-CategoryofService"
                      label="Category of Service"
                      value={props.values.categoryOfService}
                      onChange={props.handleChange('categoryOfService')}
                      disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                      placeholder=""
                      inputProps={{ maxlength: 3 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      dropdownList={props.dropdowns && props.dropdowns['C1#C_COS_CD'] && 
                        props.dropdowns['C1#C_COS_CD'].length > 0
                          ? props.dropdowns['C1#C_COS_CD'].map((item, index) => (
                            <option value={item.code}>
                              {item.description}
                            </option>
                          ))
                        : null}
                    /> :
                  <NativeDropDown
                    className='override-width-28'
                    id="standard-CategoryofService"
                    label="Category of Service"
                    value={ cosVal !== undefined ?  props.values.categoryOfService : DEFAULT_DD_VALUE}
                    onChange={props.handleChange('categoryOfService')}
                    disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                    placeholder=""
                    inputProps={{ maxlength: 3 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    dropdownList={props.COSData ? props.COSData.map((option, index) => (
                      <option key={index} value={option.code}>
                        {option.description}
                      </option>
                    )) : null}
                  />}
                  { props.values.paymentStatusCode === 'P' ?
                    <NativeDropDown
                      isRequired
                      className='override-width-28'
                      id="standard-FundCode"
                      label="Fund Code"
                      value={props.values.collocationCode}
                      onChange={props.handleChange('collocationCode')}
                      placeholder=""
                      inputProps={{ maxlength: 10 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                      errTxt={props.errors[3] ? props.errorText[3] : null}
                      dropdownList={props.dropdowns && props.dropdowns['R1#R_FUND_CD'] && 
                        props.dropdowns['R1#R_FUND_CD'].length > 0
                          ? props.dropdowns['R1#R_FUND_CD'].map((item, index) => (
                            <option value={item.code}>
                              {item.description}
                            </option>
                          ))
                        : null}
                    /> :
                   <NativeDropDown
                     isRequired
                     className='override-width-28'
                     id="standard-FundCode"
                     label="Fund Code"
                     value={fundCodeVal !== undefined ?  props.values.collocationCode :  DEFAULT_DD_VALUE}
                     onChange={props.handleChange('collocationCode')}
                     placeholder=""
                     inputProps={{ maxlength: 10 }}
                     InputLabelProps={{
                       shrink: true
                     }}
                     disabled={props.payoutParentreceipt  ||props.values.paymentStatusCode === 'P' || !userInquiryPrivileges || false}
                     errTxt={props.errors[3] ? props.errorText[3] : null}
                     dropdownList={props.fundCodeData ? props.fundCodeData.map((option, index) => (
                       <option key={index} value={option.code}>
                         {option.description}
                       </option>
                     )) : null}
                   />}
                </div>
              </fieldset>
            </div>
          </div>
        </div>

      </form>
      {props.editScreen ?
        <div className="col-12" style={{ marginBottom: '10px' }}>
          <fieldset className="custom-fieldset">
            <legend>Funding Source Allocation:</legend>
            <div className="form-wrapper">
              <div className="mui-custom-form">
                <TextField
                  id="standard-federal"
                  fullWidth
                  label="Federal"
                  placeholder=""
                  value={props.values.federal}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-state"
                  fullWidth
                  label="State"
                  placeholder=""
                  value={props.values.state}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-county"
                  fullWidth
                  label="County"
                  placeholder=""
                  value={props.values.county}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form">
                <TextField
                  id="standard-other"
                  fullWidth
                  label="Other"
                  placeholder=""
                  value={props.values.other}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    readOnly: true,
                    className: 'Mui-disabled'
                  }}
                  inputProps={{ maxlength: 14 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                </TextField>
              </div>
            </div>
          </fieldset>
        </div> : null}
    </div>
  );
}
