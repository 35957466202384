/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TableComponent from '../../../SharedModules/Table/Table';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import { searchCriteriaRowAction } from '../Store/Actions/FundCodeSearchActions';
import { DEFAULT_LOB_VALUE } from '../../../SharedModules/AppConstants';

const headCells = [
  {
    id: 'fundCodeDesc',
    numeric: false,
    disablePadding: true,
    enableHyperLink: false,
    label: 'Fund Code',
    isDate: false,
    isTooltip: true,
    isTitle: 'fundCodeLongDesc',

  },
  {
    id: 'cosCodeDesc',
    numeric: false,
    disablePadding: true,
    enableHyperLink: false,
    label: 'COS',
    isDate: false,
    isTooltip: true,
    isTitle: 'cosCodeLongDesc',
    width: '19%'
  },
  {
    id: 'agencyCodeDesc',
    numeric: false,
    disablePadding: true,
    enableHyperLink: false,
    label: 'Agency',
    isDate: false,
    width: '18%'
  },
  {
    id: 'beginDateTemp',
    numeric: false,
    disablePadding: true,
    enableHyperLink: false,
    label: 'Begin Date',
    isDate: true,
    width: '12%'
  },
  {
    id: 'endDateTemp',
    numeric: false,
    disablePadding: false,
    enableHyperLink: false,
    label: 'End Date',
    isDate: true,
    width: '12%'
  },
  {
    id: 'rankNumber',
    numeric: false,
    disablePadding: false,
    enableHyperLink: false,
    label: 'Rank',
    isDate: false,
    width: '10%'
  },
  {
    id: 'voidDate',
    numeric: false,
    disablePadding: false,
    enableHyperLink: false,
    label: 'Void Date',
    isDate: false,
    width: '12%'
  }
];

function FundCodeSearchTable(props) {
  const [redirect, setRedirect] = React.useState(0);
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  const dispatch = useDispatch();
  const onRowClick = value => dispatch(searchCriteriaRowAction(value));
  const fundCodeData = useSelector(
    state => state.fundCodeState.rowSearchResults
  );

  // Redirecting to fund code edit screen if fundCodeData is not null
  React.useEffect(() => {
    if (redirect === 1) {
      if (fundCodeData && fundCodeData.length === 1 && fundCodeData != null) {
        props.history.push({
          pathname: '/FundCodeEdit',
          state: { fundCodeData: fundCodeData, editFlag: '1', values: props.values }
        });
      } else if (fundCodeData && fundCodeData.length === 0) {
        setSpinnerLoader(false)
      }
    }
  }, [fundCodeData])

  const formatSearchCriteria = (_row) => ({
    lobCode: DEFAULT_LOB_VALUE,
    fundCode: _row.fundCode,
    activityCode: _row.activityCode,
    cosCode: _row.categoryOfServiceCode,
    beginDate: _row.beginDate,
    endDate: _row.endDate
  });

  const editRow = row => event => {
    setSpinnerLoader(true);       
    const tempvalue = formatSearchCriteria(row);
    onRowClick(tempvalue);
    const valuetoredirect = redirect + 1;
    setRedirect(valuetoredirect);
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const fundCode =
            props.dropdowns &&
            props.dropdowns['R1#R_FUND_CD'] &&
            props.dropdowns['R1#R_FUND_CD'].filter(
              value => value.code === each.fundCode
            );
          each.fundCodeDesc =
            fundCode && fundCode.length > 0
              ? fundCode[0].description
              : each.fundCode ? each.fundCode :  '';

          const cosCode =
              props.dropdowns &&
              props.dropdowns['Claims#C_COS_CD'] &&
              props.dropdowns['Claims#C_COS_CD'].filter(
                value => value.code === each.categoryOfServiceCode
              );
          each.cosCodeDesc =
            cosCode && cosCode.length > 0
                  ? cosCode[0].description
                  : each.categoryOfServiceCode ? each.categoryOfServiceCode : '';

          const activityCode =
            props.dropdowns &&
                  props.dropdowns['F1#F_AGCY_CD'] &&
                  props.dropdowns['F1#F_AGCY_CD'].filter(
                    value => value.code === each.activityCode
                  );
            each.agencyCodeDesc =
            activityCode && activityCode.length > 0
                  ? activityCode[0].description
                  : each.activityCode ? each.activityCode : '';      
                  
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <TableComponent
      print={props.print}
      headCells={headCells}
      pathTo='/FundCodeEdit?data='
      formatSearchCriteria={formatSearchCriteria}
      tableData={getTableData(props.tableData ? props.tableData : [])}
      isSearch={true}
      onTableRowClick={editRow}
      spinnerLoader={spinnerLoader}
      defaultSortColumn={headCells[1].id}
      order={'asc'}
    />
  );
}
export default withRouter(FundCodeSearchTable);
