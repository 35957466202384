/* eslint-disable no-unused-vars */
/**

* � [2020] Conduent, Inc. All rights reserved. Conduent�and Conduent Agile

* Star�are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProviderNotice from './ProviderNotice';
import RAEOBResult from './RAEOBResult';
import AdjustmentReason from './AdjustmentReason';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import SAR from './SAR';
import RemarkCode from './RemarkCode';
import './TextManagementSearch.css';
import EnhancedTableHead from './HCPCS';
import Checkbox from '@material-ui/core/Checkbox';
import EnhancedTableHead1 from './SPResult';
import EnhancedTableHead2 from './RevenueResult';
import { withRouter } from 'react-router';
import { Checkprivileges } from '../../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../../SharedModules/Layout/footer';
import * as ErrorConstants from './ErrorCheck';
import * as APP_CONSTANTS from '../../../../SharedModules/AppConstants';
import {
  searchSystemParameterAction,
  searchSystemParameterActionRAEOB,
  ResetTextSearchReducer,
  searchSystemParameterActionPN,
  searchSystemParameterActionSP,
  searchSystemParameterActionRevenue,
  searchSystemParameterActionRC,
  searchSystemParameterActionARC,
  searchSystemParameterActionNCPDP,
  resetSearchTextManagement,
  ProviderSpecialtyDataSearchAction
} from '../../Store/Actions/TextManagement/TextManagementActions';
import { ResetData } from '../../Store/Actions/TextManagement/TextManagementAddUpdateActions';
import {
  AppConfigDropdownActions,
  ModifierDropdownActions
} from '../../Store/Actions/AppConfigCommon/AppConfigActions';
import { useDispatch, useSelector } from 'react-redux';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import dropdownCriteria from './TextManagementSearch.json';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

const useStyles = makeStyles({
  tab100: {
    minWidth: 0
  }
});

function TextManagementSearch (props) {  
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));
  const modifierDropDownDispatch = () => dispatch(ModifierDropdownActions());
  const [SuccessMessage, setSuccessMessage] = React.useState([]);
  const [NCPDPValues, setNCPDPValues] = React.useState({
    NCPDPRejectCode: '',
    NCPDPRejectCodeText: ''
  });
  const [showForm, setShowForm] = React.useState(false);
  const [showRAEOB, setShowRAEOB] = React.useState(false);
  const [showPN, setShowPN] = React.useState(false);
  const [showPC, setShowPC] = React.useState(false);
  const [showAR, setShowAR] = React.useState(false);
  const [showSAR, setShowSAR] = React.useState(false);
  const [showSP, setShowSP] = React.useState(false);
  const [showRevenue, setShowRevenue] = React.useState(false);
  const [fromMod, setFromMod] = React.useState(true);
  const [thruMod, setThruMod] = React.useState(true);
  const [providerSpeciality, setProviderSpeciality] = React.useState(true);
  const [showHCPCS, setShowHCPCS] = React.useState(false);
  const [showVoid, setShowVoid] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [id, setId] = React.useState(0);
  const [inputValues, setInputValues] = React.useState({
    code: '',
    shortDescription: '',
    longDescription: '',
    constantText: ''
  });

  const [eobValues, setEOBValues] = React.useState({
    eobCode: '',
    eobText: '',
    eobRadio: ''
  });
  const [isSearch, setIsSearch] = React.useState(false);

  const onReset = () => dispatch(resetSearchTextManagement());
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [redirect, setRedirect] = React.useState(0);
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const [providerSpecialityData, setproviderSpecialityData] = React.useState(
    []
  );
  const [providerType, setproviderType] = React.useState([]);
  const [modifierData, setModifierData] = React.useState([]);
  const [redirectPN, setRedirectPN] = React.useState(0);
  const [redirectNCPDP, setRedirectNCPDP] = React.useState(0);
  const [redirectAR, setRedirectAR] = React.useState(0);
  const [redirectRC, setRedirectRC] = React.useState(0);
  const [redirectRAEOB, setRedirectRAEOB] = React.useState(0);
  const [displayTable, setDisplayTable] = React.useState([]);
  const [tableType, setTableType] = React.useState('');
  const onSearch = values => dispatch(searchSystemParameterAction(values));
  const onSearchRAEOB = values =>
    dispatch(searchSystemParameterActionRAEOB(values));
  const onSearchPN = values => dispatch(searchSystemParameterActionPN(values));
  const onSearchSP = values => dispatch(searchSystemParameterActionSP(values));
  const onSearchRC = values => dispatch(searchSystemParameterActionRC(values));
  const onSearchARC = values =>
    dispatch(searchSystemParameterActionARC(values));
  const onSearchRevenue = values =>
    dispatch(searchSystemParameterActionRevenue(values));
  const onSearchNCPDP = values =>
    dispatch(searchSystemParameterActionNCPDP(values));

  const payload = useSelector(
    state => state.appConfigState.textManagementState.payload
  );
  const dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );
  const modifierdropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.modifierDropdown
  );

  const providerTypeDesc = data => {
    const filteredValue = providerType.filter(
      (prv, index) => prv.code === data
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return data;
  };

  const providerSpecialityDesc = data => {
    if (providerSpecialityData) {
      const filteredValue = providerSpecialityData.filter(
        (prv, index) => prv.code === data
      );
      if (filteredValue && filteredValue.length > 0) {
        return filteredValue[0].description;
      }
    }
    return data;
  };

  useEffect(() => {
    onReset();
    dropDownDispatch(dropdownCriteria);
    modifierDropDownDispatch();
    if (props.location.state && props.location.state.message) {
      setSuccessMessage([props.location.state.message]);
    }
    if (props.location.search) {
      const params = props.location.search.split('?')[1].split('=');
      if (params[0] === 'remarkCode') {
        setValue(3);
        setrCValues({ remarkCode: params[1], remarkText: '', RCRadio: '' });
        searchCheckRC({ remarkCode: params[1], remarkText: '', RCRadio: '' });
      }
      if (params[0] === 'adjustmentReason') {
        setValue(4);
        setARCValues({
          adjustmentRC: params[1],
          adjustmentRT: '',
          ARCRadio: ''
        });
        searchCheckARC({
          adjustmentRC: params[1],
          adjustmentRT: '',
          ARCRadio: ''
        });
      }
      if (params[0] == 'raEob') {
        setValue(1);
        setEOBValues({ eobCode: params[1], eobText: '' });
        setValuesRAEOB({ RAEob: 'MED' });
        onSearchRAEOB({
          lobCode: ['MED'],
          claimEOBCode: params[1],
          eobText: '',
          eobTextStartsOrContains: ''
        });
      }
    }
    onDropdowns([
      Dropdowns.PROVIDER_TYPE,
      Dropdowns.PROVIDER_SPECL_CODE,
    ]);
  }, []);

  useEffect(() => {
    setspinnerLoader(false);
    dispatch(ResetData());
  }, [props]);

  React.useEffect(() => {
    if (
      payload &&
      payload.respcode === undefined &&
      !payload.systemFailure &&
      !payload.name
    ) {
      payload.map((data, index) => {
        if (data.providerType !== '') {
          data.providerTypeDesc = providerTypeDesc(data.providerType);
        }
        if (data.providerSpeciality !== '') {
          data.providerSpecialityDesc = providerSpecialityDesc(
            data.providerSpeciality
          );
        }
      });
    } else if (payload && payload.recordCount && payload.recordCount === 0) {
      seterrorMessages([ErrorConstants.NO_RECORD_FOUND]);
    }
  }, [payload]);

  const payloadData = payload ? payload[0] : {};

  useEffect(() => {
    if (redirect === 1) {
      if (payload != null) {
        if (payload.length === 1) {
          props.history.push({
            pathname: '/TextUpdate',
            state: { row: payloadData, TextType: 'EOMB', EombType: 'hcpcs' }
          });
        }
      }
    }

    if (redirectPN === 1) {
      if (payload != null) {
        if (payload.length === 1) {
          props.history.push({
            pathname: '/TextUpdate',
            state: { row: payloadData, TextType: 'Provider Notice' }
          });
        }
      }
    }

    if (redirectNCPDP === 1) {
      if (payload != null) {
        if (payload.length === 1) {
          props.history.push({
            pathname: '/TextUpdate',
            state: { row: payloadData, TextType: 'NCPDP Rejection Codes' }
          });
        }
      }
    }

    if (redirectAR === 1) {
      if (payload != null) {
        if (payload.length === 1) {
          props.history.push({
            pathname: '/TextUpdate',
            state: { row: payloadData, TextType: 'Adjustment Reason' }
          });
        }
      }
    }

    if (redirectRAEOB === 1) {
      if (payload != null) {
        if (payload.length === 1) {
          props.history.push({
            pathname: '/TextUpdate',
            state: { row: payloadData, TextType: 'RA EOB' }
          });
        }
      }
    }

    if (redirectRC === 1) {
      if (payload && payload != null) {
        if (payload.length === 1) {
          props.history.push({
            pathname: '/TextUpdate',
            state: { row: payload[0], TextType: 'Remark Code' }
          });
        }
      }
    }
  }, [payload]);

  useEffect(() => {
    if (payload != null && (payload.length > 0 || payload.length === 0)) {
      sessionStorage.setItem('textSetTableData', JSON.stringify(payload));
      sessionStorage.setItem('tableType', tableType);
      setspinnerLoader(false);
      setDisplayTable(payload);
      switch (tableType) {
        case 'hcpcs':
          setShowHCPCS(true);
          break;
        case 'sp':
          setShowSP(true);
          break;
        case 'revenue':
          setShowRevenue(true);
          break;
        case 'raeob':
          setShowRAEOB(true);
          break;
        case 'pn':
          setShowPN(true);
          break;
        case 'rc':
          setShowPC(true);
          break;
        case 'ar':
          setShowAR(true);
          break;
        case 'ncpdp':
          setShowSAR(true);
          break;
        default:
          setShowHCPCS(false);
          setShowRevenue(false);
          setShowSP(false);
          setShowPN(false);
          setShowAR(false);
          setShowRAEOB(false);
          setShowSAR(false);
          setShowPC(false);
      }
    }
    if (payload != null && payload.length === 0) {
      setspinnerLoader(false);
      errorMessagesArray.push(validvaluecons.NO_RECORD_FOUND);
      seterrorMessages(errorMessagesArray);
      setShowHCPCS(false);
      setShowRevenue(false);
      setShowSP(false);
      setShowPN(false);
    }
    if (payload && payload !== null && payload.systemFailure) {
      setspinnerLoader(false);
      errorMessagesArray.push(APP_CONSTANTS.ERR_PROCESSING_REQ);
      seterrorMessages(errorMessagesArray);
      setShowHCPCS(false);
      setShowRevenue(false);
      setShowSP(false);
      setShowPN(false);
    }
  }, [payload]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Provider#11']) {
        setproviderType(dropdown.listObj['Provider#11']);
      }
      if (dropdown.listObj['Provider#1054']) {
        setproviderSpecialityData(dropdown.listObj['Provider#1054']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (modifierdropdown && modifierdropdown.modifierList) {
      setModifierData(modifierdropdown.modifierList);
    }
  }, [modifierdropdown]);

  // Code to set Provider Notice fields to default

  const [pNValues, setpNValues] = React.useState({
    lobPN: DEFAULT_DD_VALUE,
    providertypeEOB: DEFAULT_DD_VALUE,
    providerspecialityEOB: DEFAULT_DD_VALUE,
    noticetextEOB: '',
    radioNTEOB: '',
    showVoids: false,
    PNRadio: '1',
    selectedPNOption: '1'
  });

  // Code to set Remark Code fields to default

  const [rCValues, setrCValues] = React.useState({
    remarkCode: '',
    remarkText: '',
    RCRadio: ''
  });

  // Code to set Adjustment Reason fields to default

  const [ARCValues, setARCValues] = React.useState({
    adjustmentRC: '',
    adjustmentRT: '',
    ARCRadio: ''
  });

  const [valueslobPN, setvalueslobPN] = React.useState({
    lobPN: DEFAULT_DD_VALUE
  });

  // Code to set HCPCS fields to default

  const [HCPCSValues, setHCPCSValues] = React.useState({
    HCPCSfromcode: '',
    HCPCSmodifier1: DEFAULT_DD_VALUE,
    HCPCSmodifier2: DEFAULT_DD_VALUE,
    HCPCSmodifier3: DEFAULT_DD_VALUE,
    HCPCSmodifier4: DEFAULT_DD_VALUE,
    HCPCSthrucode: '',
    HCPCSmodifier11: DEFAULT_DD_VALUE,
    HCPCSmodifier21: DEFAULT_DD_VALUE,
    HCPCSmodifier31: DEFAULT_DD_VALUE,
    HCPCSmodifier41: DEFAULT_DD_VALUE,
    HCPCSEOBCode: '',
    HCPCSRadio: '',
    SPRadio: '',
    RevenueRadio: '',
    lobEOMB: DEFAULT_DD_VALUE
  });

  // Code to set Surgical Procedure fields to default

  const [SPValues, setSPValues] = React.useState({
    SPfromcode: '',
    SPthrucode: '',
    SPEOMBText: ''
  });

  // Code to set Revenue fields to default

  const [revenueValues, setRevenueValues] = React.useState({
    revenuefromcode: '',
    revenuethrucode: '',
    revenueEOMBText: ''
  });

  const [values, setValues] = React.useState({
    lob: '',
    payee: '',
    payeeIdCode: '',
    id: '',
    name: '',
    systemPayeeId: '',
    multiline: 'Controlled',
    systemPayee: '',
    memberName: '',
    lobEOMB: DEFAULT_DD_VALUE,
    lobEob: DEFAULT_DD_VALUE,
    lobPN: DEFAULT_DD_VALUE
  });

  const [valuesPN1, setValuesPN1] = React.useState({
    providertypeEOB: DEFAULT_DD_VALUE
  });

  const [valueslobEOMB, setValueslobEOMB] = React.useState({
    lobEOMB: DEFAULT_DD_VALUE
  });

  const [valuesRAEOB, setValuesRAEOB] = React.useState({
    RAEob: DEFAULT_DD_VALUE
  });

  const [valuesPN2, setValuesPN2] = React.useState({
    providerspecialityEOB: DEFAULT_DD_VALUE
  });

  const [value, setValue] = React.useState(0);
  const [eombType, setEombType] = React.useState('HCPCS');
  const [dataElement, setDataElement] = React.useState({
    id: 0,
    dataElementName: '',
    businessName: '',
    description: ''
  });

  const handleChanges = name => event => {
    if (name === 'selectedOption') { sessionStorage.clear(); }
    setEombType(event.target.value);

    setValues({ ...values, [name]: event.target.value });

    setHCPCSValues({
      ...HCPCSValues,
      HCPCSfromcode: '',
      HCPCSmodifier1: DEFAULT_DD_VALUE,
      HCPCSmodifier2: DEFAULT_DD_VALUE,
      HCPCSmodifier3: DEFAULT_DD_VALUE,
      HCPCSmodifier4: DEFAULT_DD_VALUE,
      HCPCSthrucode: '',
      HCPCSmodifier11: DEFAULT_DD_VALUE,
      HCPCSmodifier21: DEFAULT_DD_VALUE,
      HCPCSmodifier31: DEFAULT_DD_VALUE,
      HCPCSmodifier41: DEFAULT_DD_VALUE,
      HCPCSEOBCode: ''

    });

    setShowHCPCS(false);
    setShowSP(false);
    setValueslobEOMB({
      lobEOMB: DEFAULT_DD_VALUE
    });

    setShowError({
      showHCPCSError: false,
      showHCPCSthruError: false,
      showFunctionalAreaError: false,
      showdataElementNameError: false,
      showbusinessNameError: false,
      showPNError: false,
      showRAEOBError: false,
      showSPError: false,
      showRevenueError: false,
      showCommonError: false
    });

    seterrorMessages([]);

    setRevenueValues({
      revenuefromcode: '',
      revenuethrucode: '',
      revenueEOMBText: ''
    });

    setShowRevenue(false);

    setSPValues({
      SPfromcode: '',
      SPthrucode: '',
      SPEOMBText: ''
    });

    setShowSP(false);
  };

  let errorMessagesArray = [];

  React.useEffect(() => {
    if (HCPCSValues.lobEOMB !== DEFAULT_DD_VALUE) {
      setShowError({ showHCPCSError: false });
      seterrorMessages([]);
    }
  }, [HCPCSValues.lobEOMB]);

  const searchProviderSpecialty = values =>
    dispatch(ProviderSpecialtyDataSearchAction(values));

  const ProviderSpecialtyData = useSelector(
    state => state.appConfigState.providerSpecialtyState.ProviderSpecialtyData
  );

  const handleChangesPN1 = name => event => {
    searchProviderSpecialty(event.target.value);
    setValuesPN2({ providerspecialityEOB: DEFAULT_DD_VALUE });
    setValuesPN1({ [name]: event.target.value });
  };

  const handleChangesPN2 = name => event => {
    setValuesPN2({ [name]: event.target.value });
  };

  const handleChangeTabs = (event, newValue) => {
    sessionStorage.clear();
    resetTableRAEOB();
    resetTableRC();
    resetTableARC();
    resetTableNCPDP();
    resetTableSP();
    resetTableRevenue();
    resetTablePN();
    resetTable();
    setValue(newValue);
    resetTableHCPCS();
  };

  const handleChangeREOB = name => event => {
    setEOBValues({ ...eobValues, [name]: event.target.value });
  };

  const handleShowVoidsChange = event => {
    setpNValues({ ...pNValues, showVoids: !pNValues.showVoids });
  };

  const handleChangePN = name => event => {
    setpNValues({ ...pNValues, [name]: event.target.value });
  };

  const handleChangeRC = name => event => {
    setrCValues({ ...rCValues, [name]: event.target.value });
  };

  const handleChangeARC = name => event => {
    setARCValues({ ...ARCValues, [name]: event.target.value });
  };

  const handleChangeNCPDP = name => event => {
    setNCPDPValues({ ...NCPDPValues, [name]: event.target.value });
  };

  React.useEffect(() => {
    if (HCPCSValues.HCPCSfromcode) {
      setFromMod(false);
    } else {
      setFromMod(true);
    }

    if (HCPCSValues.HCPCSthrucode) {
      setThruMod(false);
    } else {
      setThruMod(true);
    }
  }, [HCPCSValues.HCPCSfromcode, HCPCSValues.HCPCSthrucode, fromMod, thruMod]);

  React.useEffect(() => {
    if (valuesPN1.providertypeEOB !== DEFAULT_DD_VALUE) {
      setProviderSpeciality(false);
    } else {
      setProviderSpeciality(true);
    }
  }, [valuesPN1.providertypeEOB, providerSpeciality]);

  const handleChangeHCPCS = name => event => {
    setHCPCSValues({ ...HCPCSValues, [name]: event.target.value });

    if (
      HCPCSValues.selectedHCPCSOption &&
      HCPCSValues.HCPCSEOBCode.length >= 1
    ) {
      setShowError({ showCommonError: false });

      seterrorMessages(errorMessagesArray);
    }
  };

  const handleChangeRevenue = name => event => {
    setRevenueValues({ ...revenueValues, [name]: event.target.value });
  };

  const handleChangeSP = name => event => {
    setSPValues({ ...SPValues, [name]: event.target.value });
  };

  // Code to reset RA EOB fields

  const resetTableRAEOB = () => {
    seterrorMessages([]);
    setSuccessMessage([]);
    setDisplayTable([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setEOBValues({
      eobCode: '',

      eobText: ''
    });

    setShowRAEOB(false);

    setValuesRAEOB({
      RAEob: DEFAULT_DD_VALUE
    });

    setShowError({ showRAEOBError: false });
  };

  // Code to reset Remark Code fields

  const resetTableRC = () => {
    seterrorMessages([]);
    setSuccessMessage([]);
    setDisplayTable([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setrCValues({
      remarkCode: '',

      remarkText: ''
    });

    setShowPC(false);

    setShowError({ showbusinessNameError: false });
  };

  // Code to reset Adjustemtn Reason fields

  const resetTableARC = () => {
    seterrorMessages([]);
    setSuccessMessage([]);
    setDisplayTable([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setARCValues({
      adjustmentRC: '',

      adjustmentRT: ''
    });

    setShowAR(false);

    setShowError({ showdataElementNameError: false });
  };

  // Code to reset NCPDP fields

  const resetTableNCPDP = () => {
    seterrorMessages([]);
    setSuccessMessage([]);
    setDisplayTable([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setNCPDPValues({
      NCPDPRejectCode: '',

      NCPDPRejectCodeText: '',

      NCPDPRadio: ''
    });

    setShowSAR(false);

    setShowError({ showFunctionalAreaError: false });
  };

  // Code to reset HCPCS fields

  const resetTableHCPCS = () => {
    seterrorMessages([]);
    setSuccessMessage([]);
    setDisplayTable([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setHCPCSValues({
      HCPCSfromcode: '',

      HCPCSmodifier1: DEFAULT_DD_VALUE,

      HCPCSmodifier2: DEFAULT_DD_VALUE,

      HCPCSmodifier3: DEFAULT_DD_VALUE,

      HCPCSmodifier4: DEFAULT_DD_VALUE,

      HCPCSthrucode: '',

      HCPCSmodifier11: DEFAULT_DD_VALUE,

      HCPCSmodifier21: DEFAULT_DD_VALUE,

      HCPCSmodifier31: DEFAULT_DD_VALUE,

      HCPCSmodifier41: DEFAULT_DD_VALUE,

      HCPCSEOBCode: '',

      lobEOMB: DEFAULT_DD_VALUE
    });

    setShowHCPCS(false);

    setValueslobEOMB({
      lobEOMB: DEFAULT_DD_VALUE
    });

    setShowError({ showHCPCSError: false, showHCPCSthruError: false });
  };

  // Code to reset Surgical Procedure fields

  const resetTableSP = () => {
    seterrorMessages([]);
    setSuccessMessage([]);
    setDisplayTable([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setSPValues({
      SPfromcode: '',

      SPthrucode: '',

      SPEOMBText: ''
    });

    setShowSP(false);

    setHCPCSValues({
      lobEOMB: DEFAULT_DD_VALUE,

      HCPCSEOBCode: ''
    });

    setShowError({ showSPError: false });
  };

  // Code to reset Revenue fields

  const resetTableRevenue = () => {
    seterrorMessages([]);
    setSuccessMessage([]);
    setDisplayTable([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setRevenueValues({
      revenuefromcode: '',

      revenuethrucode: '',

      revenueEOMBText: ''
    });

    setShowRevenue(false);

    setHCPCSValues({
      lobEOMB: DEFAULT_DD_VALUE,

      HCPCSEOBCode: ''
    });

    setShowError({ showRevenueError: false });
  };

  // Code to reset Provider Notice fields

  const resetTablePN = () => {
    seterrorMessages([]);
    setSuccessMessage([]);
    setDisplayTable([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setpNValues({
      lobPN: DEFAULT_DD_VALUE,

      providertypeEOB: '',

      providerspecialityEOB: '',

      noticetextEOB: '',

      radioNTEOB: '',

      showVoids: false,

      PNRadio: '1',

      selectedPNOption: '1'
    });

    setShowVoid(false);

    setShowPN(false);

    setValues({
      lobPN: DEFAULT_DD_VALUE
    });

    setValuesPN1({
      providertypeEOB: DEFAULT_DD_VALUE
    });

    setValuesPN2({
      providerspecialityEOB: DEFAULT_DD_VALUE
    });

    setvalueslobPN({
      lobPN: DEFAULT_DD_VALUE
    });

    setShowError({ showPNError: false });
  };

  const resetTable = () => {
    seterrorMessages([]);

    setShowError({
      showFunctionalAreaError: false,

      showdataElementNameError: false,

      showbusinessNameError: false,

      showPNError: false,

      showRAEOBError: false,

      showHCPCSError: false,

      showHCPCSthruError: false,

      showSPError: false,

      showRevenueError: false,

      showCommonError: false
    });

    setValues({
      lob: '',

      payee: '',

      payeeIdCode: '',

      id: '',

      name: '',

      systemPayeeId: '',

      multiline: 'Controlled',

      systemPayee: '',

      memberName: ''
    });

    setShowForm(false);
  };

  const validvaluecons = ErrorConstants;

  const [errorMessages, seterrorMessages] = React.useState([]);

  const [
    {
      showFunctionalAreaError,
      showdataElementNameError,
      showbusinessNameError,
      showHCPCSthruError,
      showCommonError
    },
    setShowError
  ] = React.useState(false);

  const searchCheckNCPDP = NCPDPValues => {
    errorMessagesArray = [];
    setSuccessMessage([]);
    seterrorMessages([]);
    props.location.tableRender = false;
    var showCommonError;
    var showFunctionalAreaError = false;

    if (
      NCPDPValues.selectedNCPDPOption &&
      (!NCPDPValues.NCPDPRejectCodeText ||
        NCPDPValues.NCPDPRejectCodeText.trim().length < 2)
    ) {
      showCommonError = true;

      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);

      seterrorMessages(errorMessagesArray);

      setShowSAR(false);
    }

    if (!NCPDPValues.NCPDPRejectCode && !NCPDPValues.NCPDPRejectCodeText) {
      showFunctionalAreaError = true;

      errorMessagesArray.push(validvaluecons.DATA_ELEMENT_NAME_ERROR);

      seterrorMessages(errorMessagesArray);
      setShowSAR(false);
    }

    if (errorMessagesArray.length <= 0) {
      setspinnerLoader(true);
      const searchCriteria = {
        ncpdpRejectCode: NCPDPValues.NCPDPRejectCode
          ? NCPDPValues.NCPDPRejectCode
          : null,
        ncpdpRejectCodeText: NCPDPValues.NCPDPRejectCodeText
          ? NCPDPValues.NCPDPRejectCodeText
          : null,
        ncpdpRejectCodeStartsOrCopntains: NCPDPValues.selectedNCPDPOption
          ? NCPDPValues.selectedNCPDPOption
          : null
      };
      onSearchNCPDP(searchCriteria);

      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirectNCPDP(valuetoredirect);

      if (payloadData) {
        setShowSAR(true);
        sessionStorage.setItem('ComponentName', 'setShowSAR');
      }
      setIsSearch(true);
      sessionStorage.setItem('SearchTextCriteria', JSON.stringify(searchCriteria));
      sessionStorage.setItem('TableType', 'ncpdp');
      sessionStorage.setItem('TabValue', 5);
      sessionStorage.setItem('textSetValues', JSON.stringify(NCPDPValues));
    }

    setShowError({
      showFunctionalAreaError: showFunctionalAreaError,
      showCommonError: showCommonError
    });
    setTableType('ncpdp');
    setShowSAR(false);
  };

  const searchCheckARC = ARCValues => {
    props.location.tableRender = false;
    errorMessagesArray = [];
    setSuccessMessage([]);
    seterrorMessages([]);

    var showdataElementNameError;
    var showCommonError = false;

    if (
      ARCValues.selectedARCOption &&
      (!ARCValues.adjustmentRT || ARCValues.adjustmentRT.trim().length < 2)
    ) {
      showCommonError = true;

      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);

      seterrorMessages(errorMessagesArray);

      setShowAR(false);
    }

    if (!ARCValues.adjustmentRC && !ARCValues.adjustmentRT) {
      showdataElementNameError = true;

      errorMessagesArray.push(validvaluecons.DATA_ELEMENT_NAME_ERROR);

      seterrorMessages(errorMessagesArray);

      setShowAR(false);
    }

    if (errorMessagesArray.length <= 0) {
      setspinnerLoader(true);

      const searchCriteria = {
        adjustmentReasonCode: ARCValues.adjustmentRC
          ? ARCValues.adjustmentRC
          : null,

        adjustmentReasonText: ARCValues.adjustmentRT
          ? ARCValues.adjustmentRT
          : null,

        adjustmentReasonTextStartsOrContains: ARCValues.selectedARCOption
          ? ARCValues.selectedARCOption
          : null
      };

      onSearchARC(searchCriteria);

      let valuetoredirect = 0;

      valuetoredirect = valuetoredirect + 1;

      setRedirectAR(valuetoredirect);

      if (payloadData) {
        setShowAR(true);
        sessionStorage.setItem('ComponentName', 'setShowAR');
      }
      setIsSearch(true);
      sessionStorage.setItem('SearchTextCriteria', JSON.stringify(searchCriteria));
      sessionStorage.setItem('TableType', 'ar');
      sessionStorage.setItem('textSetValues', JSON.stringify(ARCValues));
	  sessionStorage.setItem('TabValue', 4);
    }

    setShowError({
      showdataElementNameError: showdataElementNameError,

      showCommonError: showCommonError
    });
    setTableType('ar');
    setShowAR(false);
  };

  const searchCheckRC = rCValues => {
    errorMessagesArray = [];
    setSuccessMessage([]);
    seterrorMessages([]);
    props.location.tableRender = false;
    var showbusinessNameError;
    var showCommonError = false;

    if (
      rCValues.selectedRCOption &&
      (!rCValues.remarkText || rCValues.remarkText.trim().length < 2)
    ) {
      showCommonError = true;

      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);

      seterrorMessages(errorMessagesArray);

      setShowAR(false);
    }

    if (!rCValues.remarkCode && !rCValues.remarkText) {
      showbusinessNameError = true;

      errorMessagesArray.push(validvaluecons.DATA_ELEMENT_NAME_ERROR);

      seterrorMessages(errorMessagesArray);

      setShowPC(false);
    }

    if (errorMessagesArray.length <= 0) {
      setspinnerLoader(true);

      const searchCriteria = {
        remarkCode: rCValues.remarkCode ? rCValues.remarkCode : null,

        remarkText: rCValues.remarkText ? rCValues.remarkText : null,

        remarkTextStartsOrContains: rCValues.selectedRCOption
          ? rCValues.selectedRCOption
          : null
      };

      onSearchRC(searchCriteria);

      let valuetoredirect = 0;

      valuetoredirect = valuetoredirect + 1;

      setRedirectRC(valuetoredirect);

      if (payloadData) {
        setShowPC(true);
        sessionStorage.setItem('ComponentName', 'setShowPC');
      }
      setIsSearch(true);
      sessionStorage.setItem('SearchTextCriteria', JSON.stringify(searchCriteria));
      sessionStorage.setItem('TableType', 'rc');
      sessionStorage.setItem('textSetValues', JSON.stringify(rCValues));
	  sessionStorage.setItem('TabValue', 3);
    }

    setShowError({
      showbusinessNameError: showbusinessNameError,

      showCommonError: showCommonError
    });
    setTableType('rc');
    setShowPC(false);
  };

  const searchCheckPN = valueslobPN => {
    props.location.tableRender = false;
    setSuccessMessage([]);
    errorMessagesArray = [];
    setspinnerLoader(false);
    seterrorMessages([]);
    var showPNError;
    var showCommonError = false;
    if (
      (pNValues.noticetextEOB && pNValues.noticetextEOB.trim().length < 2) && pNValues.selectedPNOption
    ) {
      showCommonError = true;
      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowAR(false);
    }

    if (errorMessagesArray.length <= 0) {
      setspinnerLoader(true);
      const searchCriteria = {
        providerTypeCode:
          valuesPN1.providertypeEOB !== DEFAULT_DD_VALUE
            ? valuesPN1.providertypeEOB
            : null,

        providerSpecialityCode:
          valuesPN2.providerspecialityEOB !== DEFAULT_DD_VALUE
            ? valuesPN2.providerspecialityEOB
            : null,

        providerNoticeText: pNValues.noticetextEOB
          ? pNValues.noticetextEOB
          : null,

        providerNoticeTextStartsOrContains: pNValues.selectedPNOption
          ? pNValues.selectedPNOption
          : '1',
        lobCode: [APP_CONSTANTS.DEFAULT_LOB_VALUE],
        showVoids: pNValues.showVoids
      };

      onSearchPN(searchCriteria);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirectPN(valuetoredirect);
      if (payloadData) {
        sessionStorage.setItem('ComponentName', 'setShowPN');
        setShowPN(true);
      }
      setIsSearch(true);
      sessionStorage.setItem('SearchTextCriteria', JSON.stringify(searchCriteria));
      sessionStorage.setItem('TabValue', 2);
      sessionStorage.setItem('TableType', 'pn');
      sessionStorage.setItem('valuesPN1', JSON.stringify(valuesPN1));
      sessionStorage.setItem('textSetValues', JSON.stringify(pNValues));
      sessionStorage.setItem('valuesPN2', JSON.stringify(valuesPN2));
      sessionStorage.setItem('valueslobPN', JSON.stringify(valueslobPN));
    }
    setShowError({
      showPNError: showPNError,

      showCommonError
    });
    setTableType('pn');
    setShowPN(false);
  };

  const searchCheckRAEOB = valuesRAEOB => {
    props.location.tableRender = false;
    setSuccessMessage([]);
    errorMessagesArray = [];
    setspinnerLoader(false);
    seterrorMessages([]);
    var showRAEOBError;
    var showCommonError = false;
    if (
      eobValues.selectedRAEOBOption &&
      (!eobValues.eobText || eobValues.eobText.trim().length < 2)
    ) {
      showCommonError = true;

      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);

      seterrorMessages(errorMessagesArray);

      setShowAR(false);
    }

    if (errorMessagesArray.length <= 0) {
      setspinnerLoader(true);

      const searchCriteria = {
        lobCode: [APP_CONSTANTS.DEFAULT_LOB_VALUE],
        claimEOBCode: eobValues.eobCode,
        eobText: eobValues.eobText,
        eobTextStartsOrContains: eobValues.selectedRAEOBOption
      };

      onSearchRAEOB(searchCriteria);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirectRAEOB(valuetoredirect);

      if (payloadData) {
        setShowRAEOB(true);
        sessionStorage.setItem('ComponentName', 'setShowRAEOB');
      }
      setIsSearch(true);
      sessionStorage.setItem('SearchTextCriteria', JSON.stringify(searchCriteria));
      sessionStorage.setItem('TableType', 'raeob');
      sessionStorage.setItem('textSetValues', JSON.stringify(eobValues));
      sessionStorage.setItem('valuesRAEOB', valuesRAEOB);
	    sessionStorage.setItem('TabValue', 1);
    }

    setShowError({
      showRAEOBError: showRAEOBError,

      showCommonError: showCommonError
    });
    setTableType('raeob');
    setShowRAEOB(false);
  };

  const searchCheckHCPCS = HCPCSValues => {
    props.location.tableRender = false;
    errorMessagesArray = [];
    setSuccessMessage([]);
    setShowHCPCS(false);
    seterrorMessages([]);
    setspinnerLoader(false);
    var showHCPCSError;
    var showHCPCSthruError;
    var showCommonError = false;
    if (HCPCSValues.HCPCSthrucode) {
      if (HCPCSValues.HCPCSfromcode === '') {
        showHCPCSthruError = true;
        errorMessagesArray.push(validvaluecons.FUNCTIONAL_AREA_REQUIRED);
        seterrorMessages(errorMessagesArray);
        setShowHCPCS(false);
      }
    }

    if (
      HCPCSValues.selectedHCPCSOption &&
      (!HCPCSValues.HCPCSEOBCode || HCPCSValues.HCPCSEOBCode.trim().length < 2)
    ) {
      showCommonError = true;
      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowHCPCS(false);
    }

    if (errorMessagesArray.length === 0) {
      setspinnerLoader(true);
      const searchCriteria = {
        eombProcedureTypeCode: 'H',
        eombFromCode: HCPCSValues.HCPCSfromcode,
        eombThruCode: HCPCSValues.HCPCSthrucode
          ? HCPCSValues.HCPCSthrucode
          : null,
        lobCode: [APP_CONSTANTS.DEFAULT_LOB_VALUE],
        eombText: HCPCSValues.HCPCSEOBCode,
        fromModifier1: HCPCSValues.HCPCSmodifier1 !== DEFAULT_DD_VALUE ? HCPCSValues.HCPCSmodifier1 : null,
        fromModifier2: HCPCSValues.HCPCSmodifier2 !== DEFAULT_DD_VALUE ? HCPCSValues.HCPCSmodifier2 : null,
        fromModifier3: HCPCSValues.HCPCSmodifier3 !== DEFAULT_DD_VALUE ? HCPCSValues.HCPCSmodifier3 : null,
        fromModifier4: HCPCSValues.HCPCSmodifier4 !== DEFAULT_DD_VALUE ? HCPCSValues.HCPCSmodifier4 : null,
        thruModifier1: HCPCSValues.HCPCSmodifier11 !== DEFAULT_DD_VALUE ? HCPCSValues.HCPCSmodifier11 : null,
        thruModifier2: HCPCSValues.HCPCSmodifier21 !== DEFAULT_DD_VALUE ? HCPCSValues.HCPCSmodifier21 : null,
        thruModifier3: HCPCSValues.HCPCSmodifier31 !== DEFAULT_DD_VALUE ? HCPCSValues.HCPCSmodifier31 : null,
        thruModifier4: HCPCSValues.HCPCSmodifier41 !== DEFAULT_DD_VALUE ? HCPCSValues.HCPCSmodifier41 : null,
        eombTextStartsOrContains: HCPCSValues.selectedHCPCSOption
      };
      onSearch(searchCriteria);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
      if (payloadData) {
        setShowHCPCS(true);
        sessionStorage.setItem('ComponentName', 'setShowHCPCS');
      }
      setIsSearch(true);
      sessionStorage.setItem('SearchTextCriteria', JSON.stringify(searchCriteria));
      sessionStorage.setItem('TableType', 'hcpcs');
      sessionStorage.setItem('textSetValues', JSON.stringify(HCPCSValues));
	  sessionStorage.setItem('TabValue', 0);
    }

    setShowError({
      showHCPCSError: showHCPCSError,

      showHCPCSthruError: showHCPCSthruError,

      showCommonError: showCommonError
    });
    setTableType('hcpcs');
    setShowHCPCS(false);
  };

  const searchCheckSP = HCPCSValues => {
    props.location.tableRender = false;
    setSuccessMessage([]);

    errorMessagesArray = [];

    setspinnerLoader(false);

    seterrorMessages([]);

    var showSPError;
    var showCommonError;
    var showHCPCSthruError = false;

    if (
      HCPCSValues.selectedHCPCSOption &&
      (!HCPCSValues.HCPCSEOBCode || HCPCSValues.HCPCSEOBCode.length < 2)
    ) {
      showCommonError = true;

      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);

      seterrorMessages(errorMessagesArray);

      setShowAR(false);
    }

    if (SPValues.SPthrucode) {
      if (SPValues.SPfromcode === '') {
        showHCPCSthruError = true;

        errorMessagesArray.push(validvaluecons.FUNCTIONAL_AREA_REQUIRED);

        seterrorMessages(errorMessagesArray);

        setShowHCPCS(false);
      }
    }

    if (errorMessagesArray.length <= 0) {
      setspinnerLoader(true);

      const searchCriteria = {
        eombProcedureTypeCode: 'I',

        eombFromCode: SPValues.SPfromcode ? SPValues.SPfromcode : null,

        eombThruCode: SPValues.SPthrucode ? SPValues.SPthrucode : null,

        lobCode: [APP_CONSTANTS.DEFAULT_LOB_VALUE],

        eombText: HCPCSValues.HCPCSEOBCode ? HCPCSValues.HCPCSEOBCode : null,

        eombTextStartsOrContains: HCPCSValues.selectedHCPCSOption
          ? HCPCSValues.selectedHCPCSOption
          : null
      };

      onSearchSP(searchCriteria);

      let valuetoredirect = 0;

      valuetoredirect = valuetoredirect + 1;

      setRedirect(valuetoredirect);

      if (payloadData) {
        setShowSP(true);
        sessionStorage.setItem('ComponentName', 'setShowSP');
      }
      setIsSearch(true);
      sessionStorage.setItem('SearchTextCriteria', JSON.stringify(searchCriteria));
      sessionStorage.setItem('TableType', 'sp');
      sessionStorage.setItem('textSetValues', JSON.stringify(SPValues));
      sessionStorage.setItem('HCPCSValues', JSON.stringify(HCPCSValues));
      sessionStorage.setItem('TabValue', 0);
    }

    setShowError({
      showSPError: showSPError,

      showCommonError: showCommonError,

      showHCPCSthruError: showHCPCSthruError
    });
    setTableType('sp');
    setShowSP(false);
  };

  const searchCheckRevenue = (HCPCSValues) => {
    props.location.tableRender = false;
    setSuccessMessage([]);
    errorMessagesArray = [];

    setspinnerLoader(false);

    seterrorMessages([]);

    var showRevenueError;
    var showCommonError;
    var showHCPCSthruError = false;

    if (
      HCPCSValues.selectedHCPCSOption &&
      (!HCPCSValues.HCPCSEOBCode || HCPCSValues.HCPCSEOBCode.length < 2)
    ) {
      showCommonError = true;

      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);

      seterrorMessages(errorMessagesArray);

      setShowAR(false);
    }

    if (revenueValues.revenuethrucode) {
      if (revenueValues.revenuefromcode === '') {
        showHCPCSthruError = true;

        errorMessagesArray.push(validvaluecons.FUNCTIONAL_AREA_REQUIRED);

        seterrorMessages(errorMessagesArray);

        setShowHCPCS(false);
      }
    }

    if (errorMessagesArray.length <= 0) {
      setspinnerLoader(true);
      const searchCriteria = {
        eombProcedureTypeCode: 'R',
        eombFromCode: revenueValues.revenuefromcode,
        eombThruCode: revenueValues.revenuethrucode,
        lobCode: [APP_CONSTANTS.DEFAULT_LOB_VALUE],
        eombText: HCPCSValues.HCPCSEOBCode,
        eombTextStartsOrContains: HCPCSValues.selectedHCPCSOption
      };
      onSearchRevenue(searchCriteria);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
      if (payloadData) {
        setShowRevenue(true);
        sessionStorage.setItem('ComponentName', 'setShowRevenue');
      };
      setIsSearch(true);
      sessionStorage.setItem('SearchTextCriteria', JSON.stringify(searchCriteria));
      sessionStorage.setItem('TableType', 'revenue');
      sessionStorage.setItem('TabValue', 0);
      sessionStorage.setItem('textSetValues', JSON.stringify(revenueValues));
      sessionStorage.setItem('HCPCSValues', JSON.stringify(HCPCSValues));
    }

    setShowError({
      showRevenueError: showRevenueError,

      showCommonError: showCommonError,

      showHCPCSthruError: showHCPCSthruError
    });
    setTableType('revenue');
    setShowRevenue(false);
  };

  // Switch Cases to display forms based on user selection from HCPCS, Surgical Process and Revenue

  const renderSwitch = eombType => {
    switch (eombType) {
      case 'HCPCS':
        return (
          <div id="hcpcs">
            <div className="form-wrapper">
              <div className="mui-custom-form override-width-19 override-m-05">
                <TextField
                  id="HCPCSfromcode"
                  fullWidth
                  label="From Code"
                  inputProps={{ maxlength: 10 }}
                  value={HCPCSValues.HCPCSfromcode}
                  error={
                    showHCPCSthruError
                      ? ErrorConstants.FUNCTIONAL_AREA_REQUIRED
                      : null
                  }
                  helperText={
                    showHCPCSthruError
                      ? ErrorConstants.FUNCTIONAL_AREA_REQUIRED
                      : null
                  }
                  onChange={handleChangeHCPCS('HCPCSfromcode')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

                <NativeDropDown
                  id="HCPCSmodifier1"
                  className='MuiTextField-root override-width-19 override-m-05'
                  label="Modifier 1"
                  inputProps={{ maxlength: 2 }}
                  value={HCPCSValues.HCPCSmodifier1}
                  onChange={handleChangeHCPCS('HCPCSmodifier1')}
                  disabled={fromMod}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={modifierData
                    ? modifierData.map(option => (
                      <option key={option} className="LOB" value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />

                <NativeDropDown
                  id="HCPCSmodifier2"
                  className='MuiTextField-root override-width-19 override-m-05'
                  label="Modifier 2"
                  inputProps={{ maxlength: 2 }}
                  disabled={fromMod}
                  value={HCPCSValues.HCPCSmodifier2}
                  onChange={handleChangeHCPCS('HCPCSmodifier2')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={modifierData
                    ? modifierData.map(option => (
                      <option key={option} className="LOB" value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
                <NativeDropDown
                  id="HCPCSmodifier3"
                  className='MuiTextField-root override-width-19 override-m-05'
                  label="Modifier 3"
                  inputProps={{ maxlength: 2 }}
                  value={HCPCSValues.HCPCSmodifier3}
                  disabled={fromMod}
                  onChange={handleChangeHCPCS('HCPCSmodifier3')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={modifierData
                    ? modifierData.map(option => (
                      <option key={option} className="LOB" value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />

                <NativeDropDown
                  id="HCPCSmodifier4"
                  className='MuiTextField-root override-width-19 override-m-05'
                  label="Modifier 4"
                  inputProps={{ maxlength: 2 }}
                  value={HCPCSValues.HCPCSmodifier4}
                  disabled={fromMod}
                  onChange={handleChangeHCPCS('HCPCSmodifier4')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={modifierData
                    ? modifierData.map(option => (
                      <option key={option} className="LOB" value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
            </div>

            <div className="form-wrapper">
              <div className="mui-custom-form override-width-19 override-m-05">
                <TextField
                  id="HCPCSthrucode"
                  fullWidth
                  label="Thru Code"
                  inputProps={{ maxlength: 10 }}
                  value={HCPCSValues.HCPCSthrucode}
                  onChange={handleChangeHCPCS('HCPCSthrucode')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

                <NativeDropDown
                  id="HCPCSmodifier11"
                  className='MuiTextField-root override-width-19 override-m-05'
                  label="Modifier 1"
                  inputProps={{ maxlength: 2 }}
                  disabled={thruMod}
                  value={HCPCSValues.HCPCSmodifier11}
                  onChange={handleChangeHCPCS('HCPCSmodifier11')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={modifierData
                    ? modifierData.map(option => (
                      <option key={option} className="LOB" value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />

                <NativeDropDown
                  id="HCPCSmodifier21"
                  className='MuiTextField-root override-width-19 override-m-05'
                  label="Modifier 2"
                  inputProps={{ maxlength: 2 }}
                  value={HCPCSValues.HCPCSmodifier21}
                  disabled={thruMod}
                  onChange={handleChangeHCPCS('HCPCSmodifier21')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={modifierData
                    ? modifierData.map(option => (
                      <option key={option} className="LOB" value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />

                <NativeDropDown
                  id="HCPCSmodifier31"
                  className='MuiTextField-root override-width-19 override-m-05'
                  label="Modifier 3"
                  inputProps={{ maxlength: 2 }}
                  value={HCPCSValues.HCPCSmodifier31}
                  disabled={thruMod}
                  onChange={handleChangeHCPCS('HCPCSmodifier31')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={modifierData
                    ? modifierData.map(option => (
                      <option key={option} className="LOB" value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />

                <NativeDropDown
                  id="HCPCSmodifier41"
                  className='MuiTextField-root override-width-19 override-m-05'
                  label="Modifier 4"
                  inputProps={{ maxlength: 2 }}
                  value={HCPCSValues.HCPCSmodifier41}
                  onChange={handleChangeHCPCS('HCPCSmodifier41')}
                  disabled={thruMod}
                  InputLabelProps={{
                    shrink: true
                  }}
                  dropdownList={modifierData
                    ? modifierData.map(option => (
                      <option key={option} className="LOB" value={option}>
                        {option}
                      </option>
                    ))
                    : null}
                />
            </div>

            <div className="float-right m-3">
              <Button
                className="btn-tab-pannel"
                onClick={() => searchCheckHCPCS(HCPCSValues)}
              >
                <i class="fa fa-search" aria-hidden="true"></i>
                Search
              </Button>

              <Button
                variant="outlined"
                color="primary"
                className="bt-tabreset btn-transparent ml-1"
                onClick={() => resetTableHCPCS()}
              >
                <i class="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
            </div>

            <div className="clearfix"></div>

            <div className="tab-holder pb-1">
              {showHCPCS && displayTable && displayTable.length > 0 ? (
                <EnhancedTableHead tableData={displayTable || []} setspinnerLoader={setspinnerLoader} />
              ) : null}
            </div>
          </div>
        );

      case 'Surgical Procedure':
        return (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              <div className="mui-custom-form override-m-06">
                <TextField
                  id="SPfromcode"
                  fullWidth
                  label="From Code"
                  inputProps={{ maxlength: 10 }}
                  error={
                    showHCPCSthruError
                      ? ErrorConstants.FUNCTIONAL_AREA_REQUIRED
                      : null
                  }
                  helperText={
                    showHCPCSthruError
                      ? ErrorConstants.FUNCTIONAL_AREA_REQUIRED
                      : null
                  }
                  value={SPValues.SPfromcode}
                  onChange={handleChangeSP('SPfromcode')}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

              <div className="mui-custom-form override-m-06">
                <TextField
                  id="SPthrucode"
                  fullWidth
                  label="Thru Code"
                  value={SPValues.SPthrucode}
                  onChange={handleChangeSP('SPthrucode')}
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </div>

            <div className="float-right m-3">
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary"
                onClick={() => searchCheckSP(HCPCSValues)}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
                Search
              </Button>

              <Button
                variant="outlined"
                color="primary"
                className="bt-tabreset btn-transparent ml-1"
                onClick={() => resetTableSP()}
              >
                <i className="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
            </div>

            <div className="clearfix"></div>

            <div className="tab-holder">
              {showSP && displayTable && displayTable.length > 0 ? (
                <EnhancedTableHead1 tableData={displayTable || []} setspinnerLoader={setspinnerLoader} />
              ) : null}
            </div>
          </div>
        );

      case 'Revenue':
        return (
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap'
              }}
            >
              <div className="mui-custom-form override-m-06">
                <TextField
                  id="revenuefromcode"
                  fullWidth
                  label="From Code"
                  value={revenueValues.revenuefromcode}
                  onChange={handleChangeRevenue('revenuefromcode')}
                  error={
                    showHCPCSthruError
                      ? ErrorConstants.FUNCTIONAL_AREA_REQUIRED
                      : null
                  }
                  helperText={
                    showHCPCSthruError
                      ? ErrorConstants.FUNCTIONAL_AREA_REQUIRED
                      : null
                  }
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>

              <div className="mui-custom-form override-m-06">
                <TextField
                  id="revenuethrucode"
                  fullWidth
                  label="Thru Code"
                  value={revenueValues.revenuethrucode}
                  onChange={handleChangeRevenue('revenuethrucode')}
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </div>

            <div className="float-right m-3">
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary"
                onClick={() => searchCheckRevenue(HCPCSValues)}
              >
                <i className="fa fa-search" aria-hidden="true"></i>
                Search
              </Button>

              <Button
                variant="outlined"
                color="primary"
                className="bt-tabreset btn-transparent ml-1"
                onClick={() => resetTableRevenue()}
              >
                <i className="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
            </div>

            <div className="clearfix"></div>

            <div className="tab-holder">
              {payload && payload.length === 1 && !props.location.tableRender
                ? (props.history.push({
                  pathname: '/TextUpdate',
                  state: {
                    row: payload[0],
                    TextType: 'EOMB',
                    EombType: 'revenue'
                  }
                })
                ) : (showRevenue && displayTable && displayTable.length > 0 ? (
                  <EnhancedTableHead2 tableData={displayTable || []} setspinnerLoader={setspinnerLoader} />
                ) : null)}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const DelResult = useSelector(
    state => state.appConfigState.textManagementState.DelResult
  );

  const addTextManagement = () => {
    sessionStorage.clear();
    props.history.push({
      pathname: '/TextAdd'
    });
  };

  // Code to display tabular options on main screen

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      seterrorMessages([]);
      const ComponentName = sessionStorage.getItem('ComponentName');
      const SearchTextCriteria = JSON.parse(sessionStorage.getItem('SearchTextCriteria'));
      const textSetValues = JSON.parse(sessionStorage.getItem('textSetValues'));
      const ValuesPN1 = JSON.parse(sessionStorage.getItem('valuesPN1'));
      const ValuesPN2 = JSON.parse(sessionStorage.getItem('valuesPN2'));
      const valueslobPN = JSON.parse(sessionStorage.getItem('valueslobPN'));
      const tabValue = Number(sessionStorage.getItem('TabValue'));
      const TableType = sessionStorage.getItem('TableType');
      const HCPCSValues = JSON.parse(sessionStorage.getItem('HCPCSValues'));
      setTableType(TableType);
      setValue(tabValue);
      setspinnerLoader(true);

      switch (ComponentName) {
        case 'setShowSAR':
          onSearchNCPDP(SearchTextCriteria);
          setNCPDPValues(textSetValues);
          setShowSAR(true);
          break;
        case 'setShowAR':
          onSearchARC(SearchTextCriteria);
          setARCValues(textSetValues);
          setShowAR(true);
          break;
        case 'setShowPC':
          onSearchRC(SearchTextCriteria);
          setrCValues(textSetValues);
          setShowPC(true);
          break;
        case 'setShowPN':
          onSearchPN(SearchTextCriteria);
          setValuesPN1(ValuesPN1);
          setValuesPN2(ValuesPN2);
          setpNValues(textSetValues);
	        setvalueslobPN(valueslobPN);
          setShowPN(true);
          break;
        case 'setShowRAEOB':
          onSearchRAEOB(SearchTextCriteria);
          setValuesRAEOB({ RAEob: 'MED' });
          setEOBValues(textSetValues);
          setShowRAEOB(true);
          break;
        case 'setShowHCPCS':
          onSearch(SearchTextCriteria);
          setHCPCSValues(textSetValues);
          setShowHCPCS(true);
          setEombType('HCPCS');
          break;
        case 'setShowSP':
          onSearchSP(SearchTextCriteria);
          setSPValues(textSetValues);
          setHCPCSValues(HCPCSValues);
          setEombType('Surgical Procedure');
          setShowSP(true);
          break;
        case 'setShowRevenue':
          onSearchRevenue(SearchTextCriteria);
          setRevenueValues(textSetValues);
          setHCPCSValues(HCPCSValues);
          setEombType('Revenue');
          setShowRevenue(true);
          break;
        default:
          sessionStorage.clear();
          break;
      }
    }
  }, [props.location.tableRender]);

  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}
      {errorMessages.length > 0 ? (
        <div className="alert alert-danger custom-alert" role="alert">
          {errorMessages.map(message => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      <SuccessMessages successMessages={SuccessMessage} />
      <div className="tabs-container">
        <div className="tab-header">
          <div className="tab-heading float-left">Search Text Management</div>
          <div className="float-right mt-1 pt-1">
            <Button
              title="Add Text Management"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addTextManagement()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="tab-body" style={{ padding: '5px' }}>
          <div className="tab-panelbody">
            <div className="tab-holder my-3">
              <AppBar position="static">
                <Tabs
                  variant="fullWidth"
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                  className="tabChange"
                >
                  {/* <Tab label="Location" /> */}

                  <Tab classes={{ root: classes.tab100 }} label="EOMB" />

                  <Tab
                    classes={{ root: classes.tab100 }}
                    label="RA EOB"
                    style={{ marginLeft: '-25px' }}
                    data-testid="RAEOB_tab"
                  />

                  <Tab
                    classes={{ root: classes.tab100 }}
                    label="Provider Notice"
                    data-testid="providerNotice_tab"
                  />

                  <Tab classes={{ root: classes.tab100 }} label="Remark Code" />

                  <Tab
                    classes={{ root: classes.tab100 }}
                    label="Adjustment Reason"
                  />

                  <Tab
                    classes={{ root: classes.tab100 }}
                    label="NCPDP Reject Codes"
                    style={{ marginRight: '25px' }}
                  />

                  {/* <Tab label="Refund Information" />

                        <Tab label="History" /> */}
                </Tabs>
              </AppBar>

              {/* code for first tab EOMB */}

              <TabPanel value={value} index={0}>
                <div className="tab-holder p-0">
                  <form autoComplete="off">
                    <div className="form-wrapper">

                      <div
                        className="mui-custom-form override-width-38 override-m-06"
                        style={{ lineHeight: '100%' }}
                      >
                        <label className="MuiFormLabel-root small-label">
                          EOMB Type<span>*</span>
                        </label>

                        <div className="sub-radio">
                          <Radio
                            value="HCPCS"
                            id="hcpcs"
                            name="eombType"
                            onChange={handleChanges('selectedOption')}
                            checked={eombType === 'HCPCS'}
                            style={{ marginLeft: '0px' }}
                          />
                          <label className="text-black"
                            htmlFor="hcpcs"
                            style={{ marginLeft: '-2px' }}
                          >
                            HCPCS
                          </label>
                          <Radio
                            value="Surgical Procedure"
                            id="surgical"
                            className="ml-2"
                            style={{ marginRight: '2px' }}
                            name="eombType"
                            onChange={handleChanges('selectedOption')}
                            checked={eombType === 'Surgical Procedure'}
                          />
                          <label className="text-black" htmlFor="surgical">Surgical Procedure</label>{' '}

                          <Radio
                            value="Revenue"
                            id="revenue"
                            className="ml-2"
                            style={{ marginRight: '2px' }}
                            name="eombType"
                            onChange={handleChanges('selectedOption')}
                            checked={eombType === 'Revenue'}
                          />
                          <label className="text-black" htmlFor="revenue">Revenue</label>{' '}
                        </div>
                      </div>

                      <div className="mui-custom-form override-width-38 override-m-1">
                        <TextField
                          id="HCPCSEOBCode"
                          fullWidth
                          label="EOMB Text"
                          inputProps={{ maxlength: 35 }}
                          value={HCPCSValues.HCPCSEOBCode}
                          error={
                            showCommonError
                              ? ErrorConstants.BUSINESS_NAME_ERROR
                              : null
                          }
                          helperText={
                            showCommonError
                              ? ErrorConstants.BUSINESS_NAME_ERROR
                              : null
                          }
                          onChange={handleChangeHCPCS('HCPCSEOBCode')}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />

                        <div className="sub-radio">
                          <Radio
                            id="startsWith"
                            value="0"
                            checked={HCPCSValues.selectedHCPCSOption === '0'}
                            onChange={handleChangeHCPCS('selectedHCPCSOption')}
                          />

                          <label className="text-black" htmlFor="startsWith">Starts With</label>

                          <Radio
                            id="contains"
                            value="1"
                            checked={HCPCSValues.selectedHCPCSOption === '1'}
                            onChange={handleChangeHCPCS('selectedHCPCSOption')}
                            className="ml-2"
                          />

                          <label className="text-black" htmlFor="contains">Contains</label>
                        </div>
                      </div>
                    </div>

                    {/* Switch Call */}

                    {renderSwitch(eombType)}
                  </form>
                </div>
              </TabPanel>

              {/* Code to display RA EOB */}

              <TabPanel value={value} index={1}>
                <div className="tab-holder">
                  <form autoComplete="off">
                    <div className="form-wrapper">

                      <div className="mui-custom-form input-sm">
                        <TextField
                          id="eobCode"
                          fullWidth
                          label="EOB Code"
                          value={eobValues.eobCode}
                          onChange={handleChangeREOB('eobCode')}
                          inputProps={{ maxLength: 6 }}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </div>

                      <div className="mui-custom-form input-xl">
                        <TextField
                          id="eobText"
                          fullWidth
                          value={eobValues.eobText}
                          onChange={handleChangeREOB('eobText')}
                          label="EOB Text"
                          error={
                            showCommonError
                              ? ErrorConstants.BUSINESS_NAME_ERROR
                              : null
                          }
                          helperText={
                            showCommonError
                              ? ErrorConstants.BUSINESS_NAME_ERROR
                              : null
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{ maxLength: 35 }}
                        />

                        <div className="sub-radio">
                          <Radio
                            value="0"
                            id="StartsWith"
                            checked={eobValues.selectedRAEOBOption === '0'}
                            onChange={handleChangeREOB('selectedRAEOBOption')}
                          />
                          <label className="text-black" htmlFor="StartsWith">Starts With</label>

                          <Radio
                            value="1"
                            id="Contains"
                            checked={eobValues.selectedRAEOBOption === '1'}
                            onChange={handleChangeREOB('selectedRAEOBOption')}
                            className="ml-2"
                          />

                          <label className="text-black" htmlFor="Contains">Contains </label>{' '}
                        </div>
                      </div>
                    </div>

                    <div className="float-right m-3">
                      <Button
                        className="btn-tab-pannel"
                        onClick={() => searchCheckRAEOB(valuesRAEOB)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        Search
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        className="bt-tabreset btn-transparent ml-1"
                        onClick={() => resetTableRAEOB()}
                      >
                        <i className="fa fa-undo" aria-hidden="true"></i>
                        Reset
                      </Button>
                    </div>

                    <div className="clearfix"></div>
                  </form>
                </div>
                <div className="tab-holder pb-1">
                  {showRAEOB && displayTable && displayTable.length > 0 ? (
                    <RAEOBResult tableData={displayTable || []} setspinnerLoader={setspinnerLoader} />
                  ) : null}
                </div>
              </TabPanel>

              {/* Code for Provider Notice Tab */}

              <TabPanel value={value} index={2}>
                {/* <form autoComplete="off"> */}
                <div className="tab-holder">
                  <div className="form-wrapper">
                      <NativeDropDown
                        id="providertypeEOB"
                        label="Provider Type"
                        inputProps={{ maxlength: 3 }}
                        value={valuesPN1.providertypeEOB}
                        onChange={handleChangesPN1('providertypeEOB')}
                        InputLabelProps={{
                          shrink: true
                        }}
                        dropdownList={providerType
                          ? providerType.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.description}
                            </option>
                          ))
                          : null}
                      />

                      <NativeDropDown
                        id="providerspecialityEOB"
                        label="Provider Specialty"
                        inputProps={{ maxlength: 3 }}
                        value={valuesPN2.providerspecialityEOB}
                        onChange={handleChangesPN2('providerspecialityEOB')}
                        disabled={providerSpeciality}
                        InputLabelProps={{
                          shrink: true
                        }}
                        dropdownList={ProviderSpecialtyData
                          ? ProviderSpecialtyData.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.description}
                            </option>
                          ))
                          : null}
                      />

                    <div className="mui-custom-form input-md">
                      <TextField
                        id="noticetextEOB"
                        fullWidth
                        label="Notice Text"
                        error={
                          showCommonError
                            ? ErrorConstants.BUSINESS_NAME_ERROR
                            : null
                        }
                        helperText={
                          showCommonError
                            ? ErrorConstants.BUSINESS_NAME_ERROR
                            : null
                        }
                        inputProps={{ maxlength: 35 }}
                        value={pNValues.noticetextEOB}
                        onChange={handleChangePN('noticetextEOB')}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />

                      <div className="sub-radio">
                        <Radio
                          value="1"
                          id="startwithoption"
                          checked={true}
                          onChange={handleChangePN('selectedPNOption')}
                        />
                        <label className="text-black" htmlFor="startwithoption">Contains </label>

                      </div>
                    </div>
                  </div>

                  <div className="form-wrapper">
                    <div className="mui-custom-form no-margin-y">
                      <div className="sub-radio no-margin">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={pNValues.showVoids}
                              value={pNValues.showVoids}
                              onChange={handleShowVoidsChange}
                            />
                          }
                          label="Show Voids"
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="float-right"
                    style={{
                      marginTop: '-12px',
                      marginRight: '16px',
                      marginBottom: '16px'
                    }}
                  >
                    <Button
                      className="btn-tab-pannel"
                      onClick={() => searchCheckPN(valueslobPN)}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                      Search
                    </Button>

                    <Button
                      variant="outlined"
                      color="primary"
                      className="bt-tabreset btn-transparent ml-1"
                      onClick={() => resetTablePN()}
                    >
                      <i className="fa fa-undo" aria-hidden="true"></i>
                      Reset
                    </Button>
                  </div>

                  <div className="clearfix"></div>

                  {/* </form> */}

                  <div className="tab-holder pb-1">
                    {showPN && displayTable && displayTable.length > 0 ? (
                      <ProviderNotice
                        tableData={displayTable || []}
                        providerType={providerType}
                        ProviderSpecialtyData={ProviderSpecialtyData}
                        setspinnerLoader={setspinnerLoader}
                        dropdowns={addDropdowns}
                      />
                    ) : null}
                  </div>
                </div>
              </TabPanel>

              {/* Code for Remark Code */}

              <TabPanel value={value} index={3}>
                <div className="tab-holder">
                  <form autoComplete="off">
                    <div className="form-wrapper">
                      <div className="mui-custom-form input-xxl">
                        <TextField
                          id="remarkCode"
                          fullWidth
                          label="Remark Code"
                          inputProps={{ maxlength: 10 }}
                          error={
                            showbusinessNameError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : null
                          }
                          helperText={
                            showbusinessNameError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : null
                          }
                          value={rCValues.remarkCode}
                          onChange={handleChangeRC('remarkCode')}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </div>

                      <div
                        className="mui-custom-form input-xxl"
                        style={{ marginLeft: '32px' }}
                      >
                        <TextField
                          id="remarkText"
                          fullWidth
                          label="Remark Text"
                          inputProps={{ maxlength: 35 }}
                          error={
                            showbusinessNameError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : showCommonError
                                ? ErrorConstants.BUSINESS_NAME_ERROR
                                : null
                          }
                          helperText={
                            showbusinessNameError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : showCommonError
                                ? ErrorConstants.BUSINESS_NAME_ERROR
                                : null
                          }
                          value={rCValues.remarkText}
                          onChange={handleChangeRC('remarkText')}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />

                        <div className="sub-radio">
                          <Radio
                            value="0"
                            id="startremark"
                            checked={rCValues.selectedRCOption === '0'}
                            onChange={handleChangeRC('selectedRCOption')}
                          />
                          <label className="text-black" htmlFor="startremark">Starts With</label>

                          <Radio
                            value="1"
                            id="containremark"
                            checked={rCValues.selectedRCOption === '1'}
                            onChange={handleChangeRC('selectedRCOption')}
                            className="ml-2"
                          />

                          <label className="text-black" htmlFor="containremark">Contains</label>
                        </div>
                      </div>
                    </div>

                    <div className="float-right m-3">
                      <Button
                        className="btn-tab-pannel"
                        onClick={() => searchCheckRC(rCValues)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        Search
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        className="bt-tabreset btn-transparent ml-1"
                        onClick={() => resetTableRC()}
                      >
                        {' '}
                        <i className="fa fa-undo" aria-hidden="true"></i>
                        Reset
                      </Button>
                    </div>

                    <div className="clearfix"></div>
                  </form>

                  <div className="tab-holder pb-1">
                    {showPC && displayTable && displayTable.length > 0 ? (
                      <RemarkCode tableData={displayTable || []} setspinnerLoader={setspinnerLoader} />
                    ) : null}
                  </div>
                </div>
              </TabPanel>

              {/* Code for adjustment reason */}

              <TabPanel value={value} index={4}>
                <div className="tab-holder">
                  <form autoComplete="off">
                    <div className="form-wrapper">
                      <div className="mui-custom-form input-md">
                        <TextField
                          id="adjustmentRC"
                          fullWidth
                          label="Adjustment Reason Code"
                          value={ARCValues.adjustmentRC}
                          error={
                            showdataElementNameError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : null
                          }
                          helperText={
                            showdataElementNameError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : null
                          }
                          inputProps={{ maxlength: 5 }}
                          onChange={handleChangeARC('adjustmentRC')}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </div>

                      <div
                        className="mui-custom-form input-xxl"
                        style={{ marginLeft: '32px' }}
                      >
                        <TextField
                          id="adjustmentRT"
                          fullWidth
                          label="Adjustment Reason Text"
                          value={ARCValues.adjustmentRT}
                          error={
                            showdataElementNameError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : showCommonError
                                ? ErrorConstants.BUSINESS_NAME_ERROR
                                : null
                          }
                          helperText={
                            showdataElementNameError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : showCommonError
                                ? ErrorConstants.BUSINESS_NAME_ERROR
                                : null
                          }
                          inputProps={{ maxlength: 35 }}
                          onChange={handleChangeARC('adjustmentRT')}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />

                        <div className="sub-radio">
                          <Radio
                            value="0"
                            id="startadjustment"
                            checked={ARCValues.selectedARCOption === '0'}
                            onChange={handleChangeARC('selectedARCOption')}
                          />
                          <label className="text-black" htmlFor="startadjustment">Starts With</label>

                          <Radio
                            value="1"
                            id="containsadjustment"
                            checked={ARCValues.selectedARCOption === '1'}
                            onChange={handleChangeARC('selectedARCOption')}
                            className="ml-2"
                          />

                          <label className="text-black" htmlFor="containsadjustment">Contains</label>
                        </div>
                      </div>
                    </div>

                    <div className="float-right m-3">
                      <Button
                        className="btn-tab-pannel"
                        onClick={() => searchCheckARC(ARCValues)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        Search
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        className="bt-tabreset btn-transparent ml-1"
                        onClick={() => resetTableARC()}
                      >
                        <i className="fa fa-undo" aria-hidden="true"></i>
                        Reset
                      </Button>
                    </div>

                    <div className="clearfix"></div>
                  </form>

                  <div className="tab-holder pb-1">
                    {showAR && displayTable && displayTable.length > 0 ? (
                      <AdjustmentReason tableData={displayTable || []} setspinnerLoader={setspinnerLoader} />
                    ) : null}
                  </div>
                </div>
              </TabPanel>

              {/* Code for NCPDP */}

              <TabPanel value={value} index={5}>
                <div className="tab-holder">
                  <form autoComplete="off">
                    <div className="form-wrapper">
                      <div className="mui-custom-form input-md">
                        <TextField
                          id="NCPDPRejectCode"
                          fullWidth
                          label="NCPDP Reject Code"
                          value={NCPDPValues.NCPDPRejectCode}
                          inputProps={{ maxlength: 3 }}
                          error={
                            showFunctionalAreaError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : null
                          }
                          helperText={
                            showFunctionalAreaError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : null
                          }
                          onChange={handleChangeNCPDP('NCPDPRejectCode')}
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </div>

                      <div
                        className="mui-custom-form input-xxl"
                        style={{ marginLeft: '32px' }}
                      >
                        <TextField
                          id="NCPDPRejectCodeText"
                          fullWidth
                          label="NCPDP Reject Code Text"
                          inputProps={{ maxlength: 50 }}
                          value={NCPDPValues.NCPDPRejectCodeText}
                          onChange={handleChangeNCPDP('NCPDPRejectCodeText')}
                          error={
                            showFunctionalAreaError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : showCommonError
                                ? ErrorConstants.BUSINESS_NAME_ERROR
                                : null
                          }
                          helperText={
                            showFunctionalAreaError
                              ? ErrorConstants.DATA_ELEMENT_NAME_ERROR
                              : showCommonError
                                ? ErrorConstants.BUSINESS_NAME_ERROR
                                : null
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                        />

                        <div className="sub-radio">
                          <Radio
                            value="0"
                            id="startncpdp"
                            checked={NCPDPValues.selectedNCPDPOption === '0'}
                            onChange={handleChangeNCPDP('selectedNCPDPOption')}
                          />

                          <label className="text-black" htmlFor="startncpdp">Starts With</label>

                          <Radio
                            value="1"
                            id="containncp"
                            checked={NCPDPValues.selectedNCPDPOption === '1'}
                            onChange={handleChangeNCPDP('selectedNCPDPOption')}
                            className="ml-2"
                          />

                          <label className="text-black" htmlFor="containncp">Contains</label>
                        </div>
                      </div>
                    </div>

                    <div className="float-right m-3">
                      <Button
                        className="btn-tab-pannel"
                        onClick={() => searchCheckNCPDP(NCPDPValues)}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                        Search
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        className="bt-tabreset btn-transparent ml-1"
                        onClick={() => resetTableNCPDP()}
                      >
                        <i className="fa fa-undo" aria-hidden="true"></i>
                        Reset
                      </Button>
                    </div>

                    <div className="clearfix"></div>
                  </form>

                  <div className="tab-holder pb-1">
                    {showSAR && displayTable && displayTable.length > 0 ? (
                      <SAR tableData={displayTable || []} setspinnerLoader={setspinnerLoader} />
                    ) : null}
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}

export default withRouter(TextManagementSearch);
