import React, { useState, memo, forwardRef } from 'react'
import { createBrowserHistory } from 'history'
import MicroFrontend from './MicroFrontend'
import { withRouter } from 'react-router'
import Spinner from '../Spinner/Spinner'

const defaultHistory = createBrowserHistory()

const { REACT_APP_ROUTE_ENV: CommonHost } = process.env

function MicrofrontendComponent (props, ref) {
  const { history, privileges } = props
  const [spinnerLoader, setspinnerLoader] = useState(false)
  let containerId = props.id ? props.id : `${props.type}-container`
  const injectableHost = `${CommonHost}injectableModule`; 
  return (
    <>
      {spinnerLoader ? <Spinner setspinnerLoader={setspinnerLoader} /> : null}
      <div className='mfComponent'>
        <MicroFrontend
          ref={ref}
          history={history}
          privileges={privileges}
          componentProps={props}
          host={injectableHost}
          name={props.type}
          containerId={containerId}
          setspinnerLoader={setspinnerLoader}
          setScriptLoad={props.setScriptLoad}
        />
      </div>
    </>
  )
}

export function arePropsEqual (prevProps, nextProps) {
  return prevProps.id === nextProps.id && nextProps.type === 'Notes'
}

export default memo(forwardRef(MicrofrontendComponent, arePropsEqual))

// export default withRouter(MicrofrontendComponent);
