/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import TableComponent from '../../../../SharedModules/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import CorrectionDetailView from './CorrectionDetailsView.js';
import {
  getCorrectionDetailView,
  get1099CorrectionDropdowns,
  correctionDetailUpdate,
  getAuditLogDetailsAction
} from '../../Store/Actions/CorrectionSearchActions';
import * as ErrorConstants from '../ErrorConstants';
import SuccessMessageToast from '../../../../SharedModules/Errors/SuccessMessage';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import CorrectionDropdownInput from './CorrectionDropdownInput.json';
import {
  getDateInMMDDYYYYFormatWithApendZero,
  generateUUID,
  getUTCTimeStamp
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import * as moment from 'moment';
import numeral from 'numeral';
import Footer from '../../../../SharedModules/Layout/footer';
import Notes from '../../../../SharedModules/Notes/Notes';
import Attachments from '../../../../SharedModules/Attachments/Attachments';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import { systemListActions, systemListActions9026, systemListActions9027 } from '../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions';
import { checkingDecimal } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import { CORRECTION_YES_APPROVAL_INDICATOR,CORRECTION_NO_APPROVAL_INDICATOR ,CORRECTION_PENDING_APPROVAL_INDICATOR,CORRECTION_Y_IND,CORRECTION_N_IND } from '../../../../SharedModules/AppConstants';

// 9024 to 9027
/* IMPLEMENT TABLE COMPONENT */
const headCells = [
  {
    id: 'fin1099Year',
    disablePadding: false,
    label: '1099 Year',
    enableHyperLink: true,
    width: '20%'
  },
  {
    id: 'original1099Amount',
    disablePadding: false,
    label: '1099 Amount',
    enableHyperLink: false,
    isBalance: true,
    width: '40%'
  },
  {
    id: 'adjusted1099Amount',
    disablePadding: false,
    label: '1099 Adjusted Amount',
    enableHyperLink: false,
    isBalance: true,
    width: '40%'
  }
];

function CorrectionDetails (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [value, setValue] = React.useState(0);
  const [claimCorrSeqAmt, setClaimCorrSeqAmt] = React.useState(0);
  const [isCorrSeqAmtUpdated, setClaimCorrSeqAmtUpdate] = React.useState(false);
  const [originalCorrectionAdjustedAmt, setOriginalCorrectionAdjustedAmt] = React.useState(0);
  const [isFocusCheck,setFocusCheck] =React.useState(false);
  const reasonCodes = (value) => dispatch(systemListActions(value));
  const reasonCodes1 = (value) => dispatch(systemListActions9026(value));
  const reasonCodes2 = (value) => dispatch(systemListActions9027(value));
  let reasonCodesValues = useSelector(state => state.appConfigState.systemListState?.payload);
  let reasonCodesValues9026 = useSelector(state => state.appConfigState.systemListState?.payload9026);// 704 System List
  let reasonCodesValues9027 = useSelector(state => state.appConfigState.systemListState?.payloadV9027);// 706 System List
  const [correctionViewDetails, setcorrectionViewDetails] = React.useState({
    original1099Amount: '',
    adjusted1099Amount: 0.0,
    reasonCode: '',
    updatedDate: '',
    a1099Approval: '',
    c1099Correction: '',
    void: '',
    auditUserID: '',
    auditTimeStamp: '',
    addedAuditUserID: '',
    addedAuditTimeStamp: '',
    claimProvSumSk: '',
    claimCorrSeqNum: ''
  });
  const [
    historyCorrectionDetailTableData,
    setShowHistoryCorrectionDetailTableData
  ] = React.useState([]);

  const [showAdditionalDetails, setShowAdditionalDetails] = React.useState(
    false
  );
  const[correctionHistoryRowData , setCorrectionHistoryRowData] = React.useState([]);
  const[isCorrectionHistoryRowData , setIsCorrectionHistoryRowData] = React.useState(false);
  const [
    { showUpdateDateError, showReasonCodeError, showAdjustedAmountError, showLetterSendDateError },
    setShowError
  ] = React.useState(false);
  const [
    {
      showUpdateDateErrorText,
      showReasonCodeErrorText,
      showAdjustedAmountErrorText,
      showLetterSendDateErrorText
    },
    setShowErrorText
  ] = React.useState('');
  const [updateDate, setUpdateDate] = React.useState(new Date());
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [showLogTable,setShowLogTable] = React.useState(false);
  const [isEdited, setisEdited] = React.useState(true);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [rowData, setrowData] = React.useState([]);
  const [letterSendDt, setLetterSendDt] = React.useState(null);
  // Attachments variables
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [
    opencorrectionDetailView,
    setopencorrectionDetailView
  ] = React.useState(false);
  const [logInUserId, setLogiInUserId] = React.useState('');
  const errorMessagesArray = [];
  let correctiondetailViewData = {};
  let onUpdateCorrectionDetailResponse = {};
  const sucessMessages = [];
  let dropdown = [];
  const amountFormatCheck = value => {
    return numeral(value).format('0,0.00');
  };
  const dispatch = useDispatch();
  
  const userInquiryPrivileges = !global.globalIsReadOnly();
  const logInUserIDconst = useSelector(state => state.sharedState?.logInUserID);
  const onRowclickAPICall = row => dispatch(getCorrectionDetailView(row));
  const onCorrectionDetailViewData = useSelector(
    state => state.correctionSearchState?.correctionDeatilViewData
  );
  const approvalIndicator = value => {
    if(value.fin1099ApprovalIndicator === CORRECTION_Y_IND){
      return CORRECTION_YES_APPROVAL_INDICATOR
    }
    else if(value.fin1099ApprovalIndicator === CORRECTION_N_IND){
      return CORRECTION_NO_APPROVAL_INDICATOR
    }
    else if(value.fin1099ApprovalIndicator === CORRECTION_PENDING_APPROVAL_INDICATOR){
      return CORRECTION_PENDING_APPROVAL_INDICATOR
    }
    else{
      return null
    }
  };
  const onUpdateCorrectionDetail = value => {
    dispatch(correctionDetailUpdate(value, attachmentsTableData.length > 0 ? attachmentFile : null));
  };

  onUpdateCorrectionDetailResponse = useSelector(
    state => state.correctionSearchState?.correctionDetailUpdateResponse
  );

  const onDropdowns = CorrectionDropdownInput =>
    dispatch(get1099CorrectionDropdowns(CorrectionDropdownInput));
  dropdown = useSelector(state => state.correctionSearchState?.dropdowns);
  const validValueDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns?.appdropdowns);
  const [systemList, setSystemList] = React.useState([]);
  const [systemList26, setSystemList26] = React.useState([]);
  const [systemList27, setSystemList27] = React.useState([]);
  const [SuccessMessage, setSuccessMessage] = React.useState(false);
  const auditData = useSelector(state => state.correctionSearchState?.auditData);
  const onClickAuditLog = () => {
    const tableName = "c_prov_1099_corr_tb";
    const keyValue = {
      c_prov_clm_sum_sk: correctionViewDetails.claimProvSumSk,
      c_1099_corr_seq_num: correctionViewDetails.claimCorrSeqNum,
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, "auditLogData"));
  };

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
    } else {
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);
  useEffect(() => {
    onDropdowns(CorrectionDropdownInput);
  }, [CorrectionDropdownInput]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Financial#3102']) {
        setReasonCodeData(dropdown.listObj['Financial#3102']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }   
  }, [dropdown]);

  useEffect(() => {
    validValueDropdowns([
      Dropdowns.F_RSN_CD,
    ]);
    reasonCodes(Dropdowns.REASON_CODES);      
    reasonCodes1(Dropdowns.REASON_CODES_9026);      
    reasonCodes2(Dropdowns.REASON_CODES_9027);      
  }, []);

  useEffect(()=>{   
    if(reasonCodesValues !== undefined && reasonCodesValues !== null ){      
      const reasonCode = reasonCodesValues[0]?.systemListDetails?.map(x => x.startingValue); 
      setSystemList(reasonCode) ;
     }  
  },[reasonCodesValues]);

  useEffect(()=>{ 
    if(reasonCodesValues9026 !== undefined && reasonCodesValues9026 !== null ){   
      const reasonCodes9026 = reasonCodesValues9026 && reasonCodesValues9026[0]?.systemListDetails?.map(x => x.startingValue); 
      setSystemList26(reasonCodes9026) ;
     } 
  },[reasonCodesValues9026]);

  useEffect(()=>{ 
    if(reasonCodesValues9027 !== undefined && reasonCodesValues9027 !== null ){      
      const reasonCodes9027 = reasonCodesValues9027 && reasonCodesValues9027[0]?.systemListDetails?.map(x => x.startingValue); 
      setSystemList27(reasonCodes9027) ;
     } 
  },[reasonCodesValues9027]);


  // userid
  useEffect(() => {
    if (logInUserIDconst) {
      setLogiInUserId(logInUserIDconst);
    }
  }, [logInUserIDconst]);

  useEffect(() => {
    if (onUpdateCorrectionDetailResponse) {
      setisEdited(true);
      props.setSpinnerLoader(false);
      setAllowNavigation(false);
      setAttachmentFile([]);
      if (
        onUpdateCorrectionDetailResponse.respcode &&
        onUpdateCorrectionDetailResponse.respcode === '02' 
      ) {
        errorMessagesArray.push(ErrorConstants.INVALID_FILE_TYPE);
        props.setErrorMessages(errorMessagesArray);
        handleCloseCorrectionDetailView();
      } else if (
        onUpdateCorrectionDetailResponse.systemFailure ||
        (onUpdateCorrectionDetailResponse.message && SuccessMessage)
      ) {
        errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
        setSubErrorMsg(errorMessagesArray);
        handleCloseCorrectionDetailView();
      } else if (
        onUpdateCorrectionDetailResponse.respcode &&
        onUpdateCorrectionDetailResponse.respcode === '01'
      ) {
        const rowDetails = {
          lobCode: props.parentTableRow.lobCode,
          categoryCode: props.parentTableRow.categoryCode,
          timePeriodCode: props.parentTableRow.timePeriodCode,
          commonEntitySK: props.parentTableRow.commonEntitySK
        };
        setcorrectionViewDetails(null);
        setNotesTableData([]);
        setNotesInput({});
        props.onRowclickAPICall(rowDetails);
        if (SuccessMessage) {
          sucessMessages.push(ErrorConstants.SUCCESS_MESSAGE);
          props.setSystemSuccesMessages(sucessMessages);
        }
        setSuccessMessage([ErrorConstants.SUCCESS_MESSAGE]);
        window.scroll(0, 0);
        handleCloseCorrectionDetailView();
      }
    }
  }, [onUpdateCorrectionDetailResponse]);
  React.useEffect(() => {
    if(allowNavigation){
      setisEdited(false);
    }
  }, [allowNavigation]);
  useEffect(() => {
    if (onCorrectionDetailViewData) {
      props.setSpinnerLoader(false);
      setisEdited(true);
      if (
        onCorrectionDetailViewData.respcode &&
        onCorrectionDetailViewData.respcode === '02'
      ) {
        errorMessagesArray.push(onCorrectionDetailViewData.respdesc);
        props.setErrorMessages(errorMessagesArray);
        setSubErrorMsg(errorMessagesArray);
      } else if (
        onCorrectionDetailViewData.systemFailure ||
        onCorrectionDetailViewData.message
      ) {
        errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
        props.setErrorMessages(errorMessagesArray);
        setSubErrorMsg(errorMessagesArray);
      } else if (onCorrectionDetailViewData.searchResults &&
        (onCorrectionDetailViewData.searchResults === null ||
          onCorrectionDetailViewData.searchResults.length === 0)
      ) {
        errorMessagesArray.push(ErrorConstants.NO_RECORDS_FOUND);
        props.setErrorMessages(errorMessagesArray);
      } else if ((onCorrectionDetailViewData.enterpriseBaseVO?.active1099Corrections ||
        onCorrectionDetailViewData.searchResults !== null) &&
        !onCorrectionDetailViewData.systemFailure
      ) {
        setSuccessMessage([]);

        if (onCorrectionDetailViewData.enterpriseBaseVO?.active1099Corrections) {
          correctiondetailViewData = onCorrectionDetailViewData.enterpriseBaseVO?.active1099Corrections;
          setOriginalCorrectionAdjustedAmt(correctiondetailViewData.fin1099VoidIndicator === 'N' || correctiondetailViewData.fin1099VoidIndicator === null
            ? numeral(amountFormatCheck(correctiondetailViewData.adjusted1099Amount)).format('0,0.00')
            : numeral(correctiondetailViewData.adjusted1099Amount).format('0,0.00'));
          setcorrectionViewDetails({
            auditUserID: logInUserId,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: correctiondetailViewData && correctiondetailViewData.addedAuditUserID
              ? correctiondetailViewData.addedAuditUserID
              : logInUserId,
            addedAuditTimeStamp: correctiondetailViewData && correctiondetailViewData.addedAuditTimeStamp
              ? correctiondetailViewData.addedAuditTimeStamp
              : getUTCTimeStamp(),
            lobCode: correctiondetailViewData.lobCode,
            categoryCode: correctiondetailViewData.categoryCode,
            timePeriodCode: correctiondetailViewData.timePeriodCode,
            commonEntitySK: correctiondetailViewData.commonEntitySK,
            original1099Amount: correctiondetailViewData.original1099Amount,
            claimProvSumSk: correctiondetailViewData.claimProvSumSk,
            claimCorrSeqNum: correctiondetailViewData.claimCorrSeqNum,
            adjusted1099Amount:
              correctiondetailViewData.fin1099VoidIndicator === 'N' ||
                correctiondetailViewData.fin1099VoidIndicator === null
                ? numeral(amountFormatCheck(correctiondetailViewData.adjusted1099Amount))
                  .format('0,0.00')
                : numeral(correctiondetailViewData.adjusted1099Amount).format('0,0.00'),
            reasonCode: correctiondetailViewData.reasonCode
              ? correctiondetailViewData.reasonCode
              : DEFAULT_DD_VALUE,
            a1099Approval: approvalIndicator(correctiondetailViewData),
            c1099Correction:
              correctiondetailViewData.fin1099CorrectionIndicator === 'Y'
                ? 'Yes'
                : correctiondetailViewData.fin1099CorrectionIndicator === 'N'
                  ? 'No'
                  : null,
            void:
              correctiondetailViewData.fin1099VoidIndicator === 'Y'
                ? 'Yes'
                : correctiondetailViewData.fin1099VoidIndicator === 'N'
                  ? 'No'
                  : null
          });
          setLetterSendDt(correctiondetailViewData.toBeSentDate);
          setrowData(correctiondetailViewData);
          setAttachmentsTableData(correctiondetailViewData.attachmentsList);
          setopencorrectionDetailView(true);
          if (correctiondetailViewData.noteSetVO) {
            setNotesInput(correctiondetailViewData.noteSetVO);
            if (correctiondetailViewData && correctiondetailViewData.noteSetVO) {
              setNotesTableData(correctiondetailViewData.noteSetVO.notesList);
            }
          } else {
            setNotesTableData([]);
            setNotesInput({});
          }
        }
        setShowHistoryCorrectionDetailTableData(onCorrectionDetailViewData.enterpriseBaseVO.history1099Corrections);
      }
    }
  }, [onCorrectionDetailViewData]);

  useEffect(() => {
    if (correctionHistoryRowData && isCorrectionHistoryRowData) {
          correctiondetailViewData = correctionHistoryRowData;
          setOriginalCorrectionAdjustedAmt(correctiondetailViewData.fin1099VoidIndicator === 'N' || correctiondetailViewData.fin1099VoidIndicator === null
            ? numeral(amountFormatCheck(correctiondetailViewData.adjusted1099Amount)).format('0,0.00')
            : numeral(correctiondetailViewData.adjusted1099Amount).format('0,0.00'));
          setcorrectionViewDetails({
            auditUserID: logInUserId,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: correctiondetailViewData && correctiondetailViewData.addedAuditUserID
              ? correctiondetailViewData.addedAuditUserID
              : logInUserId,
            addedAuditTimeStamp: correctiondetailViewData && correctiondetailViewData.addedAuditTimeStamp
              ? correctiondetailViewData.addedAuditTimeStamp
              : getUTCTimeStamp(),
            lobCode: correctiondetailViewData.lobCode,
            categoryCode: correctiondetailViewData.categoryCode,
            timePeriodCode: correctiondetailViewData.timePeriodCode,
            commonEntitySK: correctiondetailViewData.commonEntitySK,
            original1099Amount: correctiondetailViewData.original1099Amount,
            claimProvSumSk: correctiondetailViewData.claimProvSumSk,
            claimCorrSeqNum: correctiondetailViewData.claimCorrSeqNum,
            adjusted1099Amount:
              correctiondetailViewData.fin1099VoidIndicator === 'N' ||
                correctiondetailViewData.fin1099VoidIndicator === null
                ? numeral(amountFormatCheck(correctiondetailViewData.adjusted1099Amount))
                  .format('0,0.00')
                : numeral(correctiondetailViewData.adjusted1099Amount).format('0,0.00'),
            reasonCode: correctiondetailViewData.reasonCode
              ? correctiondetailViewData.reasonCode.split('-')[0]
              : DEFAULT_DD_VALUE,
            a1099Approval:approvalIndicator(correctiondetailViewData),
            c1099Correction:
              correctiondetailViewData.fin1099CorrectionIndicator === 'Y'
                ? 'Yes'
                : correctiondetailViewData.fin1099CorrectionIndicator === 'N'
                  ? 'No'
                  : null,
            void:
              correctiondetailViewData.fin1099VoidIndicator === 'Y'
                ? 'Yes'
                : correctiondetailViewData.fin1099VoidIndicator === 'N'
                  ? 'No'
                  : null
          });
          setLetterSendDt(correctiondetailViewData.toBeSentDate);
          setrowData(correctiondetailViewData);
          setAttachmentsTableData(correctiondetailViewData.attachmentsList);
          setopencorrectionDetailView(true);
          if(historyCorrectionDetailTableData?.length < 
            onCorrectionDetailViewData.enterpriseBaseVO?.history1099Corrections?.length +1){
            setShowHistoryCorrectionDetailTableData([onCorrectionDetailViewData.enterpriseBaseVO?.active1099Corrections, ...historyCorrectionDetailTableData, ]);
          }
          if (correctiondetailViewData.noteSetVO) {
            setNotesInput(correctiondetailViewData.noteSetVO);
            if (correctiondetailViewData && correctiondetailViewData.noteSetVO) {
              setNotesTableData(correctiondetailViewData.noteSetVO.notesList);
            }
          } else {
            setNotesTableData([]);
            setNotesInput({});
          }
        }
  }, [correctionHistoryRowData,isCorrectionHistoryRowData]);

  const handleChangeDetails = name => event => {
    setisEdited(false);
    if (name === 'reasonCode' && event.target.value === systemList27.toString()) {
      setcorrectionViewDetails({
        ...correctionViewDetails,
        void: 'Yes',
        c1099Correction: 'Yes',
        [name]: event.target.value
      });
    } else {
      setcorrectionViewDetails({
        ...correctionViewDetails,
        void:'No',
        c1099Correction: 'Yes',
        [name]: event.target.value
      });
    }
  };
  
  const handleDecimalValues = name => event =>{
    setisEdited(false);
    if (name === 'adjusted1099Amount') {
      setClaimCorrSeqAmt(amountFormatCheck(event.target.value));
      setClaimCorrSeqAmtUpdate(true);
    if (event.target.value && event.target.value.includes('.')) {       
      if(checkingDecimal(event.target.value)){
        setcorrectionViewDetails({ ...correctionViewDetails, c1099Correction: 'Yes', [name]: event.target.value });
        setClaimCorrSeqAmt(event.target.value);
        setClaimCorrSeqAmtUpdate(true);
      }
      } else {        
          setcorrectionViewDetails({ ...correctionViewDetails, c1099Correction: 'Yes', [name]: event.target.value });
          setClaimCorrSeqAmt(event.target.value);
          setClaimCorrSeqAmtUpdate(true);
        }
      }
      else {       
        setcorrectionViewDetails({ ...correctionViewDetails, [name]: event.target.value });
        setClaimCorrSeqAmt(event.target.value);
        setClaimCorrSeqAmtUpdate(true);
      }
    };
  
  const editRow = row => event => {
    props.setSpinnerLoader(true);
    setClaimCorrSeqAmtUpdate(false);
    const rowDetails = {
      lobCode: row.lobCode,
      categoryCode: row.categoryCode,
      timePeriodCode: row.timePeriodCode,
      commonEntitySK: row.commonEntitySK
    };
    setShowError(false);
    setShowErrorText('');
    props.setErrorMessages([]);
    setNotesTableData([]);
    setNotesInput({});
    onRowclickAPICall(rowDetails);
  };
  /* DISPLAY CORRECTION DETAILS ON ROW CLICK */
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  
  const handleLetterSendDateChange = date => {
    setAllowNavigation(true);
    setLetterSendDt(date);
  };
  const [subErrorMsg, setSubErrorMsg] = React.useState([]);
  const checkCorrectionDetailValidations = () => {
    var showUpdateDateErrorTemp = false;
    var showReasonCodeErrorTemp = false;
    var showAdjustedAmountErrorTemp = false;
    var showUpdateDateErrorTextTemp = '';
    var showReasonCodeErrorTextTemp = '';
    var showAdjustedAmountErrorTextTemp = '';
    var showLetterSendDateErrorTextTemp = '';
    var showLetterSendDateErrorTemp = false;
    if (
      correctionViewDetails.reasonCode === '' ||
      correctionViewDetails.reasonCode === undefined ||
      correctionViewDetails.reasonCode === DEFAULT_DD_VALUE
    ) {
      showReasonCodeErrorTemp = true;
      showReasonCodeErrorTextTemp = ErrorConstants.REASON_CODE_REQUIRED;
      errorMessagesArray.push(showReasonCodeErrorTextTemp);
    }
    if (
      correctionViewDetails.adjusted1099Amount !== '' &&
      correctionViewDetails.adjusted1099Amount !== undefined &&
      parseInt(correctionViewDetails.adjusted1099Amount) < 0
    ) {
      showAdjustedAmountErrorTemp = true;
      showAdjustedAmountErrorTextTemp = ErrorConstants.ADJUSTED_NEGATIVE_ERROR;
      errorMessagesArray.push(showAdjustedAmountErrorTextTemp);
    }
    if (
      correctionViewDetails.adjusted1099Amount
        .toString()
        .replace(/,/g, '')
        .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
    ) {
      showAdjustedAmountErrorTemp = true;
      showAdjustedAmountErrorTextTemp = ErrorConstants.DATA_INVALID;
      errorMessagesArray.push(showAdjustedAmountErrorTextTemp);
    } else {
      if (
        !(
          correctionViewDetails.adjusted1099Amount
            .toString()
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null
        )
      ) {
        showAdjustedAmountErrorTemp = true;
        showAdjustedAmountErrorTextTemp = ErrorConstants.DATA_INVALID;
        errorMessagesArray.push(showAdjustedAmountErrorTextTemp);
      }
    }
    setSubErrorMsg(errorMessagesArray);
    setShowError({
      showUpdateDateError: showUpdateDateErrorTemp,
      showReasonCodeError: showReasonCodeErrorTemp,
      showAdjustedAmountError: showAdjustedAmountErrorTemp,
      showLetterSendDateError: showLetterSendDateErrorTemp
    });
    setShowErrorText({
      showUpdateDateErrorText: showUpdateDateErrorTextTemp,
      showReasonCodeErrorText: showReasonCodeErrorTextTemp,
      showAdjustedAmountErrorText: showAdjustedAmountErrorTextTemp,
      showLetterSendDateErrorText: showLetterSendDateErrorTextTemp
    });
  };
  const clearSuccessErrorMsgs = () => {
    setSuccessMessage([]);
    setSubErrorMsg([]);
    setShowError(false);
    setShowErrorText('');
  };
  const onMasterUpdateCorrectionDetail = () => {
    if (isEdited){
      setSuccessMessage(["No changes to Save"]);
      return false
    }
    let finalCorrectionSeqNo =  correctionViewDetails.claimCorrSeqNum ? correctionViewDetails.claimCorrSeqNum : '';
    props.setErrorMessages([]);
    props.setSystemSuccesMessages([]);
    props.setSpinnerLoader(true);
    setSuccessMessage(false);
    checkCorrectionDetailValidations();
    const activeCorrectionData = onCorrectionDetailViewData?.enterpriseBaseVO?.active1099Corrections
    if (errorMessagesArray.length === 0) {
      const updateRequest = {
        auditUserID: logInUserId,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: activeCorrectionData?.fin1099ApprovalIndicator && activeCorrectionData?.reasonCode ? correctionViewDetails.addedAuditUserID : logInUserId,
        addedAuditTimeStamp: correctionViewDetails.addedAuditTimeStamp,
        versionNo: correctionViewDetails.versionNo,
        dbRecord: false,
        sortColumn: null,
        attachmentVO: null,
        fin1099Year: correctionViewDetails.fin1099Year,
        fin1099Amount: correctionViewDetails.fin1099Amount,
        adjusted1099Amount: correctionViewDetails.adjusted1099Amount.toString().replace(/,/g, ''),
        original1099Amount: correctionViewDetails.original1099Amount,
        claimProvSumSk: correctionViewDetails.claimProvSumSk,
        claimCorrSeqNum: finalCorrectionSeqNo,
        reasonCode: correctionViewDetails.reasonCode,
        updateDate: moment((updateDate).toLocaleString('en-US', { timeZone: process.env.REACT_APP_TIMEZONE })).format('YYYY-MM-DDTHH:mm:ss.SSSZZ'),
        fin1099ApprovalIndicator: correctionViewDetails.a1099Approval||CORRECTION_PENDING_APPROVAL_INDICATOR,
        fin1099CorrectionIndicator:
          correctionViewDetails.c1099Correction === 'Yes'
            ? 'Y': 'N',
        fin1099VoidIndicator:
          correctionViewDetails.void === 'Yes'
            ? 'Y' : 'N',
        lobCode: correctionViewDetails.lobCode,
        categoryCode: correctionViewDetails.categoryCode,
        timePeriodCode: correctionViewDetails.timePeriodCode,
        commonEntitySK: correctionViewDetails.commonEntitySK,
        displaycorrectionref: correctionViewDetails.displaycorrectionref,
        renderCorrDetails: correctionViewDetails.renderCorrDetails,
        updateDateStr: getDateInMMDDYYYYFormatWithApendZero(updateDate),
        disableFields: correctionViewDetails.disableFields,
        noteSetVO: notesInput
      };
      let attachmentsList = [];
      if (attachmentsTableData && attachmentsTableData.length > 0) {
        attachmentsTableData.map(value => {
          if (value.id) {
            let attachmentVO = {
              auditUserID: logInUserId,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserId,
              addedAuditTimeStamp: getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: true,
              sortColumn: null,
              attachmentSK: null,
              attachmentPageID: null,
              finalPath: null,
              detachInd: false,
              historyIndicator: false,
              cascadeKey: null,
              attachmentIndicator: null,
              dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date()),
              fileUrl: null,
              showDeleteForSearched: false,
              rowIndex: 0,
              addedBy: logInUserId,
              description: value && value.description ? value.description : null,
              edmsStoredDate: null,
              anEDMSPageId: null,
              fileName: attachmentsTableData && value ? value.fileName : null,
              file1: null,
              edmsWrkUnitLevel: null,
              edmsDocType: null
            };
            attachmentsList.push(attachmentVO);
          }
        });
      } else {
        updateRequest.attachmentsList = [];
      }
      updateRequest.attachmentsList=attachmentsList;
      onUpdateCorrectionDetail(updateRequest);
    } else {
      props.setSpinnerLoader(false);
    }
  };

  const handleCloseCorrectionDetailView = () => {
    setopencorrectionDetailView(false);
  };

  const handleReset = rowData => {
    setisEdited(true);
    clearSuccessErrorMsgs();
    setShowErrorText([]);
    setAttachmentFile([]);
    props.setSystemSuccesMessages([]);
    setcorrectionViewDetails({
      lobCode: rowData.lobCode,
      categoryCode: rowData.categoryCode,
      timePeriodCode: rowData.timePeriodCode,
      commonEntitySK: rowData.commonEntitySK,
      claimCorrSeqNum: rowData.claimCorrSeqNum,
      addedAuditTimeStamp: rowData.addedAuditTimeStamp,
      addedAuditUserID: rowData.addedAuditUserID,
      claimProvSumSk: rowData.claimProvSumSk,
      original1099Amount: rowData.original1099Amount,
      adjusted1099Amount: amountFormatCheck(rowData.adjusted1099Amount),
      reasonCode: rowData.reasonCode ? rowData.reasonCode : DEFAULT_DD_VALUE,
      a1099Approval:approvalIndicator(rowData),
      c1099Correction:
        rowData.fin1099CorrectionIndicator === 'Y'
          ? 'Yes'
          : rowData.fin1099CorrectionIndicator === 'N'
            ? 'No'
            : null,
      void:
        rowData.fin1099VoidIndicator === 'Y'
          ? 'Yes'
          : rowData.fin1099VoidIndicator === 'N'
            ? 'No'
            : null
    });
    setLetterSendDt(rowData.toBeSentDate);
  };

  const amountFormatToDecimal = name => event => {
    const amount = event.target.value;
    if (
      !(
        amount
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
      )
    ) {
      if (
        amount
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null
      ) {
        setcorrectionViewDetails({
          ...correctionViewDetails,
          [name]: numeral(event.target.value)
            .format('0,0.00')
        });
      }
    }
  };
  const removeAmountFormatToDecimal = name => event => {
    if (correctionViewDetails.adjusted1099Amount) {
      setcorrectionViewDetails({
        ...correctionViewDetails,
        [name]: numeral(event.target.value)
          .format('0.00')
      });
    }
  };
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [notesTableData, setNotesTableData] = React.useState([]);
  let notesDataArray = [];

  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserId,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserId,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserId,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserId,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserId,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    noteText.auditUserID = logInUserId;
    noteText.auditTimeStamp = getUTCTimeStamp();
    noteText.addedAuditUserID = logInUserId;
    noteText.addedAuditTimeStamp = getUTCTimeStamp();
    noteText.notesCexAuditUserID = logInUserId;
    noteText.notesCexAuditTimeStamp = getUTCTimeStamp();
    noteText.notesCexAddedAuditUserID = logInUserId;
    noteText.notesCexAddedAuditTimeStamp = getUTCTimeStamp();
    noteText.userId = logInUserId;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({
      ...notesInput,
      auditUserID: logInUserId,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: notesInput.addedAuditUserID
        ? notesInput.addedAuditUserID
        : logInUserId,
      addedAuditTimeStamp: notesInput.addedAuditTimeStamp
        ? notesInput.addedAuditTimeStamp
        : getUTCTimeStamp(),
      notesList: notesDataArray
    });
  };
  // Notes ENd

  useEffect(() => {
    if (
      props.noteSetDetails &&
      props.noteSetDetails.length > 0 &&
      props.noteSetDetails[0].noteSetVO
    ) {
      props.setSpinnerLoader(false);
      const noteSetVO = props.noteSetDetails[0].noteSetVO;
      const notesTable = props.noteSetDetails[0].noteSetVO.notesList;
      setNotesInput({
        auditUserID: logInUserId,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserId,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: noteSetVO.versionNo,
        dbRecord: noteSetVO.dbRecord,
        sortColumn: noteSetVO.sortColumn,
        tableName: noteSetVO.tableName,
        noteSetSK: noteSetVO.noteSetSK,
        noteSourceName: noteSetVO.noteSourceName,
        notesList: notesTable,
        globalNotesList: [],
        checkAll: noteSetVO.checkAll,
        addNewLinkRender: noteSetVO.addNewLinkRender,
        filterLinkRender: noteSetVO.filterLinkRender,
        printLinkRender: noteSetVO.printLinkRender,
        completeNotesList: []
      });
      if (notesTable) {
        setNotesTableData(notesTable);
      }
    }
  }, [props.noteSetDetails]);

   return (
    <div className="pos-relative w-100 h-100">
      <SuccessMessageToast successMessages={ SuccessMessage } />
      <div className="tabs-container">
        <div className="tab-header">
          <h1 className="tab-heading float-left">1099 Details</h1>
          <div
            className="float-right mb-2"
            style={ { marginTop: '6px' } }
          >
          </div>
        </div>
        <div className="clearfix"></div>
        <div className="tab-panelbody">
          <div className="tab-holder mb-3 mt-2">
            <div className="mt-2">
              { (
                <div className="tab-holder p-0">
                  <TableComponent
                    print={ props.print }
                    fixedTable
                    align={ 'left' }
                    isSearch={ true }
                    headCells={ headCells }
                    tableData={ props.tableData ? props.tableData : [] }
                    onTableRowClick={ editRow }
                    defaultSortColumn={ headCells[0].id }
                  />
                </div>
              ) }
            </div>
          </div>
        </div>
        { (!opencorrectionDetailView)
          ? <Footer print={ true } /> : null }
        { opencorrectionDetailView
          ? (<div className="tabs-container">
            <ErrorMessages errorMessages={ subErrorMsg } />
            <div className="tab-header">
              <h1 className="tab-heading float-left">Edit 1099 Details</h1>
              <div
                className="float-right mb-2"
                style={ { marginTop: '6px' } }
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className="btn btn-success"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : false
                  }
                  onClick={ () => onMasterUpdateCorrectionDetail() }
                ><i class="fa fa-check" aria-hidden="true"></i>
                  Save
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="bt-reset btn-transparent ml-1"
                  disabled={
                    !userInquiryPrivileges
                      ? !userInquiryPrivileges
                      : correctionViewDetails.a1099Approval === 'Yes'
                  }
                  onClick={ () => handleReset(rowData) }
                >
                  <i class="fa fa-undo" aria-hidden="true"></i>
                  Reset
                </Button>
                <Button
                className={auditButtonToggle ? "btn btn-primary ml-1" : "btn btn-text1 btn-transparent ml-1"}
                onClick={() => {
                  setAuditButtonToggle(!auditButtonToggle);
                  setShowLogTable(!showLogTable);
                }}
              >
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                Audit Log
              </Button>
              </div>
            </div>
            <div className="tab-panelbody">
              <div className="tab-holder mb-3 mt-2">
                <AppBar position="static" className="outlineTab">
                  <Tabs
                    value={ value }
                    onChange={ handleChangeTabs }
                    aria-label="simple tabs example"
                  >
                    <Tab label="1099 Correction" />
                    <Tab label="Notes" />
                    <Tab label="Attachments" />
                  </Tabs>
                </AppBar>
                <div className="mt-2">
                  <TabPanel value={ value } index={ 0 }>
                    <CorrectionDetailView
                      auditData={auditData}
                      correctionViewDetails={ correctionViewDetails }
                      setcorrectionViewDetails={ setcorrectionViewDetails }
                      handleChangeDetails={ handleChangeDetails }
                      handleDecimalValues={handleDecimalValues}
                      showReasonCodeError={ showReasonCodeError }
                      showAdjustedAmountError={ showAdjustedAmountError }
                      showAdjustedAmountErrorText={ showAdjustedAmountErrorText }
                      reasonCodeData={ reasonCodeData }
                      updateDate={ updateDate }
                      letterSendDt={letterSendDt}
                      tableData={historyCorrectionDetailTableData}
                      handleLetterSendDateChange={handleLetterSendDateChange}
                      showUpdateDateError={ showUpdateDateError }
                      showLetterSendDateError ={showLetterSendDateError}
                      setShowAdditionalDetails={ setShowAdditionalDetails }
                      showAdditionalDetails={ showAdditionalDetails }
                      showLogTable={showLogTable}
                      setShowLogTable={setShowLogTable}
                      showReasonCodeErrorText={ showReasonCodeErrorText }
                      showUpdateDateErrorText={ showUpdateDateErrorText }
                      showLetterSendDateErrorText={showLetterSendDateErrorText}
                      amountFormatToDecimal={ amountFormatToDecimal }
                      setCorrectionHistoryRowData = {setCorrectionHistoryRowData}
                      setIsCorrectionHistoryRowData = {setIsCorrectionHistoryRowData}
                      removeAmountFormatToDecimal={ removeAmountFormatToDecimal }
                      dropdowns={addDropdowns}
                      systemList={systemList}
                      systemList26={systemList26}
                    />
                  </TabPanel>

                  <TabPanel value={ value } index={ 1 }>
                    { value === 1 && (
                      <div
                        className="tab-holder my-3 p-0"
                        style={ { marginTop: '-5px' } }
                      >
                        <Notes
                          disableNotes ={ correctionViewDetails?.a1099Approval === 'Yes' }
                          addNotes={ addNotes }
                          notesTableData={ notesTableData }
                          noteSetListInput={ noteSetListInput }
                          setNoteSetListInput={ setNoteSetListInput }
                          usageTypeCodeData={ usageTypeCodeData }
                          editNoteData={ editNoteData }
                          setFocusCheck={setFocusCheck}
                          setEditNoteData={ setEditNoteData }
                          setSuccessMessages={ clearSuccessErrorMsgs }
                        />
                      </div>
                    ) }
                  </TabPanel>

                  <TabPanel value={ value } index={ 2 }>
                    { value === 2 && (
                      <div
                        className="tab-holder my-3 p-0"
                        style={ { marginTop: '-5px' } }
                      >
                        <Attachments
                          disableAttachments={ correctionViewDetails?.a1099Approval === 'Yes' }
                          userInquiryPrivileges={ userInquiryPrivileges }
                          setAttachmentFile={ setAttachmentFile }
                          attachmentsTableData={ attachmentsTableData }
                          setAttachmentsTableData={ setAttachmentsTableData }
                          parentErrorMesage={ props.setErrorMessages }
                          setSystemSuccesMessages={ clearSuccessErrorMsgs }
                          setAllowNavigation={ setAllowNavigation }
                          attachmentFile={attachmentFile}
                          setFocusCheck={setFocusCheck}
                        />
                      </div>
                    ) }
                  </TabPanel>

                </div>
              </div>
            </div>
            <Footer print={ true } />
          </div>) : null
        }
      </div>
    </div>
  );
}
export default withRouter(CorrectionDetails);
