/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import EnhancedTableHead from "../../../SharedModules/Table/EnhancedTableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TablePaginationActions from "../../../SharedModules/Table/TablePagination";
import {
  desc,
  stableSort,
  getSorting
} from "../../../SharedModules/Table/TableSort";
import Spinner from "../../../SharedModules/Spinner/Spinner";
import Moment from "react-moment";
import numeral from "numeral";
import Checkbox from "@material-ui/core/Checkbox";
import { generateUUID } from "../../../SharedModules/DateUtilities/DateUtilities";
import { useSelector } from "react-redux";

const headCells = [
  {
    id: "statusCodeDesc",
    dataColumnName: "statusCodeDesc",
    isDropDown: true,
    numeric: false,
    disablePadding: true,
    width: "90",
    label: "Status"
  },
  {
    id: "addressUsageTypeCodeDesc",
    dataColumnName: "addressUsageTypeCodeDesc",
    isDropDown: true,
    numeric: false,
    width: "90",
    disablePadding: false,
    label: "Address Type"
  },
  {
    id: "beginDate",
    dataColumnName: "beginDate",
    isDate: true,
    numeric: false,
    disablePadding: false,
    width: "85",
    label: "Begin Date"
  },
  {
    id: "endDate",
    dataColumnName: "endDate",
    isDate: true,
    numeric: false,
    disablePadding: false,
    width: "85",
    label: "End Date"
  },
  {
    id: "addressLine1",
    dataColumnName: "addressLine1",
    numeric: false,
    disablePadding: false,
    width: "90",
    label: "Street Address"
  },
  {
    id: "city",
    dataColumnName: "city",
    numeric: false,
    disablePadding: false,
    width: "100",
    label: "City"
  },
  {
    id: "stateCodeDesc",
    dataColumnName: "stateCodeDesc",
    numeric: false,
    isDropDown: true,
    width: "100",
    disablePadding: false,
    label: "State"
  }
];

const headCellsWithoutAuditLog = [
  {
    id: "statusCodeDesc",
    dataColumnName: "statusCodeDesc",
    isDropDown: true,
    numeric: false,
    disablePadding: true,
    width: "90",
    label: "Status"
  },
  {
    id: "addressUsageTypeCodeDesc",
    dataColumnName: "addressUsageTypeCodeDesc",
    isDropDown: true,
    numeric: false,
    width: "90",
    disablePadding: false,
    label: "Address Type"
  },
  {
    id: "beginDate",
    dataColumnName: "beginDate",
    isDate: true,
    numeric: false,
    disablePadding: false,
    width: "85",
    label: "Begin Date"
  },
  {
    id: "endDate",
    dataColumnName: "endDate",
    isDate: true,
    numeric: false,
    disablePadding: false,
    width: "85",
    label: "End Date"
  },
  {
    id: "addressLine1",
    dataColumnName: "addressLine1",
    numeric: false,
    disablePadding: false,
    width: "90",
    label: "Street Address"
  },
  {
    id: "city",
    dataColumnName: "city",
    numeric: false,
    disablePadding: false,
    width: "100",
    label: "City"
  },
  {
    id: "stateCodeDesc",
    dataColumnName: "stateCodeDesc",
    numeric: false,
    isDropDown: true,
    width: "100",
    disablePadding: false,
    label: "State"
  }
];

export default function AddressTable(props) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(headCells[0].id);
  const [headCellState, setHeadCellState] = useState(
    props.addPage ? headCellsWithoutAuditLog : headCells
  );
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  const [selectedID, setSelectedID] = useState(null);

  useEffect(() => {
    props.rowDeleteAddress(selected);
  }, [selected]);

  useEffect(() => {
    setSelected([]);
    let countWithID = 0;
    props.tableData.map(values => {
      if (values.id) {
        countWithID++;
      }
    });
    if (countWithID > 0) {
      setShowHeaderCheckBox(true);
    } else {
      setShowHeaderCheckBox(false);
    }
  }, [props.tableData]);
  const [showHeaderCheckBox, setShowHeaderCheckBox] = useState(false);
  useEffect(() => {
    let countWithID = 0;
    props.tableData.map(values => {
      if (values.id) {
        countWithID++;
      }
    });
    if (countWithID > 0) {
      setShowHeaderCheckBox(true);
    } else {
      setShowHeaderCheckBox(false);
    }
  }, [props.tableData.length]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelected = [];
      for (var i = 0; i < props.tableData.length; i++) {
        if (
          props.tableData[i].id !== undefined &&
          !props.tableData[i].commonEntitySK
        ) {
          newSelected.push(props.tableData[i].id);
        } else {
          newSelected.push(null);
        }
      }
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
    } else if (selectedIndex === selected.length - 1) {
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat();
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatTableCell = (row, name, index, labelId, rowIndex) => {
    const rowclick = props.handleAddressTableAdd
      ? props.handleAddressTableAdd(row, rowIndex)
      : null;
    if (name.enableHyperLink) {
      return (
        <TableCell
          onClick={rowclick}
          style={{
            minWidth: name.width,
            maxWidth: name.width,
            width: name.width
          }}
          id={labelId}
          scope="row"
          padding="default"
          className="has-link"
        >
          <a>{row[name.dataColumnName]}</a>
        </TableCell>
      );
    }
    if (name.isDate && row[name.dataColumnName] !== null) {
      return (
        <TableCell
          onClick={rowclick}
          style={{
            minWidth: name.width,
            maxWidth: name.width,
            width: name.width
          }}
        >
          <Moment format="MM/DD/YYYY" date={row[name.dataColumnName]} />
        </TableCell>
      );
    }
    if (name.isDropDown) {
      return (
        <TableCell
          onClick={rowclick}
          style={{
            minWidth: name.width,
            maxWidth: name.width,
            width: name.width
          }}
        >
          {row[name.dataColumnName] !== "Please Select One"
            ? row[name.dataColumnName]
            : ""}
        </TableCell>
      );
    }
    if (name.isRadio) {
      if (!name.showRadio) {
        if (!row.audit_flag) {
          if (!row.id && !row.uuId) {
            return (
              <TableCell
                key={index || "21" + labelId || "21"}
                style={{ width: name.width }}
              >
                <label
                  aria-hidden={true}
                  hidden
                  for={
                    props.uniqueKey
                      ? props.print
                        ? row[props.uniqueKey] + "onScreen"
                        : row[props.uniqueKey]
                      : index || "21" + labelId || "21"
                  }
                >
                  Audit Log
                </label>
                <input
                  name="auditRadio"
                  type="radio"
                  className="radio-table"
                  value={false}
                  id={
                    props.uniqueKey
                      ? props.print
                        ? row[props.uniqueKey] + "onScreen"
                        : row[props.uniqueKey]
                      : index || "21" + labelId || "21"
                  }
                  checked={!props.close && selectedID === row[props.uniqueKey]}
                  onClick={() => {
                    if (props.uniqueKey) {
                      setSelectedID(row[props.uniqueKey]);
                      if (props.setClose) {
                        props.setClose(false);
                      }
                    }
                    if (props.onClickAuditDialogLog) {
                      props.onClickAuditDialogLog(row);
                    }
                  }}
                />
              </TableCell>
            );
          }
        }
      } else {
        if (!row.audit_flag) {
          return (
            <TableCell
              key={index || "21" + labelId || "21"}
              style={{ width: name.width }}
            >
              <label
                aria-hidden={true}
                hidden
                for={
                  props.uniqueKey
                    ? props.print
                      ? row[props.uniqueKey] + "onScreen"
                      : index || "21" + labelId || "21"
                    : row[props.uniqueKey]
                }
              >
                Audit Log
              </label>
              <input
                name="auditRadio"
                type="radio"
                className="radio-table"
                value={false}
                id={
                  props.uniqueKey
                    ? props.print
                      ? row[props.uniqueKey] + "onScreen"
                      : row[props.uniqueKey]
                    : index || "21" + labelId || "21"
                }
                checked={!props.close && selectedID === row[props.uniqueKey]}
                onClick={() => {
                  if (props.uniqueKey) {
                    setSelectedID(row[props.uniqueKey]);
                    if (props.setClose) {
                      props.setClose(false);
                    }
                  }
                  if (props.onClickAuditDialogLog) {
                    props.onClickAuditDialogLog(row);
                  }
                }}
              />
            </TableCell>
          );
        }
      }
    }
    return (
      <TableCell
        onClick={rowclick}
        style={{
          minWidth: name.width,
          maxWidth: name.width,
          width: name.width
        }}
      >
        {row[name.dataColumnName]}
      </TableCell>
    );
  };

  const isSelected = name => selected.indexOf(name) !== -1;
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.tableData.length - page * rowsPerPage);

  return (
    <div className={"pos-relative"}>
      {props.spinnerLoader ? <Spinner /> : null}
      <div className="table-wrapper">
        <Table
          className="customDataTable with-link fixedTable"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={props.tableData.length}
            headCells={headCellState}
            isSearch={false}
            showHeaderCheckBox={showHeaderCheckBox}
          />
          <TableBody>
            {stableSort(props.tableData, getSorting(order, orderBy, false))
              .slice(
                ...( [page * rowsPerPage, page * rowsPerPage + rowsPerPage])
              )
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id + index}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      {row.commonEntitySK ? null : (
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={event =>
                            handleClick(
                              event,
                              row.commonEntitySK ? row.commonEntitySK : row.id
                            )
                          }
                        />
                      )}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      hidden={true}
                      scope="row"
                      padding="default"
                    >
                      {generateUUID()}
                    </TableCell>
                    {row.id ? (
                      <TableCell
                        id={labelId}
                        hidden={true}
                        scope="row"
                        padding="default"
                      >
                        {row.id}
                      </TableCell>
                    ) : null}
                    {headCellState.map((name, colindex) =>
                      formatTableCell(row, name, colindex, labelId, index)
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={headCellState.length} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className="table-pagination">
                Page: <span>{page + 1}</span>
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={headCellState.length}
                count={props.tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
}
