/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../../../../../SharedModules/TabPanel/TabPanel';
import Attachments from '../../../../../../../SharedModules/Attachments/Attachments';
import { redirectSystemID } from '../../../../../../../SharedModules/TabRedirections/TabRedirections';
import { Link, Prompt } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import {
  DialogActions,
  DialogContent
} from '../../../../../../../SharedModules/Dialog/DialogUtilities';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as ErrorConstants from '../../ErrorCheck';
import LienLevyDetailsComponent from './LienLevyDetailsComponent';
import LienLevyHistoryTableComponent from './LienLevyHistoryComponent';
import ErrorMessages from '../../../../../../../SharedModules/Errors/ErrorMessages';
import PaymentScheduleTable from './paymentScheduleTable';
import * as moment from 'moment';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as commonMethods from '../../../../../../../SharedModules/CommonUtilities/commonUtilities';
import {
  getUTCTimeStamp,
  generateUUID,
  validateDateMinimumValue,
  compareTwoDates,
  compareTwoDatesGreaterThanOrEqual,
  getDateInMMDDYYYYFormatWithApendZero
} from '../../../../../../../SharedModules/DateUtilities/DateUtilities';
import {
  getWriteOffRestablishmentDetailsReset,
  getWriteOffRestablishmentDetails,
  getWriteOffDetailsReset,
  getDropdownDataAction,
  getWriteOffDetails,
  getPayeeIdType,
  lienLevyUpdate,
  lienLevyHolderIdTypeDropDownAction,
  agingDaysList,
  ARTransferEligibility,
  ARReverseEligibility,
  ARAdjustEligibility,
  getDefaultRecoupmentDetails,
  validateLienHolderDetails,
  searchFCNAccountReceivableAction,
  searchClaimsReceivableAction
} from '../../../../../Store/Actions/accountReceivable/accountReceivableActions';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSystemParameterSpecificValue,
  setPrintLayout,
  getAuditLogDetailsAccountingAction,
  getMaxFrequencyParameter
} from '../../../../../../../SharedModules/store/Actions/SharedAction';
import SuccessMessages from '../../../../../../../SharedModules/Errors/SuccessMessage';
import Spinner from '../../../../../../../SharedModules/Spinner/Spinner';

import * as ARSearchConstants from '../../../ARSearch/ARSearchConstants';
import * as AppConstants from '../../../../../../../SharedModules/AppConstants';
import NoSaveMessage from '../../../../../../../SharedModules/Errors/NoSaveMessage';
import dropdownCriteria from './LienLevyUpdate.json';

import AuditTableComponent from '../../../../../../../SharedModules/AuditLog/AuditTableComponent';
import ReactToPrint from 'react-to-print';
import Footer from '../../../../../../../SharedModules/Layout/footer';
import { searchSingleRecordFinancialEntity } from '../../../../../../FinancialEntities/Store/Actions/FinancialEntityActions';
import Notes from '../../../../../../../SharedModules/Notes/Notes';
import numeral from 'numeral';
import Radio from '@material-ui/core/Radio';
import { searchSystemParameterAction } from '../../../../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import SystemPayeeTable from '../../../../../../../SharedModules/SystemIDTable/SystemPayeeIDTable';
import { getLongDescription } from '../../../../../../../SharedModules/CommonUtilities/commonUtilities';
import { GET_APP_DROPDOWNS } from "../../../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../../../../SharedModules/Dropdowns/NativeDropDown';

const useStyles = makeStyles(theme => ({}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

export default function FinancialAccountLienLevyUpdate(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  // Audit Log Implementation starts
  const [close, setClose] = React.useState(true);
  const [showLogTable, setShowLogTable] = React.useState(false);
  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const onClickAuditDialogLog = data => {
    setShowLogDialogTable(true);
    dispatch(
      getAuditLogDetailsAccountingAction(
        data.scheduleSK,
        '',
        true,
        'auditDialogData'
      )
    );
  };
  const onClickAuditLog = fcn => {
    dispatch(
      getAuditLogDetailsAccountingAction(fcn, '', false, 'auditLogData')
    );
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);
  useEffect(() => {
    if (showLogDialogTable && document.getElementById('audit-dialog-table')) { document.getElementById('audit-dialog-table').scrollIntoView(); }
  }, [showLogDialogTable]);
  const auditLogData = useSelector(state => state.sharedState.auditLogData);
  const auditDialogData = useSelector(
    state => state.sharedState.auditDialogData
  );
  const [auditDialogRowData, setAuditDialogRowData] = React.useState([]);
  const [auditRowData, setAuditRowData] = React.useState([]);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  useEffect(() => {
    if (auditDialogData) {
      setAuditDialogRowData(auditDialogData);
    }
    if (auditLogData) {
      setAuditRowData(auditLogData);
    }
  }, [auditDialogData, auditLogData]);
  // Audit Log Implementation ends

  // Notes Integration  Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });

  const [recoupmentTypeClicked, setRecoupmentTypeClicked] = React.useState(false);
  const [noteSetListInput, setNoteSetListInput] = React.useState({});
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [selectedRecoupmentDate, setSelectedRecoupmentDate] = React.useState(
    null
  );
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState('');
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState('');
  const [selectedDueDate, setSelectedDueDate] = React.useState(null);
  const [selectedDefaultDate, setSelectedDefaultDate] = React.useState(null);
  const [paymentScheduleData, setPaymentSchedleData] = React.useState([]);

  const [manualEditFlag, setManualEditFlag] = React.useState(false);
  const [lienLevy, setLienLevy] = React.useState({});
  const [retainValues, setRetainValues] = React.useState({});

  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (open) {
      document.getElementById('form_pop_up').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [open]);
  const errorMessagesArray = [];
  const popUpErrorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    {
      showOriginalArAmmountError,
      showFiscalYearError,
      showLienHolderTypeError,
      showLienHolderTypeCodeError,
      showLienHolderIDError,
      showCollocationCodeError,
      showRecoupmentTypeCodeError,
      showDueDateError,
      showAmountDueError,
      showInvalidBeginDateError,
      showInvalidEndDateError,
      showEndDateLesserError,
      showBeginDateRequiredError,
      showEndDateRequiredError,
      showPercentError,
      showInstallementError,
      showStartDateRequiredError,
      showDueDateGreaterError,
      showMinBeginDateError,
      showMinEndDateError,
      showMinRecoupmentDateError,
      showMinDueDateError,
      showMinDefaultDateError,
      showDefaultDateError,
      showDueDateInvalidError,
      showNegativeAmountError,
      showGreaterThanZeroAmountError,
      showOriginalArAmountPopError,
      showFrequencyError,
      showATRError
    },
    setShowError
  ] = React.useState(false);
  const [
    deleteSchedulePaymentList,
    setDeleteSchedulePaymentList
  ] = React.useState([]);
  const [
    {
      showOriginalArAmountErrorText,
      showFiscalYearErrorText,
      showLienHolderTypeErrorText,
      showLienHolderTypeCodeErrorText,
      showLienHolderIDText,
      showCollocationCodeErrorText,
      showRecoupmentTypeCodeErrorText,
      showDueDateErrorText,
      showAmountDueErrorText,
      showInvalidBeginDateText,
      showInvalidEndDateInvalidText,
      showEndDateLesserErrorText,
      showBeginDateRequiredErrorText,
      showEndDateRequiredErrorText,
      showPercentErrorText,
      showInstallmentErrorText,
      showStartDateErrorText,
      showStartDateRequiredErrorText,
      showDueDateGreaterErrorText,
      showMinBeginDateErrorText,
      showMinEndDateErrorText,
      showMinRecoupmentDateErrorText,
      showMinDueDateErrorText,
      showMinDefaultDateErrorText,
      showDefaultDUeDateErrorText,
      showDueDateInvalidErrorText,
      showNegativeAmmountErrorText,
      showGreaterThanZeroAmountText,
      showOriginalArAmountPopErrorText,
      showFrequencyErrorText,
      showATRErrorText
    },
    setShowErrorText
  ] = React.useState('');

  const [popupErrorMessages, setPopUpErrorMessage] = React.useState([]);

  const [tabOne, setTabOne] = React.useState(true);
  const [tabTwo, setTabTwo] = React.useState(false);
  const [tabThree, setTabThree] = React.useState(false);
  const [tabFour, setTabFour] = React.useState(false);
  const [add, setAdd] = React.useState(true);
  const [retainEdit, setRetainEdit] = React.useState({});
  const [editData, setEditData] = React.useState({});
  const [retainDueAmout, setREtainDueAmout] = React.useState('');
  const [retainDefaultIndicator, setDefaultIndicator] = React.useState('N');
  const [retainDefaultDate, setRetainDefaultDate] = React.useState(null);
  const [retainDueDate, setRetainDueDate] = React.useState(null);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [payoutRCodes, setPayoutRCodes] = React.useState([]);
  const [receiptRCodes, setReceiptRCodes] = React.useState([]);
  const [accountRRCodes, setAccountRRCodes] = React.useState([]);
  const [dispositionRCodes, setDispositionRCodes] = React.useState([]);
  const [dropDownCheckList, setDropDownCheckList] = React.useState([]);
  const [
    selectedInitialPaymentDate,
    setSelectedInitialPaymentDate
  ] = React.useState(null);
  const [selectedSatisfiedDate, setSelectedSatisfiedDate] = React.useState(
    null
  );
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);
  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [canCel,setCancel]=React.useState(false)
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    setFocusCheck(true);
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSystemSuccesMessages([]);
    seterrorMessages([]);
    setShowErrorText('');
    setShowError([]);
  };

  let paramsData;

  useEffect(() => {
    if (
      props.location &&
      props.location.state &&
      props.location.state.withHoldSearchResult
    ) {
      paramsData = props.location.state.withHoldSearchResult;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
      if (
        paramsData &&
        paramsData.attachmentsList &&
        paramsData.attachmentsList.length > 0
      ) {
        setAttachmentsTableData(paramsData.attachmentsList);
      } else {
        setAttachmentsTableData([]);
      }
      setAttachmentFile([]);
    }
  }, [
    props.location &&
      props.location.state &&
      props.location.state.withHoldSearchResult
      ? props.location.state.withHoldSearchResult
      : props.location
  ]);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: getUTCTimeStamp(),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: getUTCTimeStamp(),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End
  const [color, setColor] = React.useState('');
  const [writeOffSaveDetails, setWriteOffSaveDetails] = React.useState();
  const [ClaimsReceivableSaveWO, setClaimsReceivableSaveWO] = React.useState();
  const classes = useStyles();

  const lienLevyDetails = writeOffSaveDetails && writeOffSaveDetails.respcode === '01' && ClaimsReceivableSaveWO !== undefined
    ? ClaimsReceivableSaveWO : props.location.state.withHoldSearchResult;
  const claimsData = lienLevyDetails;
  const [rowPaymentScheduleData, setRowPaymentScheduleData] = React.useState(
    []
  );
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [lobCodeData, setLobCodeData] = React.useState([]);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [adjustedReasonCode, setAdjustedReasonCode] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [payeeTypeIdCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [lienHolderIdTypeData, setLienHolderIdTypeData] = React.useState([]);
  const [lienHolderTypeData, setLienHolderTypeData] = React.useState([]);
  const [recoupmentData, setRecoupmentData] = React.useState([]);
  const [frequencyData, setFrequencyData] = React.useState([]);
  const [COSData, setCOSData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  const [redirect, setRedirect] = React.useState(0);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDropdownData(dropdownCriteria);
    onAgingDaysList();
    getPayeeIdFromSystemParameter({
      paramNumber: 9025,
      functionalArea: 'F1',
      lobCode: lienLevyDetails.lob ? lienLevyDetails.lob : null,
      asOfDate: moment(
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      ).format('YYYY-DD-MM')
    });
    onDropdowns([
      Dropdowns.PAYEE_TYPE,     
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.F_RSN_CD,
      Dropdowns.ACTIVITY_TYPE,
    ]);
  }, []);
  const [systemSuccessMessages, setSystemSuccesMessages] = React.useState(
    props.location.state && props.location.state.systemSuccessMessages
      ? props.location.state.systemSuccessMessages
      : []
  );

  const [historyTableData, setHistoryTableData] = React.useState(
    props.location.state && props.location.state.withHoldSearchResult
      ? props.location.state.withHoldSearchResult.historyList
      : []
  );
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const checkARTransfer = values => {
    dispatch(ARTransferEligibility(values));
  };
  const transferDetails = useSelector(
    state => state.accountReceivableState.arTransferEligibleDetails
  );
  const checkARREverse = values => {
    dispatch(ARReverseEligibility(values));
  };
  const reverseDetails = useSelector(
    state => state.accountReceivableState.arReverseEligibleDetails
  );
  const checkARAdjust = values => {
    dispatch(ARAdjustEligibility(values));
  };
  const adjustDetails = useSelector(
    state => state.accountReceivableState.arAdjustEligibleDetails
  );
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const printLayout = useSelector(state => state.sharedState.printLayout);
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const onPayeeTypeIdDropDown = value => dispatch(getPayeeIdType(value));
  const onAgingDaysList = () => dispatch(agingDaysList());

  const claimsReceivableDataWriteOff = useSelector(
    state => state.accountReceivableState.payloadClaims
  );
  const writeOffDetailsSave = useSelector(state => state.writeOffState.saveWriteOffData);

  React.useEffect(() => {
    if (writeOffDetailsSave !== undefined) {
      setWriteOffSaveDetails(writeOffDetailsSave);
      dispatch(searchClaimsReceivableAction(writeOffDetailsSave.newFCN));
    }
  }, [writeOffDetailsSave]);

  React.useEffect(() => {
    if (claimsReceivableDataWriteOff !== undefined) { setClaimsReceivableSaveWO(claimsReceivableDataWriteOff); }
  }, [claimsReceivableDataWriteOff]);

  const onLienLevyUpdate = (value, index) =>
    dispatch(
      lienLevyUpdate(
        value,
        attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
          ? attachmentFile
          : null
      )
    );
  const onLienLevyHolderIdTypeDropDown = value =>
    dispatch(lienLevyHolderIdTypeDropDownAction(value));

  const [writeOff, setWriteOff] = React.useState(true);
  const fetchWriteOffData = values => {
    dispatch(getWriteOffDetails(values));
  };
  const writeOffDetails = useSelector(
    state => state.accountReceivableState.writeOffData
  );
  const fetchWriteOffReEstablishmentData = values => {
    dispatch(getWriteOffRestablishmentDetails(values));
  };
  const writeOffReEstablishmentDetails = useSelector(
    state => state.accountReceivableState.writeOffRestablishmentData
  );
  const onGetRecoupmetDetails = values => {
    dispatch(getDefaultRecoupmentDetails(values));
  };
  const getRecoupmetDetails = useSelector(
    state => state.accountReceivableState.fetchRecoupmetDetails
  );
  const userInquiryPrivileges = !global.globalIsReadOnly();

  let dropdown = [];
  let payeeTypeIdCodeDropdown = [];
  let lienLevyHolderIdTypeDropDown = [];
  let arAgingDays = [];
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  const searchSystemParameterPercentage = value => {
    dispatch(searchSystemParameterAction(value));
  };
  const systemParameterPayloadPercentage = useSelector(
    state => state.appConfigState.systemParameterState.payload
  );
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);

  let payeeTypeIdDropDown = [];
  payeeTypeIdDropDown = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );

  const systemParamValuetoPercentage = {
    parameterNumber: '9005',
    paramNumber: null,
    functionalArea: 'F1',
    description: null,
    parameterNumberStartsWith: false,
    descriptionStartsOrContains: null,
    withoutNotesDataFlag: false
  };

  useEffect(() => {
    if (
      systemParameterPayloadPercentage &&
      systemParameterPayloadPercentage.length > 0
    ) {
      const systemParameterPercentage =
        systemParameterPayloadPercentage[0].systemParameterDetail[0].valuePCT *
        100;
      const percentValue = systemParameterPercentage;
      const installementValue = values.installmentValue;
      const recoupmentTypeCode = 'D';
      const frequency = '1';
      const amount = '';
      const maxWithholdAmount = values.maxWithholdAmount;
      const recoupmentsection = {
        percent: percentValue,
        installment: installementValue
          ? numeral(installementValue).format('0,0.00')
          : installementValue,
        recoupment: recoupmentTypeCode,
        frequency: frequency,
        amount: amount,
        maxWithholdAmount: maxWithholdAmount
      };
      setValues(setInputValues(lienLevy, values, recoupmentsection));
    }
  }, [systemParameterPayloadPercentage]);
  React.useEffect(() => {
    setSpinnerLoader(false);
    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: {
          searchSingleFinancialEntityRes, values: valuesFromAREdit, payloadPayees: payloadPayees
        },
        forAdd: true
      });
    }
  }, [searchSingleFinancialEntityRes]);

  useEffect(() => {
    if (
      getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && !recoupmentTypeClicked
    ) {
      const amount = '';
      const originalARAmount = retainValues.originalARAmount;
      const maxWithholdAmount = retainValues.maxWithholdAmount;
      const recoupmentsection = {
        percent: getRecoupmetDetails.recoupmentVO.percentageOriginalAmount,
        installment: getRecoupmetDetails.recoupmentVO.installmentAmount
          ? numeral(getRecoupmetDetails.recoupmentVO.installmentAmount).format(
            '0,0.00'
          )
          : getRecoupmetDetails.recoupmentVO.installmentAmount,
        recoupment: getRecoupmetDetails.recoupmentVO.recoupmentTypeCode,
        frequency: getRecoupmetDetails.recoupmentVO.frequency,
        amount: amount,
        originalARAmount: originalARAmount,
        maxWithholdAmount: maxWithholdAmount
      };
      if (
        recoupmentsection.frequency !== values.frequency ||
        recoupmentsection.recoupment !== values.recoupmentTypeCode ||
        Number(recoupmentsection.installment) !== Number(values.installmentValue) ||
        Number(recoupmentsection.percent) !== Number(values.percentValue) ||
        getRecoupmetDetails.recoupmentVO.startDateStr !== moment(selectedRecoupmentDate).format('MM/DD/YYYY')
      ) { setFocusCheck(true); }
      setSelectedRecoupmentDate(moment(getRecoupmetDetails.recoupmentVO.startDateStr).format('MM/DD/YYYY'));
      setValues(setInputValues(lienLevy, values, recoupmentsection));
      seterrorMessages([]);
    } else if (getRecoupmetDetails &&
      !getRecoupmetDetails.systemFailure &&
      getRecoupmetDetails.recoupmentVO && recoupmentTypeClicked) {
      setSelectedRecoupmentDate(moment(getRecoupmetDetails.recoupmentVO.startDateStr).format('MM/DD/YYYY'));
    }
    if (getRecoupmetDetails && getRecoupmetDetails.systemFailure) {
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [getRecoupmetDetails]);

  useEffect(() => {
    if (lienLevyDetails) {
      const percentValue =
        lienLevyDetails &&
          lienLevyDetails.recoupmentVO &&
          lienLevyDetails.recoupmentVO.percentageOriginalAmount
          ? lienLevyDetails.recoupmentVO.percentageOriginalAmount
          : '';
      const installementValue =
        lienLevyDetails &&
          lienLevyDetails.recoupmentVO &&
          lienLevyDetails.recoupmentVO.installmentAmount
          ? numeral(lienLevyDetails.recoupmentVO.installmentAmount).format(
            '0,0.00'
          )
          : '';
      const recoupmentTypeCode =
        lienLevyDetails && lienLevyDetails.recoupmentVO
          ? lienLevyDetails.recoupmentVO.recoupmentTypeCode
          : DEFAULT_DD_VALUE;
      const frequency =
        lienLevyDetails && lienLevyDetails.recoupmentVO
          ? lienLevyDetails.recoupmentVO.frequency
          : DEFAULT_DD_VALUE;
      const amount = '';
      const originalARAmount =
        lienLevyDetails && lienLevyDetails.originalAmount
          ? numeral(lienLevyDetails.originalAmount).format('0,0.00')
          : 0;
      const maxWithholdAmount =
        lienLevyDetails &&
          lienLevyDetails.withholdingVO &&
          lienLevyDetails.withholdingVO.maxWithholdAmount
          ? numeral(
            Number(
              lienLevyDetails.withholdingVO.maxWithholdAmount.replace(
                /,/g,
                ''
              )
            ).toPrecision()
          ).format('0,0.00')
          : '';
      const recoupmentsection = {
        percent: percentValue,
        installment: installementValue
          ? numeral(installementValue).format('0,0.00')
          : installementValue,
        recoupment: recoupmentTypeCode,
        frequency: frequency,
        amount: amount,
        originalARAmount: originalARAmount,
        maxWithholdAmount: maxWithholdAmount
      };
      setInput(lienLevyDetails, recoupmentsection);
      setLienLevy(JSON.parse(JSON.stringify(lienLevyDetails)));
      const scheduleDataInitial = lienLevyDetails.schedulePaymentList.map(
        (option, index) => {
          const overlapId = { overlapId: generateUUID() };
          return { ...option, ...overlapId };
        }
      );
      setPaymentSchedleData(scheduleDataInitial);

      if (lienLevyDetails.activityTypeCode === 'WO') {
        setWriteOff(false);
      }
    }
  }, [lienLevyDetails]);

  useEffect(() => {
    onPayeeTypeChange(
      lienLevyDetails ? lienLevyDetails.payeePayerType : values.payeeType
    );
  }, [lienLevyDetails ? lienLevyDetails.payeePayerType : values.payeeType]);

  const onSystemPayeeIdclick = systemPayeeId => event => {
    setSpinnerLoader(true);
    dispatch(searchSingleRecordFinancialEntity(systemPayeeId));
    setRedirect(1);
  };
  let payload = [];

  const onSearch = fcn => dispatch(searchFCNAccountReceivableAction(fcn));
  payload = useSelector(state => state.accountReceivableState.payload);
  const withHoldingStatus = localStorage.getItem("withHoldingStatus");
  // Redirect to edit screen
  useEffect(() => {
    if (payload && payload.respcode === '02' && payload.respdesc !== '') {
      errorMessagesArray.push(payload.respdesc);
      seterrorMessages(errorMessagesArray);
    }
    if (
      payload != null &&
      payload !== '' &&
      payload.respcode === undefined &&
      payload.systemFailure === undefined
    ) {
      const withHoldSearchResult = payload;
      props.history.push({
        pathname: '/FinancialAccountLienLevyUpdate',
        state: { withHoldSearchResult, systemSuccessMessages, values: valuesFromAREdit, payloadPayees: payloadPayees, withHoldingStatus: withHoldingStatus }
      });

      const scheduleDataInitial = payload.schedulePaymentList.map(
        (option, index) => {
          const overlapId = { overlapId: generateUUID() };
          return { ...option, ...overlapId };
        }
      );
      setPaymentSchedleData(scheduleDataInitial);
    }
    if (payload && payload.systemFailure !== undefined) {
      errorMessagesArray.push(ErrorConstants.TRANSACTION_ERROR);
      seterrorMessages(errorMessagesArray);
    }
  }, [payload]);
  // System Payee redirection ends
  useEffect(() => {
    checkAmounts();
  }, [lienLevyDetails]);

  dropdown = useSelector(state => state.accountReceivableState.dropdownData);
  payeeTypeIdCodeDropdown = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );
  lienLevyHolderIdTypeDropDown = useSelector(
    state => state.accountReceivableState.lienLevyHolderIdType
  );
  arAgingDays = useSelector(
    state => state.accountReceivableState.agingDaysList
  );
  const lienLevyUpdateResponse = useSelector(
    state => state.accountReceivableState.lienLevyUpdate
  );
  const sucessMessages = [];
  const systemErrorMessges = [];

  const onValidateLienHolderDetails = value =>
    dispatch(validateLienHolderDetails(value));
  const onValidateLienHolderResponse = useSelector(
    state => state.accountReceivableState.lienHolderDetails
  );

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog(values.fcn);
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditDialogRowData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditDialogRowData([]);
    }
  }, [auditLogRow]);

  useEffect(() => {
    if (
      onValidateLienHolderResponse &&
      !onValidateLienHolderResponse.systemFailure &&
      onValidateLienHolderResponse.respcode &&
      onValidateLienHolderResponse.respcode === '01' &&
      onValidateLienHolderResponse.enterpriseBaseVO
    ) {
      setSpinnerLoader(false)
      const onValidateResult = onValidateLienHolderResponse.enterpriseBaseVO;
      if (onValidateResult) {
        if (
          onValidateResult &&
          onValidateResult.multiplePayeeDetails &&
          onValidateResult.multiplePayeeDetails.length > 1
        ) {
          setOpenSysPayeeTable(true);
          setSystemPayeeTableData(onValidateResult.multiplePayeeDetails);
        } else {
          setValues({
            ...values,
            lienHolderName: onValidateResult.thirdPartyHolderName,
            lienHolderId: onValidateResult.thirdPartyID
          });
        }
      }
    } else if (
      onValidateLienHolderResponse &&
      !onValidateLienHolderResponse.systemFailure &&
      onValidateLienHolderResponse.respcode &&
      onValidateLienHolderResponse.respcode === '02'
    ) {
      setSpinnerLoader(false)
      errorMessagesArray.push(onValidateLienHolderResponse.respdesc);
      seterrorMessages(errorMessagesArray);
      setValues({
        ...values,
        lienHolderName: ''
      });
    } else if (
      onValidateLienHolderResponse &&
      onValidateLienHolderResponse.systemFailure
    ) {
      setSpinnerLoader(false)
      errorMessagesArray.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
      setValues({
        ...values,
        lienHolderName: ''
      });
    }
  }, [onValidateLienHolderResponse]);

  useEffect(() => {
    if (
      writeOffDetails &&
      writeOffDetails.length !== 0 &&
      !writeOffDetails.SystemError
    ) {
      if (writeOffDetails.errorMessgaes !== null) {
        setSpinnerLoader(false);
        seterrorMessages([writeOffDetails.errorMessgaes[0]]);
      } else {
        setSpinnerLoader(false);
        props.history.push({
          pathname: '/WriteOff',
          state: { writeOffDetails, values: valuesFromAREdit, payloadPayees: payloadPayees }
        });
      }
    }
    if (writeOffDetails && writeOffDetails.SystemError === true) {
      setSpinnerLoader(false);
      seterrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
  }, [writeOffDetails]);

  useEffect(() => {
    if (
      writeOffReEstablishmentDetails &&
      !writeOffReEstablishmentDetails.SystemError &&
      writeOffReEstablishmentDetails.length !== 0
    ) {
      if (writeOffReEstablishmentDetails.errorMessgaes !== null) {
        setSpinnerLoader(false);
        seterrorMessages([writeOffReEstablishmentDetails.errorMessgaes[0]]);
      } else {
        props.history.push({
          pathname: '/WriteOffReEstablishment',
          state: { writeOffReEstablishmentDetails, values: valuesFromAREdit, payloadPayees: payloadPayees }
        });
      }
    }
    if (
      writeOffReEstablishmentDetails &&
      writeOffReEstablishmentDetails.SystemError === true
    ) {
      setSpinnerLoader(false);
      errorMessagesArray.push(ErrorConstants.TRANSACTION_ERROR);
      seterrorMessages([ErrorConstants.TRANSACTION_ERROR]);
    }
  }, [writeOffReEstablishmentDetails]);

  useEffect(() => {
    if (
      transferDetails &&
      transferDetails.transferVO !== null &&
      transferDetails.transferVO !== undefined
    ) {
      claimsData.transferVO = transferDetails.transferVO;
      props.history.push({
        pathname: '/ARTransfer',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (transferDetails && transferDetails.SystemError === true) {
      seterrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
    if (
      transferDetails &&
      transferDetails.transferVO === null &&
      transferDetails.errorMessgaes &&
      transferDetails.errorMessgaes !== null &&
      transferDetails.errorMessgaes.length > 0
    ) {
      seterrorMessages(transferDetails.errorMessgaes);
    }
  }, [transferDetails]);
  useEffect(() => {
    if (
      reverseDetails &&
      reverseDetails.reversalVO !== null &&
      reverseDetails.reversalVO !== undefined
    ) {
      claimsData.reversalVO = reverseDetails.reversalVO;
      props.history.push({
        pathname: '/ARReverse',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (reverseDetails && reverseDetails.reversalVO === null) {
      seterrorMessages([ARSearchConstants.REVERSE_ERROR]);
    }
    if (reverseDetails && reverseDetails.SystemError === true) {
      seterrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
    if (
      reverseDetails &&
      reverseDetails.reversalVO === null &&
      reverseDetails.errorMessgaes &&
      reverseDetails.errorMessgaes !== null &&
      reverseDetails.errorMessgaes.length > 0
    ) {
      seterrorMessages(reverseDetails.errorMessgaes);
    }
  }, [reverseDetails]);
  useEffect(() => {
    if (
      adjustDetails &&
      adjustDetails.adjustmentVO !== null &&
      adjustDetails.adjustmentVO !== undefined
    ) {
      claimsData.adjustmentVO = adjustDetails.adjustmentVO;
      props.history.push({
        pathname: '/ARAdjust',
        state: { claimsData, values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
    if (adjustDetails && adjustDetails.SystemError === true) {
      seterrorMessages([ARSearchConstants.TRANSACTION_ERROR]);
    }
    if (
      adjustDetails &&
      adjustDetails.adjustmentVO === null &&
      adjustDetails.errorMessgaes &&
      adjustDetails.errorMessgaes !== null &&
      adjustDetails.errorMessgaes.length > 0
    ) {
      seterrorMessages(adjustDetails.errorMessgaes);
    }
  }, [adjustDetails]);
  function ARAdjust() {
    checkValidationLink();
    if (errorMessagesArray.length <= 0) {
      if (
        lienLevyDetails.balanceAmount === '0' ||
        lienLevyDetails.balanceAmount === '0.0' ||
        lienLevyDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.ADJUSTED_BALNACE_AMOUNT_ZERO_ERROR
        );
        seterrorMessages(systemErrorMessges);
      } else {
        seterrorMessages([]);
        setSystemSuccesMessages([]);
        claimsData.accountVO.scoaFiscalYear = values.fiscalYear;
        claimsData.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
          selectedRecoupmentDate
        );
        claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
          '-'
        )[0];
        claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
          '-'
        )[0];
        claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
        checkARAdjust({ ...claimsData, withHoldingStatus: withHoldingStatus });
      }
    }
  }
  function ARReverse() {
    checkValidationLink();
    if (errorMessagesArray.length <= 0) {
      claimsData.accountVO.scoaFiscalYear = values.fiscalYear;
      claimsData.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
        selectedRecoupmentDate
      );
      claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
        '-'
      )[0];
      claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
        '-'
      )[0];
      claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
      if (
        values.adjustedARAmount &&
        values.balanceAmount &&
        values.adjustedARAmount.replace(/,/g, '') ===
        values.balanceAmount.replace(/,/g, '')
      ) {
        checkARREverse({ ...claimsData, withHoldingStatus: withHoldingStatus });
      } else {
        seterrorMessages([ARSearchConstants.REVERSE_ERROR]);
      }
    }
  }
  function ARTransfer() {
    checkValidationLink();
    if (errorMessagesArray.length <= 0) {
      if (
        lienLevyDetails.balanceAmount === '0' ||
        lienLevyDetails.balanceAmount === '0.0' ||
        lienLevyDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.TRANSFER_BALNACE_AMOUNT_ZERO_ERROR
        );
        seterrorMessages(systemErrorMessges);
      } else {
        seterrorMessages([]);
        setSystemSuccesMessages([]);
        claimsData.accountVO.scoaFiscalYear = values.fiscalYear;
        claimsData.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
          selectedRecoupmentDate
        );
        claimsData.accountReceivableReasonCode = claimsData.accountReceivableReasonCode.split(
          '-'
        )[0];
        claimsData.payeePayerTypeCode = claimsData.payeePayerTypeCode.split(
          '-'
        )[0];
        claimsData.activityTypeCode = claimsData.activityTypeCode.split('-')[0];
        checkARTransfer({ ...claimsData, withHoldingStatus: withHoldingStatus });
      }
    }
  }

  const [frequencyDataDropdown, setFrequencyDataDropdown] = React.useState([]);
  const [
    selectedRecoupmentStartDate,
    setSelectedRecoupmentStartDate
  ] = React.useState(null);
  const [retainSelectedDueDate, setRetainSelectedDueDate] = React.useState(
    null
  );
  const handleRecoupmentStartDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentStartDate(date);
    setPaymentSettingChanged(generateUUID());
    setPaymentSchedleData([]);
  };
  const [paymentScheduleVO, setPaymentScheduleVO] = React.useState({
    installmentAmount: '',
    recoupmentAmount: '0.00'
  });
  const [resetPaymentScheduleVO] = React.useState({
    installmentAmount: '',
    recoupmentAmount: '0.00'
  });
  const handleChangePaymentSchedule = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setPaymentScheduleVO({
      ...paymentScheduleVO,
      [name]: event.target.value
    });
  };
  const [paymentScheduleChanged, setPaymentScheduleChanged] = React.useState(
    false
  );
  const [paymentInd, setpaymentInd] = React.useState(false);
  useEffect(() => {
    if (paymentScheduleChanged) {
      setPaymentSchedleData([]);
      setpaymentInd(true);
      setValues({
        ...values,
        recoupmentStartDate: null,
        numberofInstallments: '',
        interestCalculation: 'N',
        interestPercentage: '',
        paymentScheduleFrequency: DEFAULT_DD_VALUE
      });
      setSelectedRecoupmentStartDate(null);
    }
  }, [paymentScheduleChanged]);
  const [paymentSettingChanged, setPaymentSettingChanged] = React.useState(
    false
  );
  useEffect(() => {
    if (paymentSettingChanged) {
      setPaymentSchedleData([]);
      setpaymentInd(true);
    }
  }, [paymentSettingChanged]);
  const [
    paymentScheduleTypeError,
    setPaymentScheduleTypeError
  ] = React.useState('');
  const [
    recoupmentStartDateError,
    setRecoupmentStartDateError
  ] = React.useState('');
  const [
    numberofInstallmentError,
    setNumberofinstallmentError
  ] = React.useState('');
  const [frequencyError, setFrequencyError] = React.useState('');
  const [interestCalError, setInstallmentCalError] = React.useState('');
  const [values, setValues] = React.useState({
    originalARAmount: '',
    maxWithholdAmount: '',
    approval: 'N',
    underAppeal: 'N',
    fiscalYear: '',
    categoryOfService: DEFAULT_DD_VALUE,
    payeeStatus: DEFAULT_DD_VALUE,
    collocationCode: DEFAULT_DD_VALUE,
    recoupmentTypeCode: DEFAULT_DD_VALUE,
    installmentValue: '',
    percentValue: '',
    frequency: DEFAULT_DD_VALUE,
    exemptFromPenalty: 'N',
    exemptFromInterest: 'N',
    exemptFromCollectionAgency: 'N',
    amountDue: '',
    defaultOnPaymentSchedule: null,
    lob: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE,
    activityCode: DEFAULT_DD_VALUE,
    systemPayeeId: '',
    payeeId: '',
    payeePayerName: '',
    fcn: '',
    adjustedARAmount: '',
    balanceAmount: '',
    relatedFTTCN: '',
    establishedDate: '',
    lastDateApplied: '',
    arAgeInDays: '',
    arLettersent: '',
    agingCode: '',
    calculatedInterest: '',
    calculatedPenalty: '',
    feesAssesed: '',
    originalARAmountData: 0,
    appliedAmountData: 0,
    balanceAmountData: 0,
    lienHolderType: DEFAULT_DD_VALUE,
    lienHolderIdType: DEFAULT_DD_VALUE,
    lienHolderName: '',
    lienHolderId: '',
    paymentScheduleType: DEFAULT_DD_VALUE,
    recoupmentStartDate: null,
    numberofInstallments: '',
    interestCalculation: 'N',
    interestPercentage: '',
    paymentScheduleFrequency: DEFAULT_DD_VALUE,
    recoupedAmount: '0.00',
    installmentAmount: '',
    federal: '',
    state: '',
    county: '',
    other: '',
    thirdPartyIDFromDB: '',
    atrNumber:''
  });

  React.useEffect(()=>{
    if(values.lienHolderType !== 'Please Select One' && values.lienHolderIdType !== 'Please Select One' && lienHolderTypeData && lienHolderIdTypeData){
    setPayeeTypeDesc(fieldDescription(lienHolderTypeData,values.lienHolderType));
    setPayeeTypeIdCodeDesc(fieldDescription(lienHolderIdTypeData,values.lienHolderIdType))
    }
  },[values,lienHolderTypeData,lienHolderIdTypeData])
  const [retainPaymentSchedule, setRetainPaymentSchedule] = React.useState({});
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [paymentScheduleTypeData, setPaymentScheduleTypeData] = React.useState(
    []
  );
  const [frequencyRecoupData, setFrequencyRecoupData] = React.useState([]);
  const getPayeeIdFromSystemParameter = systemParameter =>
    dispatch(getMaxFrequencyParameter(systemParameter));
  const frequencyResult = useSelector(
    state => state.sharedState.frequencyParameter
  );

  useEffect(() => {
    if (payeeTypeIdCodeDropdown && !payeeTypeIdCodeDropdown.systemFailure) {
      setPayeeTypeIdCodeData(payeeTypeIdCodeDropdown);
    }
  }, [payeeTypeIdCodeDropdown]);

  useEffect(() => {
    if (lienLevyHolderIdTypeDropDown) {
      setLienHolderIdTypeData(lienLevyHolderIdTypeDropDown);
    }
  }, [lienLevyHolderIdTypeDropDown]);

  useEffect(() => {
    setSpinnerLoader(false);
    setAllowNavigation(false);
    if (
      lienLevyUpdateResponse &&
      lienLevyUpdateResponse.respcode === '03' &&
      lienLevyUpdateResponse.respdesc === ''
    ) {
      setSystemSuccesMessages([]);
      seterrorMessages([ErrorConstants.GENERIC_SYSTEM_ERROR]);
    }
    if (
      lienLevyUpdateResponse &&
      lienLevyUpdateResponse.respcode === '03' &&
      lienLevyUpdateResponse.respdesc !== ''
    ) {
      setSystemSuccesMessages([]);
      seterrorMessages([ErrorConstants.GENERIC_SYSTEM_ERROR]);
    }
    if (
      lienLevyUpdateResponse &&
      lienLevyUpdateResponse.respcode === '01' &&
      lienLevyUpdateResponse.respdesc !== ''
    ) {
      const success = lienLevyUpdateResponse.respdesc;
      seterrorMessages([]);
      setSystemSuccesMessages([]);
      sucessMessages.push(success);
      setSystemSuccesMessages(sucessMessages);
      setFocusCheck(false);
      onSearch(values.fcn);
    }
    if (
      lienLevyUpdateResponse &&
      lienLevyUpdateResponse.respcode === '02' &&
      lienLevyUpdateResponse.respdesc !== ''
    ) {
      setSystemSuccesMessages([]);
      seterrorMessages(lienLevyUpdateResponse.errorMessages);
    }
    if (lienLevyUpdateResponse && lienLevyUpdateResponse.systemFailure) {
      setSystemSuccesMessages([]);
      seterrorMessages([ErrorConstants.GENERIC_SYSTEM_ERROR]);
    }
  }, [lienLevyUpdateResponse]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1019']) {
        setLobCodeData(dropdown.listObj['Reference#1019']);
      }
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#8']) {
        setAdjustedReasonCode(dropdown.listObj['Financial#8']);
      }
      if (dropdown.listObj['Financial#3013']) {
        setActivityTypeData(dropdown.listObj['Financial#3013']);
      }
      if (dropdown.listObj['Financial#6001']) {
        setReasonCodeData(dropdown.listObj['Financial#6001']);
      }
      if (dropdown.listObj['General#6002']) {
        setLienHolderTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#1007']) {
        setRecoupmentData(dropdown.listObj['Financial#1007']);
      }
      if (dropdown.listObj['Financial#1009']) {
        setFrequencyData(dropdown.listObj['Financial#1009']);
      }
      if (dropdown.listObj['Claims#1020']) {
        setCOSData(dropdown.listObj['Claims#1020']);
      }
      if (dropdown.listObj['Financial#3200']) {
        setFundCodeData(dropdown.listObj['Financial#3200']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
      if (dropdown.listObj['Financial#3006']) {
        setPayoutRCodes(dropdown.listObj['Financial#3006']);
      }
      if (dropdown.listObj['Financial#3007']) {
        setReceiptRCodes(dropdown.listObj['Financial#3007']);
      }
      if (dropdown.listObj['Financial#6001']) {
        setAccountRRCodes(dropdown.listObj['Financial#6001']);
      }
      if (dropdown.listObj['Financial#3009']) {
        setDispositionRCodes(dropdown.listObj['Financial#3009']);
      }
      if (dropdown.listObj['Financial#6007']) {
        setDropDownCheckList(dropdown.listObj['Financial#6007']);
      }
      if (dropdown.listObj['Financial#1009']) {
        setFrequencyDataDropdown(dropdown.listObj['Financial#1009']);
      }
      if (dropdown.listObj['Financial#6017']) {
        setPaymentScheduleTypeData(dropdown.listObj['Financial#6017']);
      }
      if (dropdown.listObj['Financial#6016']) {
        setFrequencyRecoupData(dropdown.listObj['Financial#6016']);
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023'])
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (paymentScheduleData && paymentScheduleData.length > 0) {
      paymentScheduleData.map(value => {
        if (
          value.recoupedAmount &&
          numeral(value.recoupedAmount).format('0.00') !== '0.00'
        ) {
          setManualEditFlag(true);
        }
      });
    }
  }, [paymentScheduleData]);

  useEffect(() => {
    if (payeeTypeIdDropDown && payeeTypeIdDropDown.payeeIdTypeCode) {
      setPayeeTypeIdCodeData(payeeTypeIdDropDown.payeeIdTypeCode);
    }
  }, [payeeTypeIdDropDown]);

  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };

  useEffect(() => {
    if (historyTableData.length > 0 && reasonCodeData.length > 0) {
      const tempPayeePayload = [];
      historyTableData.map(historyObj => {
        const outputData = historyObj;
        tempPayeePayload.push({
          ...outputData,
          reasonCodeDesc: mapCodeDescription(
            historyObj.reasonCode,
            reasonCodeData
          )
        });
      });
      setHistoryTableData(tempPayeePayload);
    }
  }, [reasonCodeData]);
  useEffect(() => {
    if (
      payoutRCodes.length > 0 &&
      receiptRCodes.length > 0 &&
      accountRRCodes.length > 0 &&
      dispositionRCodes.length > 0
    ) {
      const tempTable = JSON.parse(JSON.stringify(historyTableData));
      for (var historyValue in tempTable) {
        if (tempTable[historyValue].receivableFCN) {
          if (tempTable[historyValue].receivableFCN.length === 14) {
            payoutRCodes.map(value => {
              if (tempTable[historyValue].reasonCode === value.description) {
                tempTable[historyValue].activityType = 'Payout';
              }
              if (tempTable[historyValue].reasonCode === value.description) {
                tempTable[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
            });
            receiptRCodes.map(value => {
              if (tempTable[historyValue].reasonCode === value.description) {
                tempTable[historyValue].activityType = 'Receipt';
              }
              if (tempTable[historyValue].reasonCode === value.description) {
                tempTable[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
            });
            accountRRCodes.map(value => {
              if (tempTable[historyValue].reasonCode === value.description) {
                tempTable[historyValue].activityType = 'AccountR';
              }
              if (tempTable[historyValue].reasonCode === value.description) {
                tempTable[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
            });
            dispositionRCodes.map(value => {
              if (tempTable[historyValue].reasonCode === value.description) {
                tempTable[historyValue].activityType = 'AccountR';
              }
              if (tempTable[historyValue].reasonCode === value.description) {
                tempTable[historyValue].reasonCodeLongDesc =
                  value.longDescription;
              }
            });
          } else {
            tempTable[historyValue].activityType = 'FTTCN';
            tempTable[historyValue].reasonCodeLongDesc =
              tempTable[historyValue].reasonCode;
          }
        }
      }
      setHistoryTableData(tempTable);
    }
  }, [payoutRCodes, receiptRCodes, accountRRCodes, dispositionRCodes]);

  // F1 6007 Changes starts
  const SystemParameterValue = useSelector(
    state => state.sharedState.SystemParameterData
  );
  useEffect(() => {
    if (
      SystemParameterValue &&
      !SystemParameterValue.systemFailure &&
      commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
    ) {
      if (values.recoupmentTypeCode === 'B' && SystemParameterValue.valuePCT) {
        const percentVal = SystemParameterValue.valuePCT * 100;
        setValues({
          ...values,
          percentValue: percentVal
            ? numeral(percentVal).format('0.00')
            : percentVal,
          installmentValue: ''
        });
      }
      if (values.recoupmentTypeCode === 'A' && SystemParameterValue.valueAmt) {
        setValues({
          ...values,
          installmentValue: SystemParameterValue.valueAmt
            ? numeral(SystemParameterValue.valueAmt).format('0,0.00')
            : SystemParameterValue.valueAmt,
          percentValue: ''
        });
      }
    }
  }, [
    SystemParameterValue,
    commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
  ]);
  const [IsF16007, setIsF16007] = React.useState(false);
  useEffect(() => {
    if (
      dropdown &&
      dropDownCheckList &&
      values.reasonCode !== DEFAULT_DD_VALUE
    ) {
      if (
        commonMethods.checkValueExists(
          dropdown.listObj['Financial#6007'],
          values.reasonCode
        )
      ) {
        setIsF16007(true);
      }
    }
  }, [dropDownCheckList, values.reasonCode]);
  // F1 6007 Changes ends

  const onPayeeTypeChange = payeeType => {
    if (payeeType && payeeType !== DEFAULT_DD_VALUE) {
      onPayeeTypeIdDropDown(payeeType);
    }
  };

  useEffect(() => {
    if (
      values.lienHolderType &&
      values.lienHolderType !== DEFAULT_DD_VALUE
    ) {
      onLienLevyHolderIdTypeDropDown(values.lienHolderType);
    }
  }, [values.lienHolderType]);

  useEffect(() => {
    onPayeeTypeChange(values.payeeType);
  }, [
    values.payeeType,
    lienLevyDetails && lienLevyDetails.payeePayerTypeCode
      ? lienLevyDetails.payeePayerTypeCode
      : null
  ]);

  useEffect(() => {
    const agingDaysTemp =
      lienLevyDetails && lienLevyDetails.agingCode
        ? lienLevyDetails.agingCode
        : values.agingCode;
    if (
      (agingDaysTemp != '' || agingDaysTemp != undefined) &&
      arAgingDays &&
      arAgingDays.length > 0
    ) {
      const percentValue =
        lienLevyDetails &&
          lienLevyDetails.recoupmentVO &&
          lienLevyDetails.recoupmentVO.percentageOriginalAmount
          ? lienLevyDetails.recoupmentVO.percentageOriginalAmount
          : '';
      const installementValue =
        lienLevyDetails &&
          lienLevyDetails.recoupmentVO &&
          lienLevyDetails.recoupmentVO.installmentAmount
          ? numeral(lienLevyDetails.recoupmentVO.installmentAmount).format(
            '0,0.00'
          )
          : '';
      const recoupmentTypeCode =
        lienLevyDetails && lienLevyDetails.recoupmentVO
          ? lienLevyDetails.recoupmentVO.recoupmentTypeCode
          : DEFAULT_DD_VALUE;
      const frequency =
        lienLevyDetails && lienLevyDetails.recoupmentVO
          ? lienLevyDetails.recoupmentVO.frequency
          : DEFAULT_DD_VALUE;
      const amount = '';
      const originalARAmount =
        lienLevyDetails && lienLevyDetails.originalAmount
          ? numeral(lienLevyDetails.originalAmount).format('0,0.00')
          : 0;
      const maxWithholdAmount =
        lienLevyDetails &&
          lienLevyDetails.withholdingVO &&
          lienLevyDetails.withholdingVO.maxWithholdAmount
          ? numeral(
            Number(
              lienLevyDetails.withholdingVO.maxWithholdAmount.replace(
                /,/g,
                ''
              )
            ).toPrecision()
          ).format('0,0.00')
          : '';
      const recoupmentsection = {
        percent: percentValue,
        installment: installementValue
          ? numeral(installementValue).format('0,0.00')
          : installementValue,
        recoupment: recoupmentTypeCode,
        frequency: frequency,
        amount: amount,
        originalARAmount: originalARAmount,
        maxWithholdAmount: maxWithholdAmount
      };
      const agingCodeData = arAgingDays.filter(agingCode => {
        return agingCode.code === agingDaysTemp;
      });
      values.arLettersent =
        agingCodeData && agingCodeData.length > 0
          ? agingCodeData[0].description
          : null;
      lienLevyDetails.withholdingVO.arLetterSent =
        agingCodeData && agingCodeData.length > 0
          ? agingCodeData[0].description
          : null;
      setValues(setInputValues(lienLevyDetails, values, recoupmentsection));
    }
  }, [
    arAgingDays,
    values.arAgingDays,
    lienLevyDetails && lienLevyDetails.arAgeInDays
      ? lienLevyDetails.arAgeInDays
      : null
  ]);

  useEffect(() => {
    const reasonCode =
      lienLevyDetails && lienLevyDetails.accountReceivableReasonCode
        ? lienLevyDetails.accountReceivableReasonCode
        : DEFAULT_DD_VALUE;
    if (commonMethods.checkValueExists(dropDownCheckList, reasonCode)) {
      const percentValue =
        lienLevyDetails &&
          lienLevyDetails.recoupmentVO &&
          lienLevyDetails.recoupmentVO.percentageOriginalAmount
          ? lienLevyDetails.recoupmentVO.percentageOriginalAmount
          : '';
      const installementValue =
        lienLevyDetails &&
          lienLevyDetails.recoupmentVO &&
          lienLevyDetails.recoupmentVO.installmentAmount
          ? numeral(lienLevyDetails.recoupmentVO.installmentAmount).format(
            '0,0.00'
          )
          : '';
      const recoupmentTypeCode =
        lienLevyDetails && lienLevyDetails.recoupmentVO
          ? lienLevyDetails.recoupmentVO.recoupmentTypeCode
          : DEFAULT_DD_VALUE;
      const frequency =
        lienLevyDetails && lienLevyDetails.recoupmentVO
          ? lienLevyDetails.recoupmentVO.frequency
          : DEFAULT_DD_VALUE;
      const amount = '';
      const originalARAmount =
        lienLevyDetails && lienLevyDetails.originalAmount
          ? numeral(lienLevyDetails.originalAmount).format('0,0.00')
          : 0;
      const maxWithholdAmount =
        lienLevyDetails &&
          lienLevyDetails.withholdingVO &&
          lienLevyDetails.withholdingVO.maxWithholdAmount
          ? numeral(
            Number(
              lienLevyDetails.withholdingVO.maxWithholdAmount.replace(
                /,/g,
                ''
              )
            ).toPrecision()
          ).format('0,0.00')
          : '';
      const recoupmentsection = {
        percent: percentValue,
        installment: installementValue
          ? numeral(installementValue).format('0,0.00')
          : installementValue,
        recoupment: recoupmentTypeCode,
        frequency: frequency,
        amount: amount,
        originalARAmount: originalARAmount,
        maxWithholdAmount: maxWithholdAmount
      };
      const paramNumber =
        lienLevyDetails && lienLevyDetails.recoupmentVO
          ? lienLevyDetails.recoupmentVO.recoupmentTypeCode === 'B'
            ? 9010
            : lienLevyDetails.recoupmentVO.recoupmentTypeCode === 'A'
              ? 9011
              : ''
          : '';
      if (
        paramNumber &&
        commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
      ) {
        dispatch(
          getSystemParameterSpecificValue({
            paramNumber: paramNumber,
            functionalArea: 'F1',
            lobCode:
              lienLevyDetails &&
                lienLevyDetails.payeeDetails &&
                lienLevyDetails.payeeDetails.lob
                ? lienLevyDetails.payeeDetails.lob
                : values.lob,
            asOfDate: moment(
              new Date(
                new Date().toLocaleString('en-US', {
                  timeZone: process.env.REACT_APP_TIMEZONE
                })
              )
            ).format('YYYY-DD-MM')
          })
        );
      }
      setValues(setInputValues(lienLevyDetails, values, recoupmentsection));      
    }
  }, [
    lienLevyDetails && lienLevyDetails.accountReceivableReasonCode
      ? lienLevyDetails.accountReceivableReasonCode
      : values.reasconCode,
    IsF16007
  ]);

  const handleInitialPaymentDate = date => {
    setFocusCheck(true);
    setSelectedInitialPaymentDate(date);
    setAllowNavigation(true);
  };

  const handleSatisfiedDate = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedSatisfiedDate(date);
  };

  const handleBeginDateChange = date => {
    setFocusCheck(true);
    setSelectedBeginDate(date);
  };
  const handleEndDateChange = date => {
    setFocusCheck(true);
    setSelectedEndDate(date);
  };

  const handleRecoupmentDateChange = date => {
    setFocusCheck(true);
    setSelectedRecoupmentDate(date);
    setAllowNavigation(true);
  };

  const handleDueDateChange = date => {
    setFocusCheck(true);
    setSelectedDueDate(date);
    setAllowNavigation(true);
  };

  function setInputValues(lienLevyDetails, values, recoupmentsection) {
    return {
      lob:
        lienLevyDetails && lienLevyDetails.lob
          ? lienLevyDetails.lob
          : DEFAULT_DD_VALUE,
          atrNumber: lienLevyDetails?.atrNumber,
      payeeType:
        lienLevyDetails && lienLevyDetails.payeePayerTypeCode
          ? lienLevyDetails.payeePayerTypeCode
          : DEFAULT_DD_VALUE,
      payeeIdTypeCode:
        lienLevyDetails && lienLevyDetails.payeePayerIDTypeCode
          ? lienLevyDetails.payeePayerIDTypeCode
          : DEFAULT_DD_VALUE,
      reasonCode:
        lienLevyDetails && lienLevyDetails.accountReceivableReasonCode
          ? lienLevyDetails.accountReceivableReasonCode
          : DEFAULT_DD_VALUE,
      activityCode:
        lienLevyDetails && lienLevyDetails.activityTypeCode
          ? lienLevyDetails.activityTypeCode
          : DEFAULT_DD_VALUE,
      systemPayeeId:
        lienLevyDetails && lienLevyDetails.sysID ? lienLevyDetails.sysID : '',
      payeeId:
        lienLevyDetails && lienLevyDetails.payeePayerID
          ? lienLevyDetails.payeePayerID
          : '',
      payeePayerName:
        lienLevyDetails && lienLevyDetails.payeePayerNameFromDB
          ? lienLevyDetails.payeePayerNameFromDB
          : '',
      fcn:
        lienLevyDetails && lienLevyDetails.receivableFCN
          ? lienLevyDetails.receivableFCN
          : '',
      payeeStatus:
        lienLevyDetails && lienLevyDetails.payeeStatus
          ? lienLevyDetails.payeeStatus
          : '',
      adjustedARAmount:
        lienLevyDetails && lienLevyDetails.adjustedArAmt
          ? lienLevyDetails.adjustedArAmt
          : '',
      balanceAmount:
        lienLevyDetails && lienLevyDetails.balanceAmount
          ? lienLevyDetails.balanceAmount
          : '',
      approval:
        lienLevyDetails && lienLevyDetails.withholdingVO
          ? lienLevyDetails.approvalIndicator === '0'
            ? 'N'
            : 'Y'
          : 'N',
      relatedFTTCN:
        lienLevyDetails && lienLevyDetails.relatedFTTCN
          ? lienLevyDetails.relatedFTTCN
          : '',
      establishedDate:
        lienLevyDetails && lienLevyDetails.establishedDateStr
          ? lienLevyDetails.establishedDateStr
          : null,
      lastDateApplied:
        lienLevyDetails && lienLevyDetails.lastAppliedDateStr
          ? lienLevyDetails.lastAppliedDateStr
          : null,
      arAgeInDays:
        lienLevyDetails && lienLevyDetails.arAgeInDays
          ? lienLevyDetails.arAgeInDays
          : null,
      agingCode:
        lienLevyDetails && lienLevyDetails.agingCode
          ? lienLevyDetails.agingCode
          : null,
      arLettersent:
        lienLevyDetails && lienLevyDetails.withholdingVO
          ? lienLevyDetails.withholdingVO.arLetterSent
          : '',
      categoryOfService:
        lienLevyDetails &&
          lienLevyDetails.accountVO &&
          lienLevyDetails.accountVO.cos
          ? lienLevyDetails.accountVO.cos
          : DEFAULT_DD_VALUE,
      exemptFromCollectionAgency:
        lienLevyDetails && lienLevyDetails.exemptionsVO
          ? lienLevyDetails.exemptionsVO.exemptFromCollectionAgency === true
            ? 'Y'
            : 'N'
          : 'N',
      exemptFromInterest:
        lienLevyDetails && lienLevyDetails.exemptionsVO
          ? lienLevyDetails.exemptionsVO.exemptFromInterest === true
            ? 'Y'
            : 'N'
          : 'N',
      exemptFromPenalty:
        lienLevyDetails && lienLevyDetails.exemptionsVO
          ? lienLevyDetails.exemptionsVO.exemptFromPenalty === true
            ? 'Y'
            : 'N'
          : 'N',
      collocationCode:
        lienLevyDetails &&
          lienLevyDetails.accountVO &&
          lienLevyDetails.accountVO.fundCode
          ? lienLevyDetails.accountVO.fundCode
          : DEFAULT_DD_VALUE,
      recoupmentTypeCode: recoupmentsection.recoupment,
      frequency: recoupmentsection.frequency,
      percentValue: recoupmentsection.percent
        ? numeral(recoupmentsection.percent).format('0.00')
        : recoupmentsection.percent,
      installmentValue: recoupmentsection.installment
        ? numeral(recoupmentsection.installment).format('0,0.00')
        : recoupmentsection.installment,
      amountDue: recoupmentsection.amount,
      originalARAmountData:
        lienLevyDetails && lienLevyDetails.originalAmount
          ? lienLevyDetails.originalAmount
          : 0,
      originalARAmount:
        lienLevyDetails && lienLevyDetails.originalAmount
          ? lienLevyDetails.originalAmount
          : 0,
      maxWithholdAmount: recoupmentsection.maxWithholdAmount,
      balanceAmountData:
        lienLevyDetails && lienLevyDetails.withholdingVO
          ? lienLevyDetails.withholdingVO.balanceAmount
          : 0,
      appliedAmountData:
        lienLevyDetails && lienLevyDetails.historyVO
          ? lienLevyDetails.historyVO.appliedAmount
          : 0,
      fiscalYear:
        lienLevyDetails && lienLevyDetails.accountVO
          ? lienLevyDetails.accountVO.scoaFiscalYear
          : values.fiscalYear,
      lienHolderType:
        lienLevyDetails && lienLevyDetails.withholdingVO
          ? lienLevyDetails.withholdingVO.thirdPartyType
          : DEFAULT_DD_VALUE,
      lienHolderIdType:
        lienLevyDetails && lienLevyDetails.withholdingVO
          ? lienLevyDetails.withholdingVO.thirdPartyIDType
          : DEFAULT_DD_VALUE,
      lienHolderId:
        lienLevyDetails && lienLevyDetails.withholdingVO
          ? lienLevyDetails.withholdingVO.thirdPartyID
          : '',
      lienHolderName:
        lienLevyDetails && lienLevyDetails.withholdingVO
          ? lienLevyDetails.withholdingVO.thirdPartyHolderName
          : '',
      paymentScheduleType: values.paymentScheduleType,
      paymentScheduleFrequency: values.paymentScheduleFrequency,
      recoupmentStartDate: values.recoupmentStartDate,
      numberofInstallments: values.numberofInstallments,
      interestCalculation: values.interestCalculation,
      interestPercentage: values.interestPercentage,
      recoupedAmount: values.recoupedAmount,
      installmentAmount: values.installmentValue,
      federal:
        lienLevyDetails && lienLevyDetails.fundSourceAllocationVO
          ? numeral(
            lienLevyDetails.fundSourceAllocationVO.federalPercentage
          ).format('0.00')
          : '',
      state:
        lienLevyDetails && lienLevyDetails.fundSourceAllocationVO
          ? numeral(
            lienLevyDetails.fundSourceAllocationVO.statePercentage
          ).format('0.00')
          : '',
      county:
        lienLevyDetails && lienLevyDetails.fundSourceAllocationVO
          ? numeral(
            lienLevyDetails.fundSourceAllocationVO.countyPercentage
          ).format('0.00')
          : '',
      other:
        lienLevyDetails && lienLevyDetails.fundSourceAllocationVO
          ? numeral(
            lienLevyDetails.fundSourceAllocationVO.otherPercentage
          ).format('0.00')
          : ''
    };
  }
  const setInput = (lienLevyDetails, recoupmentsection) => {
    setSelectedRecoupmentDate(
      lienLevyDetails && lienLevyDetails.recoupmentVO
        ? lienLevyDetails.recoupmentVO.startDateStr
        : null
    );
    setSelectedRecoupmentStartDate(
      lienLevyDetails && lienLevyDetails.recoupmentStartDate
        ? lienLevyDetails.recoupmentStartDate
        : null
    );
    setValues(
      setInputValues(
        lienLevyDetails,
        {
          ...values,
          paymentScheduleType: lienLevyDetails.paymentScheduleType
            ? lienLevyDetails.paymentScheduleType
            : DEFAULT_DD_VALUE,
          recoupmentStartDate: lienLevyDetails.recoupmentStartDate
            ? lienLevyDetails.recoupmentStartDate
            : null,
          numberofInstallments: lienLevyDetails.numberofInstallments
            ? lienLevyDetails.numberofInstallments
            : '',
          interestCalculation: lienLevyDetails.interestCalculation
            ? lienLevyDetails.interestCalculation
            : 'N',
          interestPercentage: lienLevyDetails.interestPercentage
            ? numeral(lienLevyDetails.interestPercentage).format('0,0.00')
            : '',
          paymentScheduleFrequency: lienLevyDetails.paymentScheduleFrequency
            ? lienLevyDetails.paymentScheduleFrequency
            : DEFAULT_DD_VALUE
        },
        recoupmentsection
      )
    );
  };

  const handleClickOpenPaymentSchedule = () => {
    const errorArray = [];
    if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (!selectedRecoupmentStartDate) {
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate && !manualEditFlag) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format('MM/DD/YYYY'))
      ) {
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format('MM/DD/YYYY'))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (errorArray.length === 0) {
      setPopUpErrorMessage([]);
      setSelectedDueDate(null);
      setSelectedDefaultDate(null);
      setOpen(true);
      setAdd(true);
      setShowError(false);
      setShowErrorText('');
      setPaymentScheduleVO({
        installmentAmount: '',
        recoupedAmount: '0.00'
      });
      setRetainPaymentSchedule({
        installmentAmount: '',
        recoupedAmount: '0.00'
      });
      setRetainSelectedDueDate(selectedDueDate);
      setRecoupmentStartDateError('');
      setPaymentScheduleTypeError('');
      seterrorMessages([]);
    } else {
      seterrorMessages(errorArray);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPaymentScheduleVO({
      installmentAmount: '',
      recoupedAmount: '0.00'
    });
    seterrorMessages([]);
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
    if (newValue === 1) {
      setTabTwo(true);
    }
    if (newValue === 2) {
      setTabThree(true);
    }
    if (newValue === 3) {
      setTabFour(true);
    }

    var colorCondition;
    if (
      values.balanceAmount !== '0' &&
      values.balanceAmount !== '0.0' &&
      values.balanceAmount !== '0.00'
    ) {
      colorCondition = checkFormValidations();
    }

    if (newValue > 0) {
      if (colorCondition) {
        if (errorMessagesArray.length > 0) {
          setColor('red');
        } else {
          setColor('green');
        }
      }
    }
  };

  const savePaymentSchedule = () => {
    var selectedDueDateError;
    var amountDueError = false;
    var selectedDueDateGreaterErrorTemp = false;
    var minDueDateError = false;
    var minDefaultDateError = false;
    var invalidDueDateError = false;
    var invalidDefaultDateError = false;
    var negativeAmountError = false;
    var amountGreaterThanZero = false;
    var originalArAmountRequired = false;
    var selectedDueDateErrorText;
    var amountDueErrorText = '';
    var selectedDueDateErrorTextTemp = '';
    var minDueDateErrorText = '';
    var minDefaultDateErrorText = '';
    var invalidDueDateErrorText = '';
    var invalidDefaultDateErrorText = '';
    var negativeAmountErrorText = '';
    var amountGreaterThanZeroText = '';
    var originalArAmountRequiredText = '';

    if (!selectedDueDate) {
      selectedDueDateError = true;
      selectedDueDateErrorText = ErrorConstants.DUE_DATE_REQUIRED;
      popUpErrorMessagesArray.push(selectedDueDateErrorText);
    }

    if (selectedDueDate) {
      if (!moment(selectedDueDate).isValid()) {
        invalidDueDateError = true;
        invalidDueDateErrorText = ErrorConstants.DUE_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDueDateErrorText);
      }
      if (!invalidDueDateError) {
        if (validateDateMinimumValue(selectedDueDate)) {
          minDueDateError = true;
          minDueDateErrorText = AppConstants.DATE_ERROR_1964;
          popUpErrorMessagesArray.push(minDueDateErrorText);
        }
      }
    }

    if (selectedDefaultDate) {
      if (!moment(selectedDefaultDate).isValid()) {
        invalidDefaultDateError = true;
        invalidDefaultDateErrorText = ErrorConstants.DEFAULT_DATE_INVALID;
        popUpErrorMessagesArray.push(invalidDefaultDateErrorText);
      }

      if (!invalidDefaultDateError) {
        if (selectedDefaultDate) {
          if (validateDateMinimumValue(selectedDefaultDate)) {
            minDefaultDateError = true;
            minDefaultDateErrorText = AppConstants.DATE_ERROR_1964;
            popUpErrorMessagesArray.push(minDefaultDateErrorText);
          }
        }
      }
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        )
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_ERROR;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (
      selectedDueDate &&
      compareTwoDatesGreaterThanOrEqual(
        new Date(selectedDueDate),
        new Date(selectedRecoupmentStartDate)
      )
    ) {
      selectedDueDateGreaterErrorTemp = true;
      selectedDueDateErrorTextTemp = ErrorConstants.DUE_DATE_RECOUP;
      popUpErrorMessagesArray.push(selectedDueDateErrorTextTemp);
    }

    if (paymentScheduleVO.installmentAmount) {
      if (
        values.originalARAmount === '' ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        popUpErrorMessagesArray.push(originalArAmountRequiredText);
      } else {
        if (
          paymentScheduleVO.installmentAmount
            .toString()
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) === null
        ) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.DATA_INVALID;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        } else {
          if (
            !(
              paymentScheduleVO.installmentAmount
                .toString()
                .replace(/,/g, '')
                .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            negativeAmountError = true;
            negativeAmountErrorText = ErrorConstants.DATA_INVALID;
            popUpErrorMessagesArray.push(negativeAmountErrorText);
          }
        }
        if (+paymentScheduleVO.installmentAmount < 0) {
          negativeAmountError = true;
          negativeAmountErrorText = ErrorConstants.NEGATIVE_AMOUNT;
          popUpErrorMessagesArray.push(negativeAmountErrorText);
        }
        if (+paymentScheduleVO.installmentAmount === 0) {
          amountGreaterThanZero = true;
          amountGreaterThanZeroText = ErrorConstants.GREATER_THAN_ZERO_AMOUNT;
          popUpErrorMessagesArray.push(amountGreaterThanZeroText);
        }
        if (
          !amountDueError &&
          paymentScheduleVO.installmentAmount &&
          values.adjustedARAmount &&
          parseFloat(paymentScheduleVO.installmentAmount.replace(/,/g, '')) >
          parseFloat(
            values.adjustedARAmount
              .toString()
              .replace(/,/g, '')
              .replace('$', '')
              .trim()
          )
        ) {
          amountDueError = true;
          amountDueErrorText =
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION;
          popUpErrorMessagesArray.push(
            ErrorConstants.AMOUNTDUE_ORIGINALAMOUNT_VALIDATION
          );
        }
      }
    } else {
      amountDueError = true;
      amountDueErrorText = ErrorConstants.AMOUNT_DUE;
      popUpErrorMessagesArray.push(amountDueErrorText);
    }
    setPopUpErrorMessage(popUpErrorMessagesArray);
    seterrorMessages(popUpErrorMessagesArray);
    setShowError({
      showDueDateError: selectedDueDateError,
      showAmountDueError: amountDueError,
      showDueDateGreaterError: selectedDueDateGreaterErrorTemp,
      showMinDueDateError: minDueDateError,
      showMinDefaultDateError: minDefaultDateError,
      showDueDateInvalidError: invalidDueDateError,
      showDefaultDateError: invalidDefaultDateError,
      showNegativeAmountError: negativeAmountError,
      showGreaterThanZeroAmountError: amountGreaterThanZero,
      showOriginalArAmountPopError: originalArAmountRequired
    });
    setShowErrorText({
      showDueDateErrorText: selectedDueDateErrorText,
      showAmountDueErrorText: amountDueErrorText,
      showDueDateGreaterErrorText: selectedDueDateErrorTextTemp,
      showMinDueDateErrorText: minDueDateErrorText,
      showMinDefaultDateErrorText: minDefaultDateErrorText,
      showDueDateInvalidErrorText: invalidDueDateErrorText,
      showDefaultDUeDateErrorText: invalidDefaultDateErrorText,
      showNegativeAmmountErrorText: negativeAmountErrorText,
      showGreaterThanZeroAmountText: amountGreaterThanZeroText,
      showOriginalArAmountPopErrorText: originalArAmountRequiredText
    });
    if (add) {
      const scheduleData = paymentScheduleData;
      if (popUpErrorMessagesArray.length === 0) {
        var value = {
          id: generateUUID(),
          overlapId: generateUUID(),
          scheduleSK: null,
          dueDate: selectedDueDate
            ? moment(new Date(selectedDueDate).getTime()).format('MM/DD/YYYY')
            : null,
          installmentAmount: paymentScheduleVO.installmentAmount,
          recoupedAmount: paymentScheduleVO.recoupedAmount
        };
        if (scheduleData.length > 0) {
          let duplicateDueDate = false;
          scheduleData.map((option, index) => {
            if (option.dueDate === value.dueDate) {
              const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
              setShowError({
                showDueDateError: true
              });
              setShowErrorText({
                showDueDateErrorText: duplicateMessage
              });
              duplicateDueDate = true;
              window.scrollTo(0, 0);
            }
          });
          if (!duplicateDueDate) {
            scheduleData.push(value);
            setPaymentSchedleData(scheduleData);
            handleClose();
          }
        } else {
          scheduleData.push(value);
          setPaymentSchedleData(scheduleData);
          handleClose();
        }
      }
    } else {
      const scheduleData = paymentScheduleData;
      if (popUpErrorMessagesArray.length === 0) {
        if (scheduleData.length > 0) {
          let duplicateDueDate = false;
          scheduleData.map((option, index) => {
            if (
              option.dueDate ===
              moment(new Date(selectedDueDate)).format('L') &&
              option.overlapId !== paymentScheduleVO.overlapId
            ) {
              const duplicateMessage = ErrorConstants.DUPLICATE_DUE_DATE;
              setShowError({
                showDueDateError: true
              });
              setShowErrorText({
                showDueDateErrorText: duplicateMessage
              });
              duplicateDueDate = true;
              window.scrollTo(0, 0);
            }
          });
          if (!duplicateDueDate) {
            paymentScheduleData.map(value => {
              if (paymentScheduleVO.id && value.id === paymentScheduleVO.id) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    'MM/DD/YYYY'
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
                value.edited = true;
              }
              if (
                paymentScheduleVO.scheduleSK &&
                value.scheduleSK === paymentScheduleVO.scheduleSK
              ) {
                value.dueDate = selectedDueDate
                  ? moment(new Date(selectedDueDate).getTime()).format(
                    'MM/DD/YYYY'
                  )
                  : null;
                value.installmentAmount = paymentScheduleVO.installmentAmount;
                value.edited = true;
              }
              setPaymentSchedleData(paymentScheduleData);
              setOpen(false);
              setAdd(true);
              handleClose();
            });
          }
        }
      }
    }
  };

  const handleDefaultRecoupment = e => {
    setRecoupmentTypeClicked(false);
    setAllowNavigation(true);
    if (lienLevyDetails.balanceAmount !== '0.00') {
      onGetRecoupmetDetails(claimsData);
      setRetainValues(values);
    } else {
      e.preventDefault();
    }
  };

  const resetPaymentSchedule = () => {
    seterrorMessages([]);
    setPopUpErrorMessage([]);
    setShowError(false);
    setShowErrorText('');
    setPaymentScheduleVO(retainPaymentSchedule);
    setSelectedDueDate(retainSelectedDueDate);
  };
  const calculateSchedule = () => {
    const errorArray = [];
    var originalArAmountRequired = false;
    var originalArAmountRequiredText = '';
    let paymentScheduleTypeText = '';
    let recoupmentStartDateText = '';
    let noOfInstallmentText = '';
    let frequencyText = '';
    let calPercentText = '';
    setPaymentScheduleTypeError('');
    setRecoupmentStartDateError('');
    setFrequencyError('');
    setNumberofinstallmentError('');
    setInstallmentCalError('');
    if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
      paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
      errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
    }
    if (
      values.originalARAmount === '' ||
      values.originalARAmount === undefined
    ) {
      originalArAmountRequired = true;
      originalArAmountRequiredText = ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorArray.push(originalArAmountRequiredText);
    }
    if (!selectedRecoupmentStartDate) {
      recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
      setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
      errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
    }

    if (selectedRecoupmentStartDate && !manualEditFlag) {
      var startDate = new Date(selectedRecoupmentStartDate);
      var estDate = new Date(values.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        recoupmentStartDateText = ErrorConstants.DATA_INVALID;
        setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      } else if (
        validateDateMinimumValue(moment(startDate).format('MM/DD/YYYY'))
      ) {
        recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
        setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
        errorArray.push(minRecoupmentDateTextTemp);
      }
      if (
        moment(startDate).isValid() &&
        !validateDateMinimumValue(moment(startDate).format('MM/DD/YYYY'))
      ) {
        if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
          recoupmentStartDateText =
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
          setRecoupmentStartDateError(
            ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
          );
          errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
        }
      }
    }
    if (values.paymentScheduleFrequency === DEFAULT_DD_VALUE) {
      frequencyText = ErrorConstants.FREQUENCY;
      setFrequencyError(ErrorConstants.FREQUENCY);
      errorArray.push(ErrorConstants.FREQUENCY);
    }
    if (values.numberofInstallments === '') {
      noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
      setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
    }
    if (values.numberofInstallments !== '') {
      if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
        noOfInstallmentText =
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          '.';
        setNumberofinstallmentError(
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          '.'
        );
        errorArray.push(
          ErrorConstants.INSTALLMENT_MORE +
          frequencyResult.valueNum.toString() +
          '.'
        );
      }
    }
    if (
      values.interestCalculation === 'Y' &&
      values.interestPercentage === ''
    ) {
      calPercentText = ErrorConstants.INTEREST_PERCENT;
      setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
      errorArray.push(ErrorConstants.INTEREST_PERCENT);
    }
    if (
      values.interestPercentage &&
      values.interestPercentage !== '' &&
      values.interestPercentage
        .toString()
        .replace(/,/g, '')
        .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
      values.paymentScheduleType === 'A'
    ) {
      calPercentText = ErrorConstants.DATA_INVALID;
      setInstallmentCalError(ErrorConstants.DATA_INVALID);
      errorArray.push(ErrorConstants.DATA_INVALID);
    }
    if (errorArray.length === 0) {
      const scheduleArray = [];
      const amountStep = numeral(
        Number(
          Number(values.balanceAmount.toString().replace(/,/g, '')) /
          Number(values.numberofInstallments)
        )
      ).format('0.00');
      const numberOfRows = Number(
        numeral(
          Number(values.balanceAmount.toString().replace(/,/g, '')) /
          Number(amountStep)
        ).format('0')
      );
      const dateTemp = new Date(selectedRecoupmentStartDate);
      let daysStep = 0;
      switch (values.paymentScheduleFrequency) {
        case '2':
          daysStep = 7;
          break;
        case '3':
          daysStep = 14;
          break;
        case '4':
          daysStep = 30;
          break;
      }
      let sumOfInstallmentAmount = 0;
      for (let i = 0; i < numberOfRows; i++) {
        let value;
        if (i === 0) {
          value = {
            scheduleSK: null,
            dueDate: moment(dateTemp.setDate(dateTemp.getDate())).format(
              'MM/DD/YYYY'
            ),
            installmentAmount: numeral(amountStep).format('0.00'),
            recoupedAmount: '0.00'
          };
        } else {
          value = {
            scheduleSK: null,
            dueDate: moment(
              dateTemp.setDate(dateTemp.getDate() + daysStep)
            ).format('MM/DD/YYYY'),
            installmentAmount: numeral(amountStep).format('0.00'),
            recoupedAmount: '0.00'
          };
        }
        sumOfInstallmentAmount = numeral(
          Number(numeral(sumOfInstallmentAmount).format('0.00')) +
          Number(numeral(amountStep).format('0.00'))
        ).format('0.00');
        scheduleArray.push(value);
      }
      if (
        numeral(values.balanceAmount).format('0.00') !==
        numeral(sumOfInstallmentAmount).format('0.00')
      ) {
        scheduleArray[numberOfRows - 1].installmentAmount = numeral(
          parseFloat(
            numeral(scheduleArray[numberOfRows - 1].installmentAmount).format(
              '0.00'
            )
          ) +
          (parseFloat(numeral(values.balanceAmount).format('0.00')) -
            parseFloat(numeral(sumOfInstallmentAmount).format('0.00')))
        ).format('0,0.00');
      }
      setPaymentSchedleData(scheduleArray);
      setPaymentScheduleTypeError('');
      setRecoupmentStartDateError('');
      setFrequencyError('');
      setNumberofinstallmentError('');
      setInstallmentCalError('');
      seterrorMessages([]);
    } else {
      window.scrollTo(0, 0);
      setShowError({
        showOriginalArAmountPopError: originalArAmountRequired
      });
      setShowErrorText({
        showOriginalArAmountPopErrorText: originalArAmountRequiredText
      });
      setPaymentScheduleTypeError(paymentScheduleTypeText);
      setRecoupmentStartDateError(recoupmentStartDateText);
      setFrequencyError(frequencyText);
      setNumberofinstallmentError(noOfInstallmentText);
      setInstallmentCalError(calPercentText);
      seterrorMessages(errorArray);
    }
  };
  function PaymentRowDeleteClick() {
    setAllowNavigation(true);
    let temNewDialogData = [...paymentScheduleData];
    const delDataTemp = deleteSchedulePaymentList;
    let delData = [];
    for (
      let i = 0;
      i < rowPaymentScheduleData.rowPaymentScheduleData.length;
      i++
    ) {
      if (rowPaymentScheduleData.rowPaymentScheduleData[i] !== undefined) {
        delData = temNewDialogData.filter(
          payment =>
            payment.id === rowPaymentScheduleData.rowPaymentScheduleData[i] &&
            payment.paymentScheduleKey
        );
        delDataTemp.push(...delData);
        temNewDialogData = temNewDialogData.filter(
          payment =>
            payment.id !== rowPaymentScheduleData.rowPaymentScheduleData[i]
        );
      }
    }
    setPaymentSchedleData(temNewDialogData);
    setDeleteSchedulePaymentList(delDataTemp);
    setRowPaymentScheduleData([]);
  }

  const formatDecimalField = name => event => {
    if (event.target.value !== '') {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format('0,0.00')
      });
    }
  };
  const formatPercent = name => event => {
    if (event.target.value !== '') {
      setValues({
        ...values,
        [name]: numeral(event.target.value).format('0.00')
      });
    }
  };
  const removeFormatDecimalFieldWHAmount = name => event => {
    if (values.maxWithholdAmount) {
      setValues({
        ...values,
        maxWithholdAmount: numeral(values.maxWithholdAmount).format('0.00')
      });
    }
  };

  const removeFormatDecimalFieldInstallment = name => event => {
    if (values.installmentValue) {
      setValues({
        ...values,
        installmentValue: numeral(values.installmentValue).format('0.00')
      });
    }
  };
  const removeFormatDecimalField = name => event => {
    if (values.originalARAmount) {
      setValues({
        ...values,
        originalARAmount: numeral(values.originalARAmount).format('0.00')
      });
    }
  };
  const removeFormatDecimalFieldPercent = name => event => {
    if (values.interestPercentage) {
      setValues({
        ...values,
        interestPercentage: numeral(values.interestPercentage).format('0.00')
      });
    }
  };

  const formatDecimalFieldInstallment = name => event => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          '0,0.00'
        )
      });
    }
  };

  const removeFormatDecimalFieldAmountDue = name => event => {
    if (paymentScheduleVO.installmentAmount) {
      setPaymentScheduleVO({
        ...paymentScheduleVO,
        installmentAmount: numeral(paymentScheduleVO.installmentAmount).format(
          '0.00'
        )
      });
    }
  };

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode, index) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };

  
    const handleDecimalValues =name => event=>{
      setFocusCheck(true);
      setSystemSuccesMessages([]);
      setAllowNavigation(true);
      if(name === "installmentValue"){
        if (event.target.value && event.target.value.includes('.')) {
          if (commonMethods.checkingDecimal(event.target.value)) {
            setValues({ ...values, [name]: event.target.value });
          }
        } else {
            setValues({ ...values, [name]: event.target.value });
          }      
      }
      else if(name === "originalARAmount"){
        if (event.target.value && event.target.value.includes('.')) {
          if (commonMethods.checkingDecimal(event.target.value)) {
            setValues({ ...values, [name]: event.target.value });
          }
        } else {
            setValues({ ...values, [name]: event.target.value });
          }      
      }
  
    }
  

    const handleChange = name => event => {
      setFocusCheck(true);
      setSystemSuccesMessages([]);
      setAllowNavigation(true);
      if (
        name === 'paymentScheduleType' &&
        values.paymentScheduleType !== event.target.value
      ) {
        setPaymentScheduleChanged(generateUUID());
        setPaymentScheduleTypeError('');
        setRecoupmentStartDateError('');
        setFrequencyError('');
        setNumberofinstallmentError('');
        setInstallmentCalError('');
      }
      if (name === 'interestCalculation') {
        setValues({
          ...values,
          [name]: event.target.value,
          interestPercentage: ''
        });
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
      if (
        name === 'numberofInstallments' ||
        name === 'paymentScheduleFrequency'
      ) {
        setPaymentSettingChanged(generateUUID());
      }
      if (
        name === 'lienHolderType' &&
        event.target.value !== DEFAULT_DD_VALUE
      ) {
        const payeeTypeDesc = fieldDescription(
          lienHolderTypeData,
          event.target.value
        );
        setPayeeTypeDesc(payeeTypeDesc);
      }
      if (
        name === 'lienHolderIdType' &&
        event.target.value !== DEFAULT_DD_VALUE
      ) {
        const payeeTypeIdDesc = fieldDescription(
          lienHolderIdTypeData,
          event.target.value
        );
        setPayeeTypeIdCodeDesc(payeeTypeIdDesc);
      }
      // Check recoupment type only on recoupment type code dropdown change
      if (name === 'recoupmentTypeCode') {
        setRecoupmentTypeClicked(true);
        if (event.target.value === 'N') {
          const percentValue = '0';
          const installementValue = values.installmentValue;
          const recoupmentTypeCode = 'N';
          const frequency = DEFAULT_DD_VALUE;
          const amount = '';
          const maxWithholdAmount = values.maxWithholdAmount;
          const recoupmentsection = {
            percent: percentValue,
            installment: installementValue
              ? numeral(installementValue).format('0,0.00')
              : installementValue,
            recoupment: recoupmentTypeCode,
            frequency: frequency,
            amount: amount,
            maxWithholdAmount: maxWithholdAmount
          };
          setValues(setInputValues(lienLevy, {
            ...values,
            paymentScheduleType: DEFAULT_DD_VALUE,
            recoupmentStartDate: '',
            numberofInstallments: '',
            interestCalculation: 'N',
            interestPercentage: '',
            paymentScheduleFrequency: DEFAULT_DD_VALUE
          }, recoupmentsection));
          setSelectedRecoupmentDate(null);
          setPaymentSchedleData([]);
        }
        if (event.target.value === 'S') {
          setSelectedRecoupmentDate(null);
          const percentValue = '';
          const installementValue = values.installmentValue;
          const recoupmentTypeCode = 'S';
          const frequency = DEFAULT_DD_VALUE;
          const amount = '';
          const maxWithholdAmount = values.maxWithholdAmount;
          const recoupmentsection = {
            percent: percentValue,
            installment: installementValue
              ? numeral(installementValue).format('0,0.00')
              : installementValue,
            recoupment: recoupmentTypeCode,
            frequency: frequency,
            amount: amount,
            maxWithholdAmount: maxWithholdAmount
          };
          setValues(setInputValues(lienLevy, values, recoupmentsection));
        }
        if (event.target.value === 'A') {
          const percentValue = '';
          let installementValue = '0';
          const recoupmentTypeCode = 'A';
          const frequency = '1';
          const amount = '';
          const maxWithholdAmount = values.maxWithholdAmount;
          if (
            commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
          ) {
            dispatch(
              getSystemParameterSpecificValue({
                paramNumber: 9010,
                functionalArea: 'F1',
                lobCode:
                  lienLevyDetails &&
                    lienLevyDetails.payeeDetails &&
                    lienLevyDetails.payeeDetails.lob
                    ? lienLevyDetails.payeeDetails.lob
                    : values.lob,
                asOfDate: moment(
                  new Date(
                    new Date().toLocaleString('en-US', {
                      timeZone: process.env.REACT_APP_TIMEZONE
                    })
                  )
                ).format('YYYY-DD-MM')
              })
            );
            installementValue = '';
          }
          setPaymentSchedleData([]);
          const recoupmentsection = {
            percent: percentValue,
            installment: installementValue
              ? numeral(installementValue).format('0,0.00')
              : installementValue,
            recoupment: recoupmentTypeCode,
            frequency: frequency,
            amount: amount,
            maxWithholdAmount: maxWithholdAmount
          };
          setValues(setInputValues(lienLevy, {
            ...values,
            paymentScheduleType: DEFAULT_DD_VALUE,
            recoupmentStartDate: '',
            numberofInstallments: '',
            interestCalculation: 'N',
            interestPercentage: '',
            paymentScheduleFrequency: DEFAULT_DD_VALUE
          }, recoupmentsection));
          setSelectedRecoupmentDate(
            new Date(
              new Date().toLocaleString('en-US', {
                timeZone: process.env.REACT_APP_TIMEZONE
              })
            )
          );
        }
        if (event.target.value === 'B') {
          let percentValue = '100';
          const installementValue = values.installmentValue;
          const recoupmentTypeCode = 'B';
          const frequency = '1';
          const amount = '';
          if (
            commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
          ) {
            dispatch(
              getSystemParameterSpecificValue({
                paramNumber: 9011,
                functionalArea: 'F1',
                lobCode:
                  lienLevyDetails &&
                    lienLevyDetails.payeeDetails &&
                    lienLevyDetails.payeeDetails.lob
                    ? lienLevyDetails.payeeDetails.lob
                    : values.lob,
                asOfDate: moment(
                  new Date(
                    new Date().toLocaleString('en-US', {
                      timeZone: process.env.REACT_APP_TIMEZONE
                    })
                  )
                ).format('YYYY-DD-MM')
              })
            );
            percentValue = '';
          }
          const maxWithholdAmount = values.maxWithholdAmount;
          const recoupmentsection = {
            percent: percentValue,
            installment: installementValue
              ? numeral(installementValue).format('0,0.00')
              : installementValue,
            recoupment: recoupmentTypeCode,
            frequency: frequency,
            amount: amount,
            maxWithholdAmount: maxWithholdAmount
          };
          setPaymentSchedleData([]);
          setValues(setInputValues(lienLevy, {
            ...values,
            paymentScheduleType: DEFAULT_DD_VALUE,
            recoupmentStartDate: '',
            numberofInstallments: '',
            interestCalculation: 'N',
            interestPercentage: '',
            paymentScheduleFrequency: DEFAULT_DD_VALUE
          }, recoupmentsection));
          setSelectedRecoupmentDate(
            new Date(
              new Date().toLocaleString('en-US', {
                timeZone: process.env.REACT_APP_TIMEZONE
              })
            )
          );
        }
        if (event.target.value === 'D') {
          setPaymentSchedleData([]);
          setValues({
            ...values,
            recoupmentStartDate: '',
            numberofInstallments: '',
            interestCalculation: 'N',
            interestPercentage: '',
            paymentScheduleFrequency: DEFAULT_DD_VALUE,
            paymentScheduleType: DEFAULT_DD_VALUE
          }
          );
          searchSystemParameterPercentage(systemParamValuetoPercentage);
          if (recoupmentTypeClicked) {
            onGetRecoupmetDetails(claimsData);
          }
        }
      }
    };
  var showOriginalArAmmountErrorTemp = false;
  var showApprovalErrorTemp = false;
  var showFiscalYearErrorTemp = false;
  var showLienHolderTypeErrorTemp = false;
  var showLienHolderTypeCodeErrorTemp = false;
  var showLienHolderIDErrorTemp = false;
  var showCollocationCodeErrorTemp = false;
  var showRecoupmentTypeCodeErrorTemp = false;
  var showInvalidBeginDateErrorTemp = false;
  var showInvalidEndDateErrorTemp;
  var showEndDateLesserErrorTemp;
  var showBeginDateRquiredErrorTemp = false;
  var showEndDateRequiredErrorTemp = false;
  var showPercentErrorTemp = false;
  var showInstallmentErrorTemp = false;
  var startDateErrorTemp = false;
  var startDateReqErrorTemp = false;
  var minimumBeginDateError = false;
  var minimumEndDateError = false;
  var minimumRecoupmentDaateError = false;
  var showFrequencyErrorTemp = false;
  var showATRErrorTemp = false;
  var showFrequencyErrorTextTemp = '';
  var showOriginalArAmountErrorTextTemp = '';
  var showApprovalErrorTextTemp = '';
  var showFiscalYearErrorTextTemp = '';
  var showLienHolderTypeErrorTextTemp = '';
  var showLienHolderTypeCodeErrorTextTemp = '';
  var showLienHolderIDTextTemp = '';
  var showCollocationCodeErrorTextTemp = '';
  var showRecoupmentTypeCodeErrorTextTemp = '';
  var showInvalidBeginDateTextTemp = '';
  var showInvalidEndDateInvalidTextTemp = '';
  var showEndDateLesserErrorTextTemp = '';
  var showBeginDateRequiredErrorTextTemp = '';
  var showEndDateRequiredErrorTextTemp = '';
  var minBeginDateTextTemp = '';
  var minEndDateTextTemp = '';
  var minRecoupmentDateTextTemp = '';
  var showPercentErrorTextTemp = '';
  var showInstallmentErrorTextTemp = '';
  var showStartDateErrorTextTemp = '';
  var startDateReqErrorTextTemp = '';
  var showATRErrorTextTemp = '';
  const checkFormValidations = () => {
    var calPercentText = '';
    const regex = /[^A-Za-z0-9]/ ;
    var checkFormat = false;
    if (paymentScheduleData.length > 0 && values.recoupmentTypeCode === 'S') {
      let summation = 0;
      if (values.paymentScheduleType === 'M') {
        paymentScheduleData.map(value => {
          summation =
            summation + Number(numeral(value.installmentAmount).format('0.00'));
        });
        if (
          numeral(summation.toString()).format('0.00') !==
          numeral(
            values.adjustedARAmount
              .toString()
              .replace(/,/g, '')
              .replace('$', '')
              .trim()
          ).format('0.00')
        ) {
          errorMessagesArray.push(ErrorConstants.SUM_AMOUNT);
        }
      }
    }
     if (values.atrNumber && regex.test(values.atrNumber)) {
      showATRErrorTemp = true;
      showATRErrorTextTemp= (`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}`);
      errorMessagesArray.push(showATRErrorTextTemp);
    } else {showATRErrorTemp = false;}
    if (
      values.originalARAmount === '' ||
      values.originalARAmount === undefined
    ) {
      showOriginalArAmmountErrorTemp = true;
      showOriginalArAmountErrorTextTemp =
        ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorMessagesArray.push(showOriginalArAmountErrorTextTemp);
    }
    if (
      commonMethods.checkValueExists(dropDownCheckList, values.reasonCode) &&
      values.recoupmentTypeCode !== 'N' &&
      values.recoupmentTypeCode !== 'A' &&
      values.recoupmentTypeCode !== 'B'
    ) {
      showRecoupmentTypeCodeErrorTemp = true;
      showRecoupmentTypeCodeErrorTextTemp =
        ErrorConstants.INVALID_RECOUPMENT_TYPE;
      errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
    }
    if (values.fiscalYear === '' || values.fiscalYear === undefined) {
      showFiscalYearErrorTemp = true;
      showFiscalYearErrorTextTemp = ErrorConstants.FISCAL_YEAR_REQUIRED;
      errorMessagesArray.push(showFiscalYearErrorTextTemp);
    }
    if (
      values.lienHolderType === '' ||
      values.lienHolderType === DEFAULT_DD_VALUE ||
      values.lienHolderType === undefined
    ) {
      showLienHolderTypeErrorTemp = true;
      showLienHolderTypeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeErrorTextTemp);
    }
    if (
      values.lienHolderIdType === '' ||
      values.lienHolderIdType === DEFAULT_DD_VALUE ||
      values.lienHolderIdType === undefined
    ) {
      showLienHolderTypeCodeErrorTemp = true;
      showLienHolderTypeCodeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeCodeErrorTextTemp);
    }
    if (values.lienHolderId === '' || values.lienHolderId === undefined) {
      showLienHolderIDErrorTemp = true;
      showLienHolderIDTextTemp = ErrorConstants.LIEN_HOLDER_ID_REQUIRED;
      errorMessagesArray.push(showLienHolderIDTextTemp);
    }

    if (
      values.collocationCode === '' ||
      values.collocationCode === DEFAULT_DD_VALUE ||
      values.collocationCode === undefined
    ) {
      showCollocationCodeErrorTemp = true;
      showCollocationCodeErrorTextTemp = ErrorConstants.COLLOCATION_CODE;
      errorMessagesArray.push(showCollocationCodeErrorTextTemp);
    }

    if (
      values.recoupmentTypeCode === '' ||
      values.recoupmentTypeCode === DEFAULT_DD_VALUE ||
      values.recoupmentTypeCode === undefined
    ) {
      showRecoupmentTypeCodeErrorTemp = true;
      showRecoupmentTypeCodeErrorTextTemp =
        ErrorConstants.RECOUPMENT_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
    }
    if (
      lienLevyDetails.recoupmentVO.recoupmentTypeCode === 'S' ||
      lienLevyDetails.recoupmentVO.recoupmentTypeCode === 'N'
    ) {
      if (
        !(
          values.recoupmentTypeCode === 'S' || values.recoupmentTypeCode === 'N'
        ) &&
        paymentScheduleData.length > 0
      ) {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPEMENT_INVAlID;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }
    }
    if (
      !(
        lienLevy.recoupmentVO.recoupmentTypeCode === 'S' ||
        lienLevy.recoupmentVO.recoupmentTypeCode === 'N'
      ) &&
      values.recoupmentTypeCode !== DEFAULT_DD_VALUE
    ) {
      if (
        values.recoupmentTypeCode === 'S' ||
        values.recoupmentTypeCode === 'N'
      ) {
        if (
          adjustedReasonCode.some(
            values => values.code === lienLevyDetails.reasonCode
          )
        ) {
          if (
            numeral(values.adjustedARAmount).format('0.00') !==
            numeral(values.balanceAmount).format('0.00')
          ) {
            showRecoupmentTypeCodeErrorTemp = true;
            showRecoupmentTypeCodeErrorTextTemp =
              ErrorConstants.RECOUPMENT_BALANCE_INVALID;
            errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
          }
        }
      }
    }
    if (
      !showRecoupmentTypeCodeErrorTemp &&
      !(values.recoupmentTypeCode === 'S' || values.recoupmentTypeCode === 'N')
    ) {
      if (
        values.paymentScheduleType !== DEFAULT_DD_VALUE ||
        values.paymentScheduleFrequency !== DEFAULT_DD_VALUE ||
        values.interestCalculation === 'Y' ||
        values.paymentScheduleFrequency !== DEFAULT_DD_VALUE ||
        values.interestPercentage || values.recoupmentStartDate
      ) {
        showRecoupmentTypeCodeErrorTemp = true;
        showRecoupmentTypeCodeErrorTextTemp =
          ErrorConstants.RECOUPEMENT_INVAlID;
        errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
      }
    }

    if (selectedBeginDate) {
      if (!moment(selectedBeginDate).isValid()) {
        showInvalidBeginDateErrorTemp = true;
        showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidBeginDateTextTemp);
      }
    }

    if (selectedEndDate) {
      if (!moment(selectedEndDate).isValid()) {
        showInvalidEndDateErrorTemp = true;
        showInvalidEndDateInvalidTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
      }
    }

    if (
      selectedBeginDate &&
      selectedEndDate &&
      compareTwoDates(new Date(selectedEndDate), new Date(selectedBeginDate))
    ) {
      showEndDateLesserErrorTemp = true;
      showEndDateLesserErrorTextTemp = ErrorConstants.END_DATE_ERROR;
      errorMessagesArray.push(showEndDateLesserErrorTextTemp);
    }

    if (values.reasonCode === '102') {
      if (!selectedBeginDate) {
        showBeginDateRquiredErrorTemp = true;
        showBeginDateRequiredErrorTextTemp = ErrorConstants.BEGIN_DATE_REQUIRED;
        errorMessagesArray.push(showBeginDateRequiredErrorTextTemp);
      }
      if (!selectedEndDate) {
        showEndDateRequiredErrorTemp = true;
        showEndDateRequiredErrorTextTemp = ErrorConstants.END_DATE_REQUIRED;
        errorMessagesArray.push(showEndDateRequiredErrorTextTemp);
      }

      if (selectedBeginDate) {
        if (!moment(selectedBeginDate).isValid()) {
          showInvalidEndDateErrorTemp = true;
          showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidBeginDateTextTemp);
        }
      }

      if (selectedEndDate) {
        if (!moment(selectedEndDate).isValid()) {
          showInvalidEndDateErrorTemp = true;
          showInvalidEndDateInvalidTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
        }
      }
    }

    if (values.percentValue) {
      var regexPercent = /^[0-9]{1,11}(\.\d{1,2})?%?$/;
      if (!regexPercent.test(values.percentValue)) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_INVALID_ERROR;
        checkFormat = true;
      }
    }

    if (values.percentValue && values.percentValue > 100) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp =
        ErrorConstants.PERCENT_VALUE_CAN_NOT_GREATER_100;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (
      values.recoupmentTypeCode !== 'N' &&
      values.percentValue &&
      values.percentValue === 0
    ) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_ZERO;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (values.percentValue && values.percentValue < 0) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_NEGATIVE_ERROR;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }

    if (values.installmentValue) {
      if (
        values.installmentValue
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) == null || !values.installmentValue
            .toString()
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}$/) == null
      ) {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_INVALID;
        checkFormat = true;
      }
    }

    if (values.installmentValue && parseFloat(values.installmentValue) < 0) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_NEGATIVE_ERROR;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0);
    } else if (
      values.installmentValue &&
      values.adjustedARAmount &&
      parseFloat(values.installmentValue.toString().replace(/,/g, '')) >
      parseFloat(values.adjustedARAmount.toString().replace(/,/g, '')) &&
      !commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
    ) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp =
        ErrorConstants.INSTALLMENT_GREATER_ORIGINAL_AMOUNT_ERROR;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
    }

    if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'B') {
      if (!selectedRecoupmentDate && !values.percentValue) {
        errorMessagesArray.push(ErrorConstants.START_DATE_PERCENT_REQUIRED);
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
      if (
        values.installmentValue &&
        values.installmentValue !== null &&
        values.installmentValue !== ''
      ) {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
        errorMessagesArray.push(showInstallmentErrorTextTemp);
        if (values.percentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }
    if (values.recoupmentTypeCode === 'D') {
      const RECOUPMENT_PERCENT_AND_DATE_REQUIRED =
        ErrorConstants.RECOUPMENT_PERCENT_AND_DATE_REQUIRED;
      if (!values.percentValue && !selectedRecoupmentDate) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
        errorMessagesArray.push(RECOUPMENT_PERCENT_AND_DATE_REQUIRED);
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
    }

    if (values.recoupmentTypeCode === 'A') {
      if (!selectedRecoupmentDate && !values.installmentValue) {
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_START_DATE_REQUIRED);
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
      } else {
        if (!values.installmentValue) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
          errorMessagesArray.push(ErrorConstants.INSTALLMENT_REQUIRED);
        } else if (parseInt(values.installmentValue) === 0) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }

      if (values.percentValue !== '' && values.percentValue !== null) {
        if (!values.installmentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
          errorMessagesArray.push(showPercentErrorTextTemp);
        } else {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
          errorMessagesArray.push(showPercentErrorTextTemp);
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (selectedRecoupmentDate) {
      var startDate = new Date(selectedRecoupmentDate);
      var estDate = new Date(claimsData.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.Invalid_Format_Error;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      } else if (
        validateDateMinimumValue(moment(startDate).format('MM/DD/YYYY'))
      ) {
        minimumRecoupmentDaateError = true;
        minRecoupmentDateTextTemp = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(minRecoupmentDateTextTemp);
      }
    }

    if (values.recoupmentTypeCode === 'N') {
      if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'N') {
        if (
          values.installmentValue > 0 ||
          values.installmentValue < 0 ||
          values.percentValue > 0 ||
          values.percentValue < 0
        ) {
          errorMessagesArray.push(ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0);
          if (values.percentValue !== '') {
            if (
              (values.percentValue !== '0' && values.percentValue !== '0.00') ||
              values.percentValue > 0 ||
              values.percentValue < 0
            ) {
              showPercentErrorTemp = true;
              showPercentErrorTextTemp =
                ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
            }
          }
          if (values.installmentValue !== '') {
            if (
              (values.installmentValue !== '0' &&
                values.installmentValue !== '0.00') ||
              values.installmentValue > 0 ||
              values.installmentValue < 0
            ) {
              showInstallmentErrorTemp = true;
              showInstallmentErrorTextTemp =
                ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
            }
          }
        }
      }
      if (selectedRecoupmentDate) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_BLANK;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }
      if (values.frequency !== DEFAULT_DD_VALUE && values.frequency) {
        showFrequencyErrorTemp = true;
        showFrequencyErrorTextTemp = ErrorConstants.FREQUENCY_BLANK_NO_RECOUP;
        errorMessagesArray.push(showFrequencyErrorTextTemp);
      }
    }
    // Leinholder ID validation
    if (values.lienHolderId === values.payeeId) {
      showLienHolderIDErrorTemp = true;
      showLienHolderIDTextTemp = ErrorConstants.LIEN_HOLDER_ID_SAME_ERR;
      errorMessagesArray.push(showLienHolderIDTextTemp);
    }
    setShowError({
      showOriginalArAmmountError: showOriginalArAmmountErrorTemp,
      showApprovalError: showApprovalErrorTemp,
      showATRError: showATRErrorTemp,
      showFiscalYearError: showFiscalYearErrorTemp,
      showLienHolderTypeError: showLienHolderTypeErrorTemp,
      showLienHolderTypeCodeError: showLienHolderTypeCodeErrorTemp,
      showLienHolderIDError: showLienHolderIDErrorTemp,
      showCollocationCodeError: showCollocationCodeErrorTemp,
      showRecoupmentTypeCodeError: showRecoupmentTypeCodeErrorTemp,
      showInvalidBeginDateError: showInvalidBeginDateErrorTemp,
      showInvalidEndDateError: showInvalidEndDateErrorTemp,
      showEndDateLesserError: showEndDateLesserErrorTemp,
      showBeginDateRequiredError: showBeginDateRquiredErrorTemp,
      showEndDateRequiredError: showEndDateRequiredErrorTemp,
      showPercentError: showPercentErrorTemp,
      showInstallementError: showInstallmentErrorTemp,
      showStartDateError: startDateErrorTemp,
      showStartDateRequiredError: startDateReqErrorTemp,
      showMinBeginDateError: minimumBeginDateError,
      showMinEndDateError: minimumEndDateError,
      showMinRecoupmentDateError: minimumRecoupmentDaateError,
      showFrequencyError: showFrequencyErrorTemp
    });
    setShowErrorText({
      showOriginalArAmountErrorText: showOriginalArAmountErrorTextTemp,
      showApprovalErrorText: showApprovalErrorTextTemp,
      showFiscalYearErrorText: showFiscalYearErrorTextTemp,
      showATRErrorText: showATRErrorTextTemp,
      showLienHolderTypeErrorText: showLienHolderTypeErrorTextTemp,
      showLienHolderTypeCodeErrorText: showLienHolderTypeCodeErrorTextTemp,
      showLienHolderIDText: showLienHolderIDTextTemp,
      showCollocationCodeErrorText: showCollocationCodeErrorTextTemp,
      showRecoupmentTypeCodeErrorText: showRecoupmentTypeCodeErrorTextTemp,
      showInvalidBeginDateText: showInvalidBeginDateTextTemp,
      showInvalidEndDateInvalidText: showInvalidEndDateInvalidTextTemp,
      showEndDateLesserErrorText: showEndDateLesserErrorTextTemp,
      showBeginDateRequiredErrorText: showBeginDateRequiredErrorTextTemp,
      showEndDateRequiredErrorText: showEndDateRequiredErrorTextTemp,
      showPercentErrorText: showPercentErrorTextTemp,
      showInstallmentErrorText: showInstallmentErrorTextTemp,
      showStartDateErrorText: showStartDateErrorTextTemp,
      showStartDateRequiredErrorText: startDateReqErrorTextTemp,
      showMinBeginDateErrorText: minBeginDateTextTemp,
      showMinEndDateErrorText: minEndDateTextTemp,
      showMinRecoupmentDateErrorText: minRecoupmentDateTextTemp,
      showFrequencyErrorText: showFrequencyErrorTextTemp
    });
    seterrorMessages(errorMessagesArray);
    return true;
  };

  const checkValidationLink = () => {
    var checkFormat = false;
    if (
      values.originalARAmount === '' ||
      values.originalARAmount === undefined
    ) {
      showOriginalArAmmountErrorTemp = true;
      showOriginalArAmountErrorTextTemp =
        ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
      errorMessagesArray.push(showOriginalArAmountErrorTextTemp);
    }

    if (values.fiscalYear === '' || values.fiscalYear === undefined) {
      showFiscalYearErrorTemp = true;
      showFiscalYearErrorTextTemp = ErrorConstants.FISCAL_YEAR_REQUIRED;
      errorMessagesArray.push(showFiscalYearErrorTextTemp);
    }
    if (
      values.lienHolderType === '' ||
      values.lienHolderType === DEFAULT_DD_VALUE ||
      values.lienHolderType === undefined
    ) {
      showLienHolderTypeErrorTemp = true;
      showLienHolderTypeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeErrorTextTemp);
    }
    if (
      values.lienHolderIdType === '' ||
      values.lienHolderIdType === DEFAULT_DD_VALUE ||
      values.lienHolderIdType === undefined
    ) {
      showLienHolderTypeCodeErrorTemp = true;
      showLienHolderTypeCodeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeCodeErrorTextTemp);
    }
    if (values.lienHolderId === '' || values.lienHolderIdType === undefined) {
      showLienHolderIDErrorTemp = true;
      showLienHolderIDTextTemp = ErrorConstants.LIEN_HOLDER_ID_REQUIRED;
      errorMessagesArray.push(showLienHolderIDTextTemp);
    }

    if (
      values.collocationCode === '' ||
      values.collocationCode === DEFAULT_DD_VALUE ||
      values.collocationCode === undefined
    ) {
      showCollocationCodeErrorTemp = true;
      showCollocationCodeErrorTextTemp = ErrorConstants.COLLOCATION_CODE;
      errorMessagesArray.push(showCollocationCodeErrorTextTemp);
    }

    if (
      values.recoupmentTypeCode === '' ||
      values.recoupmentTypeCode === DEFAULT_DD_VALUE ||
      values.recoupmentTypeCode === undefined
    ) {
      showRecoupmentTypeCodeErrorTemp = true;
      showRecoupmentTypeCodeErrorTextTemp =
        ErrorConstants.RECOUPMENT_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showRecoupmentTypeCodeErrorTextTemp);
    }

    if (selectedBeginDate) {
      if (!moment(selectedBeginDate).isValid()) {
        showInvalidBeginDateErrorTemp = true;
        showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidBeginDateTextTemp);
      }
    }

    if (selectedEndDate) {
      if (!moment(selectedEndDate).isValid()) {
        showInvalidEndDateErrorTemp = true;
        showInvalidEndDateInvalidTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
        errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
      }
    }

    if (
      selectedBeginDate &&
      selectedEndDate &&
      compareTwoDates(new Date(selectedEndDate), new Date(selectedBeginDate))
    ) {
      showEndDateLesserErrorTemp = true;
      showEndDateLesserErrorTextTemp = ErrorConstants.END_DATE_ERROR;
      errorMessagesArray.push(showEndDateLesserErrorTextTemp);
    }

    if (values.reasonCode === '102') {
      if (!selectedBeginDate) {
        showBeginDateRquiredErrorTemp = true;
        showBeginDateRequiredErrorTextTemp = ErrorConstants.BEGIN_DATE_REQUIRED;
        errorMessagesArray.push(showBeginDateRequiredErrorTextTemp);
      }
      if (!selectedEndDate) {
        showEndDateRequiredErrorTemp = true;
        showEndDateRequiredErrorTextTemp = ErrorConstants.END_DATE_REQUIRED;
        errorMessagesArray.push(showEndDateRequiredErrorTextTemp);
      }

      if (selectedBeginDate) {
        if (!moment(selectedBeginDate).isValid()) {
          showInvalidEndDateErrorTemp = true;
          showInvalidBeginDateTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidBeginDateTextTemp);
        }
      }

      if (selectedEndDate) {
        if (!moment(selectedEndDate).isValid()) {
          showInvalidEndDateErrorTemp = true;
          showInvalidEndDateInvalidTextTemp = ErrorConstants.BEGIN_DATE_INVALID;
          errorMessagesArray.push(showInvalidEndDateInvalidTextTemp);
        }
      }
    }

    if (values.percentValue) {
      var regexPercent = /^[0-9]{1,11}(\.\d{1,2})?%?$/;
      if (!regexPercent.test(values.percentValue)) {
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_INVALID_ERROR;
        checkFormat = true;
      }
    }

    if (values.percentValue && values.percentValue > 100) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp =
        ErrorConstants.PERCENT_VALUE_CAN_NOT_GREATER_100;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (
      values.recoupmentTypeCode !== 'N' &&
      values.percentValue &&
      values.percentValue === 0
    ) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_ZERO;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }
    if (values.percentValue && values.percentValue < 0) {
      showPercentErrorTemp = true;
      showPercentErrorTextTemp = ErrorConstants.PERCENT_NEGATIVE_ERROR;
      errorMessagesArray.push(showPercentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.PERCENT_MUST_BE_WITHIN_0_100);
    }

    if (values.installmentValue) {
      if (
        values.installmentValue
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,11}\.?[0-9]{0,2}$/) == null || !values.installmentValue
            .toString()
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{12,15}\.?[0-9]{0,2}$/) == null
      ) {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_INVALID;
        checkFormat = true;
      }
    }

    if (values.installmentValue && parseFloat(values.installmentValue) < 0) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_NEGATIVE_ERROR;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
      errorMessagesArray.push(ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0);
    } else if (
      values.installmentValue &&
      values.adjustedARAmount &&
      parseFloat(values.installmentValue.toString().replace(/,/g, '')) >
      parseFloat(values.adjustedARAmount.toString().replace(/,/g, '')) &&
      !commonMethods.checkValueExists(dropDownCheckList, values.reasonCode)
    ) {
      showInstallmentErrorTemp = true;
      showInstallmentErrorTextTemp =
        ErrorConstants.INSTALLMENT_GREATER_ORIGINAL_AMOUNT_ERROR;
      errorMessagesArray.push(showInstallmentErrorTextTemp);
    }

    if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'B') {
      if (!selectedRecoupmentDate && !values.percentValue) {
        errorMessagesArray.push(ErrorConstants.START_DATE_PERCENT_REQUIRED);
        showPercentErrorTemp = true;
        showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
      } else {
        if (!values.percentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_REQUIRED;
          errorMessagesArray.push(showPercentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }
      if (
        values.installmentValue &&
        values.installmentValue !== null &&
        values.installmentValue !== ''
      ) {
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_MUST_BE_BLANK;
        errorMessagesArray.push(showInstallmentErrorTextTemp);
        if (values.percentValue) {
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (values.recoupmentTypeCode === 'A') {
      if (!selectedRecoupmentDate && !values.installmentValue) {
        errorMessagesArray.push(ErrorConstants.INSTALLMENT_START_DATE_REQUIRED);
        showInstallmentErrorTemp = true;
        showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
      } else {
        if (!values.installmentValue) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp = ErrorConstants.INSTALLMENT_REQUIRED;
          errorMessagesArray.push(ErrorConstants.INSTALLMENT_REQUIRED);
        } else if (parseInt(values.installmentValue) === 0) {
          showInstallmentErrorTemp = true;
          showInstallmentErrorTextTemp =
            ErrorConstants.INSTALLMENT_MUST_BE_GREATER_0;
          errorMessagesArray.push(showInstallmentErrorTextTemp);
        }
        if (!selectedRecoupmentDate) {
          startDateReqErrorTemp = true;
          startDateReqErrorTextTemp = ErrorConstants.START_DATE_REQUIRED;
          errorMessagesArray.push(startDateReqErrorTextTemp);
        }
      }

      if (values.percentValue !== '' && values.percentValue !== null) {
        if (!values.installmentValue) {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
          errorMessagesArray.push(showPercentErrorTextTemp);
        } else {
          showPercentErrorTemp = true;
          showPercentErrorTextTemp = ErrorConstants.PERCENT_MUST_BE_BLANK;
          errorMessagesArray.push(showPercentErrorTextTemp);
          errorMessagesArray.push(ErrorConstants.BOTH_VALUES_CANNOT_ENTER);
        }
      }
    }

    if (selectedRecoupmentDate) {
      var startDate = new Date(selectedRecoupmentDate);
      var estDate = new Date(claimsData.establishedDate);
      var currentDate = new Date(
        new Date().toLocaleString('en-US', {
          timeZone: process.env.REACT_APP_TIMEZONE
        })
      );

      if (!moment(startDate).isValid()) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.Invalid_Format_Error;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      } else if (
        validateDateMinimumValue(moment(startDate).format('MM/DD/YYYY'))
      ) {
        minimumRecoupmentDaateError = true;
        minRecoupmentDateTextTemp = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(minRecoupmentDateTextTemp);
      }
    }

    if (values.recoupmentTypeCode === 'N') {
      if (values.recoupmentTypeCode && values.recoupmentTypeCode === 'N') {
        if (
          values.installmentValue > 0 ||
          values.installmentValue < 0 ||
          values.percentValue > 0 ||
          values.percentValue < 0
        ) {
          errorMessagesArray.push(ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0);
          if (values.percentValue !== '') {
            if (
              (values.percentValue !== '0' && values.percentValue !== '0.00') ||
              values.percentValue > 0 ||
              values.percentValue < 0
            ) {
              showPercentErrorTemp = true;
              showPercentErrorTextTemp =
                ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
            }
          }
          if (values.installmentValue !== '') {
            if (
              (values.installmentValue !== '0' &&
                values.installmentValue !== '0.00') ||
              values.installmentValue > 0 ||
              values.installmentValue < 0
            ) {
              showInstallmentErrorTemp = true;
              showInstallmentErrorTextTemp =
                ErrorConstants.INSTALLMENT_PERCENT_MUST_BE_0;
            }
          }
        }
      }
      if (selectedRecoupmentDate) {
        startDateReqErrorTemp = true;
        startDateReqErrorTextTemp = ErrorConstants.START_DATE_BLANK;
        errorMessagesArray.push(startDateReqErrorTextTemp);
      }
      if (values.frequency !== DEFAULT_DD_VALUE && values.frequency) {
        showFrequencyErrorTemp = true;
        showFrequencyErrorTextTemp = ErrorConstants.FREQUENCY_BLANK_NO_RECOUP;
        errorMessagesArray.push(showFrequencyErrorTextTemp);
      }
    }

    setShowError({
      showOriginalArAmmountError: showOriginalArAmmountErrorTemp,
      showApprovalError: showApprovalErrorTemp,
      showFiscalYearError: showFiscalYearErrorTemp,
      showLienHolderTypeError: showLienHolderTypeErrorTemp,
      showLienHolderTypeCodeError: showLienHolderTypeCodeErrorTemp,
      showLienHolderIDError: showLienHolderIDErrorTemp,
      showCollocationCodeError: showCollocationCodeErrorTemp,
      showRecoupmentTypeCodeError: showRecoupmentTypeCodeErrorTemp,
      showInvalidBeginDateError: showInvalidBeginDateErrorTemp,
      showInvalidEndDateError: showInvalidEndDateErrorTemp,
      showEndDateLesserError: showEndDateLesserErrorTemp,
      showBeginDateRequiredError: showBeginDateRquiredErrorTemp,
      showEndDateRequiredError: showEndDateRequiredErrorTemp,
      showPercentError: showPercentErrorTemp,
      showInstallementError: showInstallmentErrorTemp,
      showStartDateError: startDateErrorTemp,
      showStartDateRequiredError: startDateReqErrorTemp,
      showMinBeginDateError: minimumBeginDateError,
      showMinEndDateError: minimumEndDateError,
      showMinRecoupmentDateError: minimumRecoupmentDaateError,
      showFrequencyError: showFrequencyErrorTemp
    });
    setShowErrorText({
      showOriginalArAmountErrorText: showOriginalArAmountErrorTextTemp,
      showApprovalErrorText: showApprovalErrorTextTemp,
      showFiscalYearErrorText: showFiscalYearErrorTextTemp,
      showLienHolderTypeErrorText: showLienHolderTypeErrorTextTemp,
      showLienHolderTypeCodeErrorText: showLienHolderTypeCodeErrorTextTemp,
      showLienHolderIDText: showLienHolderIDTextTemp,
      showCollocationCodeErrorText: showCollocationCodeErrorTextTemp,
      showRecoupmentTypeCodeErrorText: showRecoupmentTypeCodeErrorTextTemp,
      showInvalidBeginDateText: showInvalidBeginDateTextTemp,
      showInvalidEndDateInvalidText: showInvalidEndDateInvalidTextTemp,
      showEndDateLesserErrorText: showEndDateLesserErrorTextTemp,
      showBeginDateRequiredErrorText: showBeginDateRequiredErrorTextTemp,
      showEndDateRequiredErrorText: showEndDateRequiredErrorTextTemp,
      showPercentErrorText: showPercentErrorTextTemp,
      showInstallmentErrorText: showInstallmentErrorTextTemp,
      showStartDateErrorText: showStartDateErrorTextTemp,
      showStartDateRequiredErrorText: startDateReqErrorTextTemp,
      showMinBeginDateErrorText: minBeginDateTextTemp,
      showMinEndDateErrorText: minEndDateTextTemp,
      showMinRecoupmentDateErrorText: minRecoupmentDateTextTemp,
      showFrequencyErrorText: showFrequencyErrorTextTemp
    });
    seterrorMessages(errorMessagesArray);
    return true;
  };

  const checkAmounts = () => {
    if (lienLevyDetails) {
      if (
        lienLevyDetails.balanceAmount !== lienLevyDetails.originalAmount ||
        lienLevyDetails.balanceAmount === '0.00'
      ) {
        setIsDisabled(true);
      }
    }
  };

  const masterUpdateLienLevy = () => {
    checkFormValidations();
    setSystemSuccesMessages([]);
    setSpinnerLoader(false);
    const paymentScheduleTemp = paymentScheduleData;
    paymentScheduleTemp.map(value => {
      value = commonMethods.removeLeadingTrailingSpaces(value);
      if (paymentScheduleChanged || paymentSettingChanged) {
        value.auditUserID = logInUserID;
        value.auditTimeStamp = getUTCTimeStamp();
        value.addedAuditUserID = logInUserID;
        value.addedAuditTimeStamp = getUTCTimeStamp();
      } else {
        if (value.edited || value.scheduleSK) {
          value.auditUserID = logInUserID;
          value.auditTimeStamp = getUTCTimeStamp();
        }
        if (!value.scheduleSK) {
          value.auditUserID = logInUserID;
          value.auditTimeStamp = getUTCTimeStamp();
          value.addedAuditUserID = logInUserID;
          value.addedAuditTimeStamp = getUTCTimeStamp();
        }
      }
      value.installmentAmount = numeral(value.installmentAmount).format('0.00');
      value.recoupedAmount = numeral(value.recoupedAmount).format('0.00');
    });
    if (values.recoupmentTypeCode === 'S') {
      const errorArray = [];
      var originalArAmountRequired = false;
      var originalArAmountRequiredText = '';
      let paymentScheduleTypeText = '';
      let recoupmentStartDateText = '';
      let noOfInstallmentText = '';
      let frequencyText = '';
      let calPercentText = '';
      if (values.paymentScheduleType === DEFAULT_DD_VALUE) {
        paymentScheduleTypeText = ErrorConstants.PAYMENT_SCHEDULE_TYPE;
        errorArray.push(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
        setPaymentScheduleTypeError(ErrorConstants.PAYMENT_SCHEDULE_TYPE);
      }
      if (
        values.originalARAmount === '' ||
        values.originalARAmount === undefined
      ) {
        originalArAmountRequired = true;
        originalArAmountRequiredText =
          ErrorConstants.ORIGINAL_AR_AMOUNT_REQUIRED;
        errorArray.push(originalArAmountRequiredText);
      }
      if (!selectedRecoupmentStartDate) {
        recoupmentStartDateText = ErrorConstants.RECOPUMENT_START_DATE;
        setRecoupmentStartDateError(ErrorConstants.RECOPUMENT_START_DATE);
        errorArray.push(ErrorConstants.RECOPUMENT_START_DATE);
      }

      if (selectedRecoupmentStartDate && !manualEditFlag) {
        var startDate = new Date(selectedRecoupmentStartDate);
        var estDate = new Date(values.establishedDate);
        var currentDate = new Date(
          new Date().toLocaleString('en-US', {
            timeZone: process.env.REACT_APP_TIMEZONE
          })
        );

        if (!moment(startDate).isValid()) {
          recoupmentStartDateText = ErrorConstants.DATA_INVALID;
          setRecoupmentStartDateError(ErrorConstants.DATA_INVALID);
          errorArray.push(ErrorConstants.DATA_INVALID);
        } else if (
          validateDateMinimumValue(moment(startDate).format('MM/DD/YYYY'))
        ) {
          recoupmentStartDateText = AppConstants.DATE_ERROR_1964;
          setRecoupmentStartDateError(AppConstants.DATE_ERROR_1964);
          errorArray.push(minRecoupmentDateTextTemp);
        }
        if (
          moment(startDate).isValid() &&
          !validateDateMinimumValue(moment(startDate).format('MM/DD/YYYY'))
        ) {
          if (compareTwoDatesGreaterThanOrEqual(startDate, estDate)) {
            recoupmentStartDateText =
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR;
            setRecoupmentStartDateError(
              ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR
            );
            errorArray.push(ErrorConstants.START_DATE_LESS_AR_CREATED_ERROR);
          }
        }
      }
      if (
        values.paymentScheduleFrequency === DEFAULT_DD_VALUE &&
        values.paymentScheduleType === 'A'
      ) {
        frequencyText = ErrorConstants.FREQUENCY;
        setFrequencyError(ErrorConstants.FREQUENCY);
        errorArray.push(ErrorConstants.FREQUENCY);
      }
      if (
        values.numberofInstallments === '' &&
        values.paymentScheduleType === 'A'
      ) {
        noOfInstallmentText = ErrorConstants.NUMBER_OF_INSTALLMENTS;
        setNumberofinstallmentError(ErrorConstants.NUMBER_OF_INSTALLMENTS);
        errorArray.push(ErrorConstants.NUMBER_OF_INSTALLMENTS);
      }
      if (
        values.numberofInstallments !== '' &&
        values.paymentScheduleType === 'A'
      ) {
        if (Number(values.numberofInstallments) > frequencyResult.valueNum) {
          noOfInstallmentText =
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.';
          setNumberofinstallmentError(
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.'
          );
          errorArray.push(
            ErrorConstants.INSTALLMENT_MORE +
            frequencyResult.valueNum.toString() +
            '.'
          );
        }
      }
      if (
        values.interestCalculation === 'Y' &&
        values.interestPercentage === '' &&
        values.paymentScheduleType === 'A'
      ) {
        if (!errorMessagesArray.includes(ErrorConstants.INTEREST_PERCENT)) {
          calPercentText = ErrorConstants.INTEREST_PERCENT;
          setInstallmentCalError(ErrorConstants.INTEREST_PERCENT);
          errorArray.push(ErrorConstants.INTEREST_PERCENT);
        }
      }
      if (
        values.interestPercentage &&
        values.interestPercentage !== '' &&
        values.interestPercentage
          .toString()
          .replace(/,/g, '')
          .match(/^[-]?[0-9]{0,7}\.?[0-9]{0,2}$/) === null &&
        values.paymentScheduleType === 'A'
      ) {
        calPercentText = ErrorConstants.DATA_INVALID;
        setInstallmentCalError(ErrorConstants.DATA_INVALID);
        errorArray.push(ErrorConstants.DATA_INVALID);
      }
      if (errorArray.length === 0) {
        setPaymentScheduleTypeError('');
        setRecoupmentStartDateError('');
        setFrequencyError('');
        setNumberofinstallmentError('');
        setInstallmentCalError('');
      } else {
        setPaymentScheduleTypeError(paymentScheduleTypeText);
        setRecoupmentStartDateError(recoupmentStartDateText);
        setFrequencyError(frequencyText);
        setNumberofinstallmentError(noOfInstallmentText);
        setInstallmentCalError(calPercentText);
        if (errorArray.length !== 0) {
          errorArray.map(value => {
            errorMessagesArray.push(value);
          });
        }
      }
    }
    if (errorMessagesArray.length <= 0) {
      if (!allowNavigation) {
        NoSaveMessage();
      } else {
        lienLevyDetails.auditUserID = logInUserID;
        lienLevyDetails.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.addedAuditUserID = lienLevyDetails.addedAuditUserID
          ? lienLevyDetails.addedAuditUserID
          : logInUserID;
        lienLevyDetails.addedAuditTimeStamp = lienLevyDetails.addedAuditTimeStamp
          ? lienLevyDetails.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.receivableFCN = values.fcn;
        lienLevyDetails.balanceAmount = values.balanceAmount;
        lienLevyDetails.atrNumber = values?.atrNumber?.toUpperCase();
        lienLevyDetails.withholdingVO.auditUserID = logInUserID;
        lienLevyDetails.withholdingVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.withholdingVO.addedAuditUserID = lienLevyDetails
          .withholdingVO.addedAuditUserID
          ? lienLevyDetails.withholdingVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.withholdingVO.addedAuditTimeStamp = lienLevyDetails
          .withholdingVO.addedAuditTimeStamp
          ? lienLevyDetails.withholdingVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.approvalIndicator = values.approval === 'Y' ? '1' : '0';
        lienLevyDetails.relatedFTTCN = values.relatedFTTCN;
        lienLevyDetails.arAgeInDays = values.arAgeInDays;
        lienLevyDetails.withholdingVO.arLetterSent = values.arLettersent;
        lienLevyDetails.accountVO.auditUserID = logInUserID;
        lienLevyDetails.accountVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.accountVO.addedAuditUserID =
          lienLevyDetails &&
            lienLevyDetails.accountVO &&
            lienLevyDetails.accountVO.addedAuditUserID
            ? lienLevyDetails.accountVO.addedAuditUserID
            : logInUserID;
        lienLevyDetails.accountVO.addedAuditTimeStamp =
          lienLevyDetails &&
            lienLevyDetails.accountVO &&
            lienLevyDetails.accountVO.addedAuditTimeStamp
            ? lienLevyDetails.accountVO.addedAuditTimeStamp
            : getUTCTimeStamp();
        lienLevyDetails.paymentInd = paymentInd;
        lienLevyDetails.accountVO.cos =
          values.categoryOfService !== DEFAULT_DD_VALUE
            ? values.categoryOfService
            : null;
        lienLevyDetails.adjustedArAmt = values.adjustedARAmount;
        lienLevyDetails.accountVO.scoaFiscalYear = values.fiscalYear;
        lienLevyDetails.recoupmentVO.auditUserID = logInUserID;
        lienLevyDetails.recoupmentVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.recoupmentVO.addedAuditUserID = lienLevyDetails
          .recoupmentVO.addedAuditUserID
          ? lienLevyDetails.recoupmentVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.recoupmentVO.addedAuditTimeStamp = lienLevyDetails
          .recoupmentVO.addedAuditTimeStamp
          ? lienLevyDetails.recoupmentVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.recoupmentVO.recoupmentTypeCode =
          values.recoupmentTypeCode !== DEFAULT_DD_VALUE
            ? values.recoupmentTypeCode
            : null;
        lienLevyDetails.recoupmentVO.installmentAmount =
          values.installmentValue;
        lienLevyDetails.recoupmentVO.percentageOriginalAmount =
          values.percentValue;
        lienLevyDetails.recoupmentVO.frequency =
          values.frequency !== DEFAULT_DD_VALUE ? values.frequency : null;
        lienLevyDetails.schedulePaymentList = paymentScheduleTemp;
        lienLevyDetails.deleteSchedulePaymentList =
          deleteSchedulePaymentList.length > 0 ? deleteSchedulePaymentList : [];
        lienLevyDetails.exemptionsVO.auditUserID = logInUserID;
        lienLevyDetails.exemptionsVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.exemptionsVO.addedAuditUserID = lienLevyDetails
          .exemptionsVO.addedAuditUserID
          ? lienLevyDetails.exemptionsVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.exemptionsVO.addedAuditTimeStamp = lienLevyDetails
          .exemptionsVO.addedAuditTimeStamp
          ? lienLevyDetails.exemptionsVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.exemptionsVO.exemptFromInterest =
          values.exemptFromInterest === 'Y';
        lienLevyDetails.exemptionsVO.exemptFromCollectionAgency =
          values.excemptionFromCollectionAgency === 'Y';
        lienLevyDetails.exemptionsVO.exemptFromPenalty =
          values.exemptFromPenalty === 'Y';
        lienLevyDetails.accountVO.fundCode = values.collocationCode;
        lienLevyDetails.originalAmount = values.originalARAmount;
        lienLevyDetails.withholdingVO.thirdPartyType = values.lienHolderType;
        lienLevyDetails.withholdingVO.thirdPartyIDType =
          values.lienHolderIdType;
        lienLevyDetails.withholdingVO.thirdPartyHolderName =
          values.lienHolderName;
        lienLevyDetails.withholdingVO.thirdPartyID = values.lienHolderId;
        lienLevyDetails.withholdingVO.thirdPartyIDFromDB =
          values.thirdPartyIDFromDB !== ''
            ? values.thirdPartyIDFromDB
            : lienLevyDetails.withholdingVO
              ? lienLevyDetails.withholdingVO.thirdPartyIDFromDB
              : null;
        lienLevyDetails.recoupmentVO.startDate = new Date(
          selectedRecoupmentDate
        );
        if (values.recoupmentTypeCode === 'D') {
          lienLevyDetails.recoupmentVO.startDateStr = lienLevyDetails && lienLevyDetails.establishedDateStr
            ? lienLevyDetails.establishedDateStr
            : null;
        } else {
          lienLevyDetails.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
            selectedRecoupmentDate
          );
        };
        lienLevyDetails.noteSetVO = notesInput;
        lienLevyDetails.paymentScheduleType =
          values.paymentScheduleType === DEFAULT_DD_VALUE
            ? null
            : values.paymentScheduleType;
        lienLevyDetails.recoupmentStartDate = selectedRecoupmentStartDate
          ? moment(selectedRecoupmentStartDate).format('MM/DD/YYYY')
          : null;
        lienLevyDetails.numberofInstallments = values.numberofInstallments
          ? values.numberofInstallments
          : null;
        lienLevyDetails.interestCalculation = values.interestCalculation;
        lienLevyDetails.interestPercentage =
          values.interestPercentage !== ''
            ? numeral(values.interestPercentage).format('0.00')
            : null;
        lienLevyDetails.paymentScheduleFrequency =
          values.paymentScheduleFrequency !== DEFAULT_DD_VALUE
            ? values.paymentScheduleFrequency
            : null;
        const index = attachmentsTableData.length - 1;
        const attachmentVOList = [];
        if (
          attachmentsTableData &&
          index >= 0 &&
          attachmentsTableData[index] &&
          attachmentsTableData[index].id
        ) {
          attachmentsTableData.map(value => {
            if (value.id) {
              const attachmentVO = {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: logInUserID,
                addedAuditTimeStamp: getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: true,
                sortColumn: null,
                attachmentSK: null,
                attachmentPageID: null,
                finalPath: null,
                detachInd: false,
                historyIndicator: false,
                cascadeKey: null,
                attachmentIndicator: null,
                dateAdded: getDateInMMDDYYYYFormatWithApendZero(
                  new Date(
                    new Date().toLocaleString('en-US', {
                      timeZone: process.env.REACT_APP_TIMEZONE
                    })
                  )
                ),
                fileUrl: null,
                showDeleteForSearched: false,
                rowIndex: 0,
                addedBy: logInUserID,
                description: value ? value.description : null,
                edmsStoredDate: null,
                anEDMSPageId: null,
                fileName: attachmentsTableData && value ? value.fileName : null,
                file1: null,
                edmsWrkUnitLevel: null,
                edmsDocType: null
              };
              value = commonMethods.removeLeadingTrailingSpaces(value);
              attachmentVOList.push(
                commonMethods.removeLeadingTrailingSpaces(attachmentVO)
              );
            }
          });
        }
        lienLevyDetails.attachmentVO = null;
        lienLevyDetails.attachmentsList = attachmentVOList;
        lienLevyDetails.withholdingVO = commonMethods.removeLeadingTrailingSpaces(
          lienLevyDetails.withholdingVO
        );
        lienLevyDetails.payeeStatus = values.payeeStatus !== DEFAULT_DD_VALUE
          ? values.payeeStatus
          : null;
        setSpinnerLoader(true);
        onLienLevyUpdate(
          commonMethods.removeLeadingTrailingSpaces({ ...lienLevyDetails, withHoldingStatus: withHoldingStatus }),
          index
        );
      }
    }
  };

  const editRow = data => event => {
    setAuditLogRow(data);
    seterrorMessages([]);
    setAdd(false);
    setEditData(data);
    setRetainEdit(data);
    setOpen(true);
    setSelectedDueDate(data.dueDate);
    setPaymentScheduleVO({
      ...data,
      installmentAmount: numeral(data.installmentAmount.toString()).format(
        '0,0.00'
      ),
      recoupedAmount: numeral(data.recoupedAmount.toString()).format('0,0.00')
    });
    setRetainPaymentSchedule({
      ...data,
      installmentAmount: numeral(data.installmentAmount.toString()).format(
        '0,0.00'
      ),
      recoupedAmount: numeral(data.recoupedAmount.toString()).format('0,0.00')
    });
    setRetainSelectedDueDate(data.dueDate);
    setShowError(false);
    setShowErrorText(false);
  };

  const rowDeletePaymentSchedule = data => {
    setRowPaymentScheduleData({
      ...rowPaymentScheduleData,
      rowPaymentScheduleData: data
    });
  };

  const NavigateToWriteOff = () => {
    checkValidationLink();
    if (errorMessagesArray.length <= 0) {
      if (
        lienLevyDetails.balanceAmount === '0' ||
        lienLevyDetails.balanceAmount === '0.0' ||
        lienLevyDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(
          ErrorConstants.WRITE_OFF_BALNACE_AMOUNT_ZERO_ERROR
        );
        seterrorMessages(systemErrorMessges);
      } else {
        lienLevyDetails.auditUserID = logInUserID;
        lienLevyDetails.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.addedAuditUserID = lienLevyDetails.addedAuditUserID
          ? lienLevyDetails.addedAuditUserID
          : logInUserID;
        lienLevyDetails.addedAuditTimeStamp = lienLevyDetails.addedAuditTimeStamp
          ? lienLevyDetails.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.receivableFCN = values.fcn;
        lienLevyDetails.balanceAmount = values.balanceAmount;
        lienLevyDetails.withholdingVO.auditUserID = logInUserID;
        lienLevyDetails.withholdingVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.withholdingVO.addedAuditUserID = lienLevyDetails
          .withholdingVO.addedAuditUserID
          ? lienLevyDetails.withholdingVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.withholdingVO.addedAuditTimeStamp = lienLevyDetails
          .withholdingVO.addedAuditTimeStamp
          ? lienLevyDetails.withholdingVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.approvalIndicator = values.approval === 'Y' ? '1' : '0';
        lienLevyDetails.relatedFTTCN = values.relatedFTTCN;
        lienLevyDetails.arAgeInDays = values.arAgeInDays;
        lienLevyDetails.withholdingVO.arLetterSent = values.arLettersent;
        lienLevyDetails.accountVO.auditUserID = logInUserID;
        lienLevyDetails.accountVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.accountVO.addedAuditUserID =
          lienLevyDetails &&
            lienLevyDetails.accountVO &&
            lienLevyDetails.accountVO.addedAuditUserID
            ? lienLevyDetails.accountVO.addedAuditUserID
            : logInUserID;
        lienLevyDetails.accountVO.addedAuditTimeStamp =
          lienLevyDetails &&
            lienLevyDetails.accountVO &&
            lienLevyDetails.accountVO.addedAuditTimeStamp
            ? lienLevyDetails.accountVO.addedAuditTimeStamp
            : getUTCTimeStamp();
        lienLevyDetails.accountVO.cos =
          values.categoryOfService !== DEFAULT_DD_VALUE
            ? values.categoryOfService
            : null;
        lienLevyDetails.adjustedArAmt = values.adjustedARAmount;
        lienLevyDetails.accountVO.scoaFiscalYear = values.fiscalYear;
        lienLevyDetails.recoupmentVO.auditUserID = logInUserID;
        lienLevyDetails.recoupmentVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.recoupmentVO.addedAuditUserID = lienLevyDetails
          .recoupmentVO.addedAuditUserID
          ? lienLevyDetails.recoupmentVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.recoupmentVO.addedAuditTimeStamp = lienLevyDetails
          .recoupmentVO.addedAuditTimeStamp
          ? lienLevyDetails.recoupmentVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.recoupmentVO.recoupmentTypeCode =
          values.recoupmentTypeCode !== DEFAULT_DD_VALUE
            ? values.recoupmentTypeCode
            : null;
        lienLevyDetails.recoupmentVO.installmentAmount =
          values.installmentValue;
        lienLevyDetails.recoupmentVO.percentageOriginalAmount =
          values.percentValue;
        lienLevyDetails.recoupmentVO.frequency =
          values.frequency !== DEFAULT_DD_VALUE ? values.frequency : null;
        lienLevyDetails.schedulePaymentList = paymentScheduleData;
        lienLevyDetails.deleteSchedulePaymentList =
          deleteSchedulePaymentList.length > 0 ? deleteSchedulePaymentList : [];
        lienLevyDetails.exemptionsVO.auditUserID = logInUserID;
        lienLevyDetails.exemptionsVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.exemptionsVO.addedAuditUserID = lienLevyDetails
          .exemptionsVO.addedAuditUserID
          ? lienLevyDetails.exemptionsVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.exemptionsVO.addedAuditTimeStamp = lienLevyDetails
          .exemptionsVO.addedAuditTimeStamp
          ? lienLevyDetails.exemptionsVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.exemptionsVO.exemptFromInterest =
          values.exemptFromInterest === 'Y';
        lienLevyDetails.exemptionsVO.exemptFromCollectionAgency =
          values.excemptionFromCollectionAgency === 'Y';
        lienLevyDetails.exemptionsVO.exemptFromPenalty =
          values.exemptFromPenalty === 'Y';
        lienLevyDetails.accountVO.fundCode = values.collocationCode;
        lienLevyDetails.originalAmount = values.originalARAmount;
        lienLevyDetails.withholdingVO.thirdPartyType = values.lienHolderType;
        lienLevyDetails.withholdingVO.thirdPartyIDType =
          values.lienHolderIdType;
        lienLevyDetails.withholdingVO.thirdPartyHolderName =
          values.lienHolderName;
        lienLevyDetails.withholdingVO.thirdPartyID = values.lienHolderId;
        lienLevyDetails.recoupmentVO.startDate = new Date(
          selectedRecoupmentDate
        );
        lienLevyDetails.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
          selectedRecoupmentDate
        );
        lienLevyDetails.noteSetVO = notesInput;
        seterrorMessages([]);
        setSystemSuccesMessages([]);
        setSpinnerLoader(true);
        dispatch(getWriteOffDetailsReset());
        fetchWriteOffData(lienLevyDetails);
      }
    }
  };

  const NavigateToWriteOffRestablish = () => {
    checkValidationLink();
    if (errorMessagesArray.length <= 0) {
      if (
        lienLevyDetails.balanceAmount === '0' ||
        lienLevyDetails.balanceAmount === '0.0' ||
        lienLevyDetails.balanceAmount === '0.00'
      ) {
        setSystemSuccesMessages([]);
        systemErrorMessges.push(ErrorConstants.BALNACE_AMOUNT_ZERO_ERROR);
        seterrorMessages(systemErrorMessges);
      } else {
        lienLevyDetails.auditUserID = logInUserID;
        lienLevyDetails.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.addedAuditUserID = lienLevyDetails.addedAuditUserID
          ? lienLevyDetails.addedAuditUserID
          : logInUserID;
        lienLevyDetails.addedAuditTimeStamp = lienLevyDetails.addedAuditTimeStamp
          ? lienLevyDetails.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.receivableFCN = values.fcn;
        lienLevyDetails.balanceAmount = values.balanceAmount;
        lienLevyDetails.withholdingVO.auditUserID = logInUserID;
        lienLevyDetails.withholdingVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.withholdingVO.addedAuditUserID = lienLevyDetails
          .withholdingVO.addedAuditUserID
          ? lienLevyDetails.withholdingVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.withholdingVO.addedAuditTimeStamp = lienLevyDetails
          .withholdingVO.addedAuditTimeStamp
          ? lienLevyDetails.withholdingVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.approvalIndicator = values.approval === 'Y' ? '1' : '0';
        lienLevyDetails.relatedFTTCN = values.relatedFTTCN;
        lienLevyDetails.arAgeInDays = values.arAgeInDays;
        lienLevyDetails.withholdingVO.arLetterSent = values.arLettersent;
        lienLevyDetails.accountVO.auditUserID = logInUserID;
        lienLevyDetails.accountVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.accountVO.addedAuditUserID =
          lienLevyDetails &&
            lienLevyDetails.accountVO &&
            lienLevyDetails.accountVO.addedAuditUserID
            ? lienLevyDetails.accountVO.addedAuditUserID
            : logInUserID;
        lienLevyDetails.accountVO.addedAuditTimeStamp =
          lienLevyDetails &&
            lienLevyDetails.accountVO &&
            lienLevyDetails.accountVO.addedAuditTimeStamp
            ? lienLevyDetails.accountVO.addedAuditTimeStamp
            : getUTCTimeStamp();
        lienLevyDetails.accountVO.cos =
          values.categoryOfService !== DEFAULT_DD_VALUE
            ? values.categoryOfService
            : null;
        lienLevyDetails.adjustedArAmt = values.adjustedARAmount;
        lienLevyDetails.accountVO.scoaFiscalYear = values.fiscalYear;
        lienLevyDetails.recoupmentVO.auditUserID = logInUserID;
        lienLevyDetails.recoupmentVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.recoupmentVO.addedAuditUserID = lienLevyDetails
          .recoupmentVO.addedAuditUserID
          ? lienLevyDetails.recoupmentVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.recoupmentVO.addedAuditTimeStamp = lienLevyDetails
          .recoupmentVO.addedAuditTimeStamp
          ? lienLevyDetails.recoupmentVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.recoupmentVO.recoupmentTypeCode =
          values.recoupmentTypeCode !== DEFAULT_DD_VALUE
            ? values.recoupmentTypeCode
            : null;
        lienLevyDetails.recoupmentVO.installmentAmount =
          values.installmentValue;
        lienLevyDetails.recoupmentVO.percentageOriginalAmount =
          values.percentValue;
        lienLevyDetails.recoupmentVO.frequency =
          values.frequency !== DEFAULT_DD_VALUE ? values.frequency : null;
        lienLevyDetails.schedulePaymentList = paymentScheduleData;
        lienLevyDetails.deleteSchedulePaymentList =
          deleteSchedulePaymentList.length > 0 ? deleteSchedulePaymentList : [];
        lienLevyDetails.exemptionsVO.auditUserID = logInUserID;
        lienLevyDetails.exemptionsVO.auditTimeStamp = getUTCTimeStamp();
        lienLevyDetails.exemptionsVO.addedAuditUserID = lienLevyDetails
          .exemptionsVO.addedAuditUserID
          ? lienLevyDetails.exemptionsVO.addedAuditUserID
          : logInUserID;
        lienLevyDetails.exemptionsVO.addedAuditTimeStamp = lienLevyDetails
          .exemptionsVO.addedAuditTimeStamp
          ? lienLevyDetails.exemptionsVO.addedAuditTimeStamp
          : getUTCTimeStamp();
        lienLevyDetails.exemptionsVO.exemptFromInterest =
          values.exemptFromInterest === 'Y';
        lienLevyDetails.exemptionsVO.exemptFromCollectionAgency =
          values.excemptionFromCollectionAgency === 'Y';
        lienLevyDetails.exemptionsVO.exemptFromPenalty =
          values.exemptFromPenalty === 'Y';
        lienLevyDetails.accountVO.fundCode = values.collocationCode;
        lienLevyDetails.originalAmount = values.originalARAmount;
        lienLevyDetails.withholdingVO.thirdPartyType = values.lienHolderType;
        lienLevyDetails.withholdingVO.thirdPartyIDType =
          values.lienHolderIdType;
        lienLevyDetails.withholdingVO.thirdPartyHolderName =
          values.lienHolderName;
        lienLevyDetails.withholdingVO.thirdPartyID = values.lienHolderId;
        lienLevyDetails.recoupmentVO.startDate = new Date(
          selectedRecoupmentDate
        );
        lienLevyDetails.recoupmentVO.startDateStr = getDateInMMDDYYYYFormatWithApendZero(
          selectedRecoupmentDate
        );
        lienLevyDetails.noteSetVO = notesInput;
        setSpinnerLoader(true);
        seterrorMessages([]);
        setSystemSuccesMessages([]);
        dispatch(getWriteOffRestablishmentDetailsReset());
        fetchWriteOffReEstablishmentData(lienLevyDetails);
      }
    }
  };

  const validateLienHolderSystemDetails = e => {
    seterrorMessages([]);
    if (
      values.lienHolderType === '' ||
      values.lienHolderType === DEFAULT_DD_VALUE ||
      values.lienHolderType === undefined
    ) {
      showLienHolderTypeErrorTemp = true;
      showLienHolderTypeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeErrorTextTemp);
    }
    if (
      values.lienHolderIdType === '' ||
      values.lienHolderIdType === DEFAULT_DD_VALUE ||
      values.lienHolderIdType === undefined
    ) {
      showLienHolderTypeCodeErrorTemp = true;
      showLienHolderTypeCodeErrorTextTemp =
        ErrorConstants.LIEN_HOLDER_TYPE_CODE_REQUIRED;
      errorMessagesArray.push(showLienHolderTypeCodeErrorTextTemp);
    }
    if (values.lienHolderId === '' || values.lienHolderId === undefined) {
      showLienHolderIDErrorTemp = true;
      showLienHolderIDTextTemp = ErrorConstants.LIEN_HOLDER_ID_REQUIRED;
      errorMessagesArray.push(showLienHolderIDTextTemp);
    }

    setShowError({
      showLienHolderTypeError: showLienHolderTypeErrorTemp,
      showLienHolderTypeCodeError: showLienHolderTypeCodeErrorTemp,
      showLienHolderIDError: showLienHolderIDErrorTemp
    });

    setShowErrorText({
      showLienHolderTypeErrorText: showLienHolderTypeErrorTextTemp,
      showLienHolderTypeCodeErrorText: showLienHolderTypeCodeErrorTextTemp,
      showLienHolderIDText: showLienHolderIDTextTemp
    });

    const validateRequest = {
      thirdPartyIDType: values.lienHolderIdType,
      thirdPartyID: values.lienHolderId,
      thirdPartyType: values.lienHolderType,
      lob: lienLevyDetails.lob
    };

    seterrorMessages(errorMessagesArray);

    if (errorMessagesArray.length === 0) {
      setSpinnerLoader(true)
      onValidateLienHolderDetails(
        commonMethods.removeLeadingTrailingSpaces(validateRequest)
      );
    }
  };
  const confirmDelete = event => {
    PaymentRowDeleteClick();
    setOpenDialog(false);
  };
  const openDeletePopUpDialog = () => {
    if (rowPaymentScheduleData.rowPaymentScheduleData.length !== 0) {
      setOpenDialog(true);
    }
  };
  const closeDeletePopUpDialog = () => {
    setOpenDialog(false);
  };
  // Payee Popup Implementation Starts
  const handleCloseSysPayeeTable = e => {
    setOpenSysPayeeTable(false);
    setCancel(true)
    setValues({
      ...values,
      lienHolderId: "",
    })
  };
  useEffect(()=>{
    if(inputRefCursor.current && canCel) {
      inputRefCursor.current.focus();
    }
  },[canCel])
  const onRadioButtonClick = row => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
    if (currentSysID) {
      setValues({
        ...values,
        lienHolderName: currentSysID.payeeName,
        thirdPartyIDFromDB: currentSysID.sysID
      });
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  // Payee popup Implementation ends
  const sharedPropsDialog =
    showLogDialogTable && !add ? { maxWidth: 'xl', fullWidth: true } : null;
  const valuesFromAREdit = props.history && props.history.location &&
    props.history.location.state && props.history.location.state.values;

  const payloadPayees = props.history && props.history.location &&
    props.history.location.state && props.history.location.state.payloadPayees;

  const cancelMinorSection = () => {
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/FinancialAccountInquiry',
        tableRender: !props.history.location?.isFromAdd,
        state: { values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
  };
  const majorCancel = () => {
    seterrorMessages([]);
    setAllowNavigation(false);

    setTimeout(()=>{
    if (focusCheck) {


      setDialogOpen(true);

    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/FinancialAccountInquiry',
        tableRender: !props.history.location?.isFromAdd,
        state: { values: valuesFromAREdit, payloadPayees: payloadPayees }
      });
    }
  },100)
  };
  const inputRefCursor=useRef(null)
  return (
    <div className={'pos-relative'}>
      <Prompt
        when={allowNavigation}
        message={location =>
          `Are you sure you want to go to ${location.pathname}`
        }
      />
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <div
          align="right"
          className="nav-menu"
          style={{ paddingRight: '5px' }}
        >
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a disabled={true} onClick={ARAdjust}>
                &nbsp;&nbsp;Adjust
              </a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Adjust</span>
          )}
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a disabled={true} onClick={ARReverse}>
                &nbsp;&nbsp;Reverse
              </a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Reverse</span>
          )}
          {userInquiryPrivileges && writeOff ? (
            <span>
              <a disabled={true} onClick={ARTransfer}>
                &nbsp;&nbsp;Transfer
              </a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Transfer</span>
          )}
          {writeOff ? (
            userInquiryPrivileges ? (
              <span>
                <a onClick={NavigateToWriteOff}>&nbsp;&nbsp;Write-Off</a>
              </span>
            ) : (
              <span>&nbsp;&nbsp;Write-Off</span>
            )
          ) : userInquiryPrivileges ? (
            <span>
              <a onClick={NavigateToWriteOffRestablish}>
                &nbsp;&nbsp;Write-Off Re-establish
              </a>
            </span>
          ) : (
            <span>&nbsp;&nbsp;Write-Off Re-establish</span>
          )}
        </div>
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? (
          <SuccessMessages successMessages={systemSuccessMessages} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">Edit Withholding/Lien/Levy</h1>
          <div className="float-right mt-2">

            <Button
              className="btn btn-success ml-1"
              onClick={() => masterUpdateLienLevy()}
              disabled={!userInquiryPrivileges}
            >
              <i class="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button
              color="primary"
              className={
                auditButtonToggle
                  ? 'btn btn-primary ml-1'
                  : 'btn btn-text1 btn-transparent ml-1'
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
              Audit Log
            </Button>
            {window.location.pathname === '/FinancialAccountLienLevyUpdate' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                  id="standard-LienLevyUpdateLOB"
                  disabled={true}
                  label="LOB"
                  value={values.lob}
                onChange={handleChange('lob')}
                  inputProps={{ maxlength: 3 }}
                dropdownList={lobCodeData && lobCodeData?.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <NativeDropDown
                  id="standard-LienLevyUpdatePayeeType"
                  label="Payee Type"
                  value={values.payeeType}
                onChange={handleChange('payeeType')}
                inputProps={{ maxlength: 3 }}
                  disabled={true}
                dropdownList={addDropdowns && addDropdowns['G1#G_CMN_ENTY_TY_CD'] && 
                    addDropdowns['G1#G_CMN_ENTY_TY_CD'].length > 0
                      ? addDropdowns['G1#G_CMN_ENTY_TY_CD'].map(option => (
                        <option key={option.code} value={option.code}>
                            {option.description}
                        </option>
                        ))
                    : null}
              />
              <NativeDropDown
                  id="standard-LienLevyUpdatePayeeIDType"
                  label="Payee ID Type Code"
                  value={values.payeeIdTypeCode}
                onChange={handleChange('payeeIdTypeCode')}
                inputProps={{ maxlength: 3 }}
                  disabled={true}
                dropdownList={values.payeeType === 'M' ?
                    addDropdowns && addDropdowns['F1#B_ALT_ID_TY_CD'] && 
                    addDropdowns['F1#B_ALT_ID_TY_CD'].length > 0
                      ? addDropdowns['F1#B_ALT_ID_TY_CD'].map(option => (
                        <option key={option.code} value={option.code}>
                            {option.description}
                        </option>
                        )) : null
                    : values.payeeType === 'P' ?
                        addDropdowns && addDropdowns['F1#P_ALT_ID_TY_CD'] && 
                        addDropdowns['F1#P_ALT_ID_TY_CD'].length > 0
                        ? addDropdowns['F1#P_ALT_ID_TY_CD'].map(option => (
                          <option key={option.code} value={option.code}>
                              {option.description}
                          </option>
                          )) : null
                    : values.payeeType === 'O' ?
                          addDropdowns && addDropdowns['F1#G_ENTY_EXTL_ID_TY_CD'] && 
                          addDropdowns['F1#G_ENTY_EXTL_ID_TY_CD'].length > 0
                            ? addDropdowns['F1#G_ENTY_EXTL_ID_TY_CD'].map(option => (
                              <option key={option.code} value={option.code}>
                                  {option.description}
                              </option>
                              )) : null
                      : values.payeeType === 'TC' ?
                              addDropdowns && addDropdowns['F1#DM_G_CARR_ENTY_ID_TY_CD'] && 
                              addDropdowns['F1#DM_G_CARR_ENTY_ID_TY_CD'].length > 0
                              ? addDropdowns['F1#DM_G_CARR_ENTY_ID_TY_CD'].map(option => (
                                <option key={option.code} value={option.code}>
                                    {option.description}
                                </option>
                                )): null
                      : null}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-LienLevyUpdatePayeeID"
                  fullWidth
                  label="Payee ID"
                  InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                  value={values.payeeId}
                  onChange={handleChange('payeeId')}
                  inputProps={{ maxlength: 15 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form  input-md">
                <TextField
                  InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                  fullWidth
                  id="standard-LienLevyUpdatePayeeName"
                  label="Payee Name "
                  placeholder=""
                  inputProps={{ maxlength: 60 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeePayerName}
                ></TextField>
              </div>
              <div className="mui-custom-form  input-md has-link">
                <TextField
                  InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                  fullWidth
                  id="standard-LienLevyUpdateSystemPayeeID"
                  label="System Payee ID"
                  placeholder=""
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link to={redirectSystemID(values)} target='_blank'>
                          {values.systemPayeeId}
                        </Link>
                      </InputAdornment>
                    )
                  }}
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                ></TextField>
              </div>
              <NativeDropDown
                  disabled={true}
                  id="standard-LienLevyUpdateActivityType"
                  label="Activity Type"
                  value={values.activityCode}
                onChange={handleChange('activityCode')}
                  inputProps={{ maxlength: 3 }}
                dropdownList={addDropdowns && addDropdowns['F1#F_ACTV_TY_CD'] && 
                    addDropdowns['F1#F_ACTV_TY_CD'].length > 0
                      ? addDropdowns['F1#F_ACTV_TY_CD'].map(option => (
                        <option key={option.code} value={option.code}>
                            {option.description}
                        </option>
                        ))
                    : null}
              />
              <NativeDropDown
                  disabled={true}
                  id="standard-LienLevyUpdateReasonCode"
                  label="Reason Code"
                  value={values.reasonCode}
                onChange={handleChange('reasonCode')}
                inputProps={{ maxlength: 3 }}
                  title={getLongDescription(reasonCodeData, values.reasonCode)}
                dropdownList={addDropdowns && addDropdowns['F1#F_RSN_CD'] && 
                    addDropdowns['F1#F_RSN_CD'].length > 0
                      ? addDropdowns['F1#F_RSN_CD'].map(option => (
                        <option key={option.code} value={option.code}>
                            {option.description}
                        </option>
                        ))
                    : null}
              />
            </div>
          </form>
          <div className="tab-panelbody m-3">
            <div className="tab-holder mb-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab
                    label="Withholding/Lien/Levy Detail"
                    className={'original-tab' + color}
                  />
                  <Tab label="Payment Schedule" className={' ml-4'} />
                  <Tab label="History" className={' ml-3'} />
                  <Tab label="Notes" />
                  {<Tab label="Attachments" />}
                </Tabs>
              </AppBar>
              <TabPanel value={printLayout ? 0 : value} index={0}>
                {value === 0 && (
                  <div className="tab-holder my-3 p-0">
                    <LienLevyDetailsComponent
                      dropDownCheckList={dropDownCheckList || []}
                      payeePaymentStatusFrmState={payeePaymentStatusFrmState}
                      values={values}
                      frequencyDataDropdown={frequencyDataDropdown}
                      lienHolderTypeData={lienHolderTypeData}
                      lienHolderIdTypeData={lienHolderIdTypeData}
                      recoupmentData={recoupmentData}
                      frequencyData={frequencyData}
                      COSData={COSData}
                      fundCodeData={fundCodeData}
                      handleChange={handleChange}
                      handleDecimalValues={handleDecimalValues}
                      formatDecimalField={formatDecimalField}
                      formatPercent={formatPercent}
                      removeFormatDecimalFieldInstallment={
                        removeFormatDecimalFieldInstallment
                      }
                      removeFormatDecimalFieldWHAmount={
                        removeFormatDecimalFieldWHAmount
                      }
                      errors={[
                        showOriginalArAmmountError,
                        showFiscalYearError,
                        showCollocationCodeError,
                        showRecoupmentTypeCodeError,
                        showInvalidBeginDateError,
                        showInvalidEndDateError,
                        showEndDateLesserError,
                        showBeginDateRequiredError,
                        showEndDateRequiredError,
                        showPercentError,
                        showInstallementError,
                        showStartDateRequiredError,
                        showFrequencyError,
                        showLienHolderTypeError,
                        showLienHolderTypeCodeError,
                        showLienHolderIDError,
                        showATRError
                      ]}
                      errorText={[
                        showOriginalArAmountErrorText,
                        showFiscalYearErrorText,
                        showCollocationCodeErrorText,
                        showRecoupmentTypeCodeErrorText,
                        showInvalidBeginDateText,
                        showInvalidEndDateInvalidText,
                        showEndDateLesserErrorText,
                        showBeginDateRequiredErrorText,
                        showEndDateRequiredErrorText,
                        showPercentErrorText,
                        showInstallmentErrorText,
                        showStartDateRequiredErrorText,
                        showFrequencyErrorText,
                        showLienHolderTypeErrorText,
                        showLienHolderTypeCodeErrorText,
                        showLienHolderIDText,
                        showATRErrorText
                      ]}
                      selectedBeginDate={selectedBeginDate}
                      handleBeginDateChange={handleBeginDateChange}
                      selectedEndDate={selectedEndDate}
                      selectedRecoupmentDate={selectedRecoupmentDate}
                      handleRecoupmentDateChange={handleRecoupmentDateChange}
                      handleEndDateChange={handleEndDateChange}
                      defaultRecoupment={handleDefaultRecoupment}
                      selectedInitialPaymentDate={selectedInitialPaymentDate}
                      selectedSatisfiedDate={selectedSatisfiedDate}
                      handleInitialPaymentDate={handleInitialPaymentDate}
                      handleSatisfiedDate={handleSatisfiedDate}
                      inputRefCursor={inputRefCursor}
                      isDisabled={isDisabled}
                      validateLienHolderSystemDetails={
                        validateLienHolderSystemDetails
                      }
                    ></LienLevyDetailsComponent>
                  </div>
                )}
                {printLayout ? (
                  <div className="tab-holder my-3 p-0 hide-on-screen hide-on-print">
                    <h4 className="hide-on-screen hide-on-print">
                      <span class="badge badge-primary">
                        Withholding/Lien/Levy Detail
                      </span>
                    </h4>
                    <LienLevyDetailsComponent
                      print
                      dropDownCheckList={dropDownCheckList || []}
                      values={values}
                      frequencyDataDropdown={frequencyDataDropdown}
                      lienHolderTypeData={lienHolderTypeData}
                      lienHolderIdTypeData={lienHolderIdTypeData}
                      recoupmentData={recoupmentData}
                      frequencyData={frequencyData}
                      COSData={COSData}
                      fundCodeData={fundCodeData}
                      handleChange={handleChange}
                      handleDecimalValues={handleDecimalValues}
                      formatDecimalField={formatDecimalField}
                      formatPercent={formatPercent}
                      removeFormatDecimalFieldInstallment={
                        removeFormatDecimalFieldInstallment
                      }
                      removeFormatDecimalFieldWHAmount={
                        removeFormatDecimalFieldWHAmount
                      }
                      errors={[
                        showOriginalArAmmountError,
                        showFiscalYearError,
                        showCollocationCodeError,
                        showRecoupmentTypeCodeError,
                        showInvalidBeginDateError,
                        showInvalidEndDateError,
                        showEndDateLesserError,
                        showBeginDateRequiredError,
                        showEndDateRequiredError,
                        showPercentError,
                        showInstallementError,
                        showStartDateRequiredError,
                        showFrequencyError,
                        showLienHolderTypeError,
                        showLienHolderTypeCodeError,
                        showLienHolderIDError,
                        showATRError
                      ]}
                      errorText={[
                        showOriginalArAmountErrorText,
                        showFiscalYearErrorText,
                        showCollocationCodeErrorText,
                        showRecoupmentTypeCodeErrorText,
                        showInvalidBeginDateText,
                        showInvalidEndDateInvalidText,
                        showEndDateLesserErrorText,
                        showBeginDateRequiredErrorText,
                        showEndDateRequiredErrorText,
                        showPercentErrorText,
                        showInstallmentErrorText,
                        showStartDateRequiredErrorText,
                        showFrequencyErrorText,
                        showLienHolderTypeErrorText,
                        showLienHolderTypeCodeErrorText,
                        showLienHolderIDText,
                        showATRErrorText
                      ]}
                      selectedBeginDate={selectedBeginDate}
                      handleBeginDateChange={handleBeginDateChange}
                      selectedEndDate={selectedEndDate}
                      selectedRecoupmentDate={selectedRecoupmentDate}
                      handleRecoupmentDateChange={handleRecoupmentDateChange}
                      handleEndDateChange={handleEndDateChange}
                      defaultRecoupment={handleDefaultRecoupment}
                      selectedInitialPaymentDate={selectedInitialPaymentDate}
                      selectedSatisfiedDate={selectedSatisfiedDate}
                      handleInitialPaymentDate={handleInitialPaymentDate}
                      handleSatisfiedDate={handleSatisfiedDate}
                      isDisabled={isDisabled}
                      validateLienHolderSystemDetails={
                        validateLienHolderSystemDetails
                      }
                    ></LienLevyDetailsComponent>
                    {showLogTable ? (
                      <div id="audit-table">
                        <AuditTableComponent
                          print
                          auditRowData={auditRowData}
                          setAuditRowData={setAuditRowData}
                          setShowLogTable={setShowLogTable}
                        />{' '}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </TabPanel>

              <TabPanel value={printLayout ? 1 : value} index={1}>
                {value === 1 && (
                  <>
                    <div className="tab-holder">
                      <form autoComplete="off">
                        <div className="form-wrapper">
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <NativeDropDown
                              className='min-md'
                                id="standard-GrossDetailsCategoryofService"
                                label="Payment Schedule"
                                value={values.paymentScheduleType}
                              onChange={handleChange('paymentScheduleType')}
                              inputProps={{ maxlength: 3 }}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                    manualEditFlag
                                }
                              isRequired={values.recoupmentTypeCode === 'S'}
                              errTxt={
                                  paymentScheduleTypeError !== ''
                                    ? paymentScheduleTypeError
                                    : null
                                }
                              dropdownList={paymentScheduleTypeData && paymentScheduleTypeData?.map(
                                  (option, index) => (
                                  <option key={index} value={option.code}>
                                      {option.description}
                                  </option>
                                  )
                                )}
                            />
                            <div className="mui-custom-form with-date">
                              <KeyboardDatePicker
                                fullWidth
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                id="GrossLevelDetailBegindate-picker-dialog"
                                label="Recoupment Start Date"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                minDate={
                                  new Date(
                                    new Date().toLocaleString('en-US', {
                                      timeZone: process.env.REACT_APP_TIMEZONE
                                    })
                                  )
                                }
                                initialFocusedDate={
                                  new Date(
                                    new Date().toLocaleString('en-US', {
                                      timeZone: process.env.REACT_APP_TIMEZONE
                                    })
                                  )
                                }
                                value={selectedRecoupmentStartDate}
                                onChange={handleRecoupmentStartDateChange}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date'
                                }}
                                required={values.recoupmentTypeCode === 'S'}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                    manualEditFlag
                                }
                                helperText={
                                  recoupmentStartDateError !== ''
                                    ? recoupmentStartDateError
                                    : null
                                }
                                error={
                                  recoupmentStartDateError !== ''
                                    ? recoupmentStartDateError
                                    : null
                                }
                              />
                            </div>
                          </MuiPickersUtilsProvider>
                          <div className="mui-custom-form input-md override-m-2">
                            <TextField
                              fullWidth
                              id="standard-GrossDetailsOriginalA/RAmount"
                              label="Number of Installments"
                              placeholder=""
                              inputProps={{ maxlength: 10 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={values.numberofInstallments}
                              onChange={handleChange('numberofInstallments')}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  manualEditFlag
                              }
                              required={values.paymentScheduleType === 'A'}
                              helperText={
                                numberofInstallmentError !== ''
                                  ? numberofInstallmentError
                                  : null
                              }
                              error={
                                numberofInstallmentError !== ''
                                  ? numberofInstallmentError
                                  : null
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~ ]/,
                                  ''
                                );
                              }}
                            ></TextField>
                          </div>
                          <NativeDropDown
                            className='min-md override-m-2'
                              id="standard-GrossDetailsCategoryofService"
                              label="Frequency"
                              value={values.paymentScheduleFrequency}
                              onChange={handleChange(
                                'paymentScheduleFrequency'
                              )}
                              inputProps={{ maxlength: 3 }}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  manualEditFlag
                              }
                            isRequired={values.paymentScheduleType === 'A'}
                            errTxt={
                                frequencyError !== '' ? frequencyError : null
                              }
                            dropdownList={frequencyRecoupData && frequencyRecoupData?.map((option, index) => (
                              <option key={index} value={option.code}>
                                  {option.description}
                              </option>
                              ))}
                          />
                        </div>
                        <div className="form-wrapper">
                          <div
                            className="mui-custom-form min-md"
                            style={{ lineHeight: '100%' }}
                          >
                            <label className="MuiFormLabel-root small-label">
                              Interest Calculation
                            </label>
                            <div className="sub-radio no-margin">
                              <Radio
                                value="Y"
                                id="GroosLevelDetailsapprovalIdYes"
                                checked={values.interestCalculation === 'Y'}
                                onChange={handleChange('interestCalculation')}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    manualEditFlag
                                }
                              />
                              <label
                                className="text-black"
                                for="GroosLevelDetailsapprovalIdYes"
                              >
                                Yes
                              </label>
                              <Radio
                                value="N"
                                id="GroosLevelDetailsapprovalIdNo"
                                checked={values.interestCalculation === 'N'}
                                onChange={handleChange('interestCalculation')}
                                className="ml-2"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.recoupmentTypeCode !== 'S' ||
                                    values.paymentScheduleType !== 'A' ||
                                    manualEditFlag
                                }
                              />
                              <label
                                className="text-black"
                                for="GroosLevelDetailsapprovalIdNo"
                              >
                                No
                              </label>
                            </div>
                          </div>
                          <div className="mui-custom-form input-md">
                            <TextField
                              fullWidth
                              id="standard-GrossDetailsOriginalA/RAmount"
                              label="Interest Percentage"
                              placeholder=""
                              inputProps={{ maxlength: 14 }}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                endAdornment: <InputAdornment>%</InputAdornment>
                              }}
                              value={values.interestPercentage}
                              onBlur={formatDecimalField('interestPercentage')}
                              onFocus={removeFormatDecimalFieldPercent(
                                'interestPercentage'
                              )}
                              onChange={handleChange('interestPercentage')}
                              onKeyDown={evt =>
                                evt.key === 'e' && evt.preventDefault()
                              }
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.recoupmentTypeCode !== 'S' ||
                                  values.paymentScheduleType !== 'A' ||
                                  values.interestCalculation === 'N'
                              }
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                  ''
                                );
                              }}
                              required={values.interestCalculation === 'Y'}
                              helperText={
                                interestCalError !== ''
                                  ? interestCalError
                                  : null
                              }
                              error={
                                interestCalError !== ''
                                  ? interestCalError
                                  : null
                              }
                            ></TextField>
                          </div>
                        </div>
                      </form>
                      <div className="float-right mt-3">
                        <Button
                          className="btn-text-main-add btn-success"
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : values.recoupmentTypeCode !== 'S' ||
                              values.paymentScheduleType !== 'A' ||
                              manualEditFlag
                          }
                          onClick={calculateSchedule}
                          title={'Calculate'}
                        >
                          Calculate
                        </Button>
                        <Button
                          className="btn-text-main-delete btn-transparent ml-1"
                          onClick={openDeletePopUpDialog}
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : values.recoupmentTypeCode !== 'S' ||
                              values.paymentScheduleType === 'A' ||
                              values.balanceAmount === '0.00'
                          }
                          title={'Delete'}
                        >
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button
                          className="btn-text-main-add btn-success ml-1"
                          onClick={handleClickOpenPaymentSchedule}
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : values.recoupmentTypeCode !== 'S' ||
                              values.paymentScheduleType === 'A' ||
                              values.balanceAmount === '0.00'
                          }
                          title={'Add Payment Schedule'}
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>
                          Add
                        </Button>
                      </div>

                      <div className="clearfix"></div>
                    </div>
                    <div className="tab-holder my-3 p-0">
                      {values.paymentScheduleType === 'M' ? (
                        <PaymentScheduleTable
                          tableData={paymentScheduleData}
                          onRowClick={editRow}
                          onTableCellButtonClick={onClickAuditDialogLog}
                          uniqueKey={'scheduleSK'}
                          setClose={setClose}
                          close={close}
                          rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                          isSearch={false}
                        />
                      ) : (
                        <PaymentScheduleTable
                          tableData={paymentScheduleData}
                          onRowClick={editRow}
                          rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                          isSearch={true}
                          onTableCellButtonClick={onClickAuditDialogLog}
                          uniqueKey={'scheduleSK'}
                          setClose={setClose}
                          close={close}
                        />
                      )}
                    </div>
                    {open ? (
                      <div className="tabs-container" id="form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left">
                            {add
                              ? 'Add Payment Schedule'
                              : 'Edit Payment Schedule'}
                          </div>
                          <div className="float-right mt-1">
                            {add ? (
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-success ml-1"
                                onClick={savePaymentSchedule}
                                disabled={values.paymentScheduleType === 'A'}
                                title={'Add'}
                              >
                                <i class="fa fa-check" aria-hidden="true"></i>
                                Add
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                color="primary"
                                className="btn btn-success ml-1"
                                onClick={savePaymentSchedule}
                                disabled={values.paymentScheduleType === 'A'}
                                title={'Update'}
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                                Update
                              </Button>
                            )}
                            <Button
                              variant="outlined"
                              color="primary"
                              className="bt-reset btn-transparent ml-1"
                              onClick={resetPaymentSchedule}
                              disabled={values.paymentScheduleType === 'A'}
                            >
                              <i class="fa fa-undo" aria-hidden="true"></i>
                              Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                        <div className="form-border my-3">
                          <form autoComplete="off">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <div className="form-wrapper">
                                <div className="mui-custom-form with-date override-width-20">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      '31 Dec 9999 00:00:00 GMT'
                                    )}
                                    required
                                    fullWidth
                                    id="dueDate"
                                    label="Due Date"
                                    minDate={
                                      new Date(
                                        new Date().toLocaleString('en-US', {
                                          timeZone:
                                            process.env.REACT_APP_TIMEZONE
                                        })
                                      )
                                    }
                                    initialFocusedDate={
                                      new Date(
                                        new Date().toLocaleString('en-US', {
                                          timeZone:
                                            process.env.REACT_APP_TIMEZONE
                                        })
                                      )
                                    }
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    value={selectedDueDate}
                                    onChange={handleDueDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                    disabled={
                                      values.paymentScheduleType === 'A'
                                    }
                                    helperText={
                                      showDueDateError
                                        ? showDueDateErrorText
                                        : null || showDueDateGreaterError
                                          ? showDueDateGreaterErrorText
                                          : null || showMinDueDateError
                                            ? showMinDueDateErrorText
                                            : null || showDueDateInvalidError
                                              ? showDueDateInvalidErrorText
                                              : null
                                    }
                                    error={
                                      showDueDateError
                                        ? showDueDateErrorText
                                        : null || showDueDateGreaterError
                                          ? showDueDateGreaterErrorText
                                          : null || showMinDueDateError
                                            ? showMinDueDateErrorText
                                            : null || showDueDateInvalidError
                                              ? showDueDateInvalidErrorText
                                              : null
                                    }
                                  />
                                </div>
                                <div className="mui-custom-form input-md override-width-20">
                                  <TextField
                                    id="amountdue"
                                    fullWidth
                                    label="Installment Amount"
                                    required
                                    inputProps={{ maxlength: 14 }}
                                    value={paymentScheduleVO.installmentAmount}
                                    onChange={handleChangePaymentSchedule(
                                      'installmentAmount'
                                    )}
                                    onBlur={formatDecimalFieldInstallment(
                                      'installmentAmount'
                                    )}
                                    onFocus={removeFormatDecimalFieldAmountDue(
                                      'installmentAmount'
                                    )}
                                    disabled={
                                      values.paymentScheduleType === 'A'
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      )
                                    }}
                                    helperText={
                                      showAmountDueError
                                        ? showAmountDueErrorText
                                        : null || showNegativeAmountError
                                          ? showNegativeAmmountErrorText
                                          : null || showGreaterThanZeroAmountError
                                            ? showGreaterThanZeroAmountText
                                            : null || showOriginalArAmountPopError
                                              ? showOriginalArAmountPopErrorText
                                              : null
                                    }
                                    error={
                                      showAmountDueError
                                        ? showAmountDueErrorText
                                        : null || showNegativeAmountError
                                          ? showNegativeAmmountErrorText
                                          : null || showGreaterThanZeroAmountError
                                            ? showGreaterThanZeroAmountText
                                            : null || showOriginalArAmountPopError
                                              ? showOriginalArAmountPopErrorText
                                              : null
                                    }
                                    onInput={e => {
                                      e.target.value = e.target.value.replace(
                                        /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                        ''
                                      );
                                    }}
                                  ></TextField>
                                </div>
                                <div className="mui-custom-form input-md override-width-20">
                                  <TextField
                                    id="amountdue"
                                    fullWidth
                                    label="Recouped Amount"
                                    required
                                    disabled
                                    inputProps={{ maxlength: 14 }}
                                    value={paymentScheduleVO.recoupedAmount}
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          $
                                        </InputAdornment>
                                      )
                                    }}
                                  ></TextField>
                                </div>
                              </div>
                            </MuiPickersUtilsProvider>
                          </form>
                          {showLogDialogTable ? (
                            <div id="audit-dialog-table">
                              <AuditTableComponent
                                print
                                auditRowData={auditDialogRowData}
                                setAuditRowData={setAuditDialogRowData}
                                setShowLogTable={setShowLogDialogTable}
                                setClose={setClose}
                              />{' '}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
                <h4 className="hide-on-screen hide-on-print">
                  <span class="badge badge-primary">Payment Schedule</span>
                </h4>
                <div className="tab-holder my-3 p-0 hide-on-screen hide-on-print">
                  {values.paymentScheduleType === 'M' ? (
                    <PaymentScheduleTable
                      print
                      tableData={paymentScheduleData}
                      onRowClick={editRow}
                      onTableCellButtonClick={onClickAuditDialogLog}
                      uniqueKey={'paymentScheduleKey'}
                      setClose={setClose}
                      close={close}
                      rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                      isSearch={false}
                    />
                  ) : (
                    <PaymentScheduleTable
                      print
                      tableData={paymentScheduleData}
                      onRowClick={editRow}
                      rowDeletePaymentSchedule={rowDeletePaymentSchedule}
                      isSearch={true}
                      onTableCellButtonClick={onClickAuditDialogLog}
                      uniqueKey={'paymentScheduleKey'}
                      setClose={setClose}
                      close={close}
                    />
                  )}
                </div>
              </TabPanel>
              <TabPanel value={printLayout ? 2 : value} index={2}>
                {value === 2 && (
                  <>
                    <div className="tab-holder my-3 p-0">
                      <LienLevyHistoryTableComponent
                        values={values}
                        historyTableData={historyTableData}
                      />
                    </div>
                  </>
                )}
                {printLayout ? (
                  <div>
                    <h4 className="hide-on-screen hide-on-print">
                      <span class="badge badge-primary">History</span>
                    </h4>
                    <div className="tab-holder my-3 p-0 hide-on-screen hide-on-print">
                      <LienLevyHistoryTableComponent
                        print
                        values={values}
                        historyTableData={historyTableData}
                      />
                    </div>
                  </div>
                ) : null}
              </TabPanel>
              <TabPanel value={printLayout ? 3 : value} index={3}>
                {value === 3 && (
                  <div className="tab-holder p-0">
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      setFocusCheck={setFocusCheck}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      setErrorMessages={seterrorMessages}
                      userInquiryPrivileges={userInquiryPrivileges}
                    />
                  </div>
                )}
                <h4 className="hide-on-screen hide-on-print">
                  <span class="badge badge-primary">Notes</span>
                </h4>
                <div className="tab-holder p-0 hide-on-screen hide-on-print">
                  <Notes
                    print
                    addNotes={addNotes}
                    notesTableData={notesTableData}
                    noteSetListInput={noteSetListInput}
                    setNoteSetListInput={setNoteSetListInput}
                    usageTypeCodeData={usageTypeCodeData}
                    editNoteData={editNoteData}
                    setFocusCheck={setFocusCheck}
                    setEditNoteData={setEditNoteData}
                    setSuccessMessages={clearSuccessErrorMsgs}
                    setErrorMessages={seterrorMessages}
                    userInquiryPrivileges={userInquiryPrivileges}
                  />
                </div>
              </TabPanel>
              {
                <TabPanel
                  className={printLayout ? 'hide-on-screen hide-on-print' : ''}
                  value={printLayout ? 4 : value}
                  index={4}
                >
                  <div className="tab-holder p-0">
                    <h4 className="hide-on-screen hide-on-print">
                      <span class="badge badge-primary">Attachments</span>
                    </h4>
                  </div>
                </TabPanel>
              }
              {
                <TabPanel
                  className={printLayout ? 'hide-on-screen hide-on-print' : ''}
                  value={printLayout ? 4 : value}
                  index={4}
                >
                  <div className="tab-holder p-0">
                    <Attachments
                      userInquiryPrivileges={userInquiryPrivileges}
                      attachmentFile={attachmentFile}
                      setAttachmentFile={setAttachmentFile}
                      attachmentsTableData={attachmentsTableData}
                      setAttachmentsTableData={setAttachmentsTableData}
                      parentErrorMesage={seterrorMessages}
                      setAllowNavigation={setAllowNavigation}
                      setSystemSuccesMessages={clearSuccessErrorMsgs}
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                </TabPanel>
              }
              {showLogTable ? (
                <div id="audit-table">
                  <AuditTableComponent
                    print
                    auditRowData={auditRowData}
                    setAuditRowData={setAuditRowData}
                    setShowLogTable={setShowLogTable}
                  />{' '}
                </div>
              ) : null}
            </div>
          </div>
          <Dialog
            className="custom-dialog dialog-500 with-padding"
            fullWidth={true}
            maxWidth={'sm'}
            open={openDialog}
          >
            <DialogContent dividers>
              <div>Are you sure you want to Delete?</div>
            </DialogContent>

            <DialogActions>
              <Button
                className="btn btn-primary ml-1"
                onClick={confirmDelete}
                disabled={!userInquiryPrivileges}
              >
                OK
              </Button>
              <Button
                className="btn btn-primary ml-1"
                onClick={closeDeletePopUpDialog}
                disabled={!userInquiryPrivileges}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                Unsaved changes will be lost. <br />
                Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
              color="primary"
              className="btn btn-transparent"
            >
              STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
              CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <SystemPayeeTable
        payeeType={payeeTypeDesc}
        payeeIdTypeCode={payeeTypeIdCodeDesc}
        payeeId={values.lienHolderId}
        openSysPayeeTable={openSysPayeeTable}
        handleCloseSysPayeeTable={handleCloseSysPayeeTable}
        uniqueKey={'sysID'}
        onTableCellButtonClick={onRadioButtonClick}
        tableData={systemPayeeTableData}
        currentSysID={currentSysID}
        onContinue={onContinue}
      />
    </div>
  );
}
