/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { Component, useEffect, useState, useRef } from "react";
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from "@material-ui/core/TextField";
import Bootstrap, { Button,Form } from "react-bootstrap";
import {
  lighten,
  makeStyles,
  useTheme,
  withStyles
} from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import MapSetUpdateDefinitionData from "./MapSetUpdateDefinitionData";
import * as MapSetConstants from "./MapSetConstants";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import {
  generateUUID,
  compareTwoDatesGreaterThanOrEqual,
  getDateInMMDDYYYYFormat,
  validateDateMinimumValue,
  getUTCTimeStamp
} from "../../../../SharedModules/DateUtilities/DateUtilities";
import DateFnsUtils from "@date-io/date-fns";
import NoSaveMessage from "../../../../SharedModules/Errors/NoSaveMessage";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import {
  AppConfigDropdownActions,
  DataElementMapDropdownActions
} from "../../Store/Actions/AppConfigCommon/AppConfigActions";
import { useDispatch, useSelector } from "react-redux";
import { Prompt } from "react-router-dom";
import { withRouter } from "react-router";

import {
  mapDefinitionAdd,
  mapDefinitionUpdate,
  mapSetActionView,
  systemListActions,
  resetSearchMapSet,
  resetAddMapSet,
  getAuditLogDetailsAction,
  getAuditLogRowDetailsAction
} from "../../Store/Actions/MapSet/mapSetActions";
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../../../../SharedModules/Dialog/DialogUtilities";
import TableComponent from "../../../../SharedModules/Table/Table";
import SuccessMessage from "../../../../SharedModules/Errors/SuccessMessage";
import ErrorMessage from "../../../../SharedModules/Errors/ErrorMessages";
import dropdownCriteria from "./MapSetAddUpdate.json";
import * as AppConstants from "../../../../SharedModules/AppConstants";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../../SharedModules/TabPanel/TabPanel";
import AppBar from "@material-ui/core/AppBar";
import Notes from "../../../../SharedModules/Notes/Notes";
import Footer from "../../../../SharedModules/Layout/footer";
import moment from "moment";
import NavHOC from "../../../../SharedModules/Navigation/NavHOC";
import Spinner from "../../../../SharedModules/Spinner/Spinner";
import Radio from "@material-ui/core/Radio";
import AuditTableComponent from "../../../../SharedModules/AuditLog/AuditTableComponent";
import { LaptopWindows } from "@material-ui/icons";
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";

const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
  },
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  tableWrapper: {
    overflowX: "auto"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

function MapSetAdd(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [value, setValue] = React.useState(0);
  const [tabOne, setTabOne] = React.useState(true);
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditBtnClass, setAuditBtnClass] =React.useState("btn btn-text1 btn-transparent ml-1")

  // Total Characters
  const [totalCharacters] = React.useState(320);
  const handleChangeTabs = (event, newValue) => {
    setFocusCheck(true);
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
  };

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [intialFocusCheck, setIntialFocusCheck] = React.useState(false);
  const [dialogContinueOpen, setContinueDialogOpen] = React.useState(false);
  const [editAddDefinitionData, setEditAddDefinitionData] = React.useState(false);
  
  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const auditLogTableData = useSelector(
    state => state.mapSetState.auditRowData
  );
  const [auditTableData, setAuditTableData] = React.useState([]);
  const [close, setClose] = React.useState(true);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [auditLogRow, setAuditLogRow] = React.useState(null);
  const auditLogData = useSelector(state => state.mapSetState.auditData);
   const [auditRowData, setAuditRowData] = React.useState([]);
   const [disableNotes, setDisableNotes] = React.useState(true);
  const [showLogTable, setShowLogTable] = React.useState(false);
  const [versionNo, setVersionNo] = React.useState("");
  let newDialogData1 = [];
  let errorMessagesArray = [];
  const successMessagesArray = [];
  let loopData = [];
  let defDataObj = {};
  let mapDefinitionDataArray = [];
  let addOutputResponse = [];
  let updateOutputResponse = [];
  let systemLists = [];
  let payloadViewDataOnAdd = [];
  let checkBeginDate = false;
  const addheadCells = [
    {
      id: "beginDate",
      numeric: false,
      disablePadding: true,
      label: "Begin Date",
      isDate: true,
      enableHyperLink: true,
      width: "15%"
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      label: "End Date",
      isDate: true,
      width: "15%"
    },
    {
      id: "exclude",
      numeric: false,
      disablePadding: false,
      label: "Exclude",
      isBoolean: true,
      width: "11%"
    },
    {
      id: "dataElementCriteria",
      numeric: false,
      disablePadding: false,
      label: "Data Element Criteria",
      width: "24%"
    },
    {
      id: "functionalAreaDesc",
      numeric: false,
      disablePadding: false,
      label: "Functional Area",
      width: "20%"
    },
    {
      id: "value",
      numeric: false,
      disablePadding: false,
      label: "Value",
      width: "15%"
    }
  ];
  const editheadCells = [
    {
      id: "beginDate",
      numeric: false,
      disablePadding: true,
      label: "Begin Date",
      isDate: true,
      enableHyperLink: true,
      width: "15%"
    },
    {
      id: "endDate",
      numeric: false,
      disablePadding: false,
      width: "15%",
      isDate: true,
      label: "End Date"
    },
    {
      id: "exclude",
      numeric: false,
      disablePadding: false,
      label: "Exclude",
      isBoolean: true,
      width: "13%"
    },
    {
      id: "dataElementCriteria",
      numeric: false,
      disablePadding: false,
      label: "Data Element Criteria",
      width: "24%"
    },
    {
      id: "functionalAreaDesc",
      numeric: false,
      disablePadding: false,
      label: "Functional Area",
      width: "20%"
    },
    {
      id: "value",
      numeric: false,
      disablePadding: false,
      label: "Value",
      width: "12%"
    },
    {
      id: "voidDate",
      numeric: false,
      disablePadding: false,
      label: "Void Date",
      isDate: true,
      width: "12%"
     }
  ];


  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [editMapDefinitionVoid, seteditMapDefinitionVoid] = React.useState(
    false
  );
  const [mapDefinitionTableData, setmapDefinitionTableData] = React.useState(
    []
  );

  

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [successMessage, setSuccessMessage] = React.useState([]);
  const [LoadSpinner, setSpinnerLoader] = React.useState(false);
  const [masterSaveDefDataType, setMasterSaveDefDataType] = useState("");
  const [gridData, setgridData] = React.useState([]);
  const [gridUpdatedTable, setGridUpdatedTable] = React.useState(gridData);
  const [
    {
      showMapIDError,
      showMapDescError,
      showMapLongDescError,
      showMapTypeError,
      showLOBError,
      showDataElementCriteriaError,
      showFunctionalError,
      showDateOverlappingError,
      showValueError,
      showFromError,
      showThruError,
      showBeginDateError,
      showEndDateError,
      showFromThruError
    },
    setShowError
  ] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showEditField, setshowEditField] = React.useState(false);
  const [showEditScreen, setshowEditScreen] = React.useState(false);
  const [
    showMapDefinitionAddForm,
    setshowMapDefinitionAddForm
  ] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [id, setId] = React.useState(0);
  const [selectedDate, setSelectedDateEdit] = React.useState("");
  const [beginDate, setSelectedDate] = React.useState(null);
  const [endDate, setSelectedDate1] = React.useState("12/31/9999");
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [
    {
      beginDateerrorText,
      endDateerrorText,
      showMapIDErrorText,
      showMapDescriptionErrorText,
      showMapLongDescriptionErrorText
    },
    setShowErrorText
  ] = React.useState("");
  const [rowData, setRowData] = React.useState({});
  const [definitionrowData, setdefinitionrowData] = React.useState({});
 
  const [values, setValues] = React.useState({
    LOB: DEFAULT_DD_VALUE,
    mapType: DEFAULT_DD_VALUE,
    mapId: "",
    description: "",
    mapLongDescription: ''
  });
  const [showResults, setshowResults] = React.useState(true);
  const [showEditResults, setshowEditResults] = React.useState(false);
  const [dataElement, setDataElement] = React.useState({
    id: 0,
    dataElementCriteria: DEFAULT_DD_VALUE,
    functionalArea: DEFAULT_DD_VALUE,
    value: DEFAULT_DD_VALUE,
    value1: null,
    value2: null,
    associatedselectedOption: "",
    exclude: false
  });
  const [check, setcheck] = React.useState("YES");
  const [newDialogData, setnewDialogData] = React.useState([]);
  const [newDialogDataAdd, setnewDialogDataAdd] = React.useState([]);
  const [eombType, setEombtype] = useState("List");
  const dispatch = useDispatch();
  const toPrintRef = useRef();

  const [MapTypeReference, setMapTypeReference] = useState([]);
  const [dataElementReference, setdataElementReference] = useState([]);
  const [functionalAreaReference, setfunctionalAreaReference] = useState([]);
  const [mapDefinitionDataArrayState, setmapDefinitionDataArray] = useState([]);
  const [searchViewOnAdd, setsearchViewOnAdd] = useState({});
  const [redirect, setRedirect] = React.useState(0);
  const [useEffectValues, setUseEffectValues] = React.useState([]);
  const [valuesListDropdown, setvaluesListDropdown] = React.useState([]);
  const [openDialogEdit, setOpenDialogEdit] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [dataindex, setDataIndex] = React.useState(0);
  const [showVoids, setshowVoids] = React.useState(false);
  const [updateShowVoids, setupdateShowVoids] = React.useState(0);
  const [openErrorPopUp, setOpenErrorPopUp] = React.useState(false);
  const [uuid, setUUID] = React.useState("");
  const [MapSetSeqNo, setMapSetSeqNo] = React.useState("");
  const [selectedDateRetained, setSelectedDateRetained] = React.useState(null);
  const [selectedDate1Retained, setSelectedDate1Retained] = React.useState(
    null
  );
  const [beginDateChangeFlag, setBeginDateChangeFlag] = React.useState(false);
  const [endDateChangeFlag, setEndDateChangeFlag] = React.useState(false);
  const [rowmapDefDetailData, setRowMapDefDetailData] = React.useState([]);
  const [rowMasterDataDetails, setRowMasterDataDetails] = React.useState([]);

  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  const [tabValue, setTabValue] = React.useState(0);
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({});
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [noteaddFlag, setNoteAddFlag] = React.useState(false);
  const [editNoteData, setEditNoteData] = React.useState({});
  const onReset = () => dispatch(resetSearchMapSet());
  const onAddSuccess = rowClickValues =>
    dispatch(systemListActions(rowClickValues));
  systemLists = useSelector(
    state => state.appConfigState.mapSetState.systemLists
  );
  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));
  const dataElemDropDownDispatch = () =>
    dispatch(DataElementMapDropdownActions());
  const dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );
  const payloadEditData = Array.isArray(props.location.state?.payloadData) ? props.location.state?.payloadData[0] : props.location.state?.payloadData;
  const payloadMapDef = Array.isArray(payloadEditData) ?  payloadEditData[0]?.mapDefDetail : payloadEditData?.mapDefDetail;
  const [newData, setnewData] = React.useState(payloadMapDef || props.location.state?.payloadViewDataOnAdd[0].mapDefDetail || []);
  const [searchData, setSearchData] = React.useState(payloadEditData || props.location.state?.payloadViewDataOnAdd[0] || {});
  let functionalCode = dropdown?.listObj["Reference#1017"] && dropdown?.listObj["Reference#1017"].find(value => value.code === dataElement?.functionalArea);
    
  const renderSwitch = eombType => {
    switch (eombType) {
      case "Range":
        return (
          <React.Fragment>
            <div className="mui-custom-form input-md">
              <TextField
                id="value1"
                label="From"
                InputProps={{
                  readOnly: !userInquiryPrivileges || rowData.voidDate != null,
                  className: !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : rowData.voidDate != null
                    ? "Mui-disabled"
                    : ""
                }}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 30, "data-testid":"test_range_from" }}
                helperText={
                  showFromError
                    ? MapSetConstants.ADD_FROM_VALUE
                    : showFromThruError
                    ? MapSetConstants.FROM_THRU_VALUE
                    : null
                }
                error={
                  showFromError
                    ? MapSetConstants.ADD_FROM_VALUE
                    : showFromThruError
                    ? MapSetConstants.FROM_THRU_VALUE
                    : null
                }
                value={dataElement.value1}
                onChange={handleChangeDataElement("value1")}
                onInput={e => {
                  e.target.value = e.target.value.replace(
                    /[!@#$%^&*()_+=[\]{};':"\\|,<>/?~.`\- ]/,
                    ""
                  );
                }}
              />
            </div>
            <div className="mui-custom-form input-md">
              <TextField
                id="value2"
                label="Thru"
                disabled={
                  !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : rowData.voidDate != null
                }
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{ maxLength: 30, "data-testid":"test_range_thru" }}
                helperText={
                  showThruError ? MapSetConstants.ADD_THRU_VALUE : null
                }
                error={showThruError ? MapSetConstants.ADD_THRU_VALUE : null}
                value={dataElement.value2}
                onChange={handleChangeDataElement("value2")}
                onInput={e => {
                  e.target.value = e.target.value.replace(
                    /[!@#$%^&*()_+=[\]{};':"\\|,<>/?~.`\- ]/,
                    ""
                  );
                }}
              />
            </div>
          </React.Fragment>
        );
      case "List":
        return (
          <React.Fragment>        
              <NativeDropDown
                id="functional-area"
                label="Functional Area"
                isRequired                
                value={functionalCode!== undefined ? dataElement.functionalArea : editAddDefinitionData ? dataElement.functionalArea : ''}
                disabled={
                  !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : rowData.voidDate != null
                }
                onChange={handleChangeDataElement("functionalArea")}
                InputLabelProps={{
                  shrink: true
                }}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                errTxt={
                  showFunctionalError
                    ? MapSetConstants.ADD_FUNCTIONAL_AREA
                    : null
                }
                dropdownList={functionalAreaReference
                  ? functionalAreaReference.map((option, index) => (
                      <option value={option.code}>
                        {option.description}
                      </option>
                    ))
                  : null}
              />

              <NativeDropDown
                id="value"
                label="Value"
                isRequired
                value={dataElement.value}
                onChange={handleChangeDataElement("value")}
                InputLabelProps={{
                  shrink: true
                }}
                disabled={
                  !userInquiryPrivileges
                    ? !userInquiryPrivileges
                    : rowData.voidDate != null
                }
                errTxt={
                  showValueError ? MapSetConstants.ADD_VALUE_ERROR : null
                }
                SelectProps={{
                  MenuProps: {
                    className: classes.menu
                  }
                }}
                dropdownList={valuesListDropdown
                  ? valuesListDropdown.map(option => (
                      <option value={option}>{option}</option>
                    ))
                  : null}
              />
          </React.Fragment>
        );
      default:
        return null;
    }
  };
  // radio button
 
  

  useEffect(() => {
    addOutputResponse = null;
    updateOutputResponse = null;
    mapDefinitionDataArray = null;
    payloadViewDataOnAdd = null;
    dropDownDispatch(dropdownCriteria);
    dataElemDropDownDispatch();
    dispatch(resetAddMapSet());
    onDropdowns([
      Dropdowns.LOB_CODE,
      Dropdowns.MAP_TYP_CODE,
      Dropdowns.NOTES_USG_CODE,
      Dropdowns.R_FUNC_AREA_CD,
    ]);
  }, []);

  
  const datElemDropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.dataElementMapDropdown
  );

  const onMasterSaveMapDefinition = inputValues =>
    dispatch(mapDefinitionAdd(inputValues));
  addOutputResponse = useSelector(
    state => state.appConfigState.mapSetState.addResponse
  );

  const onMasterUpdateMapDefinition = searchData =>
    dispatch(mapDefinitionUpdate(searchData));
  updateOutputResponse = useSelector(
    state => state.appConfigState.mapSetState.updateResponse
  );

  const onMapDefinitionAddSuccess = searchViewOnAdd =>
    dispatch(mapSetActionView(searchViewOnAdd));
  payloadViewDataOnAdd = useSelector(
    state => state.appConfigState.mapSetState.mapSetView
  );
  const userInquiryPrivileges = !global.globalIsReadOnly();

  useEffect(() => {
    if (redirect === 1) {
      setSpinnerLoader(false);
      if (
        payloadViewDataOnAdd &&
        payloadViewDataOnAdd.length === 1 &&
        props.history.pathname !== "/MapSetEdit"
      ) {
        payloadViewDataOnAdd[0].mapDefDetail.map(var1 => {
          var1.mapDefData.map(var2 => {
            functionalAreaReference.map(fDesc => {
              if (var2.functionalArea === fDesc.code) {
                var2.functionalAreaDesc = fDesc.description;
              }
            });
          });
        });
        payloadViewDataOnAdd.AddSuccessMessage = AppConstants.SAVE_SUCCESS;
        props.history.push({
          pathname: "/MapSetEdit",
          state: { payloadViewDataOnAdd, values: ValuesFromMapSet, isFromAdd: true }
        });
      }
    } else if (payloadViewDataOnAdd && !payloadViewDataOnAdd.systemFailure) {
      setSpinnerLoader(false);
      loopData = [];
      const searchData = payloadViewDataOnAdd[0];
      if (searchData.noteSetVO) {
        const noteSetVO = searchData.noteSetVO;
        const notesTable = searchData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
      const newData = payloadViewDataOnAdd[0].mapDefDetail;
      if (newData && typeof newData === "object") {
        newData.map((newd, i) => {
          newd.mapDefData.map((newdchild, cindex) => {
            const data = {};
            if (cindex === 0) {
              data.beginDate = newd.beginDate;
              data.endDate = newd.endDate;
              data.voidDate = newd.voidDate;
            } else {
              data.beginDate = "";
              data.endDate = "";
              data.voidDate = newd.voidDate;
            }
            data.voidIndicator = newd.voidIndicator;
            data.exclude = newdchild.excludeIndicator;
            data.functionalArea = newdchild.functionalArea;
            const tempArea = newdchild.functionalArea;
            functionalAreaReference.map(fDesc => {
              if (tempArea === fDesc.code) {
                data.functionalAreaDesc = fDesc.description;
              }
            });
            data.mapSetSeq = newd.mapSetSeq;
            data.colSeq = newd.colSeq;
            data.dataElementCriteria = newdchild.dataElementCriteria;
            data.definitionData = newd.mapDefData;

            if (newdchild.type === "List") {
              data.value = newdchild.fromValue;
            } else {
              data.value = newdchild.fromValue + " to " + newdchild.thruValue;
            }
            loopData.push(data);
          });
        });
        setgridData(loopData);
        setmapDefinitionTableData(loopData);
        updateGridData(loopData);
      }
      setVersionNo(payloadViewDataOnAdd[0].versionNo);
    } else {
      setSpinnerLoader(false);
    }
  }, [payloadViewDataOnAdd]);
  
  const updateGridData = data => {
    let updatedShowVoidsData = [];
    if (showVoids) {
      updatedShowVoidsData = data;
      setgridData(updatedShowVoidsData);
    } else {
      updatedShowVoidsData = data.filter(tableobj => {
        if (tableobj.voidDate == null) {
          return tableobj;
        }
      });
      gridData.map((option, index) => {
        if (option.beginDate !== data) {
        }
      });
      setgridData(updatedShowVoidsData);
    }
  };
  useEffect(() => {
      let count = 0;
      gridData.map(val => {
        val.unique = count;
        count = count + 1;
      });
    setGridUpdatedTable(gridData);
  }, [gridData]);

  useEffect(() => {
    if (
      addOutputResponse &&
      !addOutputResponse.systemFailure &&
      addOutputResponse.respcode === "01"
    ) {
      successMessagesArray.push(MapSetConstants.ADD_SUCCESSFULLY);
      setSuccessMessage(successMessagesArray);
      setFocusCheck(false);
      onMapDefinitionAddSuccess(searchViewOnAdd);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    } else if (
      addOutputResponse &&
      !addOutputResponse.systemFailure &&
      addOutputResponse.respcode === "02"
    ) {
      errorMessagesArray.push(addOutputResponse.respdesc);
      seterrorMessages(errorMessagesArray);
      setSpinnerLoader(false);
    } else if (addOutputResponse && addOutputResponse.systemFailure) {
      errorMessagesArray.push(MapSetConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
      setSpinnerLoader(false);
    }
  }, [addOutputResponse]);
  useEffect(() => {
    if (
      updateOutputResponse &&
      !updateOutputResponse.systemFailure &&
      updateOutputResponse.respcode === "01"
    ) {
      successMessagesArray.push(MapSetConstants.ADD_SUCCESSFULLY);
      setshowEditScreen(true);
      setSuccessMessage(successMessagesArray);      
      setFocusCheck(false);
      const searchView = {
        lineOfBusiness: values.LOB ? values.LOB : null,
        mapType: values.mapType ? values.mapType : null,
        mapSetID: values.mapId ? values.mapId : null,
        mapDescription: values.description ? values.description : null,
        fieldValue: null,
        mapSetIDStartsOrContains: 1,
        mapDescStartsOrContains: 1,
        dataElementName: null
      };
      onMapDefinitionAddSuccess(searchViewOnAdd);
      setSpinnerLoader(false);
    } else if (
      updateOutputResponse &&
      !updateOutputResponse.systemFailure &&
      updateOutputResponse.respcode === "02"
    ) {
      errorMessagesArray.push(updateOutputResponse.respdesc);
      seterrorMessages(errorMessagesArray);
      setSpinnerLoader(false);
      setshowEditScreen(false);
    } else if (updateOutputResponse && updateOutputResponse.systemFailure) {
      errorMessagesArray.push(MapSetConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray);
      setSpinnerLoader(false);
      setshowEditScreen(false);
    }
  }, [updateOutputResponse]);

  useEffect(() => {
    if (Object.keys(searchData).length != 0) {
      setshowEditScreen(true);
    } else {
      setshowEditScreen(false);
    }
    setmapDefinitionDataArray(searchData.mapDefDetail);
    if (loopData.length > 0 || loopData.length === 0) {
      loopData.map(var1 => {
        functionalAreaReference.map(fDesc => {
          if (var1.functionalArea === fDesc.code) {
            var1.functionalAreaDesc = fDesc.description;
          }
        });
      });
      setmapDefinitionTableData(loopData);
    }
  }, []);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj["Reference#1022"]) {
        setMapTypeReference(dropdown.listObj["Reference#1022"]);
      }
      if (dropdown.listObj["Reference#1017"]) {
        setfunctionalAreaReference(dropdown.listObj["Reference#1017"]);
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
    }
  }, [dropdown]);
  // use effect for edit page .. on finding search data setting values to edit description
  useEffect(() => {
    if (searchData) {
      setValues({
        ...values,
        description: searchData.mapDesc
          ? searchData.mapDesc
          : searchData.mapDescription,
          mapLongDescription: searchData.mapLongDescription
          ? searchData.mapLongDescription
          : ''
      });
      if (searchData.noteSetVO) {
        const noteSetVO = searchData.noteSetVO;
        const notesTable = searchData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID
            ? noteSetVO.addedAuditUserID
            : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
            ? noteSetVO.addedAuditTimeStamp
            : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
  }, [searchData]);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID
          ? notesInput.addedAuditUserID
          : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp
          ? notesInput.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: "",
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: "",
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum
          ? notesInput.noteCexVersionNum
          : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber
          ? notesInput.saNoteSequenceNumber
          : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue
          ? notesInput.notesCexnoteTextValue
          : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  useEffect(() => {
    if (datElemDropdown && !datElemDropdown.systemFailure) {
      setdataElementReference(datElemDropdown);
    }
  }, [datElemDropdown]);

  useEffect(() => {
    onReset();
    loopData = [];
    if (
      props.location.state &&
      props.location.state.payloadViewDataOnAdd &&
      props.location.state.payloadViewDataOnAdd.AddSuccessMessage
    ) {
      setSuccessMessage([
        props.location.state.payloadViewDataOnAdd.AddSuccessMessage
      ]);
      setFocusCheck(false);
    }
    if (props.location.state && payloadEditData) {
      setValues({
        LOB: payloadEditData.lineOfBusiness
          ? payloadEditData.lineOfBusiness
          : DEFAULT_DD_VALUE,
        mapType: payloadEditData.mapType
          ? payloadEditData.mapType
          : DEFAULT_DD_VALUE,
        mapId: payloadEditData.mapID
          ? payloadEditData.mapID
          : "",
        description: payloadEditData.mapDescription
          ? payloadEditData.mapDescription
          : "",
          mapLongDescription: payloadEditData.mapLongDescription
          ? payloadEditData.mapLongDescription
          : ""
      });
    }
    if (newData && newData.length) {
      newData.map((newd, i) => {
        newd.mapDefData.map((newdchild, cindex) => {
          const data = {};
          if (cindex === 0) {
            data.beginDate = newd.beginDate;
            data.endDate = newd.endDate;
            data.voidDate = newd.voidDate;
          } else {
            data.beginDate = "";
            data.endDate = "";
            data.voidDate = newd.voidDate;
          }
          data.voidIndicator = newd.voidIndicator;
          data.exclude = newdchild.excludeIndicator;
          data.functionalArea = newdchild.functionalArea;
          const tempArea = newdchild.functionalArea;
          functionalAreaReference.map(fDesc => {
            if (tempArea === fDesc.code) {
              data.functionalAreaDesc = fDesc.description;
            }
          });

          data.dataElementCriteria = newdchild.dataElementCriteria;
          data.definitionData = newd.mapDefData;
          data.mapSetSeq = newd.mapSetSeq;
          data.colSeq = newd.colSeq;
          if (newdchild.type === "List") {
            data.value = newdchild.fromValue;
          } else {
            data.value = newdchild.fromValue + " to " + newdchild.thruValue;
          }
          loopData.push(data);
        });
      });
      setgridData(loopData);
      setmapDefinitionTableData(loopData);
      updateGridData(loopData);
    }
  }, [functionalAreaReference]);
  useEffect(() => {
    if (updateShowVoids === 1) {
      updateGridData(mapDefinitionTableData);
      setupdateShowVoids(0);
    }
  }, [showVoids]);
  useEffect(() => {
    if (
      definitionrowData.functionalArea !== dataElement.functionalArea ||
      (rowData.functionalArea &&
        rowData.functionalArea !== dataElement.functionalArea)
    ) {
      setDataElement({ ...dataElement, value: DEFAULT_DD_VALUE });
    } else {
      setDataElement({ ...dataElement, value: parseInt(dataElement.value) });
    }
    if (
      dataElement.functionalArea &&
      dataElement.functionalArea !== DEFAULT_DD_VALUE &&
      dataElement.dataElementCriteria &&
      dataElement.dataElementCriteria !== DEFAULT_DD_VALUE
    ) {
      const rowClickValues = {
        functionalArea: dataElement.functionalArea,
        dataElementName: dataElement.dataElementCriteria
      };
      onAddSuccess(rowClickValues);
    }
  }, [dataElement.functionalArea, dataElement.dataElementCriteria]);

  useEffect(() => {
    const valuesList = [];
    if (systemLists && systemLists.length > 0) {
      systemLists.reverse().map(list => {
        valuesList.push(list.listNumber);
      });
      setvaluesListDropdown(valuesList);
    }
    if (systemLists && systemLists.length === 0) {
      setvaluesListDropdown([]);
    }
  }, [systemLists]);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, gridData.length - page * rowsPerPage);

    const handleChange = name => event => {
      setFocusCheck(true);
      setAllowNavigation(true);
      if (name === "mapId" || name === "description") {
        setValues({ ...values, [name]: event.target.value.toUpperCase() });
      } else {
        setValues({ ...values, [name]: event.target.value });
      }
    };

  const handleChangeshowVoids = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setupdateShowVoids(1);
    setshowVoids(!showVoids);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleeditMapDefinitionVoid = () => {
    setFocusCheck(true);
    setAllowNavigation(true);
    seteditMapDefinitionVoid(!editMapDefinitionVoid);
  };
  const resetMapDefinitionData = () => {
    setFocusCheck(false);
    setAllowNavigation(false);
    setShowErrorText({
      showMapIDErrorText: "",
      showMapDescriptionErrorText: "",
      showMapLongDescriptionErrorText:""
    });
    setDataElement({
      dataElementCriteria: definitionrowData.dataElementCriteria,
      functionalArea: definitionrowData.functionalArea,
      value: definitionrowData.fromValue,
      exclude: definitionrowData.excludeIndicator,
      value1: definitionrowData.fromValue,
      value2: definitionrowData.thruValue,
      selectedOption: definitionrowData.type,
      definitionData: definitionrowData.definitionData
    });
    setEombtype(definitionrowData.type);
  };

  const handleCloseNewDialog = () => {
    setFocusCheck(false);
    setAllowNavigation(false);
    setOpenDialogEdit(false);
    setDataElement({
      id: 0,
      dataElementCriteria: "",
      functionalArea: DEFAULT_DD_VALUE,
      value: DEFAULT_DD_VALUE,
      exclude: false,
      value1: null,
      value2: null,
      selectedOption: "List"
    });
  };

  const saveMapDefinitionData = () => {
    let defDataEditCount = 0;
    let saveData = [];
    let functionalAreaDesc = null;
    errorMessagesArray = [];
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText("");
    if (validateDefinitionData()) {
      if (Object.keys(searchData).length !== 0) {
        saveData = newDialogData;
      } else {
        saveData = newDialogDataAdd;
      }
      saveData.map((mapDef1, i) => {
        if (
          mapDef1.dataElementCriteria == dataElement.dataElementCriteria &&
          ((mapDef1.type == "List" &&
            mapDef1.functionalArea == dataElement.functionalArea &&
            mapDef1.fromValue == dataElement.value) ||
            (mapDef1.type == "Range" &&
              mapDef1.fromValue == dataElement.value1 &&
              mapDef1.thruValue == dataElement.value2)) &&
          ((mapDef1.excludeIndicator == false &&
            (dataElement.exclude == "No" || !dataElement.exclude)) ||
            mapDef1.excludeIndicator == dataElement.exclude ||
            (mapDef1.excludeIndicator == true &&
              (dataElement.exclude == "Yes" || dataElement.exclude)) ||
            mapDef1.excludeIndicator == dataElement.exclude) &&
          i != dataindex
        ) {
          defDataEditCount = defDataEditCount + 1;
          return true;
        }
      });
      /* To fetch description on the basis of functional area code */
      functionalAreaDesc = functionalAreaReference.filter(
        data => data.code === dataElement.functionalArea
      );

      dataElement.functionalAreaDesc = functionalAreaDataDescriptionMap(
        dataElement.functionalArea
      );

      if (defDataEditCount === 0) {
        for (var i = 0; i <= saveData.length; i++) {
          if (i === dataindex) {
            let updatedDefinitionData = {};
            const functionAreaDescription = functionalAreaReference.filter(
              fDesc => {
                if (dataElement.functionalArea == fDesc.code) {
                  return fDesc.description;
                }
              }
            );
            updatedDefinitionData = {
              auditUserID: logInUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserID,
              addedAuditTimeStamp: getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: false,
              sortColumn: null,
              auditKeyList: [],
              auditKeyListFiltered: false,
              excludeIndicator:
                dataElement.exclude == "NO" || !dataElement.exclude
                  ? false
                  : !!(dataElement.exclude == "YES" || dataElement.exclude),
              dataElementCriteria: dataElement.dataElementCriteria,
              type: dataElement.selectedOption,
              functionalArea:
                dataElement.selectedOption == "List" &&
                dataElement.functionalArea &&
                dataElement.functionalArea != DEFAULT_DD_VALUE
                  ? dataElement.functionalArea
                  : null,
              value:
                dataElement.selectedOption === "List"
                  ? dataElement.value
                  : dataElement.value1 + " to " + dataElement.value2,
              fromValue: dataElement.value1
                ? dataElement.value1
                : dataElement.value,
              thruValue: dataElement.value2 ? dataElement.value2 : null,
              hiddenListNum: null,
              domain: dataElement.dataElementCriteria,
              functionalAreaDesc: dataElement.functionalArea
                ? functionAreaDescription && functionAreaDescription.length > 0
                  ? functionAreaDescription[0].description
                  : null
                : null,
              nullRecord: false,
              systemListDesc: null,
              colSeqNumber: 1,
              auditFilterList: [
                {
                  fieldName: null,
                  domainAttributeName: null,
                  displayInfo: 0,
                  duplicatePrimaryKey: false
                }
              ]
            };
            updatedDefinitionData.colSeqNumber = saveData[i].colSeqNumber;
            saveData[i] = updatedDefinitionData;
            break;
          }
        }
        setnewDialogDataAdd(saveData);
        setAllowNavigation(true);
      } else {
        errorMessagesArray.push(MapSetConstants.DEFINITION_DATA_OVERLAPPING);
        seterrorMessages(errorMessagesArray);
      }
      setOpenDialogEdit(false);
    }
  };

  const handleClickOpenEdit = row => event => {
    setAuditLogRow(row)
    setUUID(row.id);
    setMapSetSeqNo(row.mapSetSeq);
    if (open === true) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText("");
    const arrayTemp = row.definitionData;
    arrayTemp.map(tempvar =>
      functionalAreaReference.map(fDesc => {
        if (tempvar.functionalArea == fDesc.code) {
          tempvar.functionalAreaDesc = fDesc.description;
        }
      })
    );
    row.definitionData = arrayTemp;
    if (row.beginDate !== "") {
      setRowData({
        beginDate: row.beginDate,
        endDate: row.endDate,
        definitionData: row.definitionData,
        voidIndicator: !!(row.voidDate && row.voidDate != null),
        voidDate: row.voidDate
      });
      if (row.definitionData) {
        const defData = [];
        row.definitionData.map((option, index) => {
          if (option.type === "List") {
            option.value = option.fromValue;
          } else {
            option.value =
              option.fromValue && option.thruValue
                ? option.fromValue + " to " + option.thruValue
                : option.value;
          }
          defData.push(option);
        });
        setnewDialogData(defData);
        setnewDialogDataAdd(defData);
      } else {
        setnewDialogData(newDialogDataAdd);
        setnewDialogDataAdd([]);
      }
      if (row.voidIndicator) {
        seteditMapDefinitionVoid(row.voidIndicator);
      }
      if (row.voidDate && row.voidDate != null) {
        seteditMapDefinitionVoid(true);
      } else {
        seteditMapDefinitionVoid(false);
      }
      setSelectedDate(row.beginDate);
      setSelectedDate1(row.endDate);
      setSelectedDateRetained(row.beginDate);
      setSelectedDate1Retained(row.endDate);
      setshowEditResults(true);
      setOpen(true);

      setEombtype(row.selectedOption);
      setDataElement({
        beginDate: row.beginDate,
        endDate: row.endDate,
        format: ""
      });
    }
  };
  useEffect(() => {
    if (open === true) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [open]);
  const cancelEdit = () => {
    setFocusCheck(intialFocusCheck);
    setAllowNavigation(intialFocusCheck);
    setShowError(false);
    setShowErrorText({
      showMapIDErrorText: "",
      showMapDescriptionErrorText: "",
      showMapLongDescriptionErrorText:""
    });
    setmapDefinitionDataArray(mapDefinitionDataArray);
    setnewDialogData(
      rowData.definitionData && rowData.definitionData.length > 0
        ? rowData.definitionData
        : newDialogData
    );
    setSelectedDate(rowData.beginDate);
    setSelectedDate1(rowData.endDate);
    seteditMapDefinitionVoid(
      rowData.voidIndicator ? rowData.voidIndicator : editMapDefinitionVoid
    );
    setOpen(true);
  };

  const handleDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    var beginDateerrorText = "";
    const defaultEndDate = new Date("12-31-9999");
    var showBeginDateError = false;
    setSelectedDate(date);
    if (selectedDateRetained !== date) {
      setBeginDateChangeFlag(true);
    } else {
      setBeginDateChangeFlag(false);
    }
    setShowError({
      ...showEndDateError,
      showBeginDateError: showBeginDateError
    });
    setShowErrorText({
      ...endDateerrorText,
      beginDateerrorText: beginDateerrorText
    });
  };

  const handleDateChange1 = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setIntialFocusCheck(true);
    var endDateerrorText = "";
    var showEndDateError = false;
    setSelectedDate1(date);
    if (selectedDate1Retained !== date) {
      setEndDateChangeFlag(true);
    } else {
      setEndDateChangeFlag(false);
    }
    setShowError({
      ...showBeginDateError,
      showEndDateError: showEndDateError
    });
    setShowErrorText({
      ...beginDateerrorText,
      endDateerrorText: endDateerrorText
    });
  };
  const closeErrorPopUpDialog = () => {
    setOpenErrorPopUp(false);
  };
  const handleChangeDataElement = name => event => {
    if(event.target.value) {
      setEditAddDefinitionData(true);
    }else if(event.target.value && functionalCode === undefined){
      setEditAddDefinitionData(false);
    }
    setFocusCheck(true);
    setAllowNavigation(true);
    setDataElement({ ...dataElement, [name]: event.target.value });
    if (name == "selectedOption") {
      setEombtype(event.target.value);
      if (event.target.value === "Range") {
        setDataElement({
          ...dataElement,
          selectedOption: event.target.value,
          value: "",
          value1: "",
          value2: ""
        });
      }
      if (event.target.value === "List") {
        setDataElement({
          ...dataElement,
          selectedOption: event.target.value,
          value: DEFAULT_DD_VALUE,
          functionalArea: DEFAULT_DD_VALUE,
          value1: "",
          value2: ""
        });
      }
    }
    if (name === "value") {
      setDataElement({
        ...dataElement,
        value: event.target.value,
        value1: event.target.value
      });
    }
    if (name == "exclude") {
      setDataElement({ ...dataElement, exclude: !dataElement.exclude });
    }
  };

  const handleCloseAddDefinitionDataDialog = () => {
    setOpenDialog(false);
    setDataElement({
      dataElementCriteria: DEFAULT_DD_VALUE,
      functionalArea: DEFAULT_DD_VALUE,
      value: DEFAULT_DD_VALUE
    });
  };

  const handleClickOpenNewDialog = (row, index) => event => {
    errorMessagesArray = [];
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText("");
    setDataElement({
      dataElementCriteria: row.dataElementCriteria,
      functionalArea: row.functionalArea,
      value: row.fromValue,
      exclude: row.excludeIndicator,
      value1: row.fromValue,
      value2: row.thruValue,
      selectedOption: row.type,
      definitionData: row.definitionData
    });
    setdefinitionrowData(row);
    setDataIndex(index);
    setOpenDialogEdit(true);
    setOpenDialog(false);
    seteditMapDefinitionVoid(
      row.voidIndicator ? row.voidIndicator : editMapDefinitionVoid
    );
    setShowDialog(true);
    setEombtype(row.type);
  };

  const addMapDefinitionButton = values => {
    setSelectedDate(null);
    setSelectedDate1("12/31/9999");
    setnewDialogDataAdd([]);
    setEditAddDefinitionData(true);
    setShowLogDialogTable(false)
    setAuditLogRow(null)
    if (!showEditScreen) {
      validateHeaderFields();
    }

    if (errorMessagesArray.length == 0) {
      window.scrollTo(0, document.body.scrollHeight);
      seterrorMessages([]);
      setShowError(false);
      setshowMapDefinitionAddForm(true);
      setshowResults(false);
      setshowEditResults(false);
      setOpen(false);
    }
  };

  const addDefinitionDataButton = type => {
    setOpenDialogEdit(false);
    setAllowNavigation(true);
    setRowData([]);
    errorMessagesArray = [];
    seterrorMessages([]);
    setSuccessMessage([]);
    const defaultEndDate = new Date("12-31-9999");
    var EditForm = false;
    if (type == "edit") {
      EditForm = true;
    } else if (type == "add") {
      EditForm = false;
    }
    var beginDateerrorText = "";
    var endDateerrorText = "";
    var showBeginDateError;
    var showEndDateError;
    var showDateOverlappingError = false;

    // date validations
    if (!beginDate) {
      showBeginDateError = true;
      beginDateerrorText = MapSetConstants.BEGINDATE_REQUIRED;
      errorMessagesArray.push(MapSetConstants.BEGINDATE_REQUIRED);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    } else if (beginDate.toString() === "Invalid Date") {
      showBeginDateError = true;
      beginDateerrorText = MapSetConstants.BEGINDATE_ERROR;
      errorMessagesArray.push(MapSetConstants.BEGINDATE_ERROR);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    } else if (validateDateMinimumValue(getDateInMMDDYYYYFormat(beginDate))) {
      showBeginDateError = true;
      beginDateerrorText = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    }
    let tempEndDate = endDate;
    if (
      getDateInMMDDYYYYFormat(new Date(beginDate)) ===
        getDateInMMDDYYYYFormat(new Date()) &&
      endDate == null
    ) {
      setSelectedDate1(defaultEndDate);
      tempEndDate = defaultEndDate;
      checkBeginDate = true;
    }
    if (!tempEndDate && !checkBeginDate) {
      showEndDateError = true;
      endDateerrorText = MapSetConstants.ENDDATE_REQUIRED;
      errorMessagesArray.push(MapSetConstants.ENDDATE_REQUIRED);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    } else if (tempEndDate.toString() === "Invalid Date") {
      showEndDateError = true;
      endDateerrorText = MapSetConstants.ENDDATE_ERROR;
      errorMessagesArray.push(MapSetConstants.ENDDATE_ERROR);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    } else if (validateDateMinimumValue(getDateInMMDDYYYYFormat(tempEndDate))) {
      showEndDateError = true;
      endDateerrorText = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    }
    if (
      beginDate &&
      endDate &&
      !showBeginDateError &&
      !showEndDateError &&
      compareTwoDatesGreaterThanOrEqual(new Date(endDate), new Date(beginDate))
    ) {
      showBeginDateError = true;
      beginDateerrorText = MapSetConstants.BEGINDATE_ENDDATE_MATCH;
      errorMessagesArray.push(MapSetConstants.BEGINDATE_ENDDATE_MATCH);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    }

    if (errorMessagesArray.length == 0) {
      if (type == "edit") {
        setEditAddDefinitionData(true);
      } else if (type == "add") {
        setEditAddDefinitionData(false);
      }
      setShowError(false);

      if (open && showEditResults) {
        setshowEditField(true);
      } else {
        setshowEditField(false);
      }

      setEombtype("List");
      setOpenDialog(true);
      setDataElement({
        dataElementCriteria: DEFAULT_DD_VALUE,
        functionalArea: DEFAULT_DD_VALUE,
        value: DEFAULT_DD_VALUE,
        exclude: false,
        selectedOption: "List"
      });
    }
    setShowError({
      showBeginDateError: showBeginDateError,
      showEndDateError: showEndDateError,
      showDateOverlappingError: showDateOverlappingError
    });
    setShowErrorText({
      beginDateerrorText: beginDateerrorText,
      endDateerrorText: endDateerrorText
    });
  };
  // Validate Map Definition Data for Add and Update functionality
  const validateDefinitionData = () => {
    var showDataElementCriteriaError;
    var showFunctionalError;
    var showValueError;
    var showFromError = false;
    var showThruError = false;
    var showFromThruError = false;
    var tempErrorMessagesArray = [];
    if (
      dataElement.selectedOption == "List" &&
      (!dataElement.value || dataElement.value == DEFAULT_DD_VALUE)
    ) {
      showValueError = true;
      tempErrorMessagesArray.push(MapSetConstants.ADD_VALUE_ERROR);
    }
    if (
      dataElement.selectedOption == "List" &&
      (!dataElement.functionalArea ||
        dataElement.functionalArea == DEFAULT_DD_VALUE)
    ) {
      showFunctionalError = true;
      tempErrorMessagesArray.push(MapSetConstants.ADD_FUNCTIONAL_AREA);
    }
    if (
      !dataElement.dataElementCriteria ||
      dataElement.dataElementCriteria == DEFAULT_DD_VALUE
    ) {
      showDataElementCriteriaError = true;
      tempErrorMessagesArray.push(MapSetConstants.ADD_DATAELEMENT_CRITERIA);
    }
    if (dataElement.selectedOption == "Range" && !dataElement.value1) {
      showFromError = true;
      tempErrorMessagesArray.push(MapSetConstants.ADD_FROM_VALUE);
    }
    if (dataElement.selectedOption == "Range" && !dataElement.value2) {
      showThruError = true;
      tempErrorMessagesArray.push(MapSetConstants.ADD_THRU_VALUE);
    }
    if (!showFromError && !showThruError) {
      const value1 = dataElement.value1;
      if (
        dataElement.value1 &&
        dataElement.value2 &&
        dataElement.selectedOption == "Range" &&
        !typeof parseFloat(dataElement.value1) === "number" &&
        !typeof parseFloat(dataElement.value2) === "number" &&
        isNaN(parseFloat(dataElement.value1)) &&
        isNaN(parseFloat(dataElement.value2)) &&
        dataElement.value1.localeCompare(dataElement.value2) === 1
      ) {
        showFromThruError = true;
        tempErrorMessagesArray.push(MapSetConstants.FROM_THRU_VALUE);
      }
      if (
        typeof parseFloat(dataElement.value1) === "number" &&
        typeof parseFloat(dataElement.value2) === "number" &&
        !isNaN(parseFloat(dataElement.value1)) &&
        !isNaN(parseFloat(dataElement.value2)) &&
        dataElement.selectedOption == "Range"
      ) {
        if (Number(dataElement.value1) > Number(dataElement.value2)) {
          showFromThruError = true;
          tempErrorMessagesArray.push(MapSetConstants.FROM_THRU_VALUE);
        }
      }
    }   
    setShowError({
      showDataElementCriteriaError: showDataElementCriteriaError,
      showFunctionalError: showFunctionalError,
      showValueError: showValueError,
      showFromError: showFromError,
      showThruError: showThruError,
      showFromThruError: showFromThruError
    });
    seterrorMessages(tempErrorMessagesArray);
    if (
      !showDataElementCriteriaError &&
      !showFunctionalError &&
      !showValueError &&
      !showFromError &&
      !showThruError &&
      !showFromThruError
    ) {
      return true;
    } else {
      return false;
    }
  };

  const functionalAreaDataDescriptionMap = functionalArea => {
    const filteredValue = functionalAreaReference.filter(
      (fnArea, index) => fnArea.code === functionalArea
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return functionalArea;
  };

  const addDefinitionDataToList = dataElement => {
    errorMessagesArray = [];
    seterrorMessages([]);
    setAllowNavigation(true);
    setShowError(false);
    setShowErrorText("");
    let functionalAreaDesc = null;
    if (validateDefinitionData()) {
      if (dataElement.value1 && dataElement.value2) {
        dataElement.value = dataElement.value1 + " to " + dataElement.value2;
      }
      newDialogData1 =
        Object.keys(newDialogDataAdd).length == 0 ? [] : newDialogDataAdd;
      if (newDialogData1.length < 15) {
        let defDataCount = 0;
        functionalAreaReference.filter(mapDef1 => {
          if (
            (mapDef1.dataElementCriteria == dataElement.dataElementCriteria &&
              mapDef1.type == "List" &&
              mapDef1.functionalArea == dataElement.functionalArea &&
              mapDef1.fromValue == dataElement.value) ||
            (mapDef1.type == "Range" &&
              mapDef1.fromValue == dataElement.value1 &&
              mapDef1.thruValue == dataElement.value2 &&
              mapDef1.excludeIndicator == false &&
              (dataElement.exclude == "No" || !dataElement.exclude)) ||
            (mapDef1.excludeIndicator == true &&
              (dataElement.exclude || dataElement.exclude == "Yes"))
          ) {
            defDataCount = defDataCount + 1;
            return true;
          }
        });

        /* To fetch description on the basis of functional area code */
        functionalAreaDesc = functionalAreaReference.filter(
          data => data.code === dataElement.functionalArea
        );

        dataElement.functionalAreaDesc = functionalAreaDataDescriptionMap(
          dataElement.functionalArea
        );

        newDialogData1.map(mapDef1 => {
          if (
            (mapDef1.dataElementCriteria == dataElement.dataElementCriteria &&
              mapDef1.type == "List" &&
              mapDef1.functionalArea == dataElement.functionalArea &&
              mapDef1.fromValue == dataElement.value) ||
            (mapDef1.type == "Range" &&
              mapDef1.fromValue == dataElement.value1 &&
              mapDef1.thruValue == dataElement.value2 &&
              mapDef1.excludeIndicator == false &&
              (dataElement.exclude == "No" || !dataElement.exclude)) ||
            (mapDef1.excludeIndicator == true &&
              (dataElement.exclude || dataElement.exclude == "Yes"))
          ) {
            defDataCount = defDataCount + 1;
            return true;
          }
        });
        if (defDataCount == 0) {
          const functionAreaDescription = functionalAreaReference.filter(
            fDesc => {
              if (dataElement.functionalArea == fDesc.code) {
                return fDesc.description;
              }
            }
          );
          defDataObj = {};
          defDataObj = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: false,
            sortColumn: null,
            auditKeyList: [],
            auditKeyListFiltered: false,
            excludeIndicator:
              dataElement.exclude == "NO" || !dataElement.exclude
                ? false
                : !!(dataElement.exclude == "YES" || dataElement.exclude),
            dataElementCriteria: dataElement.dataElementCriteria,
            type: dataElement.selectedOption,
            functionalArea:
              dataElement.selectedOption == "List" &&
              dataElement.functionalArea &&
              dataElement.functionalArea != DEFAULT_DD_VALUE
                ? dataElement.functionalArea
                : null,
            fromValue: dataElement.value1
              ? dataElement.value1
              : dataElement.value,
            thruValue: dataElement.value2 ? dataElement.value2 : null,
            value: dataElement.value,
            hiddenListNum: null,
            domain: dataElement.dataElementCriteria,
            functionalAreaDesc: dataElement.functionalArea
              ? functionAreaDescription && functionAreaDescription.length > 0
                ? functionAreaDescription[0].description
                : null
              : null,
            nullRecord: false,
            systemListDesc: null,
            colSeqNumber: 1,
            auditFilterList: [
              {
                fieldName: null,
                domainAttributeName: null,
                displayInfo: 0,
                duplicatePrimaryKey: false
              }
            ],
            id: generateUUID()
          };
          if (newDialogData1.length === 0) {
            defDataObj.colSeqNumber = 1;
          } else {
            let seq = newDialogData1.length;
            defDataObj.colSeqNumber = defDataObj.colSeqNumber + seq;
          }
          newDialogData1.push(defDataObj);
          setnewDialogDataAdd(newDialogData1);
          setnewDialogData(newDialogData1);
          setAllowNavigation(true);
        } else {
          errorMessagesArray.push(MapSetConstants.DEFINITION_DATA_OVERLAPPING);
          seterrorMessages(errorMessagesArray);
        }
      } else {
        errorMessagesArray.push(MapSetConstants.DEFINITIONS_LENGTH_ERROR);
        seterrorMessages(errorMessagesArray);
      }

      setOpenDialog(false);
    }
  };
  function checkDateFormat(date) {
    date = new Date(date);
    var updatedDate = new Date(date);
    if (
      date.getMonth() + 1 < 10 &&
      date.getMonth() + 1 > 0 &&
      date.getDate() < 10 &&
      date.getDate() > 0
    ) {
      updatedDate =
        "0" +
        (date.getMonth() + 1) +
        "/0" +
        date.getDate() +
        "/" +
        date.getFullYear();
    } else if (date.getMonth() + 1 < 10 && date.getMonth() + 1 > 0) {
      updatedDate =
        "0" +
        (date.getMonth() + 1) +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear();
    } else if (date.getDate() < 10 && date.getDate() > 0) {
      updatedDate =
        date.getMonth() + 1 + "/0" + date.getDate() + "/" + date.getFullYear();
    } else {
      updatedDate =
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear();
    }
    return updatedDate;
  }
  function onConfirm(masterSaveDefData) {
    let temp = masterSaveDefData;
    if (temp === null) {
      temp = masterSaveDefDataType;
    }
    let newArrayObj = {};
    var updatedBeginDate = new Date(null);
    var updatedEndDate = new Date(null);

    const defaultEndDate = new Date("12-31-9999");
    setSelectedDate(new Date(beginDate));
    if (checkBeginDate) {
      setSelectedDate1(defaultEndDate);
    } else {
      setSelectedDate1(endDate);
    }
    updatedBeginDate = checkDateFormat(beginDate);
    if (checkBeginDate) {
      updatedEndDate = checkDateFormat(defaultEndDate);
    } else {
      updatedEndDate = checkDateFormat(endDate);
    }
    setSelectedDate(new Date(updatedBeginDate));
    setSelectedDate1(updatedEndDate);
    var currentDate = new Date();
    var dd = currentDate.getDate();
    var mm = currentDate.getMonth() + 1;

    var yyyy = currentDate.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    currentDate = yyyy + "-" + mm + "-" + dd;
    if (temp === "add") {
      setnewDialogData([]);
      if (
        beginDate != null &&
        ((checkBeginDate && defaultEndDate != null) || endDate != null) &&
        newDialogDataAdd &&
        newDialogDataAdd.length > 0
      ) {
        let mapDefinitionDataObj = {};
        const uniqueuuid = generateUUID();
        let seqnum = 1;
        mapDefinitionDataArray =
          mapDefinitionDataArrayState ||
          (searchData && searchData.mapDefDetail
            ? searchData.mapDefDetail
            : []);
        if (mapDefinitionDataArray === []) {
          seqnum = 1;
        } else {
          if (mapDefinitionDataArray && mapDefinitionDataArray.length > 0) {
            if (
              mapDefinitionDataArray[mapDefinitionDataArray.length - 1] &&
              mapDefinitionDataArray[mapDefinitionDataArray.length - 1]
                .mapSetSeq
            ) {
              seqnum =
                mapDefinitionDataArray[mapDefinitionDataArray.length - 1]
                  .mapSetSeq;
            }
            seqnum = seqnum + 1;
          }
        }
        mapDefinitionDataObj = {
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: logInUserID,
          addedAuditTimeStamp: getUTCTimeStamp(),
          versionNo: 0,
          dbRecord: false,
          sortColumn: null,
          auditKeyList: [],
          auditKeyListFiltered: false,
          beginDate: updatedBeginDate || beginDate.toLocaleDateString(),
          endDate: updatedEndDate || endDate.toLocaleDateString(),
          voidIndicator: editMapDefinitionVoid,
          voidStatus: "1",
          mapDefData: newDialogDataAdd || [],
          benefitPlan: null,
          voidDate: editMapDefinitionVoid == true ? currentDate : null,
          voidMapStatus: false,
          mapSetSeq: seqnum,
          colSeq: 0,
          id: uniqueuuid
        };
        let pushCount = 0;
        if (mapDefinitionDataArray.length > 0) {
          mapDefinitionDataArray.map((option, inde) => {
            if (option.mapSetSeq !== mapDefinitionDataObj.mapSetSeq) {
              if (option.beginDate === mapDefinitionDataObj.beginDate) {
                pushCount = 1;
              }
            }
          });
          if (pushCount === 1) {
            errorMessagesArray.push(MapSetConstants.BEGIN_DATE_OVERLAPPING);
          } else {
            mapDefinitionDataArray.push(mapDefinitionDataObj);
          }
        } else {
          mapDefinitionDataArray.push(mapDefinitionDataObj);
        }

        setmapDefinitionDataArray(mapDefinitionDataArray);
        const mapDefinitionTableDataTemp = [];
        mapDefinitionDataArray.map((mapDefState, i) => {
          mapDefState.mapDefData.map((definitionData, j) => {
            newArrayObj = {};
            if (j == 0) {
              newArrayObj.beginDate = mapDefState.beginDate
                ? mapDefState.beginDate
                : updatedBeginDate;
              newArrayObj.endDate = mapDefState.endDate
                ? mapDefState.endDate
                : updatedEndDate;
              newArrayObj.id = mapDefState.id;
              newArrayObj.mapSetSeq = mapDefState.mapSetSeq;
            } else {
              newArrayObj.beginDate = "";
              newArrayObj.endDate = "";
              newArrayObj.isChild = true;
            }
            newArrayObj.exclude =
              definitionData.excludeIndicator == false
                ? "No"
                : definitionData.excludeIndicator == true
                ? "Yes"
                : "No";
            newArrayObj.dataElementCriteria =
              definitionData.dataElementCriteria;
            newArrayObj.functionalArea = definitionData.functionalArea;
            functionalAreaReference.map(fDesc => {
              if (definitionData.functionalArea == fDesc.code) {
                newArrayObj.functionalAreaDesc = fDesc.description;
              }
            });

            newArrayObj.value =
              definitionData.fromValue &&
              definitionData.thruValue &&
              definitionData.type === "Range"
                ? definitionData.fromValue + " to " + definitionData.thruValue
                : definitionData.value
                ? definitionData.value
                : definitionData.fromValue;
            if (mapDefState.voidDate) {
              newArrayObj.voidDate = mapDefState.voidDate;
            } else {
              newArrayObj.voidDate =
                editMapDefinitionVoid == true ? currentDate : null;
            }
            newArrayObj.definitionData = mapDefState.mapDefData;
            mapDefinitionTableDataTemp.push(newArrayObj);
          });
        });
        setmapDefinitionTableData(mapDefinitionTableDataTemp);
        updateGridData(mapDefinitionTableDataTemp);
        resetDefinitionData(true);
        setshowMapDefinitionAddForm(false);
        setshowEditResults(false);
      } else if (
        beginDate != null &&
        ((checkBeginDate && defaultEndDate != null) || endDate != null) &&
        newDialogDataAdd &&
        newDialogDataAdd.length == 0
      ) {
        errorMessagesArray.push(MapSetConstants.DEFINITION_DATA_LENGTH);
        seterrorMessages(errorMessagesArray);
      }
    } else if (temp === "edit") {
      let updatedMapDefArray = [];
      let dataToUpdate = [];
      let pushCount = 0;
      dataToUpdate =
        mapDefinitionDataArrayState && mapDefinitionDataArrayState.length > 0
          ? mapDefinitionDataArrayState
          : searchData.mapDefDetail
          ? searchData.mapDefDetail
          : [];
      if (
        beginDate != null &&
        ((checkBeginDate && defaultEndDate != null) || endDate != null) &&
        dataToUpdate &&
        dataToUpdate.length > 0
      ) {
        dataToUpdate.map(mapDef => {
          if (mapDef.id !== uuid) {
            if (mapDef.beginDate === moment(beginDate).format("L")) {
              pushCount = 1;
            }
          }
        });
        dataToUpdate.map(mapDef => {
          if (mapDef.id && mapDef.id === uuid && pushCount === 0) {
            mapDef.beginDate =
              updatedBeginDate || beginDate.toLocaleDateString();
            mapDef.endDate = updatedEndDate || endDate.toLocaleDateString();
            mapDef.voidIndicator = editMapDefinitionVoid;
            mapDef.mapDefData =
              Object.keys(dataToUpdate).length != 0 ? newDialogData : [];
            mapDef.voidDate =
              editMapDefinitionVoid == true ? currentDate : null;
            mapDef.auditTimeStamp = getUTCTimeStamp();
            mapDef.auditUserID = logInUserID;
            mapDef.mapDefData.map(data => {
              data.auditTimeStamp = getUTCTimeStamp();
              data.auditUserID = logInUserID;
            });
          } else if (mapDef.mapSetSeq === MapSetSeqNo && pushCount === 0) {
            mapDef.beginDate =
              updatedBeginDate || beginDate.toLocaleDateString();
            mapDef.endDate = updatedEndDate || endDate.toLocaleDateString();
            mapDef.voidIndicator = editMapDefinitionVoid;
            mapDef.mapDefData =
              Object.keys(dataToUpdate).length != 0
                ? newDialogData || newDialogDataAdd
                : [];
            mapDef.voidDate =
              editMapDefinitionVoid == true ? currentDate : null;
            mapDef.auditTimeStamp = getUTCTimeStamp();
            mapDef.auditUserID = logInUserID;
            mapDef.mapDefData.map(data => {
              data.auditTimeStamp = getUTCTimeStamp();
              data.auditUserID = logInUserID;
            });
          }
        });
        if (pushCount === 1) {
          errorMessagesArray.push(MapSetConstants.BEGIN_DATE_OVERLAPPING);
        }
        setnewDialogData([]);
        updatedMapDefArray = dataToUpdate;
        setmapDefinitionDataArray(updatedMapDefArray);
        const updatedMapDefTableData = [];
        if (
          endDateChangeFlag &&
          selectedDate1Retained <= endDate &&
          (values.mapType === "MCT-Member Cnt" || values.mapType === "MCT")
        ) {
          errorMessagesArray.push(MapSetConstants.MCT_BEGIN_END_DATE_FIX);
        } else {
          updatedMapDefArray.map((mapDefState, i) => {
            mapDefState.mapDefData.map((definitionData, i) => {
              newArrayObj = {};
              if (i === 0) {
                newArrayObj.beginDate = mapDefState.beginDate;
                newArrayObj.endDate = mapDefState.endDate;
                newArrayObj.mapSetSeq = mapDefState.mapSetSeq;
              } else {
                newArrayObj.beginDate = "";
                newArrayObj.endDate = "";
              }
              newArrayObj.exclude =
                definitionData.excludeIndicator == false
                  ? "No"
                  : definitionData.excludeIndicator == true
                  ? "Yes"
                  : "No";
              newArrayObj.dataElementCriteria =
                definitionData.dataElementCriteria;
              newArrayObj.functionalArea = definitionData.functionalArea;
              functionalAreaReference.map(fDesc => {
                if (definitionData.functionalArea == fDesc.code) {
                  newArrayObj.functionalAreaDesc = fDesc.description;
                }
              });
              newArrayObj.value =
                definitionData.fromValue &&
                definitionData.thruValue &&
                definitionData.type === "Range"
                  ? definitionData.fromValue + " to " + definitionData.thruValue
                  : definitionData.fromValue;
              newArrayObj.voidDate = mapDefState.voidDate;
              newArrayObj.definitionData = mapDefState.mapDefData;
              newArrayObj.id = mapDefState.id;
              updatedMapDefTableData.push(newArrayObj);
            });
          });
          setmapDefinitionTableData(updatedMapDefTableData);
          updateGridData(updatedMapDefTableData);
        }
        setshowEditResults(false);
      } else if (
        beginDate != null &&
        ((checkBeginDate && defaultEndDate != null) || endDate != null) &&
        dataToUpdate &&
        dataToUpdate.length === 0
      ) {
        errorMessagesArray.push(MapSetConstants.DEFINITION_DATA_LENGTH);
        seterrorMessages(errorMessagesArray);
      }
    }
    setOpenErrorPopUp(false);
  }

  const masterAddDefinitionData = masterSaveDefData => {
    setEndDateChangeFlag(false);
    setMasterSaveDefDataType(masterSaveDefData);
    errorMessagesArray = [];
    seterrorMessages([]);
    setSuccessMessage([]);

    const defaultEndDate = new Date("12-31-9999");
    var EditForm = false;
    if (masterSaveDefDataType == "edit") {
      EditForm = true;
    } else if (masterSaveDefDataType == "add") {
      EditForm = false;
    }
    var beginDateerrorText = "";
    var endDateerrorText = "";
    var showBeginDateError;
    var showEndDateError;
    var showDateOverlappingError = false;
    // date validations for master Add/Update
    if (!beginDate) {
      showBeginDateError = true;
      beginDateerrorText = MapSetConstants.BEGINDATE_REQUIRED;
      errorMessagesArray.push(MapSetConstants.BEGINDATE_REQUIRED);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    } else if (beginDate.toString() === "Invalid Date") {
      showBeginDateError = true;
      beginDateerrorText = MapSetConstants.BEGINDATE_ERROR;
      errorMessagesArray.push(MapSetConstants.BEGINDATE_ERROR);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    } else if (validateDateMinimumValue(getDateInMMDDYYYYFormat(beginDate))) {
      showBeginDateError = true;
      beginDateerrorText = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    }
    let tempEndDate = endDate;
    if (
      getDateInMMDDYYYYFormat(new Date(beginDate)) ===
        getDateInMMDDYYYYFormat(new Date()) &&
      endDate == null
    ) {
      setSelectedDate1(defaultEndDate);
      tempEndDate = defaultEndDate;
      checkBeginDate = true;
    }
    if (!tempEndDate && !checkBeginDate) {
      showEndDateError = true;
      endDateerrorText = MapSetConstants.ENDDATE_REQUIRED;
      errorMessagesArray.push(MapSetConstants.ENDDATE_REQUIRED);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    } else if (tempEndDate.toString() === "Invalid Date") {
      showEndDateError = true;
      endDateerrorText = MapSetConstants.ENDDATE_ERROR;
      errorMessagesArray.push(MapSetConstants.ENDDATE_ERROR);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    } else if (validateDateMinimumValue(getDateInMMDDYYYYFormat(tempEndDate))) {
      showEndDateError = true;
      endDateerrorText = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    }
    if (
      beginDate &&
      endDate &&
      !showBeginDateError &&
      !showEndDateError &&
      compareTwoDatesGreaterThanOrEqual(new Date(endDate), new Date(beginDate))
    ) {
      showBeginDateError = true;
      beginDateerrorText = MapSetConstants.BEGINDATE_ENDDATE_MATCH;
      errorMessagesArray.push(MapSetConstants.BEGINDATE_ENDDATE_MATCH);
      seterrorMessages(errorMessagesArray);
      setshowEditResults(EditForm);
      setOpen(EditForm);
    }
    // -------------------------

    if (errorMessagesArray.length === 0) {
      const newArrayObj = {};
      var updatedBeginDate = new Date(null);
      var updatedEndDate = new Date(null);
      var updatedDate = new Date(null);
      setShowError(false);

      if (
        checkBeginDate ||
        (defaultEndDate !== null &&
          endDate !== null &&
          !compareTwoDatesGreaterThanOrEqual(new Date(), new Date(endDate)))
      ) {
        setOpenErrorPopUp(true);
      } else {
        onConfirm(masterSaveDefData);
      }
    }
    setShowError({
      showBeginDateError: showBeginDateError,
      showEndDateError: showEndDateError,
      showDateOverlappingError: showDateOverlappingError
    });
    setShowErrorText({
      beginDateerrorText: beginDateerrorText,
      endDateerrorText: endDateerrorText
    });
    seterrorMessages(errorMessagesArray);
  };

  const checkEdit = () => {
    if (endDateChangeFlag && beginDateChangeFlag) {
      masterAddDefinitionData("add");
    } else {
      masterAddDefinitionData("edit");
    }
  };
  const masterSave = () => {
    if (!allowNavigation && showEditScreen) {
      NoSaveMessage();
    } else {
      errorMessagesArray = [];
      seterrorMessages([]);
      setSuccessMessage([]);
      if (!showEditScreen) {
        validateHeaderFields();
        if (errorMessagesArray.length === 0) {
          setShowError(false);
          const setInputValues = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: false,
            sortColumn: null,
            auditKeyList: [],
            auditKeyListFiltered: false,
            lineOfBusiness: AppConstants.DEFAULT_LOB_VALUE,
            mapType: values.mapType,
            mapID: values.mapId,
            mapDescription: values.description,
            mapLongDescription: values.mapLongDescription,
            mapDefDetail:
              mapDefinitionDataArrayState &&
              Object.keys(mapDefinitionDataArrayState).length > 0
                ? mapDefinitionDataArrayState
                : [],
            mapDefDefinition: [
              {
                auditUserID: logInUserID,
                auditTimeStamp: getUTCTimeStamp(),
                addedAuditUserID: logInUserID,
                addedAuditTimeStamp: getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: false,
                sortColumn: null,
                auditKeyList: [],
                auditKeyListFiltered: false,
                colSeqNumber: 1,
                dataElementCriteriaList: [],
                currentDepth: 2
              }
            ],
            noteSetVO: notesInput
          };
          const searchView = {
            lineOfBusiness: [AppConstants.DEFAULT_LOB_VALUE],
            mapType: values.mapType ? values.mapType : null,
            mapSetID: values.mapId ? values.mapId : null,
            mapDescription: values.description ? values.description : null,
            mapLongDescription:values.mapLongDescription ?values.mapLongDescription :null,
            fieldValue: null,
            mapSetIDStartsOrContains: 1,
            mapDescStartsOrContains: 1,
            dataElementName: null
          };
          setsearchViewOnAdd(searchView);
          setSpinnerLoader(true);
          onMasterSaveMapDefinition(setInputValues);
          setAllowNavigation(false);
        }
      } else if (showEditScreen) {
        if (allowNavigation === false) {
          NoSaveMessage();
        } else {
          errorMessagesArray = [];
          var showMapDescError = false;
          var showMapDescriptionErrorText;
          var showMapLongDescError = false;
          var showMapLongDescriptionErrorText;
          if (
            (values.description && !values.description.trim()) ||
            !values.description
          ) {
            showMapDescError = true;
            showMapDescriptionErrorText = MapSetConstants.ADD_MAP_DESC_ERROR;
            errorMessagesArray.push(MapSetConstants.ADD_MAP_DESC_ERROR);
            setshowEditResults(false);
            setOpen(false);
          }
          if (
            (values.mapLongDescription && !values.mapLongDescription.trim()) ||
            !values.mapLongDescription
          ) {
            showMapLongDescError = true;
            showMapLongDescriptionErrorText = MapSetConstants.ADD_MAP_LONG_DESC_ERROR;
            errorMessagesArray.push(MapSetConstants.ADD_MAP_LONG_DESC_ERROR);
            setshowEditResults(false);
            setOpen(false);
          }
          if (/^[a-zA-Z0-9 ]*$/.test(values.description) === false) {
            showMapDescError = true;
            showMapDescriptionErrorText = MapSetConstants.MAP_DESC_ERROR;
            errorMessagesArray.push(MapSetConstants.MAP_DESC_ERROR);
            setshowEditResults(false);
            setOpen(false);
          }
          setShowError({
            showBeginDateError: showBeginDateError,
            showEndDateError: showEndDateError,
            showDateOverlappingError: showDateOverlappingError,
            showLOBError: showLOBError,
            showMapTypeError: showMapTypeError,
            showMapIDError: showMapIDError,
            showMapDescError: showMapDescError,
            showMapLongDescError: showMapLongDescError
          });
          setShowErrorText({
            beginDateerrorText: beginDateerrorText,
            endDateerrorText: endDateerrorText,
            showMapIDErrorText: showMapIDErrorText,
            showMapDescriptionErrorText: showMapDescriptionErrorText,
            showMapLongDescriptionErrorText: showMapLongDescriptionErrorText
          });
          seterrorMessages(errorMessagesArray);
          if (errorMessagesArray.length === 0) {
            setShowError(false);
            searchData.mapDefDetail = mapDefinitionDataArrayState;
            searchData.auditUserID = logInUserID;
            searchData.auditTimeStamp = getUTCTimeStamp();
            searchData.addedAuditUserID = searchData.addedAuditUserID
              ? searchData.addedAuditUserID
              : logInUserID;
            searchData.addedAuditTimeStamp = searchData.addedAuditTimeStamp
              ? searchData.addedAuditTimeStamp
              : getUTCTimeStamp();
            searchData.versionNo =
              versionNo && versionNo !== "" ? versionNo : searchData.versionNo;
            const searchView = {
              lineOfBusiness: [AppConstants.DEFAULT_LOB_VALUE],
              mapType: searchData.mapType ? searchData.mapType : null,
              mapSetID: searchData.mapID ? searchData.mapID : null,
              mapDescription: values.description ? values.description : null,
              fieldValue: null,
              mapSetIDStartsOrContains: 1,
              mapDescStartsOrContains: 1,
              dataElementName: null
            };
            setsearchViewOnAdd(searchView);
            // editing description in search data
            const updateDescription = {
              ...searchData,
              mapDescription: values.description,
              mapLongDescription: values.mapLongDescription,
              noteSetVO: notesInput,
              lineOfBusiness: AppConstants.DEFAULT_LOB_VALUE
            };
            onMasterUpdateMapDefinition(updateDescription);
            setSpinnerLoader(true);
            setAllowNavigation(false);
          }
        }
      }
    }
  };

  const resetDefinitionData = flag => {
    if (flag) {
      setSelectedDate(null);
      setSelectedDate1("12/31/9999");
    }
    setShowErrorText({
      showMapIDErrorText: "",
      showMapDescriptionErrorText: "",
      showMapLongDescriptionErrorText:""
    });
    setDataElement({
      dataElementCriteria: DEFAULT_DD_VALUE,
      functionalArea: DEFAULT_DD_VALUE,
      value: DEFAULT_DD_VALUE,
      exclude: false,
      value1: null,
      value2: null,
      selectedOption: "List"
    });
    seterrorMessages([]);
    setEombtype("List");
    setShowError(false);
  };

  const validateHeaderFields = () => {
    var showMapIDError = false;
    var showMapDescError = false;
    var showMapLongDescError=false;
    var showMapDescriptionErrorText;
    var showMapLongDescriptionErrorText;
    var showLOBError = false;
    var showMapTypeError = false;
    var showMapIDErrorText = "";
    if (!values.mapType || values.mapType === DEFAULT_DD_VALUE) {
      showMapTypeError = true;
      errorMessagesArray.push(MapSetConstants.ADD_MAP_TYPE_ERROR);
      seterrorMessages(errorMessagesArray);
      setOpen(false);
    }
    if ((values.mapId && !values.mapId.trim()) || !values.mapId) {
      showMapIDError = true;
      showMapIDErrorText = MapSetConstants.ADD_MAP_ID_ERROR;
      errorMessagesArray.push(MapSetConstants.ADD_MAP_ID_ERROR);
      setshowEditResults(false);
      setOpen(false);
    }
    if (/^[a-zA-Z0-9 ]*$/.test(values.mapId) === false) {
      showMapIDError = true;
      showMapIDErrorText = MapSetConstants.MAP_ID_ERROR;
      errorMessagesArray.push(MapSetConstants.MAP_ID_ERROR);
      setshowEditResults(false);
      setOpen(false);
    }
    if (
      (values.description && !values.description.trim()) ||
      !values.description
    ) {
      showMapDescError = true;
      showMapDescriptionErrorText = MapSetConstants.ADD_MAP_DESC_ERROR;
      errorMessagesArray.push(MapSetConstants.ADD_MAP_DESC_ERROR);
      setshowEditResults(false);
      setOpen(false);
    }
    if (/^[a-zA-Z0-9 ]*$/.test(values.description) === false) {
      showMapDescError = true;
      showMapDescriptionErrorText = MapSetConstants.MAP_DESC_ERROR;
      errorMessagesArray.push(MapSetConstants.MAP_DESC_ERROR);
      setshowEditResults(false);
      setOpen(false);
    }
    if (
      (values.mapLongDescription && !values.mapLongDescription.trim()) ||
      !values.mapLongDescription
    ) {
      showMapLongDescError = true;
      showMapLongDescriptionErrorText = MapSetConstants.ADD_MAP_LONG_DESC_ERROR;
      errorMessagesArray.push(MapSetConstants.ADD_MAP_LONG_DESC_ERROR);

      setshowEditResults(false);
      setOpen(false);
    }
    seterrorMessages(errorMessagesArray);
    setShowError({
      showBeginDateError: showBeginDateError,
      showEndDateError: showEndDateError,
      showDateOverlappingError: showDateOverlappingError,
      showLOBError: showLOBError,
      showMapTypeError: showMapTypeError,
      showMapIDError: showMapIDError,
      showMapDescError: showMapDescError,
      showMapLongDescError:showMapLongDescError
    });

    setShowErrorText({
      beginDateerrorText: beginDateerrorText,
      endDateerrorText: endDateerrorText,
      showMapIDErrorText: showMapIDErrorText,
      showMapDescriptionErrorText: showMapDescriptionErrorText,
      showMapLongDescriptionErrorText: showMapLongDescriptionErrorText
    });
  };
  const rowDeleteSystemListDetails = data => {
    setRowMapDefDetailData({
      ...rowmapDefDetailData,
      rowmapDefDetailData: data
    });
  };

  function mapDefDetailRowDeleteClick(data, dataSetName) {
    let temNewDialogData = [...data];
    if (rowmapDefDetailData.rowmapDefDetailData) {
      for (let i = 0; i < rowmapDefDetailData.rowmapDefDetailData.length; i++) {
        if (rowmapDefDetailData.rowmapDefDetailData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(
            payment => payment.id !== rowmapDefDetailData.rowmapDefDetailData[i]
          );
        }
      }
    }
    if (dataSetName === "setnewDialogDataAdd") {
      setnewDialogDataAdd(temNewDialogData);
      setmapDefinitionDataArray(temNewDialogData);
    } else if (dataSetName === "setnewDialogData") {
      setnewDialogData(temNewDialogData);
    }

    setRowMapDefDetailData([]);
  }

  const masterRowDeleteMapListDetails = data => {
    setRowMasterDataDetails({
      ...rowMasterDataDetails,
      rowMasterDataDetails: data
    });
  };

  function masterDataRowDeleteClick(data) {
    let tempData = [...data];
    setAllowNavigation(true);
    if (rowMasterDataDetails.rowMasterDataDetails) {
      for (
        let i = 0;
        i < rowMasterDataDetails.rowMasterDataDetails.length;
        i++
      ) {
        if (rowMasterDataDetails.rowMasterDataDetails[i] !== undefined) {
          tempData = tempData.filter(
            payment =>
              payment.id !== rowMasterDataDetails.rowMasterDataDetails[i]
          );
        }
      }
    }

    setmapDefinitionDataArray(tempData);
    const mapDefinitionTableDataTemp = [];
    let newArrayObj = {};

    var currentDate = new Date();
    var dd = currentDate.getDate();
    var mm = currentDate.getMonth() + 1;

    var yyyy = currentDate.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    currentDate = yyyy + "-" + mm + "-" + dd;

    tempData.map((mapDefState, i) => {
      mapDefState.mapDefData.map((definitionData, j) => {
        newArrayObj = {};
        if (j == 0) {
          newArrayObj.beginDate = mapDefState.beginDate;
          newArrayObj.endDate = mapDefState.endDate;
          newArrayObj.id = mapDefState.id;
        } else {
          newArrayObj.beginDate = "";
          newArrayObj.endDate = "";
          newArrayObj.isChild = true;
        }

        newArrayObj.exclude =
          definitionData.excludeIndicator == false
            ? "No"
            : definitionData.excludeIndicator == true
            ? "Yes"
            : "No";
        newArrayObj.dataElementCriteria = definitionData.dataElementCriteria;
        newArrayObj.functionalArea = definitionData.functionalArea;
        functionalAreaReference.map(fDesc => {
          if (definitionData.functionalArea == fDesc.code) {
            newArrayObj.functionalAreaDesc = fDesc.description;
          }
        });
        newArrayObj.mapSetSeq = definitionData.mapSetSeq;
        newArrayObj.value =
          definitionData.fromValue && definitionData.thruValue
            ? definitionData.fromValue + " to " + definitionData.thruValue
            : definitionData.fromValue;
        if (mapDefState.voidDate) {
          newArrayObj.voidDate = mapDefState.voidDate;
        } else {
          newArrayObj.voidDate =
            editMapDefinitionVoid == true ? currentDate : null;
        }
        newArrayObj.definitionData = mapDefState.mapDefData;
        mapDefinitionTableDataTemp.push(newArrayObj);
      });
    });

    setmapDefinitionTableData(mapDefinitionTableDataTemp);
    updateGridData(mapDefinitionTableDataTemp);
    if (
      rowMasterDataDetails.rowMasterDataDetails &&
      rowMasterDataDetails.rowMasterDataDetails.length > 0
    ) {
      resetDefinitionData(true);
    }
    setshowMapDefinitionAddForm(false);
    setRowMasterDataDetails([]);
  }
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    if (!noteaddFlag) {
      notesDataArray.push(noteText);
      setNotesTableData(notesDataArray);
      setNotesInput({ ...notesInput, notesList: notesDataArray });
    } else {
      notesTableData[notesTableData.indexOf(editNoteData)].noteText =
        data.noteText;
      notesTableData[notesTableData.indexOf(editNoteData)].usageTypeCode =
        data.usageTypeCode;
      setNotesInput({ ...notesInput, notesList: notesTableData });
    }
  };

  
  const validateParentForm = () => {
    if (!showEditScreen) {
      if (
        !values.LOB ||
        values.LOB === DEFAULT_DD_VALUE ||
        !values.mapType ||
        values.mapType === DEFAULT_DD_VALUE ||
        !values.mapId ||
        values.mapId === "" ||
        !values.description ||
        values.description === "",
        !values.mapLongDescription ||
        values.mapLongDescription === ""
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!values.description || values.description === "") {
        return true;
      } else {
        return false;
      }
    }
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessage([]);
    seterrorMessages([]);
    setShowError(false);
  };
  //Audit Log Implementation starts
   
  const onClickAuditLog = () => {
    const tableName = "r_mpst_hdr_tb";
    const keyValue = {
      r_mpst_id:
        values.mapId === ""
          ? props.location.state &&
            (props.location.state.payloadViewData &&
            props.location.state.payloadViewData[0].mapID
              ? props.location.state.payloadViewData[0].mapID
              : props.location.state &&
                props.location.state.payloadViewDataOnAdd &&
                props.location.state.payloadViewDataOnAdd[0].mapID
              ? props.location.state.payloadViewDataOnAdd[0].mapID
              : props.location.state.payloadViewData[0].mapID)
          : values.mapId
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, "auditLogData"));
  };
 
 
  useEffect(() => {
    if (showLogTable && document.getElementById("audit-table"))
      document.getElementById("audit-table").scrollIntoView();
  }, [showLogTable]);
 
  const filterAuditTable = rowData => {
    return rowData.afterValue || rowData.beforeValue;
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);
  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      setAuditBtnClass("btn btn-primary ml-1")
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
      setAuditBtnClass("btn btn-text1 btn-transparent ml-1")

    }
  }, [auditButtonToggle]);
  const onClickAuditDialogLog = row => {
    let colSeqNum = "";
    row.definitionData.map(record => {
      if (record.type === "List" && record.fromValue === row.value) {
        colSeqNum = record.colSeqNumber;
      }
      if (record.type === "Range") {
        const val = row.value.split(" ");
        if (record.fromValue === val[0] && record.thruValue === val[2]) {
          colSeqNum = record.colSeqNumber;
        }
      }
    });
    const auditReq = [
      {
        tableName: 'r_mpst_dtl_tb',
        keyValue: {
          r_mpst_id:
        values.mapId === ""
          ? props.location.state &&
            (props.location.state.payloadViewData &&
            props.location.state.payloadViewData[0].mapID
              ? props.location.state.payloadViewData[0].mapID
              : props.location.state &&
                props.location.state.payloadViewDataOnAdd &&
                props.location.state.payloadViewDataOnAdd[0].mapID
              ? props.location.state.payloadViewDataOnAdd[0].mapID
              : props.location.state.payloadViewData[0].mapID)
          : values.mapId,
        r_mpst_col_seq_num: row.colSeq,
        r_mpst_seq_num: row.mapSetSeq
        }
      }, {
        tableName: 'r_mpst_tb',
        keyValue: {
          r_mpst_id:
        values.mapId === ""
          ? props.location.state &&
            (props.location.state.payloadViewData &&
            props.location.state.payloadViewData[0].mapID
              ? props.location.state.payloadViewData[0].mapID
              : props.location.state &&
                props.location.state.payloadViewDataOnAdd &&
                props.location.state.payloadViewDataOnAdd[0].mapID
              ? props.location.state.payloadViewDataOnAdd[0].mapID
              : props.location.state.payloadViewData[0].mapID)
          : values.mapId,
          r_mpst_col_seq_num: row.colSeq
          },
        columnNames: "r_mpst_col01_vv_domain_nam,r_mpst_col02_vv_domain_nam,r_mpst_col03_vv_domain_nam,r_mpst_col04_vv_domain_nam,r_mpst_col05_vv_domain_nam,r_mpst_col06_vv_domain_nam,r_mpst_col07_vv_domain_nam,r_mpst_col08_vv_domain_nam,r_mpst_col09_vv_domain_nam,r_mpst_col10_vv_domain_nam,r_mpst_col11_vv_domain_nam,r_mpst_col12_vv_domain_nam,r_mpst_col13_vv_domain_nam,r_mpst_col14_vv_domain_nam,r_mpst_col15_vv_domain_nam"
      },
      {
        tableName: 'r_list_dtl_tb',
        keyValue: {r_func_area_cd:row.definitionData[0].functionalArea,r_list_num:+row.definitionData[0].hiddenListNum,r_list_dtl_sk:row.definitionData[0].listDetail},
        columnNames: 'r_list_end_value,r_list_beg_value'
      }
    ]
    dispatch(
      getAuditLogRowDetailsAction(auditReq, "auditLogTableData")
    );
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById("audit-dialog-table"))
      document.getElementById("audit-dialog-table").scrollIntoView();
  }, [showLogDialogTable]);
  //Audit Log Implementation ends

  useEffect(() => {
    if (openDialog && document.getElementById("add_definition_form_pop_up"))
      document.getElementById("add_definition_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
  }, [openDialog]);
  useEffect(() => {
    if (
      openDialogEdit &&
      document.getElementById("edit_definition_form_pop_up")
    )
      document.getElementById("edit_definition_form_pop_up").scrollIntoView({
        behavior: "smooth"
      });
  }, [openDialogEdit]);

  useEffect(() => {
    if (open && showEditResults && document.getElementById("edit_mapdef")) {
      document.getElementById("edit_mapdef").scrollIntoView({
        behavior: "smooth"
      });
    } else if (
      !open &&
      !showEditResults &&
      showMapDefinitionAddForm &&
      document.getElementById("add_mapdef")
    ) {
      document.getElementById("add_mapdef").scrollIntoView({
        behavior: "smooth"
      });
    } else {
    }
   
  }, [open,showEditResults,showMapDefinitionAddForm]);

  const cancelMapdefDetail = () => {
    setshowMapDefinitionAddForm(false);
    resetDefinitionData(true);
  }
  const cancelMapdefDetailEdit =()=>{ 
    setFocusCheck(intialFocusCheck);
    setAllowNavigation(intialFocusCheck);   
    cancelEdit()
    setshowEditResults(false) 
  }
 
  const ValuesFromMapSet = props.history && props.history.location && props.history.location.state
  && props.history.location.state.values

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(()=>{
      setDialogOpen(false);
      setFocusCheck(false);
      if(window.location.pathname === '/MapSetAdd'){        
          setDialogOpen(false);
          setFocusCheck(false);
          props.history.push({
            pathname: '/MapSetSearch',
            tableRender: false
          });
      }
      else if(window.location.pathname === '/MapSetEdit'){
        props.history.push({
          pathname: '/MapSetSearch',
          tableRender: !props.history.location?.state?.isFromAdd,
          state: { values: ValuesFromMapSet }
        });
      }

    },100)
    };

  const majorCancel = () => {
    seterrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      if(window.location.pathname === '/MapSetEdit'){
         props.history.push({
        pathname: '/MapSetSearch',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: {values: ValuesFromMapSet}
      });}
      else if(window.location.pathname === '/MapSetAdd'){
        props.history.push({
          pathname: '/MapSetSearch',
          tableRender:false
        });
      }

    }
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const functionalArea1 =
           addDropdowns &&
            addDropdowns['Reference#R_FUNC_AREA_CD'] &&
            addDropdowns['Reference#R_FUNC_AREA_CD'].filter(
              value => value.code === each.functionalArea
            );
          each.functionalAreaDesc =
          functionalArea1 && functionalArea1.length > 0
              ? functionalArea1[0].description
              : each.functionalArea1 ? each.functionalArea1 :  '';     
                  
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <div>
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      {LoadSpinner ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessage errorMessages={errorMessages} />
        <SuccessMessage successMessages={successMessage} />
        <div className="tab-header">
          {showEditScreen ? (
            <h1 className="tab-heading float-left">Edit Map Definition</h1>
          ) : null}
          {!showEditScreen ? (
            <h1 className="tab-heading float-left">Add Map Definition</h1>
          ) : null}
          <div className="float-right mt-2">
            {showEditScreen ? (
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-success"
                onClick={() => masterSave("add")}
                disabled={!userInquiryPrivileges}
              >
                <i class="fa fa-check" aria-hidden="true"></i>
                Save
              </Button>
            ) : null}
            {!showEditScreen ? (
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-success"
                onClick={() => masterSave("edit")}
                disabled={!userInquiryPrivileges}
              >
                <i class="fa fa-check" aria-hidden="true"></i>
                Save
              </Button>
            ) : null}
            {showEditScreen ? 
              <Button
                className={ 
                  auditBtnClass
                }
                onClick={() => {
                  setAuditButtonToggle(!auditButtonToggle);
                  setShowLogTable(true);
                }}
              >
                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                Audit Log
              </Button>
              : null}

            <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
                                  </div>

          <div className="clearfix"></div>
        </div>
        <div>
          {!showEditScreen ? (
            <div className="tab-body pb-1">
              <form noValidate autoComplete="off">
                <div className="form-wrapper">
                  <NativeDropDown
                      id="map-type"
                      label="Map Type"
                      isRequired
                      disabled={!userInquiryPrivileges}
                      errTxt={
                        showMapTypeError
                          ? MapSetConstants.ADD_MAP_TYPE_ERROR
                          : null
                      }
                      value={values.mapType}
                      onChange={handleChange("mapType")}
                      InputLabelProps={{
                        shrink: true
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      dropdownList={MapTypeReference
                        ? MapTypeReference.map((item, index) => (
                            <option value={item.code}>
                              {item.description}
                            </option>
                          ))
                        : null}
                    />
                  <div className="mui-custom-form input-md">
                    <TextField
                      id="map-id"
                      fullWidth
                      label="Map ID"
                      required
                      InputProps={{
                        readOnly: !userInquiryPrivileges,
                        className: !userInquiryPrivileges ? "Mui-disabled" : ""
                      }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      helperText={showMapIDError ? showMapIDErrorText : null}
                      error={showMapIDError ? showMapIDErrorText : null}
                      inputProps={{ maxLength: 30 }}
                      value={values.mapId}
                      onChange={handleChange("mapId")}
                    />
                  </div>
                  <div className="mui-custom-form input-xl">
                    <TextField
                      id="description"
                      label="Map Description"
                      required
                      InputProps={{
                        readOnly: !userInquiryPrivileges,
                        className: !userInquiryPrivileges ? "Mui-disabled" : ""
                      }}
                      fullWidth
                      helperText={
                        showMapDescError ? showMapDescriptionErrorText : null
                      }
                      error={
                        showMapDescError ? showMapDescriptionErrorText : null
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ maxLength: 30 }}
                      value={values.description}
                      onChange={handleChange("description")}
                    />
                  </div>                 
                  <div className="mui-custom-form with-textarea override-width-95">
                  <Form.Group>
                    <Form.Label className="MuiFormLabel-root small-label" id='text-label-01'>
                    Map Long Description<span>*</span>             
                    </Form.Label>
                    <Form.Control
                      aria-describedby="text-label-01"
                      aria-label="form-controll-01"
                      as="textarea"
                      rows="4"
                      name="text"
                      value={values.mapLongDescription}
                       onChange={handleChange("mapLongDescription")}
                      maxLength={totalCharacters}
                      style={{ width: "100%" }}
                    />
                      {showMapLongDescError ? (
                <p
                  class="MuiFormHelperText-root Mui-error Mui-required"
                  role="alert"
                >
                  {
                 showMapLongDescError
                 ? showMapLongDescriptionErrorText
                 : null
                }{' '}
                </p>
              ) : null}
                  </Form.Group>
                  <div
                    style={{
                      border: "1px solid rgb(169, 169, 169)",
                      borderRadius: ".25rem",
                      paddingLeft: "5px",
                      width: "200px",
                      alignText: "center"
                    }}
                  >
                    Characters Remaining:{320-values.mapLongDescription.length}
                  </div>
                </div>
                </div>
                <div className="float-right mt-5 mr-3 my-3">
                  <Button
                    className="btn-text-main-delete btn-transparent"
                    onClick={() =>
                      masterDataRowDeleteClick(mapDefinitionDataArrayState)
                    }
                    disabled={!userInquiryPrivileges}
                    title={"Delete"}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                    Delete
                  </Button>
                  <Button
                    className="btn-text-main-add btn-success ml-1"
                    title="Add Map Definition"
                    onClick={() => addMapDefinitionButton(values)}
                    disabled={!userInquiryPrivileges}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    Add
                  </Button>
                </div>
                <div className="clearfix"></div>
              </form>
            </div>
          ) : null}

          {showEditScreen ? (
            <div className="tab-body pb-1">
              <form noValidate autoComplete="off">
                <div className="form-wrapper">
                    <NativeDropDown
                      id="map-type"
                      label="Map Type"
                      isRequired
                      value={
                        searchData.mapTypeCode
                          ? searchData.mapTypeCode
                          : searchData.mapType
                      }
                      disabled={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      dropdownList={addDropdowns && addDropdowns['G1#R_MAP_TY_CD'] && 
                  addDropdowns['G1#R_MAP_TY_CD'].length > 0
                    ? addDropdowns['G1#R_MAP_TY_CD'].map((item, index) => (
                            <option value={item.code}>
                              {item.description}
                            </option>
                          ))
                        : null}
                    />

                  <div className="mui-custom-form  input-sm">
                    <TextField
                      id="map-id"
                      fullWidth
                      label="Map ID"
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ readOnly: true, className: "Mui-disabled" }}
                      value={
                        searchData.mapSetID
                          ? searchData.mapSetID
                          : searchData.mapID
                      }
                      inputProps={{ maxLength: 30 }}
                    />
                  </div>
                  <div className="mui-custom-form input-xl">
                    <TextField
                      id="description"
                      fullWidth
                      label="Map Description"
                      required
                      InputLabelProps={{
                        shrink: true
                      }}
                      disabled={!userInquiryPrivileges}
                      value={values.description}
                      onChange={handleChange("description")}
                      inputProps={{ maxLength: 30 }}
                      helperText={
                        showMapDescError ? showMapDescriptionErrorText : null
                      }
                      error={
                        showMapDescError ? showMapDescriptionErrorText : null
                      }
                    />
                  </div>                  
                  <div className="mui-custom-form with-textarea override-width-95">
                  <Form.Group>
                    <Form.Label className="MuiFormLabel-root small-label" id='text-label-01'>
                    Map Long Description<span>*</span>
                    </Form.Label>
                    <Form.Control
                      aria-describedby="text-label-01"
                      aria-label="form-controll-01"
                      as="textarea"
                      rows="4"
                      name="text"
                      value={values.mapLongDescription}
                       onChange={handleChange("mapLongDescription")}
                      maxLength={totalCharacters}
                      style={{ width: "100%" }}
                    />
                    {showMapLongDescError ? (
                <p
                  class="MuiFormHelperText-root Mui-error Mui-required"
                  role="alert"
                >
                  {
                 showMapLongDescError
                 ? showMapLongDescriptionErrorText
                 : null
                }{' '}
                </p>
              ) : null}
                  </Form.Group>
                  <div
                    style={{
                      border: "1px solid rgb(169, 169, 169)",
                      borderRadius: ".25rem",
                      paddingLeft: "5px",
                      width: "200px",
                      alignText: "center"
                    }}
                  >
                    Characters Remaining:{320-values.mapLongDescription.length}
                  </div>
                </div>
                </div>
                <div className="form-wrapper justify-space-bw">
                  <div className="mui-custom-form">
                    <div className="sub-radio">
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            onChange={handleChangeshowVoids(showVoids)}
                            value={showVoids}
                            inputProps={{"data-testid":"test_show_voids"}}
                          />
                        }
                        label="Show Voids"
                      />
                    </div>
                  </div>
                  <div className="float-right mr-3 my-3">
                    <Button
                      className="btn-text-main-delete btn-transparent"
                      onClick={() =>
                        masterDataRowDeleteClick(mapDefinitionDataArrayState)
                      }
                      title={"Delete"}
                    >
                      <i className="fa fa-trash" aria-hidden="true"></i>
                      Delete
                    </Button>
                    <Button
                      className="btn-text-main-add btn-success ml-1"
                      title="Add Map Definition"
                      onClick={() => addMapDefinitionButton(values)}
                      disabled={!userInquiryPrivileges}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>
                      Add
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          ) : null}

          <div className="tab-body">
            <div className="tab-holder">
              <div className={classes.root}>
                <div className="tableNoWrap">
                  <TableComponent
                    isSearch={false}
                    fixedTable
                    tableData={getTableData(gridUpdatedTable || [])}
                    headCells={showEditScreen ? editheadCells : addheadCells}
                    onTableRowClick={handleClickOpenEdit}
                    sortOrder={"desc"}
                    onTableRowDelete={masterRowDeleteMapListDetails}
                    // Audit Log Changes
                    onTableCellButtonClick={onClickAuditDialogLog}
                    uniqueKey={"unique"}
                    setClose={setClose}
                    close={close}
                  />
                </div>
                <div className="clearfix"></div>

                {open === true && showEditResults === true ? (
                  <div className="tabs-container" id="edit_mapdef">
                    <div className="tab-header  mini-tab-header">
                      <div className="tab-heading float-left">
                        Edit Map Definition
                      </div>
                      <div className="float-right mr-3 mt-1">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-primary ml-1"
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : rowData.voidDate != null
                          }
                          onClick={() => checkEdit()}
                          data-testid="test_map_def_update"
                        >
                          <i class="fa fa-check" aria-hidden="true"></i>
                          Update
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="bt-reset btn-transparent  ml-1"
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : rowData.voidDate != null
                          }
                          onClick={() => cancelEdit()}
                        >
                          <i class="fa fa-undo" aria-hidden="true"></i>
                          Reset
                        </Button>
                        <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={cancelMapdefDetailEdit}
                            >
                              Cancel
                            </Button>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                    <form className="form-wrapper" autoComplete="off">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <div className="mui-custom-form  with-date">
                            <KeyboardDatePicker
                              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                              id="begindate-picker-dialog"
                              label="Begin Date"
                              format="MM/dd/yyyy"
                              value={beginDate}
                              required
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : Object.keys(searchData).length !== 0
                              }
                              placeholder="mm/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              helperText={
                                showBeginDateError ? beginDateerrorText : null
                              }
                              error={
                                showBeginDateError ? beginDateerrorText : null
                              }
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </div>
                          <div className="mui-custom-form   with-date">
                            <KeyboardDatePicker
                              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                              id="enddate-picker-dialog"
                              label="End Date"
                              format="MM/dd/yyyy"
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : rowData.voidDate != null
                              }
                              value={checkBeginDate ? "12/31/9999" : endDate}
                              InputLabelProps={{
                                shrink: true
                              }}
                              placeholder="mm/dd/yyyy"
                              helperText={
                                showEndDateError ? endDateerrorText : null
                              }
                              error={showEndDateError ? endDateerrorText : null}
                              onChange={handleDateChange1}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </div>
                          {Object.keys(searchData).length !== 0 ? (
                            <div className="mui-custom-form">
                              <label className="MuiFormLabel-root small-label no-margin">
                                Void
                              </label>
                              <div
                                className="sub-radio"
                                style={{
                                  paddingTop: "10px",
                                  marginLeft: "0px"
                                }}
                              >
                                <Radio
                                  value={true}
                                  id="voidyescont"
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : rowData.voidDate != null
                                  }
                                  checked={editMapDefinitionVoid === true}
                                  onChange={handleeditMapDefinitionVoid}
                                />
                                <span className="text-black">
                                  {" "}
                                  <label for="voidyescont"> Yes </label>
                                </span>
                                <Radio
                                  value={false}
                                  id="voidnocont"
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : rowData.voidDate != null
                                  }
                                  checked={editMapDefinitionVoid === false}
                                  onChange={handleeditMapDefinitionVoid}
                                  className="ml-2"
                                />
                                <span className="text-black">
                                  {" "}
                                  <label for="voidnocont"> No </label>
                                </span>
                              </div>
                            </div>
                          ) : null}
                          <div className="ml-auto mr-3 mt-5 my-3">
                            <Button
                              className="btn-text-main-delete btn-transparent"
                              onClick={() =>
                                mapDefDetailRowDeleteClick(
                                  newDialogData,
                                  "setnewDialogData"
                                )
                              }
                              title={"Delete"}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                              Delete
                            </Button>
                            <Button
                              className="btn-text-main-add btn-success ml-1"
                              title=" Add Definition Data"
                              onClick={() => addDefinitionDataButton("edit")}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : rowData.voidDate != null
                              }
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              Add
                            </Button>
                          </div>
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </form>
                    <div className="tab-holder">
                      <div>
                        <MapSetUpdateDefinitionData
                          rowDeleteSystemListDetails={
                            rowDeleteSystemListDetails
                          }
                          tableData={getTableData(newDialogData || [])}
                          showEdit={showEditField}
                          handleClickOpenNewDialog={handleClickOpenNewDialog}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="clearfix"></div>
                {open === false &&
                showEditResults === false &&
                showMapDefinitionAddForm === true ? (
                  <div
                    className="tabs-container"
                    id="add_mapdef"
                    hidden={showResults}
                  >
                    <div className="tab-header mini-tab-header">
                      <div className="tab-heading float-left">
                        Add Map Definition Detail
                      </div>
                      <div className="float-right mr-3 mt-1">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-success ml-1"
                          onClick={() => masterAddDefinitionData("add")}
                          disabled={!userInquiryPrivileges}
                        >
                          <i class="fa fa-check" aria-hidden="true"></i>
                          Add
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="bt-reset btn-transparent  ml-1"
                          onClick={() => resetDefinitionData(true)}
                          disabled={!userInquiryPrivileges}
                        >
                          <i class="fa fa-undo" aria-hidden="true"></i>
                          Reset
                        </Button>
                        <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={cancelMapdefDetail}
                            >
                              Cancel
                            </Button>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  <div className="form-border my-3">
                    <form
                      style={{ display: "flex", flexWrap: "wrap" }}
                      autoComplete="off"
                    >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                          <div className="mui-custom-form with-date">
                            <KeyboardDatePicker
                              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                              id="date-picker-dialog-begindat"
                              label="Begin Date"
                              required
                              disabled={!userInquiryPrivileges}
                              format="MM/dd/yyyy"
                              placeholder="mm/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              helperText={
                                showBeginDateError ? beginDateerrorText : null
                              }
                              error={
                                showBeginDateError ? beginDateerrorText : null
                              }
                              value={beginDate}
                              onChange={handleDateChange}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </div>
                          <div className="mui-custom-form with-date">
                            <KeyboardDatePicker
                              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                              id="date-picker-dialog-enddat"
                              label="End Date"
                              required
                              disabled={!userInquiryPrivileges}
                              format="MM/dd/yyyy"
                              placeholder="mm/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              helperText={
                                showEndDateError ? endDateerrorText : null
                              }
                              error={showEndDateError ? endDateerrorText : null}
                              value={checkBeginDate ? "12/31/9999" : endDate}
                              onChange={handleDateChange1}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </div>
                          <div className="ml-auto mr-3 mt-5 my-3">
                            <Button
                              className="btn-text-main-delete btn-transparent"
                              onClick={() =>
                                mapDefDetailRowDeleteClick(
                                  newDialogDataAdd,
                                  "setnewDialogDataAdd"
                                )
                              }
                              title={"Delete"}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                              Delete
                            </Button>
                            <Button
                              className="btn-text-main-add btn-success ml-1"
                              title=" Add Definition Data"
                              onClick={() => addDefinitionDataButton("add")}
                              disabled={!userInquiryPrivileges}
                            >
                                  <i className="fa fa-plus" aria-hidden="true"></i>
                              Add
                            </Button>
                          </div>
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </form>
                    </div>
                    <div className="tab-holder">
                      <div>
                        <MapSetUpdateDefinitionData
                          rowDeleteSystemListDetails={
                            rowDeleteSystemListDetails
                          }
                          tableData={newDialogDataAdd}
                          showEdit={showEditField}
                          handleClickOpenNewDialog={handleClickOpenNewDialog}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
                {openDialogEdit ? (
                  <div
                    className="tabs-container"
                    id="edit_definition_form_pop_up"
                  >
                    <div className="tab-header  mini-tab-header">
                      <div className="tab-heading float-left">
                        Edit Definition Data
                      </div>
                      <div className="float-right mt-1 mr-3">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-success ml-1"
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : rowData.voidDate != null
                          }
                          onClick={saveMapDefinitionData}
                          data-testid="test_definition_update"
                        >
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Update
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="bt-reset btn-transparent  ml-1"
                          disabled={
                            !userInquiryPrivileges
                              ? !userInquiryPrivileges
                              : rowData.voidDate != null
                          }
                          onClick={resetMapDefinitionData}
                          data-testid="test_definition_reset"
                        >
                          <i className="fa fa-undo" aria-hidden="true"></i>
                          Reset
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-primary btn btn-outlined ml-1"
                          onClick={handleCloseNewDialog}
                          data-testid="test_definition_cancel"
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    <div className="form-border my-3 mx-3">
                      <form noValidate autoComplete="off">
                        <div className="form-wrapper">
                          
                            <NativeDropDown
                              id="data-element-criteria"
                              label="Data Element Criteria"
                              isRequired
                              value={dataElement.dataElementCriteria}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : rowData.voidDate != null
                              }
                              onChange={handleChangeDataElement(
                                "dataElementCriteria"
                              )}
                              inputProps={{"data-testid": "edit_data_ele_criteria"}}
                              InputLabelProps={{
                                shrink: true
                              }}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu
                                }
                              }}
                              errTxt={
                                showDataElementCriteriaError
                                  ? MapSetConstants.ADD_DATAELEMENT_CRITERIA
                                  : null
                              }
                              dropdownList={dataElementReference
                                ? dataElementReference.map((option, index) => (
                                    <option value={option}>{option}</option>
                                  ))
                                : null}
                            />
                          <div className="mui-custom-form input-md">
                            <label className="MuiFormLabel-root small-label">
                              Type<span>*</span>
                            </label>
                            <div
                              className="sub-radio"
                              style={{ paddingTop: "10px" }}
                            >
                              <Radio
                                className="ml-1"
                                value="List"
                                id="eomblist"
                                name="eombType"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : rowData.voidDate != null
                                }
                                checked={dataElement.selectedOption === "List"}
                                onChange={handleChangeDataElement(
                                  "selectedOption"
                                )}
                                inputProps={{"data-testid":"edit_eomb_list"}}
                              />
                              <span className="text-black">
                                {" "}
                                <label for="eomblist"> List </label>
                              </span>
                              <Radio
                                value="Range"
                                name="eombType"
                                id="eombrange"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : rowData.voidDate != null
                                }
                                className="ml-2"
                                checked={dataElement.selectedOption === "Range"}
                                onChange={handleChangeDataElement(
                                  "selectedOption"
                                )}
                                inputProps={{"data-testid":"edit_eomb_range"}}
                              />
                              <span className="text-black">
                                {" "}
                                <label for="eombrange"> Range </label>{" "}
                              </span>
                            </div>
                          </div>
                          {renderSwitch(eombType)}
                          <div
                            className="mui-custom-form input-md"
                            style={{ marginTop: "10px" }}
                          >
                            <label
                              className="MuiFormLabel-root small-label"
                              for="Excludecontent"
                            >
                              Exclude
                            </label>
                            <div className="sub-radio">
                              <Checkbox
                                id="Excludecontent"
                                color="primary"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : rowData.voidDate != null
                                }
                                onChange={handleChangeDataElement("exclude")}
                                value={dataElement.exclude}
                                checked={dataElement.exclude}
                              />
                            </div>
                          </div>
                          
                        </div>
                      </form>

                      <div className="clearfix"></div>
                    
                    </div>
                  </div>
                ) : null}
                {openDialog ? (
                  <div
                    className="tabs-container"
                    id="add_definition_form_pop_up"
                  >
                    <div className="tab-header  mini-tab-header">
                      <div className="tab-heading float-left">
                        Add Definition Data
                      </div>
                      <div className="float-right mt-1 mr-3">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-success ml-1"
                          onClick={() => addDefinitionDataToList(dataElement)}
                          disabled={!userInquiryPrivileges}
                        >
                          <i className="fa fa-check" aria-hidden="true"></i>
                          Add
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="bt-reset btn-transparent  ml-1"
                          onClick={() => resetDefinitionData(false)}
                          disabled={!userInquiryPrivileges}
                        >
                          <i className="fa fa-undo" aria-hidden="true"></i>
                          Reset
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-primary btn btn-outlined ml-1"
                          onClick={handleCloseAddDefinitionDataDialog}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    <div className="form-border my-3 mx-3">
                      <form noValidate autoComplete="off">
                        <div className="form-wrapper">
                         
                            <NativeDropDown
                              id="data-element-criteria"
                              label="Data Element Criteria"
                              isRequired
                              disabled={!userInquiryPrivileges}
                              value={dataElement.dataElementCriteria}
                              onChange={handleChangeDataElement(
                                "dataElementCriteria"
                              )}
                              InputLabelProps={{
                                shrink: true
                              }}
                              SelectProps={{
                                MenuProps: {
                                  className: classes.menu
                                }
                              }}
                              errTxt={
                                showDataElementCriteriaError
                                  ? MapSetConstants.ADD_DATAELEMENT_CRITERIA
                                  : null
                              }
                              dropdownList={dataElementReference &&
                              !dataElementReference.systemFailure
                                ? dataElementReference.map((option, index) => (
                                    <option value={option}>{option}</option>
                                  ))
                                : null}
                            />
                          <div className="mui-custom-form input-md">
                            <label className="MuiFormLabel-root small-label">
                              Type<span>*</span>
                            </label>
                            <div className="sub-radio">
                              <Radio
                                value="List"
                                id="eombtypelist"
                                checked={dataElement.selectedOption === "List"}
                                name="eombType"
                                disabled={!userInquiryPrivileges}
                                onChange={handleChangeDataElement(
                                  "selectedOption"
                                )}
                              />
                              <span className="text-black">
                                {" "}
                                <label for="eombtypelist"> List </label>
                              </span>
                              <Radio
                                className="ml-2"
                                id="eombtyperange"
                                value="Range"
                                name="eombType"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : rowData.voidDate != null
                                }
                                checked={dataElement.selectedOption === "Range"}
                                onChange={handleChangeDataElement(
                                  "selectedOption"
                                )}
                              />
                              <span className="text-black">
                                {" "}
                                <label for="eombtyperange"> Range </label>
                              </span>
                            </div>
                          </div>
                          {renderSwitch(eombType)}
                          <div
                            className="mui-custom-form input-md"
                            style={{ marginTop: "10px" }}
                          >
                            <label
                              className="MuiFormLabel-root small-label"
                              for="Exclude"
                            >
                              Exclude
                            </label>
                            <div className="sub-radio">
                              <Checkbox
                                id="Exclude"
                                color="primary"
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : rowData.voidDate != null
                                }
                                onChange={handleChangeDataElement("exclude")}
                                value={dataElement.exclude}
                                checked={dataElement.exclude}
                              />
                            </div>
                          </div>
                         
                        </div>
                      </form>

                      <div className="clearfix"></div>
          
                    </div>
                  </div>
                ) : null}
                  {showLogDialogTable ? (
        <div id="audit-dialog-table">
          <AuditTableComponent
            print
            auditRowData={auditTableData}
            setAuditRowData={setAuditTableData}
            setShowLogTable={setShowLogDialogTable}
            setClose={setClose}
          />{" "}
        </div>
      ) : null} 
                <div className="clearfix"></div>
              </div>
            </div>
           
            {/* Dialog Box for a popupErrorMessage */}
            <Dialog
              className="custom-dialog dialog-500 with-padding"
              fullWidth={fullWidth}
              maxWidth={maxWidth}
              open={openErrorPopUp}
              disableBackdropClick
            >
              <DialogContent dividers>
                <div>
                  The Map Definition End Date is less than the Current Date. Are
                  you sure you want to save? If so, click OK to continue with
                  the save. If you click Cancel, the current updated information
                  will not be saved.
                </div>
              </DialogContent>

              <DialogActions>
                <Button
                  className="btn btn-primary ml-1"
                  onClick={() => onConfirm(null)}
                  disabled={!userInquiryPrivileges}
                >
                  OK
                </Button>
                <Button
                  className="btn btn-primary ml-1"
                  onClick={closeErrorPopUpDialog}
                  disabled={!userInquiryPrivileges}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
                  color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogContinueOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setContinueDialogOpen(false);
            }}
                  color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
            <div className="tab-panelbody m-3">
              <div className="tab-holder mb-2">
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="simple tabs example"
                    className="tabChange"
                  >
                    <Tab label="Notes" />
                  </Tabs>
                </AppBar>
                <TabPanel value={tabValue} index={0}>
                  <div className="tab-holder p-0">
                    <div className={classes.root}>
                      <Notes
                        addNotes={addNotes}
                        notesTableData={notesTableData}
                        noteSetListInput={noteSetListInput}
                        setNoteSetListInput={setNoteSetListInput}
                        usageTypeCodeData={usageTypeCodeData}
                        noteaddFlag={noteaddFlag}
                        setNoteAddFlag={setNoteAddFlag}
                        editNoteData={editNoteData}
                        setEditNoteData={setEditNoteData}
                        setSuccessMessages={clearSuccessErrorMsgs}
                        disableNotes={disableNotes}
                        setErrorMessages = {seterrorMessages}
                        setFocusCheck = {setFocusCheck}
                        userInquiryPrivileges = {userInquiryPrivileges}
                      />
                    </div>
                  </div>
                </TabPanel>
                {showLogTable ? (
                  <div id="audit-table">
                    <AuditTableComponent
                      print
                      auditRowData={auditRowData}
                      setAuditRowData={setAuditRowData}
                      setShowLogTable={setShowLogTable}
                    />{" "}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}
// withRouter(MapSetAdd)//
export default withRouter(
  NavHOC({
    Wrapped: MapSetAdd,
    url: "/MapSetEdit",
    action: mapSetActionView,
    selector: "appConfigState.mapSetState.mapSetView"
  })
);
