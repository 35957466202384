/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { Checkprivileges } from '../../../SharedModules/store/Actions/SharedAction';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import FinancialEntitySearchTable from './FinancialEntitySearchTable';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import Spinner from '../../../SharedModules/Spinner/Spinner';
import { keySort } from '../../../SharedModules/Table/MultipleTableSort';
import { getPayeeIdTypeCodeAction, getDropdownDataAction, SearchPayeeAction, ResetUpdateResponseData } from '../Store/Actions/FinancialEntityActions';
import * as FinancialEntityConstants from './FinancialEntityConstants';
import Footer from '../../../SharedModules/Layout/footer';
import dropdownCriteria from './FinancialEntitySearch.json';
import Radio from '@material-ui/core/Radio';
import NavHOC from '../../../SharedModules/Navigation/NavHOC';
import { searchSystemParameterAction9047 } from '../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import { removeLeadingTrailingSpaces } from '../../../SharedModules/CommonUtilities/commonUtilities';
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';

function FinancialEntitySearch (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const dispatch = useDispatch();
  const tableRender = sessionStorage.getItem('tableRender');
  const cancelRender = sessionStorage.getItem('isCancel');
  const toPrintRef = useRef();
  
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [spinLoadForPayPaymSts, setSpinLoadForPayPaymSts] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);
  const [searchtableData, setSearchTableData] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [payeeTypeDataFrmState, setPayeeTypeDataFrmState] = React.useState([]);
  const [payeePaymentStatusFrmState, setPayeePaymentStatusFrmState] = React.useState([]);
  const [payeeIDTypeFrmState, setPayeeIDTYpeFrmState] = React.useState([]);
  const [descriptionDropdownValues, setDropdownDescriptionValues] = React.useState([]);
  const [erroMessage, setErrorMessage] = React.useState({
    payeeIDErrorMsg: '',
    payeeNameErrorMsg: ''
  });
  const [values, setValues] = React.useState({
    payeeType: DEFAULT_DD_VALUE,
    payeeIDTypeCode: DEFAULT_DD_VALUE,
    payeePaymentStatus: DEFAULT_DD_VALUE,
    payeeID: '',
    payeeName: '',
    payeeNameStartsWithOrContains: ''
  });
  const [isSearch, setIsSearch] = React.useState(false);
  const [showPayeePaymentStatus,setShowPayeePaymentStatus]=React.useState('')
  const searchSystemParameterActionData = {
    parameterNumber: "9047", functionalArea: "F1"
  }
  const searchSystemParamForFinEntity = value => {
    dispatch(searchSystemParameterAction9047(value));
  };
  const systemParamaterPayload = useSelector(state => state.appConfigState?.systemParameterState?.data);
  const payeeDetailsFrmState = useSelector(state => state.financialEntityState.payeeDetails);
  const getPayeeTypeDetailsAPI = (payee) => dispatch(getPayeeIdTypeCodeAction(payee));
  const payeeTypeDetailsFrmState = useSelector(state => state.financialEntityState.payeeTypeDetails);
  const getPayeeSearchAPI = (searchObj) => dispatch(SearchPayeeAction(removeLeadingTrailingSpaces(searchObj)));
  let payeeSearchResult = useSelector(state => state.financialEntityState.searchPayeeDetails);
  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  const dropdown = useSelector(state => state.financialEntityState.dropdownData);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [payeeIDTypeDropdown, setPayeeIDTypeDropdown] = React.useState({
    memberDropdown: [],
    otherDropdown: [],
    providerDropdown: [],
    tplDropdown: []
  });  
  useEffect(() => {
    payeeSearchResult = null
    setspinnerLoader(true);
    setSpinLoadForPayPaymSts(true);
    getDropdownData(dropdownCriteria);
    searchSystemParamForFinEntity(searchSystemParameterActionData)
    setSearchTableData([]);
    dispatch(ResetUpdateResponseData());
    setspinnerLoader(true);
    onDropdowns([
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
    ]);
  }, []);
  useEffect(()=>{
    if(systemParamaterPayload){
      setShowPayeePaymentStatus(systemParamaterPayload[0]?.systemParameterDetail[0]?.valueData)
      setSpinLoadForPayPaymSts(false);
    }
  },[systemParamaterPayload])
  //payeeSearchResult
  useEffect(() => {
    if (props && props.location && props.location.state && props.location.state.payeeSearchResult && props.location.state.payeeSearchResult[0] && payeeSearchResult) {
      const recordFromAddEntity = props.location.state.payeeSearchResult[0];
      setValues({
        ...values,
        payeeType: recordFromAddEntity.commonEntityTypeCode ? recordFromAddEntity.commonEntityTypeCode : DEFAULT_DD_VALUE,
        payeeIDTypeCode: recordFromAddEntity.entityIDTypeCode ? recordFromAddEntity.entityIDTypeCode : DEFAULT_DD_VALUE,
        payeeID: recordFromAddEntity.entityID ? recordFromAddEntity.entityID : '',
        payeeName: recordFromAddEntity.entityName ? recordFromAddEntity.entityName : ''
      });
    }
    if (props && props.location && props.location.state && props.location.state.searchCriteria) {
      const recordFromAddEntity = props.location.state.searchCriteria;
      setValues({
        ...values,
        payeeType: recordFromAddEntity.commonEntityTypeCode ? recordFromAddEntity.commonEntityTypeCode : DEFAULT_DD_VALUE,
        payeeIDTypeCode: recordFromAddEntity.entityIDTypeCode ? recordFromAddEntity.entityIDTypeCode : DEFAULT_DD_VALUE,
        payeeID: recordFromAddEntity.entityID ? recordFromAddEntity.entityID : '',
        payeeName: recordFromAddEntity.payeeName ? recordFromAddEntity.payeeName : ''
      });
      setspinnerLoader(true);
    }
    payeeTypecodeSwitch(values.payeeType);
  }, [props && props.location && props.location.state, payeeIDTypeDropdown]);

  // DropdownFilter starts
  const dropDownFilter = (dropDownType, value) => {
    let TypeDropdownData = [];
    switch (dropDownType) {
      case 'payeeType':
        TypeDropdownData = payeeTypeDataFrmState;
        break;
      case 'payeeIDTypeCode':
        TypeDropdownData = descriptionDropdownValues;
        break;
    }
    if (TypeDropdownData) {
      const filteredValue = TypeDropdownData.filter((dropdownTypeValue, index) => dropdownTypeValue.code === value);
      if (filteredValue && filteredValue.length > 0) {
        return filteredValue[0].description;
      }
      return value;
    }
  };
  const filterSearchData = (filteredData) => {
    if (filteredData && filteredData.length > 0) {
      filteredData.map((data, index) => {
        data.commonEntityTypeCodeDesc = dropDownFilter('payeeType', data.commonEntityTypeCode);
        data.entityIDTypeCodeDesc = dropDownFilter('payeeIDTypeCode', data.entityIDTypeCode);
        if (data.sortName === 'null, null') {
          data.sortName = '';
        }
      });
    }
    setSearchTableData(filteredData);
  };
  // DropdownFilter ends

  useEffect(() => {
    const tempErrorArray = [];
    if (payeeSearchResult && payeeSearchResult.systemFailue) {
      tempErrorArray.push(FinancialEntityConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(tempErrorArray);
      setShowTable(false);
      setSearchTableData([]);
      setspinnerLoader(false);
    }
    if (payeeSearchResult && payeeSearchResult.length === 0) {
      tempErrorArray.push(FinancialEntityConstants.NORECORDS_FOUND_FOR_SEARCH_CRITERIA);
      seterrorMessages(tempErrorArray);
      setShowTable(false);
      setSearchTableData([]);
      setspinnerLoader(false);
    }
    if (payeeSearchResult && payeeSearchResult.length === 1) {
      if (isSearch) {
        props.history.push({
          pathname: '/FinancialEntityUpdate',
          state: { searchSingleFinancialEntityRes: payeeSearchResult[0], values: values }
        });
      }else if (cancelRender !== 'true') {
        props.history.push({
          pathname: '/FinancialEntityUpdate',
          state: { searchSingleFinancialEntityRes: payeeSearchResult[0], values: values }
        });
      }
       else {
        setSearchTableData(payeeSearchResult);
      }
    }

    if (payeeSearchResult && payeeSearchResult.length > 0) {
      setShowTable(true);
      payeeSearchResult.map((value, index) => {
        if (value.entityName === 'null') {
          value.entityName = '';
        }
        if (value.sortName === 'null, null') {
          value.sortName = '';
        }
      });
      filterSearchData(payeeSearchResult);
      setSearchTableData(payeeSearchResult);
    }
  }, [payeeSearchResult]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeDataFrmState(dropdown.listObj['General#6002']);
      }
      const payeeIDTypeDropdownTemp = { memberDropdown: [], otherDropdown: [], providerDropdown: [], tplDropdown: [] };
      if (dropdown.listObj['Financial#1023']) {
        setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, tplDropdown: dropdown.listObj['Financial#1023'] });
        payeeIDTypeDropdownTemp.tplDropdown = dropdown.listObj['Financial#1023'];
      }
      if (dropdown.listObj['Financial#1024']) {
        setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, otherDropdown: dropdown.listObj['Financial#1024'] });
        payeeIDTypeDropdownTemp.otherDropdown = dropdown.listObj['Financial#1024'];
      }
      if (dropdown.listObj['Financial#2']) {
        setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, memberDropdown: dropdown.listObj['Financial#2'] });
        payeeIDTypeDropdownTemp.memberDropdown = dropdown.listObj['Financial#2'];
      }
      if (dropdown.listObj['Financial#3']) {
        setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, providerDropdown: dropdown.listObj['Financial#3'] });
        payeeIDTypeDropdownTemp.providerDropdown = dropdown.listObj['Financial#3'];
      }
      setPayeeIDTypeDropdown({ ...payeeIDTypeDropdown, ...payeeIDTypeDropdownTemp });
      if (dropdown && dropdown.listObj && dropdown.listObj['Financial#3'] && dropdown.listObj['Financial#2'] && dropdown.listObj['Financial#1023'] && dropdown.listObj['Financial#1024']) {
        const mergedropdowns = [];
        dropdown.listObj['Financial#3'].map((row, index) => {
          mergedropdowns.push(row);
        });
        dropdown.listObj['Financial#2'].map((row, index) => {
          mergedropdowns.push(row);
        });
        dropdown.listObj['Financial#1023'].map((row, index) => {
          mergedropdowns.push(row);
        });
        dropdown.listObj['Financial#1024'].map((row, index) => {
          mergedropdowns.push(row);
        });
        if (mergedropdowns && mergedropdowns.length > 0) {
          setDropdownDescriptionValues(mergedropdowns);
        }
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeePaymentStatusFrmState(dropdown.listObj['Financial#6023'])
      }
    }
  }, [dropdown]);

  useEffect(() => {
    setspinnerLoader(false);
    const tempErrorArray = [];
    if (payeeTypeDetailsFrmState && payeeTypeDetailsFrmState.systemFailue) {
      tempErrorArray.push(FinancialEntityConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(tempErrorArray);
    }
    if (payeeTypeDetailsFrmState && payeeTypeDetailsFrmState.payeeIdTypeCode && payeeTypeDetailsFrmState.payeeIdTypeCode.length > 0) {
      setPayeeIDTYpeFrmState(payeeTypeDetailsFrmState.payeeIdTypeCode);
    }
  }, [payeeTypeDetailsFrmState]);

  const payeeTypecodeSwitch = payeeType => {
    if (payeeIDTypeDropdown) {
      switch (payeeType) {
        case 'M':
          if (payeeIDTypeDropdown.memberDropdown) { setPayeeIDTYpeFrmState(payeeIDTypeDropdown.memberDropdown); }
          break;
        case 'O':
          if (payeeIDTypeDropdown.otherDropdown) { setPayeeIDTYpeFrmState(payeeIDTypeDropdown.otherDropdown); }
          break;
        case 'P':
          if (payeeIDTypeDropdown.providerDropdown) { setPayeeIDTYpeFrmState(payeeIDTypeDropdown.providerDropdown); }
          break;
        case 'TC':
          if (payeeIDTypeDropdown.tplDropdown) { setPayeeIDTYpeFrmState(payeeIDTypeDropdown.tplDropdown); }
          break;
        default:
          setPayeeIDTYpeFrmState([]);
      }
    }
  };
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    if (name === 'payeeType' && event.target.value !== DEFAULT_DD_VALUE) {
      const payeeType = event.target.value;
      setValues({
        payeeType: event.target.value,
        payeeIDTypeCode: DEFAULT_DD_VALUE,
        payeePaymentStatus: DEFAULT_DD_VALUE,
        payeeID: values.payeeID,
        payeeName: values.payeeName,
        payeeNameStartsWithOrContains: values.payeeNameStartsWithOrContains
      });
      payeeTypecodeSwitch(payeeType);
    }
    if (name === 'payeeType' && event.target.value === DEFAULT_DD_VALUE) {
      setPayeeIDTYpeFrmState([]);
      setValues({
        payeeType: DEFAULT_DD_VALUE,
        payeeIDTypeCode: DEFAULT_DD_VALUE,
        payeePaymentStatus: DEFAULT_DD_VALUE,
        payeeID: values.payeeID,
        payeeName: values.payeeName,
        payeeNameStartsWithOrContains: values.payeeNameStartsWithOrContains
      });
    }
  };
  const [{ showPayeeError, showPayeeIdTypeCode, showPayeeIdError, showPayeeNameError }, setShowError] = React.useState(false);
  const validateSearch = () => {
    setErrorMessage({
      payeeIDErrorMsg: '',
      payeeNameErrorMsg: ''
    });
    const errorMsgArray = [];
    const showPayeeIdTypeCode = false;
    let showPayeeIdError = false;
    let showPayeeError = false;
    let showPayeeNameError = false;
    let payeeIDErrorMsg = '';
    let payeeNameErrorMsg = '';
    let error = true;
    if (values.payeeIDTypeCode !== DEFAULT_DD_VALUE && values.payeeType === DEFAULT_DD_VALUE) {
      showPayeeError = true;
      errorMsgArray.push(FinancialEntityConstants.PAYEEIDTYPECODE_REQUIRED);
      error = false;
    }
    if (values.payeeType !== DEFAULT_DD_VALUE && values.payeeID === '' && values.payeeName === '') {
      showPayeeNameError = true;
      showPayeeIdError = true;
      errorMsgArray.push(FinancialEntityConstants.PAYEEID_ID_NAME);
      error = false;
      payeeIDErrorMsg = FinancialEntityConstants.PAYEEID_ID_NAME;
      payeeNameErrorMsg = FinancialEntityConstants.PAYEEID_ID_NAME;
    }
    if (values.payeeNameStartsWithOrContains) {
      if (values.payeeName.length < 2) {
        showPayeeNameError = true;
        errorMsgArray.push(FinancialEntityConstants.PAYEENAME_ERROR);
        payeeNameErrorMsg = FinancialEntityConstants.PAYEENAME_ERROR;
        error = false;
      }
    }
    setShowError({
      showPayeeError: showPayeeError,
      showPayeeIdTypeCode: showPayeeIdTypeCode,
      showPayeeIdError: showPayeeIdError,
      showPayeeNameError: showPayeeNameError
    });

    setErrorMessage({
      payeeIDErrorMsg: payeeIDErrorMsg,
      payeeNameErrorMsg: payeeNameErrorMsg
    });
    seterrorMessages(errorMsgArray);
    return error;
  };
  const financialPayeeSearch = () => {
    const loginUserDetails = JSON.parse(localStorage.getItem('loginState'));
    setIsSearch(true);
    sessionStorage.clear();
    if (validateSearch()) {
      setspinnerLoader(true);
      seterrorMessages([]);
      const tempSearchObj = {
        commonEntityTypeCode: values.payeeType,
        entityIDTypeCode: values.payeeIDTypeCode,
        payeePaymentStatus: values.payeePaymentStatus,
        entityID: values.payeeID.trim(),
        payeeNameStartsWithOrContains: values.payeeNameStartsWithOrContains?.trim() === 'StartsWith' ? '0' : values.payeeNameStartsWithOrContains?.trim() === 'Contains' ? '1' : null,
        payeeName: values.payeeName.trim(),
        userId: loginUserDetails.logInUser,
        userLastName: loginUserDetails.loginUserName
      };

      if (values.payeeType === DEFAULT_DD_VALUE) { tempSearchObj.commonEntityTypeCode = null; }
      if (values.payeeIDTypeCode === DEFAULT_DD_VALUE) { tempSearchObj.entityIDTypeCode = null; }
      if (values.payeePaymentStatus === DEFAULT_DD_VALUE) { tempSearchObj.payeePaymentStatus = null; }
      if (values.payeeID === '') { tempSearchObj.entityID = null; }
      if (values.payeeName === '') { tempSearchObj.payeeName = null; }
      if (values.payeeNameStartsWithOrContains === '') { tempSearchObj.payeeNameStartsWithOrContains = null; }
      getPayeeSearchAPI(tempSearchObj);
      if(!props.history.state){
        props.history.state = {}
      }
      props.history.state.values = tempSearchObj
      setShowTable(false);
    }
  };
  const setValuesFromFinEntityData = props?.history?.state?.values;

  useEffect(() => {
    if (tableRender === 'true' && !isSearch) {
     const entityValuesFromstore = sessionStorage.getItem('ValuesFromFinEntity');
     let entityValuesObj = entityValuesFromstore && entityValuesFromstore !== "null" || "undefined" ? JSON.parse(entityValuesFromstore) : null;
     const setValuesFromFinEntity = setValuesFromFinEntityData || (entityValuesFromstore != "undefined" && entityValuesFromstore !== null ?
     entityValuesObj : null);
     if(!setValuesFromFinEntity && entityValuesObj){
      entityValuesObj = {
        ...entityValuesObj,
        commonEntityTypeCode : entityValuesObj.payeeType,
        entityIDTypeCode : entityValuesObj.payeeIDTypeCode,
        entityID : entityValuesObj.payeeID,
        payeeNameStartsWithOrContains : entityValuesObj.payeeNameStartsWithOrContains== 'StartsWith' ? 0 : entityValuesObj.payeeNameStartsWithOrContains == 'Contains' ? 1 : null,
        payeeName : entityValuesObj.payeeName,
        payeePaymentStatus : entityValuesObj.payeePaymentStatus,
      }
     }
     
      if (setValuesFromFinEntity !== undefined && setValuesFromFinEntity !== null) {
        setspinnerLoader(true);
        seterrorMessages([]);
        const tempSearchObj = {
          commonEntityTypeCode: setValuesFromFinEntity.commonEntityTypeCode,
          entityIDTypeCode: setValuesFromFinEntity.entityIDTypeCode,
          entityID: setValuesFromFinEntity.entityID,
          payeeNameStartsWithOrContains: setValuesFromFinEntity?.payeeNameStartsWithOrContains,
          payeeName: setValuesFromFinEntity.payeeName,
          payeePaymentStatus : setValuesFromFinEntity.payeePaymentStatus,
        };
        if (setValuesFromFinEntity.commonEntityTypeCode === DEFAULT_DD_VALUE) { tempSearchObj.commonEntityTypeCode = null; }
        if (setValuesFromFinEntity.entityIDTypeCode === DEFAULT_DD_VALUE) { tempSearchObj.entityIDTypeCode = null; }
        if (setValuesFromFinEntity.entityID === '') { tempSearchObj.entityID = null; }
        if (setValuesFromFinEntity.payeeName === '') { tempSearchObj.payeeName = null; }
        if (setValuesFromFinEntity.payeeNameStartsWithOrContains === '') { tempSearchObj.payeeNameStartsWithOrContains = null; }
        if (setValuesFromFinEntity.payeePaymentStatus === '') { tempSearchObj.payeePaymentStatus = null; }
        getPayeeSearchAPI(tempSearchObj);
        sessionStorage.setItem('tableRender', false);
        setValues({
          payeeType: setValuesFromFinEntity.commonEntityTypeCode || DEFAULT_DD_VALUE,
          payeeIDTypeCode: setValuesFromFinEntity.entityIDTypeCode || DEFAULT_DD_VALUE,
          payeeID: setValuesFromFinEntity.entityID || '',
          payeeName: setValuesFromFinEntity.payeeName || '',
          payeeNameStartsWithOrContains: setValuesFromFinEntity.payeeNameStartsWithOrContains == 0 ? 'StartsWith' : setValuesFromFinEntity.payeeNameStartsWithOrContains == 1 ? 'Contains' : null,
          payeePaymentStatus: setValuesFromFinEntity.payeePaymentStatus || DEFAULT_DD_VALUE,
          });
        setIsSearch(false);
      }
    }
  }, [tableRender]);

  const financialPayeeSearchReset = () => {
    sessionStorage.clear();
    setShowTable(false);
    setPayeeIDTYpeFrmState([]);
    seterrorMessages([]);
    setValues({
      payeeType: DEFAULT_DD_VALUE,
      payeeIDTypeCode: DEFAULT_DD_VALUE,
      payeePaymentStatus: DEFAULT_DD_VALUE,
      payeeID: '',
      payeeName: '',
      payeeNameStartsWithOrContains: ''
    });
    setShowError({
      showPayeeIdTypeCode: false,
      showPayeeIdError: false
    });
  };

  const addFinEntity = () => {
    props.history.push({
      pathname: '/FinancialEntityAdd'
    });
  };

  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}
      {spinLoadForPayPaymSts ? <Spinner /> : null}
      <ErrorMessages errorMessages={errorMessages} />
      <div className="tabs-container" ref={toPrintRef}>
        <div className="tab-header">
          <div className="tab-heading float-left">
            Search Financial Payee
          </div>
          <div className="float-right mt-1 pt-1">
            <Button
              title="Add Financial Entity"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addFinEntity()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="MuiExpansionPanel-root">
          <div className="MuiCollapse-container MuiCollapse-entered">
            <div className="MuiExpansionPanelDetails-root">
              {/* form goes here */}
              <div className="tab-body">
                <div className="form-wrapper">
                  <NativeDropDown
                    id="standard-select-payeeType"
                      value={values.payeeType}
                      label="Payee Type"
                      name='payeeType'
                      onChange={handleChange('payeeType')}
                      SelectProps={{
                        MenuProps: {}
                      }}
                      placeholder=""
                    errTxt={showPayeeError ? FinancialEntityConstants.PAYEETYPE_REQUIRED : null}
                      InputLabelProps={{
                        shrink: true
                      }}
                    dropdownList={payeeTypeDataFrmState.map(option => (
                      <option key={option.code} value={option.code}>
                          {option.description}
                      </option>
                      ))}
                  />
                  <NativeDropDown
                    id="standard-select-payeeIDTypeCode"
                      value={values.payeeIDTypeCode}
                    disabled={values.payeeType === DEFAULT_DD_VALUE}
                      label="Payee ID Type Code"
                      name='payeeIDTypeCode'
                      onChange={handleChange('payeeIDTypeCode')}
                      SelectProps={{
                        MenuProps: {}
                      }}
                      placeholder=""
                    errTxt={showPayeeIdTypeCode ? FinancialEntityConstants.PAYEEIDTYPECODE_REQUIRED : null}
                      InputLabelProps={{
                        shrink: true
                      }}
                    dropdownList={payeeIDTypeFrmState.map(option => (
                      <option key={option.code} value={option.code}>
                          {option.description}
                      </option>
                      ))}
                  />
                  <div className="mui-custom-form input-md">
                    <TextField
                      id="PayeeID"
                      fullWidth
                      label="Payee ID"
                      type="string"
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ maxLength: 15 }}
                      helperText={showPayeeIdError ? erroMessage.payeeIDErrorMsg : null}
                      error={showPayeeIdError ? erroMessage.payeeIDErrorMsg : null}
                      value={values.payeeID}
                      onChange={handleChange('payeeID')}
                    />
                  </div>
                  <div className="mui-custom-form override-width-22 override-m-1" >
                    <TextField
                      id="standard-select-PayeeName"
                      fullWidth
                      value={values.payeeName}
                      label="Payee Name"
                      name='payeeName'
                      helperText={showPayeeNameError ? erroMessage.payeeNameErrorMsg : null}
                      error={showPayeeNameError ? erroMessage.payeeNameErrorMsg : null}
                      onChange={handleChange('payeeName')}
                      inputProps={{ maxLength: 60 }}
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                    >
                    </TextField>
                    <div className="sub-radio">
                      <Radio
                        value="StartsWith"
                        id="payeeNameStarts"
                        checked={values.payeeNameStartsWithOrContains === 'StartsWith'}
                        inputProps={{ maxLength: 1 }}
                        onChange={handleChange('payeeNameStartsWithOrContains')}
                      /><label className="text-black" for="payeeNameStarts">Starts With</label>
                      <Radio
                        value="Contains"
                        id="payeeNameContains"
                        inputProps={{ maxLength: 1 }}
                        checked={values.payeeNameStartsWithOrContains === 'Contains'}
                        onChange={handleChange('payeeNameStartsWithOrContains')}
                        className="ml-2"
                      /><label for="payeeNameContains" className="text-black">Contains</label>
                    </div>
                  </div>
                  {showPayeePaymentStatus == 'Y' ? (
                    <NativeDropDown
                      id="standard-select-payeePaymentStatus"
                        value={values.payeePaymentStatus}
                        label="Payee Payment Status"
                        name='payeePaymentStatus'
                        onChange={handleChange('payeePaymentStatus')}
                        SelectProps={{
                          MenuProps: {}
                        }}
                        placeholder=""
                        InputLabelProps={{
                          shrink: true
                        }}
                      dropdownList={payeePaymentStatusFrmState.map(option => (
                        <option key={option.code} value={option.code}>
                            {option.description}
                        </option>
                        ))}
                    />
                  ) : null}
                </div>
                <div className="float-right mr-3 mb-2 pt-1">
                  <Button variant="outlined" color="primary" className='btn btn-primary ml-1'
                    onClick={() => { financialPayeeSearch(); }}
                  >
                    <i class="fa fa-search" aria-hidden="true"></i>
                    Search
                  </Button>
                  <Button variant="outlined" color="primary" className='bt-reset btn-transparent  ml-1'
                    onClick={() => { financialPayeeSearchReset(); }}
                  >
                    <i class="fa fa-undo" aria-hidden="true"></i>
                    Reset
                  </Button>
                </div>

                <div className="clearfix"></div>
                <div>
                  {
                    showTable
                      ? <div className="tab-holder pb-1">
                        <FinancialEntitySearchTable setspinnerLoader={setspinnerLoader} tableData={searchtableData.keySort({ commonEntityTypeCodeDesc: 'asc', entityIDTypeCodeDesc: 'asc', entityID: 'asc' })} values={ values} dropdowns = {addDropdowns}/>
                      </div>
                      : null
                  }
                </div>

              </div>
            </div>
            <Footer print />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(NavHOC({
  Wrapped: FinancialEntitySearch,
  action: SearchPayeeAction,
  url: '/FinancialEntitySearch',
  actionKey: 'payeeSearchResult',
  selector: 'financialEntityState.searchPayeeDetails'
}));
