/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import * as AppConstants from '../../../SharedModules/AppConstants';
import TableComponent from '../../../SharedModules/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchFiscalPendCriteriaAction,
  getFiscalPendRecordAction
} from '../Store/Actions/FiscalPendCriteriaSearchActions';
import * as ErrorConstants from './ErrorConstants';

// implement table component
const headCells = [
  {
    id: 'id',
    disablePadding: false,
    label: 'Fiscal Pend ID',
    enableHyperLink: true,
    width: '12%'
  },
  {
    id: 'description',
    disablePadding: false,
    label: 'Description',
    enableHyperLink: false,
    width: '20%'
  },
  {
    id: 'fundCodeDesc',
    disablePadding: false,
    label: 'Fund Code',
    enableHyperLink: false,
    width: '15%',
    isTooltip: true,
    isTitle: 'fundCodeLongDesc',
  },
  {
    id: 'payCycBegDate',
    disablePadding: false,
    label: 'Paid Date Begin Date',
    isDate: true,
    enableHyperLink: false,
    width: '14%'
  },
  {
    id: 'payCycEndDate',
    disablePadding: false,
    label: 'Paid Date End Date',
    isDate: true,
    enableHyperLink: false,
    width: '13%'
  },
  {
    id: 'financialTxnIndicator',
    disablePadding: false,
    label: 'Include FT',
    isBoolean: true,
    enableHyperLink: false,
    width: '10%'
  },
  {
    id: 'rank',
    disablePadding: false,
    label: 'Rank',
    enableHyperLink: false,
    width: '8%'
  },
  {
    id: 'voidDate',
    disablePadding: false,
    label: 'Void',
    enableHyperLink: false,
    width: '10%'
  }
];

// This component is used to display a table for fiscal pend search results
function FiscalPendSearchTable(props) {
  const [redirect, setRedirect] = React.useState(0);
  const dispatch = useDispatch();
  const fiscalPendRecord = useSelector(
    state => state.fiscalPendSearchState.searchRecord
  );
  const redirectToEdit = values => dispatch(getFiscalPendRecordAction(values));

  /* On click of table row call API to fetch fiscal pend data */
  const editRow = row => event => {
    props.setSpinnerLoader(true);
    redirectToEdit('/' + row.id + '/' + AppConstants.DEFAULT_LOB_VALUE);
  };

  // redirect to edit in case of multiple record
  useEffect(() => {
    if (fiscalPendRecord) {
      if (fiscalPendRecord.systemFailure) {
        props.tableErrors([ErrorConstants.SYSTEMERROR]);
      } else {
        props.history.push({
          pathname: '/FiscalPendCriteriaEdit',
          state: { searchResult: fiscalPendRecord, values: props.values }
        });
      }
    }
  }, [fiscalPendRecord]);
  const formatSearchCriteria = (row) => {
    return ('/' + row.id + '/' + AppConstants.DEFAULT_LOB_VALUE);
  }

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const fundCode =
            props.dropdowns &&
            props.dropdowns['R1#R_FUND_CD'] &&
            props.dropdowns['R1#R_FUND_CD'].filter(
              value => value.code === each.fundCode
            );
          each.fundCodeDesc =
            fundCode && fundCode.length > 0
              ? fundCode[0].description
              : each.fundCode ? each.fundCode : '';  
                  
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <TableComponent
      fixedTable
      pathTo='/FiscalPendCriteriaEdit?data='
      formatSearchCriteria={formatSearchCriteria}
      isSearch={true}
      headCells={headCells}
      tableData={getTableData(props.tableData ? props.tableData : [])}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
      order={'desc'}
    />
  );
}
export default withRouter((FiscalPendSearchTable));
