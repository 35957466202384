/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import Spinner from "../../SharedModules/Spinner/Spinner";
import * as AppConstants from "../../SharedModules/AppConstants";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ErrorMessages from "../../SharedModules/Errors/ErrorMessages";
import moment from "moment";
import TableComponent from "../../SharedModules/Table/Table";
import Footer from "../../SharedModules/Layout/footer";
import { searchWaiverction, ResetWVR } from './Store/Actions/WaiverActions';
import * as WaiverConstants from './WaiverConstants';

function WaiverLineSearch(props) {
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [waiverState, setWaiverState] = useState({
    WvrLiCd: "",
    WvrCd: "",
    WvrName: "",
    CMS64TOSCd: ""
  });
  const [waiverTable, setWaiverTable] = useState([]);
  const searchWaiver = (values) => dispatch(searchWaiverction(values));
  let searchSuccess = null
  searchSuccess = useSelector(state => state.waiverState.searchPayload);


  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };

  const handleChange = (name) => (event) => {
    setWaiverState({
      ...waiverState,
      [name]: event.target.value,
    });
  };

  const waiverSearchAction = () => {
    setspinnerLoader(true);

    let searchWvrOb = {
      "wvrLineCd": waiverState.WvrLiCd,
      "wvrLineCdDescStartsOrContains": 0,
      "cms64TosCd": waiverState.CMS64TOSCd,
      "cms64TosDescriptionStartsOrContains": 0,
      "begWvrCd": waiverState.WvrCd,
      "waiverName": waiverState.WvrName
    };
    searchWaiver(searchWvrOb);
  };
  const waiverReset = () => {
    setWaiverState({
      WvrLiCd: "",
      WvrCd: "",
      WvrName: "",
      CMS64TOSCd: ""
    });
    setWaiverTable([]);
    setShowTable(false);
    seterrorMessages([]);
  };

  const headCells = [
    {
      id: "wvrLineCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: true,
      label: "Waiver Line Code",
      isDate: false,
      width: "10%",
    },
    {
      id: "waiverName",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Waiver Name",
      width: "13%",
    },
    {
      id: "priorityNumber",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Priority Number",
      isDate: false,
      width: "10%",
    },
    {
      id: "begWvrCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Begin Waiver Code",
      isDate: false,
      width: "11%",
    },
    {
      id: "endWvrCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "End Waiver Code",
      isDate: false,
      width: "11%",
    },
    {
      id: "cms64TosCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "CMS64 TOS",
      isDate: false,
      width: "11%",
    },

    {
      id: "begProcCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Begin Procedure Code",
      isDate: false,
      width: "13%",
    },

    {
      id: "endProcCd",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "End Procedure Code",
      isDate: false,
      width: "13%",
    },
    {
      id: "procModCd1",
      numeric: false,
      disablePadding: false,
      enableHyperLink: false,
      label: "Procedure Modifier Code",
      isDate: false,
      width: "13%",
    },

  ];
  const formatSearchCriteria = (row) => {
    return {};
  };
  const editRow = (row) => (event) => {
    let searchWaiverOb = {
      wvrSk: row.waiverLookUpSeqNo,
    };
    searchWaiver(searchWaiverOb);
  };

  useEffect(() => {
    searchSuccess = null;
    ResetWVR('searchPayload');
  }, []);

  useEffect(() => {
    setspinnerLoader(false);
    if (searchSuccess && !searchSuccess.systemFailure) {
      seterrorMessages([])
    }
    if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length > 1) {
      setShowTable(true);
      setWaiverTable(searchSuccess)
      seterrorMessages([])
    }
    if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 1) {
      let searchData = searchSuccess;
      props.history.push({
        pathname: '/WaiverUpdate',
        state: { searchData: searchData, editPage: true, fromSearch: true }
      });
    }
    else if (searchSuccess && searchSuccess.systemFailure) {
      let ErrorMessages = [];
      ErrorMessages.push(WaiverConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(ErrorMessages);
    }
    else if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 0) {
      let ErrorMessages = [];
      ErrorMessages.push(WaiverConstants.NO_RECORDS_FOUND);
      seterrorMessages(ErrorMessages);
      setWaiverTable([]);
      setShowTable(false);
    }
  }, [searchSuccess]);


  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <h1 className="tab-heading float-left">Search Waiver Line Code</h1>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body" style={{ overflow: "hidden" }}>
          <form autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form with-select input-md">
                <TextField
                  id="tosLine"
                  fullWidth
                  label="Waiver Line Code"
                  onChange={handleChange("WvrLiCd")}
                  value={waiverState.WvrLiCd}
                  placeholder=""
                  inputProps={{ maxlength: 10 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  id="tosDescription"
                  fullWidth
                  label="Waiver Code"
                  onChange={handleChange("WvrCd")}
                  value={waiverState.WvrCd}
                  placeholder=""
                  inputProps={{ maxlength: 150 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  id="priorityNumber"
                  fullWidth
                  label="Waiver Name"
                  onChange={handleChange("WvrName")}
                  value={waiverState.WvrName}
                  placeholder=""
                  inputProps={{ maxlength: 150 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form with-select input-md">
                <TextField
                  id="CMS64TOSCd"
                  fullWidth
                  label="CMS64 TOS Code"
                  onChange={handleChange("CMS64TOSCd")}
                  value={waiverState.CMS64TOSCd}
                  placeholder=""
                  inputProps={{ maxlength: 8 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </div>
            </div>
          </form>

          <div className="float-right mr-4 mb-3">
            <Button
              variant="outlined"
              color="primary"
              className="btn btn-primary ml-1"
              onClick={() => {
                waiverSearchAction();
              }}
            >
              <i class="fa fa-search" aria-hidden="true"></i>
              Search
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="bt-reset btn-transparent ml-1"
              onClick={() => {
                waiverReset();
              }}
            >
              <i class="fa fa-undo" aria-hidden="true"></i> Reset
            </Button>
          </div>

          <div className="clearfix"></div>

          {showTable ? (
            <div className="tab-holder pb-1 tableNoWrap">
              <TableComponent
                print
                fixedTable
                headCells={headCells}
                formatSearchCriteria={formatSearchCriteria}
                tableData={waiverTable}
                isSearch={true}
                onTableRowClick={editRow}
                defaultSortColumn={headCells[1].id}
                order={"asc"}
              />
            </div>
          ) : null}
          <Footer print />
        </div>
      </div>
    </div>
  );
}

export default withRouter(WaiverLineSearch);
