/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from 'react-router';
import Dialog from '@material-ui/core/Dialog';
import {Button, Form} from 'react-bootstrap';
import moment from "moment";
import {
  generateUUID,
  getUTCTimeStamp
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import TextField from "@material-ui/core/TextField";
import TableApprovalComponent from '../../../../SharedModules/Table/TableApproval';
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";

const headCells = [
  {
    id: 'providerTaxID',
    disablePadding: false,
    label: 'Provider Tax ID',
    enableHyperLink: false,
    width: '11%'
  },
  {
    id: 'providerID',
    disablePadding: false,
    label: 'Provider ID',
    enableHyperLink: true,
    width: '11%'
  },
  {
    id: 'ssn',
    disablePadding: false,
    label: 'SSN',
    enableHyperLink: false,
    width: '9%'
  },
  {
    id: 'taxYear',
    disablePadding: false,
    label: '1099 Tax Year',
    enableHyperLink: false,
    width: '9%'
  },
  {
    id: 'original1099Amount',
    disablePadding: false,
    label: 'Original 1099 Amount',
    enableHyperLink: false,
    width: '11%',
    isBalance: true
  },
  {
    id: 'adjusted1099Amount',
    disablePadding: false,
    label: 'Adjusted 1099 Amount',
    enableHyperLink: false,
    width: '11%',
    isBalance: true
  },
  {
    id: 'reasonCodeDesc',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    width: '14%',
    isTooltip: true,
    isTitle: 'reasonCodeLongDesc',
  },
  {
    id: 'creatorUserID',
    disablePadding: false,
    label: 'Creator User ID',
    enableHyperLink: false,
    width: '10%'
  },
  {
    id: 'approvalIndicator',
    disablePadding: false,
    label: <i class="fa fa-check" id="correct-icon" aria-hidden="true"><span hidden>Approved</span></i>,
    enableHyperLink: false,
    width: '6%',
    isApproval: true
  },
  {
    id: 'deleteIndicator',
    disablePadding: false,
    label: (
      <i
        class="fa fa-close"
        id="close-icon"
        aria-hidden="true"
        style={{ marginLeft: '0px' }}
      ><span hidden>Delete</span></i>
    ),
    enableHyperLink: false,
    width: '6%',
    isDelete: true
  },
  {
    id: 'notesIcon',
    disablePadding: false,
    label: 'Notes',
    enableHyperLink: false,
    width: '10%',
    isIcon: true
  },
];
function CorrectionApprovalSearchTable(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();
  const [charactersRemBpText, setCharactersRemBpText] = useState(320);
  const [open, setOpen]=useState(false);
  const [{ usageTypeError, textError }, setShowError] = React.useState(false);
  const [
    { usageTypeErrorText, textErrorText },
    setShowErrorText
  ] = React.useState("");
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: props.logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: props.logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: [],
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: props.logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: props.logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: props.logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });
 
  const mapCodeDescription = (_code, _mapArray) => {
    if (_mapArray && _mapArray.length > 0) {
      let _description = [];
      _description = _mapArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };
  const handleChangeValue = name => event => {
    setNoteSetListInput({
      ...noteSetListInput,
      [name]: event.target.value,
      strBeginDate: noteSetListInput.addedAuditTimeStamp
        ? moment(noteSetListInput.addedAuditTimeStamp).format(
            "MM/DD/YYYY HH:mm:ss"
          )
        : ''
    });
    if (name === "usageTypeCode") {
      setNoteSetListInput({
        ...noteSetListInput,
        [name]: event.target.value,
        usageTypeDesc: mapCodeDescription(
          event.target.value,
          props.usageTypeCodeData
        ),
        strBeginDate: moment(noteSetListInput.auditTimeStamp).format(
          "MM/DD/YYYY HH:mm:ss"
        )
      });
    };
  };

  const editRow = row => event => {
    if(row.notesIcon === event.target?.id){
      setOpen(true);
    }else{
      setOpen(false);
    };
    props.setrowSeqNum(row.claimCorrSeqNum);
    props.editLink(row);
  };
  const handleClose = () => {
    setOpen(false);
    setNoteSetListInput({
      ...noteSetListInput,
      usageTypeCode: DEFAULT_DD_VALUE,
      noteText: ""
    });
    setCharactersRemBpText(320);
    setShowError([]);
    setShowErrorText([]);
  };
  const checkCharacterRem = event => {
    setCharactersRemBpText(320 - event.target.value.length);
  };
  const editLink = row => event => {
    props.editLink(row);
    props.setrowSeqNum(row.claimCorrSeqNum);
  };

  const handleReset = () => {
    setNoteSetListInput({
      ...noteSetListInput,
      usageTypeCode: DEFAULT_DD_VALUE,
      noteText: ""
    });
    setCharactersRemBpText(320);
    setShowError([]);
    setShowErrorText([]);
  };

  const addNotes =(data)=>{
  let notesDataArray=[];
    const noteText = data;
    noteText.auditUserID = props.logInUserID;
    noteText.auditTimeStamp = getUTCTimeStamp();
    noteText.addedAuditUserID = props.logInUserID;
    noteText.addedAuditTimeStamp = getUTCTimeStamp();
    noteText.notesCexAuditUserID = props.logInUserID;
    noteText.notesCexAuditTimeStamp = getUTCTimeStamp();
    noteText.notesCexAddedAuditUserID = props.logInUserID;
    noteText.notesCexAddedAuditTimeStamp = getUTCTimeStamp();
    noteText.userId = props.logInUserID;
    notesDataArray.push(noteText);
    setNotesInput({
      ...notesInput,
      auditUserID: props.logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: notesInput.addedAuditUserID
        ? notesInput.addedAuditUserID
        : props.logInUserID,
      addedAuditTimeStamp: notesInput.addedAuditTimeStamp
        ? notesInput.addedAuditTimeStamp
        : getUTCTimeStamp(),
      notesList: [...notesInput.notesList, ...notesDataArray]
    });
    props.setNotesData({...notesInput,
      auditUserID: props.logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: notesInput.addedAuditUserID
        ? notesInput.addedAuditUserID
        : props.logInUserID,
      addedAuditTimeStamp: notesInput.addedAuditTimeStamp
        ? notesInput.addedAuditTimeStamp
        : getUTCTimeStamp(),
      notesList: [...notesInput.notesList, ...notesDataArray]})
   
  };
  const handleClickOpenDialogueBoxSave = () => {
    const value = noteSetListInput;
    var usageTypeError;
    var textError = false;
    var usageTypeErrorText;
    var textErrorText = "";
    let tempErrorArray = [];
    if (noteSetListInput.usageTypeCode === DEFAULT_DD_VALUE) {
      usageTypeError = true;
      usageTypeErrorText = "Usage Type Code is required.";
      tempErrorArray.push(usageTypeErrorText);
    }
    if (noteSetListInput.noteText === "") {
      textError = true;
      textErrorText = "Text is required.";
      tempErrorArray.push(textErrorText);
    }
    if (!usageTypeError && !textError) {
      value.strBeginDate = moment(new Date()).format("MM/DD/YYYY HH:mm:ss");
      value.auditTimeStamp = getUTCTimeStamp();
      value.addedAuditTimeStamp = getUTCTimeStamp();
      value.auditUserID = props.logInUserID;
      value.addedAuditUserID = props.logInUserID;
      value.noteText = noteSetListInput.noteText
        ? noteSetListInput.noteText.trim()
        : noteSetListInput.noteText;
      addNotes(value);
      handleClose();
    }
    setShowError({
      usageTypeError: usageTypeError,
      textError: textError
    });
    setShowErrorText({
      usageTypeErrorText: usageTypeErrorText,
      textErrorText: textErrorText
    });
  };

  const selectedRowsForApproval = selectedArray => {
    props.selectedArrayRowsApproval(selectedArray);
  };
  const selectedRowsForDelete = selectedArray => {
    props.selectedArrayRowsDelete(selectedArray);
  };

  const getTableData = d => {
    if (d && d.length) {
      let tData = d;
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          const reasonCode =
            props.dropdowns &&
            props.dropdowns['F1#F_RSN_CD'] &&
            props.dropdowns['F1#F_RSN_CD'].filter(
              value => value.code === each.reasonCode
            );
          each.reasonCodeDesc =
          reasonCode && reasonCode.length > 0
              ? reasonCode[0].description
              : '';
        each.notesIcon = (!each.approvalDisableCheck && !each.denyDisableCheck) ? "Image" : null;
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <div>
    <div className='tab-body'>
    <Dialog className="custom-dialog"
      onClose={handleClose}
      open={open}
      disableBackdropClick>
         <div className="tabs-container" id="notes-div">
          <div className="tab-header  mini-tab-header">
            <div className="tab-heading float-left">{"Add New Note"}</div>{" "}
            <div className="float-right mt-1">
              <Button
                className="btn btn-success ml-1"
                title={'Add'}
                onClick={handleClickOpenDialogueBoxSave}
              >
                <i class="fa fa-plus" aria-hidden="true"></i>
                {"Add"}
              </Button>
              <Button
                className="bt-reset btn-transparent  ml-1'"
                onClick={handleReset}
              >
                <i class="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="btn btn-primary btn btn-outlined ml-1"
                onClick={handleClose}
              >
                Cancel
              </Button>
            </div>
          </div>
          <div className="form-border my-3">
            <form autoComplete="off">
              <div className="form-wrapper" style={{ minWidth: "480px" }}>
                  <NativeDropDown
                    id="usageTypeCode"
                    className="override-width-25"
                    isRequired
                    value={noteSetListInput.usageTypeCode}
                    onChange={handleChangeValue("usageTypeCode")}
                    label="Usage Type Code"
                    errTxt={usageTypeError ? usageTypeErrorText : null}
                    InputLabelProps={{
                      shrink: true
                    }}
                    dropdownList={props.usageTypeCodeData
                      ? JSON.parse(
                          JSON.stringify(
                            props.usageTypeCodeData.sort((a, b) =>
                              a.code.toLocaleUpperCase() <
                              b.code.toLocaleUpperCase()
                                ? -1
                                : 1
                            )
                          )
                        ).map(option => (
                          <option key={option.code} value={option.code}>
                            {option.description}
                          </option>
                        ))
                      : null}
                  />
                <div className="mui-custom-form with-textarea override-width-95">
                  <Form.Group>
                    <Form.Label className="MuiFormLabel-root small-label">
                      Text{" "}
                      <span class="Mui-required" id="text-label-01">
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      aria-describedby="text-label-01"
                      aria-label="form-controll-01"
                      as="textarea"
                      rows="4"
                      name="text"
                      value={noteSetListInput.noteText}
                      onChange={handleChangeValue("noteText")}
                      onKeyUp={checkCharacterRem}
                      maxLength={320}
                      style={{ width: "100%" }}
                    />
                    {textError > 0 ? (
                      <p
                        class="MuiFormHelperText-root Mui-error Mui-required"
                        role="alert"
                      >
                        {textErrorText}
                      </p>
                    ) : null}
                  </Form.Group>
                  <div
                    style={{
                      border: "1px solid rgb(169, 169, 169)",
                      borderRadius: ".25rem",
                      paddingLeft: "5px",
                      width: "300px",
                      alignText: "center"
                    }}
                  >
                    Characters Remaining:{charactersRemBpText}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
      </div>
    <TableApprovalComponent
    calledFrom="1099Correction"
      validate={true}
      setSpinnerLoader={props.setSpinnerLoader}
      seterrorMessages={props.seterrorMessages}
      print={props.print}
      approvalArray={props.approvalArray}
      deleteArray={props.deleteArray}
      align={'left'}
      headCells={headCells}
      tableData={getTableData(props.tableData ? props.tableData : [])}
      newSelectedArrayForApproval={selectedRowsForApproval}
      newSelectedArrayForDelete={selectedRowsForDelete}
      onTableRowClick={editRow}
      onLinkClick={editLink}
      defaultSortColumn={headCells[0].id}
      order={'asc'}
      isSearch={true}
    />
      </div>
  );
}
export default withRouter(CorrectionApprovalSearchTable);
