/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from './TextManagementActionTypes';
import * as serviceEndPoint from '../../../services/service';
import { commonAPIHandler } from '../../../../../SharedModules/ServerErrorHandler';
import axios from 'axios';

export const TextManagementDeleteEOMBAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_DELETE_EOMBTEXT}`, value)
      .then(response => {
       let sendingResponse = commonAPIHandler(response);
        dispatch(SetSpinner(false));
        dispatch(TextManagementDeleteEOMB(sendingResponse));
      })
      .catch(error => {
       let sendingResponse = commonAPIHandler(error);
        dispatch(SetSpinner(false));
        dispatch(TextManagementDeleteEOMB(sendingResponse));
      });
  };
};

export const TextManagementDeleteRAEOBAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANGEMENT_DELETE_RAEOB}`, value)
      .then(response => {
        let sendingResponse =  commonAPIHandler(response)
        dispatch(SetSpinner(false));
        dispatch(TextManagementDeleteRAEOB(sendingResponse));
      })
      .catch(error => {
        dispatch(SetSpinner(false));
        let sendingResponse =  commonAPIHandler(error)
        dispatch(TextManagementDeleteRAEOB(sendingResponse));
      });
  };
};

export const TextManagementUpdateEOMBAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_UPDATE_EOMB_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(SetSpinner(false));
        dispatch(TextManagementUpdateEOMB(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(TextManagementUpdateEOMB([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(SetSpinner(false));
        dispatch(TextManagementUpdateEOMB(sendingResponse));
      });
  };
};

export const TextManagementAddEOMBAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_ADD_EOMB_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response)

        if (sendingResponse.respcode !== '01') {
          dispatch(SetSpinner(false));
        }
        dispatch(TextManagementAddEOMB(sendingResponse));
        if (sendingResponse && sendingResponse.respcode === '01') {
          dispatch(EOMBGetRecordAction(
            {
              eombProcedureTypeCode: value.eombType,
              lobCode: [value.lob],
              eombFromCode: value.eombFromCode,
              eombthruCode: value.eombThruCode,
              eombText: value.eombDesc,
              eombTextStartsOrContains: null,
              fromModifier1: value.fromModifier1 ? value.fromModifier1 : null,
              fromModifier2: value.fromModifier2 ? value.fromModifier2 : null,
              fromModifier3: value.fromModifier3 ? value.fromModifier3 : null,
              fromModifier4: value.fromModifier4 ? value.fromModifier4 : null,
              thruModifier1: value.thruModifier1 ? value.thruModifier1 : null,
              thruModifier2: value.thruModifier2 ? value.thruModifier2 : null,
              thruModifier3: value.thruModifier3 ? value.thruModifier3 : null,
              thruModifier4: value.thruModifier4 ? value.thruModifier4 : null
            }
          ));
        }
        if (sendingResponse === undefined) {
          dispatch(TextManagementAddEOMB([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(SetSpinner(false));
        dispatch(TextManagementAddEOMB(sendingResponse));
      });
  };
};

export const TextManagementUpdateProviderNoticeAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_UPDATE_PN_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(SetSpinner(false));
        dispatch(TextManagementUpdateProviderNotice(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(TextManagementUpdateProviderNotice([]));
        }
      })
      .catch(error => {
        dispatch(SetSpinner(false));
        let sendingResponse = commonAPIHandler(error);
        dispatch(TextManagementUpdateProviderNotice(sendingResponse));
      });
  };
};
export const TextManagementAddProviderNoticeAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_ADD_PN_ENDPOINT}`, value)
      .then(response => {
       let sendingResponse = commonAPIHandler(response);
        if (sendingResponse.respcode !== '01') {
          dispatch(SetSpinner(false));
        }
        dispatch(TextManagementAddProviderNotice(sendingResponse));
        if (sendingResponse && sendingResponse.respcode === '01' && sendingResponse.enterpriseBaseVO) {
          dispatch(providerNoticeGetRecordAction(
            {
              providerTextSk: sendingResponse.enterpriseBaseVO.textRALetterSK
            }
          ));
        }
        if (sendingResponse === undefined) {
          dispatch(TextManagementAddProviderNotice([]));
        }
      })
      .catch(error => {
        dispatch(SetSpinner(false));
        let sendingResponse = commonAPIHandler(error);
        dispatch(TextManagementAddProviderNotice(sendingResponse));
      });
  };
};

export const TextManagementUpdateRAEOBAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_UPDATE_RAEOB_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(SetSpinner(false));
        dispatch(TextManagementUpdateRAEOB(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(TextManagementUpdateRAEOB([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(SetSpinner(false));
        dispatch(TextManagementUpdateRAEOB(sendingResponse));
      });
  };
};
export const TextManagementAddRAEOBAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_ADD_RAEOB_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse.respcode !== '01') {
          dispatch(SetSpinner(false));
        }
        dispatch(TextManagementAddRAEOB(sendingResponse));
        if (sendingResponse && sendingResponse.respcode === '01') {
          dispatch(RaeobGetRecordAction(
            {
              eobText: value.eobDesc,
              lobCode: [value.lobCode],
              claimEOBCode: value.claimEOBCode,
              eobTextStartsOrContains: null
            }
          ));
        }
        if (sendingResponse === undefined) {
          dispatch(TextManagementAddRAEOB([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(SetSpinner(false));
        dispatch(TextManagementAddRAEOB(sendingResponse));
      });
  };
};

export const TextManagementUpdateRemarkTextAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_UPDATE_REMARK_TEXT_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(SetSpinner(false));
        dispatch(TextManagementUpdateRemarkText(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(TextManagementUpdateRemarkText([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(SetSpinner(false));
        dispatch(TextManagementUpdateRemarkText(sendingResponse));
      });
  };
};
export const TextManagementAddRemarkTextAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_ADD_REMARK_TEXT_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse.respcode !== '01') {
          dispatch(SetSpinner(false));
        }
        dispatch(TextManagementAddRemarkText(sendingResponse));
        if (sendingResponse && sendingResponse.respcode === '01') {
          dispatch(RemarkTextGetRecordAction(
            {
              remarkCode: value.remarkCode,
              remarkText: value.remarkText,
              remarkTextStartsOrContains: null
            }
          ));
        }
        if (sendingResponse === undefined) {
          dispatch(TextManagementAddRemarkText([]));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(SetSpinner(false));
        dispatch(TextManagementAddRemarkText(sendingResponse));
      });
  };
};

export const TextManagementUpdateAdjustmentReasonAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_UPDATE_ADJUSTMENT_REASON_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        dispatch(SetSpinner(false));
        dispatch(TextManagementUpdateAdjustmentReason(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(TextManagementUpdateAdjustmentReason([]));
        }
      })
      .catch(error => {
        dispatch(SetSpinner(false));
        let sendingResponse = commonAPIHandler(error);
        dispatch(TextManagementUpdateAdjustmentReason(sendingResponse));
      });
  };
};

export const TextManagementAddAdjustmentReasonAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_ADD_ADJUSTMENT_REASON_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        if (sendingResponse.respcode !== '01') {
          dispatch(SetSpinner(false));
        }
        dispatch(TextManagementAddAdjustmentReason(sendingResponse));
        if (sendingResponse && sendingResponse.respcode === '01') {
          dispatch(AdjustmentReasonRecordAction(
            {
              adjustmentReasonCode: value.adjustmentReasonCode,
              adjustmentReasonText: value.adjustmentReasonText,
              adjustmentReasonTextStartsOrContains: null
            }
          ));
        }
        if (sendingResponse === undefined) {
          dispatch(TextManagementAddAdjustmentReason([]));
        }
      })
      .catch(error => {
        dispatch(SetSpinner(false));
        let sendingResponse = commonAPIHandler(error);
        dispatch(TextManagementAddAdjustmentReason(sendingResponse));
      });
  };
};

export const TextManagementUpdateNCPDPAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_UPDATE_NCPDP_ENDPOINT}`, value)
      .then(response => {
        dispatch(SetSpinner(false));
        let sendingResponse = commonAPIHandler(response);
        dispatch(TextManagementUpdateNCPDP(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(TextManagementUpdateNCPDP([]));
        }
      })
      .catch(error => {
        dispatch(SetSpinner(false));
        let sendingResponse = commonAPIHandler(error);
        dispatch(TextManagementUpdateNCPDP(sendingResponse));
      });
  };
};
export const TextManagementAddNCPDPAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_NCPDP_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response);
        let data = [];
        data = response.data;

        if (sendingResponse.respcode !== '01') {
          dispatch(SetSpinner(false));
        }
        dispatch(TextManagementAddNCPDP(sendingResponse));
        dispatch(TextManagementAddNCPDP(data));

        if (sendingResponse && sendingResponse.respcode === '01') {
          dispatch(NCPDPGetRecordAction(
            {
              ncpdpRejectCode: value.ncpdpRejectCode,
              ncpdpRejectCodeText: value.ncpdpRejectCodeText,
              ncpdpRejectCodeStartsOrCopntains: null
            }
          ));
        }
        if (sendingResponse === undefined) {
          dispatch(TextManagementAddNCPDP([]));
        }
      })
      .catch(error => {
        dispatch(SetSpinner(false));
        let sendingResponse = commonAPIHandler(error);
        dispatch(TextManagementAddNCPDP(sendingResponse));
      });
  };
};
export const EOMBGetRecordAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_SP_ENDPOINT}`, value)
      .then(response => {
      let sendingResponse = commonAPIHandler(response, true);

        dispatch(EOMBGetRecord(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(EOMBGetRecord([]));
          dispatch(SetSpinner(false));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(EOMBGetRecord(sendingResponse));
      });
  };
};
export const EOMBGetRecord = (EOMBText) => {
  return {
    type: actionTypes.GET_EOMB_TEXT,
    EOMBText: EOMBText
  };
};
export const RemarkTextGetRecordAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_RC_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(RemarkTextGetRecord(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(RemarkTextGetRecord([]));
          dispatch(SetSpinner(false));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(RemarkTextGetRecord(sendingResponse));
      });
  };
};
export const RemarkTextGetRecord = (RemarkText) => {
  return {
    type: actionTypes.GET_REMARK_TEXT,
    RemarkText: RemarkText
  };
};
export const AdjustmentReasonRecordAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_ARC_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true)
        dispatch(AdjustmentReasonRecord(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(AdjustmentReasonRecord([]));
          dispatch(SetSpinner(false));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true)
        dispatch(AdjustmentReasonRecord(sendingResponse));
      });
  };
};
export const AdjustmentReasonRecord = (ARText) => {
  return {
    type: actionTypes.GET_ADJUSTMENT_TEXT,
    ARText: ARText
  };
};
export const NCPDPGetRecordAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_NCPDP_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(NCPDPGetRecord(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(NCPDPGetRecord([]));
          dispatch(SetSpinner(false));
        }
      })
      .catch(error => {
      });
  };
};
export const NCPDPGetRecord = (NcpdpText) => {
  return {
    type: actionTypes.GET_NCPDP_TEXT,
    NcpdpText: NcpdpText
  };
};
export const providerNoticeGetRecordAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_PN_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(providerNoticeGetRecord(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(providerNoticeGetRecord([]));
          dispatch(SetSpinner(false));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error);
        dispatch(providerNoticeGetRecord(sendingResponse));
      });
  };
};
export const RaeobGetRecordAction = (value) => {
  return dispatch => {
    return axios.post(`${serviceEndPoint.TEXT_MANAGEMENT_SEARCH_RAEOB_ENDPOINT}`, value)
      .then(response => {
        let sendingResponse = commonAPIHandler(response, true);
        dispatch(RaeobGetRecord(sendingResponse));
        if (sendingResponse === undefined) {
          dispatch(RaeobGetRecord([]));
          dispatch(SetSpinner(false));
        }
      })
      .catch(error => {
        let sendingResponse = commonAPIHandler(error, true);
        dispatch(RaeobGetRecord(sendingResponse));
      });
  };
};

export const RaeobGetRecord = (RaeobText) => {
  return {
    type: actionTypes.GET_RAEOB_TEXT,
    RaeobText: RaeobText
  };
};
export const providerNoticeGetRecord = (PNText) => {
  return {
    type: actionTypes.GET_PROVIDER_NOTICE_TEXT,
    PNText: PNText
  };
};
export const TextManagementDeleteEOMB = (EOMBData) => {
  return {
    type: actionTypes.DELETE_TEXT_EOMB_TEXT,
    EOMBDelResult: EOMBData
  };
};

export const TextManagementDeleteRAEOB = (RAEOBData) => {
  return {
    type: actionTypes.DELETE_TEXT_RAEOB,
    RAEOBDelResult: RAEOBData
  };
};

export const TextManagementUpdateEOMB = (EOMBData) => {
  return {
    type: actionTypes.UPDATE_TEXT_EOMB_TEXT,
    EOMBData: EOMBData
  };
};
export const TextManagementAddEOMB = (EOMBData) => {
  return {
    type: actionTypes.ADD_TEXT_EOMB_TEXT,
    EOMBData: EOMBData
  };
};

export const TextManagementUpdateNCPDP = (NCPDPData) => {
  return {
    type: actionTypes.UPDATE_TEXT_NCPDP_TEXT,
    NCPDPData: NCPDPData
  };
};

export const TextManagementAddNCPDP = (NCPDPData) => {
  return {
    type: actionTypes.ADD_TEXT_NCPDP_TEXT,
    NCPDPData: NCPDPData
  };
};
export const TextManagementUpdateAdjustmentReason = (AdjustmentReasonData) => {
  return {
    type: actionTypes.UPDATE_TEXT_ADJUSTMENT_REASON,
    AdjustmentReasonData: AdjustmentReasonData
  };
};
export const TextManagementAddAdjustmentReason = (AdjustmentReasonData) => {
  return {
    type: actionTypes.ADD_TEXT_ADJUSTMENT_REASON,
    AdjustmentReasonData: AdjustmentReasonData
  };
};

export const TextManagementUpdateRemarkText = (RemarkTextData) => {
  return {
    type: actionTypes.UPDATE_TEXT_REMARKTEXT,
    RemarkTextData: RemarkTextData
  };
};

export const TextManagementAddRemarkText = (RemarkTextData) => {
  return {
    type: actionTypes.ADD_TEXT_REMARKTEXT,
    RemarkTextData: RemarkTextData
  };
};
export const TextManagementUpdateRAEOB = (RAEOBData) => {
  return {
    type: actionTypes.UPDATE_TEXT_RAEOB,
    RAEOBData: RAEOBData
  };
};
export const TextManagementAddRAEOB = (RAEOBData) => {
  return {
    type: actionTypes.ADD_TEXT_RAEOB,
    RAEOBData: RAEOBData
  };
};

export const TextManagementUpdateProviderNotice = (PNData) => {
  return {
    type: actionTypes.UPDATE_TEXT_PN,
    PNData: PNData
  };
};

export const TextManagementAddProviderNotice = (PNData) => {
  return {
    type: actionTypes.ADD_TEXT_PN,
    PNData: PNData
  };
};
export const SetSpinner = (value) => {
  return {
    type: actionTypes.SET_SPINNER_VALUE,
    showSpinner: value
  };
};
export const ResetData = () => {
  return {
    type: actionTypes.RESET_FIELD
  };
};
