/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { redirectSystemID } from '../../../../SharedModules/TabRedirections/TabRedirections';
import { searchSingleRecordFinancialEntity } from '../../../FinancialEntities/Store/Actions/FinancialEntityActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

function PayeeTabDetail(props) {
  const {
    lobDD,
    addDropdowns,
    payeeTypeDD,
    payeeIDTypeDD,
    memberIDTypeDD,
    payeeTab,
    payeeTabHandleChanges,
    validatePayeeBtnEvent,
    showPayeeError,
    payeeErrorMsg,
    editFlag,
    setspinnerLoader,
    userInquiryPrivileges,
    validateMember,
    sysParameterPayeeExist,
    regularPayee
  } = props;
  const dispatch = useDispatch();
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(state => state.financialEntityState.singleSearchResults);
  const [redirect, setRedirect] = React.useState(0);
  let memberTypeId = memberIDTypeDD && memberIDTypeDD.find(value => value.code === payeeTab.memberIDType)
  let payeeTypeIDDropdown = payeeTab.entityType === 'P' ? 'P1#P_ALT_ID_TY_CD' :
     payeeTab.entityType === 'M' ? 'F1#B_ALT_ID_TY_CD' :
     payeeTab.entityType === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD' :
     payeeTab.entityType === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null
  
  React.useEffect(() => {
    setspinnerLoader(false);
    if (redirect === 1 && searchSingleFinancialEntityRes && !searchSingleFinancialEntityRes.systemFailue) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  const onSystemPayeeIdclick = systemPayeeId => event => {
    setspinnerLoader(true);
    dispatch(searchSingleRecordFinancialEntity(systemPayeeId));
    setRedirect(1);
  };
  // System Payee redirection ends
  return (
    <div>
      <form autoComplete="off">
        <div className="form-wrapper">
          {editFlag ? 
          <NativeDropDown
              id="lob"
              isRequired
              label="LOB"
              inputProps={{ maxLength: 3 }}
              value={payeeTab.lob}
              InputLabelProps={{
                shrink: true
              }}
              disabled={!userInquiryPrivileges || editFlag}
              dropdownList={addDropdowns
                ? addDropdowns['Reference#R_LOB_CD']?.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            /> :
            <NativeDropDown
              id="lob"
              isRequired
              label="LOB"
              inputProps={{ maxLength: 3 }}
              value={payeeTab.lob}
              onChange={payeeTabHandleChanges('lob')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showPayeeError.showLobErr ? payeeErrorMsg.lobErrMsg : null
              }
              disabled={!userInquiryPrivileges || editFlag}
              dropdownList={lobDD
                ? lobDD.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />
              }
          {editFlag ? 
          <NativeDropDown
            id="payeeType"
            isRequired
            disabled={true}
            label="Payee Type"
            inputProps={{ maxLength: 3 }}
            value={payeeTab.entityType}
            InputLabelProps={{
              shrink: true
            }}
            dropdownList={addDropdowns
              ? addDropdowns['G1#G_CMN_ENTY_TY_CD'] && addDropdowns['G1#G_CMN_ENTY_TY_CD'].map(option => (
                <option key={option.code} value={option.code}>
                  {option.description}
                </option>
              ))
              : null}
          /> : 
          <NativeDropDown
            id="payeeType"
            isRequired
            disabled={!userInquiryPrivileges || (editFlag && regularPayee)}
            label="Payee Type"
            inputProps={{ maxLength: 3 }}
            value={payeeTab.entityType}
            onChange={payeeTabHandleChanges('entityType')}
            InputLabelProps={{
              shrink: true
            }}
            errTxt={
              showPayeeError.showPTypeErr ? payeeErrorMsg.pTypeErrMsg : null
            }
            dropdownList={payeeTypeDD
              ? payeeTypeDD.map(option => (
                <option key={option.code} value={option.code}>
                  {option.description}
                </option>
              ))
              : null}
        />}
         { editFlag ? 
          <NativeDropDown
            id="payeeIDTypeCode"
            isRequired
            disabled={!userInquiryPrivileges || (editFlag && regularPayee)}
            label="Payee ID Type Code"
            inputProps={{ maxLength: 3 }}
            value={payeeTab.entityIDTypeCode}
            onChange={payeeTabHandleChanges('entityIDTypeCode')}
            InputLabelProps={{
              shrink: true
            }}
            dropdownList={addDropdowns
                ? addDropdowns[payeeTypeIDDropdown] &&  addDropdowns[payeeTypeIDDropdown].map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            /> :
            <NativeDropDown
              id="payeeIDTypeCode"
              isRequired
              disabled={!userInquiryPrivileges || (editFlag && regularPayee)}
              label="Payee ID Type Code"
              inputProps={{ maxLength: 3 }}
              value={payeeTab.entityIDTypeCode}
              onChange={payeeTabHandleChanges('entityIDTypeCode')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showPayeeError.showPIDTypeErr
                  ? payeeErrorMsg.pIDTypeErrMsg
                  : null
              }
              dropdownList={payeeIDTypeDD
                ? payeeIDTypeDD.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            /> }
          <div className="mui-custom-form">
            <TextField
              id="payeeID"
              fullWidth
              label="Payee ID"
              type="string"
              required
              inputRef={props.inputRefCursor}
             onBlur={validatePayeeBtnEvent}
              inputProps={{ maxLength: 15 }}
              value={payeeTab.entityID}
              onChange={payeeTabHandleChanges('entityID')}
              InputProps={{
                readOnly: !userInquiryPrivileges || (editFlag && regularPayee),
                className: !userInquiryPrivileges || (editFlag && regularPayee) ? 'Mui-disabled' : ''
              }}
              InputLabelProps={{
                shrink: true
              }}
              helperText={
                showPayeeError.showPIDErr ? payeeErrorMsg.pIDErrMsg : null
              }
              error={showPayeeError.showPIDErr ? payeeErrorMsg.pIDErrMsg : null}
            ></TextField>
          </div>
          <div className="mui-custom-form" style={{ margin: '0px' }}>
            <Button
              className="btn btn-primary"
              onClick={validatePayeeBtnEvent}
              hidden={true}
            >
              Validate
            </Button>
          </div>
        </div>
        <div className="form-wrapper">
          {payeeTab.sysID === '' ?
            <div className="mui-custom-form">
              <TextField
                id="systemPayeeID"
                fullWidth
                label="System Payee ID"
                type="string"
                inputProps={{ maxLength: 10 }}
                value={payeeTab.sysID}
                InputProps={{
                  className: 'Mui-disabled',
                  readOnly: true
                }}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div> :
            <div className="mui-custom-form has-link">
              <TextField
                id="systemPayeeID"
                fullWidth
                label="System Payee ID"
                type="string"
                inputProps={{ maxLength: 10 }}
                InputProps={{
                  className: 'Mui-disabled',
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                       <Link to={redirectSystemID(payeeTab)} target='_blank'>
                        {payeeTab.sysID}
                      </Link>
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  shrink: true
                }}
              ></TextField>
            </div>

          }
          <div className="mui-custom-form">
            <TextField
              id="payeeName"
              fullWidth
              label="Payee Name"
              type="string"
              inputProps={{ maxLength: 60 }}
              value={payeeTab.entityName}
              InputProps={{
                className: 'Mui-disabled',
                readOnly: true
              }}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>

            <NativeDropDown
              id="memberIDType"
              required={false}
              disabled={!userInquiryPrivileges}
              label="Member ID Type"
              inputProps={{ maxLength: 3 }}
              value={memberTypeId !== undefined ? payeeTab.memberIDType : ''}
              onChange={payeeTabHandleChanges('memberIDType')}
              InputLabelProps={{
                shrink: true
              }}
              errTxt={
                showPayeeError.showMIDTypeErr
                  ? payeeErrorMsg.mIDTypeErrMsg
                  : null
              }
              dropdownList={memberIDTypeDD
                ? memberIDTypeDD.map(option => (
                  <option key={option.code} value={option.code}>
                    {option.description}
                  </option>
                ))
                : null}
            />
          <div className="mui-custom-form">
            <TextField
              id="memberId"
              fullWidth
              label="Member ID"
              type="string"
              required={false}
              inputProps={{ maxLength: 15 }}
              value={payeeTab.memberID}
              onChange={payeeTabHandleChanges('memberID')}
              //onBlur={validateMember}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: !userInquiryPrivileges,
                className: !userInquiryPrivileges ? 'Mui-disabled' : ''
              }}
              helperText={
                showPayeeError.showMIDErr ? payeeErrorMsg.mIDErrMsg : null
              }
              error={showPayeeError.showMIDErr ? payeeErrorMsg.mIDErrMsg : null}
            ></TextField>
          </div>

        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form">
            <TextField
              id="memberName"
              fullWidth
              label="Member Name"
              type="string"
              inputProps={{ maxLength: 60 }}
              InputProps={{
                className: 'Mui-disabled',
                readOnly: true
              }}
              value={payeeTab.memberName}
              onChange={payeeTabHandleChanges('memberName')}
              InputLabelProps={{
                shrink: true
              }}
            ></TextField>
          </div>

        </div>
      </form>
    </div>
  );
}
export default withRouter((PayeeTabDetail));
