import React from "react";
import { withRouter } from "react-router";
import TableComponent from "../../../../SharedModules/Table/Table";

const headCellsProcedure = [
  {
    id: "userId",
    numeric: false,
    disablePadding: false,
    label: "User ID",
    enableHyperLink: true,
    fontSize: 12,
    width: "120px",
  },
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "User Name",
    enableHyperLink: false,
    fontSize: 12,
  },

  {
    id: "sourceAuditDate",
    numeric: false,
    disablePadding: false,
    label: "Date",
    enableHyperLink: false,
    fontSize: 12,
  },
  {
    id: "sourceAuditTime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    enableHyperLink: false,
    fontSize: 12,
  },
  {
    id: "tableName",
    numeric: false,
    disablePadding: false,
    label: "Table Name",
    enableHyperLink: false,
    fontSize: 12,
  },

  {
    id: "field",
    numeric: false,
    disablePadding: false,
    label: "Field",
    enableHyperLink: false,
    fontSize: 12,
  },
  {
    id: "firstColumnKeyType",
    numeric: false,
    disablePadding: false,
    label: "First Column Key Type",
    enableHyperLink: false,
    fontSize: 12,
  },
  {
    id: "key",
    numeric: false,
    disablePadding: false,
    label: "Key",
    enableHyperLink: false,
    fontSize: 12,
    width: "200px",
  },
];

function SearchTableComponent(props) {
  let tableData = props.tableData.map((i,v) => Object.fromEntries(
    Object.entries(props.tableData[v]).map(([key, value]) => key ==='tableName' || key === 'field' || key === 'firstColumnKeyType' ? [key, value.toUpperCase()] : [key, value])
  ))
  const editRow = (row) => (event) => {
    props.setspinnerLoader(true);
    props.setRedirect(true);
    props.history.push({
      pathname: `/GlobalAuditDetails`,
      auditDetails: row,
      state: { auditDetails: row },
    });
  };
  return (
    <TableComponent
      headCells={headCellsProcedure}
      tableData={tableData || []}
      onTableRowClick={editRow}
      defaultSortColumn="userId"
      isSearch={true}
    />
  );
}
export default withRouter(SearchTableComponent);
