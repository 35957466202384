/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import * as moment from 'moment';
import * as ErrorConstants from './ErrorCheck';
import { compareTwoDatesGreaterThanOrEqual, validateDateMinimumValue, getUTCTimeStamp } from '../../../SharedModules/DateUtilities/DateUtilities';
import * as AppConstants from '../../../SharedModules/AppConstants';

var DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
export const checkPayoutAddValidations = (values, checkDate, reasonCodesData, costsettlementBeginDate, costsettlementEndDate, errorMessagesArray, setShowError, setShowErrorText, seterrorMessages, hipCodes, payoutRsnCd, cosCodes, isParentReceipt) => {
  var showLOBErrorTemp = false; var showPayeeTypeErrorTemp = false; var showATRErrorTemp =false; var showPayeeIDTypeCodeErrorTemp = false;
  var showPayeeIDErrorTemp = false; var showCheckNumberErrorTemp = false; var showCheckDateErrorTemp = false;
  var showReasonCodeErrorTemp = false; var showAmountErrorTemp = false; var showMemberIDErrorTemp = false;
  var showMemberIDTypeCodeErrorTemp = false;
  var showFiscalYearErrorTemp = false; var showCollocationCodeErrorTemp = false; var showCompareDatesErrorTemp = false;
  var showBeginDateErrorTemp = false; var showEndDateErrorTemp = false;
  var showLOBErrorTextTemp = ''; var showPayeeTypeErrorTextTemp = ''; var showPayeeIDTypeCodeErrorTextTemp = '';
  var showATRErrorTextTemp='';
  var showPayeeIDErrorTextTemp = ''; var showCheckNumberErrorTextTemp = ''; var showCheckDateErrorTextTemp = '';
  var showReasonCodeErrorTextTemp = ''; var showAmountErrorTextTemp = ''; var showMemberIDErrorTextTemp = '';
  var showMemberIDTypeCodeErrorTextTemp = '';
  var showFiscalYearErrorTextTemp = ''; var showCollocationCodeErrorTextTemp = ''; var showCompareDatesErrorTextTemp = '';
  var showBeginDateErrorTextTemp = ''; var showEndDateErrorTextTemp = '';
  const regex = /[^A-Za-z0-9]/ ;
  if (values.lob === '' || values.lob === undefined || values.lob === DEFAULT_DD_VALUE) {
    showLOBErrorTemp = true;
    showLOBErrorTextTemp = ErrorConstants.LOB_REQUIRED;
    errorMessagesArray.push(showLOBErrorTextTemp);
  }
  if (values.payeeType === '' || values.payeeType === undefined || values.payeeType === DEFAULT_DD_VALUE) {
    showPayeeTypeErrorTemp = true;
    showPayeeTypeErrorTextTemp = ErrorConstants.PAYEE_TYPE_REQUIRED;
    errorMessagesArray.push(showPayeeTypeErrorTextTemp);
  }
  if (values.atrNumber && regex.test(values.atrNumber)) {
    showATRErrorTemp = true;
    showATRErrorTextTemp =process.env.REACT_APP_TRANSACTION_NUMBER_LABEL+ ' ' + ErrorConstants.TRANSACTION_NUMBER_FORMAT;
    errorMessagesArray.push(showATRErrorTextTemp);
  }
  if (values.payeeIdTypeCode === '' || values.payeeIdTypeCode === undefined || values.payeeIdTypeCode === DEFAULT_DD_VALUE) {
    showPayeeIDTypeCodeErrorTemp = true;
    showPayeeIDTypeCodeErrorTextTemp = ErrorConstants.PAYEE_TYPE_ID_CODE_REQUIRED;
    errorMessagesArray.push(showPayeeIDTypeCodeErrorTextTemp);
  }
  if (values.entityID === '' || values.entityID === undefined) {
    showPayeeIDErrorTemp = true;
    showPayeeIDErrorTextTemp = ErrorConstants.PAYEE_ID_REQUIRED;
    errorMessagesArray.push(showPayeeIDErrorTextTemp);
  }
  if (values.reasonCode && checkDropdownValue(values.reasonCode) && checkReasonCode(values.reasonCode, reasonCodesData)) {
    if ((values.checkNumber === '' || values.checkNumber === undefined) &&
      (checkDate === '' || checkDate === undefined || checkDate === null)) {
      showCheckNumberErrorTemp = true;
      showCheckNumberErrorTextTemp = ErrorConstants.CHECK_DETAILS_REQUIRED;
      showCheckDateErrorTemp = true;
      showCheckDateErrorTextTemp = ErrorConstants.CHECK_DETAILS_REQUIRED;
      errorMessagesArray.push(showCheckNumberErrorTextTemp);
    } else {
      if (values.checkNumber === '' || values.checkNumber === undefined || values.checkNumber === null) {
        showCheckNumberErrorTemp = true;
        showCheckNumberErrorTextTemp = ErrorConstants.CHECK_DETAILS_REQUIRED;
        errorMessagesArray.push(showCheckNumberErrorTextTemp);
      }
      if (checkDate === '' || checkDate === undefined || checkDate === null) {
        showCheckDateErrorTemp = true;
        showCheckDateErrorTextTemp = ErrorConstants.CHECK_DETAILS_REQUIRED;
        errorMessagesArray.push(showCheckDateErrorTextTemp);
      }
    }
    if (checkDate) {
      if (!moment(checkDate).isValid()) {
        showCheckDateErrorTemp = true;
        showCheckDateErrorTextTemp = ErrorConstants.MANUAL_DATE_INVALID;
        errorMessagesArray.push(showCheckDateErrorTextTemp);
      } else if (moment(checkDate).isValid() && new Date(checkDate).setHours(0, 0, 0, 0) > new Date(values.establishedDateStr).setHours(0, 0, 0, 0)) {
        showCheckDateErrorTemp = true;
        showCheckDateErrorTextTemp = ErrorConstants.CHECK_DATE_GREATER_EST_DATE;
        errorMessagesArray.push(showCheckDateErrorTextTemp);
      }
    }
  }
  if (values.reasonCode === '' || values.reasonCode === undefined || values.reasonCode === DEFAULT_DD_VALUE) {
    showReasonCodeErrorTemp = true;
    showReasonCodeErrorTextTemp = ErrorConstants.REASON_CODE_REQUIRED;
    errorMessagesArray.push(showReasonCodeErrorTextTemp);
  }
  if (values.reasonCode && (hipCodes?.includes(+values.reasonCode)) &&
    (values.memberID === '' || !values.memberID)) {
    showMemberIDErrorTemp = true;
    showMemberIDErrorTextTemp = ErrorConstants.MEMBERID_REASONCODE_REQUIRED;
    errorMessagesArray.push(showMemberIDErrorTextTemp);
  }
  if (values.memberIDType && values.memberIDType !== DEFAULT_DD_VALUE &&
    (values.memberID === '' || !values.memberID)) {
    showMemberIDErrorTemp = true;
    showMemberIDErrorTextTemp = ErrorConstants.MEMBERID_REQUIRED;
    errorMessagesArray.push(showMemberIDErrorTextTemp);
  }
  if (values.memberID && (values.memberIDType === '' || values.memberIDType === DEFAULT_DD_VALUE)) {
    showMemberIDTypeCodeErrorTemp = true;
    showMemberIDTypeCodeErrorTextTemp = ErrorConstants.MEMBERIDTYPECODE_REQUIRED;
    errorMessagesArray.push(showMemberIDTypeCodeErrorTextTemp);
  }
  if (values.amount === '' || values.amount === undefined) {
    showAmountErrorTemp = true;
    showAmountErrorTextTemp = ErrorConstants.AMOUNT_REQUIRED;
    errorMessagesArray.push(showAmountErrorTextTemp);
  }
  if (values.amount !== '' && values.amount !== undefined && parseFloat(values.amount) <= 0) {
    showAmountErrorTemp = true;
    showAmountErrorTextTemp = ErrorConstants.AMOUNT_GREATER_THAN_ZERO;
    errorMessagesArray.push(showAmountErrorTextTemp);
  }
  if (values.amount && parseFloat(values.amount) > 0) {
    if ((values.amount
      .replace(/,/g, '')
      .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null) ||
      !(values.amount
        .replace(/,/g, '')
        .match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null
      )) {
      showAmountErrorTemp = true;
      showAmountErrorTextTemp = ErrorConstants.DATA_FORMAT_ERROR;
      errorMessagesArray.push(showAmountErrorTextTemp);
    }
  }
  if (values.fiscalYear === '' || values.fiscalYear === undefined) {
    showFiscalYearErrorTemp = true;
    showFiscalYearErrorTextTemp = ErrorConstants.FISCAL_YEAR_REQUIRED;
    errorMessagesArray.push(showFiscalYearErrorTextTemp);
  }
  if (values.fiscalYear && values.fiscalYear.length < 4) {
    showFiscalYearErrorTemp = true;
    showFiscalYearErrorTextTemp = ErrorConstants.DATA_FORMAT_ERROR;
    errorMessagesArray.push(showFiscalYearErrorTextTemp);
  }
  let dateToday = new Date();
  let monthToday = dateToday.getMonth() + 1;
  let currFiscalYear = monthToday > 6 ? dateToday.getFullYear() + 1 : dateToday.getFullYear();
  if (values.fiscalYear && values.fiscalYear.length === 4 && values.fiscalYear < currFiscalYear && values.paymentStatusCode !== 'P' && !isParentReceipt) {
    showFiscalYearErrorTemp = true;
    showFiscalYearErrorTextTemp = ErrorConstants.WRONG_FISCAL_YEAR;
    errorMessagesArray.push(showFiscalYearErrorTextTemp);
  }
  if (values.fiscalYear && (values.collocationCode === '' || values.collocationCode === DEFAULT_DD_VALUE || values.collocationCode === undefined)) {
    showCollocationCodeErrorTemp = true;
    showCollocationCodeErrorTextTemp = ErrorConstants.COLLOCATION_CODE;
    errorMessagesArray.push(showCollocationCodeErrorTextTemp);
  }
  if ((values.fiscalYear === '' || values.fiscalYear === undefined) &&
    (values.collocationCode === '' || values.collocationCode === DEFAULT_DD_VALUE || values.collocationCode === undefined)) {
    showCollocationCodeErrorTemp = true;
    showCollocationCodeErrorTextTemp = ErrorConstants.FUND_CODE_REQUIRED;
    errorMessagesArray.push(showCollocationCodeErrorTextTemp);
  }
  if (costsettlementBeginDate) {
    if (costsettlementBeginDate.toString() === 'Invalid Date') {
      showBeginDateErrorTemp = true;
      showBeginDateErrorTextTemp = ErrorConstants.SETTLEMENT_BEGINDATE_INVALID;
      errorMessagesArray.push(showBeginDateErrorTextTemp);
    } else if (validateDateMinimumValue(costsettlementBeginDate)) {
      showBeginDateErrorTemp = true;
      showBeginDateErrorTextTemp = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(showBeginDateErrorTextTemp);
    }
  }
  if (costsettlementEndDate) {
    if (costsettlementEndDate.toString() === 'Invalid Date') {
      showEndDateErrorTemp = true;
      showEndDateErrorTextTemp = ErrorConstants.SETTLEMENT_ENDDATE_INVALID;
      errorMessagesArray.push(showEndDateErrorTextTemp);
    } else if (validateDateMinimumValue(costsettlementEndDate)) {
      showEndDateErrorTemp = true;
      showEndDateErrorTextTemp = AppConstants.DATE_ERROR_1964;
      errorMessagesArray.push(showEndDateErrorTextTemp);
    }
  }
  if (costsettlementBeginDate && costsettlementEndDate &&
    !showBeginDateErrorTemp && !showEndDateErrorTemp &&
    compareTwoDatesGreaterThanOrEqual(new Date(costsettlementEndDate), new Date(costsettlementBeginDate))) {
    showBeginDateErrorTemp = true;
    showBeginDateErrorTextTemp = ErrorConstants.BEGIN_END_DATE_COMPARE;
    errorMessagesArray.push(showBeginDateErrorTextTemp);
  }
  if (values.reasonCode && (payoutRsnCd?.includes(values.reasonCode))) {
    let pushFlag = false;
    if (!costsettlementBeginDate) {
      showBeginDateErrorTemp = true;
      showBeginDateErrorTextTemp = ErrorConstants.SETTLEMENT_DATES_REQUIRED;
      pushFlag = true;
    }
    if (!costsettlementEndDate) {
      showEndDateErrorTemp = true;
      showEndDateErrorTextTemp = ErrorConstants.SETTLEMENT_DATES_REQUIRED;
      pushFlag = true;
    }
    if (pushFlag) {
      errorMessagesArray.push(ErrorConstants.SETTLEMENT_DATES_REQUIRED);
    }
  }
  setShowError({
    showLOBError: showLOBErrorTemp,
    showPayeeTypeError: showPayeeTypeErrorTemp,
    showATRError: showATRErrorTemp,
    showPayeeIDTypeCodeError: showPayeeIDTypeCodeErrorTemp,
    showPayeeIDError: showPayeeIDErrorTemp,
    showCheckNumberError: showCheckNumberErrorTemp,
    showCheckDateError: showCheckDateErrorTemp,
    showReasonCodeError: showReasonCodeErrorTemp,
    showAmountError: showAmountErrorTemp,
    showFiscalYearError: showFiscalYearErrorTemp,
    showCollocationCodeError: showCollocationCodeErrorTemp,
    showMemberIDError: showMemberIDErrorTemp,
    showMemberIDTypeCodeError: showMemberIDTypeCodeErrorTemp,
    showCompareDatesError: showCompareDatesErrorTemp,
    showBeginDateError: showBeginDateErrorTemp,
    showEndDateError: showEndDateErrorTemp
  });
  setShowErrorText({
    showLOBErrorText: showLOBErrorTextTemp,
    showPayeeTypeErrorText: showPayeeTypeErrorTextTemp,
    showATRErrorText: showATRErrorTextTemp,
    showPayeeIDTypeCodeErrorText: showPayeeIDTypeCodeErrorTextTemp,
    showPayeeIDErrorText: showPayeeIDErrorTextTemp,
    showCheckNumberErrorText: showCheckNumberErrorTextTemp,
    showCheckDateErrorText: showCheckDateErrorTextTemp,
    showReasonCodeErrorText: showReasonCodeErrorTextTemp,
    showAmountErrorText: showAmountErrorTextTemp,
    showFiscalYearErrorText: showFiscalYearErrorTextTemp,
    showCollocationCodeErrorText: showCollocationCodeErrorTextTemp,
    showMemberIDErrorText: showMemberIDErrorTextTemp,
    showMemberIDTypeCodeErrorText: showMemberIDTypeCodeErrorTextTemp,
    showCompareDatesErrorText: showCompareDatesErrorTextTemp,
    showBeginDateErrorText: showBeginDateErrorTextTemp,
    showEndDateErrorText: showEndDateErrorTextTemp
  });
  seterrorMessages(errorMessagesArray);
};

export const checkPayeeIDValidations = (values, errorMessagesArray, setShowError, setShowErrorText, seterrorMessages) => {
  var showLOBErrorTemp = false; var showPayeeTypeErrorTemp = false; var showPayeeIDTypeCodeErrorTemp = false;
  var showPayeeIDErrorTemp = false;
  var showLOBErrorTextTemp = ''; var showPayeeTypeErrorTextTemp = ''; var showPayeeIDTypeCodeErrorTextTemp = '';
  var showPayeeIDErrorTextTemp = '';
  if (values.lob === '' || values.lob === undefined || values.lob === DEFAULT_DD_VALUE) {
    showLOBErrorTemp = true;
    showLOBErrorTextTemp = ErrorConstants.LOB_REQUIRED;
    errorMessagesArray.push(showLOBErrorTextTemp);
  }
  if (values.payeeType === '' || values.payeeType === undefined || values.payeeType === DEFAULT_DD_VALUE) {
    showPayeeTypeErrorTemp = true;
    showPayeeTypeErrorTextTemp = ErrorConstants.PAYEE_TYPE_REQUIRED;
    errorMessagesArray.push(showPayeeTypeErrorTextTemp);
  }
  if (values.payeeIdTypeCode === '' || values.payeeIdTypeCode === undefined || values.payeeIdTypeCode === DEFAULT_DD_VALUE) {
    showPayeeIDTypeCodeErrorTemp = true;
    showPayeeIDTypeCodeErrorTextTemp = ErrorConstants.PAYEE_TYPE_ID_CODE_REQUIRED;
    errorMessagesArray.push(showPayeeIDTypeCodeErrorTextTemp);
  }
  if (values.entityID === '' || values.entityID === undefined) {
    showPayeeIDErrorTemp = true;
    showPayeeIDErrorTextTemp = ErrorConstants.PAYEE_ID_REQUIRED;
    errorMessagesArray.push(showPayeeIDErrorTextTemp);
  }
  setShowError({
    showLOBError: showLOBErrorTemp,
    showPayeeTypeError: showPayeeTypeErrorTemp,
    showPayeeIDTypeCodeError: showPayeeIDTypeCodeErrorTemp,
    showPayeeIDError: showPayeeIDErrorTemp
  });
  setShowErrorText({
    showLOBErrorText: showLOBErrorTextTemp,
    showPayeeTypeErrorText: showPayeeTypeErrorTextTemp,
    showPayeeIDTypeCodeErrorText: showPayeeIDTypeCodeErrorTextTemp,
    showPayeeIDErrorText: showPayeeIDErrorTextTemp
  });
  seterrorMessages(errorMessagesArray);
};

export const checkDropdownValue = (value) => {
  if (value && value !== DEFAULT_DD_VALUE) {
    return value;
  } else {
    return null;
  }
};

export const checkReasonCode = (reasonCode, reasonCodesData) => {
  const result = reasonCodesData?.filter(reasonCodeObj => {
    return reasonCodeObj.code === reasonCode;
  });
  if (result?.length > 0) {
    return true;
  }
  return false;
};

export const validatePayeeRequest = (values) => {
  return {
    auditUserID: null,
    auditTimeStamp: null,
    addedAuditUserID: null,
    addedAuditTimeStamp: null,
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    payeePayerName: null,
    payoutHistoryList: [],
    approvedUserID: null,
    memberName: null,
    beginDate: null,
    endDate: null,
    fcnSeqNum: values.fcnSeqNum,
    fcnMediaCode: '0',
    entityType: checkDropdownValue(values.payeeType),
    entityIDTypeCode: checkDropdownValue(values.payeeIdTypeCode),
    entityID: values.entityID,
    lineOfBusiness: checkDropdownValue(values.lob),
    fcn: values.fcn,
    checkNumber: null,
    checkDate: null,
    reasonCode: null,
    amount: null,
    approval: null,
    establishedDate: values.establishedDate,
    settlementThruDate: null,
    establishedBehalfOfMemberID: null,
    memberIDType: null,
    relatedFTTCN: values.relatedFTTCN,
    account: null,
    payeePayerID: values.payeeId,
    updateAuditTimeStamp: null,
    orgAuditTimeStamp: null,
    receiptVersionNo: 0,
    cmnEntVersionNo: 0,
    sysID: null,
    lineOfBusinessValue: null,
    entityTypeValue: null,
    entityIDTypeCodeValue: null,
    reasonCodeValue: null,
    memberIDTypeValue: null,
    activeTypeCode: null,
    voidIndicator: null,
    voidDate: null,
    associatedClaim: null,
    entityIDType: null,
    provBillSysID: null,
    fdetailVersionNo: 0,
    establishedDateStr: values.establishedDatestr
  };
};

export const validateMemberRequest = (values, logInUserID) => {
  return {
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    payeePayerName: values.payeePayerName,
    payoutHistoryList: [],
    approvedUserID: null,
    memberName: null,
    beginDate: values.beginDate,
    endDate: values.endDate,
    fcnSeqNum: values.fcnSeqNum,
    fcnMediaCode: null,
    entityType: checkDropdownValue(values.payeeType),
    entityIDTypeCode: checkDropdownValue(values.payeeIdTypeCode),
    entityID: values.payeeId,
    lineOfBusiness: values.lob,
    fcn: values.fcn,
    checkNumber: '',
    checkDate: null,
    reasonCode: checkDropdownValue(values.reasonCode),
    amount: values.amount,
    approval: values.approval,
    establishedDate: values.establishedDate,
    settlementThruDate: null,
    establishedBehalfOfMemberID: values.memberID,
    memberIDType: checkDropdownValue(values.memberIDType),
    relatedFTTCN: values.relatedFTTCN,
    account: {
      auditUserID: logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: logInUserID,
      addedAuditTimeStamp: getUTCTimeStamp(),
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      scoaFiscalYearDisable: false,
      scoaFiscalYear: values.fiscalYear,
      fundCodeDisable: false,
      fundCode: checkDropdownValue(values.fundCode),
      cosDisable: false,
      cos: null,
      cosValue: null,
      fundCodeValue: null
    },
    payeePayerID: values.payeeId,
    updateAuditTimeStamp: null,
    orgAuditTimeStamp: null,
    receiptVersionNo: 0,
    cmnEntVersionNo: 0,
    sysID: values.sysID,
    lineOfBusinessValue: null,
    entityTypeValue: null,
    entityIDTypeCodeValue: '',
    reasonCodeValue: null,
    memberIDTypeValue: null,
    activeTypeCode: null,
    voidIndicator: false,
    voidDate: null,
    associatedClaim: false,
    entityIDType: [
      ''
    ],
    establishedDateStr: values.establishedDatestr,
    fdetailVersionNo: 0,
    noteSetVO: {
      auditUserID: '',
      auditTimeStamp: null,
      addedAuditUserID: '',
      addedAuditTimeStamp: null,
      versionNo: 0,
      dbRecord: false,
      sortColumn: null,
      tableName: null,
      noteSetSK: null,
      noteSourceName: null,
      notesList: [],
      globalNotesList: [],
      checkAll: null,
      addNewLinkRender: null,
      filterLinkRender: null,
      printLinkRender: null,
      completeNotesList: []
    }
  };
};
