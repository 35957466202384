/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Button, Form } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import SystemParameterAddValueTable from "./SystemParameterAddValueTable";
import DateFnsUtils from "@date-io/date-fns";
import {
  ToastsContainer,
  ToastsStore,
  ToastsContainerPosition
} from "react-toasts";
import * as SystemParameterAddConstants from "./SystemParameterAddConstants";
import * as DateUtils from "../../../../../SharedModules/DateUtilities/DateUtilities";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  addSystemParameter,
  systemParameterRowClickAction
} from "../../../Store/Actions/systemParameter/systemParameterActions";
import ErrorMessages from "../../../../../SharedModules/Errors/ErrorMessages";
import { Prompt } from "react-router-dom";
import * as AppConstants from "../../../../../SharedModules/AppConstants";
import { AppConfigDropdownActions } from "../../../Store/Actions/AppConfigCommon/AppConfigActions";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import numeral from "numeral";
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from "../../../../../SharedModules/Dialog/DialogUtilities";
import TabPanel from "../../../../../SharedModules/TabPanel/TabPanel";
import dropdownCriteria from "../SystemParameterAddUpdate.json";
import moment from "moment";
import Spinner from "../../../../../SharedModules/Spinner/Spinner";
import Notes from "../../../../../SharedModules/Notes/Notes";
import NumberFormatCustom from "../../../../../SharedModules/NumberFormat/NumberFormat";
import Footer from "../../../../../SharedModules/Layout/footer";
import DialogContentText from '@material-ui/core/DialogContentText';
import NativeDropDown from "../../../../../SharedModules/Dropdowns/NativeDropDown";
import { checkingDecimal } from "../../../../../SharedModules/CommonUtilities/commonUtilities";

let requestTableData = [];
let requestTableDataEdit = [];

const useStyles = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

function SystemParameterAdd(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [value, setValue] = React.useState(0);
  const [tabOne, setTabOne] = React.useState(true);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
  };
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initialFocusCheck,setIntialFocusCheck] = React.useState(false);

  
  const toPrintRef = useRef();
  let errorMessagesArray = [];
  const [formatError, setFormatError] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const classes = useStyles();
  const [id, setId] = React.useState(1);
  const [open, setOpen] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  const [errorMeg, setErrorMeg] = React.useState("");
  const [errorMegDate, setErrorMegDate] = React.useState("");
  const [errorMegPercent, setErrorMegPercent] = React.useState("");
  const [errorMegNumeric, setErrorMegNumeric] = React.useState("");
  const [errorMegCurrency, setErrorMegCurrency] = React.useState("");
  const [errorMegAlpha, setErrorMegAlpha] = React.useState("");
  const [errorMegTime, setErrorMegTime] = React.useState("");
  const [previousDataformatValue, setPreviousDataFormatValue] = React.useState(
    ""
  );
  const [editData, setEditData] = React.useState({});
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  const [newData, setnewData] = React.useState([]);
  const systemParamAddCons = SystemParameterAddConstants;
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    {
      showBlankRowError,
      showFunctionalAreaError,
      showDataFormatError,
      showParameterNoError,
      showDescriptionError,
      showNumericError,
      showPercentError,
      showAlphaNumericError,
      showTimestampError,
      showCurrencyError,
      showCurrencyInvalidError,
      showAlphaNumericInvalidError,
      showPercentInvalidError,
      showNumericInvalidError,
      showBeginDateError,
      showDateError,
      showDateInvalidError
    },
    setShowError
  ] = React.useState(false);

  const [begindatePress, setBeginDatePress] = React.useState(false);
  const [formatdatePress, setFormatDatePress] = React.useState(false);
  const [selectedBeginDate, setSelectedBeginDate] = React.useState("");
  const [selectedFormatDate, setSelectedFormatDate] = React.useState("");
  const [redirect, setRedirect] = React.useState(0);
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const [dataFormatData, setDataFormatData] = React.useState([]);
  const [lobCodeData, setLobCodeData] = React.useState([]);
  const [add, setAdd] = React.useState(true);
  const [formatChange, setFormatChange] = React.useState(true);
  const [beginDateChange, setBeginDateChange] = React.useState(true);
  const [retainEdit, setRetainEdit] = React.useState();
  const [retainBeginDate, setRetainBeginDate] = React.useState();
  const [retainFormatDate, setRetainFormatDate] = React.useState();
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [successResponse, setSuccessResponse] = React.useState();
  const [rowSystemParameterData, setRowSystemParameterData] = React.useState(
    []
  );
  // notes
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtils.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: DateUtils.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: "",
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: "",
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format("YYYY-MM-DD"),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: DateUtils.generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // add notes
  let notesDataArray = [];

  const addNotes = data => {
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));

  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
  }, []);
  const dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );

  const [values, setValues] = React.useState({
    lobDetail: DEFAULT_DD_VALUE,
    dataFormat: DEFAULT_DD_VALUE,
    description: "",
    paramNumber: "",
    functionalArea: DEFAULT_DD_VALUE
  });

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      let tempData = JSON.parse(JSON.stringify(values));
      if (dropdown.listObj["Reference#1017"]) {
        setFunctionalAreaData(dropdown.listObj["Reference#1017"]);
        if (dropdown.listObj["Reference#1017"].length === 1) {
          tempData.functionalArea = dropdown.listObj["Reference#1017"][0].code;
        }
      }
      if (dropdown.listObj["Reference#1019"]) {
        // Changes for single dropdown
        if (dropdown.listObj["Reference#1019"].length === 1) {
          tempData.lobDetail = dropdown.listObj["Reference#1019"][0].code;
        }
        setLobCodeData(dropdown.listObj["Reference#1019"]);
      }
      if (dropdown.listObj["Reference#1025"]) {
        setDataFormatData(dropdown.listObj["Reference#1025"]);
        if (dropdown.listObj["Reference#1025"].length === 1) {
          tempData.dataFormat = dropdown.listObj["Reference#1025"][0].code;
        }
      }
      if (dropdown.listObj["General#1012"]) {
        setUsageTypeCodeData(dropdown.listObj["General#1012"]);
      }
      setValues(tempData);
    }
  }, [dropdown]);
  const addResponse = useSelector(
    state => state.appConfigState.systemParameterState.addSystemParameter
  );

  useEffect(() => {
    setAllowNavigation(false);
    setFocusCheck(false);
    setSuccessResponse(addResponse ? addResponse : {});
  }, [addResponse]);
  const onAddSuccess = values =>
    dispatch(systemParameterRowClickAction(values));

  if (addResponse && addResponse.respcode === "01") {
    const rowClickValues = {
      functionalArea: values.functionalArea,
      parameterNumber: +values.paramNumber
    };
    onAddSuccess(rowClickValues);
  }
  const payloadData = useSelector(
    state => state.appConfigState.systemParameterState.rowSearchSysParam
  );

  useEffect(() => {
    if (redirect === 1) {
      if (payloadData && successResponse) {
        setSpinnerLoader(false);
        props.history.push({
          pathname: "/SystemParametersEdit",
          state: { payloadData, successResponse, isFromAdd:true},
                 });
      }
    }
    if (addResponse) {
      if (addResponse && addResponse.respcode === "02") {
        setSpinnerLoader(false);
        errorMessagesArray.push(addResponse.respdesc);
        seterrorMessages(errorMessagesArray);
      } else if (addResponse && addResponse.respcode === "03") {
        setSpinnerLoader(false);
        errorMessagesArray.push(addResponse.respdesc);
        seterrorMessages(errorMessagesArray);
      } else if (addResponse.systemFailure) {
        setSpinnerLoader(false);
        errorMessagesArray.push([AppConstants.ERR_PROCESSING_REQ]);
        seterrorMessages(errorMessagesArray);
      } else {
        let valuetoredirect = 0;
        valuetoredirect = valuetoredirect + 1;
        setRedirect(valuetoredirect);
      }
    }
  }, [addResponse]);

  const handleDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedBeginDate(date);
    setBeginDatePress(true);
    setBeginDateChange(true);
  };

  const handleFormatDateChange = formatDate => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedFormatDate(formatDate);
    setFormatDatePress(true);
  };

  const [dataElement, setDataElement] = React.useState({
    id: 0,
    beginDateShowInTable: "",
    beginDate: "",
    endDateShowInTable: "",
    endDate: "",
    format: "",
    auditUserID: logInUserID,
    auditTimeStamp: DateUtils.getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
    versionNo: "",
    voidDate: "",
    tempValue: "",
    valueDate: "",
    valueTimeStamp: "",
    valueAmt: "",
    valuePCT: "",
    valueNum: "",
    valueData: "",
    lob: "",
    parameterDetails: ""
  });

  const [
    systemParameterAddHeadCells,
    setSystemParameterAddHeadCells
  ] = React.useState([
    {
      id: "beginDateShowInTable",
      disablePadding: false,
      label: "Begin Date",
      width: "33%",
      isDate: true
    },
    {
      id: "endDateShowInTable",
      disablePadding: false,
      label: "End Date",
      width: "33%",
      isDate: true
    },
    { id: "format", disablePadding: false, label: "Format", width: "34%" }
  ]);

  const handleClickOpen = () => {
    setAdd(true); //298390 - fix
    errorMessagesArray = [];
    seterrorMessages([]);
    var showFunctionalAreaError;
    var showParameterNoError;
    var showDataFormatError;
    var showDescriptionError = false;
    setSelectedBeginDate(null);

    if (values.dataFormat === "D") {
      setSelectedFormatDate(null);
    }

    if (
      !values.functionalArea ||
      values.functionalArea === DEFAULT_DD_VALUE
    ) {
      showFunctionalAreaError = true;
      errorMessagesArray.push(systemParamAddCons.FUNCTIONAL_AREA_REQUIRED);
      seterrorMessages(errorMessagesArray);
    }

    if (!values.paramNumber) {
      showParameterNoError = true;
      errorMessagesArray.push(systemParamAddCons.PARAMETER_NUMBER_REQUIRED);
      seterrorMessages(errorMessagesArray);
    }

    if (!values.dataFormat || values.dataFormat === DEFAULT_DD_VALUE) {
      showDataFormatError = true;
      errorMessagesArray.push(systemParamAddCons.DATA_FORMAT_REQUIRED);
      seterrorMessages(errorMessagesArray);
    }

    if (!values.description) {
      showDescriptionError = true;
      errorMessagesArray.push(systemParamAddCons.DESCRIPTION_REQUIRED);
      seterrorMessages(errorMessagesArray);
    }

    if (errorMessagesArray.length > 0) {
      setShowError({
        showFunctionalAreaError: showFunctionalAreaError,
        showParameterNoError: showParameterNoError,
        showDataFormatError: showDataFormatError,
        showDescriptionError: showDescriptionError
      });
    } else {
      showDataFormatError = false;
      setShowError(false);

      setOpen(true);
      setDataElement({
        beginDateShowInTable: "",
        endDateShowInTable: "",
        format: ""
      });
      setValues({
        ...values,
        lobDetail: DEFAULT_DD_VALUE
      });
    }
  };
  const lobCodeDescription = (_code, _lobArray) => {
    if (_lobArray && _lobArray.length > 0) {
      let _description = [];
      _description = _lobArray.filter((value, index) => value.code === _code);
      if (_description.length > 0) {
        return _description[0].description;
      } else {
        return _code;
      }
    }
    return _code;
  };
  const handleClose = () => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    seterrorMessages([]);
    if (!add) {
      setAdd(true);
    }
    setOpen(false);
    setDataElement({
      beginDateShowInTable: "",
      endDateShowInTable: "",
      format: ""
    });
    setShowError(false);
  };

  const checkFormatValidations = () => {
    let errorArray = [];
    var showBeginDateError;
    var showDateError;
    var showPercentError;
    var showNumericError;
    var showCurrencyError;
    var showAlphaNumericError;
    var showTimestampError;
    var showLOBError;
    if (!selectedBeginDate) {
      showBeginDateError = true;
      setErrorMeg(SystemParameterAddConstants.BEGIN_DATE_REQUIRED);
      errorArray.push(SystemParameterAddConstants.BEGIN_DATE_REQUIRED);
      setShowError({ showBeginDateError: showBeginDateError });
    } else if (selectedBeginDate.toString() === "Invalid Date") {
      showBeginDateError = true;
      setErrorMeg(SystemParameterAddConstants.BEGIN_DATE_INVALID);
      errorArray.push(SystemParameterAddConstants.BEGIN_DATE_INVALID);
      setShowError({ showBeginDateError: showBeginDateError });
    } else if (DateUtils.validateDateMinimumValue(selectedBeginDate)) {
      showBeginDateError = true;
      setErrorMeg(AppConstants.DATE_ERROR_1964);
      errorArray.push(AppConstants.DATE_ERROR_1964);
      setShowError({ showBeginDateError: showBeginDateError });
    }
    if (values.dataFormat === "D" && !selectedFormatDate) {
      showDateError = true;
      setErrorMegDate(SystemParameterAddConstants.DATE_REQUIRED);
      errorArray.push(SystemParameterAddConstants.DATE_REQUIRED);
      setShowError({ showDateError: showDateError });
    } else if (
      values.dataFormat === "D" &&
      selectedFormatDate.toString() === "Invalid Date"
    ) {
      showDateError = true;
      setErrorMegDate(SystemParameterAddConstants.DATE_INVALID);
      errorArray.push(SystemParameterAddConstants.DATE_INVALID);
      setShowError({ showDateError: showDateError });
    } else if (
      values.dataFormat === "D" &&
      DateUtils.validateDateMinimumValue(selectedFormatDate)
    ) {
      showDateError = true;
      setErrorMegDate(AppConstants.DATE_ERROR_1964);
      errorArray.push(AppConstants.DATE_ERROR_1964);
      setShowError({ showDateError: showDateError });
    }
    if (
      selectedBeginDate &&
      selectedFormatDate &&
      values.dataFormat === "D" &&
      !showBeginDateError &&
      !showDateError
    ) {
      const beginDate = moment(new Date(selectedBeginDate)).format(
        "MM/DD/YYYY"
      );
      const formatDate = new Date(selectedFormatDate).getTime();
      seterrorMessages(errorArray);
      return true;
    }
    if (values.dataFormat === "P") {
      if (!dataElement.format) {
        showPercentError = true;
        setShowError({ showPercentError: showPercentError });
        setErrorMegPercent(SystemParameterAddConstants.PERCENT_REQUIRED);
        errorArray.push(SystemParameterAddConstants.PERCENT_REQUIRED);
      } else {
        const regex = /^[0-9]{1}(\.[0-9]{0,4})?$/;
        if (!regex.test(dataElement.format)) {
          showPercentError = true;
          setShowError({ showPercentError: showPercentError });
          setErrorMegPercent(SystemParameterAddConstants.PERCENT_INVALID);
          errorArray.push(SystemParameterAddConstants.PERCENT_INVALID);
        }
      }
    }
    if (values.dataFormat === "N") {
      if (!dataElement.format) {
        showNumericError = true;
        setShowError({ showNumericError: showNumericError });
        setErrorMegNumeric(SystemParameterAddConstants.NUMERIC_REQUIRED);
        errorArray.push(SystemParameterAddConstants.NUMERIC_REQUIRED);
      } else {
        const regex = /^[0-9]+$/;
        if (!regex.test(dataElement.format)) {
          showNumericError = true;
          setShowError({ showNumericError: showNumericError });
          setErrorMegNumeric(SystemParameterAddConstants.NUMERIC_INVALID);
          errorArray.push(SystemParameterAddConstants.NUMERIC_INVALID);
        }
      }
    }
    if (values.dataFormat === "C") {
      if (!dataElement.format) {
        showCurrencyError = true;
        setShowError({ showCurrencyError: showCurrencyError });
        setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_REQUIRED);
        errorArray.push(SystemParameterAddConstants.CURRENCY_REQUIRED);
      } else {
        if (
          dataElement.format
            .toString()
            .replace(/,/g, "")
            .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,4}$/) === null
        ) {
          showCurrencyError = true;
          setShowError({ showCurrencyError: showCurrencyError });
          setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_INVALID);
          errorArray.push(SystemParameterAddConstants.CURRENCY_INVALID);
        } else {
          if (
            !(
              dataElement.format
                .toString()
                .replace(/,/g, "")
                .match(/^[-]?[0-9]{10,15}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            showCurrencyError = true;
            setShowError({ showCurrencyError: showCurrencyError });
            setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_INVALID);
            errorArray.push(SystemParameterAddConstants.CURRENCY_INVALID);
          }
        }
      }
    }
    if (values.dataFormat === "T") {
      if (!dataElement.format) {
        showAlphaNumericError = true;
        setShowError({ showAlphaNumericError: showAlphaNumericError });
        setErrorMegAlpha(SystemParameterAddConstants.ALPHA_NUMERIC_REQUIRED);
        errorArray.push(SystemParameterAddConstants.ALPHA_NUMERIC_REQUIRED);
      } else {
        const regex = /^[a-zA-Z0-9]+(\s+[a-zA-Z0-9]+)*\s?$/;
        if (!regex.test(dataElement.format)) {
          showAlphaNumericError = true;
          setShowError({ showAlphaNumericError: showAlphaNumericError });
          setErrorMegAlpha(SystemParameterAddConstants.ALPHA_NUMERIC_INVALID);
          errorArray.push(SystemParameterAddConstants.ALPHA_NUMERIC_INVALID);
        }
      }
    }
    if (values.dataFormat === "Z") {
      if (!dataElement.format) {
        showTimestampError = true;
        setShowError({ showTimestampError: showTimestampError });
        setErrorMegTime(SystemParameterAddConstants.TIMESTAMP_REQUIRED);
        errorArray.push(SystemParameterAddConstants.TIMESTAMP_INVALID);
      } else {
        const regex = SystemParameterAddConstants.TIMESTAMP_REGEX;
        if (!regex.test(dataElement.format)) {
          showTimestampError = true;
          setShowError({ showTimestampError: showTimestampError });
          setErrorMegTime(SystemParameterAddConstants.TIMESTAMP_INVALID);
          errorArray.push(SystemParameterAddConstants.TIMESTAMP_INVALID);
        }
      }
    }
    setShowError({
      showAlphaNumericError: showAlphaNumericError,
      showDateError: showDateError,
      showCurrencyError: showCurrencyError,
      showBeginDateError: showBeginDateError,
      showNumericError: showNumericError,
      showPercentError: showPercentError,
      showTimestampError: showTimestampError,
      showLOBError: showLOBError
    });
    seterrorMessages(errorArray);
    if (
      !showTimestampError &&
      !showDateError &&
      !showAlphaNumericError &&
      !showCurrencyError &&
      !showBeginDateError &&
      !showNumericError &&
      !showPercentError &&
      !showLOBError
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkForBeginDateValidValue = dateString => {
    // First checking for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
      return false;
    }

    // Parsing the date parts to integers
    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Checking the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) {
      return false;
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    // Checking the range of the day
    return day > 0 && day <= monthLength[month - 1];
  };

  const showDataFormatTable = formatValue => {
    let addHeadCellsData = [];
    formatValue = formatValue.toString();
    if (formatValue === "C") {
      addHeadCellsData = [];
      addHeadCellsData = systemParameterAddHeadCells;
      addHeadCellsData.pop();
      addHeadCellsData.push({
        id: "format",
        numeric: false,
        disablePadding: false,
        label: "Currency",
        isSPBalance: true,
        width: "34%"
      });
      setSystemParameterAddHeadCells(addHeadCellsData);
    } else if (formatValue === "P") {
      addHeadCellsData = systemParameterAddHeadCells;
      addHeadCellsData.pop();
      addHeadCellsData.push({
        id: "format",
        numeric: false,
        disablePadding: false,
        label: "Percent",
        width: "34%"
      });
      setSystemParameterAddHeadCells(addHeadCellsData);
    } else if (formatValue === "D") {
      addHeadCellsData = systemParameterAddHeadCells;
      addHeadCellsData.pop();
      addHeadCellsData.push({
        id: "format",
        numeric: false,
        disablePadding: false,
        label: "Date",
        isDate: true,
        width: "34%"
      });
      setSystemParameterAddHeadCells(addHeadCellsData);
    } else if (formatValue === "N") {
      addHeadCellsData = systemParameterAddHeadCells;
      addHeadCellsData.pop();
      addHeadCellsData.push({
        id: "format",
        numeric: false,
        disablePadding: false,
        label: "Number",
        width: "34%"
      });
      setSystemParameterAddHeadCells(addHeadCellsData);
    } else if (formatValue === "T") {
      addHeadCellsData = systemParameterAddHeadCells;
      addHeadCellsData.pop();
      addHeadCellsData.push({
        id: "format",
        numeric: false,
        disablePadding: false,
        label: "Text",
        width: "34%"
      });
      setSystemParameterAddHeadCells(addHeadCellsData);
    } else if (formatValue === "Z") {
      addHeadCellsData = systemParameterAddHeadCells;
      addHeadCellsData.pop();
      addHeadCellsData.push({
        id: "format",
        numeric: false,
        disablePadding: false,
        label: "Timestamp",
        width: "34%"
      });
      setSystemParameterAddHeadCells(addHeadCellsData);
    }

    if (formatValue === DEFAULT_DD_VALUE) {
      setShowForm(false);
    } else {
      setShowForm(true);
      setShowError(false);
      errorMessagesArray = [];
      seterrorMessages([]);
      if (previousDataformatValue === formatValue) {
      } else {
        setnewData([]);
        setDataElement({
          ...dataElement,
          beginDateShowInTable: null,
          beginDate: null,
          endDate: null,
          format: null
        });
      }
    }
  };
  let newDataCopy=[]

  const addParameter = () => {
    var showBeginDateError;
    var showDateError;
    var showTimestampError;
    var showPercentError;
    var showNumericError;
    var showCurrencyError;
    var showAlphaNumericError = false;
    var showLOBError = false;
    if (add) {
      if (!selectedBeginDate) {
        showBeginDateError = true;
        setErrorMeg(SystemParameterAddConstants.BEGIN_DATE_REQUIRED);
      }

      if (values.dataFormat === "D" && !selectedFormatDate) {
        showDateError = true;
        setErrorMegDate(SystemParameterAddConstants.DATE_REQUIRED);
      } else {
        if (checkForBeginDateValidValue(selectedFormatDate)) {
          showDateError = false;
        } else {
          showDateError = true;
          setErrorMegDate(SystemParameterAddConstants.DATE_INVALID);
        }
      }

      if (values.dataFormat === "P") {
        if (!dataElement.format) {
          showPercentError = true;
          setErrorMegPercent(SystemParameterAddConstants.PERCENT_REQUIRED);
        } else {
          var regex = /^[0-9]{1}\.[0-9]{1,4}$/;
          if (regex.test(dataElement.format)) {
            showPercentError = false;
          } else {
            showPercentError = true;
            setShowError({ showPercentError: showPercentError });
            setErrorMegPercent(SystemParameterAddConstants.PERCENT_INVALID);
          }
        }
      }

      if (values.dataFormat === "N") {
        if (!dataElement.format) {
          showNumericError = true;
          setErrorMegNumeric(SystemParameterAddConstants.NUMERIC_REQUIRED);
        } else {
          const regex = /^[0-9]+$/;
          if (regex.test(dataElement.format)) {
            showNumericError = false;
          } else {
            showNumericError = true;
            setShowError({ showNumericError: showNumericError });
            setErrorMegNumeric(SystemParameterAddConstants.NUMERIC_INVALID);
          }
        }
      }

      if (values.dataFormat === "C") {
        if (!dataElement.format) {
          showCurrencyError = true;
          setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_REQUIRED);
        } else {
          if (
            dataElement.format
              .toString()
              .replace(/,/g, "")
              .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,4}$/) === null
          ) {
            showCurrencyError = true;
            setShowError({ showCurrencyError: showCurrencyError });
            setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_INVALID);
          } else {
            if (
              !(
                dataElement.format
                  .toString()
                  .replace(/,/g, "")
                  .match(/^[-]?[0-9]{10,15}\.?[0-9]{0,2}?$/) === null
              )
            ) {
              showCurrencyError = true;
              setShowError({ showCurrencyError: showCurrencyError });
              setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_INVALID);
            }
          }
        }
      }

      if (values.dataFormat === "T") {
        if (!dataElement.format) {
          showAlphaNumericError = true;
          setErrorMegAlpha(SystemParameterAddConstants.ALPHA_NUMERIC_REQUIRED);
        } else {
          const regex = /^[a-zA-Z0-9]+(\s+[a-zA-Z0-9]+)*\s?$/;
          if (regex.test(dataElement.format)) {
            showAlphaNumericError = false;
          } else {
            showAlphaNumericError = true;
            setShowError({ showAlphaNumericError: showAlphaNumericError });
            setErrorMegAlpha(SystemParameterAddConstants.ALPHA_NUMERIC_INVALID);
          }
        }
      }

      if (values.dataFormat === "Z") {
        if (!dataElement.format) {
          showTimestampError = true;
          setErrorMegTime(SystemParameterAddConstants.TIMESTAMP_REQUIRED);
        } else {
          const regex = SystemParameterAddConstants.TIMESTAMP_REGEX;
          if (regex.test(dataElement.format)) {
            showTimestampError = false;
          } else {
            showTimestampError = true;
            setShowError({ showTimestampError: showTimestampError });
            setErrorMegTime(SystemParameterAddConstants.TIMESTAMP_INVALID);
          }
        }
      }

      setShowError({
        showBeginDateError: showBeginDateError,
        showDateError: showDateError,
        showPercentError: showPercentError,
        showAlphaNumericError: showAlphaNumericError,
        showTimestampError: showTimestampError,
        showCurrencyError: showCurrencyError,
        showNumericError: showNumericError,
        showLOBError: showLOBError
      });

      if (checkFormatValidations()) {
        setShowForm(true);
        setOpen(false);
        setId(id + 1);
        dataElement.id = id;

        if (begindatePress) {
          dataElement.beginDateShowInTable = selectedBeginDate;
          dataElement.beginDate = moment(selectedBeginDate).format(
            "MM/DD/YYYY"
          );
        } else {
          dataElement.beginDateShowInTable = DateUtils.setSelectedDate(
            selectedBeginDate
          );
          dataElement.beginDate =moment(selectedBeginDate).format(
          "MM/DD/YYYY"
          );
        }

        if (values.dataFormat === "D") {
          if (formatdatePress) {
            dataElement.format = moment(selectedFormatDate).format("L");
          } else {
            dataElement.format = DateUtils.setSelectedDate(selectedFormatDate);
          }
        }

        let count = 0;
        if (newData.length > 0) {
          newData.map((value, index) => {
            let checkDate = moment((value.beginDateShowInTable)).format(
              "MM/DD/YYYY"
            );
            let valueDate = moment(
              (dataElement.beginDateShowInTable)
            ).format("MM/DD/YYYY");

            if (checkDate === valueDate) {
              count = count + 1;
            }
          });
        }

        var showDateOverlappingError = false;
        var showBeginDateInvalidError = false;
        if (count > 0) {
          errorMessagesArray = [];
          seterrorMessages([]);
          showBeginDateInvalidError = false;
          showDateOverlappingError = true;
          errorMessagesArray.push(systemParamAddCons.BEGIN_DATE_UNIQUE);
          seterrorMessages(errorMessagesArray);
          setShowError({ showDateOverlappingError: showDateOverlappingError });
        }

        if (newData.length > 0 && count === 0) {
          if (!showDateOverlappingError) {
            dataElement.lob = values.lobDetail;
            dataElement.lobShowInTable = lobCodeDescription(
              values.lobDetail,
              lobCodeData
            );
            newData.push(dataElement)
            newDataCopy=adjustEndDates(newData);//gives the updated newData after adjusting the enddates.
          }
        } else if (!showDateOverlappingError) {
          errorMessagesArray = [];
          seterrorMessages([]);
          setShowError(false);
          dataElement.endDateShowInTable = moment(
            "9999-12-31"
          )            
            .format("MM/DD/YYYY");
          dataElement.endDate = moment(
            "9999-12-31"
          ).format("L");
          dataElement.lob = values.lobDetail;
          dataElement.lobShowInTable = lobCodeDescription(
            values.lobDetail,
            lobCodeData
          );
          newData.push(dataElement);
          newDataCopy=adjustEndDates(newData);
        }

        requestTableData = [];

        if (newDataCopy.length === 0 || count === 0) {
          newDataCopy.map((value, index) => {
            let dataElementToSend = {};
            dataElementToSend.id = value.id;
            if (values.dataFormat === "D") {
              dataElementToSend.valueDate = moment(
                value.format
              ).format("MM/DD/YYYY");
            } else {
              dataElementToSend.valueDate = null;
            }
            if (value.lob !== "") {
              dataElementToSend.lob = value.lob;
            }
            if (values.dataFormat === "T") {
              dataElementToSend.valueData = value.format;
            } else {
              dataElementToSend.valueData = null;
            }

            if (values.dataFormat === "C") {
              dataElementToSend.valueAmt = parseFloat(
                value.format.toString().replace(/,/g, "")
              );
            } else {
              dataElementToSend.valueAmt = null;
            }

            if (values.dataFormat === "N") {
              dataElementToSend.valueNum = value.format;
            } else {
              dataElementToSend.valueNum = null;
            }

            if (values.dataFormat === "P") {
              dataElementToSend.valuePCT = value.format;
            } else {
              dataElementToSend.valuePCT = null;
            }

            if (values.dataFormat === "Z") {
              showTimestampError = false;
              let tempFormat = value.format;
              if (value.format && value.format.length === 10) {
                tempFormat = tempFormat + " 00:00:00.000000";
              } else if (value.format && value.format.length === 19) {
                tempFormat = tempFormat + ".000000";
              } else if (value.format && value.format.length === 29) {
                tempFormat = tempFormat ? tempFormat.slice(0, -3) : tempFormat;
              }
              dataElementToSend.valueTimeStamp = tempFormat;
              value.format = tempFormat;
            } else {
              dataElementToSend.valueTimeStamp = null;
            }

            dataElementToSend.auditUserID = logInUserID;
            dataElementToSend.auditTimeStamp = DateUtils.getUTCTimeStamp();
            dataElementToSend.addedAuditUserID = logInUserID;
            dataElementToSend.addedAuditTimeStamp = DateUtils.getUTCTimeStamp();
            dataElementToSend.versionNo = "0";
            dataElementToSend.beginDate = moment(
              (value.beginDateShowInTable)
            ).format("MM/DD/YYYY");
            dataElementToSend.endDate = moment(
              (value.endDateShowInTable)
            ).format("MM/DD/YYYY");
            dataElementToSend.voidDate = null;
            dataElementToSend.tempValue = null;
            dataElementToSend.parameterDetails = null;
            const detailIdObject = {};
            detailIdObject.functionalArea = values.functionalArea;
            detailIdObject.parameterNumber = values.paramNumber;
            detailIdObject.parameterDetailSK = null;
            dataElementToSend.systemParameterDetailID = detailIdObject;
            let finalDataElementToSend = {};
            finalDataElementToSend = dataElementToSend;
            requestTableData.push(finalDataElementToSend);
            setDesNewData(requestTableData)// set the add data to desnewData
            finalDataElementToSend = {};
            dataElementToSend = {};
          });
        }

        setShowError(false);
      }
    } else {
      if (!selectedBeginDate) {
        showBeginDateError = true;
        setErrorMeg(SystemParameterAddConstants.BEGIN_DATE_REQUIRED);
      }

      if (values.dataFormat === "D" && !selectedFormatDate) {
        showDateError = true;
        setErrorMegDate(SystemParameterAddConstants.DATE_REQUIRED);
      } else {
        if (checkForBeginDateValidValue(selectedFormatDate)) {
          showDateError = false;
        } else {
          showDateError = true;
          setErrorMegDate(SystemParameterAddConstants.DATE_INVALID);
        }
      }

      if (values.dataFormat === "P") {
        if (!dataElement.format) {
          showPercentError = true;
          setErrorMegPercent(SystemParameterAddConstants.PERCENT_REQUIRED);
        } else {
          const regex = /^[0-9]{1}(\.[0-9]{0,4})?$/;
          if (regex.test(dataElement.format)) {
            showPercentError = false;
          } else {
            showPercentError = true;
            setShowError({ showPercentError: showPercentError });
            setErrorMegPercent(SystemParameterAddConstants.PERCENT_INVALID);
          }
        }
      }

      if (values.dataFormat === "N") {
        if (!dataElement.format) {
          showNumericError = true;
          setErrorMegNumeric(SystemParameterAddConstants.NUMERIC_REQUIRED);
        } else {
          const regex = /^[0-9]+$/;
          if (regex.test(dataElement.format)) {
            showNumericError = false;
          } else {
            showNumericError = true;
            setShowError({ showNumericError: showNumericError });
            setErrorMegNumeric(SystemParameterAddConstants.NUMERIC_INVALID);
          }
        }
      }

      if (values.dataFormat === "C") {
        if (!dataElement.format) {
          showCurrencyError = true;
          setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_REQUIRED);
        } else {
          if (
            dataElement.format
              .toString()
              .replace(/,/g, "")
              .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,4}$/) === null
          ) {
            showCurrencyError = true;
            setShowError({ showCurrencyError: showCurrencyError });
            setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_INVALID);
          } else {
            if (
              !(
                dataElement.format
                  .toString()
                  .replace(/,/g, "")
                  .match(/^[-]?[0-9]{10,15}\.?[0-9]{0,2}?$/) === null
              )
            ) {
              showCurrencyError = true;
              setShowError({ showCurrencyError: showCurrencyError });
              setErrorMegCurrency(SystemParameterAddConstants.CURRENCY_INVALID);
            }
          }
        }
      }

      if (values.dataFormat === "T") {
        if (!dataElement.format) {
          showAlphaNumericError = true;
          setErrorMegAlpha(SystemParameterAddConstants.ALPHA_NUMERIC_REQUIRED);
        } else {
          const regex = /^[a-zA-Z0-9]+(\s+[a-zA-Z0-9]+)*\s?$/;
          if (regex.test(dataElement.format)) {
            showAlphaNumericError = false;
          } else {
            showAlphaNumericError = true;
            setShowError({ showAlphaNumericError: showAlphaNumericError });
            setErrorMegAlpha(SystemParameterAddConstants.ALPHA_NUMERIC_INVALID);
          }
        }
      }

      if (values.dataFormat === "Z") {
        if (!dataElement.format) {
          showTimestampError = true;
          setErrorMegTime(SystemParameterAddConstants.TIMESTAMP_REQUIRED);
        } else {
          const regex = SystemParameterAddConstants.TIMESTAMP_REGEX;
          if (regex.test(dataElement.format)) {
            showTimestampError = false;
            let tempFormat = dataElement.format;
            if (dataElement.format && dataElement.format.length === 10) {
              tempFormat = tempFormat + " 00:00:00.000000";
            } else if (dataElement.format && dataElement.format.length === 19) {
              tempFormat = tempFormat + ".000000";
            } else if (dataElement.format && dataElement.format.length === 29) {
              tempFormat = tempFormat ? tempFormat.slice(0, -3) : tempFormat;
            }
            setDataElement({ ...dataElement, format: tempFormat });
          } else {
            showTimestampError = true;
            setShowError({ showTimestampError: showTimestampError });
            setErrorMegTime(SystemParameterAddConstants.TIMESTAMP_INVALID);
          }
        }
      }

      setShowError({
        showBeginDateError: showBeginDateError,
        showDateError: showDateError,
        showPercentError: showPercentError,
        showAlphaNumericError: showAlphaNumericError,
        showTimestampError: showTimestampError,
        showCurrencyError: showCurrencyError,
        showNumericError: showNumericError,
        showLOBError: showLOBError
      });

      if (checkFormatValidations()) {
        setAdd(true);
        setOpen(false);
        if (begindatePress) {
          dataElement.beginDateShowInTable = selectedBeginDate;
        } else {
          if (
            selectedBeginDate.getMonth() + 1 < 10 &&
            selectedBeginDate.getMonth() + 1 > 0 &&
            selectedBeginDate.getDate() < 10 &&
            selectedBeginDate.getDate() > 0
          ) {
            dataElement.beginDateShowInTable =
              "0" +
              (selectedBeginDate.getMonth() + 1) +
              "/0" +
              selectedBeginDate.getDate() +
              "/" +
              selectedBeginDate.getFullYear();
          } else if (
            selectedBeginDate.getMonth() + 1 < 10 &&
            selectedBeginDate.getMonth() + 1 > 0
          ) {
            dataElement.beginDateShowInTable =
              "0" +
              (selectedBeginDate.getMonth() + 1) +
              "/" +
              selectedBeginDate.getDate() +
              "/" +
              selectedBeginDate.getFullYear();
          } else if (
            selectedBeginDate.getDate() < 10 &&
            selectedBeginDate.getDate() > 0
          ) {
            dataElement.beginDateShowInTable =
              selectedBeginDate.getMonth() +
              1 +
              "/0" +
              selectedBeginDate.getDate() +
              "/" +
              selectedBeginDate.getFullYear();
          } else {
            dataElement.beginDateShowInTable =
              selectedBeginDate.getMonth() +
              1 +
              "/" +
              selectedBeginDate.getDate() +
              "/" +
              selectedBeginDate.getFullYear();
          }
        }

        if (values.dataFormat === "D") {
          if (formatdatePress) {
            dataElement.format = selectedFormatDate;
          } else {
            if (
              selectedFormatDate.getMonth() + 1 < 10 &&
              selectedFormatDate.getMonth() + 1 > 0 &&
              selectedFormatDate.getDate() < 10 &&
              selectedFormatDate.getDate() > 0
            ) {
              dataElement.format =
                "0" +
                (selectedFormatDate.getMonth() + 1) +
                "/0" +
                selectedFormatDate.getDate() +
                "/" +
                selectedFormatDate.getFullYear();
            } else if (
              selectedFormatDate.getMonth() + 1 < 10 &&
              selectedFormatDate.getMonth() + 1 > 0
            ) {
              dataElement.format =
                "0" +
                (selectedFormatDate.getMonth() + 1) +
                "/" +
                selectedFormatDate.getDate() +
                "/" +
                selectedFormatDate.getFullYear();
            } else if (
              selectedFormatDate.getDate() < 10 &&
              selectedFormatDate.getDate() > 0
            ) {
              dataElement.format =
                selectedFormatDate.getMonth() +
                1 +
                "/0" +
                selectedFormatDate.getDate() +
                "/" +
                selectedFormatDate.getFullYear();
            } else {
              dataElement.format =
                selectedFormatDate.getMonth() +
                1 +
                "/" +
                selectedFormatDate.getDate() +
                "/" +
                selectedFormatDate.getFullYear();
            }
          }
        }

        let count = 0;
        var showDateOverlappingError = false;
        newData.map((value, index) => {
          if (value.id !== currentParameterSK.id) {
            if (value.beginDate === moment((selectedBeginDate)).format("L")) {
              count = count + 1;
            }
          }});
          if (count > 0) {
            errorMessagesArray = [];
            seterrorMessages([]);
            showDateOverlappingError = true;
            errorMessagesArray.push(
              systemParamAddCons.BEGIN_DATE_OVERLAPPING
            );
            seterrorMessages(errorMessagesArray);
            setShowError({
              showDateOverlappingError: showDateOverlappingError
            });
            window.scrollTo(0, 0);
            return showDateOverlappingError;
          }else {
          const selectedDate = new Date(selectedBeginDate);
          const formatDateSelected = new Date(selectedFormatDate);
          if (
            selectedDate instanceof Date &&
            !isNaN(selectedDate.valueOf())
          ) {
            if (beginDateChange) {
              if (
                newData&&newData.length>0
              ) {
                newData[
                  newData.indexOf(editData)
                ].beginDate = moment((selectedBeginDate)).format("L");
                newData[
                  newData.indexOf(editData)
                ].beginDateShowInTable = moment((selectedBeginDate)).format("L");

              }
            }
            if (values.lobDetail) {
              newData[
                newData.indexOf(editData)
              ].lobShowInTable = lobCodeDescription(
                values.lobDetail,
                lobCodeData
              );
              newData[newData.indexOf(editData)].lob = values.lobDetail;
            }

            if (values.dataFormat === "C") {
              newData[newData.indexOf(editData)].format = parseFloat(
                dataElement.format.toString().replace(/,/g, "")
              );
            }

            if (values.dataFormat === "N") {
              newData[newData.indexOf(editData)].format = dataElement.format;
            }

            if (values.dataFormat === "P") {
              newData[newData.indexOf(editData)].format = dataElement.format;
            }

            if (values.dataFormat === "T") {
              newData[newData.indexOf(editData)].format = dataElement.format;
            }

            if (values.dataFormat === "Z") {
              let tempFormat = dataElement.format;
              if (dataElement.format && dataElement.format.length === 10) {
                tempFormat = tempFormat + " 00:00:00.000000";
              } else if (
                dataElement.format &&
                dataElement.format.length === 19
              ) {
                tempFormat = tempFormat + ".000000";
              } else if (
                dataElement.format &&
                dataElement.format.length === 29
              ) {
                tempFormat = tempFormat
                  ? tempFormat.slice(0, -3)
                  : tempFormat;
              }
              newData[newData.indexOf(editData)].format = tempFormat;
            }

            if (values.dataFormat === "D") {
              if (
                formatDateSelected instanceof Date &&
                !isNaN(formatDateSelected.valueOf())
              ) {
                newData[newData.indexOf(editData)].format = moment(
                  (selectedFormatDate)
                );
              } else {
                errorMessagesArray.push(systemParamAddCons.DATE_INVALID);
                seterrorMessages(errorMessagesArray);
              }
            }
          } else {
            errorMessagesArray.push(systemParamAddCons.BEGIN_DATE_INVALID);
            seterrorMessages(errorMessagesArray);
          }
        }
        requestTableDataEdit = [];
      if(!showDateOverlappingError){
        newDataCopy= adjustEndDates(newData);
        if (newDataCopy.length === 0 || count === 0) {
          newDataCopy.map((value, index) => {
            let dataElementToSendEdit = {};
            dataElementToSendEdit.id = value.id;

            if (values.dataFormat === "D") {
              dataElementToSendEdit.valueDate = moment(
               (value.format)
              ).format("MM/DD/YYYY");
            } else {
              dataElementToSendEdit.valueDate = null;
            }

            if (values.dataFormat === "T") {
              dataElementToSendEdit.valueData = value.format;
            } else {
              dataElementToSendEdit.valueData = null;
            }

            if (values.dataFormat === "C") {
              dataElementToSendEdit.valueAmt = parseFloat(
                value.format.toString().replace(/,/g, "")
              );
            } else {
              dataElementToSendEdit.valueAmt = null;
            }

            if (values.dataFormat === "N") {
              dataElementToSendEdit.valueNum = value.format;
            } else {
              dataElementToSendEdit.valueNum = null;
            }
            if (value.lob !== "") {
              dataElementToSendEdit.lob = value.lob;
            }

            if (values.dataFormat === "P") {
              dataElementToSendEdit.valuePCT = value.format;
            } else {
              dataElementToSendEdit.valuePCT = null;
            }

            if (values.dataFormat === "Z") {
              showTimestampError = false;
              let tempFormat = value.format;
              if (value.format && value.format.length === 10) {
                tempFormat = tempFormat + " 00:00:00.000000";
              } else if (value.format && value.format.length === 19) {
                tempFormat = tempFormat + ".000000";
              } else if (value.format && value.format.length === 29) {
                tempFormat = tempFormat ? tempFormat.slice(0, -3) : tempFormat;
              }
              dataElementToSendEdit.valueTimeStamp = tempFormat;
            } else {
              dataElementToSendEdit.valueTimeStamp = null;
            }

            dataElementToSendEdit.auditUserID = logInUserID;
            dataElementToSendEdit.auditTimeStamp = DateUtils.getUTCTimeStamp();
            dataElementToSendEdit.addedAuditUserID = logInUserID;
            dataElementToSendEdit.addedAuditTimeStamp = DateUtils.getUTCTimeStamp();
            dataElementToSendEdit.versionNo = "0";
            dataElementToSendEdit.beginDate = moment(
              (value.beginDateShowInTable)
            ).format("MM/DD/YYYY");
            dataElementToSendEdit.endDate = moment(
              (value.endDateShowInTable)
            ).format("MM/DD/YYYY");
            dataElementToSendEdit.voidDate = null;
            dataElementToSendEdit.tempValue = null;
            dataElementToSendEdit.parameterDetails = null;
            const detailIdObject = {};
            detailIdObject.functionalArea = values.functionalArea;
            detailIdObject.parameterNumber = values.paramNumber;
            detailIdObject.parameterDetailSK = null;
            dataElementToSendEdit.systemParameterDetailID = detailIdObject;
            let finalDataElementToSendEdit = {};
            finalDataElementToSendEdit = dataElementToSendEdit;
            requestTableDataEdit.push(finalDataElementToSendEdit);
            setDesNewData(requestTableDataEdit) //set's the updated data in desNewData
            dataElementToSendEdit = {};
            finalDataElementToSendEdit = {};
          });
        }
        setShowError(false);}
      }
      setcurrentParameterSK("");}
    //setnewData(newData);
    setisNewADD(true);
  };
  const [isNewADD , setisNewADD] = React.useState(false)
useEffect(()=>
// here the id is getting added temporary purpose which helps to delete the Data with the reference of ID
{if(isNewADD)
{  newData.map((item,i)=>{   
    desNewData[i].id= item['id']   
  })
  setisNewADD(false);} 
});
const [currentParameterSK, setcurrentParameterSK] = React.useState();
  const beginDateSelected = new Date(selectedBeginDate).getTime();
  const adjustEndDates = newDatai => {
    /*
    //commented this code for the 296819 fix 
     const activeEndDate = new Date("9999-12-31T18:30:00.000+0000").getTime();
    var activeBeginDate;
    let activeIndex;
    let activeRecord;
    let isInBetween = false;

    let aDayBefore = new Date(beginDateSelected);

    const beforeDate = aDayBefore.setDate(aDayBefore.getDate() - 1);
    let beforDateLeast = beforeDate;

    newData.map((option, index) => {
      if (new Date(option.endDate).getTime() === activeEndDate) {
        activeIndex = index;
        const beginDate = new Date(option.beginDate).getTime();
        activeBeginDate = beginDate;
        activeRecord = option;
        return true;
      }
    });

    if (beginDateSelected > activeBeginDate) {
      value.endDate = activeRecord.endDate;
      value.endDateShowInTable = moment(new Date(activeRecord.endDate))
        .utc()
        .format("MM/DD/YYYY");
      newData[activeIndex].endDate = beforeDate;
      newData[activeIndex].endDateShowInTable = new Date(beforeDate);

      if (add) {
        newData.push(value);
      } else {
        if (beginDateChange) {
          newData.map((option, index) => {
            if (
              new Date(option.endDate).getTime() ===
              new Date(activeBeginDate).setDate(
                new Date(activeBeginDate).getDate() - 1
              )
            ) {
              newData[newData.indexOf(editData) - 1].endDate = new Date(
                beginDateSelected
              ).setDate(new Date(beginDateSelected).getDate() - 1);
              newData[
                newData.indexOf(editData) - 1
              ].endDateShowInTable = new Date(beginDateSelected).setDate(
                new Date(beginDateSelected).getDate() - 1
              );
            }
          });
          newData[newData.indexOf(editData)].beginDate = selectedBeginDate;
          newData[newData.indexOf(editData)].endDate = new Date(
            "9999-12-31T18:30:00.000+0000"
          ).getTime();
          newData[newData.indexOf(editData)].beginDateShowInTable = moment(
            new Date(selectedBeginDate)
          );
          newData[newData.indexOf(editData)].endDateShowInTable = moment(
            new Date("9999-12-31T18:30:00.000+0000")
          )
            .utc()
            .format("MM/DD/YYYY");
        }
      }
      return true;
    } else {
      aDayBefore = new Date(activeRecord.beginDate);
      const beforeDateLeastCheck = aDayBefore.setDate(aDayBefore.getDate() - 1);
      beforDateLeast = beforeDateLeastCheck;

      newData.map((option, index) => {
        if (
          beginDateSelected > new Date(option.beginDate).getTime() &&
          beginDateSelected < new Date(option.endDate)
        ) {
          activeRecord = option;
          activeIndex = index;
          isInBetween = true;
          return true;
        } else if (
          new Date(option.beginDate).getTime() <= beforeDateLeastCheck &&
          new Date(option.beginDate).getTime() > beginDateSelected
        ) {
          if (new Date(option.beginDate).getTime() <= beforDateLeast) {
            const beforeBeginDate = new Date(option.beginDate);
            beforDateLeast = beforeBeginDate.setDate(
              beforeBeginDate.getDate() - 1
            );
            return true;
          }
        }
      });

      if (isInBetween) {
        value.beginDate = beginDateSelected;
        value.endDate = activeRecord.endDate;
        value.endDateShowInTable = new Date(activeRecord.endDate);
        activeRecord.endDate = beforeDate;
        activeRecord.endDateShowInTable = new Date(beforeDate);

        if (add) {
          newData.push(value);
        } else {
          if (beginDateChange) {
            newData[newData.indexOf(editData)].beginDate = beginDateSelected;
            newData[newData.indexOf(editData)].endDate = beforDateLeast;
            newData[newData.indexOf(editData)].beginDateShowInTable = new Date(
              beginDateSelected
            );
            newData[newData.indexOf(editData)].endDateShowInTable = new Date(
              beforDateLeast
            );
          }
        }
      } else {
        value.beginDate = beginDateSelected;
        value.endDate = beforDateLeast;
        value.endDateShowInTable = new Date(beforDateLeast);
        if (add) {
          newData.push(value);
        } else {
          if (beginDateChange) {
            newData[newData.indexOf(editData)].beginDate = beginDateSelected;
            newData[newData.indexOf(editData)].endDate = beforDateLeast;
            newData[newData.indexOf(editData)].beginDateShowInTable = new Date(
              beginDateSelected
            );
            newData[newData.indexOf(editData)].endDateShowInTable = new Date(
              beforDateLeast
            );
          }
        }
      }
      return true;
    }
    setnewData(newData);
    */
   const tempArray = JSON.parse(
      JSON.stringify(
        newDatai.sort((a, b) =>
          Date.parse(a.beginDate) < Date.parse(b.beginDate) ? -1 : 1
        )
      )
    ); //gives the sorted records based the begindate
    tempArray.map((option, index) => {
      if (index === tempArray.length - 1) {
        option.endDate = moment("9999-12-31").format("L");//updates reocrd if it has highest date
        option.endDateShowInTable = moment(option.endDate).format("L")
      } else {

        option.endDate = moment(
         (tempArray[index + 1].beginDate)
        ).subtract(1,"days").format("L");//will subract a day from previous begin date and adds it as end date of new record.
        option.endDateShowInTable = moment(option.endDate).format("L")
      }
    });

    setnewData(tempArray);
    return tempArray // return the updated newData.
  };

  const checkFieldValidations = values => {
    //commented this code as part of 298387 fix
/*     let tempRequestTableData = requestTableData;
    for (let i = 0; i < tempRequestTableData.length; i++) {
      if (tempRequestTableData[i] !== undefined) {
        tempRequestTableData = tempRequestTableData.filter(
          payment => payment.id === newData[i].id
        );
      }
    }
    requestTableData = tempRequestTableData; */
    var showBlankRowError = false;
    var showFunctionalAreaError;
    var showParameterNoError;
    var showDataFormatError;
    var showDescriptionError = false;
    if (
      values.functionalArea !== AppConstants.PLEASE_SELECT_ONE &&
      values.paramNumber &&
      values.dataFormat !== AppConstants.PLEASE_SELECT_ONE &&
      values.description
    ) {
      if (newData.length === 0) {
        errorMessagesArray = [];
        seterrorMessages([]);
        showBlankRowError = true;
        errorMessagesArray.push(systemParamAddCons.DETAIL_ROW_REQUIRED);
        seterrorMessages(errorMessagesArray);
        setShowError({ showBlankRowError: showBlankRowError });
      } else {
        setShowError(false);
        seterrorMessages([]);
        setSpinnerLoader(true);

        if (desNewData.length > 0) {
          desNewData.map((value, index) => {
            value.lob = AppConstants.DEFAULT_LOB_VALUE;
          });
          //updates the endDate after deleting a row or updating a row
          desNewData.map((item,index)=>{
            item.endDate=newData[index].endDate         
        })

          const requestDataEdit = {
            auditUserID: logInUserID,
            auditTimeStamp: DateUtils.getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
            versionNo: 0,
            parameterTypeCode: values.dataFormat,
            functionalArea: values.functionalArea,
            parameterNumber: values.paramNumber,
            parameterName: values.description,
            systemParamID: {
              functionalArea: values.functionalArea,
              parameterNumber: values.paramNumber
            },
            systemParameterDetail: desNewData,
            deletedSysParameterDetails: null,
            noteSetVO: notesInput
          };
          dispatch(addSystemParameter(requestDataEdit));
        } else {
          desNewData.map((value, index) => {
            value.lob = AppConstants.DEFAULT_LOB_VALUE;
          });
          for(let i=0;i<desNewData.length;i++){
            delete desNewData[i]['id']; //delete's the 'id' key which was temporarly added
          }
           //updates the endDate after deleting a row or updating a row
          desNewData.map((item,index)=>{
            item.endDate=newData[index].endDate         
        })
          const requestData = {
            auditUserID: logInUserID,
            auditTimeStamp: DateUtils.getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
            versionNo: 0,
            parameterTypeCode: values.dataFormat,
            functionalArea: values.functionalArea,
            parameterNumber: values.paramNumber,
            parameterName: values.description,
            systemParamID: {
              functionalArea: values.functionalArea,
              parameterNumber: values.paramNumber
            },
            systemParameterDetail: desNewData,
            deletedSysParameterDetails: null,
            noteSetVO: notesInput
          };
          setAllowNavigation(false);
          dispatch(addSystemParameter(requestData));
        }
      }
    } else {
      errorMessagesArray = [];
      seterrorMessages([]);

      if (
        !values.functionalArea ||
        values.functionalArea === DEFAULT_DD_VALUE
      ) {
        showFunctionalAreaError = true;
        errorMessagesArray.push(systemParamAddCons.FUNCTIONAL_AREA_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }

      if (!values.paramNumber) {
        showParameterNoError = true;
        errorMessagesArray.push(systemParamAddCons.PARAMETER_NUMBER_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }

      if (!values.dataFormat || values.dataFormat === DEFAULT_DD_VALUE) {
        showDataFormatError = true;
        errorMessagesArray.push(systemParamAddCons.DATA_FORMAT_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }

      if (!values.description) {
        showDescriptionError = true;
        errorMessagesArray.push(systemParamAddCons.DESCRIPTION_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }

      setShowError({
        showFunctionalAreaError: showFunctionalAreaError,
        showParameterNoError: showParameterNoError,
        showDataFormatError: showDataFormatError,
        showDescriptionError: showDescriptionError
      });
    }
    //setnewData(newData);
  };

  const onClickEditSystemParameter = (data, label) => event => {
    setAdd(false);
    setBeginDateChange(false);
    setFormatChange(false);
    setDataElement({
      format: data.format
    });

    setSelectedBeginDate(new Date(data.beginDateShowInTable));
    setRetainBeginDate(new Date(data.beginDateShowInTable));
    setcurrentParameterSK(data);

    if (values.dataFormat === "D") { //changes did for as part of 298390 fix
      setSelectedFormatDate(moment(data.format).format("L"));
      setRetainFormatDate(moment(data.format).format("L"));
    }
    setOpen(true);
    setValues({ ...values, lobDetail: data.lob });
    setEditData(data);
    setRetainEdit(data);
  };

  const resetTable = () => {
    setFocusCheck(initialFocusCheck);
    setAllowNavigation(initialFocusCheck);
    seterrorMessages([]);
    if (add) {
      setDataElement({
        beginDate: null,
        format: "",
        beginDateShowInTable: ""
      });

      setValues({ ...values, lobDetail: DEFAULT_DD_VALUE });

      setSelectedBeginDate(null);
      if (values.dataFormat === "D") {
        setSelectedFormatDate(null);
      }
    } else {
      setDataElement(retainEdit);
      setSelectedFormatDate(retainFormatDate);
      setSelectedBeginDate(retainBeginDate);
      setValues({ ...values, lobDetail: retainEdit.lob });
    }
    setShowError(false);
  };

  const handleChangeDataElement = name => event => {
    setFocusCheck(true);
    const inputValue = event.target.value;
    const regex = /^[^\s].*/; 
    if (name === "format") {
      if (event.target.value && event.target.value.includes('.')) {
      if(checkingDecimal(event.target.value)){
        setDataElement({ ...dataElement, [name]: inputValue });
    setFormatError(false);
    setFormatChange(true);
      }    
    } else {
      setDataElement({ ...dataElement, [name]: inputValue });
    }
    }
    else{      
      setFormatError(true);
    }
  };

  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setIntialFocusCheck(true);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleDataFormatChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });
    showDataFormatTable(event.target.value);
    setPreviousDataFormatValue(event.target.value);
  };

  const rowDeleteSystemParameterDetails = data => {
    setRowSystemParameterData({
      ...rowSystemParameterData,
      rowSystemParameterData: data
    });
  };

  function systemParameterRowDeleteClick() {
    let temNewDialogData = [...newData];
    let sdesNewData=[...desNewData]; 
    // below forlOop will removes the selected record to delete.
    if (rowSystemParameterData.rowSystemParameterData) {
      for (
        let i = 0;
        i < rowSystemParameterData.rowSystemParameterData.length;
        i++
      ) {
        if (rowSystemParameterData.rowSystemParameterData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(
            payment =>
              payment.id !== rowSystemParameterData.rowSystemParameterData[i]
          );
        }
      }
    }
//updates the sdesNewData after deleting the row
    const result = sdesNewData.filter(c => temNewDialogData.some(s => s.id === c.id));

  setDesNewData(result); //updates desNewData after a record/records being deleted
  newDataCopy =adjustEndDates(temNewDialogData);// updates the data in table.
    setRowSystemParameterData([]);
  }
  const [desNewData, setDesNewData]=React.useState([])//to contain both add,update record after perfroming the respective action
  const formatCurrency = () => {
    if (dataElement.format && values.dataFormat === "C") {
      setDataElement({
        ...dataElement,
        format: numeral(dataElement.format).format("0,0.00")
      });
    }
  };
  const removeFormatCurrency = () => {
    if (dataElement.format && values.dataFormat === "C") {
      setDataElement({
        ...dataElement,
        format: numeral(dataElement.format).format("0.00")
      });
    }
  };
  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    setSuccessResponse([]);
    if (
      !values.functionalArea ||
      values.functionalArea === DEFAULT_DD_VALUE ||
      !values.paramNumber ||
      values.paramNumber === "" ||
      !values.dataFormat ||
      values.dataFormat === DEFAULT_DD_VALUE ||
      !values.description ||
      values.description === ""
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessResponse([]);
    seterrorMessages([]);
    setShowError(false);
  };
  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(()=>{
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/SystemParameter'
      });
    },100)
  };
  const majorCancel = () => {
    seterrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/SystemParameter'
      });
    }
  };

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      <ToastsContainer
        store={ToastsStore}
        position={ToastsContainerPosition.TOP_RIGHT}
      />
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <div className="tab-heading float-left">
            {SystemParameterAddConstants.ADD_SYSTEM_PARAMETER_TEXT}
          </div>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              onClick={() => checkFieldValidations(values)}
            >
              <i class="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            {window.location.pathname === '/SystemParametersAdd' && (
            <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
                  color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
            <div className="form-wrapper">
                <NativeDropDown
                  id="functional-area"
                  isRequired
                  label="Functional Area"
                  value={values.functionalArea}
                  onChange={handleChange("functionalArea")}
                  errTxt={
                    showFunctionalAreaError
                      ? SystemParameterAddConstants.FUNCTIONAL_AREA_REQUIRED
                      : null
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  dropdownList={functionalAreaData
                    ? functionalAreaData.map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      ))
                    : null}
                />

              <div
                className="mui-custom-form input-md" // style={{ marginLeft: '30px' }}
              >
                <TextField
                  id="param-number"
                  fullWidth
                  required
                  label="Parameter Number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.paramNumber}
                  onChange={handleChange("paramNumber")}
                  inputProps={{ maxLength: 10 }}
                  onInput={e => {
                    e.target.value = e.target.value.replace(
                      AppConstants.NUMBER_ONLY_REGEX,
                      ""
                    );
                  }}
                  helperText={
                    showParameterNoError
                      ? SystemParameterAddConstants.PARAMETER_NUMBER_REQUIRED
                      : null
                  }
                  error={
                    showParameterNoError
                      ? SystemParameterAddConstants.PARAMETER_NUMBER_REQUIRED
                      : null
                  }
                />
              </div>

              <NativeDropDown
                  id="data-format"
                  isRequired
                  label="Data Format"
                  value={values.dataFormat}
                  onChange={handleDataFormatChange("dataFormat")}
                  errTxt={
                    showDataFormatError
                      ? SystemParameterAddConstants.DATA_FORMAT_REQUIRED
                      : null
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu
                    }
                  }}
                  dropdownList={dataFormatData
                    ? dataFormatData.map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      ))
                    : null}
                />

              <div className="mui-custom-form-description input-xl">
                <TextField
                  required
                  id="description"
                  fullWidth
                  label="Description"
                  value={values.description}
                  onChange={handleChange("description")}
                  error={
                    showDescriptionError
                      ? SystemParameterAddConstants.DESCRIPTION_REQUIRED
                      : null
                  }
                  helperText={
                    showDescriptionError
                      ? SystemParameterAddConstants.DESCRIPTION_REQUIRED
                      : null
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </div>

              <div className="mui-custom-form">
                <div className="clearfix"></div>
              </div>
              <div className="clearfix"></div>
            </div>
          </form>

          <div className="my-1 tab-holder">
            <div className="float-right my-3">
              {showForm ? (
                <Button
                  className="btn-text-main-delete btn-transparent"
                  title={"Delete"}
                  onClick={systemParameterRowDeleteClick}
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  Delete
                </Button>
              ) : null}
              {showForm ? (
                <Button
                  className="btn-text-main-add btn-success ml-1"
                  onClick={handleClickOpen}
                  title={
                    SystemParameterAddConstants.ADD_SYSTEM_PARAMETER_DETAIL
                  }
                >
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  {"Add"}
                </Button>
              ) : null}
            </div>
            <div className="clearfix"></div>


            <div className="tab-holder p-0">
              {showForm ? (
                <SystemParameterAddValueTable
                  tableData={newData}
                  systemParameterAddHeadCells={systemParameterAddHeadCells}
                  editSystemVariableTable={onClickEditSystemParameter}
                  rowDeleteSystemParameterDetails={
                    rowDeleteSystemParameterDetails
                  }
                />
              ) : null}
            </div>

            {open ? (
              <div className="tabs-container" id="form_pop_up">
                <div className="tab-header  mini-tab-header">
                  <div className="tab-heading float-left">
                    {add
                      ? "Add System Parameter Detail"
                      : "Edit System Parameter Detail"}
                  </div>
                  <div className="float-right mt-1">
                    <Button
                      onClick={addParameter}
                      className="btn btn-success"
                      title={add ? "Add" : "Update"}
                    >
                       <i class="fa fa-check" aria-hidden="true"></i>
                      {add ? "Add" : "Update"}
                    </Button>
                    <Button
                      className="bt-reset btn-transparent"
                      onClick={resetTable}
                    >
                      <i class="fa fa-undo" aria-hidden="true"></i>
                      Reset
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      className="btn btn-primary btn btn-outlined ml-1"
                      title={"Cancel"}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
                <div className="form-border my-3">
                  <form noValidate autoComplete="off">
                    <div className="form-wrapper">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="mui-custom-form override-width-20">
                          <KeyboardDatePicker
                            maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                            required
                            fullWidth
                            id="beginDateShowInTable"
                            label="Begin Date"
                            format="MM/dd/yyyy"
                            InputLabelProps={{
                              shrink: true
                            }}
                            placeholder="mm/dd/yyyy"
                            value={selectedBeginDate}
                            onChange={handleDateChange}
                            helperText={showBeginDateError ? errorMeg : null}
                            error={showBeginDateError ? errorMeg : null}
                            KeyboardButtonProps={{
                              "aria-label": "change date"
                            }}
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                      {values.dataFormat === "N" ? (
                        <div className="mui-custom-form override-width-20">
                          <TextField
                            id="format"
                            fullWidth
                            required
                            label="Number"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={dataElement.format}
                            onChange={handleChangeDataElement("format")}
                            inputProps={{ maxLength: 10 }}
                            helperText={
                              showNumericError ? errorMegNumeric : null
                            }
                            error={showNumericError ? errorMegNumeric : null}
                          />
                        </div>
                      ) : null}

                      {values.dataFormat === "C" ? (
                        <div className="mui-custom-form override-width-20">
                          <TextField
                            required
                            id="format"
                            label="Currency"
                            value={dataElement.format}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth                            
                            inputProps={{ maxLength: 17 }}
                            onChange={handleChangeDataElement("format")}
                            onInput={e => {
                              e.target.value = e.target.value.replace(
                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                                ""
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              )
                            }}
                            error={showCurrencyError ? errorMegCurrency : null}
                          />

                          <p class="MuiFormHelperText-root MuiFormHelperText-filled danger-text">
                            {showCurrencyError ? errorMegCurrency : null}
                          </p>
                        </div>
                      ) : null}

                      {values.dataFormat === "D" ? (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div className="mui-custom-form override-width-20">
                            <KeyboardDatePicker
                              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                              required
                              id="format"
                              fullWidth
                              label="Date"
                              format="MM/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              placeholder="mm/dd/yyyy"
                              value={selectedFormatDate}
                              onChange={handleFormatDateChange}
                              helperText={showDateError ? errorMegDate : null}
                              error={showDateError ? errorMegDate : null}
                              KeyboardButtonProps={{
                                "aria-label": "change date"
                              }}
                            />
                          </div>
                        </MuiPickersUtilsProvider>
                      ) : null}

                      {values.dataFormat === "P" ? (
                        <div className="mui-custom-form override-width-20">
                          <TextField
                            id="format"
                            fullWidth
                            required
                            label="Percent"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={dataElement.format}
                            inputProps={{ maxLength: 6 }}
                            onChange={handleChangeDataElement("format")}
                            helperText={
                              showPercentError ? errorMegPercent : null
                            }
                            error={showPercentError ? errorMegPercent : null}
                          />
                        </div>
                      ) : null}

                      {values.dataFormat === "T" ? (
                        <><div className="mui-custom-form with-textarea override-width-95">
                          <Form.Group>
                            <Form.Label className="MuiFormLabel-root small-label">
                              Text{" "}
                              <span class="Mui-required" id="text-label-01">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control
                              aria-describedby="text-label-01"
                              aria-label="form-controll-01"
                              as="textarea"
                              rows="4"
                              name="text"
                              value={dataElement.format}
                              onChange={handleChangeDataElement("format")}
                              maxLength={300}
                              style={{ width: "100%" }} />
                            {showAlphaNumericError ? (
                              <p
                                class="MuiFormHelperText-root Mui-error Mui-required"
                                role="alert"
                              >
                                {errorMegAlpha}
                              </p>
                            ) : null}
                          </Form.Group><div
                            style={{
                              border: "1px solid rgb(169, 169, 169)",
                              borderRadius: ".25rem",
                              paddingLeft: "5px",
                              width: "200px",
                              alignText: "center"
                            }}
                          >
                            Characters Remaining:{300 - dataElement.format?.length}
                          </div>
                        </div></>
                      ) : null}

                      {values.dataFormat === "Z" ? (
                        <div className="mui-custom-form override-width-20">
                          <TextField
                            id="format"
                            fullWidth
                            required
                            label="Timestamp"
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={dataElement.format}
                            inputProps={{ maxLength: 29 }}
                            onChange={handleChangeDataElement("format")}
                            helperText={
                              showTimestampError ? errorMegTime : null
                            }
                            error={showTimestampError ? errorMegTime : null}
                          />
                        </div>
                      ) : null}
                    </div>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
          <div className="tab-panelbody">
            <div className="tab-holder mb-3 mt-2">
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChangeTabs}
                  aria-label="simple tabs example"
                >
                  <Tab label="Notes" />
                </Tabs>
              </AppBar>
              <TabPanel value={tabValue} index={0}>
                <div className="tab-holder p-0">
                  <div>
                    <Notes
                      addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      disableNotes={disableNotes}
                      setErrorMessages={seterrorMessages}
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                </div>

              </TabPanel>
            </div>
          </div>
          <Footer print />
        </div>
      </div>
    </div>
  );
}

export default withRouter(SystemParameterAdd);
