/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { redirectSystemID, redirectGrossLevelFCN } from '../../../../../SharedModules/TabRedirections/TabRedirections';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../../SharedModules/Errors/SuccessMessage';
import * as ErrorConstants from './ErrorConstants';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../../../SharedModules/services/service';
import moment from 'moment';
import { getDropdownDataAction, getPayeeIdType, saveARReverse, agingDaysList } from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import Spinner from '../../../../../SharedModules/Spinner/Spinner';
import { getLongDescription } from '../../../../../SharedModules/CommonUtilities/commonUtilities';
import { Prompt } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import numeral from 'numeral';
import dropdownCriteria from './ARReverse.json';
import { GET_APP_DROPDOWNS } from "../../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../../SharedModules/Dropdowns/dropdowns";
import { refreshAttachmentsAction } from '../../../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../../../SharedModules/Layout/footer';
import { getUTCTimeStamp, getDateInMMDDYYYYFormatWithApendZero, generateUUID } from '../../../../../SharedModules/DateUtilities/DateUtilities';
import { searchSingleRecordFinancialEntity } from '../../../../FinancialEntities/Store/Actions/FinancialEntityActions';
import Notes from '../../../../../SharedModules/Notes/Notes';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import TabPanel from '../../../../../SharedModules/TabPanel/TabPanel';
import Attachments from '../../../../../SharedModules/Attachments/Attachments';
import { isArray } from 'util';
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

function ARReverse(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  // NotesTab Functionality
  const [value, setValue] = React.useState(0);
  const [tabOne, setTabOne] = React.useState(true);
  
  const [isFocusCheck,setFocusCheck] =React.useState(false);
  const [atrError, showATRError] = React.useState(false);
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setTabOne(true);
    }
  };
  // Attachments Integration start
  const [attachmentsTableData, setAttachmentsTableData] = React.useState([]);
  let refreshAttachmentResult = useSelector(state => state.sharedState.newAttachmentResult);
  const [attachmentFile, setAttachmentFile] = React.useState([]);
  const [headerValuesDesc, setHeaderValuesDesc] = React.useState({
    lobDesc:"",
    payeePayerTypeCodeDesc: "",
    payeePayerIDTypeCodeDesc:""
  });
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  React.useEffect(() => {
    let attachmentVOList = [];
    if (attachmentsTableData && attachmentsTableData.length > 0 && ARReverseDetails) {
      attachmentsTableData.map((value) => {
        if (value.id) {
          let attachmentVO = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: 0,
            dbRecord: true,
            sortColumn: null,
            attachmentSK: null,
            attachmentPageID: null,
            finalPath: null,
            detachInd: false,
            historyIndicator: false,
            cascadeKey: null,
            attachmentIndicator: null,
            dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
            fileUrl: null,
            showDeleteForSearched: false,
            rowIndex: 0,
            addedBy: logInUserID,
            description:
              value && value
                ? value.description
                : null,
            edmsStoredDate: null,
            anEDMSPageId: null,
            fileName:
              attachmentsTableData && value
                ? value.fileName
                : null,
            file1: null,
            edmsWrkUnitLevel: null,
            edmsDocType: null
          };
          attachmentVOList.push(attachmentVO);
        }
      })
    }
    ARReverseDetails.attachmentVO = null;
    ARReverseDetails.attachmentsList = attachmentVOList;
  }, [attachmentsTableData]);
  // Attachment Integration ends
  // Notes Integration Begin
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({

    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []

  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({

  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});
  // add notes
  let notesDataArray = [];

  const addNotes = (data) => {
    setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;

    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setsaveMessage([]);
    seterrorMessages([]);
    setReasonCodeError(false);
  };

  let paramsData;



  useEffect(() => {
    if (props.location.state.claimsData) {
      paramsData = props.location.state.claimsData;
      // notes
      if (paramsData.noteSetVO) {
        const noteSetVO = paramsData.noteSetVO;
        const notesTable = paramsData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: noteSetVO.addedAuditUserID ? noteSetVO.addedAuditUserID : logInUserID,
          addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp ? noteSetVO.addedAuditTimeStamp : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTableData,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
    refreshAttachmentResult = null;

  }, []);


  useEffect(() => {
    if (refreshAttachmentResult && !refreshAttachmentResult.systemFailure) {
      setAttachmentsTableData(JSON.parse(JSON.stringify(refreshAttachmentResult)));
    }
  }, [refreshAttachmentResult]);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput.addedAuditUserID ? notesInput.addedAuditUserID : logInUserID,
        addedAuditTimeStamp: notesInput.addedAuditTimeStamp ? notesInput.addedAuditTimeStamp : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum ? notesInput.noteCexVersionNum : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber ? notesInput.saNoteSequenceNumber : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue ? notesInput.notesCexnoteTextValue : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  // Notes Integration End

  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [reasonCodeDetails, setReasonCodeDetails] = React.useState([]);
  const ARReverseDetails = props.location.state.claimsData;
  const [ARletterSent, setARletterSent] = React.useState('');
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const DropDownAPI = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  let dropDownDetails = [];
  dropDownDetails = useSelector(state => state.accountReceivableState.dropdownData);
  const PayeeIDCodeAPI = (payee) => dispatch(getPayeeIdType(payee));
  let payeeIDTypeCodeDetails = [];
  payeeIDTypeCodeDetails = useSelector(state => state.accountReceivableState.payeeIdTypeDropdown);
  const SaveReverseAPI = (values, index) => { setspinnerLoader(true); dispatch(saveARReverse(values, attachmentsTableData && index >= 0 && attachmentsTableData[index] && attachmentsTableData[index].id ? attachmentFile : null)); };
  let saveReverseResult = [];
  saveReverseResult = useSelector(state => state.accountReceivableState.arReverseResults);
  const ARletterSentAPI = () => { dispatch(agingDaysList()); };
  const ARLetterSentDetails = useSelector(state => state.accountReceivableState.agingDaysList);
  let SystemError = useSelector(state => state.accountReceivableTransferState.transferError);
  useEffect(() => {
    SystemError = [];
    saveReverseResult = [];
    setsaveMessage([]);
    seterrorMessages([]);
    setspinnerLoader(true);
 onDropdowns([
      Dropdowns.LOB_CODE,
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
    ]);
    PayeeIDCodeAPI(ARReverseDetails.payeePayerTypeCode);
    dispatch(refreshAttachmentsAction(ARReverseDetails.receivableFCN));
    ARletterSentAPI();
    DropDownAPI(dropdownCriteria);
  }, []);
  useEffect(() => {
    if (ARLetterSentDetails) {
      ARLetterSentDetails.map(var1 => {
        if (ARReverseDetails.agingCode === var1.code) {
          setARletterSent(var1.description);
        }
      });
    }
  }, [ARLetterSentDetails]);
  function updateDropdownDescription(addDropdowns, dropdownKey, valueToMatch, targetProperty) {
    if(addDropdowns && addDropdowns[dropdownKey]){
    const dropdownData =addDropdowns[dropdownKey].filter((value) => value.code === valueToMatch?.split("-")[0]);
    setHeaderValuesDesc(prevState => ({
      ...prevState,
      [targetProperty]: dropdownData && dropdownData.length > 0
        ? dropdownData[0].description
        : valueToMatch ? valueToMatch : "",
    }));
    }
  }
  useEffect(() => {
    if (addDropdowns) {
      setspinnerLoader(false);
      // Usage for 'lobDesc':
      updateDropdownDescription(addDropdowns, 'Reference#R_LOB_CD', ARReverseDetails.lob, 'lobDesc');
      // Usage for 'payeePayerTypeCodeDesc':
      updateDropdownDescription(addDropdowns, 'G1#G_CMN_ENTY_TY_CD', ARReverseDetails.payeePayerTypeCode, 'payeePayerTypeCodeDesc');
      // Usage for 'payeePayerIDTypeCodeDesc':
      const payeeIdTypeCdDD = {
        'P': 'F1#P_ALT_ID_TY_CD',
        'M': 'F1#B_ALT_ID_TY_CD',
        'O': 'F1#G_ENTY_EXTL_ID_TY_CD',
        'TC': 'F1#DM_G_CARR_ENTY_ID_TY_CD'
      };
      const selectedPayeeIdTypeCdDD = payeeIdTypeCdDD[ARReverseDetails.payeePayerTypeCode] || '';
      updateDropdownDescription(addDropdowns, selectedPayeeIdTypeCdDD, ARReverseDetails.payeePayerIDTypeCode, 'payeePayerIDTypeCodeDesc');
    }
  }, [addDropdowns]);
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(state => state.financialEntityState.singleSearchResults);
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    setspinnerLoader(false);
    if (redirect === 1 && searchSingleFinancialEntityRes && !searchSingleFinancialEntityRes.systemFailue) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  const onSystemPayeeIdclick = systemPayeeId => event => {
    setspinnerLoader(true);
    dispatch(searchSingleRecordFinancialEntity(systemPayeeId));
    setRedirect(1);
  };
  // System Payee redirection ends
  useEffect(() => {
    setspinnerLoader(false);
    if (dropDownDetails && dropDownDetails.listObj) {
      if (dropDownDetails.listObj['Financial#10']) {
        if (dropDownDetails.listObj['Financial#10'].length === 1) {
          setValues({ ...values, reasonCode: dropDownDetails.listObj['Financial#10'][0]?.code });
        }
        setReasonCodeDetails(dropDownDetails.listObj['Financial#10']);
      }
      if (dropDownDetails.listObj['General#1012']) {
        setUsageTypeCodeData(dropDownDetails.listObj['General#1012']);
      }
      seterrorMessages([]);
    }
  }, [dropDownDetails]);

  useEffect(() => {
    setspinnerLoader(false);
    const saveMSG = [];
    const tempErrorArray = [];
    if (saveReverseResult && saveReverseResult.respcode && saveReverseResult.respcode === '01') {
      saveMSG.push(ErrorConstants.SAVE_SUCCESS);
      setsaveMessage(saveMSG);
      seterrorMessages([]);
      ARReverseDetails.claimsReceivableDetailsVO.balanceAmount = 0;
      dispatch(refreshAttachmentsAction(ARReverseDetails.receivableFCN));
    } else if (saveReverseResult && (saveReverseResult.respcode || saveReverseResult.respcode === null) && saveReverseResult.respcode !== '01') {
      if (saveReverseResult.errorMessages && isArray(saveReverseResult.errorMessages)) {
        tempErrorArray.push(saveReverseResult.errorMessages[0]);
      } else {
        tempErrorArray.push(ErrorConstants.SYS_ERROR);
      }
      seterrorMessages(tempErrorArray);
    } else if (saveReverseResult && saveReverseResult.SystemError) {
      seterrorMessages([ErrorConstants.SYS_ERROR]);
    }
  }, [saveReverseResult]);
  useEffect(() => {
    if (SystemError && SystemError.SystemError) {
      const arconst = [];
      arconst.push(ErrorConstants.SYS_ERROR);
      seterrorMessages(arconst);
    }
  }, [SystemError]);
  const useStyles = makeStyles(() => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    container2: {
      textAlign: 'left',
      marginTop: '10px'
    },
    dense: {
      marginTop: 19
    },
    menu: {
      width: 200
    }
  }));
  const classes = useStyles();

  const [values, setValues] = React.useState({
    establishedDateStr: moment().format('L'),
    reasonCode: '-1',
    atrNumber: '',

  });
  const [saveMessage, setsaveMessage] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [reasonCodeError, setReasonCodeError] = React.useState(false);
  const [allowNavigation, setAllowNavigation] = React.useState(false);

  const handleChange = name => event => {
    setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });
  };

  function masterSave() {
    setAllowNavigation(false);
    const tempErrorMessages = [];
     const regex = /[^A-Za-z0-9]/ ;

    if (values.reasonCode === DEFAULT_DD_VALUE) {
      setReasonCodeError(true);
      tempErrorMessages.push(ErrorConstants.REASON_CODE);
    } else {
      setReasonCodeError(false);
    }
    if (values.atrNumber && regex.test(values.atrNumber)) {
      showATRError(true);
      tempErrorMessages.push(`${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${ErrorConstants.TRANSACTION_NUMBER_FORMAT}`);
    }else{showATRError(false);}
    
    if (tempErrorMessages.length === 0) {
      seterrorMessages([]);
      ARReverseDetails.auditUserID = logInUserID;
      ARReverseDetails.auditTimeStamp = getUTCTimeStamp();
      ARReverseDetails.reversalVO.auditUserID = logInUserID;
      ARReverseDetails.reversalVO.auditTimeStamp = getUTCTimeStamp();
      ARReverseDetails.reversalVO.addedAuditUserID = logInUserID;
      ARReverseDetails.reversalVO.addedAuditTimeStamp = getUTCTimeStamp();
      ARReverseDetails.lob = ARReverseDetails.lob.split('-')[0];
      ARReverseDetails.payeePayerIDTypeCode = ARReverseDetails.payeePayerIDTypeCode;
      ARReverseDetails.reversalVO.reasonCode = values.reasonCode;
      ARReverseDetails.reversalVO.atrNumber = props?.location?.state?.claimsData?.atrNumber?.toUpperCase();
      ARReverseDetails.noteSetVO = notesInput;
      const index = attachmentsTableData.length - 1;
      let attachmentVOList = [];
      if (attachmentsTableData && index >= 0 && attachmentsTableData[index] && attachmentsTableData[index].id) {
        attachmentsTableData.map((value) => {
          if (value.id) {
            let attachmentVO = {
              auditUserID: logInUserID,
              auditTimeStamp: getUTCTimeStamp(),
              addedAuditUserID: logInUserID,
              addedAuditTimeStamp: getUTCTimeStamp(),
              versionNo: 0,
              dbRecord: true,
              sortColumn: null,
              attachmentSK: null,
              attachmentPageID: null,
              finalPath: null,
              detachInd: false,
              historyIndicator: false,
              cascadeKey: null,
              attachmentIndicator: null,
              dateAdded: getDateInMMDDYYYYFormatWithApendZero(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
              fileUrl: null,
              showDeleteForSearched: false,
              rowIndex: 0,
              addedBy: logInUserID,
              description:
                value && value
                  ? value.description
                  : null,
              edmsStoredDate: null,
              anEDMSPageId: null,
              fileName:
                attachmentsTableData && value
                  ? value.fileName
                  : null,
              file1: null,
              edmsWrkUnitLevel: null,
              edmsDocType: null
            };
            attachmentVOList.push(attachmentVO);
          }
        })
      }
      ARReverseDetails.attachmentVO = null;
      ARReverseDetails.attachmentsList = attachmentVOList;
      if (ARReverseDetails.claimsReceivableDetailsVO.balanceAmount && ARReverseDetails.claimsReceivableDetailsVO.adjustedArAmt &&
        parseFloat((ARReverseDetails.claimsReceivableDetailsVO.balanceAmount).replace(/,/g, '')) ===
        parseFloat((ARReverseDetails.claimsReceivableDetailsVO.adjustedArAmt).replace(/,/g, ''))) {
        SaveReverseAPI(ARReverseDetails, index);
      } else {
        seterrorMessages([ErrorConstants.REVERSE_ERROR]);
      }
    }
    else {
      seterrorMessages(tempErrorMessages);

    }
  }

  return (
    <div>
      <div>
        <Prompt
          when={ allowNavigation }
          message={ location => `Are you sure you want to go to ${location.pathname}` }
        />
        { spinnerLoader ? <Spinner /> : null }
        <div className="tabs-container" ref={ toPrintRef }>
          <ErrorMessages errorMessages={ errorMessages } />
          { saveMessage.length > 0 && errorMessages.length === 0
            ? <SuccessMessages successMessages={ saveMessage } /> : null
          }
          <div className="tab-header">
            <h1 className="tab-heading float-left">
              Account Receivable Reverse
            </h1>
            <div className="float-right mt-2">
              <Button className='btn btn-success' onClick={ masterSave }>
                <i class="fa fa-check" aria-hidden="true"></i>
                Save
              </Button>
            </div>
            <div className="clearfix"></div>
          </div>
          <div className="tab-body" style={ { overflow: 'hidden' } }>
            <form autoComplete="off">
              <div className="form-wrapper">
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-ARReverselob1"
                    fullWidth
                    label="LOB"
                    value={ headerValuesDesc.lobDesc || "" }
                    InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                    inputProps={ { maxLength: 2 } }
                    InputLabelProps={ {
                      shrink: true
                    } } />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-ARReversePayee1"
                    fullWidth
                    label="Payee/Payer Type"
                    value={ headerValuesDesc.payeePayerTypeCodeDesc || "" }
                    inputProps={ { maxLength: 13 } }
                    InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                    InputLabelProps={ {
                      shrink: true
                    } } />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-ARReversePayee/PayerIDType"
                    fullWidth
                    label="Payee/Payer ID Type Code"
                    value={ headerValuesDesc.payeePayerIDTypeCodeDesc || "" }
                    inputProps={ { maxLength: 13 } }
                    InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                    InputLabelProps={ {
                      shrink: true
                    } } />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-ARReversePayeeID"
                    fullWidth
                    label="Payee ID"
                    value={ ARReverseDetails.payeePayerID }
                    inputProps={ { maxLength: 15 } }
                    InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                    InputLabelProps={ {
                      shrink: true
                    } } />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-ARReversePayee/Payer"
                    fullWidth
                    label="Payee/Payer Name"
                    value={ ARReverseDetails.payeePayerNameFromDB }
                    InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                    inputProps={ { maxLength: 60 } }
                    InputLabelProps={ {
                      shrink: true
                    } } />
                </div>
                <div className="mui-custom-form input-md has-link">
                  <TextField
                    id="standard-ARReversesystempayeeid"
                    fullWidth
                    label="System Payee ID"
                    InputProps={ {
                      readOnly: true, className: 'Mui-disabled', startAdornment: (
                        <InputAdornment position="start">
                          <Link to={ redirectSystemID(ARReverseDetails.sysID) } target='_blank'>
                            { ARReverseDetails.sysID }
                          </Link>
                        </InputAdornment>
                      )
                    } }
                    InputLabelProps={ {
                      shrink: true
                    } } />
                </div>
              </div>
              <div className="tab-holder">
                <div className="row mb-2">
                  <div className="col-6" style={ { maxWidth: '500px' } }>
                    <fieldset className="custom-fieldset">
                      <legend>Original Request:</legend>
                      <div className="form-wrapper">
                        <div className="mui-custom-form input-md override-width-45 has-link">
                          <TextField
                            id="standard-ARReverseFCN1"
                            fullWidth
                            label="FCN"
                            inputProps={ { maxLength: 3 } }
                            InputProps={ {
                              readOnly: true, className: 'Mui-disabled', startAdornment: (
                                <InputAdornment position="start">
                                  <Link to={ redirectGrossLevelFCN(ARReverseDetails.receivableFCN) } target='_blank'>
                                    { ARReverseDetails.receivableFCN }
                                  </Link>
                                </InputAdornment>
                              )
                            } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                        <div className="mui-custom-form input-md override-width-45">
                          <TextField
                            id="standard-ARReversereasoncode1"
                            fullWidth
                            label="A/R Letter Sent"
                            value={ ARletterSent }
                            inputProps={ { maxLength: 9 } }
                            InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form input-md override-width-45">
                          <TextField
                            id="standard-ARReversepayeeid1"
                            fullWidth
                            label="Establishment Date"
                            value={ moment(new Date(ARReverseDetails.establishedDate)).format('L') }
                            inputProps={ { maxLength: 10 } }
                            InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-ARReverseoriginalamt1"
                            fullWidth
                            label="Original A/R Amount"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.originalAmount).format('0,0.00') }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } }
                            inputProps={ { maxLength: 11 } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form input-md override-width-45 has-link">
                          <TextField
                            id="standard-ARReverseRelatedfT"
                            fullWidth
                            label="Related FT TCN"
                            InputProps={ {
                              readOnly: true, className: 'Mui-disabled',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <a href={ CLAIMS_INQUIRY_TCN_LINK + ARReverseDetails.relatedFTTCN } target='_blank'>
                                    { ARReverseDetails.relatedFTTCN }
                                  </a>
                                </InputAdornment>
                              )
                            } }
                            inputProps={ { maxLength: 10 } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-ARReverseBalanceamount"
                            fullWidth
                            label="Balance Amount"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.balanceAmount).format('0,0.00') }
                            inputProps={ { maxLength: 14 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } }
                          />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-ARReverseAdjustedA/R1"
                            fullWidth
                            label="Adjusted A/R Amount"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.adjustedArAmt).format('0,0.00') }
                            inputProps={ { maxLength: 17 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } }
                          />
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-ARReverseinterest1"
                            fullWidth
                            label="Interest"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.interest).format('0,0.00') }
                            placeholder=""
                            inputProps={ { maxLength: 3 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } } />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-AdjustedA/Rpenalty1"
                            fullWidth
                            label="Penalty"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.penalty).format('0,0.00') }
                            placeholder=""
                            inputProps={ { maxLength: 3 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } } />
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-AdjustedA/Rlettersent1"
                            fullWidth
                            label="Fees"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.fee).format('0,0.00') }
                            placeholder=""
                            inputProps={ { maxLength: 3 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } } />
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                        <TextField
                          id='ATRnumber'
                          fullWidth
                          label={ process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                          placeholder=""
                          value={props?.location?.state?.claimsData?.atrNumber||''}
                          InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                          InputLabelProps={{
                            shrink: true,
                          }}                         
                        ></TextField>
                        </div>
                      </div>
                    </fieldset>
                  </div>

                  <div className="col-6" style={ { paddingLeft: '5px' } }>
                    <fieldset className="custom-fieldset">
                      <legend>Reversal</legend>
                      <div className="form-wrapper">
                        <div className="mui-custom-form input-md override-width-45 has-link">
                          <TextField
                            id="standard-AdjustedA/Rlettersent11"
                            fullWidth
                            InputProps={ {
                              readOnly: true, className: 'Mui-disabled', startAdornment: (
                                <InputAdornment position="start">
                                  <Link to={ redirectGrossLevelFCN(ARReverseDetails.receivableFCN) } target='_blank'>
                                    { ARReverseDetails.receivableFCN }
                                  </Link>
                                </InputAdornment>
                              )
                            } }
                            label="FCN"
                            placeholder=""
                            inputProps={ { maxLength: 3 } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                        <div className="mui-custom-form input-md override-width-45">
                          <TextField
                            id="standard-ARReversestandard-fcn2"
                            fullWidth
                            label="A/R Letter Sent"
                            value={ ARletterSent }
                            inputProps={ { maxLength: 14 } }
                            InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form input-md override-width-45">
                          <TextField
                            fullWidth
                            id="standard-ARReversestandardEstablishedDate2"
                            label="Established Date"
                            value={ ARReverseDetails.reversalVO.reversedEstablishedDateStr }
                            inputProps={ { maxlength: 15 } }
                            InputProps={ { readOnly: true, className: 'Mui-disabled' } }
                            placeholder=""
                            InputLabelProps={ {
                              shrink: true
                            } }

                          >
                          </TextField>
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-ARReverseamtA/RAmount"
                            fullWidth
                            label="Original A/R Amount"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.originalAmount).format('0,0.00') }
                            inputProps={ { maxLength: 60 } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <div className="mui-custom-form input-md override-width-45 has-link">
                          <TextField
                            id="standard-ARReverseFTTCN"
                            fullWidth
                            label="Related FT TCN"
                            inputProps={ { maxLength: 10 } }
                            InputProps={ {
                              readOnly: true, className: 'Mui-disabled',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <a href={ CLAIMS_INQUIRY_TCN_LINK + ARReverseDetails.relatedFTTCN } target='_blank'>
                                    { ARReverseDetails.relatedFTTCN }
                                  </a>
                                </InputAdornment>
                              )
                            } }
                            InputLabelProps={ {
                              shrink: true
                            } } />
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-lettersent"
                            fullWidth
                            label="Balance Amount"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.balanceAmount).format('0,0.00') }
                            placeholder=""
                            inputProps={ { maxLength: 3 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } } />
                        </div>
                      </div>
                      <div className="form-wrapper">

                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-fttcn"
                            fullWidth
                            label="Adjusted A/R Amount"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.adjustedArAmt).format('0,0.00') }
                            inputProps={ { maxLength: 17 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } } />
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="standard-interest1"
                            fullWidth
                            label="Interest"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.interest).format('0,0.00') }
                            inputProps={ { maxLength: 3 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } } />
                        </div>
                      </div>
                      <div className="form-wrapper">

                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="ARReverse-Penalty"
                            fullWidth
                            label="Penalty"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.penalty).format('0,0.00') }
                            inputProps={ { maxLength: 3 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } } />
                        </div>
                        <div className="mui-custom-form input-Adronment override-width-45">
                          <TextField
                            id="ARReverse-Fees1"
                            fullWidth
                            label="Fees"
                            value={ numeral(ARReverseDetails.claimsReceivableDetailsVO.fee).format('0,0.00') }
                            inputProps={ { maxLength: 3 } }
                            InputLabelProps={ {
                              shrink: true
                            } }
                            InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } } />
                        </div>
                      </div>
                      <div className="form-wrapper">
                        <NativeDropDown
                            id="standard-reasoncode1"
                          className='override-width-45'
                            label="Reason Code"
                          isRequired
                            value={ values.reasonCode }
                            onChange={ handleChange('reasonCode') }
                            inputProps={ { maxLength: 3 } }
                          errTxt={reasonCodeError ? ErrorConstants.REASON_CODE : null}
                            title={ getLongDescription(reasonCodeDetails, values.reasonCode) }
                          dropdownList={reasonCodeDetails ? reasonCodeDetails.map(option => (
                            <option key={option.code} value={option.code}>
                                { option.description }
                            </option>
                            )) : null }

                        />
                      </div>

                    </fieldset>
                  </div>
                </div>
              </div>
            </form>

            <div className="tab-panelbody">
              <div className="tab-holder mb-3 mt-2">
                <AppBar position="static">
                  <Tabs
                    value={ value }
                    onChange={ handleChangeTabs }
                    aria-label="simple tabs example"
                  >
                    <Tab label="Notes" />
                    <Tab label="Attachments" />
                  </Tabs>
                </AppBar>
                <TabPanel value={ value } index={ 0 }>
                  <div className="tab-holder p-0">
                    <Notes addNotes={ addNotes }
                      setFocusCheck={setFocusCheck}
                      notesTableData={ notesTableData }
                      noteSetListInput={ noteSetListInput }
                      setNoteSetListInput={ setNoteSetListInput }
                      usageTypeCodeData={ usageTypeCodeData }
                      editNoteData={ editNoteData }
                      setEditNoteData={ setEditNoteData }
                      setSuccessMessages={ clearSuccessErrorMsgs } />
                  </div>
                </TabPanel>
                <TabPanel value={ value } index={ 1 }>
                  <div className="tab-holder p-0">
                    <Attachments
                      userInquiryPrivileges={ userInquiryPrivileges }
                      attachmentFile={ attachmentFile }
                      setAttachmentFile={ setAttachmentFile }
                      attachmentsTableData={ attachmentsTableData }
                      setAttachmentsTableData={ setAttachmentsTableData }
                      parentErrorMesage={ seterrorMessages }
                      setAllowNavigation={ setAllowNavigation }
                      setSystemSuccesMessages={ clearSuccessErrorMsgs }
                      setFocusCheck={setFocusCheck}
                    />
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
          <Footer print />
        </div>
      </div>
    </div>
  );
}
export default withRouter((ARReverse));
