/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useRef, useEffect } from 'react';
import ValidValueSearchTableComponent from './ValidValueSearchTableComponent';
import Bootstrap, { Button } from 'react-bootstrap';
import * as ValidValueConstants from './ValidValueConstants';
import {
  validValueAction,
  resetSearchValidValue
} from '../../Store/Actions/validValue/validValueActions';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import ValidValueSearchForm from './ValidValueSearchForm';
import { AppConfigDropdownActions } from '../../Store/Actions/AppConfigCommon/AppConfigActions';
import dropdownCriteria from './ValidValueSearch.json';
import Footer from '../../../../SharedModules/Layout/footer';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";

function ValidValueSearch (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const toPrintRef = useRef();
  
  let errorMessagesArray = [];
  const [showTable, setShowTable] = React.useState(false);
  const [showNoRecords, setShowNoRecords] = React.useState(false);
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [values, setValues] = React.useState({
    code: null,
    functionalArea: DEFAULT_DD_VALUE,
    dataElementName: null,
    businessName: null,
    valueShortDescription: null,
    crossReferenceColumnName: null,
    crossReferenceTableName: null,
    dataEleNameStartsOrContains: null,
    busNameStartsOrContains: null
  });
  const validvaluecons = ValidValueConstants;
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [
    {
      showdataElementNameError,
      showbusinessNameError
    },
    setShowError
  ] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);

  const handleChanges = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const resetTable = () => {
    onReset()
    paylod = [];
    setShowTable(false);
    setShowNoRecords(false);
    seterrorMessages([]);
    setShowError(false);
    setValues({
      code: '',
      dataElementName: '',
      businessName: '',
      valueShortDescription: '',
      crossReferenceColumnName: '',
      crossReferenceTableName: '',
      dataEleNameStartsOrContains: '',
      busNameStartsOrContains: ''
    });
    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };
  /// INTEGRATION PART
  const dispatch = useDispatch();
  const [useEffectValues, setUseEffectValues] = React.useState([]);

  const onReset = () => dispatch(resetSearchValidValue());
  const dropDownDispatch = dropdownvalues =>
    dispatch(AppConfigDropdownActions(dropdownvalues));

  let paylod = [];
  let dropdown = [];
  useEffect(() => {
    onReset();
    dropDownDispatch(dropdownCriteria);
    onDropdowns([
      Dropdowns.R_FUNC_AREA_CD,
    ]);
  }, [useEffectValues]);

  const [redirect, setRedirect] = React.useState(0);

  const onSearch = searchvalues => dispatch(validValueAction(searchvalues));
  paylod = useSelector(
    state => state.appConfigState.validValueState.validValue
  );
  const searchCriteriaData = useSelector(
    state => state.appConfigState.validValueState.searchCriteria
  );
  dropdown = useSelector(
    state => state.appConfigState.AppConfigCommonState.appConfigDropdown
  );

  useEffect(() => {
    if (dropdown && dropdown.listObj && dropdown.listObj['Reference#1017']) {
      setFunctionalAreaData(dropdown.listObj['Reference#1017']);
    }
  }, [dropdown]);

  const payloadData = paylod ? paylod[0] : {};

  const functionalAreaDataDescriptionMap = functionalArea => {
    const filteredValue = functionalAreaData.filter(
      (fnArea, index) => fnArea.code === functionalArea
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return functionalArea;
  };

  if (paylod && !paylod.systemFailure && paylod.length > 0) {
    paylod.map((data, index) => {
      data.functionalAreaDesc = functionalAreaDataDescriptionMap(
        data.functionalArea
      );
    });
  }

  if (redirect === 1) {
    if (paylod != null) {
      if (paylod && !paylod.systemFailure && paylod.length === 1) {
        props.history.push({
          pathname: '/ValidvalueView',
          state: { payloadData, values: values }
        });
      }
    }
  }
  // INTEGRATION PART
  // Spinner Functionality
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  useEffect(() => {
    if (paylod != null && !paylod.systemFailure && (paylod.length >= 0)) {
      setShowTable(true);
      setspinnerLoader(false);
    }
    if (paylod && paylod.systemFailure) {
      setspinnerLoader(false);
      errorMessagesArray.push(validvaluecons.ERROR_OCCURED_DURING_TRANSACTION);
      seterrorMessages(errorMessagesArray);
    }
    if (paylod && !paylod.systemFailure && paylod.length === 0) {
      setspinnerLoader(false);
      setShowNoRecords(true);
    }
  }, [paylod]);
  // Spinner Functionality
  const searchCheck = () => {
    setIsSearch(true);
    paylod = [];
    setShowTable(false);
    setspinnerLoader(false);
    errorMessagesArray = [];
    seterrorMessages([]);
    var showbusinessNameError;
    var showdataElementNameError = false;    
    if (
      values.busNameStartsOrContains &&
      (!values.businessName || values.businessName.trim().length < 2)
    ) {
      showbusinessNameError = true;
      errorMessagesArray.push(validvaluecons.BUSINESS_NAME_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    }
    if (
      values.dataEleNameStartsOrContains &&
      (!values.dataElementName || values.dataElementName.trim().length < 2)
    ) {
      showdataElementNameError = true;
      errorMessagesArray.push(validvaluecons.DATA_ELEMENT_NAME_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    } else if (errorMessagesArray.length === 0) {
      setspinnerLoader(true);
      let searchCriteria = {};
      searchCriteria = {
        code: values.code !== '' ? values.code : null,
        businessName: values.businessName !== '' ? values.businessName : null,
        dataElementName:
          values.dataElementName !== '' ? values.dataElementName : null,
        dataEleNameStartsOrContains:
          values.dataEleNameStartsOrContains === 'StartsWith'
            ? 0
            : values.dataEleNameStartsOrContains === 'Contains'
              ? 1
              : null,
        busNameStartsOrContains:
          values.busNameStartsOrContains === 'StartsWith'
            ? 0
            : values.busNameStartsOrContains === 'Contains'
              ? 1
              : null,
        valueShortDescription:
          values.valueShortDescription !== ''
            ? values.valueShortDescription
            : null,
        crossReferenceColumnName:
          values.crossReferenceColumnName !== ''
            ? values.crossReferenceColumnName
            : null,
        crossReferenceTableName:
          values.crossReferenceTableName !== ''
            ? values.crossReferenceTableName
            : null
      };
      onSearch(searchCriteria);
      if(!props.history.state){
        props.history.state = {}
      }
      props.history.state.values = searchCriteria
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    }
    setShowError({
      showbusinessNameError: showbusinessNameError,
      showdataElementNameError: showdataElementNameError
    });
    setShowTable(false);
  };

  const setValuesFromValidView = props?.history?.state?.values;

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (setValuesFromValidView !== undefined) {
        seterrorMessages([]);
        setspinnerLoader(true);
        const searchCriteria = {
          code: setValuesFromValidView.code !== '' ? setValuesFromValidView.code : null,
          businessName: setValuesFromValidView.businessName !== '' ? setValuesFromValidView.businessName : null,          
          dataElementName:
          setValuesFromValidView.dataElementName !== '' ? setValuesFromValidView.dataElementName : null,
          dataEleNameStartsOrContains:
          setValuesFromValidView.dataEleNameStartsOrContains,
          busNameStartsOrContains:
          setValuesFromValidView.busNameStartsOrContains,
          valueShortDescription:
          setValuesFromValidView.valueShortDescription !== ''
            ? setValuesFromValidView.valueShortDescription
            : null,
          crossReferenceColumnName:
          setValuesFromValidView.crossReferenceColumnName !== ''
            ? setValuesFromValidView.crossReferenceColumnName
            : null,
          crossReferenceTableName:
          setValuesFromValidView.crossReferenceTableName !== ''
            ? setValuesFromValidView.crossReferenceTableName
            : null
        };
        onSearch(searchCriteria);
        setValues({...setValuesFromValidView,
          dataEleNameStartsOrContains:setValuesFromValidView.dataEleNameStartsOrContains === 0 ? "StartsWith" : setValuesFromValidView.dataEleNameStartsOrContains === 1 ? "Contains" : null,
          busNameStartsOrContains:setValuesFromValidView.busNameStartsOrContains === 0 ? "StartsWith" : setValuesFromValidView.busNameStartsOrContains === 1 ? "Contains" : null,
        });
      }
    }
  }, [props.location.tableRender]);

  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}

      <div className="tabs-container" ref={toPrintRef}>
        {errorMessages.length > 0 ? (
          <div
            className="alert alert-danger custom-alert"
            role="alert"
          >
            {errorMessages.map(message => (
              <li>{message}</li>
            ))}
          </div>
        ) : null}
        {errorMessages.length === 0 &&
          paylod && !paylod.systemFailure &&
          paylod.length === 0 &&
          showNoRecords ? (
            <div
              className="alert alert-danger custom-alert"
              role="alert"
            >
              <li>{ValidValueConstants.NO_RECORDS_FOUND}</li>
            </div>
          ) : null}
        <div className="tab-header">
          <div className="tab-heading float-left">Search Valid Value</div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <ValidValueSearchForm
            values={values}
            // setIsSearch={setIsSearch}
            functionalArea={functionalAreaData}
            handleChanges={handleChanges}
            resetTable={resetTable}
            searchCheck={searchCheck}
            errors={{
              showdataElementNameError: showdataElementNameError,
              showbusinessNameError: showbusinessNameError
            }}
          />
          {showTable && paylod && !paylod.systemFailure && paylod.length > 0 ? (
            <div
              className="tab-holder pb-1"
            >
              <ValidValueSearchTableComponent tableData={paylod} values={ values} dropdowns = {addDropdowns} />
            </div>
          ) : null}
          <Footer print />
        </div>
      </div>
    </div>
  );
}
export default withRouter(ValidValueSearch);
