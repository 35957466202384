/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import * as actionTypes from '../../Actions/TextManagement/TextManagementActionTypes';

const reducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SET_SPINNER_VALUE:
      return { ...state, showSpinner: action.showSpinner };
    default: return state;
  }
};

export default reducer;
