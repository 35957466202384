/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";

import TableComponent from "../../../../../SharedModules/Table/Table";

const headCells = [
  {
    id: "receivableFCN",
    numeric: false,
    disablePadding: true,
    label: "FCN",
    width: "13%",
    enableHyperLink: true
  },
  {
    id: "reasonCodeDesc",
    numeric: false,
    disablePadding: false,
    label: "Original A/R Reason Code",
    width: "15%",
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: "establishedDate",
    numeric: false,
    disablePadding: false,
    label: "Established Date",
    width: "12%",
    isDate: true
  },
  {
    id: "balanceAmount",
    numeric: false,
    disablePadding: false,
    label: "Balance Amount",
    width: "15%",
    isBalance: true
  },
  {
    id: "transferreasonCode",
    numeric: false,
    disablePadding: false,
    isSelectBox: true,
    label: "Write-off Reason code",
    width: "17%",
    errorKey: "errorKey",
    dropDownData: []
  },
  {
    id: "newTransferFcn",
    numeric: false,
    disablePadding: false,
    label: "Write-Off FCN",
    isNewHyperlink: true,
    width: "13%"
  },
  {
    id: "newBalanceAmount",
    numeric: false,
    disablePadding: false,
    label: "Write-Off Balance Amount",
    width: "15%",
    isBalance: true
  }
];
const tableData = [
  {
    listNumber: "1010",
    description: "200",
    listType: "200",
    dataElementName: "122",
    functionalAreaDesc: "233",
    reasonCode: ""
  }
];
function BulkWriteOffTable(props) {
  const { setspinnerLoader } = props;
  const formatSearchCriteria = row => {
    return row.receivableFCN;
  };

  const editRow = row => event => {
    const payloadData = formatSearchCriteria(row);
    props.history.push({
      pathname: "/ARBulkTransfer",
      state: { payloadData }
    });
  };
  const [headCellsState, setHeadCells] = React.useState(headCells);
  React.useEffect(() => {
    if (props.reasonCodeDropDown) {
      headCells[6].dropDownData = props.reasonCodeDropDown;
    }
  }, [props.reasonCodeDropDown]);
  const tableComp = (
    <TableComponent
      print={ props.print }
      pathTo="/FinancialAccountGrossLevelUpdate?data="
      formatSearchCriteria={ formatSearchCriteria }
      fixedTable
      fixedView
      headCells={ headCells }
      isSearch={ true }
      dropDownData={ props.reasonCodeDropdown }
      handleDropDown={ props.handleTableReasonCode }
      tableData={ props.tableData ? props.tableData : [] }
      onTableRowClick={ editRow }
      defaultSortColumn={ headCells[0].id }
      setRsnCdData={ props.setRsnCdData }
    />
  );
  return tableComp;
}
export default withRouter(BulkWriteOffTable);
