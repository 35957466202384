/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef,useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button, Form } from 'react-bootstrap';
import DialogContentText from '@material-ui/core/DialogContentText';
import CrossReferenceTableComponent from './CrossReferenceTableComponent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import AssociatedValuesTableComponent from './AssociatedValueTableComponent';
import * as ValidValueConstants from './ValidValueConstants';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { validValueAction, ValidValueUpdateAction, validValueViewAction, getAuditLogDetailsAction, getAuditLogRowDetailsAction } from '../../Store/Actions/validValue/validValueActions';
import { useDispatch, useSelector } from 'react-redux';
import { Prompt } from 'react-router-dom';
import NoSaveMessage from '../../../../SharedModules/Errors/NoSaveMessage';
import { AppConfigDropdownActions } from '../../Store/Actions/AppConfigCommon/AppConfigActions';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import dropdownCriteria from './ValidValueView.json';
import { generateUUID, getUTCTimeStamp } from '../../../../SharedModules/DateUtilities/DateUtilities';
import Notes from '../../../../SharedModules/Notes/Notes';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import SuccessMessage from '../../../../SharedModules/Errors/SuccessMessage';
import Footer from '../../../../SharedModules/Layout/footer';
import NavHOC from '../../../../SharedModules/Navigation/NavHOC';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

let newData = [];
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant='h6'>{children}</Typography>
      {onClose
        ? <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

function TabPanel (props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
export default NavHOC({
  Wrapped: ValidValueAdd,
  url: '/ValidvalueView',
  action: validValueViewAction,
  selector: 'appConfigState.validValueState.validValue'
});

function ValidValueAdd (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const _payload_combine = props.payloadViewData ||props.payloadData ||
   props.location.state && (props.location.state.payloadViewData
  || props.location?.state?.payloadDataTable);
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const toPrintRef = useRef();
  
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  const [dataFormat, setDataFormat] = React.useState([]);
  const [ValuesFromValidView, setValuesFromValidView] = React.useState();

  // Audit Log Implementation starts
  const [showLogTable, setShowLogTable] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initialFocusCheck, setInitialFocusCheck] = React.useState(false);
  const [totalCharacters] = React.useState(100);

  const onClickAuditLog = () => {
    const tableName = 'r_dd_domain_tb';
    const keyValue = {
      r_domain_nam: values.domainName
    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, 'auditLogData'));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);
  const auditLogData = useSelector(state => (state.appConfigState.validValueState?.auditData));
  const [auditRowData, setAuditRowData] = React.useState([]);
  const filterAuditTable = (rowData) => {
    return (rowData.afterValue || rowData.beforeValue);
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);

  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const auditLogTableData = useSelector(state => (state.appConfigState.validValueState.auditRowData));
  const [auditTableData, setAuditTableData] = React.useState([]);
  const [close, setClose] = React.useState(true);

  const onClickAuditDialogLog = (row) => {
    const tableName = 'r_vv_tb';
    const keyValue = {
      r_vv_domain_nam: values.domainName,
      r_vv_cd: row.code
    };
    dispatch(getAuditLogRowDetailsAction(tableName, keyValue, 'auditLogTableData'));
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById('audit-dialog-table')) { document.getElementById('audit-dialog-table').scrollIntoView(); }
  }, [showLogDialogTable]);
  // Audit Log Implementation ends

  const getValues = _payloadData => ({
    code: '',
    dataBusinessName: _payloadData.dataBusinessName || '',
    domainName: _payloadData.domainName || '',
    description: _payloadData.description || '',
    dataformat: _payloadData.dataFormat || '',
    dataSize: _payloadData.dataSize || '',
    functionalAreas: _payloadData.functionalAre ? _payloadData.functionalArea : DEFAULT_DD_VALUE,
    shortDescription: '',
    longDescription: '',
    constantText: '',
    crossReference:_payloadData.crossReference||'',
    sourceTable: _payloadData.sourceTableName || '',
    sourceCodeDescription: _payloadData.sourceCodeDescription || '',
    sourceCode: _payloadData.sourceCode || '',
    associatedselectedOption: '' || '',
    selectedOption: false,
    versionNo: _payloadData.versionNo || '',
    validValueUpdate: _payloadData.endUserUpdate || _payloadData.endUserUpdate === 'true' ? 'Yes' : 'No',
    dbRecord: _payloadData.dbRecord||'',
    sortColumn: _payloadData.sortColumn||'',
    auditKeyList: _payloadData.auditKeyList||'',
    auditKeyListFiltered: _payloadData.auditKeyListFiltered||'',
    showSourceField: _payloadData.showSourceField||'',
    showAssociativeActive: _payloadData.showAssociativeActive||'',
  });

  const [tableData, setTableData] = React.useState(null);
  const [filteredData, setFilteredData] = React.useState(null);
  const [tableDataCrossReference, setTableDataCrossReference] = React.useState(null);
  const [searchData, setSearchData] = React.useState(null);
  const [values, setValues] = React.useState(getValues({}));
  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);

  useEffect(() => {
    if (_payload_combine) {
      const _payload_combine1 = Array.isArray(_payload_combine)?_payload_combine[0]:_payload_combine;
      !tableData && setTableData(_payload_combine1.associatedValidValues);
      !filteredData && setFilteredData(_payload_combine1.associatedValidValues.filter(value => value.voidDate == null));
      !tableDataCrossReference && setTableDataCrossReference(_payload_combine1.crossReference);
      !searchData && setSearchData(_payload_combine1);
      !values.dataBusinessName && setValues(getValues(_payload_combine1));
    }
  }, [props.payloadData]);

  const validvaluecons = ValidValueConstants;
  let errorMessagesArray = [];
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [{ showCodeError, showConstanttError, showShortDescriptionError, showLongDescriptionError, showDateOverlappingError, showSourceTableError, showFunctionalAreaError }
    , setShowError] = React.useState(false);
  const [{
    showCodeErrorText,
    showConstanttErrorText,
    showShortDescriptionErrorText,
    showLongDescriptionErrorText,
    showSourceTableErrorText,
  }, setShowErrorText] = React.useState('');
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [rowAssociatedValidValueData, setRowAssociatedValidValueData] = React.useState([]);

  const [value, setValue] = React.useState(0);
  const [showVoid, setShowVoid] = React.useState(false);
  const [openAddForm, setOpenAddForm] = React.useState(false);
  const [openEditForm, setOpenEditForm] = React.useState(false);
  // Notes
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []

  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({});

  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});

  const dispatch = useDispatch();
  const dropDownDispatch = (dropdownvalues) => dispatch(AppConfigDropdownActions(dropdownvalues));
  const dropdown = useSelector(state => state.appConfigState.AppConfigCommonState?.appConfigDropdown);

  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
  }, []);

  const onSave = (values) => dispatch(ValidValueUpdateAction(values));
  const getSearchRecord = (values) => dispatch(validValueAction(values));
  const backendMsg = useSelector(state => state.appConfigState.validValueState?.validValueUpdate);
  const updatedRecord = useSelector(state => state.appConfigState.validValueState?.validValue);
  useEffect(() => {
    const tempData = JSON.parse(JSON.stringify(values));
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1017']) {
        setFunctionalAreaData(dropdown.listObj['Reference#1017']);
        if (dropdown.listObj['Reference#1017'].length === 1) {
          tempData.functionalAreas = dropdown.listObj['Reference#1017'][0].code;
        }
      }
      if (dropdown.listObj['Reference#1050']) {
        setDataFormat(dropdown.listObj['Reference#1050']);
        if (dropdown.listObj['Reference#1050'].length === 1) {
          tempData.dataFormat = dropdown.listObj['Reference#1050'][0].code;
        }
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }
    setValues(tempData);
  }, [dropdown]);

  const [dataElement, setDataElement] = React.useState({
    associatedselectedOption: 'No',
    addedAuditTimeStamp: new Date(),
    addedAuditUserID: 'SYSADMIN',
    auditKeyListFiltered: false,
    auditTimeStamp: new Date(),
    auditUserID: 'SYSADMIN',
    code: values && values.functionalAreas && values.functionalAreas !== DEFAULT_DD_VALUE ? values.functionalAreas.substring(0, 2) : null,
    constantText: '',
    dbRecord: false,
    longDescription: '',
    shortDescription: '',
    showVoidRecord: false,
    showVoids: false,
    sortColumn: null,
    valueStatus: true,
    versionNo: 0,
    voidDate: null,
    voidIndicator: false
  });
  const userInquiryPrivileges = !global.globalIsReadOnly();
  useEffect(() => {
    if (searchData && searchData.noteSetVO) {
      const noteSetVO = searchData.noteSetVO;
      const notesTable = searchData.noteSetVO.notesList;
      setNotesInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: searchData && searchData !== undefined ? (searchData.noteSetVO.addedAuditUserID ? searchData.noteSetVO.addedAuditUserID : logInUserID) : logInUserID,
        addedAuditTimeStamp: searchData && searchData !== undefined ? (searchData.noteSetVO.addedAuditTimeStamp ? searchData.noteSetVO.addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
        versionNo: noteSetVO.versionNo,
        dbRecord: noteSetVO.dbRecord,
        sortColumn: noteSetVO.sortColumn,
        tableName: noteSetVO.tableName,
        noteSetSK: noteSetVO.noteSetSK,
        noteSourceName: noteSetVO.noteSourceName,
        notesList: notesTable,
        globalNotesList: [],
        checkAll: noteSetVO.checkAll,
        addNewLinkRender: noteSetVO.addNewLinkRender,
        filterLinkRender: noteSetVO.filterLinkRender,
        printLinkRender: noteSetVO.printLinkRender,
        completeNotesList: []
      });
      setNotesTableData(notesTable);
    }
  }, [searchData, dropdown]);

  useEffect(() => {
    if (notesInput) {
      setNoteSetListInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: notesInput && notesInput !== undefined ? (notesInput.addedAuditUserID ? notesInput.addedAuditUserID : logInUserID) : logInUserID,
        addedAuditTimeStamp: notesInput && notesInput !== undefined ? (notesInput.addedAuditTimeStamp ? notesInput.addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
        versionNo: notesInput.versionNo,
        dbRecord: false,
        sortColumn: null,
        noteTextValue: null,
        userIdName: null,
        notesCexAuditUserID: null,
        notesCexAuditTimeStamp: null,
        notesCexAddedAuditUserID: null,
        notesCexAddedAuditTimeStamp: null,
        noteSetSK: notesInput.noteSetSK,
        usageTypeDesc: '',
        shortNotes: null,
        checked: false,
        renderNoHistoryMsg: false,
        noteSequenceNumber: null,
        currentNote: null,
        rowValue: null,
        usageTypeList: null,
        strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
        usageTypeCode: DEFAULT_DD_VALUE,
        tableName: null,
        noteText: '',
        commonEntityName: null,
        commonEntityTypeCode: null,
        commonEntityId: null,
        entityId: null,
        filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
        filterEndDate: null,
        userId: notesInput.userId ? notesInput.userId : logInUserID,
        noteCexVersionNum: notesInput.noteCexVersionNum ? notesInput.noteCexVersionNum : 0,
        saNoteSequenceNumber: notesInput.saNoteSequenceNumber ? notesInput.saNoteSequenceNumber : null,
        notesCexnoteTextValue: notesInput.notesCexnoteTextValue ? notesInput.notesCexnoteTextValue : 0,
        id: generateUUID()
      });
    }
  }, [notesInput]);

  const handleClickOpen = () => {
    seterrorMessages([]);
    setSuccessMessages([]);
    setShowErrorText({
      showCodeErrorText: '',
      showConstanttErrorText: '',
      showShortDescriptionErrorText: '',
      showLongDescriptionErrorText: '',
      showSourceTableErrorText: '',
    });
    setShowError({
      showCodeError: false,
      showConstanttError: false,
      showShortDescriptionError: false,
      showLongDescriptionError: false,
      showDateOverlappingError: false,
      showSourceTableError: false,
      showFunctionalAreaError: false
    });
    setOpenAddForm(true);
	setOpenEditForm(false);
    setDataElement({
      code: '',
      longDescription: '',
      description: ''
    });
  };

  const handleClose = () => {
    setFocusCheck(initialFocusCheck)
    setAllowNavigation(initialFocusCheck)
    seterrorMessages([]);
    setSuccessMessages([]);
    setShowErrorText({
      showCodeErrorText: '',
      showConstanttErrorText: '',
      showShortDescriptionErrorText: '',
      showLongDescriptionErrorText: '',
      showSourceTableErrorText: '',
    });
    setShowError({
      showCodeError: false,
      showConstanttError: false,
      showShortDescriptionError: false,
      showLongDescriptionError: false,
      showDateOverlappingError: false,
      showSourceTableError: false,
      showFunctionalAreaError: false
    });
	setOpenAddForm(false);
  };

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitialFocusCheck(true);
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeDataElement = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setInitialFocusCheck(true);
    if (name === 'code' || name === 'constantText') {
      setDataElement({ ...dataElement, [name]: (event.target.value).toUpperCase() });
    } else {
      setDataElement({ ...dataElement, [name]: event.target.value });
    }
  };

  const handleReset = (row) => {
    setFocusCheck(initialFocusCheck)
    setAllowNavigation(initialFocusCheck);
    errorMessagesArray = [];
    seterrorMessages([]);
    setSuccessMessages([]);
    setShowErrorText({
      showCodeErrorText: '',
      showConstanttErrorText: '',
      showShortDescriptionErrorText: '',
      showLongDescriptionErrorText: '',
      showSourceTableErrorText: '',
    });
    setShowError({
      showCodeError: false,
      showConstanttError: false,
      showShortDescriptionError: false,
      showLongDescriptionError: false,
      showDateOverlappingError: false,
      showSourceTableError: false,
      showFunctionalAreaError: false
    });
    setDataElement({
      associatedselectedOption: '',
      addedAuditTimeStamp: '',
      addedAuditUserID: '',
      auditKeyListFiltered: '',
      auditTimeStamp: '',
      auditUserID: '',
      code: '',
      constantText: '',
      dbRecord: '',
      longDescription: '',
      shortDescription: '',
      showVoidRecord: '',
      showVoids: '',
      sortColumn: '',
      valueStatus: '',
      versionNo: 0,
      voidDate: null,
      voidIndicator: ''
    });
  };

  const [newRecord, setNewRecord] = React.useState(
    {
      associatedselectedOption: '',
      addedAuditTimeStamp: '',
      addedAuditUserID: '',
      auditKeyListFiltered: '',
      auditTimeStamp: '',
      auditUserID: '',
      code: '',
      constantText: '',
      dbRecord: '',
      longDescription: '',
      shortDescription: '',
      showVoidRecord: '',
      showVoids: '',
      sortColumn: '',
      valueStatus: '',
      versionNo: 0,
      voidDate: null,
      voidIndicator: ''
    }
  );
  const handleAdd = () => {
    errorMessagesArray = [];
    seterrorMessages([]);
    setSuccessMessages([]);
    let count = 0;
    var showCodeError; var showConstanttError; var showShortDescriptionError; var showLongDescriptionError; var showDateOverlappingError = false;
    var showCodeErrorText; var showConstanttErrorText; var showShortDescriptionErrorText; var showLongDescriptionErrorText = '';

    if (!dataElement.code || (dataElement.code && !dataElement.code.trim())) {
      showCodeError = true;
      showCodeErrorText = validvaluecons.CODE_DATASIZE_REQUIRED;
      errorMessagesArray.push(showCodeErrorText);
      seterrorMessages(errorMessagesArray);
    }
	if ((dataElement.code && dataElement.code.trim()) && dataElement.code.trim().length > values.dataSize) {
      showCodeError = true;
      showCodeErrorText = validvaluecons.CODE_DATASIZE_ERROR;
      errorMessagesArray.push(showCodeErrorText);
      seterrorMessages(errorMessagesArray);
    }
    if (dataElement.code && tableData.length > 0) {
      tableData.map((value, index) => {
        if (value.code === dataElement.code && value.voidDate === null) {
          count = count + 1;
          return false;
        }
      });
      if (count > 0) {
        showCodeError = true;
        showCodeErrorText = validvaluecons.VALID_VALUE_CODE_OVERLAPPING;
        errorMessagesArray.push(showCodeErrorText);
        seterrorMessages(errorMessagesArray);
      }
    }
    const alphaNumericCheck = /^[a-z0-9]+$/i;
    if (dataElement.code && (!alphaNumericCheck.test(dataElement.code))) {
      showCodeError = true;
      showCodeErrorText = validvaluecons.CODE_INVALID;
      errorMessagesArray.push(showCodeErrorText);
      seterrorMessages(errorMessagesArray);
    }
    if (dataElement.constantText === undefined || dataElement.constantText === '') {
      if (dataElement.shortDescription !== undefined && dataElement.shortDescription !== '') {
        dataElement.constantText = 'VV_' + dataElement.shortDescription.replace(/[^A-Z0-9]/ig, '_');
        if (dataElement.constantText.replace(/[^A-Z0-9]/ig, '_') !== dataElement.constantText) {
          showConstanttError = true;
          showConstanttErrorText = ValidValueConstants.TEXT_INVALID_FORMAT;
        } else {
          dataElement.constantText = dataElement.constantText.replace(/[^A-Z0-9]/ig, '_');
        }
      } else {
        showConstanttError = true;
        showConstanttErrorText = validvaluecons.CONSTANT_TEXT_REQUIRED;
        errorMessagesArray.push(showConstanttErrorText);
        seterrorMessages(errorMessagesArray);
      }
    } else {
      if (dataElement.constantText.replace(/[^A-Z0-9]/ig, '_') !== dataElement.constantText) {
        showConstanttError = true;
        showConstanttErrorText = ValidValueConstants.TEXT_INVALID_FORMAT;
      } else {
        if (!dataElement.constantText.toUpperCase().startsWith('VV_')) {
          dataElement.constantText = 'VV_' + dataElement.constantText;
        }
        dataElement.constantText = dataElement.constantText.replace(/[^A-Z0-9]/ig, '_');
      }
    }
    let dupConstantText = false;
    if (dataElement.constantText !== undefined && dataElement.constantText !== '') {
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].constantText === dataElement.constantText && tableData[i].voidDate === null) {
          dupConstantText = true;
          break;
        }
      }
      if (dupConstantText) {
        showConstanttError = true;
        showConstanttErrorText = validvaluecons.CONSTANT_TEXT_UNIQUE_ERROR;
        errorMessagesArray.push(showConstanttErrorText);
        seterrorMessages(errorMessagesArray);
      }
    }

    if (!dataElement.shortDescription) {
      showShortDescriptionError = true;
      showShortDescriptionErrorText = validvaluecons.SHORT_DESCRIPTION_REQUIRED;
      errorMessagesArray.push(showShortDescriptionErrorText);
      seterrorMessages(errorMessagesArray);
    }
    if (!dataElement.longDescription) {
      showLongDescriptionError = true;
      showLongDescriptionErrorText = validvaluecons.LONG_DESCRIPTION_REQUIRED;
      errorMessagesArray.push(showLongDescriptionErrorText);
      seterrorMessages(errorMessagesArray);
    }
    const regex = /^^[a-zA-Z0-9 _/\-',.]*$/;
    if (!regex.test(dataElement.longDescription)) {
      showLongDescriptionError = true;
      showLongDescriptionErrorText = validvaluecons.LONG_DES_INVALID;
      errorMessagesArray.push(showLongDescriptionErrorText);
      seterrorMessages(errorMessagesArray);
      setOpenAddForm(true);
	  setOpenEditForm(false);
    }
    if (!regex.test(dataElement.shortDescription)) {
      showShortDescriptionError = true;
      showShortDescriptionErrorText = validvaluecons.SHORT_DES_INVALID;
      errorMessagesArray.push(showShortDescriptionErrorText);
      seterrorMessages(errorMessagesArray);
      setOpenAddForm(true);
	  setOpenEditForm(false);
    }

    seterrorMessages(errorMessagesArray);

    if ((errorMessagesArray.length === 0) &&
      (!showCodeError && !showConstanttError && !showShortDescriptionError &&
        !showLongDescriptionError && !showDateOverlappingError)) {
      const code = dataElement.code.toString().trim();
      const dataElementdata = {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: logInUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        code: code,
        shortDescription: dataElement.shortDescription,
        longDescription: dataElement.longDescription,
        voidDate: null,
        auditKeyList: [],
        constantText: dataElement.constantText,
        auditKeyListFiltered: '',
        dbRecord: false,
        showVoidRecord: false,
        showVoids: false,
        sortColumn: '',
        valueStatus: null,
        versionNo: 0,
        voidIndicator: false,
        isNewRow: true,
        id: generateUUID()
      };
      setDataElement(dataElementdata);
      setNewRecord(dataElement);
      newData = tableData;
      newData.unshift(dataElementdata);
      setTableData(newData);
      const filterData = newData.filter((value, index) => {
        return value.voidDate == null;
      });
      setFilteredData(filterData);
      setOpenAddForm(false);
    }
    setShowError({
      showCodeError: showCodeError,
      showConstanttError: showConstanttError,
      showShortDescriptionError: showShortDescriptionError,
      showLongDescriptionError: showLongDescriptionError,
      showDateOverlappingError: showDateOverlappingError
    });

    setShowErrorText({
      showCodeErrorText: showCodeErrorText,
      showConstanttErrorText: showConstanttErrorText,
      showShortDescriptionErrorText: showShortDescriptionErrorText,
      showLongDescriptionErrorText: showLongDescriptionErrorText
    });
  };
  const [flag, setFlag] = React.useState(false);
  useEffect(() => {
    setSpinnerLoader(false);
    if (updatedRecord && !updatedRecord.systemFailure && updatedRecord.length === 1) {
      setValues({
        code: updatedRecord[0].sourceCode,
        dataBusinessName: updatedRecord[0].dataBusinessName,
        domainName: updatedRecord[0].domainName,
        description: updatedRecord[0].description,
        dataformat: updatedRecord[0].dataFormat,
        dataSize: updatedRecord[0].dataSize,
        functionalAreas: updatedRecord[0].functionalArea,
        shortDescription: '',
        longDescription: '',
        constantText: '',
        sourceTable: updatedRecord[0].sourceTableName,
        sourceCodeDescription: updatedRecord[0].sourceCodeDescription,
        sourceCode: updatedRecord[0].sourceCode,
        associatedselectedOption: '',
        selectedOption: false,
        versionNo: updatedRecord[0].versionNo,
        validValueUpdate: updatedRecord[0].endUserUpdate === true || updatedRecord[0].endUserUpdate === 'true' ? 'Yes' : 'No'
      });
      setTableDataCrossReference(updatedRecord[0].crossReference);
      setTableData(updatedRecord[0].associatedValidValues);
      const filterData = updatedRecord[0].associatedValidValues.filter((value, index) => {
        return value.voidDate == null;
      });
      setFilteredData(filterData);
      setFlag(!flag);
      if (updatedRecord[0] && updatedRecord[0].noteSetVO) {
        const searchData = updatedRecord[0];
        const noteSetVO = searchData.noteSetVO;
        const notesTable = searchData.noteSetVO.notesList;
        setNotesInput({
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: searchData && searchData !== undefined ? (searchData.noteSetVO.addedAuditUserID ? searchData.noteSetVO.addedAuditUserID : logInUserID) : logInUserID,
          addedAuditTimeStamp: searchData && searchData !== undefined ? (searchData.noteSetVO.addedAuditTimeStamp ? searchData.noteSetVO.addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
          versionNo: noteSetVO.versionNo,
          dbRecord: noteSetVO.dbRecord,
          sortColumn: noteSetVO.sortColumn,
          tableName: noteSetVO.tableName,
          noteSetSK: noteSetVO.noteSetSK,
          noteSourceName: noteSetVO.noteSourceName,
          notesList: notesTable,
          globalNotesList: [],
          checkAll: noteSetVO.checkAll,
          addNewLinkRender: noteSetVO.addNewLinkRender,
          filterLinkRender: noteSetVO.filterLinkRender,
          printLinkRender: noteSetVO.printLinkRender,
          completeNotesList: []
        });
        setNotesTableData(notesTable);
      }
    }
  }, [updatedRecord]);

  React.useEffect(() => {
    setSpinnerLoader(false);
    if (backendMsg && !backendMsg.systemFailure && backendMsg.respcode && backendMsg.respcode === '01') {
      getSearchRecord({
        businessName: values.dataBusinessName !== '' ? values.dataBusinessName : null,
        dataElementName: values.domainName !== '' ? values.domainName : null
      });
      setSuccessMessages([backendMsg.respdesc]);
      setAllowNavigation(false);
      setFocusCheck(false);
    } else if (backendMsg && !backendMsg.systemFailure && backendMsg.respcode && backendMsg.respcode !== '01') {
      setSuccessMessages([]);
      seterrorMessages([backendMsg.respdesc]);
    } else if (backendMsg && backendMsg.systemFailure) {
      setSuccessMessages([]);
      seterrorMessages([ValidValueConstants.ERROR_PROCESSING_REQUEST]);
    }
  }, [backendMsg]);
  // Updated table data from associated valid value
  const updateTableData = (tableDataUpdated) => {
    seterrorMessages([]);
    setSuccessMessages([]);
    setAllowNavigation(true);
    setTableData(tableDataUpdated);
    const filteredData = tableDataUpdated.filter((value, index) => {
      return value.voidDate == null;
    });
    setFilteredData(filteredData);
  };
  React.useEffect(() => {
    if (tableData && tableData.length > 0) {
      tableData.map((row) => {
        row.tempID = generateUUID();
      });
    }
  }, [tableData]);

  const handleShowVoidsChange = event => {
    if (showVoid) {
      setShowVoid(false);
    } else {
      setShowVoid(true);
    }
  };
  const masterSaveDataElement = () => {
    seterrorMessages([]);
    setSuccessMessages([]);
    setShowError([]);
    setShowErrorText([]);
    var showSourceTableError = false; var showFunctionalAreaError = false;
    var showSourceTableErrorText = '';
    if (!allowNavigation) {
      NoSaveMessage();
    } else {
      if (values.sourceTable === '') {
        showSourceTableError = true;
        showSourceTableErrorText = validvaluecons.SOURCE_TABLE_REQUIRED;
        errorMessagesArray.push(showSourceTableErrorText);
        seterrorMessages(errorMessagesArray);
      }     
      let nonVoidCount = 0;
      for (var tabVal in tableData) {
        if (tableData[tabVal].voidDate === null) {
          nonVoidCount = nonVoidCount + 1;
        }
      }
      if (nonVoidCount === 0 && searchData?.sourceTableName === 'R_VV_TB') {
        errorMessagesArray.push(validvaluecons.ASSOCIATED_VALID_VALUE_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
      if (errorMessagesArray.length === 0) {
        for (var tabVal in tableData) {
          if (tableData && tableData[tabVal] && tableData[tabVal].voidDate && tableData[tabVal].voidDate !== null) {
            tableData[tabVal].voidDate = tableData[tabVal].voidDate.split(' ')[0];
          }
        }
        const tabDataTemp = JSON.parse(JSON.stringify(tableData));
        tabDataTemp.map((value) => {
          if (value._edit_audit) {
            value.auditUserID = logInUserID;
            value.auditTimeStamp = getUTCTimeStamp();
          }
          if (value.id) {
            value.addedAuditTimeStamp = getUTCTimeStamp();
            value.addedAuditUserID = logInUserID;
            value.auditUserID = logInUserID;
            value.auditTimeStamp = getUTCTimeStamp();
          }
        });
        // API Integration
        const editCriteria =
        {
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: searchData && searchData !== undefined ? (searchData.addedAuditUserID ? searchData.addedAuditUserID : logInUserID) : logInUserID,
          addedAuditTimeStamp: searchData && searchData !== undefined ? (searchData.addedAuditTimeStamp ? searchData.addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
          versionNo: values.versionNo,
          dbRecord: values.dbRecord,
          sortColumn: values.sortColumn,
          auditKeyList: values.auditKeyList,
          auditKeyListFiltered: values.auditKeyListFiltered,
          domainName: values.domainName,
          showSourceField: values.showSourceField,
          showAssociativeActive: values.showAssociativeActive,
          sourceCode: values.sourceCode,
          sourceCodeDescription: values.sourceCodeDescription,
          dataBusinessName: values.dataBusinessName,
          dataFormat: values.dataFormat,
          dataSize: values.dataSize,
          description: values.description,
          sourceTableName: values.sourceTable,
          crossReference: values.crossReference,
          associatedValidValues: tabDataTemp,
          endUserUpdate: 'true',
          noteSetVO: notesInput
        };

        onSave(editCriteria);
        setSpinnerLoader(true);
      }
    }
    setShowError({
      showSourceTableError: true,
      showFunctionalAreaError: true
    });
    setShowErrorText({
      showSourceTableErrorText: showSourceTableErrorText,
    });
  };

  const rowDeleteAssociatedValidValue = data => {
    setRowAssociatedValidValueData({ ...rowAssociatedValidValueData, rowAssociatedValidValueData: data });
  };

  function associatedValidValueRowDeleteClick () {
    let temNewDialogData = [...tableData];
    if (rowAssociatedValidValueData.rowAssociatedValidValueData) {
      for (let i = 0; i < rowAssociatedValidValueData.rowAssociatedValidValueData.length; i++) {
        if (rowAssociatedValidValueData.rowAssociatedValidValueData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(payment => payment.id !== rowAssociatedValidValueData.rowAssociatedValidValueData[i]);
        }
      }
    };
    updateTableData(temNewDialogData);
    setRowAssociatedValidValueData([]);
  }
  const [openDialog, setOpenDialog] = React.useState(false);
  const confirmDelete = (event) => {
    associatedValidValueRowDeleteClick();
    setOpenDialog(false);
  };
  const openDeletePopUpDialog = () => {
    if (rowAssociatedValidValueData.rowAssociatedValidValueData.length !== 0) {
      setOpenDialog(true);
    }
  };
  const closeDeletePopUpDialog = () => {
    setOpenDialog(false);
  };
  // add Notes
  let notesDataArray = [];

  const addNotes = (data) => {
    setAllowNavigation(true);

    data.usageTypeCode = data.usageTypeCode === DEFAULT_DD_VALUE ? '' : data.usageTypeCode;
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };
  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    if (
      (!(values.sourceTable) || values.sourceTable === '')
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    setShowError(false);
  };

  useEffect(() => {
      if (props.history?.location?.state?.values !== undefined)      
    {
      setValuesFromValidView(props.history.location.state.values);
      }}, [props.history.location?.state?.values]);

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(()=>{
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/ValidvalueSearch',
        tableRender: true,
        state: { ValuesFromValidView: ValuesFromValidView }
      });
    },100)
  };
  const majorCancel = () => {
    seterrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/ValidvalueSearch',
        tableRender: true,
        state: { ValuesFromValidView: ValuesFromValidView }
      });
    }
  };

  let functArea = functionalAreaData.find(value => value.code === values.functionalAreas);

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      {/* {spinnerLoader || !searchData ? <Spinner /> : null} */}
      <div className="tabs-container" ref={toPrintRef}>
        {errorMessages.length === 0 ? <SuccessMessage successMessages={successMessages} /> : null}
        <ErrorMessages errorMessages={errorMessages} />

        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Edit Valid Value
          </h1>
          <div className="float-right mt-2">
            <Button variant="outlined" color="primary" className='btn btn-success ml-1' onClick={masterSaveDataElement} disabled={!userInquiryPrivileges}>
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button color="primary"
              className={
                auditButtonToggle
                  ? 'btn btn-primary ml-1'
                  : 'btn btn-text1 btn-transparent ml-1'
              }
              onClick={() => {
                setAuditButtonToggle(!auditButtonToggle);
                setShowLogTable(true);
              }}
            >
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
              Audit Log
            </Button>
            {window.location.pathname === '/ValidvalueView' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <div className='pb-2'>
            <form autoComplete='off'>
              <div className="form-wrapper">
                <div className='mui-custom-form input-md'>
                  <TextField
                    id="standard-dataelement"
                    required
                    label="Data Element Name"
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    inputProps={{ maxLength: 30 }}
                    value={values.domainName}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-business-name"
                    fullWidth
                    required
                    label="Business Name"
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    inputProps={{ maxLength: 40 }}
                    value={values.dataBusinessName}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                <div className='mui-custom-form override-width-45'>
                  <TextField
                    id='standard-descriptioon'
                    fullWidth
                    required
                    inputProps={{ maxLength: 320 }}
                    label='Description'
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    value={values.description}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
              </div>
              <div className="form-wrapper">
                <div className='mui-custom-form input-md'>
                  <TextField
                    id='standard-sourceTable'
                    fullWidth
                    label='Source Table'
                    required
                    InputProps={{ readOnly: !userInquiryPrivileges, className: !userInquiryPrivileges ? 'Mui-disabled' : '' }}
                    inputProps={{ maxLength: 30 }}
                    value={values.sourceTable}
                    onChange={handleChange('sourceTable')}
                    placeholder=""
                    error={showSourceTableError ? showSourceTableErrorText : null}
                    helperText={showSourceTableError ? showSourceTableErrorText : null}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
                  <NativeDropDown
                    id='standard-dataFormate'
                    isRequired
                    label='Data Format'
                    disabled={true}
                    value={values.dataformat}
                    inputProps={{ maxLength: 2 }}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  dropdownList={dataFormat ? dataFormat.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.description}
                      </option>
                    )) : null}
                  />
                <div className='mui-custom-form input-md'>
                  <TextField
                    id='standard-datasize'
                    fullWidth
                    required
                    label='Data Size'
                    InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                    inputProps={{ maxLength: 5 }}
                    value={values.dataSize}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </div>
              </div>
            </form>
            <div className='tab-panelbody m-0' >
              <div className='tab-holder mb-3 mt-4'>
                <AppBar position='static'>
                  <Tabs value={value} onChange={handleChangeTabs} aria-label='simple tabs example'>
                    <Tab label='Cross-Reference' />
                    <Tab label='Associated Valid values' data-testid='test-AVV' />
                    <Tab label='Notes' />
                  </Tabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <div className="tab-holder mt-3">
                    <CrossReferenceTableComponent tableData={tableDataCrossReference} />
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className="tab-holder" >
                    <div className="mui-custom-form no-margin dib show-voids mt-3">
                      <div className="sub-radio">
                        <FormControlLabel
                          control={<Checkbox color="primary" checked={showVoid} value={showVoid} onChange={handleShowVoidsChange} />}
                          label='Show Voids'
                        />
                      </div>
                    </div>
                    <div className="float-right my-3">
                      <Button className="btn-text-main-delete btn-transparent" onClick={openDeletePopUpDialog}
                        title={'Delete'}

                      >
                        <i className="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </Button>
                      <Button variant='outlined' color='primary' className='btn-text-main-add btn-success ml-1'title={' Add Associated Valid Values'} disabled={!userInquiryPrivileges ? !userInquiryPrivileges : !!(values.sourceTable !== 'R_VV_TB' || values.validValueUpdate === 'No')} onClick={handleClickOpen}>
                        <i className="fa fa-plus" aria-hidden='true' ata-testid='test-AddAVV'></i>
                       Add
                      </Button>
                    </div>
                    <div className="clearfix"></div>

                    <div className="table-no-wrap">

                      <AssociatedValuesTableComponent
                      initialFocusCheck={initialFocusCheck}
                        fixedTable
	                        setAuditLogRow={setAuditLogRow}
                        flag={flag}
                        tableData={tableData}
                        filteredData={filteredData}
                        newRecord={newRecord}
                        searchData={searchData}
                        updateTableData={updateTableData}
                        values={values}
                        showVoid={showVoid}
                        rowDeleteAssociatedValidValue={rowDeleteAssociatedValidValue}
                        onClickAuditDialogLog={onClickAuditDialogLog}
                        uniqueKey={'constantText'}
                        setClose={setClose}
                        close={close}
                        showLogDialogTable={showLogDialogTable}
                        auditRowData={auditTableData}
                        setAuditRowData={setAuditTableData}
                        setShowLogTable={setShowLogDialogTable}
                        setFocusCheck={setFocusCheck}
                        setAllowNavigation={setAllowNavigation}
						setOpenEditForm={setOpenEditForm}
						openEditForm={openEditForm}
						setOpenAddForm ={setOpenAddForm}
                      />
                    </div>
                    {openAddForm ? (
                      <div className="tabs-container" id="form_pop_up">
                        <div className="tab-header  mini-tab-header">
                          <div className="tab-heading float-left" data-testid="test-associated">
            Add Associated Valid Values
                          </div>
                          <div className="float-right mt-1">

                            <Button variant='outlined' color='primary' className='btn btn-success ml-1'
                              onClick={() => handleAdd()} disabled={!userInquiryPrivileges}>
                              <i class="fa fa-check" aria-hidden="true"></i>Add
                            </Button>
                            <Button variant='outlined' color='primary' className='bt-reset btn-transparent'
                              onClick={() => handleReset(dataElement)} disabled={!userInquiryPrivileges}>
                              <i className="fa fa-undo" aria-hidden="true"></i>        Reset
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary btn btn-outlined ml-1"
                              onClick={handleClose}
                            >
                Cancel
                            </Button>
                          </div>
                        </div>


            <div className="form-border my-3"><form autoComplete='off'>
              <div className="form-wrapper" style={{ minWidth: "480px" }}>
              <div className='mui-custom-form input-md override-width-20'>
                              <TextField
                                id='standard-code'
                                fullWidth
                                label='Code'
                                required
                                InputProps={{ readOnly: !userInquiryPrivileges, className: !userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                value={dataElement.code ? dataElement.code.trim() : ''}
                                inputProps={{ maxLength: 15 }}
                                onChange={handleChangeDataElement('code')}
                                placeholder=""
                                helperText={showCodeError ? showCodeErrorText : null}
                                error={showCodeError ? showCodeErrorText : null}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </div>
                            <div className='mui-custom-form input-md override-width-20'>
                              <TextField
                                id='standard-constantText'
                                label='Constant Text'
                                required
                                InputProps={{ readOnly: !userInquiryPrivileges, className: !userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                inputProps={{ maxLength: 50 }}
                                value={dataElement.constantText}
                                onChange={handleChangeDataElement('constantText')}
                                placeholder=""
                                helperText={showConstanttError ? showConstanttErrorText : null}
                                error={showConstanttError ? showConstanttErrorText : null}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </div>
                            <div className='mui-custom-form input-md override-width-20'>
                              <TextField
                                id='standard-shortDescription'
                                label='Short Description'
                                inputProps={{ maxLength: 10 }}
                                helperText={showShortDescriptionError ? showShortDescriptionErrorText : null}
                                error={showShortDescriptionError ? showShortDescriptionErrorText : null}
                                required
                                InputProps={{ readOnly: !userInquiryPrivileges, className: !userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                value={dataElement.shortDescription}
                                onChange={handleChangeDataElement('shortDescription')}
                                placeholder=""
                                InputLabelProps={{
                                  shrink: true
                                }}
                              />
                            </div>
                <div className="mui-custom-form with-textarea override-width-95">
                  <Form.Group>
                    <Form.Label className="MuiFormLabel-root small-label">
                      Long Description{" "}
                      <span class="Mui-required" id="text-label-01">
                        *
                      </span>
                    </Form.Label>
                    <Form.Control
                      aria-describedby="text-label-01"
                      aria-label="form-controll-01"
                      as="textarea"
                      rows="4"
                      name="text"
                      maxLength={totalCharacters}
                      value={dataElement.longDescription}
                      onChange={handleChangeDataElement('longDescription')}
                      InputProps={{ readOnly: !userInquiryPrivileges, className: !userInquiryPrivileges ? 'Mui-disabled' : '' }}
                      inputProps={{ maxLength: 100 }}
                      maxLength={100}
                      style={{ width: "100%" }}
                    />
                      <p
                        class="MuiFormHelperText-root Mui-error Mui-required"
                        role="alert"
                      >
                        {
                 showLongDescriptionError
                 ? showLongDescriptionErrorText
                 : null
                }{' '}
                      </p>
                  </Form.Group>
                  <div
                    style={{
                      border: "1px solid rgb(169, 169, 169)",
                      borderRadius: ".25rem",
                      paddingLeft: "5px",
                      width: "300px",
                      alignText: "center"
                    }}
                  >
                    Characters Remaining: {100-dataElement.longDescription.length}
                  </div>
                </div>
              </div>
            </form>
        </div>
        </div>)
        : null}
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className="tab-holder">
                    <Notes addNotes={addNotes}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      disableNotes={disableNotes}
                      setFocusCheck={setFocusCheck}
                      setErrorMessages={seterrorMessages}
                      userInquiryPrivileges = {userInquiryPrivileges}
                    />
                  </div>
                </TabPanel>
              </div>

              {
                showLogTable
                  ? (<div id='audit-table'>
                    <AuditTableComponent
                      print
                      auditRowData={auditRowData}
                      setAuditRowData={setAuditRowData}
                      setShowLogTable={setShowLogTable}
                    /> </div>) : null
              }
              <Footer print />
            </div>
            <Dialog className="custom-dialog dialog-500 with-padding" fullWidth={true} maxWidth={'sm'} open={openDialog}>
              <DialogContent dividers>
                <div>Are you sure you want to Delete?</div>
              </DialogContent>

              <DialogActions>
                <Button className="btn btn-primary ml-1" onClick={confirmDelete} disabled={!userInquiryPrivileges} >
                  OK
                </Button>
                <Button className="btn btn-primary ml-1" onClick={closeDeletePopUpDialog} disabled={!userInquiryPrivileges}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                  </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setDialogOpen(false);
                  }}
			                 color="primary"
                  className="btn btn-transparent"
                >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
                >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  );
}
