/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import AddRefundDisposition from './AddRefundDisposition';
import TableComponent from '../../../../SharedModules/Table/Table';
import {
  validateAddRefundPopUpDetails,
  addFundPopUpReset,
  addFundPopUpErrMsgReset,
  mapCodeDescription,
  buildAddRefundRowObject
} from '../AddValidationUtility';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import {
  generateUUID,
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import { addRefundRecoveryValidateAction } from '../../Store/Actions/FinancialReceipt/financialReceiptActions';
import * as FinancialReceiptConstants from '../FinancialReceiptConstants';
import numeral from 'numeral';
import moment from 'moment';
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import { checkingDecimal } from '../../../../SharedModules/CommonUtilities/commonUtilities';

const headCells = [
  {
    id: 'fcn',
    numeric: false,
    disablePadding: true,
    label: 'FCN',
    isRedirectable: true,
    width: 100,
    fontSize: 14,
    openInNewTab: true
  },
  {
    id: '_reasonCodeDescr',
    numeric: false,
    disablePadding: false,
    label: 'Reason Code',
    width: 100,
    fontSize: 14,
    isTooltip: true,
    isTitle: 'rcLongDesc'
  },
  {
    id: 'fiscalYear',
    numeric: false,
    disablePadding: false,
    label: 'Fiscal Year',
    width: 100,
    fontSize: 14
  },
  {
    id: '_cosDescr',
    numeric: false,
    disablePadding: false,
    label: 'Category of Service',
    width: 100,
    fontSize: 14,
    isTooltip: true,
    isTitle: 'cosLongDesc'
  },
  {
    id: 'ffpCalculateDate',
    numeric: false,
    disablePadding: false,
    label: 'FFP Calculate Date',
    isDate: true,
    width: 100,
    fontSize: 14
  },
  {
    id: 'payerAmount',
    numeric: false,
    disablePadding: false,
    label: 'Payment Amount',
    isBalance: true,
    width: 100,
    fontSize: 14
  }
];

export default function Refund(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const loginUserID = useSelector(state => state.sharedState.logInUserID);
  // This Variable reduxFix is to Stop Repeated Component reload Which Effects the Data Addition in Add Refund Recovery Table
  const [reduxFix, setReduxFix] = React.useState(false);
  const [spinnerLoaderRefund, setSpinnerLoaderRefund] = React.useState(false);
  const [refundErrorMessages, setRefundErrorMessages] = React.useState([]);
  const [editRefund, setEditRefund] = React.useState(false);

  const dispatch = useDispatch();
  const {
    refundDispositionRsnCodeDD,
    cosDD,
    refundPayeeIDTypeDD,
    setRefundPayeeIDTypeDD,
    addRefundNewFCN,
    onChangePayeeType,
    payeeTab,
    originalTab,
    addRefundTabTableData,
    setAddRefundTabTableData,
    seterrorMessages,
    setspinnerLoader,
    disableAddRefundbtn,
    generateFCN,
    overPaymentsData,
    atrNumber,
    setAllowNavigation,
    userInquiryPrivileges,
    sysParameterPayeeExist,
    setFocusCheck,
    initalFocusCheck,
    historyData,
    refundRecoveryVOList,
    addDropdowns
  } = props;

const [checkStatusDesc, setCheckStatusDesc] = React.useState('');

  const [
    addRefundTableSelectedRows,
    setAddRefundTableSelectedRows
  ] = React.useState([]);

  // Add Refund/ General Recovery Disposition Popup Details
  const [addRefndValues, setAddRefundValues] = React.useState({
    fcn: addRefundNewFCN || 0,
    reasonCode: DEFAULT_DD_VALUE,
    fiscalYear: new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE })).getFullYear(),
    fundCode: DEFAULT_DD_VALUE,
    cos: DEFAULT_DD_VALUE,
    ffpCalculateDate: getDateInMMDDYYYYFormat(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))),
    payerAmount: '0.00',
    entityType: payeeTab.entityType,
    atrNumber: editRefund? refundRecoveryVOList?.atrNumber?.atrNumber :atrNumber||'',
    paymentStatus: '' ,
    entityIDTypeCode: payeeTab.entityIDTypeCode,
    entityID: payeeTab.entityID,
    _reasonCodeDescr: '',
    _cosDescr: ''
  });

  const RefundTabFormat = () => {
    if (addRefndValues.payerAmount) {
      setAddRefundValues({
        ...addRefndValues,
        payerAmount: numeral(addRefndValues.payerAmount).format('0,0.00')
      });
    }
  };
  const removeRefundTabFormat = () => {
    if (addRefndValues.payerAmount) {
      setAddRefundValues({
        ...addRefndValues,
        payerAmount: numeral(addRefndValues.payerAmount).format('0.00')
      });
    }
  }; 

  useEffect(() => {
    if (addRefndValues?.paymentStatus) {
      const paymentStatusCode =
      addDropdowns &&
        addDropdowns['F1#C_STAT_CD'] &&
        addDropdowns['F1#C_STAT_CD'].filter(
          value1 => value1.code == addRefndValues?.paymentStatus
        );
      const paymentStatusDesc =
        paymentStatusCode && paymentStatusCode.length > 0
          ? paymentStatusCode[0].description
          : addRefndValues?.paymentStatus ? addRefndValues?.paymentStatus : '';
      setCheckStatusDesc(paymentStatusDesc);
    } else {setCheckStatusDesc('')}
  }, [addRefndValues]);

  
  const [isDisableFields,setIsDisableFields] = useState(false);
  const [showAddRefundPopUpErr, setShowAddRefundPopUpErr] = React.useState({
    showDispositonCodeErr: false,
    showFCodeErr: false,
    showPaymentAmtErr: false,
    showFinancialYearErr: false,
    showFfpDateErr: false,
    showATRErr:false
  });
  const [addRefundPopUpErrMsg, setAddRefundPopUpErrMsg] = React.useState({
    dispositonCodeErrMsg: '',
    fCodeErrMsg: '',
    paymentAmtErrMsg: '',
    financialYearErrMsg: '',
    fFPDateErrMsg: '',
    atrErrMsg:'',
  });
  const [
    selectedAddRefundTableRow,
    setSelectedAddRefundtableRow
  ] = React.useState(null);
  const addvalidateResult = useSelector(
    state => state.financialReceiptState.validateAddRefundResult
  );

  useEffect(() => {
    if (addvalidateResult && addvalidateResult.systemFailure && reduxFix) {
      setSpinnerLoaderRefund(false);
      const errorMessagesArray = [];
      errorMessagesArray.push(FinancialReceiptConstants.GENERIC_SYSTEM_ERROR);
      setRefundErrorMessages(errorMessagesArray);
      setReduxFix(false);
    }
    if (
      addvalidateResult &&
      !addvalidateResult.systemFailure &&
      addvalidateResult.respcode &&
      reduxFix
    ) {
      setSpinnerLoaderRefund(false);
      if (addvalidateResult.respcode === '01') {
        setReduxFix(false);

        addValidRowToReundTable();
        setRefundErrorMessages([]);
        handleCloseRefund();
        setAllowNavigation(true);
      } else {
        const errorMessagesArray = addvalidateResult.errorMessages;
        setRefundErrorMessages(errorMessagesArray);
        setReduxFix(false);
      }
    }
  }, [addvalidateResult]);
  const addValidRowToReundTable = () => {
    setAllowNavigation(true);
    const tempaddFundTableData = [...addRefundTabTableData];
    //edit
if(      validateAddRefundPopUpDetails(
  addRefndValues,
  selectedFFPCalculateDate,
  setShowAddRefundPopUpErr,
  setAddRefundPopUpErrMsg,
  originalTab,
  seterrorMessages
)){    if (selectedAddRefundTableRow && selectedAddRefundTableRow !== null) {
      tempaddFundTableData.map((value, index) => {
        if (value.fcn === selectedAddRefundTableRow.fcn) {
          value.auditUserID = loginUserID;
          value.auditTimeStamp = getUTCTimeStamp();
          value.dbRecord = false;
          value.sortColumn = null;
          value.auditKeyList = [];
          value.auditKeyListFiltered = false;
          value.fcn = addRefndValues.fcn ? addRefndValues.fcn.toString() : null;
          value.fcnSeqNum = addRefndValues.fcn
            ? +addRefndValues.fcn
              .toString()
              .slice(
                addRefndValues.fcn.toString().length - 5,
                originalTab.fcn.toString().length
              )
            : 49296;
          value.fcnMediaCode = null;
          value.reasonCode = addRefndValues.reasonCode;
          value.fiscalYear = addRefndValues.fiscalYear
            ? addRefndValues.fiscalYear.toString()
            : null;
          value.fundCode = addRefndValues.fundCode;
          value.atrNumber = addRefndValues?.atrNumber?.toUpperCase();
          value.paymentStatus = addRefndValues?.paymentStatus;
          value.cos =
            addRefndValues.cos === DEFAULT_DD_VALUE
              ? null
              : addRefndValues.cos;
          value.updatedAuditTime = new Date();
          value.fundCodeExistsFlag = false;
          value.orgAuditTimeStamp = moment(new Date()).format('MM/DD/YYYY hh:mm:ss A');
          value.tempTransactionCtrlNumber = null;
          value.dispositionDate = null;
          value.ffpCalculateDate = selectedFFPCalculateDate
            ? getDateInMMDDYYYYFormat(selectedFFPCalculateDate)
            : null;
          value.payerAmount = addRefndValues.payerAmount.replace(/,/g, '');
          value.entityType = addRefndValues.entityType;
          value.entityIDTypeCode = addRefndValues.entityIDTypeCode;
          value.entityID = addRefndValues.entityID;
          value._reasonCodeDescr = mapCodeDescription(
            addRefndValues.reasonCode,
            refundDispositionRsnCodeDD
          );
          value.rcLongDesc = getLongDescription(refundDispositionRsnCodeDD, addRefndValues.reasonCode)
          value.cosLongDesc = getLongDescription(cosDD, addRefndValues.cos)
          value._cosDescr =
            addRefndValues.cos === DEFAULT_DD_VALUE
              ? null
              : mapCodeDescription(addRefndValues.cos, cosDD);
          value.oldPayerAmnt = null;
          value.reasonCodeValue = null;
          value.fundCodeValue = null;
          value.cosValue = null;
          value.entityTypeValue = null;
          value.entityIDTypeCodeValue = null;
          value.associatedPayoutApproved = false;
        }
      });
      setSelectedAddRefundtableRow(null);
    }
    //add
    else {
      tempaddFundTableData.push({
        ...addRefndValues,
        atrNumber : addRefndValues?.atrNumber?.toUpperCase(),        
        newRow: true,
        payerAmount: addRefndValues.payerAmount.replace(/,/g, ''),
        auditUserID: loginUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: loginUserID,
        addedAuditTimeStamp: getUTCTimeStamp(),
        ffpCalculateDate: getDateInMMDDYYYYFormat(selectedFFPCalculateDate),
        cos:
          addRefndValues.cos === DEFAULT_DD_VALUE
            ? null
            : addRefndValues.cos,
        _reasonCodeDescr: mapCodeDescription(
          addRefndValues.reasonCode,
          refundDispositionRsnCodeDD
        ),
        _cosDescr:
          addRefndValues.cos === DEFAULT_DD_VALUE
            ? null
            : mapCodeDescription(addRefndValues.cos, cosDD),
        cosLongDesc:
          addRefndValues.cos === DEFAULT_DD_VALUE
            ? null
            : getLongDescription(cosDD, addRefndValues.cos),
        rcLongDesc: getLongDescription(refundDispositionRsnCodeDD, addRefndValues.reasonCode),
        id: generateUUID(),
        fcnSeqNum: addRefndValues.fcn
          ? +addRefndValues.fcn
            .toString()
            .slice(
              addRefndValues.fcn.toString().length - 5,
              originalTab.fcn.toString().length
            )
          : 49296
      });
    }
    setRefundErrorMessages([]);
    handleCloseRefund();
    setAllowNavigation(true);
    setAddRefundTabTableData(tempaddFundTableData);
  }
    addFundPopUpResetbtnEvent();
  };
  const addValidateAPICall = addRowObj => {
    setSpinnerLoaderRefund(true);
    dispatch(addRefundRecoveryValidateAction(addRowObj));
  };

  const addRefundHandleChanges = name => event => {
    if(name === 'payerAmount'){
      if (event.target.value && event.target.value.includes('.')) {
        if(checkingDecimal(event.target.value)){
          setAddRefundValues({ ...addRefndValues, [name]: event.target.value });
        }
      }
      else{        
        setAddRefundValues({ ...addRefndValues, [name]: event.target.value });
    }
    }
    else{        
        setAddRefundValues({ ...addRefndValues, [name]: event.target.value });
    }
    setAllowNavigation(true);
    setFocusCheck(true);
    
  };
  const [
    selectedFFPCalculateDate,
    setSelectedFFPCalculateDate
  ] = React.useState(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE })));

  const handleFFPCalculateDateChange = date => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setSelectedFFPCalculateDate(date);
    if (date !== null && date !== 'Invalid Date') {
      setAddRefundValues({
        ...addRefndValues,
        ffpCalculateDate: getDateInMMDDYYYYFormat(date)
      });
    }
  };
  const handleClickOpenRefund = () => {
    setRefundErrorMessages([]);
    setEditRefund(false);
    setUpdateButton(false);
    setIsDisableFields(false);
    // Generate New FCN Number on Add Refun/ General Recovery Disposition
    if (disableAddRefundButton()) {
      setSelectedFFPCalculateDate(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE })));
      generateFCN();
      addFundPopUpErrMsgReset(
        setShowAddRefundPopUpErr,
        setAddRefundPopUpErrMsg
      );
      const dateToday = new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }));
      const monthToday = dateToday.getMonth() + 1;
      const currFiscalYear = monthToday > 6 ? dateToday.getFullYear() + 1 : dateToday.getFullYear();
      setAddRefundValues({
        atrNumber: atrNumber,
        fcn: (0).toString(),
        reasonCode: DEFAULT_DD_VALUE,
        fiscalYear: currFiscalYear ? currFiscalYear.toString() : (new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE }))).getFullYear().toString(),
        fundCode: DEFAULT_DD_VALUE,
        cos: DEFAULT_DD_VALUE,
        ffpCalculateDate: '',
        payerAmount: '0.00',
        entityType: payeeTab.entityType,
        entityIDTypeCode: payeeTab.entityIDTypeCode,
        entityID: payeeTab.entityID,
        _reasonCodeDescr: '',
        _cosDescr: '',
        auditUserID: 'MDEAKINS',
        auditTimeStamp: null,
        addedAuditUserID: 'DM_User',
        addedAuditTimeStamp: null,
        versionNo: 0,
        dbRecord: false,
        sortColumn: null,
        auditKeyList: [],
        auditKeyListFiltered: false,
        fcnSeqNum: 0,
        fcnMediaCode: null,
        updatedAuditTime: new Date(),
        fundCodeExistsFlag: false,
        orgAuditTimeStamp: moment(new Date()).format('MM/DD/YYYY hh:mm:ss A'),
        tempTransactionCtrlNumber: null,
        dispositionDate: null,
        oldPayerAmnt: null,
        refundVersionNo: 2,
        crossVersionNo: 0,
        reasonCodeValue: null,
        fundCodeValue: null,
        cosValue: null,
        entityTypeValue: null,
        entityIDTypeCodeValue: null,
        associatedPayoutApproved: false,
        receiptVersionNumber: 0
      });
      setOpenRefund(true);
      if (openRefund && document.getElementById('form_pop_up')) {
        document.getElementById('form_pop_up').scrollIntoView();
      }
    }
  };
  const [openRefund, setOpenRefund] = React.useState(false);
  const [updateButton, setUpdateButton] = React.useState(false);
  const handleClose = () => {
    setEditRefund(false);
    setUpdateButton(false);
    setOpenRefund(false);
    setRefundErrorMessages([]);
  };
  const handleCloseRefund = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    setEditRefund(false);
    setUpdateButton(false);
    setOpenRefund(false);
    setRefundErrorMessages([]);
    setSelectedAddRefundtableRow(null);
  };
  const addFundPopUpSavebtnEvent = () => {
    setRefundErrorMessages([]);
    if (
      validateAddRefundPopUpDetails(
        addRefndValues,
        selectedFFPCalculateDate,
        setShowAddRefundPopUpErr,
        setAddRefundPopUpErrMsg,
        originalTab,
        seterrorMessages
      )
    ) {
      // Call Validate API
      addValidateAPICall(
        buildAddRefundRowObject(removeLeadingTrailingSpaces(addRefndValues), removeLeadingTrailingSpaces(payeeTab), removeLeadingTrailingSpaces(originalTab))
      );
      setReduxFix(true);
    }
  };
  const addRefundTableRowClkEvent = row => event => {
   let rowDataLength = historyData.filter(history=>history?.tcn === row.fcn && !history.tcnstatCdO).length ;
   if(rowDataLength !== undefined && rowDataLength > 0){
    setIsDisableFields(true);
       } else (setIsDisableFields(false));  
   
    setEditRefund(true);
    setSelectedAddRefundtableRow({ ...row });
    setUpdateButton(true);
    setSelectedFFPCalculateDate(row.ffpCalculateDate);
    setAddRefundValues({
      id: row.id,
      fcn: row.fcn,
      reasonCode: row.reasonCode,
      fiscalYear: row.fiscalYear,
      atrNumber: row?.atrNumber?.toUpperCase(),
      paymentStatus: row?.paymentStatus,
      fundCode: row.fundCode === null ? DEFAULT_DD_VALUE : row.fundCode,
      cos: row.cos === null ? DEFAULT_DD_VALUE : row.cos,
      ffpCalculateDate:
        row.ffpCalculateDate === ''
          ? getDateInMMDDYYYYFormat(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE })))
          : getDateInMMDDYYYYFormat(selectedFFPCalculateDate),
      payerAmount: numeral(row.payerAmount).format('0,0.00'),
      entityType: row.entityType,
      entityIDTypeCode: row.entityIDTypeCode,
      entityID: row.entityID,
      _reasonCodeDescr: row._reasonCodeDescr,
      _cosDescr: row._cosDescr === null ? DEFAULT_DD_VALUE : row._cosDescr,
      rcLongDesc: row.rcLongDesc === null ? DEFAULT_DD_VALUE : row.rcLongDesc,
      cosLongDesc: row.cos === null ? DEFAULT_DD_VALUE : getLongDescription(cosDD, row.cos),
      auditUserID: 'MDEAKINS',
      auditTimeStamp: null,
      addedAuditUserID: 'DM_User',
      addedAuditTimeStamp: null,
      versionNo: row.versionNo,
      dbRecord: false,
      sortColumn: null,
      auditKeyList: [],
      auditKeyListFiltered: false,
      fcnSeqNum: row.fcnSeqNum,
      fcnMediaCode: null,
      updatedAuditTime: new Date(),
      fundCodeExistsFlag: false,
      orgAuditTimeStamp: moment(new Date()).format('MM/DD/YYYY hh:mm:ss A'),
      tempTransactionCtrlNumber: null,
      dispositionDate: null,
      oldPayerAmnt: null,
      refundVersionNo: row.refundVersionNo,
      crossVersionNo: row.crossVersionNo,
      reasonCodeValue: null,
      fundCodeValue: null,
      cosValue: null,
      entityTypeValue: null,
      entityIDTypeCodeValue: null,
      associatedPayoutApproved: false,
      receiptVersionNumber: row.receiptVersionNumber
    });
    setOpenRefund(true);
  };
  const addRefundSelectedRows = selectedRows => {
    setAddRefundTableSelectedRows([...selectedRows]);
  };
  const addRefundTableRowDeletEvent = () => {
    let tempaddFundTableData = [...addRefundTabTableData];
    if (addRefundTableSelectedRows && addRefundTableSelectedRows.length > 0) {
      addRefundTableSelectedRows.map((selectedRowId, index) => {
        tempaddFundTableData = tempaddFundTableData.filter(
          tempRow => tempRow.id !== selectedRowId
        );
      });
    }
    setAddRefundTabTableData(tempaddFundTableData);
  };
  const addFundPopUpResetbtnEvent = () => {
    setFocusCheck(initalFocusCheck);
    setAllowNavigation(initalFocusCheck);
    setRefundErrorMessages([]);
    seterrorMessages([]);
    addFundPopUpErrMsgReset(setShowAddRefundPopUpErr, setAddRefundPopUpErrMsg);
    if (selectedAddRefundTableRow && selectedAddRefundTableRow !== null) {
      setSelectedFFPCalculateDate(
        new Date(selectedAddRefundTableRow.ffpCalculateDate)
      );
    } else {
      setSelectedFFPCalculateDate(new Date(new Date().toLocaleString("en-US", { timeZone: process.env.REACT_APP_TIMEZONE })));
    }
    addFundPopUpReset(
      payeeTab,
      addRefndValues,
      setAddRefundValues,
      addRefundNewFCN,
      selectedAddRefundTableRow,
      setShowAddRefundPopUpErr,
      setAddRefundPopUpErrMsg
    );
  };
  const disableAddRefundButton = () => {
    if (
      (payeeTab.sysID !== '' && payeeTab.sysID !== null) ||
      originalTab.financialReason === '217'
    ) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (addRefundNewFCN !== 0) {
      setAddRefundValues({
        ...addRefndValues,
        fcn: addRefundNewFCN ? addRefundNewFCN.toString() : null
      });
    }
  }, [addRefundNewFCN]);
  useEffect(() => {
    if (refundPayeeIDTypeDD && refundPayeeIDTypeDD.length > 0) {
      let _PayeeTypeFlag = false;
      refundPayeeIDTypeDD.map((value, index) => {
        if (value.code === payeeTab.entityIDTypeCode) {
          _PayeeTypeFlag = true;
        }
      });
      if (_PayeeTypeFlag) {
        setAddRefundValues({
          ...addRefndValues,
          entityIDTypeCode: payeeTab.entityIDTypeCode
        });
      } else {
        setAddRefundValues({
          ...addRefndValues,
          entityIDTypeCode: DEFAULT_DD_VALUE
        });
      }
    }
  }, [refundPayeeIDTypeDD]);
  useEffect(() => {
    setAddRefundValues({
      ...addRefndValues,
      entityIDTypeCode: DEFAULT_DD_VALUE
    });
    if (addRefndValues.entityType !== DEFAULT_DD_VALUE) {
      setRefundPayeeIDTypeDD([]);
      onChangePayeeType(addRefndValues.entityType);
    }
  }, [addRefndValues.entityType]);

  const onLinkClick = row => event => {
    window.open('fcn', '_blank');
  };
  useEffect(() => {
    if (openRefund && document.getElementById('form_pop_up'))
      document.getElementById('form_pop_up').scrollIntoView();
  }, [openRefund])

  const disablePaymentStatus = editRefund && addRefndValues?.paymentStatus=='P';

  return (
    <div>
      {spinnerLoaderRefund ? <Spinner /> : null}
      <div>
        <div>
          <div className="float-right mr-3">
            <Button
              variant="outlined" color="primary" className='btn-text-main-delete btn-transparent  ml-1'
              onClick={addRefundTableRowDeletEvent}
              disabled={isDisableFields || !userInquiryPrivileges}
              title={"Delete"}
            >
              <i class="fa fa-trash" aria-hidden="true" ></i>
              Delete
            </Button>

            <Button
              variant="outlined" color="primary" className='btn-text-main-add btn-success ml-1'
              disabled={
                !userInquiryPrivileges ||
                payeeTab.sysID === '' ||
                payeeTab.sysID == null ||
                originalTab.financialReason === '217' ||
                sysParameterPayeeExist
              }
              title={'Add Refund/General Recovery Disposition'}
              onClick={handleClickOpenRefund}
              data-testid="test_add_plus_btn"
            >
              <i className="fa fa-plus" aria-hidden="true"></i>
              Add
                      </Button>
          </div>
          <div className="clearfix"></div>
          <div className="tab-holder table-no-wrap my-3">
            <TableComponent
              print={props.print}
              isSearch={false}
              headCells={headCells}
              tableData={addRefundTabTableData}
              onTableRowClick={addRefundTableRowClkEvent}
              onTableRowDelete={addRefundSelectedRows}
              defaultSortColumn={'fcn'}
              onLinkClick={onLinkClick}
              onTableCellButtonClick={props.onClickAuditDialogLog}
              uniqueKey={'reasonCode'}
              setClose={props.setClose}
              close={props.close}
            />
            {openRefund ?
              <div className="tabs-container" id='form_pop_up'>
                <div className="tab-header  mini-tab-header">
                  <div className="tab-heading float-left">
                    {editRefund
                      ? 'Edit Refund/General Recovery Disposition'
                      : 'Add Refund/General Recovery Disposition'}
                  </div>
                  <div className="float-right mt-1">
                    <Button
                      variant="outlined" color="primary" className='btn btn-success ml-1'
                      onClick={addFundPopUpSavebtnEvent}
                      disabled={isDisableFields ||!userInquiryPrivileges||disablePaymentStatus}
                      title= {updateButton ? 'Update' : 'Add'}
                    >
                     <i class="fa fa-check" aria-hidden="true"></i>
                      {updateButton ? 'Update' : 'Add'}
                    </Button>
                    <Button
                      variant="outlined" color="primary" className='bt-reset btn-transparent  ml-1'
                      onClick={addFundPopUpResetbtnEvent}
                      disabled={isDisableFields || !userInquiryPrivileges || disablePaymentStatus}
                      title={'Reset'}
                      data-testid="test_reset_btn"
                    >
                      <i className="fa fa-undo" aria-hidden="true"></i>
                      Reset
                      </Button>
                    <Button
                      variant="outlined" color="primary" className='btn btn-primary btn btn-outlined ml-1'
                      onClick={handleCloseRefund}
                      title={'Cancel'}
                    >
                      Cancel
                      </Button>
                  </div>
                </div>
                <AddRefundDisposition
                  refundErrorMessages={refundErrorMessages}
                  addRefndValues={addRefndValues}
                  atrNumber={editRefund? refundRecoveryVOList?.atrNumber?.atrNumber :atrNumber}
                  paymentStatus={editRefund? checkStatusDesc :''}
                  addRefundHandleChanges={addRefundHandleChanges}
                  setAddRefundValues={setAddRefundValues}
                  selectedFFPCalculateDate={selectedFFPCalculateDate}
                  handleFFPCalculateDateChange={handleFFPCalculateDateChange}
                  addFundPopUpSavebtnEvent={addFundPopUpSavebtnEvent}
                  addFundPopUpResetbtnEvent={addFundPopUpResetbtnEvent}
                  showAddRefundPopUpErr={showAddRefundPopUpErr}
                  addRefundPopUpErrMsg={addRefundPopUpErrMsg}
                  overPaymentsData={overPaymentsData}
                  RefundTabFormat={RefundTabFormat}
                  removeRefundTabFormat={removeRefundTabFormat}
                  updateButton={updateButton}
                  spinnerLoaderRefund={spinnerLoaderRefund}
                  seterrorMessages = {seterrorMessages}
                  isDisableFields={editRefund && isDisableFields}
                  disablePaymentStatus ={disablePaymentStatus}
                  {...props}
                />
              </div>
              : null}
          </div>
        </div>
        <br></br>
      </div>
    </div>
  );
}
