/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState,useEffect  } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import Bootstrap, { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import numeral from 'numeral';
import InputAdornment from '@material-ui/core/InputAdornment';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../../../../SharedModules/services/service';
import TabPanel from '../../../../../../SharedModules/TabPanel/TabPanel';
import Radio from '@material-ui/core/Radio';
import { redirectGrossLevelFCN } from '../../../../../../SharedModules/TabRedirections/TabRedirections';
import { Link } from 'react-router-dom';
import { getLongDescription } from '../../../../../../SharedModules/CommonUtilities/commonUtilities';
import * as Dropdowns from "../../../../../../SharedModules/Dropdowns/dropdowns"; 
import { systemListActions } from '../../../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions';
import NativeDropDown from '../../../../../../SharedModules/Dropdowns/NativeDropDown';
import {
	compareTwoDatesGreaterThanOrEqual
  } from '../../../../../../SharedModules/DateUtilities/DateUtilities';
import moment from 'moment';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function GrossLevelDetails(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const userInquiryPrivileges = !global.globalIsReadOnly();

  const dispatch = useDispatch();
  const getRecord= (dropdownList,elName,inputVal)=>{
    let updatedInputVal= inputVal?.split('-')[0];
    let record;
    if(dropdownList &&  Object.keys(dropdownList).length >0 ){
       record=  dropdownList[elName]?.find(value => value.code === updatedInputVal);
    }
    return record;
  }
  const onReasonCd = (values) => dispatch(systemListActions(values));
  let reasonCdData = useSelector(state => state.appConfigState.systemListState.payload);
  const [reasonCd, setReasonCd] = useState([]);
  
  useEffect(()=>{
    onReasonCd(Dropdowns.AR_BULK_RN9023_CD)
  },[]);
  
  useEffect(()=>{	
	if(reasonCdData !== undefined && reasonCdData!==null ){  
		let date= moment(new Date()).format("MM/DD/YYYY");    
	  const nonVoidedRsnCdsList = reasonCdData[0]?.systemListDetails?.filter(
		  (code, index) => code.voidDate === null
		);const nonVoidedEndDtRsnCdsList = nonVoidedRsnCdsList.filter(
			(code, index) => compareTwoDatesGreaterThanOrEqual(date, code.endDate)
		  );
		  const nonVoidedRsnCds = nonVoidedEndDtRsnCdsList?.map(rsnCd => rsnCd.startingValue)
		  setReasonCd(nonVoidedRsnCds);
	  } 
	}, [reasonCdData]);


// getting record to set desc
let fundCodeRecord = getRecord(props.addDropdowns,'R1#R_FUND_CD',props.values.collocationCode);
let fundCodeVal =fundCodeRecord !== undefined ? fundCodeRecord.description :  null;

// getting record to set desc
let recoupmentRecord = getRecord(props.addDropdowns,'F1#F_RECOUP_TY_CD',props.values.recoupmentTypeCode);
let recoupmentVal =recoupmentRecord !== undefined ? recoupmentRecord.description :  null;

let paymentStatus = props.values.payeePaymentStatusFrmState && props.values.payeePaymentStatusFrmState.find(value => value.code === props.values.payeeStatus);
let cosData = props.values.categoryOfServiceData && props.values.categoryOfServiceData.find(value => value.code === props.values.categoryOfService);
let frequencyData = props.frequencyDataDropdown && props.frequencyDataDropdown.find(value => value.code === props.values.frequency);

  return (
    <div>
      <form autoComplete="off">
        <div className="form-wrapper">
          <div className="mui-custom-form override-m-1">
            <TextField
              id="standard-GrossFCN"
              fullWidth
              label="FCN"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 14 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.fcn }
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              required
              fullWidth
              id="standard-GrossOriginalA/RAmount"
              label="Original A/R Amount"
              name="originalARAmount"
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
              InputProps={ {
                readOnly: true,
                className: 'Mui-disabled',
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              } }
              value={ numeral(props.values.originalARAmount).format('0,0.00') }
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-GrossAdjustedA/RAmount"
              fullWidth
              label="Adjusted A/R Amount"
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ numeral(props.values.adjustedARAmount).format('0,0.00') }
              InputProps={ {
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                readOnly: true,
                className: 'Mui-disabled'
              } }
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-sm override-m-1">
            <TextField
              id="standard-GrossBalanceAmount"
              fullWidth
              label="Balance Amount"
              InputProps={ { startAdornment: <InputAdornment position="start">$</InputAdornment>, readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 11 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ numeral(props.values.balanceAmount).format('0,0.00') }
            >
            </TextField>
          </div>
          <div className="mui-custom-form override-m-1 override-width-10" style={ { lineHeight: '100%' } }>
            <label className='MuiFormLabel-root small-label' >Approval<span>*</span></label>
            <div className="sub-radio no-margin">
              <Radio
                value="Y"
                id="GrossapprovalIdYes"
                checked={ props.values.approval === 'Y' }
                onChange={ props.handleChange('approval') }
                disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : (parseFloat(props.values.balanceAmount) <= 0 || props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount) }
              /><label className="text-black" for="GrossapprovalIdYes">Yes</label>
              <Radio
                value="N"
                id="GrossapprovalIdNo"
                checked={ props.values.approval === 'N' }
                onChange={ props.handleChange('approval') }
                className="ml-2"
                disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : (parseFloat(props.values.balanceAmount) <= 0 || props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount) }
              /><label className="text-black" for="GrossapprovalIdNo">No</label>
            </div>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form input-md override-m-1 has-link">
            <TextField
              id="standard-GrossRelatedFTTCN"
              fullWidth
              label="Related FT TCN"
              InputProps={ {
                readOnly: true, className: 'Mui-disabled', startAdornment: (
                  <InputAdornment position="start">
                    <a href={ CLAIMS_INQUIRY_TCN_LINK + props.values.relatedFTTCN } target='_blank'>
                      { props.values.relatedFTTCN }
                    </a>
                  </InputAdornment>
                )
              } }
              placeholder=""
              inputProps={ { maxlength: 17 } }
              InputLabelProps={ {
                shrink: true
              } }
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1">
            <TextField
              id="standard-GrossEstablishedDate"
              fullWidth
              label="Established Date"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 10 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.establishedDate }
            >
            </TextField>
          </div>
          <div className="mui-custom-form input-md override-m-1" >
            <TextField
              id="standard-GrossLastDateApplied"
              fullWidth
              label="Last Date Applied"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              placeholder=""
              inputProps={ { maxlength: 10 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.values.lastDateApplied }
            >
            </TextField>
          </div>
          <div className="mui-custom-form  input-sm override-m-1">
            <TextField
              id="standard-GrossAgeinDays"
              fullWidth
              label="A/R Age in Days"
              InputProps={ { readOnly: true, className: 'Mui-disabled' } }
              value={ props.values.arAgeInDays }
              placeholder=""
              inputProps={ { maxlength: 3 } }
              InputLabelProps={ {
                shrink: true
              } }
            >
            </TextField>
          </div>
          <div className="mui-custom-form override-m-1 override-width-10" style={ { lineHeight: '100%' } }>
            <label className='MuiFormLabel-root small-label' >Under Appeal<span>*</span></label>
            <div className="sub-radio no-margin">
              <Radio
                value="Y"
                id="GrossunderAppealIdYes"
                checked={ props.values.underAppeal === 'Y' }
                onChange={ props.handleChange('underAppeal') }
                disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : parseFloat(props.values.balanceAmount) <= 0 }
              /><label className="text-black" for="GrossunderAppealIdYes">Yes</label>
              <Radio
                value="N"
                id="GrossunderAppealIdNo"
                checked={ props.values.underAppeal === 'N' }
                onChange={ props.handleChange('underAppeal') }
                className="ml-2"
                disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : parseFloat(props.values.balanceAmount) <= 0 }
              /><label className="text-black" for="GrossunderAppealIdNo">No</label>
            </div>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form  input-md override-m-1">
            <TextField
              id="standard-GrossLetterSent"
              fullWidth
              label="A/R Letter Sent "
              disabled
              placeholder=""
              inputProps={ { maxlength: 3 } }
              InputLabelProps={ {
                shrink: true
              } }
              value={ props.ARletterSent }
            >
            </TextField>
          </div>
          { <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <div className="mui-custom-form with-date override-m-1">
              <KeyboardDatePicker
                maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
				required={ reasonCd.includes(props.values.reasonCode.includes("-")? props.values.reasonCode.split("-")[0]: props.values.reasonCode) }
                id="date-picker-dialogGrossBeginDate"
                label="Begin Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                minDate={ '01/01/1964' }
				disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : (parseFloat(props.values.balanceAmount) <= 0) || !(reasonCd.includes(props.values.reasonCode.includes("-")? props.values.reasonCode.split("-")[0]: props.values.reasonCode)) }
                value={ props.selectedBeginDate }
                onChange={ props.handleBeginDateChange }
                InputLabelProps={ {
                  shrink: true
                } }
                
                KeyboardButtonProps={ {
                  'aria-label': 'change date'
                } }
                helperText={ props.errors[4] ? props.errorText[4] : null || props.errors[7] ? props.errorText[7] : null || props.errors[13] ? props.errorText[13] : null }
                error={ props.errors[4] ? props.errorText[4] : null || props.errors[7] ? props.errorText[7] : null || props.errors[13] ? props.errorText[13] : null }
              />
            </div>
          </MuiPickersUtilsProvider> }
          { <MuiPickersUtilsProvider utils={ DateFnsUtils }>
            <div className="mui-custom-form with-date  override-m-1">
              <KeyboardDatePicker
                maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                required={ reasonCd.includes(props.values.reasonCode.includes("-")? props.values.reasonCode.split("-")[0]: props.values.reasonCode) }
                id="date-picker-dialogEndDate"
                label="End Date"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
				disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : (parseFloat(props.values.balanceAmount) <= 0) || !(reasonCd.includes(props.values.reasonCode.includes("-")? props.values.reasonCode.split("-")[0]: props.values.reasonCode)) }
                value={ props.selectedEndDate }
                onChange={ props.handleEndDateChange }
                InputLabelProps={ {
                  shrink: true
                } }
                KeyboardButtonProps={ {
                  'aria-label': 'change date'
                } }
                helperText={ props.errors[5] ? props.errorText[5] : null || props.errors[8] ? props.errorText[8] : null || props.errors[6] ? props.errorText[6] : null || props.errors[14] ? props.errorText[14] : null }
                error={ props.errors[4] ? props.errorText[4] : null || props.errors[8] ? props.errorText[8] : null || props.errors[6] ? props.errorText[6] : null || props.errors[14] ? props.errorText[14] : null }
              />
            </div>
          </MuiPickersUtilsProvider> }
          <NativeDropDown
            className='override-m-1'
            id="payeeStatus"
              label="Payee Status"
            value={paymentStatus !== undefined ? props.values.payeeStatus : DEFAULT_DD_VALUE}
            onChange={props.handleChange('payeeStatus')}
              title={getLongDescription(props.values.payeePaymentStatusFrmState, props.values.payeeStatus)}
            dropdownList={props.values.payeePaymentStatusFrmState
                ? props.values.payeePaymentStatusFrmState.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.description}
                  </option>
                ))
                : null}
          />
                    <div className="mui-custom-form input-md override-m-1">
            <TextField
              id='ATRnumber'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              value={props.values.atrNumber}
              onChange={props.handleChange('atrNumber')}
              inputProps={{ maxlength: 18 }}
              InputLabelProps={{
                shrink: true,
              }}
              helperText={props.errors[17] ? props.errorText[17] : null}
              error={props.errors[17] ? props.errorText[17] : null}
            ></TextField>
          </div>
        </div>
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-5" style={ { maxWidth: '398px' } }>
              <fieldset className="custom-fieldset">
                <legend>Account:</legend>
                <div className="form-wrapper vertical-wrapper">
                  <div className="mui-custom-form min-md">
                    <TextField
                      required
                      fullWidth
                      id="standard-GrossFiscalYear"
                      label="Fiscal Year"
                      placeholder=""
                      InputProps={ { readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : (parseFloat(props.values.balanceAmount) <= 0 || props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount), className: !userInquiryPrivileges ? !userInquiryPrivileges : (parseFloat(props.values.balanceAmount) <= 0 || props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount) ? 'Mui-disabled' : '' } }
                      inputProps={ { maxlength: 11 } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      type="number"
                      value={ props.values.fiscalYear }
                      onChange={ props.handleChange('fiscalYear') }
                      helperText={ props.errors[1] ? props.errorText[1] : null }
                      error={ props.errors[1] ? props.errorText[1] : null }
                      onInput={ (e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                      } }
                      onKeyDown={ (evt) => evt.key === 'e' && evt.preventDefault() }
                    >
                    </TextField>
                  </div>
                  <NativeDropDown
                    className='min-md'
                      id="standard-GrossCategoryofService"
                      label="Category of Service"
                      InputProps={ { readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : (props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount), className: !userInquiryPrivileges ? !userInquiryPrivileges : (props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount) ? 'Mui-disabled' : '' } }
                    value={cosData !== undefined ? props.values.categoryOfService : DEFAULT_DD_VALUE}
                    onChange={props.handleChange('categoryOfService')}
                    inputProps={{ maxlength: 3 }}
                      title={ getLongDescription(props.values.categoryOfServiceData, props.values.categoryOfService) }
                    dropdownList={props.values.categoryOfServiceData.map((option, index) => (
                      <option key={index} value={option.code}>
                          { option.description }
                      </option>
                      )) }
                  />
                  <NativeDropDown
                    className='min-md'
                    isRequired
                      id="standard-GrossFundCode"
                      label="Fund Code"
                    InputProps={{ readOnly: !userInquiryPrivileges ? !userInquiryPrivileges : (props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount), className: !userInquiryPrivileges ? !userInquiryPrivileges : (props.amountObj.tempBalanceAmount !== props.amountObj.tempOriginalARAmount) ? 'Mui-disabled' : '' }}
                      value={props.values.collocationCode}
                    onChange={props.handleChange('collocationCode')}
                      inputProps={ { maxlength: 10 } }
                    errTxt={props.errors[2] ? props.errorText[2] : null}
                      title={ getLongDescription(props.values.collocationCodeData, props.values.collocationCode) }
                    dropdownList={props.values.collocationCodeData.map((option, index) => (
                      <option key={index} value={option.code}>
                          { option.description }
                      </option>
                      )) }

                  />
                </div>
              </fieldset>
            </div>
            <div className="col-7" style={ { paddingLeft: '50px' } }>
              <fieldset className="custom-fieldset">
                <legend>Recoupment:</legend>
                <div className="form-wrapper">
                  <NativeDropDown
                    className='min-md'
                    isRequired={props.values.underAppeal === 'N'}
                      id="standard-GrossRecoupmentTypeCode"
                      label="Recoupment Type Code"
                      value={props.values.recoupmentTypeCode}
                    onChange={props.handleChange('recoupmentTypeCode')}
                      inputProps={ { maxlength: 1 } }
                    errTxt={props.errors[3] ? props.errorText[3] : null}
                      disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : (props.values.underAppeal === 'Y' || parseFloat(props.values.balanceAmount) <= 0) }
                    dropdownList={props.values.recoupmentData.map((option, index) => (
                      <option key={index} value={option.code}>
                          { option.description }
                      </option>
                      )) }

                  />
                  <div className="mui-custom-form  min-md">
                    <TextField
                      required={ props.values.recoupmentTypeCode === 'A' }
                      fullWidth

                      id="standard-GrossInstallmentValue"
                      label="Installment Value"
                      placeholder=""
                      inputProps={ { maxlength: 14 } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      value={ props.values.installmentValue }
                      onChange={ props.handleDecimalValues('installmentValue') }                      
                      disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : (props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S' || parseFloat(props.values.balanceAmount) <= 0) }
                      helperText={ props.errors[10] ? props.errorText[10] : null }
                      error={ props.errors[10] ? props.errorText[10] : null }
                      onInput={ e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                          ''
                        );
                      } }
                    >
                    </TextField>
                  </div>
                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form  min-md">
                    <TextField
                      id="standard-GrossPercentValue"
                      fullWidth

                      required={ props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'D'}
                      label="Percent Value"
                      placeholder=""
                      inputProps={ { maxlength: 14 } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      InputProps={ {
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                      } }
                      value={ props.values.percentValue }
                      onChange={ props.handleChange('percentValue') }
                      onBlur={ props.formatPercent('percentValue') }
                      disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : ((props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S') || parseFloat(props.values.balanceAmount) <= 0) }
                      helperText={ props.errors[9] ? props.errorText[9] : null }
                      error={ props.errors[9] ? props.errorText[9] : null }
                      onInput={ e => {
                        e.target.value = e.target.value.replace(
                          /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                          ''
                        );
                      } }
                    >

                    </TextField>
                  </div>
                  { <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                    <div className="mui-custom-form with-date min-md">
                      <KeyboardDatePicker
                        maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                        id="date-picker-dialogGrossStartDate"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        placeholder="mm/dd/yyyy"
                        minDate={ new Date() }
                        value={ props.selectedRecoupmentDate }
                        onChange={ props.handleRecoupmentDateChange }
                        InputLabelProps={ {
                          shrink: true
                        } }
                        KeyboardButtonProps={ {
                          'aria-label': 'change date'
                        } }
                        disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : (props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S' || parseFloat(props.values.balanceAmount) <= 0) }
                        helperText={ props.errors[11] ? props.errorText[11] : null || props.errors[12] ? props.errorText[12] : null || props.errors[15] ? props.errors[15] : null }
                        error={ props.errors[11] ? props.errorText[11] : null || props.errors[12] ? props.errorText[12] : null || props.errors[15] ? props.errors[15] : null }
                        required={ props.values.recoupmentTypeCode === 'B' || props.values.recoupmentTypeCode === 'A' || props.values.recoupmentTypeCode === 'D' }
                      />
                    </div>
                  </MuiPickersUtilsProvider> }
                </div>
                <div className="form-wrapper">
                  <NativeDropDown
                    className='min-md'
                      id="standard-GrossFrequency"
                      label="Frequency"
                    value={frequencyData !== undefined ? props.values.frequency : DEFAULT_DD_VALUE}
                    onChange={props.handleChange('frequency')}
                    inputProps={{ maxlength: 2 }}
                      disabled={ !userInquiryPrivileges ? !userInquiryPrivileges : (props.values.underAppeal === 'Y' || props.values.recoupmentTypeCode === 'S' || parseFloat(props.values.balanceAmount) <= 0) }
                    errTxt={props.errors[16] ? props.errorText[16] : null}
                    dropdownList={props.frequencyDataDropdown.map((option, index) => (
                      <option key={index} value={option.code}>
                          { option.description }
                      </option>
                      )) }
                  />
                  <div className="form-wrapper">
                    <div className="mui-custom-form with-select min-md override-width-61" style={ { alignSelf: 'flex-end' } }>
                      { userInquiryPrivileges ? <a onClick={ props.defaultRecoupment } className="has-link" style={ { color: 'blue', cursor: 'pointer' } }>
                        Use Payee Default Recoupment
                      </a> : <span>Use Payee Default Recoupment</span> }
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <>
          <div className="col-12" style={ { paddingRight: '22px' } }>
            <fieldset className="custom-fieldset">
              <legend>Funding Source Allocation:</legend>
              <div className="form-wrapper">

                <div className="mui-custom-form">
                  <TextField
                    id="standard-federal"
                    fullWidth
                    label="Federal"
                    placeholder=""
                    value={ props.values.federal }
                    InputProps={ {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      readOnly: true,
                      className: 'Mui-disabled'
                    } }
                    inputProps={ { maxlength: 14 } }
                    InputLabelProps={ {
                      shrink: true
                    } }
                  >
                  </TextField>
                </div>
                <div className="mui-custom-form">
                  <TextField
                    id="standard-state"
                    fullWidth
                    label="State"
                    placeholder=""
                    value={ props.values.state }
                    InputProps={ {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      readOnly: true,
                      className: 'Mui-disabled'
                    } }
                    inputProps={ { maxlength: 14 } }
                    InputLabelProps={ {
                      shrink: true
                    } }
                  >
                  </TextField>
                </div>
                <div className="mui-custom-form">
                  <TextField
                    id="standard-county"
                    fullWidth
                    label="County"
                    placeholder=""
                    value={ props.values.county }
                    InputProps={ {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      readOnly: true,
                      className: 'Mui-disabled'
                    } }
                    inputProps={ { maxlength: 14 } }
                    InputLabelProps={ {
                      shrink: true
                    } }
                  >
                  </TextField>
                </div>
                <div className="mui-custom-form">
                  <TextField
                    id="standard-other"
                    fullWidth
                    label="Other"
                    placeholder=""
                    value={ props.values.other }
                    InputProps={ {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      readOnly: true,
                      className: 'Mui-disabled'
                    } }
                    inputProps={ { maxlength: 14 } }
                    InputLabelProps={ {
                      shrink: true
                    } }
                  >
                  </TextField>
                </div>
              </div>
            </fieldset>
          </div>
        </>
      </form>
    </div>
  );
}
