/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import BudgetFundingSourceTable from './BudgetFundingSourceTable';
import BudgetAssociatedFundCodesTable from './BudgetAssociatedFundCodesTable';
import FundCodeHistoryTable from './FundCodeHistoryTable';
import FundingSourceHistoryTable from './FundingSourceHistoryTable';
import DateFnsUtils from '@date-io/date-fns';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as BudgetMaintenanceConstants from '../BudgetMaintenanceConstants';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import {
  resetSearchBudgetMaintenance,
  budgetMaintenanceUpdateSaveAPI,
  searchBudgetMaintenanceIDAction,
  getDropdownDataAction,
  getSubDropdownDataAction,
  budgetEndDateAPI,
  getAuditLogDetailsAction,
  getAuditLogRowDetailsAction
} from '../../Store/Actions/budgetMaintenance/budgetMaintenanceActions';
import {
  validateDateMinimumValue,
  setSelectedDate,
  getDifferenceInDays,
  overlapCheckWithDateRange,
  getDateInMMDDYYYYFormatWithApendZero,
  generateUUID,
  getUTCTimeStamp,
  compareTwoDatesGreaterThanOrEqual,
  handelDateRangeGap,
  handelDateRangeGapHeaderCI
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import { withRouter } from 'react-router';

import Spinner from '../../../../SharedModules/Spinner/Spinner';
import { Prompt } from 'react-router-dom';
import numeral from 'numeral';
import {
  DialogTitle,
  DialogContent,
  DialogActions
} from '../../../../SharedModules/Dialog/DialogUtilities';
import DialogContentText from '@material-ui/core/DialogContentText';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import NoSaveMessage from '../../../../SharedModules/Errors/NoSaveMessage';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import dropdownCriteria from '../FinancialBudgetAddEdit.json';
import subDropdownCriteria from '../FinancialBudgetSub.json';
import Notes from '../../../../SharedModules/Notes/Notes';
import moment from 'moment';
import Footer from '../../../../SharedModules/Layout/footer';
import NavHOC from '../../../../SharedModules/Navigation/NavHOC';
import Radio from '@material-ui/core/Radio';
import { checkingDecimal, getLongDescription, removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

let errorMessagesArray = [];
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: 'left'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
}));

function BudgetMaintenanceUpdate (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [fundCodeBeginDate, setFundCodeBeginDate] = React.useState(null);
  const [fundCodeEndDate, setFundCodeEndDate] = React.useState(null);
  const [endDateDefault, setEndDateDefault] = React.useState('');
  
  const toPrintRef = useRef();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);
  const [initalFocusCheck, setInitalFocusCheck] = React.useState(false);
  const EndDateFromDB = useSelector(
    state => state.budgetMaintenanceState.budgetEndDateInfo
  );
  const classes = useStyles();
  const [
    {
      showStateFiscalYearError,
      showBudgetDescError,
      showAgencyError,
      showGLCompanyError,
      showGLAccountingUnitError,
      showGLAccountError,
      showACAccountCategoryError,
      showTotalPercentageError,
      showFundCodeError,
      showBeginDateError,
      showEndDateError,
      showBudgetAmountError,
      showBudgetLessThanZero,
      showACActivityError,
      showBudgetAmountFormatError,
      showFederalError,
      showStateError,
      showCountyError,
      showOtherError,
      showFederalFormat,
      showStateFormat,
      showCountyFormat,
      showOtherFormat,
      showFSourceBeginDateError,
      showFSourceEndDateError
    },
    setShowError
  ] = React.useState(false);
  const [
    { beginDateerrorText, endDateerrorText },
    setShowErrorText
  ] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  useEffect(() => {
    rowDeleteFCode(selected);
  }, [selected]);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  useEffect(() => {
    if (EndDateFromDB) {
      if (EndDateFromDB.systemFailure) {
        const errorArray = [];
        errorArray.push(BudgetMaintenanceConstants.DATABASE_ERROR);
        seterrorMessages(errorArray);
      } else {
        if (EndDateFromDB.respcode === '01') {
          setEndDateDefault(EndDateFromDB.enterpriseBaseVO.fundCodeVO.endDate);
          setFundCodeEndDate(
            new Date(EndDateFromDB.enterpriseBaseVO.fundCodeVO.endDate)
          );
          seterrorMessages([]);
        }
        if (EndDateFromDB.respcode !== '01') {
          const errorArray = [];
          errorArray.push(BudgetMaintenanceConstants.DATABASE_ERROR);
          seterrorMessages(errorArray);
        }
      }
    }
  }, [EndDateFromDB]);

  const dispatch = useDispatch();

  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const [fiscalYearData, setFiscalYearData] = React.useState([]);
  const [agencyData, setAgencyData] = React.useState([]);
  const [glCompanyData, setGLCompanyData] = React.useState([]);
  const [unitData, setUnitData] = React.useState([]);
  const [glAccountData, setGLAccountData] = React.useState([]);
  const [glSubAccountData, setGLSubAccountData] = React.useState([]);
  const [acAccountCategoryData, setACAccountCategoryData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  const [cosData, setCosData] = React.useState([]);
  const [acActivityData, setACActivityData] = React.useState([]);
  const [selectedFSourceRow, setSelectedFSourceRow] = React.useState(null);
  const [selectedFundCodeRow, setSelectedFundCodeRow] = React.useState(null);
  const [rowFSourceData, setrowFSourceData] = React.useState([{}]);
  const [rowFCodeeData, setrowFCodeData] = React.useState([{}]);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [searchData, setsearchData] = React.useState(
    props.location.state && props.location.state.payloadData
      ? props.location.state.payloadData
      : props.location.state && props.location.state.payloadDataRowData
        ? props.location.state.payloadDataRowData
        : props.location.state && props.location.state.addSuccessData
          ? props.location.state.addSuccessData
          : []
  );
  // Audit Log Implementation starts
  // #region
  const [showLogTable, setShowLogTable] = React.useState(false);

  const onClickAuditLog = () => {
    const tableName = 'f_bdgt_tb';
    const keyValue = {
      f_bdgt_sk: searchData.budgetID

    };
    dispatch(getAuditLogDetailsAction(tableName, keyValue, 'auditLogData'));
  };
  useEffect(() => {
    if (showLogTable && document.getElementById('audit-table')) { document.getElementById('audit-table').scrollIntoView(); }
  }, [showLogTable]);
  const auditLogData = useSelector(state => (state.budgetMaintenanceState.auditDetails));
  const [auditRowData, setAuditRowData] = React.useState([]);
  const filterAuditTable = (rowData) => {
    return (rowData.afterValue || rowData.beforeValue);
  };
  useEffect(() => {
    if (auditLogData) {
      setAuditRowData(auditLogData.filter(filterAuditTable));
    }
  }, [auditLogData]);

  function onlyUniqueErrorMessage (value, index, self) {
    return self.indexOf(value) === index;
  }

  const [showLogDialogTable, setShowLogDialogTable] = React.useState(false);
  const auditLogTableData = useSelector(state => (state.budgetMaintenanceState.auditRowDetails));
  const [auditTableData, setAuditTableData] = React.useState([]);
  const [close, setClose] = React.useState(true);

  const onClickAuditDialogLog = (row) => {
    const auditReq = [
      {
        tableName: 'f_bdgt_fund_tb',
        keyValue: { f_bdgt_sk: searchData.budgetID, f_bdgt_fund_seq_num: row.sequenceNumber }
      },
      {
        tableName: 'f_bdgt_fund_src_tb',
        keyValue: { f_bdgt_sk: searchData.budgetID, f_bdgt_fund_seq_num: row.sequenceNumber, f_bdgt_fund_src_seq_num: row.fundSourceVOList[0].sequenceNumber },
        columnNames: 'f_fed_fund_pct,f_state_fund_pct,f_cnty_fund_pct,f_other_fund_pct'
      }
    ];

    dispatch(getAuditLogRowDetailsAction(auditReq, 'auditLogTableData'));
    setShowLogDialogTable(true);
  };
  useEffect(() => {
    if (auditLogTableData) {
      setAuditTableData(auditLogTableData.filter(filterAuditTable));
    }
  }, [auditLogTableData]);

  useEffect(() => {
    if (showLogDialogTable && document.getElementById('audit-dialog-table')) { document.getElementById('audit-dialog-table').scrollIntoView(); }
  }, [showLogDialogTable]);
  // #endregion
  // Audit Log Implementation ends

  // notes
  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []
  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});

  const onReset = () => dispatch(resetSearchBudgetMaintenance());

  let payload = [];
  useEffect(() => {
    onReset();
    setSpinnerLoader(true);
    getDropdownData(dropdownCriteria);
    getSubDropdownData(subDropdownCriteria);
    if (props.location.state && props.location.state.successMessage) {
      setSuccessMessage(props.location.state.successMessage);
    }
    dispatch(budgetEndDateAPI({ fiscalYear: searchData.fiscalYear }));
    onDropdowns([
      Dropdowns.FUND_CODE,
      Dropdowns.COS,
    ]);
  }, []);

  payload = useSelector(state => state.budgetMaintenanceState.payload);
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const getSubDropdownData = dropdownValues =>
    dispatch(getSubDropdownDataAction(dropdownValues));
  const dropdown = useSelector(
    state => state.budgetMaintenanceState.dropdownData
  );
  const subdropdown = useSelector(
    state => state.budgetMaintenanceState.subdropdownData
  );

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      setSpinnerLoader(false);
      if (dropdown.listObj['Financial#1021']) {
        setFiscalYearData(dropdown.listObj['Financial#1021'].sort((a, b) => a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? 1 : -1));
      }
      if (dropdown.listObj['Financial#1125']) {
        setAgencyData(dropdown.listObj['Financial#1125']);
      }
      if (dropdown.listObj['Financial#1124']) {
        setGLCompanyData(dropdown.listObj['Financial#1124']);
      }
      if (dropdown.listObj['Financial#1016']) {
        setUnitData(dropdown.listObj['Financial#1016']);
      }
      if (dropdown.listObj['Financial#1015']) {
        setGLAccountData(dropdown.listObj['Financial#1015']);
      }
      if (dropdown.listObj['Financial#1130']) {
        setACAccountCategoryData(dropdown.listObj['Financial#1130']);
      }
      if (dropdown.listObj['Claims#1020']) {
        setCosData(dropdown.listObj['Claims#1020']);
      }
      if (dropdown.listObj['Reference#1042']) {
        setFundCodeData(dropdown.listObj['Reference#1042']);
      }
      if (dropdown.listObj['Financial#1129']) {
        setACActivityData(dropdown.listObj['Financial#1129']);
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (subdropdown && subdropdown.listObj) {
      if (subdropdown.listObj['Financial#F_SUB_OBJ_CD']) {
        setGLSubAccountData(subdropdown.listObj['Financial#F_SUB_OBJ_CD']);
      }
    }
  }, [subdropdown]);

  const budgetMaintenanceUpdateSave = saveObj =>
    dispatch(budgetMaintenanceUpdateSaveAPI(removeLeadingTrailingSpaces(saveObj)));
  const budgetMaintenanceUpdateResponse = useSelector(
    state => state.budgetMaintenanceState.BudgetMaintenanceUpdateSaveResponse
  );

  const getUpdatedBudgetResult = budgetID =>
    dispatch(searchBudgetMaintenanceIDAction(budgetID));
  const getUpdatedBudgetResponse = useSelector(
    state => state.budgetMaintenanceState.searchBudgetID
  );

  useEffect(() => {
    if (
      budgetMaintenanceUpdateResponse &&
      budgetMaintenanceUpdateResponse.respcode &&
      budgetMaintenanceUpdateResponse.respcode === '01'
    ) {
             setAllowNavigation(false);
      const successArray = [];
      setSpinnerLoader(false);
      successArray.push(budgetMaintenanceUpdateResponse.respdesc ? budgetMaintenanceUpdateResponse.respdesc : AppConstants.SAVE_SUCCESS);
      setSuccessMessage(successArray);
      setFocusCheck(false);
      getUpdatedBudgetResult(data.budgetID);
    } else if (
      budgetMaintenanceUpdateResponse &&
      budgetMaintenanceUpdateResponse.respcode &&
      budgetMaintenanceUpdateResponse.respcode !== '' &&
      budgetMaintenanceUpdateResponse.respcode === '03'
    ) {
      const errorArray = [];
      setSpinnerLoader(false);
      if (budgetMaintenanceUpdateResponse.errorMessages && budgetMaintenanceUpdateResponse.errorMessages !== null) {
        errorArray.push(budgetMaintenanceUpdateResponse.errorMessages[0]);
        seterrorMessages(budgetMaintenanceUpdateResponse.errorMessages);
        setSuccessMessage([]);
      } else {
        if (budgetMaintenanceUpdateResponse.respdesc && budgetMaintenanceUpdateResponse.respdesc !== null) {
          errorArray.push(budgetMaintenanceUpdateResponse.respdesc);
          seterrorMessages(errorArray);
          setSuccessMessage([]);
        }
      }
    } else if (
      budgetMaintenanceUpdateResponse &&
      budgetMaintenanceUpdateResponse.systemFailure
    ) {
      const errorArray = [];
      setSpinnerLoader(false);
      errorArray.push(BudgetMaintenanceConstants.DATABASE_ERROR);
      setSuccessMessage([]);
      seterrorMessages(errorArray);
    } else if (
      budgetMaintenanceUpdateResponse &&
      budgetMaintenanceUpdateResponse.respcode &&
      budgetMaintenanceUpdateResponse.respcode !== '' &&
      budgetMaintenanceUpdateResponse.respcode === '02'
    ) {
      const errorArray = [];
      setSpinnerLoader(false);
      if (budgetMaintenanceUpdateResponse.errorMessages && budgetMaintenanceUpdateResponse.errorMessages !== null) {
        errorArray.push(budgetMaintenanceUpdateResponse.errorMessages[0]);
        seterrorMessages(budgetMaintenanceUpdateResponse.errorMessages);
        setSuccessMessage([]);
      } else {
        if (budgetMaintenanceUpdateResponse.respdesc && budgetMaintenanceUpdateResponse.respdesc !== null) {
          errorArray.push(budgetMaintenanceUpdateResponse.respdesc);
          seterrorMessages(errorArray);
          setSuccessMessage([]);
        }
      }
    }
  }, [budgetMaintenanceUpdateResponse]);
  useEffect(() => {
    if (getUpdatedBudgetResponse && getUpdatedBudgetResponse !== '') {
      setSpinnerLoader(false);
      setsearchData(getUpdatedBudgetResponse);
      getUpdatedBudgetResponse.voidIndicator = getUpdatedBudgetResponse.voidIndicator
        ? getUpdatedBudgetResponse.voidIndicator
        : false;
      setData(getUpdatedBudgetResponse);
      setnewFundData(getUpdatedBudgetResponse.fundCodeVOList);
      setshowResults(false);
    }
  }, [getUpdatedBudgetResponse]);

  const openEditModal = row => event => {
    errorMessagesArray = [];
    setAdjustAmtError('');
    setSourceErrors([]);
    seterrorMessages([]);
    if (row.cos === null) {
      setValues({ ...values, cos: DEFAULT_DD_VALUE });
    }
    setSelectedFundCodeRow(JSON.parse(JSON.stringify(row)));
    setVoids({
      ...voids,
      codeVoidIndicator: row.voidIndicator ? row.voidIndicator : false
    });
    setcodeHistoryData(row.fundCodeHistoryList ? row.fundCodeHistoryList : []);
    setsourceHistoryData(
      row.fundSourceHistoryList ? row.fundSourceHistoryList : []
    );
    handleClickOpen(row);
    setAuditLogRow(row);
  };

  // Add fund code begin
  const [showResults, setshowResults] = React.useState(false);
  const handleFundCodeBeginDate = date => {
    setFocusCheck(true);
    setFundCodeBeginDate(date);
  };

  const handleFundCodeEndDate = date => {
    setFocusCheck(true);
         setAllowNavigation(true);
    setFundCodeEndDate(date);
  };

  const handleStartDateChange = date => {
    setFocusCheck(true);
         setAllowNavigation(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = date => {
    setFocusCheck(true);
         setAllowNavigation(true);
    setSelectedEndDate(date);
  };

  const [data, setData] = React.useState({
    auditUserID: searchData.auditUserID,
    auditTimeStamp: new Date(searchData.auditTimeStamp),
    addedAuditUserID: searchData.addedAuditUserID,
    addedAuditTimeStamp: new Date(searchData.addedAuditTimeStamp),
    versionNo: searchData.versionNo,
    dbRecord: searchData.dbRecord,
    sortColumn: searchData.sortColumn,
    auditKeyList: searchData.auditKeyList,
    auditKeyListFiltered: searchData.auditKeyListFiltered,
    budgetID: searchData.budgetID,
    voidIndicator: searchData.voidIndicator ? searchData.voidIndicator : false,
    lob: searchData.lob,
    noteSet: searchData.noteSet,
    fundCodeVOList: searchData.fundCodeVOList,
    fundCodeVO: searchData.fundCodeVO,
    accountCategoryCode: searchData.accountCategoryCode,
    fiscalYear: searchData.fiscalYear,
    agencyCode: searchData.agencyCode,
    activityCode: searchData.activityCode,
    organizationCode: searchData.organizationCode,
    subOrganizationCode: searchData.subOrganizationCode,
    objectCode: searchData.objectCode,
    subObjectCode: searchData.subObjectCode,
    programCode: searchData.programCode,
    classCode: searchData.classCode,
    glCompanyCode: searchData.glCompanyCode,
    budgetDesc: searchData.budgetDesc,
    funcCode: searchData.funcCode,
    voidDate:
      searchData.voidDate != null ? new Date(searchData.voidDate) : null,
    voidValue: searchData.voidValue,
    voidProtect: searchData.voidProtect,
    componentCode: searchData.componentCode,
    subAppropriationSequenceNumber: searchData.subAppropriationSequenceNumber,
    subAppropriationCode: searchData.subAppropriationCode,
    subAppropriationBeginDate: searchData.subAppropriationBeginDate,
    subAppropriationEndDate: searchData.subAppropriationEndDate,
    subAppropriationOriginalBudgetAmount:
      searchData.subAppropriationOriginalBudgetAmount,
    subAppropriationAdjustedAuthorzedAmount:
      searchData.subAppropriationAdjustedAuthorzedAmount,
    subAppropriationAuthorized: searchData.subAppropriationAuthorized,
    subAppropriationExpenditures: searchData.subAppropriationExpenditures,
    subAppropriationVoidIndicator: searchData.subAppropriationVoidIndicator
  });
  const [voids, setVoids] = React.useState({
    sourceVoidIndicator: false,
    codeVoidIndicator: false
  });
  const [values, setValues] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    voidIndicator: false,
    lob: null,
    fundSourceHistoryList: [],
    fundCodeHistoryList: [],
    beginDate: null,
    endDate: null,
    fundCode: '',
    budgetAmount: '0.00',
    adjustedAuthorizedAmount: '0.00',
    expenditureAmount: '0.00',
    tempExpAmount: '0.00',
    cos: null,
    cmsTos: null,
    jobNumber: null,
    inKindIndicator: 'N',
    federal: '0.00',
    state: '0.00',
    county: '0.00',
    other: '0.00',
    sequenceNumber: null,
    fundVoidDate: null,
    voidValue: 'No',
    fundCodeVoidProtect: false,
    benefitPlanID: null,
    oldFundCode: null,
    oldBeginDate: null,
    oldEndDate: null,
    voSeqNum: 0,
    fundSourceVOList: []
  });

  const [value, setValue] = React.useState(0);
  const [historyvalue, setHistoryValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [openAddFundCode, setOpenFuncd] = React.useState(false);

  const [dataElement, setDataElement] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    auditKeyList: [],
    auditKeyListFiltered: false,
    voidIndicator: false,
    activeInd: false,
    dateDisable: false,
    beginDate: null,
    fundVO: null,
    endDate: null,
    federalPercentage: '',
    federal: null,
    statePercentage: '',
    countyPercentage: '',
    otherPercentage: '',
    totalPercentage: 0,
    sequenceNumber: null,
    fundSourceVoidDate: null,
    voidValue: 'No',
    fundSourceVoidProtect: false,
    pastDateDisable: false
  });

  const handleChangeMain = name => event => {
    setFocusCheck(true);
         setAllowNavigation(true);
    setInitalFocusCheck(true);
    setData({ ...data, [name]: event.target.value, auditUserID: logInUserID });
    if (name === 'fiscalYear') {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        dispatch(budgetEndDateAPI({ fiscalYear: event.target.value }));
      }
      setFundCodeEndDate(new Date(endDateDefault));
      setSelectedEndDate(new Date(endDateDefault));
    }
  };
  const handleChangeSourceVoid = () => {
    setFocusCheck(true);
         setAllowNavigation(true);
    setVoids({ ...voids, sourceVoidIndicator: !voids.sourceVoidIndicator });
  };
  const handleChangeCodeVoid = () => {
    setFocusCheck(true);
         setAllowNavigation(true);
    setVoids({ ...voids, codeVoidIndicator: !voids.codeVoidIndicator });
  };
  const handleChangeMainVoid = () => {
    setFocusCheck(true);
         setAllowNavigation(true);
    setInitalFocusCheck(true);
    setData({ ...data, voidIndicator: !data.voidIndicator });
  };

  const duplicateFundCodeValidation = (type) => {
    let count = 0;
    for (var i = 0; i < newFundData.length; i++) {
      if (selectedFundCodeRow && newFundData[i] && newFundData[i].id !== selectedFundCodeRow.id) {
        if (
          (newFundData[i].cos === values.cos ||
            (newFundData[i].cos === null &&
              values.cos === DEFAULT_DD_VALUE)) &&
          newFundData[i].fundCode === values.fundCode &&
          (moment(fundCodeBeginDate).isBetween(newFundData[i].beginDate, newFundData[i].endDate) ||
            moment(newFundData[i].beginDate).isBetween(fundCodeBeginDate, fundCodeEndDate) ||
            moment(newFundData[i].endDate).isBetween(fundCodeBeginDate, fundCodeEndDate))
        ) {
          count++;
        }
        if (
          (newFundData[i].cos === values.cos ||
            (newFundData[i].cos === null &&
              values.cos === DEFAULT_DD_VALUE)) &&
          newFundData[i].fundCode === values.fundCode &&
          ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) &&
           (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)))
        ) {
          count++;
        }
        if (
          (!newFundData[i].voidIndicator) &&
          (newFundData[i].cos === values.cos ||
            (newFundData[i].cos === null &&
              values.cos === DEFAULT_DD_VALUE)) &&
          newFundData[i].fundCode === values.fundCode &&
            (new Date(fundCodeBeginDate) <= new Date(newFundData[i].beginDate) &&
            ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) &&
            (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)) ||
            new Date(fundCodeEndDate) > new Date(newFundData[i].endDate)))
        ) {
          count++;
        }
      }
      else if(type === "add"){
          if (
            (newFundData[i].cos === values.cos ||
              (newFundData[i].cos === null &&
                values.cos === DEFAULT_DD_VALUE)) &&
            newFundData[i].fundCode === values.fundCode &&
            (moment(fundCodeBeginDate).isBetween(newFundData[i].beginDate, newFundData[i].endDate) ||
              moment(newFundData[i].beginDate).isBetween(fundCodeBeginDate, fundCodeEndDate) ||
              moment(newFundData[i].endDate).isBetween(fundCodeBeginDate, fundCodeEndDate))
          ) {
            count++;
          }
          if (
            (newFundData[i].cos === values.cos ||
              (newFundData[i].cos === null &&
                values.cos === DEFAULT_DD_VALUE)) &&
            newFundData[i].fundCode === values.fundCode &&
            ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) &&
             (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)))
          ) {
            count++;
          }
          if (
            (!newFundData[i].voidIndicator) &&
            (newFundData[i].cos === values.cos ||
              (newFundData[i].cos === null &&
                values.cos === DEFAULT_DD_VALUE)) &&
            newFundData[i].fundCode === values.fundCode &&
              (new Date(fundCodeBeginDate) <= new Date(newFundData[i].beginDate) &&
              ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) &&
              (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)) ||
              new Date(fundCodeEndDate) > new Date(newFundData[i].endDate)))
          ) {
            count++;
          }
        
      }
    }
    if (count > 0 ) {
  errorMessagesArray.push(BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION);
      seterrorMessages(errorMessagesArray);
    }
  };

  const handleFundingSourceOpen = () => {
    errorMessagesArray = [];
    if (validateFundCode(false)) {
      setOpen(true);
      setDataElement({
        selectedStartDate: '',
        selectedEndDate: '',
        federal: '',
        state: '',
        county: '',
        other: ''
      });
      seterrorMessages([]);
      setSelectedStartDate(null);
      setSelectedEndDate(new Date(endDateDefault));
    }
  };

  const [newFundData, setnewFundData] = React.useState(
    searchData.fundCodeVOList ? searchData.fundCodeVOList : []
  );
  const [codeHistoryData, setcodeHistoryData] = React.useState([]);
  const [sourceHistoryData, setsourceHistoryData] = React.useState([]);
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);

  const validateFundCode = (add,type) => {
    var beginDateerrorText = '';
    var endDateerrorText = '';
    var showBeginDateError = false;
    var showEndDateError = false;
    var showFundCodeError = false;
    var showBudgetAmountError = false;
    var showBudgetLessThanZero = false;
    var showACActivityError = false;
    var showBudgetAmountFormatError = false;
    var showDateOverlappingError = false;
    var showFundingSourceEmptyError = false;
    if (
      fundCodeBeginDate &&
      fundCodeEndDate &&
      new Date(fundCodeEndDate).getTime() <
      new Date(fundCodeBeginDate).getTime()
    ) {
      showBeginDateError = true;
      showEndDateError = true;
      beginDateerrorText =
        BudgetMaintenanceConstants.BEGINDATE_ENDDATE_MATCH_FUND_CODE;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.BEGINDATE_ENDDATE_MATCH_FUND_CODE
      );
      seterrorMessages(errorMessagesArray);
    }

    if (!fundCodeBeginDate || fundCodeBeginDate === '') {
      showBeginDateError = true;
      beginDateerrorText = BudgetMaintenanceConstants.BEGINDATE_ERROR;
      errorMessagesArray.push(BudgetMaintenanceConstants.BEGINDATE_ERROR);
    }
    if (fundCodeBeginDate && fundCodeBeginDate.toString() === 'Invalid Date') {
      showBeginDateError = true;
      beginDateerrorText =
        BudgetMaintenanceConstants.FCODE_BEGINDATE_INVALID_ERROR;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.FCODE_BEGINDATE_INVALID_ERROR
      );
    }
    if (fundCodeBeginDate && fundCodeBeginDate.toString() !== 'Invalid Date') {
      let isDateValid = false;
      isDateValid = validateDateMinimumValue(moment(fundCodeBeginDate).format('MM/DD/YYYY'));
      if (isDateValid) {
        showBeginDateError = true;
        beginDateerrorText = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      } else if (compareTwoDatesGreaterThanOrEqual(moment(new Date(fundCodeBeginDate)).format('MM/DD/YYYY'), moment(new Date()).format('MM/DD/YYYY'))) {
        if (!addUpdateFundCode) {
          showBeginDateError = true;
          beginDateerrorText =
          BudgetMaintenanceConstants.FCODE_BEGINDATE_IS_PASTDATE;
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FCODE_BEGINDATE_IS_PASTDATE
          );
          seterrorMessages(errorMessagesArray);
        }
      }
    }

    if (!fundCodeEndDate || fundCodeEndDate === '') {
      showEndDateError = true;
      endDateerrorText = BudgetMaintenanceConstants.ENDDATE_ERROR;
      errorMessagesArray.push(BudgetMaintenanceConstants.ENDDATE_ERROR);
    }
    if (fundCodeEndDate && fundCodeEndDate.toString() === 'Invalid Date') {
      showEndDateError = true;
      endDateerrorText = BudgetMaintenanceConstants.FCODE_ENDDATE_INVALID_ERROR;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.FCODE_ENDDATE_INVALID_ERROR
      );
    }
    if (fundCodeEndDate && fundCodeEndDate.toString() !== 'Invalid Date') {
      let isDateValid = false;
      isDateValid = validateDateMinimumValue(moment(fundCodeEndDate).format('MM/DD/YYYY'));
      if (isDateValid) {
        showEndDateError = true;
        endDateerrorText = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    }
    if (!values.fundCode || values.fundCode === DEFAULT_DD_VALUE) {
      showFundCodeError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_FUND_CODE_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (AdjustAmtError !== '') {
      errorMessagesArray.push(AdjustAmtError);
    } else {
      if (parseFloat(values.adjustedAuthorizedAmount) < 0) {
        errorMessagesArray.push(BudgetMaintenanceConstants.ADJUST_LESS);
        setAdjustAmtError(BudgetMaintenanceConstants.ADJUST_LESS);
      }
    }
    if (!values.id && (!values.budgetAmount || values.budgetAmount === ' ')) {
      showBudgetAmountError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_BUDGET_AMOUNT_ERROR
      );
      seterrorMessages(errorMessagesArray);
    }
    if (parseFloat(values.budgetAmount) < 0) {
      showBudgetLessThanZero = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.BUDGET_LESS);
      seterrorMessages(errorMessagesArray);
    }
    if (values.id && errorMessagesArray.length === 0) {
      for (var i = 0; i < newFundData.length; i++) {
        if (
          (!newFundData[i].voidIndicator) &&
          newFundData[i].cos === values.cos &&
          newFundData[i].fundCode === values.fundCode &&
          newFundData[i].id !== values.id && (
            ((new Date(newFundData[i].beginDate) <= new Date(fundCodeBeginDate)) && (new Date(fundCodeBeginDate) <= new Date(newFundData[i].endDate)))
          )
        ) {
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION
          );
          seterrorMessages(errorMessagesArray);
        } else if (
          (!newFundData[i].voidIndicator) &&
          newFundData[i].cos === values.cos &&
          newFundData[i].fundCode === values.fundCode &&
          newFundData[i].id !== values.id && (
            ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) && (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)))
          )
        ) {
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION
          );
          seterrorMessages(errorMessagesArray);
        } else if (
          (!newFundData[i].voidIndicator) &&
          (newFundData[i].cos === values.cos ||
            (newFundData[i].cos === null &&
              values.cos === DEFAULT_DD_VALUE)) &&
              newFundData[i].id !== values.id &&
          newFundData[i].fundCode === values.fundCode && (
            (new Date(fundCodeBeginDate) <= new Date(newFundData[i].beginDate) && ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) && (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)) || new Date(fundCodeEndDate) > new Date(newFundData[i].endDate)))
          )
        ) {
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION
          );
          seterrorMessages(errorMessagesArray);
        }
      }
    }
    if (add && newDialogData.length <= 0) {
      showFundingSourceEmptyError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_FUNDING_SOURCE_EMPTY_ERROR
      );
      seterrorMessages(errorMessagesArray);
    }
    // Dates Coverage
    if (add && newDialogData.length > 0) {
      const daysGapChecker = handelDateRangeGapHeaderCI(fundCodeBeginDate, fundCodeEndDate, newDialogData, sourceHistoryData) ||
       handelDateRangeGap(newDialogData);
      if (daysGapChecker) {
        showDateOverlappingError = true;
        errorMessagesArray.push(
          BudgetMaintenanceConstants.FUND_CODE_COVERAGE_ERROR
        );
        seterrorMessages(errorMessagesArray);
      }
    }
    duplicateFundCodeValidation(type);
    setShowError({
      showBeginDateError: showBeginDateError,
      showEndDateError: showEndDateError,
      showDateOverlappingError: showDateOverlappingError,
      showFundCodeError: showFundCodeError,
      showBudgetAmountError: showBudgetAmountError,
      showBudgetLessThanZero: showBudgetLessThanZero,
      showBudgetAmountFormatError: showBudgetAmountFormatError,
      showFundingSourceEmptyError: showFundingSourceEmptyError,
      showACActivityError: showACActivityError
    });
    setShowErrorText({
      beginDateerrorText: beginDateerrorText,
      endDateerrorText: endDateerrorText
    });

    if (errorMessagesArray.length === 0) {
      seterrorMessages([]);
      return true;
    } else {
      seterrorMessages(errorMessagesArray.filter(onlyUniqueErrorMessage));
      return false;
    }
  };

  const addFundCode = () => {
    errorMessagesArray = [];
    var updatedBeginDate = null;
    var updatedEndDate = null;
    if (open && !validateFundSourceCode()) {
      return false;
    }
    if (selectedFundCodeRow) {
      if (validateFundCode(true,"edit")) {
        setOpenFuncd(false);
        let recordCount = 0;
        for (var i = 0; i < newDialogData.length; i++) {
          if (
            newDialogData[i].voidIndicator === false ||
            newDialogData[i].voidIndicator === undefined ||
            voids.codeVoidIndicator === true
          ) {
            recordCount++;
          }
        }
        if (recordCount === 0) {
          errorMessagesArray.push(
            BudgetMaintenanceConstants.ADD_FUNDING_SOURCE_EMPTY_ERROR
          );
          seterrorMessages(errorMessagesArray);
        }
        if (errorMessagesArray.length === 0) {
          setSourceErrors([]);
          setShowError(false);
          updatedBeginDate = setSelectedDate(new Date(fundCodeBeginDate));
          updatedEndDate = setSelectedDate(new Date(fundCodeEndDate));
          if (newDialogData.length > 0) {
            let skipRow = -1;
            const tempNewFundData = [...newFundData];
            values.fundSourceVOList = [...newDialogData];
            for (let i = 0; i < tempNewFundData.length; i++) {
              if (
                selectedFundCodeRow &&
                selectedFundCodeRow.cos === tempNewFundData[i].cos
              ) {
                skipRow = i;
              }
            }
            let count = 0;
            for (let i = 0; i < newFundData.length; i++) {
              if (i !== skipRow) {
                if (
                  (!newFundData[i].voidIndicator) &&
                  (newFundData[i].cos === values.cos ||
                    (newFundData[i].cos === null &&
                      values.cos === DEFAULT_DD_VALUE)) &&
                  newFundData[i].fundCode === values.fundCode && (
                    ((new Date(newFundData[i].beginDate) <= new Date(fundCodeBeginDate)) && 
                    (new Date(fundCodeBeginDate) <= new Date(newFundData[i].endDate)))
                  )
                ) {
                  count++;
                }
                if (
                  (!newFundData[i].voidIndicator) &&
                  (newFundData[i].cos === values.cos ||
                    (newFundData[i].cos === null &&
                      values.cos === DEFAULT_DD_VALUE)) &&
                  newFundData[i].fundCode === values.fundCode && (
                    ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) && (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)))
                  )
                ) {
                  count++;
                }
                if (
                  (!newFundData[i].voidIndicator) &&
                  (newFundData[i].cos === values.cos ||
                    (newFundData[i].cos === null &&
                      values.cos === DEFAULT_DD_VALUE)) &&
                  newFundData[i].fundCode === values.fundCode && (
                    (new Date(fundCodeBeginDate) <= new Date(newFundData[i].beginDate) && ((new Date(fundCodeEndDate) >= new Date(newFundData[i].beginDate)) && (new Date(fundCodeEndDate) <= new Date(newFundData[i].endDate)) || new Date(fundCodeEndDate) > new Date(newFundData[i].endDate)))
                  )
                ) {
                  count++;
                }
              }
            }
            if (count > 0) {
              errorMessagesArray.push(
                BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION
              );
              seterrorMessages(errorMessagesArray);
            }
            if (errorMessagesArray.length === 0) {
              seterrorMessages([]);
              values.auditUserID = logInUserID;
              values.auditTimeStamp = getUTCTimeStamp();
              values.addedAuditUserID = logInUserID;
              values.addedAuditTimeStamp = getUTCTimeStamp();
              values.beginDate = fundCodeBeginDate;
              values.endDate = fundCodeEndDate;
              values.adjustedAuthorizedAmount = values.id
                ? values.budgetAmount.replace(/,/g, '')
                : values.adjustedAuthorizedAmount;
              values.inKindIndicator = values.inKindIndicator;
              values.fundVoidDate = null;
              values.fundVoidDate = voids.codeVoidIndicator
                ? setDateTimeStampFormate(new Date())
                : null;
              values.voidIndicator = voids.codeVoidIndicator;
              if (
                values.fundSourceVOList &&
                values.fundSourceVOList.length > 0 &&
                values.voidIndicator
              ) {
                values.fundSourceVOList.map(sourcedata => {
                  sourcedata.fundSourceVoidDate = sourcedata.fundSourceVoidDate
                    ? setDateTimeStampFormate(
                      new Date(sourcedata.fundSourceVoidDate)
                    )
                    : new Date();
                  sourcedata.voidIndicator = values.voidIndicator;
                });
              }
              tempNewFundData[skipRow] = values;
            let newDataWithoutId = tempNewFundData.map(({ id, ...rest }) => rest);            
              setnewFundData(newDataWithoutId);
              setSelectedFundCodeRow(null);
              ResetUpdateFundCodeForm();
              setshowResults(false);
              return true;
            }
          }
        }
      } else { setOpenFuncd(true); }
    } else {
      if (validateFundCode(true,"add")) {
        setOpenFuncd(false);
        if (errorMessagesArray.length === 0) {
          setSourceErrors([]);
          setShowError(false);
          values.auditUserID = logInUserID;
          values.auditTimeStamp = getUTCTimeStamp();
          values.addedAuditUserID = logInUserID;
          values.addedAuditTimeStamp = getUTCTimeStamp();
          updatedBeginDate = setSelectedDate(new Date(fundCodeBeginDate));
          updatedEndDate = setSelectedDate(new Date(fundCodeEndDate));
          if (newDialogData.length > 0) {
            values.fundSourceVOList = newDialogData;
            values.beginDate = fundCodeBeginDate;
            values.endDate = fundCodeEndDate;
            values.adjustedAuthorizedAmount = values.budgetAmount;
            values.id = generateUUID();
            values.voidIndicator = voids.codeVoidIndicator;
            values.fundVoidDate = null;
            if (
              values.fundSourceVOList &&
              values.fundSourceVOList.length > 0 &&
              values.voidIndicator
            ) {
              values.fundVoidDate = voids.codeVoidIndicator
                ? setDateTimeStampFormate(new Date())
                : null;
              values.fundSourceVOList.map(sourcedata => {
                sourcedata.fundSourceVoidDate = sourcedata.fundSourceVoidDate
                  ? setDateTimeStampFormate(
                    new Date(sourcedata.fundSourceVoidDate)
                  )
                  : new Date();
                sourcedata.voidIndicator = values.voidIndicator;
              });
            }
            let newTableData = [];
            newTableData = newFundData;
            newTableData.push(values);
            let newDataWithoutId = newTableData.map(({ id, ...rest }) => rest);
            setnewFundData(newDataWithoutId);
            ResetAddFundCodeForm('minorSave');
            setshowResults(false);
            return true;
          }
        }
      } else { setOpenFuncd(true); }
    }
    const errorArray = [];
    errorMessagesArray.map(var1 => {
      if (var1 === BudgetMaintenanceConstants.FUND_CODE_COVERAGE_ERROR) {
        errorArray.push(var1);
      }
      if (var1 === BudgetMaintenanceConstants.ADD_FUNDING_SOURCE_EMPTY_ERROR) {
        errorArray.push(var1);
      }
      if (var1 === BudgetMaintenanceConstants.FUNDCODE_COS_COMBINATION) {
        errorArray.push(var1);
      }
    });
    setSourceErrors(errorArray);
  };
  const [newDialogData, setnewDialogData] = React.useState([]);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [sourceErrors, setSourceErrors] = React.useState([]);
  const [addUpdateFundCode, setAddUpdateFundCode] = React.useState(false);

  const validateFundSourceCode = () => {
    var beginDateerrorText = '';
    var endDateerrorText = '';
    var showBeginDateError;
    var showEndDateError;
    var showDateOverlappingError;
    var showFederalError;
    var showStateError;
    var showCountyError;
    var showOtherError = false;
    var showTotalPercentageError = false;
    var showFederalFormat;
    var showStateFormat;
    var showCountyFormat;
    var showOtherFormat;
    //  errorMessagesArray = [];
    if (dataElement.federalPercentage) {
      if (
        dataElement.federalPercentage
          .toString()
          .match(/^[-]?[0-9]{0,3}\.?[0-9]{0,4}$/) === null
      ) {
        showFederalFormat = true;
      } else {
        if (
          !(
            dataElement.federalPercentage
              .toString()
              .match(/^[-]?[0-9]{4,9}\.?[0-9]{0,4}?$/) === null
          )
        ) {
          showFederalFormat = true;
        } else {
          showFederalFormat = false;
        }
      }
    }

    if (dataElement.statePercentage) {
      if (
        dataElement.statePercentage
          .toString()
          .match(/^[-]?[0-9]{0,3}\.?[0-9]{0,4}$/) === null
      ) {
        showStateFormat = true;
      } else {
        if (
          !(
            dataElement.statePercentage
              .toString()
              .match(/^[-]?[0-9]{4,9}\.?[0-9]{0,4}?$/) === null
          )
        ) {
          showStateFormat = true;
        } else {
          showStateFormat = false;
        }
      }
    }

    if (dataElement.countyPercentage) {
      if (
        dataElement.countyPercentage
          .toString()
          .match(/^[-]?[0-9]{0,3}\.?[0-9]{0,4}$/) === null
      ) {
        showCountyFormat = true;
      } else {
        if (
          !(
            dataElement.countyPercentage
              .toString()
              .match(/^[-]?[0-9]{4,9}\.?[0-9]{0,4}?$/) === null
          )
        ) {
          showCountyFormat = true;
        } else {
          showCountyFormat = false;
        }
      }
    }

    if (dataElement.otherPercentage) {
      if (
        dataElement.otherPercentage
          .toString()
          .match(/^[-]?[0-9]{0,3}\.?[0-9]{0,4}$/) === null
      ) {
        showOtherFormat = true;
      } else {
        if (
          !(
            dataElement.otherPercentage
              .toString()
              .match(/^[-]?[0-9]{4,9}\.?[0-9]{0,4}?$/) === null
          )
        ) {
          showOtherFormat = true;
        } else {
          showOtherFormat = false;
        }
      }
    }
    dataElement.totalPercentage =
      parseFloat(dataElement.federalPercentage) +
      parseFloat(dataElement.statePercentage) +
      parseFloat(dataElement.countyPercentage) +
      parseFloat(dataElement.otherPercentage);

    if (
      !dataElement.federalPercentage ||
      dataElement.federalPercentage === ''
    ) {
      dataElement.federalPercentage = '0.00';
    }
    if (!dataElement.statePercentage || dataElement.statePercentage === '') {
      dataElement.statePercentage = '0.00';
    }
    if (!dataElement.countyPercentage || dataElement.countyPercentage === '') {
      dataElement.countyPercentage = '0.00';
    }
    if (!dataElement.otherPercentage || dataElement.otherPercentage === '') {
      dataElement.otherPercentage = '0.00';
    }

    dataElement.totalPercentage =
      parseFloat(dataElement.federalPercentage) +
      parseFloat(dataElement.statePercentage) +
      parseFloat(dataElement.countyPercentage) +
      parseFloat(dataElement.otherPercentage);
    if (parseFloat(dataElement.totalPercentage).toFixed(2) !== parseFloat(100).toFixed(2)) {
      showTotalPercentageError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.TOTAL_PERCENTAGE_ERROR
      );
    }

    if (
      selectedStartDate &&
      selectedEndDate && compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedStartDate)
    ) {
      showBeginDateError = true;
      beginDateerrorText = BudgetMaintenanceConstants.BEGINDATE_ENDDATE_MATCH;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.BEGINDATE_ENDDATE_MATCH
      );
    }
    if (!selectedStartDate || selectedStartDate === '') {
      showBeginDateError = true;
      beginDateerrorText = BudgetMaintenanceConstants.BEGINDATE_ERROR;
      errorMessagesArray.push(BudgetMaintenanceConstants.BEGINDATE_ERROR);
    }
    if (selectedStartDate && selectedStartDate.toString() === 'Invalid Date') {
      showBeginDateError = true;
      beginDateerrorText =
        BudgetMaintenanceConstants.FSOURCE_BEGINDATE_INVALID_ERROR;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.FSOURCE_BEGINDATE_INVALID_ERROR
      );
    }
    if (selectedStartDate && selectedStartDate.toString() !== 'Invalid Date') {
      let isDateValid = false;
      isDateValid = validateDateMinimumValue(selectedStartDate);
      if (isDateValid) {
        showBeginDateError = true;
        beginDateerrorText = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    }

    if (!selectedEndDate || selectedEndDate === '') {
      showEndDateError = true;
      endDateerrorText = BudgetMaintenanceConstants.ENDDATE_ERROR;
      errorMessagesArray.push(BudgetMaintenanceConstants.ENDDATE_ERROR);
    }
    if (selectedEndDate && selectedEndDate.toString() === 'Invalid Date') {
      showEndDateError = true;
      endDateerrorText =
        BudgetMaintenanceConstants.FSOURCE_ENDDATE_INVALID_ERROR;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.FSOURCE_ENDDATE_INVALID_ERROR
      );
    }
    if (selectedEndDate && selectedEndDate.toString() !== 'Invalid Date') {
      let isDateValid = false;
      isDateValid = validateDateMinimumValue(selectedEndDate);
      if (isDateValid) {
        showEndDateError = true;
        endDateerrorText = AppConstants.DATE_ERROR_1964;
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
      }
    }
    // FSource Date validation
    const disabled = dataElement.pastDateDisable;
    if (!showBeginDateError && !disabled && selectedStartDate.toString() !== 'Invalid Date') {
      if ((Date.parse(selectedStartDate) < Date.parse(fundCodeBeginDate)) || (Date.parse(selectedStartDate) < Date.parse(moment(new Date()).format('MM/DD/YYYY')))) {
        showBeginDateError = true;
        beginDateerrorText = BudgetMaintenanceConstants.FSOUCE_STARTDATE_GREATEROREQALTO_FCODE_STARTDATE;
        errorMessagesArray.push(BudgetMaintenanceConstants.FSOUCE_STARTDATE_GREATEROREQALTO_FCODE_STARTDATE);
      }
    }
    if (selectedEndDate && selectedEndDate.toString() !== 'Invalid Date') {
      if (
        !showEndDateError && compareTwoDatesGreaterThanOrEqual(fundCodeEndDate, selectedEndDate)
      ) {
        showEndDateError = true;
        endDateerrorText =
          BudgetMaintenanceConstants.FSOUCE_ENDDATE_LESSTHANOREQALTO_FCODE_ENDDATE;
        errorMessagesArray.push(
          BudgetMaintenanceConstants.FSOUCE_ENDDATE_LESSTHANOREQALTO_FCODE_ENDDATE
        );
      }
    }
    if (
      !showBeginDateError &&
      selectedStartDate.toString() !== 'Invalid Date'
    ) {
      for (var i = 0; i < sourceHistoryData.length; i++) {
        if (
          (moment(selectedStartDate).format('MM/DD/YYYY') == sourceHistoryData[i].beginDate) ||
      (moment(selectedEndDate).format('MM/DD/YYYY') == sourceHistoryData[i].endDate) ||
      (moment(selectedStartDate).format('MM/DD/YYYY') == sourceHistoryData[i].endDate) ||
      (moment(selectedEndDate).format('MM/DD/YYYY') == sourceHistoryData[i].beginDate)) {
          showBeginDateError = true;
          beginDateerrorText =
              BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR;
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR
          );
        }
      }
    }

    if (
      !showBeginDateError &&
        selectedStartDate.toString() !== 'Invalid Date'
    ) {
      for (var i = 0; i < sourceHistoryData.length; i++) {
        if (
          (moment(selectedStartDate).format('MM/DD/YYYY') == sourceHistoryData[i].beginDate) ||
        (moment(selectedEndDate).format('MM/DD/YYYY') == sourceHistoryData[i].endDate) ||
        (moment(selectedStartDate).format('MM/DD/YYYY') == sourceHistoryData[i].endDate) ||
        (moment(selectedEndDate).format('MM/DD/YYYY') == sourceHistoryData[i].beginDate)) {
          showBeginDateError = true;
          beginDateerrorText =
                BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR;
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR
          );
        }
      }
    }

    if (
      !showFederalFormat &&
      !showStateFormat &&
      !showCountyFormat &&
      !showOtherFormat &&
      !showEndDateError &&
      !showBeginDateError &&
      !showTotalPercentageError
    ) {
      // Overlapping Dates
      if (newDialogData.length > 0) {
        let ovelappingDate = false;
        let skipRow = -1;
        const dateRanges = [];
        const tempSourceData = [...newDialogData];
        for (var i = 0; i < tempSourceData.length; i++) {
          if (
            selectedFSourceRow &&
            JSON.stringify(tempSourceData[i]) ===
            JSON.stringify(selectedFSourceRow)
          ) {
            skipRow = i;
          }
        }
        for (var j = 0; j < newDialogData.length; j++) {
          if (selectedFSourceRow && skipRow === j) {
            continue;
          }
          if (newDialogData[j].voidIndicator !== true) {
            dateRanges.push({
              start: new Date(newDialogData[j].beginDate),
              end: new Date(newDialogData[j].endDate)
            });
          }
        }
        dateRanges.push({
          start: new Date(selectedStartDate),
          end: new Date(selectedEndDate)
        });
        ovelappingDate = overlapCheckWithDateRange(dateRanges).overlap;
        if (ovelappingDate) {
          showEndDateError = true;
          endDateerrorText =
            BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR;
          showBeginDateError = true;
          beginDateerrorText =
            BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR;
          errorMessagesArray.push(
            BudgetMaintenanceConstants.FSOURCE_OVERLAPPING_ERROR
          );
        }
      }
    }

    setShowError({
      showTotalPercentageError: showTotalPercentageError,
      showFSourceBeginDateError: showBeginDateError,
      showFSourceEndDateError: showEndDateError,
      showDateOverlappingError: showDateOverlappingError,
      showFederalError: showFederalError,
      showStateError: showStateError,
      showCountyError: showCountyError,
      showOtherError: showOtherError,
      showFederalFormat: showFederalFormat,
      showStateFormat: showStateFormat,
      showCountyFormat: showCountyFormat,
      showOtherFormat: showOtherFormat
    });
    setShowErrorText({
      beginDateerrorText: beginDateerrorText,
      endDateerrorText: endDateerrorText
    });

    if (errorMessagesArray.length === 0) {
      seterrorMessages([]);
      return true;
    } else {
      seterrorMessages(errorMessagesArray.filter(onlyUniqueErrorMessage));
      return false;
    }
  };

  const saveFundingSourceData = () => {
    errorMessagesArray = [];
    if (validateFundSourceCode()) {
      setShowError(false);
      var updatedBeginDate = setSelectedDate(new Date(selectedStartDate));
      setSelectedStartDate(new Date(updatedBeginDate));
      var updatedEndDate = setSelectedDate(new Date(selectedEndDate));
      setSelectedEndDate(new Date(updatedEndDate));
      if (selectedFSourceRow) {
        let skipRow = -1;
        const tempSourceData = [...newDialogData];

        for (var i = 0; i < tempSourceData.length; i++) {
          if (
            selectedFSourceRow &&
            JSON.stringify(tempSourceData[i]) ===
            JSON.stringify(selectedFSourceRow)
          ) {
            skipRow = i;
          }
        }
        tempSourceData[skipRow].auditUserID = logInUserID;
        tempSourceData[skipRow].auditTimeStamp = getUTCTimeStamp();
        tempSourceData[skipRow].addedAuditUserID = logInUserID;
        tempSourceData[skipRow].addedAuditTimeStamp = getUTCTimeStamp();
        tempSourceData[skipRow].beginDate = selectedStartDate;
        tempSourceData[skipRow].endDate = selectedEndDate;
        tempSourceData[skipRow].federalPercentage =
          dataElement.federalPercentage;
        tempSourceData[skipRow].statePercentage = dataElement.statePercentage;
        tempSourceData[skipRow].countyPercentage = dataElement.countyPercentage;
        tempSourceData[skipRow].otherPercentage = dataElement.otherPercentage;
        tempSourceData[skipRow].totalPercentage =
          +dataElement.federalPercentage +
          +dataElement.statePercentage +
          +dataElement.countyPercentage +
          +dataElement.otherPercentage;
        tempSourceData[skipRow].fundSourceVoidDate = voids.sourceVoidIndicator
          ? setDateTimeStampFormate(new Date())
          : null;
        tempSourceData[skipRow].voidIndicator = voids.sourceVoidIndicator;
        setnewDialogData([...tempSourceData]);
      } else {
        let newTableArray = [];
        dataElement.auditUserID = logInUserID;
        dataElement.auditTimeStamp = getUTCTimeStamp();
        dataElement.addedAuditUserID = logInUserID;
        dataElement.addedAuditTimeStamp = getUTCTimeStamp();
        dataElement.versionNo = 0;
        dataElement.dbRecord = false;
        dataElement.voidIndicator = false;
        dataElement.fundSourceVoidDate = null;
        dataElement.voidValue = 'No';
        dataElement.fundSourceVoidProtect = false;
        dataElement.beginDate = selectedStartDate;
        dataElement.endDate = selectedEndDate;
        dataElement.totalPercentage =
          +dataElement.federalPercentage +
          +dataElement.statePercentage +
          +dataElement.countyPercentage +
          +dataElement.otherPercentage;
        dataElement.id = generateUUID();
        dataElement.fundSourceVoidDate = null;
        newTableArray = newDialogData;
        newTableArray.push(dataElement);
        setnewDialogData(newTableArray);
      }
      setOpen(false);
      setSelectedFSourceRow(null);
    }   
  };
  const resetFundingSourceData = () => {
    setFocusCheck(initalFocusCheck);
         setAllowNavigation(initalFocusCheck);
    seterrorMessages([]);
    if (selectedFSourceRow) {
      setDataElement({
        selectedStartDate: selectedFSourceRow.beginDate,
        selectedEndDate: selectedFSourceRow.endDate,
        federalPercentage: selectedFSourceRow.federalPercentage,
        statePercentage: selectedFSourceRow.statePercentage,
        countyPercentage: selectedFSourceRow.countyPercentage,
        otherPercentage: selectedFSourceRow.otherPercentage,
        totalPercentage:
          +selectedFSourceRow.federalPercentage +
          +selectedFSourceRow.statePercentage +
          +selectedFSourceRow.countyPercentage +
          +selectedFSourceRow.otherPercentage
      });
      setVoids({
        ...voids,
        sourceVoidIndicator: selectedFSourceRow.voidIndicator
          ? setSelectedFSourceRow.voidIndicator
          : false
      });
      setSelectedStartDate(new Date(selectedFSourceRow.beginDate));
      setSelectedEndDate(new Date(selectedFSourceRow.endDate));
    } else {
      setDataElement({
        federalPercentage: '',
        statePercentage: '',
        countyPercentage: '',
        otherPercentage: '',
        totalPercentage: '',
        selectedStartDate: '',
        selectedEndDate: ''
      });
      setVoids({
        ...voids,
        sourceVoidIndicator: false
      });
      setSelectedStartDate(null);
      setSelectedEndDate(new Date(endDateDefault));
    }

    setShowError(false);
    setShowErrorText('');
  };
  const handleClose = () => {
    setFocusCheck(false)
         setAllowNavigation(false)
    setOpen(false);
    setSelectedFSourceRow(null);
    resetFundingSourceData();
  };
  const formatDecimalForBudget = () => {
    if (showBudgetAmountFormatError === false) {
      if (values.budgetAmount !== '') {
        setValues({
          ...values,
          budgetAmount: numeral(values.budgetAmount)
            .format('0,0.00')
        });
      }
    }
  };
  const removeFormatDecimalForBudget = () => {
    if (showBudgetAmountFormatError === false) {
      if (values.budgetAmount !== '') {
        setValues({
          ...values,
          budgetAmount: numeral(values.budgetAmount)
            .format('0.00')
        });
      }
    }
  };
  const validateBudgetData = () => {
    setFocusCheck(false);
    var showStateFiscalYearError = false;
    var showBudgetDescError = false;
    var showAgencyError = false;
    var showGLCompanyError = false;
    var showGLAccountingUnitError = false;
    var showGLAccountError = false;
    var showACAccountCategoryError = false;

    if (!data.fiscalYear || data.fiscalYear === DEFAULT_DD_VALUE) {
      showStateFiscalYearError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_FISCAL_YEAR_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      !data.organizationCode ||
      data.organizationCode === DEFAULT_DD_VALUE
    ) {
      showGLAccountingUnitError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_GL_ACCOUNTING_UNIT_ERROR
      );
      seterrorMessages(errorMessagesArray);
    }
    if (!data.objectCode || data.objectCode === DEFAULT_DD_VALUE) {
      showGLAccountError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_GL_ACCOUNT_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (!data.agencyCode || data.agencyCode === DEFAULT_DD_VALUE) {
      showAgencyError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_AGENCY_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (!data.glCompanyCode || data.glCompanyCode === DEFAULT_DD_VALUE) {
      showGLCompanyError = true;
      errorMessagesArray.push(BudgetMaintenanceConstants.ADD_GL_COMPANY_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (!data.budgetDesc || data.budgetDesc === ' ') {
      showBudgetDescError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_BUDGET_DESCRIPTION_ERROR
      );
      seterrorMessages(errorMessagesArray);
    }

    /*     if (
      !data.accountCategoryCode ||
      data.accountCategoryCode === DEFAULT_DD_VALUE
    ) {
      showACAccountCategoryError = true;
      errorMessagesArray.push(
        BudgetMaintenanceConstants.ADD_AC_ACCOUNT_CATEGORY_ERROR
      );
      seterrorMessages(errorMessagesArray);
    } */
    setShowError({
      showStateFiscalYearError: showStateFiscalYearError,
      showBudgetDescError: showBudgetDescError,
      showAgencyError: showAgencyError,
      showGLCompanyError: showGLCompanyError,
      showGLAccountingUnitError: showGLAccountingUnitError,
      showGLAccountError: showGLAccountError
      // showACAccountCategoryError: showACAccountCategoryError
    });

    if (errorMessagesArray.length === 0) {
      seterrorMessages([]);
      return true;
    } else {
      seterrorMessages(errorMessagesArray.filter(onlyUniqueErrorMessage));
      return false;
    }
  };
  const amountFormatCheck = value => {
    return typeof value === 'string'
      ? parseFloat(value.replace(/[^\d\.\-]/g, ''))
        .toString()
        .indexOf('.') === -1
        ? `${parseFloat(value.replace(/[^\d\.\-]/g, ''))}.00`
        : parseFloat(value.replace(/[^\d\.\-]/g, ''))
      : numeral(value).format('$0,0.00');
  };
  const handleClickOpen = rowData => {
    errorMessagesArray = [];
    setSelectedFundCodeRow(null);
    setAdjustAmtError('');
    seterrorMessages([]);
    setSourceErrors([]);
    setSuccessMessage([]);
    if (validateBudgetData()) {
      if (rowData) {
        setSelectedFundCodeRow(rowData);
        let cosTemp;
        if (rowData.cos === null) {
          cosTemp = DEFAULT_DD_VALUE;
        } else {
          cosTemp = rowData.cos;
        }
        setValues({
          ...rowData,
          cos: cosTemp,
          budgetAmount: rowData.budgetAmount,
          adjustedAuthorizedAmount: rowData.adjustedAuthorizedAmount ? numeral(rowData.adjustedAuthorizedAmount).format('0,0.00') : rowData.adjustedAuthorizedAmount,
          expenditureAmount: rowData.expenditureAmount ? numeral(rowData.expenditureAmount).format('0,0.00') : rowData.expenditureAmount,
          federal: rowData.federal ? numeral(rowData.federal).format('0,0.00') : rowData.federal,
          state: rowData.state ? numeral(rowData.state).format('0,0.00') : rowData.state,
          county: rowData.county ? numeral(rowData.county).format('0,0.00') : rowData.county,
          other: rowData.other ? numeral(rowData.other).format('0,0.00') : rowData.other,
          tempExpAmount: rowData.tempExpAmount ? numeral(rowData.tempExpAmount).format('0,0.00') : rowData.tempExpAmount,
          inKindIndicator: rowData.inKindIndicator
        });
        setnewDialogData(rowData.fundSourceVOList);
        setFundCodeBeginDate(rowData.beginDate);
        setFundCodeEndDate(rowData.endDate);
        setAddUpdateFundCode(true);
      } else {
        setSelectedFundCodeRow(null);
        setValues({
          fundCode: DEFAULT_DD_VALUE,
          adjustedAuthorizedAmount: '0.00',
          beginDate: '',
          budgetAmount: '0.00',
          endDate: '',
          expenditureAmount: '0.00',
          cos: DEFAULT_DD_VALUE,
          jobNumber: '-1',
          federal: '0.00',
          state: '0.00',
          county: '0.00',
          other: '0.00',
          inKindIndicator: 'N',
          fundSourceVOList: []
        });
        setFundCodeBeginDate(null);
        setFundCodeEndDate(new Date(endDateDefault));
        setAddUpdateFundCode(false);
        setnewDialogData([]);
      }

      setshowResults(true);
    }
  };

  const ResetAddFundCodeForm = (calledFrom='') => {
    setFocusCheck(initalFocusCheck);
 calledFrom !== 'minorSave' && setAllowNavigation(initalFocusCheck);
    setAdjustAmtError('');
    setSourceErrors([]);
    seterrorMessages([]);
    if (selectedFundCodeRow) {
      setValues({
        fundCode: selectedFundCodeRow.fundCode,
        adjustedAuthorizedAmount: amountFormatCheck(
          selectedFundCodeRow.adjustedAuthorizedAmount
        ),
        beginDate: selectedFundCodeRow.beginDate,
        budgetAmount: selectedFundCodeRow.budgetAmount,
        endDate: selectedFundCodeRow.endDate,
        expenditureAmount: selectedFundCodeRow.expenditureAmount,
        cos: selectedFundCodeRow.cos !== null ? selectedFundCodeRow.cos : DEFAULT_DD_VALUE,
        jobNumber: selectedFundCodeRow.jobNumber || '-1',
        inKindIndicator: selectedFundCodeRow.inKindIndicator,
        fundSourceVOList: [...selectedFundCodeRow.fundSourceVOList],
        federal: selectedFundCodeRow.federal,
        state: selectedFundCodeRow.state,
        county: selectedFundCodeRow.county,
        other: selectedFundCodeRow.other
      });
      setnewDialogData([...selectedFundCodeRow.fundSourceVOList] || []);
      setVoids({
        ...voids,
        codeVoidIndicator: selectedFundCodeRow.voidIndicator
          ? selectedFundCodeRow.voidIndicator
          : false
      });
      setFundCodeBeginDate(new Date(selectedFundCodeRow.beginDate));
      setFundCodeEndDate(new Date(selectedFundCodeRow.endDate));
      setShowError(false);
      setShowErrorText('');
    } else {
      setFocusCheck(false);
      setValues({
        fundCode: DEFAULT_DD_VALUE,
        adjustedAuthorizedAmount: '0.00',
        beginDate: '',
        budgetAmount: '0.00',
        endDate: '',
        expenditureAmount: '0.00',
        cos: DEFAULT_DD_VALUE,
        jobNumber: '-1',
        federal: '0.00',
        state: '0.00',
        county: '0.00',
        other: '0.00',
        inKindIndicator: 'N',
        fundSourceVOList: []
      });
      setVoids({
        ...voids,
        codeVoidIndicator: false
      });
      setnewDialogData([]);
      setFundCodeBeginDate(null);
      setFundCodeEndDate(new Date(endDateDefault));
      setShowError(false);
      setShowErrorText('');
    }
  };
  const ResetUpdateFundCodeForm = () => {
    setFocusCheck(false);
    setValues({
      fundCode: DEFAULT_DD_VALUE,
      adjustedAuthorizedAmount: '0.00',
      beginDate: '',
      budgetAmount: '0.00',
      endDate: '',
      expenditureAmount: '0.00',
      cos: DEFAULT_DD_VALUE,
      jobNumber: '-1',
      federal: '0.00',
      state: '0.00',
      county: '0.00',
      other: '0.00',
      inKindIndicator: 'N',
      fundSourceVOList: []
    });
    setnewDialogData([]);
    setFundCodeBeginDate(null);
    setVoids({
      ...voids,
      codeVoidIndicator: false
    });
    setFundCodeEndDate(new Date(endDateDefault));
    setShowError(false);
    setShowErrorText('');
  };
  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeHistoryTabs = (event, newValue) => {
    setFocusCheck(true);
    setHistoryValue(newValue);
  };
  const handleBlurPEr = (name, type) => event => {
    setFocusCheck(true);
    var num = event.target.value;
    if (num && !isNaN(num)) {
      const newNum = Number.parseFloat(num).toFixed(2);
      setDataElement({ ...dataElement, [name]: event.target && newNum ? newNum : event });
    }
  };
  const handleChangeDataElement = name => event => {
    setFocusCheck(true);
         setAllowNavigation(true);
    setDataElement({ ...dataElement, [name]: event.target.value });
  };

  const adjAmountFormat = () => {
    if (AdjustAmtError === '') {
      setValues({
        ...values,
        adjustedAuthorizedAmount: numeral(values.adjustedAuthorizedAmount)
          .format('0,0.00')
      });
    }
  };
  const removeAdjAmountFormat = () => {
    if (AdjustAmtError === '') {
      setValues({
        ...values,
        adjustedAuthorizedAmount: numeral(values.adjustedAuthorizedAmount)
          .format('0.00')
      });
    }
  };
  const [AdjustAmtError, setAdjustAmtError] = React.useState('');
  const handleChangeData = name => event => {
    setFocusCheck(true);
         setAllowNavigation(true);
    if (name === 'inKindIndicator') {
      if (event.target.value === 'Y') {
        setValues({ ...values, [name]: 'Y' });
      } else {
        setValues({ ...values, [name]: 'N' });
      }
    } else {
      if (name === 'adjustedAuthorizedAmount') {
        if (
          event.target.value
            .replace(/,/g, '')
            .match(/^[-]?[0-9]{0,9}\.?[0-9]{0,2}$/) === null
        ) {
          setAdjustAmtError(BudgetMaintenanceConstants.DATA_INVALID);
        } else {
          if (
            !(
              event.target.value
                .replace(/,/g, '')
                .match(/^[-]?[0-9]{10,13}\.?[0-9]{0,2}?$/) === null
            )
          ) {
            setAdjustAmtError(BudgetMaintenanceConstants.DATA_INVALID);
          } else {
            setAdjustAmtError('');
          }
        }
      }
      var showBudgetAmountFormatError;
      if (name === 'budgetAmount') {
        if (event.target.value && event.target.value.includes('.')) {
          if (checkingDecimal(event.target.value)) {
            setValues({ ...values, [name]: event.target.value });
          }
        } else {
            setValues({ ...values, [name]: event.target.value });          
        }
      }
      else{
        setValues({ ...values, [name]: event.target.value });
      }
    }
  };
  const setDateTimeStampFormate = dateToFormat => {
    var currentDate = new Date(dateToFormat);
    if (data.voidIndicator === true) {
      data.voidDate = getDateInMMDDYYYYFormatWithApendZero(new Date());
    }
    var dd = currentDate.getDate();
    var mm = currentDate.getMonth() + 1;

    var yyyy = currentDate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    currentDate = yyyy + '-' + mm + '-' + dd + ' 00:00:00.000000000';
    return currentDate;
  };

  const masterUpdateBudgetData = () => {
      errorMessagesArray = [];
      if (!allowNavigation) {
        NoSaveMessage();
      } else {
        if (open && !validateFundSourceCode()) {
          return false;
        } else if (openAddFundCode && !validateFundCode(true)) {
          return false;
        }
        setSuccessMessage([]);
        setSpinnerLoader(false);
        if (validateBudgetData()) {
          setData(data);
          const saveObject = {
            ...data,
            lob: AppConstants.DEFAULT_LOB_VALUE
          };
          newFundData.map(value => {
            value.fundSourceHistoryList = [];
          });
          saveObject.fundCodeVOList = [...newFundData];
          saveObject.voidNoteInd = searchData.voidIndicator ? searchData.voidIndicator : false;
          newFundData.map(value => {
            if (value.cos === DEFAULT_DD_VALUE) {
              value.cos = null;
            }
            if (value.cos !== null && value.cos !== undefined) {
              value.cosCodeLongDesc = getLongDescription(cosData, value.cos);
            }
            if (value.inKindIndicator === 'Y') {
              value.inKindIndicator = 'Y';
            } else {
              value.inKindIndicator = 'N';
            }
            value.lob = data.lob;
            value.beginDate = getDateInMMDDYYYYFormatWithApendZero(
              value.beginDate
            ); // Moment(value.beginDate).format('MM/DD/YYYY');
            value.endDate = getDateInMMDDYYYYFormatWithApendZero(value.endDate); // Moment(value.endDate).format('MM/DD/YYYY');
            if (data.voidIndicator && !value.fundVoidDate) {
              value.fundVoidDate = setDateTimeStampFormate(new Date());
              value.voidIndicator = data.voidIndicator;
            } else {
              value.fundVoidDate = value.fundVoidDate
                ? setDateTimeStampFormate(new Date(value.fundVoidDate))
                : null;
            }
            if (value.fundSourceVOList && value.fundSourceVOList.length > 0) {
              value.fundSourceVOList.map(sourcedata => {
                sourcedata.beginDate = getDateInMMDDYYYYFormatWithApendZero(
                  sourcedata.beginDate
                ); // Moment(value.beginDate).format('MM/DD/YYYY');
                sourcedata.endDate = getDateInMMDDYYYYFormatWithApendZero(
                  sourcedata.endDate
                ); // Moment(value.endDate).format('MM/DD/YYYY');
                if (data.voidIndicator) {
                  sourcedata.fundSourceVoidDate = setDateTimeStampFormate(
                    new Date()
                  );
                  sourcedata.voidIndicator = data.voidIndicator;
                }
              });
            }
          });

          if (data.voidIndicator === true) {
            saveObject.voidDate = setDateTimeStampFormate(new Date());
            saveObject.voidDate = searchData.voidIndicator ? setDateTimeStampFormate(searchData.voidDate) : saveObject.voidDate;
          }

          const finalSaveObj = formatRequest(saveObject);
          setSpinnerLoader(true);
          if (finalSaveObj && finalSaveObj.fundCodeVOList) {
            finalSaveObj.fundCodeVOList.map(var1 => {
              if (
                var1.adjustedAuthorizedAmount &&
              var1.adjustedAuthorizedAmount !== null
              ) {
                var1.adjustedAuthorizedAmount = var1.adjustedAuthorizedAmount
                  .toString()
                  .replace('$', '');
              }
            });
          }
          if (finalSaveObj.voidIndicator === true) {
            finalSaveObj.voidValue = 'Yes';
          }
          let count = 0;
          finalSaveObj.fundCodeVOList.map(var1 => {
            if (var1.voidIndicator && var1.voidIndicator === true) {
              var1.voidValue = 'Yes';
              var1.fundSourceVoidDate = moment(new Date()).format('L');
            }
            finalSaveObj.fundCodeVOList.map((fundcode, index) => {
              if (!fundcode.voidIndicator) {
                count = count + 1;
              }
            });
          });

          if (count === 0 && (!searchData.voidIndicator && !finalSaveObj.voidIndicator)) {
            setSpinnerLoader(false);
            const errorMessages = [];
            const message = BudgetMaintenanceConstants.ONE_BUDGETACTIVE_RECORD;
            errorMessages.push(message);
            seterrorMessages(errorMessages);
          } else {
            budgetMaintenanceUpdateSave(finalSaveObj);
          }
        }
      }
    };

  const formatRequest = saveObject => {
    Object.keys(saveObject).forEach(key => {
      if (saveObject[key] === DEFAULT_DD_VALUE) {
        saveObject[key] = null;
      }
    });
    saveObject = { ...saveObject, noteSetVO: notesInput };
    return saveObject;
  };

  function fSourceRowDeleteClick () {
    let temNewDialogData = [...newDialogData];
    if (rowFSourceData.rowFSourceData) {
      for (let i = 0; i < rowFSourceData.rowFSourceData.length; i++) {
        if (rowFSourceData.rowFSourceData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(
            fSource => fSource.id !== rowFSourceData.rowFSourceData[i]
          );
        }
      }
    }
    setnewDialogData(temNewDialogData);
    setrowFSourceData([{}]);
  }
  function fCodeRowDeleteClick () {
    let temNewDialogData = [...newFundData];
    if (rowFCodeeData.rowFCodeeData) {
      for (let i = 0; i < rowFCodeeData.rowFCodeeData.length; i++) {
        if (rowFCodeeData.rowFCodeeData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(
            fCode => fCode.id !== rowFCodeeData.rowFCodeeData[i]
          );
        }
      }
    }
    setnewFundData(temNewDialogData);
    setrowFCodeData([{}]);
  }

  const rowDeleteFSource = data => {
    setrowFSourceData({ ...rowFSourceData, rowFSourceData: data });
  };
  const rowDeleteFCode = data => {
    setrowFCodeData({ ...rowFCodeeData, rowFCodeeData: data });
  };
  const editFundingSource = row => event => {
    setSelectedFSourceRow({ ...row });
    if (row) {
      setOpen(true);
      setDataElement({
        selectedStartDate: row.beginDate,
        selectedEndDate: row.endDate,
        federalPercentage: row.federalPercentage,
        statePercentage: row.statePercentage,
        countyPercentage: row.countyPercentage,
        otherPercentage: row.otherPercentage,
        totalPercentage:
          +row.federalPercentage +
          +row.statePercentage +
          +row.countyPercentage +
          +row.otherPercentage,
        fundSourceVoidDate: row.fundSourceVoidDate,
        pastDateDisable: new Date(row.beginDate) < new Date()
      });
      setSelectedStartDate(new Date(row.beginDate));
      setSelectedEndDate(new Date(row.endDate));
      setVoids({
        ...voids,
        sourceVoidIndicator: row.voidIndicator ? row.voidIndicator : false
      });
    }
  };

  // add notes
  let notesDataArray = [];

  const addNotes = data => {
           setAllowNavigation(true);
    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  // use effect for edit page .. on finding search data setting values to edit description
  useEffect(() => {
    if (searchData && searchData.noteSetVO) {
      const noteSetVO = searchData.noteSetVO;
      const notesTable = searchData.noteSetVO.notesList;
      setNotesInput({
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: noteSetVO.addedAuditUserID ? noteSetVO.addedAuditUserID : logInUserID,
        addedAuditTimeStamp: noteSetVO.addedAuditTimeStamp
          ? noteSetVO.addedAuditTimeStamp
          : getUTCTimeStamp(),
        versionNo: noteSetVO.versionNo,
        dbRecord: noteSetVO.dbRecord,
        sortColumn: noteSetVO.sortColumn,
        tableName: noteSetVO.tableName,
        noteSetSK: noteSetVO.noteSetSK,
        noteSourceName: noteSetVO.noteSourceName,
        notesList: notesTable,
        globalNotesList: [],
        checkAll: noteSetVO.checkAll,
        addNewLinkRender: noteSetVO.addNewLinkRender,
        filterLinkRender: noteSetVO.filterLinkRender,
        printLinkRender: noteSetVO.printLinkRender,
        completeNotesList: []
      });

      setNotesTableData(notesTable);
    }
  }, [searchData]);
  const [disableNotes, setDisableNotes] = React.useState(true);

  const validateParentForm = () => {
    let flag = false;
    if (!data.fiscalYear || data.fiscalYear === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (
      !data.organizationCode ||
      data.organizationCode === DEFAULT_DD_VALUE
    ) {
      flag = flag || true;
    }
    if (!data.objectCode || data.objectCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (!data.agencyCode || data.agencyCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (!data.glCompanyCode || data.glCompanyCode === DEFAULT_DD_VALUE) {
      flag = flag || true;
    }
    if (!data.budgetDesc || data.budgetDesc === '') {
      flag = flag || true;
    }

    if (
      !data.accountCategoryCode ||
      data.accountCategoryCode === DEFAULT_DD_VALUE
    ) {
      flag = flag || true;
    }
    return flag;
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [data]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessage([]);
    seterrorMessages([]);
    setShowError(false);
    setShowErrorText('');
  };
  const [associatedFundCodeTable, setAssociatedFundCodeTable] = React.useState(newFundData);

  React.useEffect(() => {
    if (newFundData) {
      newFundData.map((record) => {
        record.id = generateUUID()
        if (record.fundCode) {
          record.fundCodeLongDesc = getLongDescription(fundCodeData, record.fundCode);
        }
        if (record.cos) {
          record.cosCodeLongDesc = getLongDescription(cosData, record.cos);
        }
        if (record.cos === 'Please Select One') {
          record.cos = null;
        }
      });
    }
    setAssociatedFundCodeTable(newFundData);
  }, [newFundData, fundCodeData, cosData]);

  const handleCloseAddFundcode = () => {
    setFocusCheck(initalFocusCheck);
 setAllowNavigation(initalFocusCheck);
    setshowResults(false);
    seterrorMessages([]);
    errorMessagesArray = [];
    handleCloseFundigSource();
  };
  const handleCloseFundigSource = () => {
    setFocusCheck(initalFocusCheck);
         setAllowNavigation(initalFocusCheck);
    seterrorMessages([]);
    errorMessagesArray = [];
    setOpen(false);
    setSelectedFSourceRow(null);
    resetFundingSourceData();
  };

  const [auditButtonToggle, setAuditButtonToggle] = React.useState(false);
  const [auditLogRow, setAuditLogRow] = React.useState(null);

  useEffect(() => {
    if (auditButtonToggle) {
      onClickAuditLog();
      if (auditLogRow) {
        onClickAuditDialogLog(auditLogRow);
      }
    } else {
      setClose(false);
      setShowLogDialogTable(false);
      setShowLogTable(false);
    }
  }, [auditButtonToggle]);

  useEffect(() => {
    if (auditLogRow && auditButtonToggle) {
      setAuditTableData([]);
      onClickAuditDialogLog(auditLogRow);
    } else {
      setAuditTableData([]);
    }
  }, [auditLogRow]);

  const ValuesFromBudgetM = props.history && props.history.location && props.history.location.state && props.history.location.state.values;

  const cancelMinorSection = () => {
         setAllowNavigation(false);
    setTimeout(()=>{
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/FinancialBudgetSearch',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { ValuesFromBudgetM: ValuesFromBudgetM }
      });
    },100)
  };

  const majorCancel = () => {
         setAllowNavigation(false);
    seterrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/FinancialBudgetSearch',
        tableRender: !props.history.location?.state?.isFromAdd,
        state: { ValuesFromBudgetM: ValuesFromBudgetM }
      });
    }
  };
  let fiscalYearVal = fiscalYearData?.find(value => value.code == data.fiscalYear);
  let agencyVal = agencyData?.find(value => value.code == data.agencyCode);
  let subObjectVal = glSubAccountData?.find(value => value.code == data.subObjectCode);
  let accCatVal = acAccountCategoryData?.find(value => value.code == data.accountCategoryCode);
  let glCompanyVal = glCompanyData?.find(value => value.code == data.glCompanyCode);
  let organVal = unitData?.find(value => value.code == data.organizationCode);
  let accountVal = glAccountData?.find(value => value.code == data.objectCode);
  let fundCodeVal = fundCodeData.find(value => value.code === values.fundCode);
  let cosCodeVal = cosData.find(value => value.code === values.cos);

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      {spinnerLoader ? <Spinner /> : null}

      <div className="tabs-container" ref={toPrintRef}>
        { errorMessages.length !== 0 ? (<ErrorMessages errorMessages={errorMessages} />) : null }
        {successMessage.length > 0 && errorMessages.length === 0 ? (
          <SuccessMessages successMessages={successMessage} />
        ) : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">Edit Budget Data</h1>
          <div className="float-right mt-2">
            <Button
              className="btn btn-success ml-1"
              onClick={masterUpdateBudgetData}
              disabled={!userInquiryPrivileges}
            >
              <i class="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
            <Button color="primary" className={
              auditButtonToggle
                ? 'btn btn-primary ml-1'
                : 'btn btn-text1 btn-transparent ml-1'
            }
            onClick={() => {
              setAuditButtonToggle(!auditButtonToggle);
              setShowLogTable(true);
            }}
            >
              <i class="fa fa-question-circle" aria-hidden="true"></i>
              Audit Log
            </Button>

            {window.location.pathname === '/BudgetDataUpdate' && (
              <Button title="Cancel" onClick={majorCancel}>{AppConstants.CANCEL}</Button>
            )}

          </div>
          <div className="clearfix"></div>
        </div>
        <div>
          <div className="tab-body">
            <form autoComplete="off">
              <div className="form-wrapper">
                <NativeDropDown
                  id="state-fiscal-year"
                    label="State Fiscal Year"
                  isRequired
                  value={fiscalYearVal !== undefined ? data.fiscalYear : DEFAULT_DD_VALUE}
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : searchData.voidDate != null ||
                        searchData.voidIndicator
                    }
                    onChange={handleChangeMain('fiscalYear')}
                  errTxt={
                      showStateFiscalYearError
                        ? BudgetMaintenanceConstants.ADD_FISCAL_YEAR_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 4 }}
                  dropdownList={fiscalYearData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />
                <div className="mui-custom-form override-width-45">
                  <TextField
                    id="budget-description"
                    fullWidth
                    label="Budget Description"
                    required
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : searchData.voidDate != null ||
                        searchData.voidIndicator
                    }
                    rows="1"
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 60 }}
                    value={data.budgetDesc || ''}
                    onChange={handleChangeMain('budgetDesc')}
                    helperText={
                      showBudgetDescError
                        ? BudgetMaintenanceConstants.ADD_BUDGET_DESCRIPTION_ERROR
                        : null
                    }
                    error={
                      showBudgetDescError
                        ? BudgetMaintenanceConstants.ADD_BUDGET_DESCRIPTION_ERROR
                        : null
                    }
                  />
                </div>
              </div>
              <div className="form-wrapper">
                <NativeDropDown
                  id="agency"
                    label="Agency"
                  isRequired
                  value={agencyVal !== undefined ? data.agencyCode : DEFAULT_DD_VALUE}
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : searchData.voidDate != null ||
                        searchData.voidIndicator
                    }
                    onChange={handleChangeMain('agencyCode')}
                  errTxt={
                      showAgencyError
                        ? BudgetMaintenanceConstants.ADD_AGENCY_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 3 }}
                  dropdownList={agencyData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />
                <NativeDropDown
                  id="gl-company"
                  isRequired
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : searchData.voidDate != null ||
                        searchData.voidIndicator
                    }
                    label="GL Company"
                  errTxt={
                      showGLCompanyError
                        ? BudgetMaintenanceConstants.ADD_GL_COMPANY_ERROR
                        : null
                    }
                  value={glCompanyVal !== undefined ? data.glCompanyCode : DEFAULT_DD_VALUE}
                    onChange={handleChangeMain('glCompanyCode')}
                    InputLabelProps={{
                      shrink: true,
                      required: true
                    }}
                    inputProps={{ maxLength: 4 }}
                  dropdownList={glCompanyData.map(option => (
                    <option key={option.code} value={option.code}>
                        {option.description}
                    </option>
                    ))}
                />
                <NativeDropDown
                  id="gl-accounting-unit"
                    label="GL Accounting Unit"
                  isRequired
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : searchData.voidDate != null ||
                        searchData.voidIndicator
                    }
                  value={organVal !== undefined ? data.organizationCode : DEFAULT_DD_VALUE}
                    onChange={handleChangeMain('organizationCode')}
                  errTxt={
                      showGLAccountingUnitError
                        ? BudgetMaintenanceConstants.ADD_GL_ACCOUNTING_UNIT_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 8 }}
                  dropdownList={unitData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />
                <NativeDropDown
                  id="gl-account"
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : searchData.voidDate != null ||
                        searchData.voidIndicator
                    }
                    label="GL Account"
                  isRequired
                  value={accountVal !== undefined ? data.objectCode : DEFAULT_DD_VALUE}
                    onChange={handleChangeMain('objectCode')}
                  errTxt={
                      showGLAccountError
                        ? BudgetMaintenanceConstants.ADD_GL_ACCOUNT_ERROR
                        : null
                    }
                    inputProps={{ maxLength: 6 }}
                  dropdownList={glAccountData.map(option => (
                    <option key={option.code} value={option.code}>
                        {option.description}
                    </option>
                    ))}
                />
              </div>
              <div className="form-wrapper">
                <NativeDropDown
                  id="gl-sub-account"
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : searchData.voidDate != null ||
                        searchData.voidIndicator
                    }
                    label="GL Sub-Account"
                  value={subObjectVal !== undefined ? data.subObjectCode : DEFAULT_DD_VALUE}
                    onChange={handleChangeMain('subObjectCode')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 4 }}
                  dropdownList={glSubAccountData
                      ? glSubAccountData.map(option => (
                        <option key={option.code} value={option.code}>
                          {option.description}
                        </option>
                      ))
                      : null}
                />
                <NativeDropDown
                  id="ac-account-category"
                    label="AC Account Category"
                  value={accCatVal !== undefined ? data.accountCategoryCode : DEFAULT_DD_VALUE}
                  onChange={handleChangeMain('accountCategoryCode')}
                    inputProps={{ maxLength: 5 }}
                    disabled={
                      !userInquiryPrivileges
                        ? !userInquiryPrivileges
                        : searchData.voidDate != null ||
                        searchData.voidIndicator
                    }
                  dropdownList={acAccountCategoryData.map(option => (
                    <option key={option.code} value={option.code}>
                        {option.description}
                    </option>
                    ))}
                />
                <div className="mui-custom-form">
                  <label className="MuiFormLabel-root small-label no-margin">
                    Void
                  </label>
                  <div className="sub-radio">
                    <Radio
                      id="budgetyes"
                      value={true}
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : searchData.voidDate != null ||
                          searchData.voidIndicator
                      }
                      checked={data.voidIndicator === true}
                      onChange={handleChangeMainVoid}
                    />
                    <span className="text-black">
                      {' '}
                      <label for="budgetyes">Yes </label>
                    </span>
                    <Radio
                      value={true}
                      id="budgetno"
                      disabled={
                        !userInquiryPrivileges
                          ? !userInquiryPrivileges
                          : searchData.voidDate != null ||
                          searchData.voidIndicator
                      }
                      checked={data.voidIndicator === false}
                      onChange={handleChangeMainVoid}
                      className="ml-2"
                    />
                    <span className="text-black">
                      <label for="budgetno">No</label>
                    </span>
                  </div>
                </div>
              </div>
            </form>
            <div className="tab-panelbody m-0">
              <div className="tab-holder mb-3 mt-4">
                <AppBar position="static">
                  <Tabs
                    value={value}
                    onChange={handleChangeTabs}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Associated Fund Codes/COS" />
                    <Tab label="Notes" />
                  </Tabs>
                </AppBar>
                <TabPanel
                  value={value}
                  index={0}
                >
                  <div className="tab-holder p-0">
                    {value === 0 && (<div className="float-right my-3">
                      <Button
                        className="btn-text-main-delete btn-transparent  ml-1"
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : searchData.voidDate != null ||
                            searchData.voidIndicator
                        }
                        onClick={fCodeRowDeleteClick}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </Button>
                      <Button
                        className="btn-text-main-add btn-success ml-1"
                        disabled={
                          !userInquiryPrivileges
                            ? !userInquiryPrivileges
                            : searchData.voidDate != null ||
                            searchData.voidIndicator
                        }
                        onClick={() => {
                          handleClickOpen(null);
                        }}
                        title={'Add Fund Code'}
                      >
                        <i class="fa fa-plus" aria-hidden="true"></i>
                        Add
                      </Button>
                    </div>)}

                    <div className="clearfix"></div>
                      {value === 0 &&
                      (<div className="tab-holder p-0">
                        <BudgetAssociatedFundCodesTable
                          tableData={associatedFundCodeTable}
                          onRowClick={openEditModal}
                          rowDeleteFCode={rowDeleteFCode}
                          onClickAuditDialogLog={onClickAuditDialogLog}
                          uniqueKey={'sequenceNumber'}
                          setClose={setClose}
                          close={close}
                          dropdowns = {addDropdowns}
                        />
                      </div>)}

                    {showResults ? (
                      <div className="tabs-container" hidden={!showResults}>
                        <div className="tab-header mini-tab-header">
                          <div className="tab-heading float-left">
                            {addUpdateFundCode
                              ? 'Update Fund Code'
                              : 'Add Fund Code'}
                          </div>
                          <div className="float-right mt-1">
                            <Button
                              className="btn btn-success ml-1"
                              onClick={addFundCode}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.fundVoidDate != null
                              }
                            >
                              <i class="fa fa-check" aria-hidden="true"></i>
                              {addUpdateFundCode ? 'Update' : 'Add'}
                            </Button>
                            <Button
                              className="bt-reset btn-transparent ml-1"
                              onClick={ResetAddFundCodeForm}
                              disabled={
                                !userInquiryPrivileges
                                  ? !userInquiryPrivileges
                                  : values.fundVoidDate != null
                              }
                            >
                              <i className="fa fa-undo" aria-hidden="true"></i>

                              Reset
                            </Button>
                            <Button
                              variant="outlined" color="primary" className='btn btn-primary btn btn-outlined ml-1'
                              onClick={handleCloseAddFundcode}
                              title={'Cancel'}
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                        <div>
                          <form noValidate autoComplete="off">
                            <div className="form-wrapper">
                              <NativeDropDown
                                id="fund-code"
                                  label="Fund Code"
                                isRequired
                                value={fundCodeVal !== undefined ? values.fundCode : DEFAULT_DD_VALUE}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : values.fundVoidDate != null
                                  }
                                  onChange={handleChangeData('fundCode')}
                                errTxt={
                                    showFundCodeError
                                      ? BudgetMaintenanceConstants.ADD_FUND_CODE_ERROR
                                      : null
                                  }
                                  title={getLongDescription(fundCodeData, values.fundCode)}
                                  SelectProps={{}}
                                dropdownList={fundCodeData.map(option => (
                                  <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                  </option>
                                  ))}
                              />
                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="fundCodeBudgetAmount"
                                  fullWidth
                                  name="budgetAmount"
                                  label="Budget Amount"
                                  required
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : !!(
                                        (addUpdateFundCode && !values.uuId) ||
                                        values.fundVoidDate != null
                                      ),
                                    className: (!userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : !!(
                                        (addUpdateFundCode && !values.uuId) ||
                                        values.fundVoidDate != null
                                      ))
                                      ? 'Mui-disabled'
                                      : ''
                                  }}
                                  onInput={e => {
                                    e.target.value = e.target.value.replace(
                                      /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                                      ''
                                    );
                                  }}
                                  inputProps={{ maxLength: 12 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  value={values.budgetAmount || ''}
                                  onChange={handleChangeData('budgetAmount')}
                                  onBlur={formatDecimalForBudget}
                                  onFocus={removeFormatDecimalForBudget}
                                  helperText={
                                    showBudgetAmountError
                                      ? BudgetMaintenanceConstants.ADD_BUDGET_AMOUNT_ERROR
                                      : showBudgetAmountFormatError
                                        ? BudgetMaintenanceConstants.BUDGET_AMOUNT_FORMAT_ERROR
                                        : showBudgetLessThanZero
                                          ? BudgetMaintenanceConstants.BUDGET_LESS
                                          : null
                                  }
                                  error={
                                    showBudgetAmountError
                                      ? BudgetMaintenanceConstants.ADD_BUDGET_AMOUNT_ERROR
                                      : showBudgetAmountFormatError
                                        ? BudgetMaintenanceConstants.BUDGET_AMOUNT_FORMAT_ERROR
                                        : showBudgetLessThanZero
                                          ? BudgetMaintenanceConstants.BUDGET_LESS
                                          : null
                                  }
                                />
                              </div>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-date with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      '31 Dec 9999 00:00:00 GMT'
                                    )}
                                    required
                                    id="addFundingBeginDate"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : values.fundVoidDate != null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    placeholder="mm/dd/yyyy"
                                    value={fundCodeBeginDate}
                                    onChange={handleFundCodeBeginDate}
                                    helperText={
                                      showBeginDateError
                                        ? beginDateerrorText
                                        : null
                                    }
                                    error={
                                      showBeginDateError
                                        ? beginDateerrorText
                                        : null
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>

                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <div className="mui-custom-form input-date with-date">
                                  <KeyboardDatePicker
                                    maxDate={Date.parse(
                                      '31 Dec 9999 00:00:00 GMT'
                                    )}
                                    required
                                    id="endDate"
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : values.fundVoidDate != null
                                    }
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    placeholder="mm/dd/yyyy"
                                    value={fundCodeEndDate}
                                    onChange={handleFundCodeEndDate}
                                    helperText={
                                      showEndDateError ? endDateerrorText : null
                                    }
                                    error={
                                      showEndDateError ? endDateerrorText : null
                                    }
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date'
                                    }}
                                  />
                                </div>
                              </MuiPickersUtilsProvider>
                            </div>
                            <div className="form-wrapper">
                              <NativeDropDown
                                id="fundCodeCos"
                                  label="COS"
                                value={cosCodeVal !== undefined ? values.cos : DEFAULT_DD_VALUE}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : values.fundVoidDate != null
                                  }
                                  onChange={handleChangeData('cos')}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  SelectProps={{}}
                                  title={getLongDescription(cosData, values.cos)}
                                dropdownList={cosData.map((item, index) => (
                                  <option key={index} value={item.code}>
                                      {item.description}
                                  </option>
                                  ))}
                              />
                              <NativeDropDown
                                id="fundCodeACActivity"
                                  label="AC Activity"
                                  value={values.jobNumber || '-1'}
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : values.fundVoidDate != null
                                  }
                                  onChange={handleChangeData('jobNumber')}
                                errTxt={
                                    showACActivityError
                                      ? BudgetMaintenanceConstants.ADD_AC_ACTIVITY_ERROR
                                      : null
                                  }
                                  SelectProps={{}}
                                dropdownList={acActivityData.map(option => (
                                  <option
                                      key={option.code}
                                      value={option.code}
                                    >
                                      {option.description}
                                  </option>
                                  ))}
                              />
                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="fundCodeExpenditure"
                                  fullWidth
                                  name="expenditure"
                                  label="Expenditure"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.expenditureAmount ? numeral(values.expenditureAmount).format('0,0.00') || '' : ''}
                                  onChange={handleChangeData(
                                    'expenditureAmount'
                                  )}
                                />
                              </div>

                              <div className="mui-custom-form input-md">
                                <TextField
                                  id="fundCodeADJ"
                                  fullWidth
                                  name="adjustedAuthorized"
                                  disabled={
                                    !userInquiryPrivileges
                                      ? !userInquiryPrivileges
                                      : values.fundVoidDate != null || values.id
                                        ? true
                                        : !addUpdateFundCode && !values.id
                                          ? true
                                          : !!(addUpdateFundCode && values.id)
                                  }
                                  label="Adjusted Authorized"
                                  inputProps={{ maxLength: 12 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    )
                                  }}
                                  onInput={e => {
                                    e.target.value = e.target.value.replace(
                                      /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|<>/?~ ]/,
                                      ''
                                    );
                                  }}
                                  onBlur={adjAmountFormat}
                                  onFocus={removeAdjAmountFormat}
                                  value={values.adjustedAuthorizedAmount || ''}
                                  onChange={handleChangeData(
                                    'adjustedAuthorizedAmount'
                                  )}
                                  error={
                                    AdjustAmtError !== ''
                                      ? AdjustAmtError
                                      : null
                                  }
                                  helperText={
                                    AdjustAmtError !== ''
                                      ? AdjustAmtError
                                      : null
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-wrapper">
                              {/*   <div className="mui-custom-form input-sm">
                                <TextField
                                  id="Federal"
                                  fullWidth
                                  name="Federal"
                                  label="Federal"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.federal ? numeral(values.federal).format('0,0.00') || '' : ''}
                                  onChange={handleChangeData('federal')}
                                />
                              </div>
                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="State"
                                  fullWidth
                                  name="State"
                                  label="State"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.state ? numeral(values.state).format('0,0.00') || '' : ''}
                                  onChange={handleChangeData('state')}
                                />
                              </div>
                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="County"
                                  fullWidth
                                  name="County"
                                  label="County"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.county ? numeral(values.county).format('0,0.00') || '' : ''}
                                  onChange={handleChangeData('county')}
                                />
                              </div>
                              <div className="mui-custom-form input-sm">
                                <TextField
                                  id="other"
                                  fullWidth
                                  name="other"
                                  label="Other"
                                  inputProps={{ maxLength: 11 }}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                    readOnly: true,
                                    className: 'Mui-disabled'
                                  }}
                                  onInput={e => {
                                    e.target.value = Math.max(
                                      0,
                                      parseFloat(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 12);
                                  }}
                                  value={values.other ? numeral(values.other).format('0,0.00') || '' : ''}
                                  onChange={handleChangeData('other')}
                                />
                              </div> */}
                              <div
                                className="mui-custom-form"
                                style={{ lineHeight: '100%' }}
                              >
                                <label className="MuiFormLabel-root small-label">
                                  In-Kind Indicator
                                </label>
                                <div className="sub-radio">
                                  <Radio
                                    value={'Y'}
                                    id="inkindyes"
                                    name="eombType"
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : values.fundVoidDate != null
                                    }
                                    checked={values.inKindIndicator === 'Y'}
                                    onChange={handleChangeData(
                                      'inKindIndicator'
                                    )}
                                  />
                                  <span className="text-black">
                                    {' '}
                                    <label
                                      for="inkindyes"
                                      style={{ fontSize: '14px' }}
                                    >
                                      {' '}
                                      Yes{' '}
                                    </label>
                                  </span>
                                  <Radio
                                    value={'N'}
                                    className="ml-2"
                                    name="eombType"
                                    id="inkindno"
                                    disabled={
                                      !userInquiryPrivileges
                                        ? !userInquiryPrivileges
                                        : values.fundVoidDate != null
                                    }
                                    checked={values.inKindIndicator === 'N'}
                                    onChange={handleChangeData(
                                      'inKindIndicator'
                                    )}
                                  />
                                  <span className="text-black">
                                    {' '}
                                    <label
                                      for="inkindno"
                                      style={{ fontSize: '14px' }}
                                    >
                                      {' '}
                                      No{' '}
                                    </label>{' '}
                                  </span>
                                </div>
                              </div>
                              {selectedFundCodeRow &&
                                !selectedFundCodeRow.id ? (
                                  <div
                                    className="mui-custom-form"
                                    style={{ lineHeight: '100%' }}
                                  >
                                    <label className="MuiFormLabel-root small-label">
                                      Void
                                    </label>
                                    <div className="sub-radio">
                                      <Radio
                                        value={true}
                                        disabled={
                                          !userInquiryPrivileges
                                            ? !userInquiryPrivileges
                                            : values.fundVoidDate != null
                                        }
                                        checked={voids.codeVoidIndicator === true}
                                        id="voidconyes"
                                        onChange={handleChangeCodeVoid}
                                      />
                                      <span className="text-black">
                                        {' '}
                                        <label for="voidconyes"> Yes </label>
                                      </span>
                                      <Radio
                                        id="voidcontno"
                                        value={false}
                                        disabled={
                                          !userInquiryPrivileges
                                            ? !userInquiryPrivileges
                                            : values.fundVoidDate != null
                                        }
                                        checked={
                                          voids.codeVoidIndicator === false
                                        }
                                        onChange={handleChangeCodeVoid}
                                        className="ml-2"
                                      />
                                      <span className="text-black">
                                        {' '}
                                        <label for="voidcontno"> No </label>
                                      </span>
                                    </div>
                                  </div>
                                ) : null}
                            </div>
                            <div className="float-right my-3">
                              <Button
                                className="btn-text-main-delete btn-transparent  ml-1"
                                onClick={fSourceRowDeleteClick}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.fundVoidDate != null
                                }
                                title={'Delete'}
                              >
                                <i class="fa fa-trash" aria-hidden="true"></i>
                                Delete
                              </Button>
                              <Button
                                className="btn-text-main-add btn-success ml-1"
                                onClick={handleFundingSourceOpen}
                                disabled={
                                  !userInquiryPrivileges
                                    ? !userInquiryPrivileges
                                    : values.fundVoidDate != null
                                }
                                title={'Add Funding Source'}
                                data-testid="funding_source_add_btn"
                              >
                                <i class="fa fa-plus" aria-hidden="true"></i>
                                Add
                              </Button>
                            </div>
                            <div className="clearfix"></div>
                          </form>
                          <div className="tab-holder p-0">
                            <BudgetFundingSourceTable
                              tableData={newDialogData}
                              onRowClick={editFundingSource}
                              rowDeleteFSource={rowDeleteFSource}
                            />
                            {
                              open
                                ? <div className="tabs-container" id='form_pop_up'>
                                  <div className="tab-header  mini-tab-header">
                                    <div className="tab-heading float-left">
                                      {selectedFSourceRow
                                        ? 'Edit Funding Source'
                                        : 'Add Funding Source'}
                                    </div>
                                    <div className="float-right mt-1">
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        className="btn btn-success ml-1"
                                        disabled={
                                          !userInquiryPrivileges
                                            ? !userInquiryPrivileges
                                            : dataElement.fundSourceVoidDate != null
                                        }
                                        onClick={saveFundingSourceData}
                                        data-testid="funding_source_save_btn"
                                      >
                                        <i class="fa fa-check" aria-hidden="true"></i>
                                        {selectedFSourceRow ? 'Update' : 'Add'}
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        className="bt-reset btn-transparent ml-1"
                                        disabled={
                                          !userInquiryPrivileges
                                            ? !userInquiryPrivileges
                                            : dataElement.fundSourceVoidDate != null
                                        }
                                        onClick={resetFundingSourceData}
                                        data-testid="funding_source_reset_btn"
                                      >
                                        <i className="fa fa-undo" aria-hidden="true"></i>

                                Reset
                                      </Button>
                                      <Button
                                        variant="outlined" color="primary" className='btn btn-primary btn btn-outlined ml-1'
                                        onClick={handleCloseFundigSource}
                                        title={'Cancel'}
                                      >
                                  Cancel
                                      </Button>
                                    </div>
                                  </div>
                                  <div>
                                    <form noValidate autoComplete="off">
                                      <div className="form-wrapper">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <div className="mui-custom-form input-date with-date">
                                            <KeyboardDatePicker
                                              maxDate={Date.parse(
                                                '31 Dec 9999 00:00:00 GMT'
                                              )}
                                              required
                                              id="selectedStartDate"
                                              label="Begin Date"
                                              format="MM/dd/yyyy"
                                              InputLabelProps={{
                                                shrink: true
                                              }}
                                              disabled={
                                                !userInquiryPrivileges
                                                  ? !userInquiryPrivileges
                                                  : dataElement.fundSourceVoidDate !=
                                            null ||
                                            dataElement.pastDateDisable
                                              }
                                              placeholder="mm/dd/yyyy"
                                              value={selectedStartDate}
                                              onChange={handleStartDateChange}
                                              helperText={
                                                showFSourceBeginDateError
                                                  ? beginDateerrorText
                                                  : null
                                              }
                                              error={
                                                showFSourceBeginDateError
                                                  ? beginDateerrorText
                                                  : null
                                              }
                                              KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                              }}
                                            />
                                          </div>
                                        </MuiPickersUtilsProvider>

                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                          <div className="mui-custom-form input-date with-date">
                                            <KeyboardDatePicker
                                              maxDate={Date.parse(
                                                '31 Dec 9999 00:00:00 GMT'
                                              )}
                                              required
                                              id="selectedEndDate"
                                              label="End Date"
                                              format="MM/dd/yyyy"
                                              InputLabelProps={{
                                                shrink: true
                                              }}
                                              disabled={
                                                !userInquiryPrivileges
                                                  ? !userInquiryPrivileges
                                                  : dataElement.fundSourceVoidDate !=
                                            null
                                              }
                                              placeholder="mm/dd/yyyy"
                                              value={selectedEndDate}
                                              onChange={handleEndDateChange}
                                              helperText={
                                                showFSourceEndDateError
                                                  ? endDateerrorText
                                                  : null
                                              }
                                              error={
                                                showFSourceEndDateError
                                                  ? endDateerrorText
                                                  : null
                                              }
                                              KeyboardButtonProps={{
                                                'aria-label': 'change date'
                                              }}
                                            />
                                          </div>
                                        </MuiPickersUtilsProvider>

                                        <div className="mui-custom-form">
                                          <TextField
                                            id="federal"
                                            fullWidth
                                            label="Federal"
                                            required
                                            disabled={
                                              !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : dataElement.fundSourceVoidDate !=
                                          null
                                            }
                                            onInput={e => {
                                              e.target.value = e.target.value.replace(
                                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                                ''
                                              );
                                            }}
                                            inputProps={{ maxLength: 8 }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                            %
                                                </InputAdornment>
                                              )
                                            }}
                                            value={
                                              dataElement.federalPercentage || ''
                                            }
                                            onChange={handleChangeDataElement(
                                              'federalPercentage'
                                            )}
                                            onBlur={handleBlurPEr('federalPercentage')}
                                            helperText={
                                              showFederalError
                                                ? BudgetMaintenanceConstants.ADD_FEDERAL_ERROR
                                                : showFederalFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                            error={
                                              showFederalError
                                                ? BudgetMaintenanceConstants.ADD_FEDERAL_ERROR
                                                : showFederalFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                          />
                                        </div>
                                        <div className="mui-custom-form">
                                          <TextField
                                            id="state"
                                            fullWidth
                                            label="State"
                                            required
                                            disabled={
                                              !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : dataElement.fundSourceVoidDate !=
                                          null
                                            }
                                            onInput={e => {
                                              e.target.value = e.target.value.replace(
                                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                                ''
                                              );
                                            }}
                                            inputProps={{ maxLength: 8 }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                            %
                                                </InputAdornment>
                                              )
                                            }}
                                            value={dataElement.statePercentage || ''}
                                            onChange={handleChangeDataElement(
                                              'statePercentage'
                                            )}
                                            onBlur={handleBlurPEr('statePercentage')}
                                            helperText={
                                              showStateError
                                                ? BudgetMaintenanceConstants.ADD_STATE_ERROR
                                                : showStateFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                            error={
                                              showStateError
                                                ? BudgetMaintenanceConstants.ADD_STATE_ERROR
                                                : showStateFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                          />
                                        </div>

                                      </div>

                                      <div className="form-wrapper">

                                        <div className="mui-custom-form input-sm">
                                          <TextField
                                            id="county"
                                            fullWidth
                                            label="County"
                                            required
                                            disabled={
                                              !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : dataElement.fundSourceVoidDate !=
                                          null
                                            }
                                            onInput={e => {
                                              e.target.value = e.target.value.replace(
                                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                                ''
                                              );
                                            }}
                                            inputProps={{ maxLength: 8 }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                            %
                                                </InputAdornment>
                                              )
                                            }}
                                            value={dataElement.countyPercentage || ''}
                                            onChange={handleChangeDataElement(
                                              'countyPercentage'
                                            )}
                                            onBlur={handleBlurPEr('countyPercentage')}
                                            helperText={
                                              showCountyError
                                                ? BudgetMaintenanceConstants.ADD_COUNTY_ERROR
                                                : showCountyFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                            error={
                                              showCountyError
                                                ? BudgetMaintenanceConstants.ADD_COUNTY_ERROR
                                                : showCountyFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                          />
                                        </div>

                                        <div className="mui-custom-form input-sm">
                                          <TextField
                                            id="otherbudgetcontent"
                                            fullWidth
                                            label="Other"
                                            required
                                            disabled={
                                              !userInquiryPrivileges
                                                ? !userInquiryPrivileges
                                                : dataElement.fundSourceVoidDate !=
                                          null
                                            }
                                            onInput={e => {
                                              e.target.value = e.target.value.replace(
                                                /[a-zA-Z!@#$%^&*()_+=[\]{};':"\\|,<>/?~ ]/,
                                                ''
                                              );
                                            }}
                                            inputProps={{ maxLength: 8 }}
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                            %
                                                </InputAdornment>
                                              )
                                            }}
                                            value={dataElement.otherPercentage || ''}
                                            onChange={handleChangeDataElement(
                                              'otherPercentage'
                                            )}
                                            onBlur={handleBlurPEr('otherPercentage')}
                                            helperText={
                                              showOtherError
                                                ? BudgetMaintenanceConstants.ADD_OTHER_ERROR
                                                : showOtherFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                            error={
                                              showOtherError
                                                ? BudgetMaintenanceConstants.ADD_OTHER_ERROR
                                                : showOtherFormat
                                                  ? BudgetMaintenanceConstants.DATA_INVALID
                                                  : null
                                            }
                                          />
                                        </div>
                                        <div className="mui-custom-form input-sm">
                                          <TextField
                                            id="total"
                                            fullWidth
                                            label="Total"
                                            required
                                            type="number"
                                            inputProps={{ maxLength: 7 }}
                                            disabled
                                            InputLabelProps={{
                                              shrink: true
                                            }}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                            %
                                                </InputAdornment>
                                              )
                                            }}
                                            onInput={e => {
                                              e.target.value = Math.max(
                                                0,
                                                parseFloat(e.target.value)
                                              )
                                                .toString()
                                                .slice(0, 7);
                                            }}
                                            value={parseFloat(dataElement.totalPercentage).toFixed(2) || ''}
                                            onChange={handleChangeDataElement(
                                              'totalPercentage'
                                            )}
                                            onBlur={handleBlurPEr('totalPercentage')}
                                            helperText={
                                              showTotalPercentageError
                                                ? BudgetMaintenanceConstants.TOTAL_PERCENTAGE_ERROR
                                                : null
                                            }
                                            error={
                                              showTotalPercentageError
                                                ? BudgetMaintenanceConstants.TOTAL_PERCENTAGE_ERROR
                                                : null
                                            }
                                          />
                                        </div>
                                      </div>

                                      <div className="form-wrapper">
                                        {selectedFSourceRow &&
                                    !selectedFSourceRow.id ? (
                                            <div className="mui-custom-form override-width-28">
                                              <div>
                                                <label className="MuiFormLabel-root small-label no-margin">
                                            Void
                                                </label>
                                                <div className="sub-radio">
                                                  <Radio
                                                    id="fsourceyes"
                                                    value={true}
                                                    disabled={
                                                      !userInquiryPrivileges
                                                        ? !userInquiryPrivileges
                                                        : dataElement.fundSourceVoidDate !=
                                                  null
                                                    }
                                                    checked={
                                                      voids.sourceVoidIndicator === true
                                                    }
                                                    onChange={handleChangeSourceVoid}
                                                  />
                                                  <label for="fsourceyes" className="text-black">
                                              Yes
                                                  </label>
                                                  <Radio
                                                    id="fsourceno"
                                                    value={false}
                                                    checked={
                                                      voids.sourceVoidIndicator ===
                                                false
                                                    }
                                                    disabled={
                                                      !userInquiryPrivileges
                                                        ? !userInquiryPrivileges
                                                        : dataElement.fundSourceVoidDate !=
                                                  null
                                                    }
                                                    onChange={handleChangeSourceVoid}
                                                    className="ml-2"
                                                  />
                                                  <label for="fsourceno" className="text-black">No</label>
                                                </div>
                                              </div>
                                            </div>
                                          ) : null}
                                      </div>
                                    </form>
                                  </div>
                                </div> : null }
                            {addUpdateFundCode ? (
                              <div>
                                <AppBar position="static">
                                  <Tabs
                                    value={value}
                                    onChange={handleChangeHistoryTabs}
                                    aria-label="simple tabs example"
                                  >
                                    <Tab label="History" />
                                  </Tabs>
                                </AppBar>
                                <TabPanel value={historyvalue} index={0}>
                                  <h>Fund Code / Adjusted Authorized History</h>
                                  <div className="table-no-wrap">
                                    <FundCodeHistoryTable
                                      tableData={codeHistoryData}
                                    >
                                    </FundCodeHistoryTable>
                                  </div>
                                  Funding source History
                                  <FundingSourceHistoryTable
                                    tableData={sourceHistoryData}
                                  ></FundingSourceHistoryTable>
                                </TabPanel>{' '}
                              </div>
                            ) : null}
                          </div>

                        </div>
                      </div>
                    ) : null}
                  </div>
                  {
                    (showLogDialogTable)
                      ? (<div id='audit-dialog-table'>
                        <AuditTableComponent
                          print
                          auditRowData={auditTableData}
                          setAuditRowData={setAuditTableData}
                          setShowLogTable={setShowLogDialogTable}
                          setClose={setClose}
                        /> </div>) : null
                  }
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}>
                  <div className="tab-holder my-3 p-0">
                    <Notes
                      disableNotes={disableNotes}
                      addNotes={addNotes}
                      setFocusCheck={setFocusCheck}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      setErrorMessages={seterrorMessages}
                      isNotesTable={true}
                      userInquiryPrivileges = {userInquiryPrivileges}
                    />
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
        {
          showLogTable
            ? (<div id='audit-table'>
              <AuditTableComponent
                print
                auditRowData={auditRowData}
                setAuditRowData={setAuditRowData}
                setShowLogTable={setShowLogTable}
              /> </div>) : null
        }
        <Footer print />
        <Dialog
          open={dialogOpen}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="custom-alert-box"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
              </>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDialogOpen(false);
              }}
			                 color="primary"
              className="btn btn-transparent"
            >
                  STAY ON THIS PAGE!
            </Button>
            <Button
              onClick={cancelMinorSection}
              color="primary"
              className="btn btn-success"
              autoFocus
            >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div >
  );
}

export default withRouter(
  NavHOC({
    Wrapped: BudgetMaintenanceUpdate,
    action: searchBudgetMaintenanceIDAction,
    selector: 'budgetMaintenanceState.searchBudgetID',
    url: '/BudgetDataUpdate'
  })
);
