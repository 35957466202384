/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../SharedModules/Spinner/Spinner";
import ErrorMessages from "../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from '../../SharedModules/Errors/SuccessMessage';
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { updateWaiverAction, searchWaiverction, createWaiverAction, ResetWVR } from './Store/Actions/WaiverActions';
import * as WaiverAddConstants from './WaiverConstants';
import { generateUUID, getDateInMMDDYYYYFormat, getUTCTimeStamp, compareTwoDatesGreaterThanOrEqual } from '../../SharedModules/DateUtilities/DateUtilities';
import NavHOC from "../../SharedModules/Navigation/NavHOC";
import NoSaveMessage from '../../SharedModules/Errors/NoSaveMessage';
import { Prompt } from 'react-router-dom';

function WaiverAdd(props) {
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  
  const [spinnerLoader, setspinnerLoader] = useState(false);
  const [errorMessages, seterrorMessages] = useState([]);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [waiverState, setWaiverState] = useState({
    wvrLiCd: '',
    cms64Cd: '',
    wvrLiCdDesc: '',
    priorityNumber: '',
    wvrName: '',
    cms64Desc: '',
    bgnWvrCd: '',
    endWvrCd: '',
    bgnProcCd: '',
    endProcCd: '',
    pocModCd1: '',
    pocModCd2: '',
    pocModCd3: '',
    pocModCd4: '',
    comments: ''
  });
  const [searchObj, setSearchObj] = useState({});
  const [showError, setShowError] = useState({
    wvrLiCdError: false,
    cms64CdError: false,
    wvrLiCdDescError: false,
    priorityNumError: false,
    wvrNameError: false,
    cms64CdDescError: false,
    bgnWvrCdError: false,
    endWvrCdError: false,
    bgnProcCdError: false,
    endProcCdError: false,
    bgnDateError: false,
    endDateError: false
  });
  const [showErrorMessage, setShowErrorMessage] = useState({
    wvrLiCdErrorMsg: '',
    cms64CdErrorMsg: '',
    wvrLiCdDescErrorMsg: '',
    priorityNumberErrorMsg: '',
    wvrNameErrorMsg: '',
    cms64CdDescErrorMsg: '',
    bgnWvrCdErrorMsg: '',
    endWvrCdErrorMsg: '',
    bgnProcCdErrorMsg: '',
    endProcCdErrorMsg: '',
    bgnDateErrorMsg: '',
    endDateErrorMsg: ''
  });
  const [successMessages, setSuccessMessages] = useState([]);
  const [editPage, setEditPage] = useState(false);
  const createWaiver = (values) => dispatch(createWaiverAction(values));
  const updateWaiver = (values) => dispatch(updateWaiverAction(values));
  let createSuccess = useSelector(state => state.waiverState.createPayload);
  let updateSuccess = useSelector(state => state.waiverState.updatePayload);
  const searchWaiver = (values) => dispatch(searchWaiverction(values));
  let searchSuccess = {}
  searchSuccess = useSelector(state => state.waiverState.searchPayload);
  const [selectedBeginDate, setSelectedBeginDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null)
  let sucessArray = [];
  const handleBeginDate = date => {
    setAllowNavigation(true);
    setSelectedBeginDate(date)
  }
  const handleEndDate = date => {
    setSelectedEndDate(date)
    setAllowNavigation(true);
  }
  useEffect(() => {
    if (!props.location.state) {
      setEditPage(false);
    }
    if (props.location.state) {
      if (props.location.state.successMessages && !props.location.state.fromSearch) {
        const tempVar = JSON.parse(JSON.stringify(props.location.state.successMessages));
        setSuccessMessages(tempVar);
      } else {
        setSuccessMessages([]);
      }
    }

  }, []);

  useEffect(() => {
    ResetWVR('createPayload');
    ResetWVR('searchPayload');
    ResetWVR('updatePayload');
    searchSuccess = null;
    createSuccess = null;
    updateSuccess = null;
  }, [])
  useEffect(() => {
    setspinnerLoader(false);
    if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 1) {
      let searchData = searchSuccess;
      let successMessagesArray = [];
      successMessagesArray.push('System successfully saved the information.');
      props.history.push({
        pathname: '/WaiverUpdate',
        state: { searchData: searchData, successMessages: successMessagesArray, search: false }
      });
    } else if (searchSuccess && searchSuccess.systemFailure) {
      let ErrorMessages = [];
      ErrorMessages.push(WaiverAddConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(ErrorMessages);
    }
  }, [searchSuccess]);
  let successMessagesArray = [];
  useEffect(() => {
    setspinnerLoader(false);
    if (createSuccess && createSuccess.respcode === '01') {
      successMessagesArray.push(createSuccess.respdesc);
      setSuccessMessages(successMessagesArray);
      sucessArray = successMessagesArray;
      let searchWaiverOb = {
        "wvrLineCd": waiverState.wvrLiCd,
        "wvrLineCddesc": waiverState.wvrLiCdDesc,
        "wvrLineCdDescStartsOrContains": 0,
        "cms64TosCd": waiverState.cms64Cd,
        "cms64TosCdDescription": waiverState.cms64Desc,
        "cms64TosDescriptionStartsOrContains": 0,
        "priorityNumber": waiverState.priorityNumber,
        "begWvrCd": waiverState.bgnWvrCd,
        "endWvrCd": waiverState.endWvrCd,
        "waiverName": waiverState.wvrName
      }
      searchWaiver(searchWaiverOb);
    } else if (createSuccess && createSuccess.systemFailure) {
      let ErrorMessages = [];
      ErrorMessages.push(WaiverAddConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(ErrorMessages);
    }

  }, [createSuccess]);
  useEffect(() => {
    setspinnerLoader(false);
    if (updateSuccess && updateSuccess.respcode === '01') {
      successMessagesArray.push(updateSuccess.respdesc);
      setSuccessMessages(successMessagesArray);
      sucessArray = successMessagesArray
      let searchWaiverOb = {
        wvrSk: searchObj.waiverLookUpSeqNo
      }
      searchWaiver(searchWaiverOb);
    } else if (updateSuccess && updateSuccess.systemFailure) {
      let ErrorMessages = [];
      ErrorMessages.push(WaiverAddConstants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(ErrorMessages);
    }

  }, [updateSuccess]);
  let searchObject = {}
  useEffect(() => {
    if (searchSuccess === {}) {
      props.location.state = {}
    }
    if (props.history.location.state && props.history.location.state.searchData) {
      setEditPage(true);
      searchObject = props.location.state.searchData[0];
      setSearchObj(searchObject);
      setSelectedBeginDate(searchObject.begDt);
      setSelectedEndDate(searchObject.endDt);
      setWaiverState({
        wvrName: searchObject.waiverName,
        wvrLiCdDesc: searchObject.wvrLineCddesc,
        wvrLiCd: searchObject.wvrLineCd,
        cms64Cd: searchObject.cms64TosCd,
        cms64Desc: searchObject.cms64TosCdDescription,
        priorityNumber: searchObject.priorityNumber,
        bgnWvrCd: searchObject.begWvrCd,
        endWvrCd: searchObject.endWvrCd,
        bgnProcCd: searchObject.begProcCd,
        endProcCd: searchObject.endProcCd,
        pocModCd1: searchObject.procModCd1,
        pocModCd2: searchObject.procModCd2,
        pocModCd3: searchObject.procModCd3,
        pocModCd4: searchObject.procModCd4,
        comments: searchObject.comments

      })
    }


  }, [props.history.location.state])

  const handleChange = name => event => {
    setAllowNavigation(true);
    setWaiverState({
      ...waiverState,
      [name]: event.target.value
    })
  }


  const logInUserID = useSelector(state => state.sharedState.logInUserID);


  const saveWaiverLine = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    let wvrLiCdError = false; let cms64CdDescError = false; let wvrLiCdDescError = false;
    let priorityNumError = false; let wvrNameError = false; let cms64CdError = false;
    let bgnWvrCdError = false; let endWvrCdError = false; let bgnProcCdError = false;
    let endProcCdError = false; let bgnDateError = false; let endDateError = false;
    let wvrLiCdErrorMsg = ''; let cms64CdDescErrorMsg = ''; let wvrLiCdDescErrorMsg = '';
    let priorityNumberErrorMsg = ''; let wvrNameErrorMsg = ''; let cms64CdErrorMsg = '';
    let bgnWvrCdErrorMsg = ''; let endWvrCdErrorMsg = ''; let bgnProcCdErrorMsg = '';
    let endProcCdErrorMsg = ''; let bgnDateErrorMsg = ''; let endDateErrorMsg = '';
    let errorMessages = [];
    if (waiverState.wvrLiCd === '') {
      wvrLiCdError = true;
      wvrLiCdErrorMsg = WaiverAddConstants.WVRLICD;
      errorMessages.push(wvrLiCdErrorMsg);
    }
    if (waiverState.cms64Cd === '') {
      cms64CdError = true;
      cms64CdErrorMsg = WaiverAddConstants.CMS64CD;
      errorMessages.push(cms64CdErrorMsg);
    }
    if (waiverState.wvrLiCdDesc === '') {
      wvrLiCdDescError = true;
      wvrLiCdDescErrorMsg = WaiverAddConstants.WVRLICDDESC;
      errorMessages.push(wvrLiCdDescErrorMsg);
    }
    if (waiverState.priorityNumber === '') {
      priorityNumError = true;
      priorityNumberErrorMsg = WaiverAddConstants.PRIORITY_NUMB;
      errorMessages.push(priorityNumberErrorMsg);
    }
    if (waiverState.cms64Desc === '') {
      cms64CdDescError = true;
      cms64CdDescErrorMsg = WaiverAddConstants.CMS64CDDESC;
      errorMessages.push(cms64CdDescErrorMsg);
    }
    if (waiverState.wvrName === '') {
      wvrNameError = true;
      wvrNameErrorMsg = WaiverAddConstants.WAIVERNAME;
      errorMessages.push(wvrLiCdDescErrorMsg);
    }
    if (waiverState.bgnWvrCd === '') {
      bgnWvrCdError = true;
      bgnWvrCdErrorMsg = WaiverAddConstants.BGNWVRCD;
      errorMessages.push(bgnWvrCdErrorMsg);
    }
    if (waiverState.endWvrCd === '') {
      endWvrCdError = true;
      endWvrCdErrorMsg = WaiverAddConstants.ENDWVRCD;
      errorMessages.push(endWvrCdErrorMsg);
    }
    if (waiverState.endWvrCd && waiverState.bgnWvrCd && !isNaN(waiverState.bgnWvrCd) && !isNaN(waiverState.endWvrCd) && Number(waiverState.bgnWvrCd) > Number(waiverState.endWvrCd)) {
      bgnWvrCdError = true;
      bgnWvrCdErrorMsg = WaiverAddConstants.ENDWVRCDINVALID;
      errorMessages.push(bgnWvrCdErrorMsg);
    } else if (waiverState.endWvrCd && waiverState.bgnWvrCd && (waiverState.bgnWvrCd) > (waiverState.endWvrCd)) {
      bgnWvrCdError = true;
      bgnWvrCdErrorMsg = WaiverAddConstants.ENDWVRCDINVALID;
      errorMessages.push(bgnWvrCdErrorMsg);
    }
    if (waiverState.bgnProcCd === '') {
      bgnProcCdError = true;
      bgnProcCdErrorMsg = WaiverAddConstants.BGNPROCCD;
      errorMessages.push(bgnProcCdErrorMsg);
    }
    if (waiverState.endProcCd === '') {
      endProcCdError = true;
      endProcCdErrorMsg = WaiverAddConstants.ENDPROCCD;
      errorMessages.push(endProcCdErrorMsg);
    }
    if (waiverState.bgnProcCd && waiverState.endProcCd && !isNaN(waiverState.bgnProcCd) && !isNaN(waiverState.endProcCd) && Number(waiverState.bgnProcCd) > Number(waiverState.endProcCd)) {
      bgnProcCdError = true;
      bgnProcCdErrorMsg = WaiverAddConstants.ENDPRCCDINVALID;
      errorMessages.push(bgnProcCdErrorMsg);
    } else if (waiverState.bgnProcCd && waiverState.endProcCd && (waiverState.bgnProcCd) > (waiverState.endProcCd)) {
      bgnProcCdError = true;
      bgnProcCdErrorMsg = WaiverAddConstants.ENDPRCCDINVALID;
      errorMessages.push(bgnProcCdErrorMsg);
    }
    if (selectedBeginDate === null) {
      bgnDateError = true;
      bgnDateErrorMsg = WaiverAddConstants.BGNDTREQ;
      errorMessages.push(bgnDateErrorMsg)
    }
    if (selectedEndDate === null) {
      endDateError = true;
      endDateErrorMsg = WaiverAddConstants.ENDDTREQ;
      errorMessages.push(endDateErrorMsg)
    }
    if (selectedEndDate && selectedEndDate.toString() === 'Invalid Date') {
      endDateError = true;
      endDateErrorMsg = WaiverAddConstants.INVALID_DATE;
      errorMessages.push(endDateErrorMsg);
    }
    if (selectedBeginDate && selectedBeginDate.toString() === 'Invalid Date') {
      bgnDateError = true;
      bgnDateErrorMsg = WaiverAddConstants.INVALID_DATE;
      errorMessages.push(bgnDateErrorMsg)
    }
    if (selectedBeginDate && selectedEndDate && !bgnDateError && !endDateError &&
      compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedBeginDate)) {
      endDateError = true;
      endDateErrorMsg = WaiverAddConstants.END_DATE_GREATER_THAN_BG_DT;
      errorMessages.push(endDateErrorMsg);
    }
    seterrorMessages(errorMessages);
    setShowError({
      wvrLiCdError: wvrLiCdError,
      cms64CdError: cms64CdError,
      wvrLiCdDescError: wvrLiCdDescError,
      priorityNumError: priorityNumError,
      wvrNameError: wvrNameError,
      cms64CdDescError: cms64CdDescError,
      bgnWvrCdError: bgnWvrCdError,
      endWvrCdError: endWvrCdError,
      bgnProcCdError: bgnProcCdError,
      endProcCdError: endProcCdError,
      bgnDateError: bgnDateError,
      endDateError: endDateError
    });
    setShowErrorMessage({
      wvrLiCdErrorMsg: wvrLiCdErrorMsg,
      cms64CdErrorMsg: cms64CdErrorMsg,
      wvrLiCdDescErrorMsg: wvrLiCdDescErrorMsg,
      priorityNumberErrorMsg: priorityNumberErrorMsg,
      wvrNameErrorMsg: wvrNameErrorMsg,
      cms64CdDescErrorMsg: cms64CdDescErrorMsg,
      bgnWvrCdErrorMsg: bgnWvrCdErrorMsg,
      endWvrCdErrorMsg: endWvrCdErrorMsg,
      bgnProcCdErrorMsg: bgnProcCdErrorMsg,
      endProcCdErrorMsg: endProcCdErrorMsg,
      bgnDateErrorMsg: bgnDateErrorMsg,
      endDateErrorMsg: endDateErrorMsg
    });

    if (!allowNavigation && editPage) {
      NoSaveMessage();
    } else {
      if (errorMessages.length === 0) {
        setAllowNavigation(false);
        setspinnerLoader(true);
        if (!editPage) {
          let waiverObject = {
            "auditUserID": logInUserID,
            "auditTimeStamp": getUTCTimeStamp(),
            "addedAuditUserID": logInUserID,
            "addedAuditTimeStamp": getUTCTimeStamp(),
            "versionNo": 0,
            "dbRecord": false,
            "sortColumn": null,
            "auditKeyList": [],
            "auditKeyListFiltered": false,
            "waiverLookUpSeqNo": null,
            "wvrLineSortKey": "",
            "wvrLineCd": waiverState.wvrLiCd,
            "wvrLineCddesc": waiverState.wvrLiCdDesc,
            "cms64TosCd": waiverState.cms64Cd,
            "cms64TosCdDescription": waiverState.cms64Desc,
            "priorityNumber": waiverState.priorityNumber,
            "endWvrCd": waiverState.endWvrCd,
            "waiverName": waiverState.wvrName,
            "begProcCd": waiverState.bgnProcCd,
            "endProcCd": waiverState.endProcCd,
            "procModCd1": waiverState.pocModCd1 === '' ? null : waiverState.pocModCd1,
            "procModCd2": waiverState.pocModCd2 === '' ? null : waiverState.pocModCd2,
            "procModCd3": waiverState.pocModCd3 === '' ? null : waiverState.pocModCd3,
            "procModCd4": waiverState.pocModCd4 === '' ? null : waiverState.pocModCd4,
            "begDt": getDateInMMDDYYYYFormat(selectedBeginDate),
            "endDt": getDateInMMDDYYYYFormat(selectedEndDate),
            "comments": waiverState.comments === '' ? null : waiverState.comments,
            "begWvrCd": waiverState.bgnWvrCd,
          }
          createWaiver(waiverObject);
        } else if (editPage) {
          let waiverObject = {
            "auditUserID": logInUserID,
            "auditTimeStamp": getUTCTimeStamp(),
            "addedAuditUserID": logInUserID,
            "addedAuditTimeStamp": getUTCTimeStamp,
            "versionNo": searchObj.versionNo,
            "dbRecord": false,
            "sortColumn": null,
            "auditKeyList": [],
            "auditKeyListFiltered": false,
            "waiverLookUpSeqNo": searchObj.waiverLookUpSeqNo,
            "wvrLineSortKey": searchObj.wvrLineSortKey,
            "wvrLineCd": waiverState.wvrLiCd,
            "wvrLineCddesc": waiverState.wvrLiCdDesc,
            "cms64TosCd": waiverState.cms64Cd,
            "cms64TosCdDescription": waiverState.cms64Desc,
            "priorityNumber": +waiverState.priorityNumber,
            "begWvrCd": waiverState.bgnWvrCd,
            "endWvrCd": waiverState.endWvrCd,
            "waiverName": waiverState.wvrName,
            "begProcCd": waiverState.bgnProcCd,
            "endProcCd": waiverState.endProcCd,
            "procModCd1": waiverState.pocModCd1,
            "procModCd2": waiverState.pocModCd2,
            "procModCd3": waiverState.pocModCd3,
            "procModCd4": waiverState.pocModCd4,
            "begDt": getDateInMMDDYYYYFormat(selectedBeginDate),
            "endDt": getDateInMMDDYYYYFormat(selectedEndDate),
            "comments": waiverState.comments
          }
          updateWaiver(waiverObject);
        }
      }
    }
  }

  return (
    <div className="pos-relative w-100 h-100">

      <div className="tabs-container" ref={toPrintRef}>
        {spinnerLoader ? <Spinner /> : null}
        <Prompt
          when={allowNavigation}
          message={location => `Are you sure you want to go to ${location.pathname}`}
        />
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? <SuccessMessages successMessages={successMessages} /> : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">{editPage ? 'Update Waiver Line' : 'Add Waiver Line'}</h1>

          <div className="float-right mt-1 pt-1">
            <Button
              className="btn btn-success ml-1"
              onClick={() => saveWaiverLine()}
            >
              <i class="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="Waiver Line Code"
                  value={waiverState.wvrLiCd}
                  onChange={handleChange('wvrLiCd')}
                  inputProps={{ maxlength: 10 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError.wvrLiCdError ? showErrorMessage.wvrLiCdErrorMsg : null
                  }
                  error={
                    showError.wvrLiCdError ? showErrorMessage.wvrLiCdErrorMsg : null
                  }
                ></TextField>
              </div>

              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="CMS64 TOS Code"
                  value={waiverState.cms64Cd}
                  onChange={handleChange('cms64Cd')}
                  inputProps={{ maxlength: 8 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError.cms64CdError ? showErrorMessage.cms64CdErrorMsg : null
                  }
                  error={
                    showError.cms64CdError ? showErrorMessage.cms64CdErrorMsg : null
                  }
                ></TextField>
              </div>

              <div
                className="mui-custom-form-description" // style={{ marginLeft: '30px' }}
              >
                <TextField
                  id="standard-businessName"
                  fullWidth
                  required
                  label="Waiver Line Code Description"
                  value={waiverState.wvrLiCdDesc}
                  onChange={handleChange('wvrLiCdDesc')}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ maxLength: 150 }}
                  helperText={
                    showError.wvrLiCdDescError ? showErrorMessage.wvrLiCdDescErrorMsg : null
                  }
                  error={
                    showError.wvrLiCdError ? showErrorMessage.wvrLiCdDescErrorMsg : null
                  }
                />
              </div>
            </div>
            <div className="form-wrapper">

              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="Priority Number"
                  value={waiverState.priorityNumber}
                  onChange={handleChange('priorityNumber')}
                  inputProps={{ maxlength: 3 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError.priorityNumError ? showErrorMessage.priorityNumberErrorMsg : null
                  }
                  error={
                    showError.priorityNumError ? showErrorMessage.priorityNumberErrorMsg : null
                  }
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="Waiver Name"
                  value={waiverState.wvrName}
                  onChange={handleChange('wvrName')}
                  inputProps={{ maxlength: 150 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError.wvrNameError ? showErrorMessage.wvrNameErrorMsg : null
                  }
                  error={
                    showError.wvrNameError ? showErrorMessage.wvrNameErrorMsg : null
                  }
                ></TextField>
              </div>

              <div
                className="mui-custom-form-description" // style={{ marginLeft: '30px' }}
              >
                <TextField
                  id="standard-businessName"
                  fullWidth
                  required
                  label="CMS64 TOS Code Description"
                  value={waiverState.cms64Desc}
                  onChange={handleChange('cms64Desc')}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ maxLength: 150 }}
                  helperText={
                    showError.cms64CdDescError ? showErrorMessage.cms64CdDescErrorMsg : null
                  }
                  error={
                    showError.cms64CdDescError ? showErrorMessage.cms64CdDescErrorMsg : null
                  }
                />
              </div>

            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="Begin Waiver Code"
                  value={waiverState.bgnWvrCd}
                  onChange={handleChange('bgnWvrCd')}
                  inputProps={{ maxlength: 2 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError.bgnWvrCdError ? showErrorMessage.bgnWvrCdErrorMsg : null
                  }
                  error={
                    showError.bgnWvrCdError ? showErrorMessage.bgnWvrCdErrorMsg : null
                  }
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="End Waiver Code"
                  value={waiverState.endWvrCd}
                  onChange={handleChange('endWvrCd')}
                  inputProps={{ maxlength: 150 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError.endWvrCdError ? showErrorMessage.endWvrCdErrorMsg : null
                  }
                  error={
                    showError.endWvrCdError ? showErrorMessage.endWvrCdErrorMsg : null
                  }
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="Begin Procedure Code"
                  value={waiverState.bgnProcCd}
                  onChange={handleChange('bgnProcCd')}
                  inputProps={{ maxlength: 7 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError.bgnProcCdError ? showErrorMessage.bgnProcCdErrorMsg : null
                  }
                  error={
                    showError.bgnProcCdError ? showErrorMessage.bgnProcCdErrorMsg : null
                  }
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  required
                  label="End Procedure Code"
                  value={waiverState.endProcCd}
                  onChange={handleChange('endProcCd')}
                  inputProps={{ maxlength: 7 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                  helperText={
                    showError.endProcCdError ? showErrorMessage.endProcCdErrorMsg : null
                  }
                  error={
                    showError.endProcCdError ? showErrorMessage.endProcCdErrorMsg : null
                  }
                ></TextField>
              </div>

            </div>
            <div className="form-wrapper">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    fullWidth
                    required
                    id="GrossLevelDetailBegindate-picker-dialog"
                    label="Begin Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedBeginDate}
                    onChange={handleBeginDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    maxDateMessage=''

                    helperText={
                      showError.bgnDateError ? showErrorMessage.bgnDateErrorMsg : null
                    }
                    error={
                      showError.bgnDateError ? showErrorMessage.bgnDateErrorMsg : null
                    }

                  />
                </div>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    required
                    fullWidth
                    id="GrossLevelDetailBegindate-picker-dialog"
                    label="End Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedEndDate}
                    onChange={handleEndDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    helperText={
                      showError.endDateError ? showErrorMessage.endDateErrorMsg : null
                    }
                    error={
                      showError.endDateError ? showErrorMessage.endDateErrorMsg : null
                    }
                    maxDateMessage=''
                  />
                </div>
              </MuiPickersUtilsProvider>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  label="Procedure Mod Code 1"
                  value={waiverState.pocModCd1}
                  onChange={handleChange('pocModCd1')}
                  inputProps={{ maxlength: 2 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  label="Procedure Mod Code 2"
                  value={waiverState.pocModCd2}
                  onChange={handleChange('pocModCd2')}
                  inputProps={{ maxlength: 2 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  label="Procedure Mod Code 3"
                  value={waiverState.pocModCd3}
                  onChange={handleChange('pocModCd3')}
                  inputProps={{ maxlength: 2 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="standard-AccountPayeeID"
                  fullWidth
                  label="Procedure Mod Code 4"
                  value={waiverState.pocModCd4}
                  onChange={handleChange('pocModCd4')}
                  inputProps={{ maxlength: 2 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>
              </div>
            </div>
            <div className='form-wrapper'>
              <div className="mui-custom-form">
                <label className='MuiFormLabel-root small-label no-margin' for="w3review">Comments</label>
                <textarea id="w3review" name="w3review" rows="4" cols="100" value={waiverState.comments} onChange={handleChange('comments')} />
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
}
export default withRouter(WaiverAdd);
