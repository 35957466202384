/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from 'react';
import Bootstrap, { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import TextField from '@material-ui/core/TextField';
import { redirectSystemID } from '../../../../SharedModules/TabRedirections/TabRedirections';
import { searchSingleRecordFinancialEntity } from '../../../FinancialEntities/Store/Actions/FinancialEntityActions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import InputAdornment from '@material-ui/core/InputAdornment';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown'

function Common(props) {
  const disableField = true;
  const dispatch = useDispatch();
  // System Payee redirection starts
  const searchSingleFinancialEntityRes = useSelector(state => state.financialEntityState.singleSearchResults);
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    props.setspinnerLoader(false);
    if (redirect === 1 && searchSingleFinancialEntityRes && !searchSingleFinancialEntityRes.systemFailue) {
      props.history.push({
        pathname: '/FinancialEntityUpdate',
        state: { searchSingleFinancialEntityRes }
      });
    }
  }, [searchSingleFinancialEntityRes]);
  const onSystemPayeeIdclick = systemPayeeId => event => {
    props.setspinnerLoader(true);
    dispatch(searchSingleRecordFinancialEntity(systemPayeeId));
    setRedirect(1);
  };
  // System Payee redirection ends
  let payeeTypeIDDropdown = props.values.payeeType.split('-')[0] === 'P' ? 'P1#P_ALT_ID_TY_CD' :
      props.values.payeeType.split('-')[0] === 'M' ? 'F1#B_ALT_ID_TY_CD' :
      props.values.payeeType.split('-')[0] === 'O' ? 'F1#G_ENTY_EXTL_ID_TY_CD' :
      props.values.payeeType.split('-')[0] === 'TC' ? 'F1#DM_G_CARR_ENTY_ID_TY_CD' : null
  let addDropdowns = props.location?.state?.dropdowns
  
  return (
    <div className="tab-body">
      <form noValidate autoComplete="off">
        <div className="form-wrapper">
            <NativeDropDown
              id="lob"
              label="LOB"
              className='override-width-22 override-m-1'
              inputProps={{ maxlength: 3 }}
              value={props.values.LOB}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={props.lobCode ? props.lobCode.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
            />
            <NativeDropDown
              id="payee-type"
              className='override-width-22 override-m-1'
              label="Payee Type"
              inputProps={{ maxlength: 3 }}
              value={props.values.payeeType}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList = {addDropdowns ? addDropdowns['G1#G_CMN_ENTY_TY_CD'] && 
              addDropdowns['G1#G_CMN_ENTY_TY_CD'].map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
              />
            <NativeDropDown
              id="payee-type-code"
              className='override-width-22 override-m-1'
              label="Payee ID Type Code"
              inputProps={{ maxlength: 3 }}
              value={props.values.payeeIdTypeCode}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={addDropdowns ? addDropdowns[payeeTypeIDDropdown] && 
              addDropdowns[payeeTypeIDDropdown].map((item, index) => (
                <option key={index} value={item.code}>
                  {item.description}
                </option>
              )) : null}
            />          
            <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="payee-id"
              fullWidth
              label="Payee ID"
              inputProps={{ maxlength: 15 }}
              value={props.values.payeeId}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
            >
            </TextField>
          </div>

        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1 has-link" >
            <TextField
              id="system-payee-id"
              fullWidth
              label="System Payee ID"
              inputProps={{ maxlength: 10 }}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Link to={redirectSystemID(props.values)}>
                      {props.values.systemPayeeId}
                    </Link>
                  </InputAdornment>
                )
              }}
            >
            </TextField>
          </div>

          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="payee-name"
              fullWidth
              label="Payee Name"
              inputProps={{ maxlength: 60 }}
              value={props.values.payeeName}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
            >
            </TextField>
          </div>

            <NativeDropDown
              id="memberidtype"
              className='override-width-22 override-m-1'
              label="Member ID Type"
              inputProps={{ maxlength: 15 }}
              value={props.values.memberIdType ? props.values.memberIdType :''}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
              dropdownList={addDropdowns ? addDropdowns['Member#B_ALT_ID_TY_CD'] && 
                 addDropdowns['Member#B_ALT_ID_TY_CD'].map((item, index) => (
                   <option key={index} value={item.code}>
                     {item.description}
                   </option>
                 )) : null}
            />
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="memberid"
              fullWidth
              label="Member ID"
              inputProps={{ maxlength: 15 }}
              value={props.values.memberId}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
            >
            </TextField>
          </div>

        </div>

        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="member-name"
              fullWidth
              label="Member Name"
              inputProps={{ maxlength: 40 }}
              value={props.values.memberName}
              disabled={disableField}
              InputLabelProps={{
                shrink: true
              }}
            >
            </TextField>
          </div>

        </div>
      </form>
    </div>
  );
}
export default withRouter(Common);
