/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

export default function AdjustmentVerification(props) {
  const disableField = true;

  return (
    <form autoComplete="off">
      <fieldset className="custom-fieldset no-padding">
        <legend>Adjustment Results</legend>
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1">
            <TextField
              id="fcn-code"
              fullWidth
              label="FCN"
              inputProps={ { maxlength: 14 } }
              value={ props.calculatedData.adjustedFcn }
              disabled={ disableField }
              InputLabelProps={ {
                shrink: true
              } }
            >
            </TextField>
          </div>
            <NativeDropDown
              id="lob"
              className='override-width-22 override-m-1'
              label="LOB"
              inputProps={ { maxlength: 3 } }
              value={ props.calculatedData.adjustedLOB }
              disabled={ disableField }
              InputLabelProps={ {
                shrink: true
              } }
              dropdownList={ props && props.lobCode ? props.lobCode.map((item, index) => (
                <option key={ index } value={ item.code }>
                  { item.description }
                </option>
              )) : null }
            />
          <div className="mui-custom-form with-date override-width-22 override-m-1">
            <MuiPickersUtilsProvider utils={ DateFnsUtils }>
              <KeyboardDatePicker
                maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                formnovalidate
                id="begin_date"
                label="Adjustment Date"
                format="MM/dd/yyyy"
                placeholder='mm/dd/yyyy'
                InputLabelProps={ {
                  shrink: true
                } }
                name='begin_date'
                required
                KeyboardButtonProps={ {
                  'aria-label': 'change date'
                } }
                disabled={ disableField }
              />
            </MuiPickersUtilsProvider>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1" >
            <TextField
              id="related-ft"
              fullWidth
              label="Related FT TCN"
              inputProps={ { maxlength: 17 } }
              value={ props.values.adjustedTcn }
              disabled={ disableField }
              InputLabelProps={ {
                shrink: true
              } }
            >
            </TextField>
          </div>
        </div>
        <div className="form-wrapper">
          <div className="mui-custom-form override-width-22 override-m-1 with-icon with-icon-margin">
            <TextField
              id="original-amount"
              fullWidth
              label="Original A/R Amount"
              inputProps={ { maxlength: 11 } }
              InputProps={ {
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              } }
              value={ props.calculatedData.newOriginalAmount }
              disabled={ disableField }
              InputLabelProps={ {
                shrink: true
              } }
            >
            </TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1 with-icon with-icon-margin">
            <TextField
              id="bal-amount"
              fullWidth
              label="Balance Amount"
              inputProps={ { maxlength: 11 } }
              value={ props.calculatedData.adjustedBalanceAmount }
              InputProps={ {
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              } }
              disabled={ disableField }
              InputLabelProps={ {
                shrink: true
              } }
            >
            </TextField>
          </div>
          <div className="mui-custom-form override-width-22 override-m-1 with-icon with-icon-margin">
            <TextField
              id="bal-amount"
              fullWidth
              label="Adjustment Amount"
              inputProps={ { maxlength: 11 } }
              value={ props.calculatedData.adjustmentAmount }
              InputProps={ {
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              } }
              disabled={ disableField }
              InputLabelProps={ {
                shrink: true
              } }
            >
            </TextField>
          </div>
            <NativeDropDown
              id="select-reasoncode"
              className='override-width-22 override-m-1'
              label="Reason Code"
              value={ props.calculatedData.adjusmentReasonCode }
              inputProps={ { maxLength: 3 } }
              InputLabelProps={ {
                shrink: true
              } }
              disabled={ disableField }
              dropdownList={ props.reasonCodeDropDown ? props.reasonCodeDropDown.map((item, index) => (
                <option key={ index } value={ item.code } title={ item.longDescription }>
                  { item.description }
                </option>
              )) : null }

            />
                      <div className="mui-custom-form override-width-22 override-m-1 with-icon with-icon-margin">
          <TextField
              id='ATRnumber'
              fullWidth
              label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
              placeholder=""
              value={props?.atrNumber}
              disabled
              inputProps={{ maxlength: 18 }}
              InputLabelProps={{
                shrink: true,
              }}
            ></TextField>
          </div>
            
        </div>
      </fieldset>
    </form>
  );
}
