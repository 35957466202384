/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Spinner from "../../../SharedModules/Spinner/Spinner";
import { withRouter } from 'react-router';
import * as BankAccountLockBoxConstants from './ErrorConstants';
import { useDispatch, useSelector } from 'react-redux';
import { resetBankAccountAndLockBox } from '../Store/Actions/BankAccountAndLockBoxSearchActions';
import * as AppConstants from '../../../SharedModules/AppConstants';
import { getDateInMMDDYYYYFormat, validateDateMinimumValue, compareTwoDatesGreaterThanOrEqual } from '../../../SharedModules/DateUtilities/DateUtilities';
import Radio from '@material-ui/core/Radio';

function BankAccountAndLockBoxSearchform (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [routingStartsWith, setRoutingStartsWith] = React.useState(false);
  const [accountStartsWith, setAccountStartsWith] = React.useState(false);
  const [lockBoxStartsWith, setLockBoxStartsWith] = React.useState(false);
  const [showBeginDateErrorMsg, setShowBeginDateErroMsg] = React.useState(null);
  const [showEndDateErrorMsg, setShowEndDateErroMsg] = React.useState(null);  
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [values, setValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    bankName: '',
    routingBank: '',
    account: '',
    accountName: '',
    lockBox: '',
    lockBoxName: ''
  });
  const dispatch = useDispatch();

  let errorMessagesArray = [];
  const [{
    showRoutingError, showAccountError, showLockBoxError, showLockBoxNameError,
    showAccountNameError, showBankNameError, showBeginDateError, showEndDateError
  }
  , setShowError] = React.useState(false);
  const modifyStartWithOrContains = (selectedValue) => {
    if (selectedValue === 'Starts With') { return 1; }
    if (selectedValue === 'Contains') { return 0; }
    return null;
  };
  const [isSearch, setIsSearch] = React.useState(false);
  const [useEffectValues, setUseEffectValues] = React.useState([]);
  const onReset = () => dispatch(resetBankAccountAndLockBox());
  useEffect(() => {
    onReset();
  }, [useEffectValues]);

  const searchBankAccountAndLockBox = () => {
    props.location.tableRender = false;
    setspinnerLoader(true);
    props.setShowTable(!props.showTable); // Changed to false
    errorMessagesArray = [];
    props.seterrorMessages([]);
    var showRoutingError; var showAccountError; var showLockBoxError; var showLockBoxNameError;
    var showAccountNameError; var showBankNameError; var showEndDateError; var showBeginDateError = false;
    if (routingStartsWith && (values.routingBank === '' || values.routingBank.length < 2)) {
      showRoutingError = true;
      errorMessagesArray.push(BankAccountLockBoxConstants.MIN_CHAR_ERROR);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (accountStartsWith && (!values.account || values.account.length < 2)) {
      showAccountError = true;
      errorMessagesArray.push(BankAccountLockBoxConstants.MIN_CHAR_ERROR);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (values.account && ('/^[0-9]?$/'.match(values.account))) {
      showAccountError = true;
      errorMessagesArray.push(BankAccountLockBoxConstants.INVALID_FORMAT);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (lockBoxStartsWith && (!values.lockBox || values.lockBox.trim().length < 2)) {
      showLockBoxError = true;
      errorMessagesArray.push(BankAccountLockBoxConstants.MIN_CHAR_ERROR);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (values.lockBox && ('/^[0-9]?$/'.match(values.lockBox))) {
      showLockBoxError = true;
      errorMessagesArray.push(BankAccountLockBoxConstants.INVALID_FORMAT);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (values.bankNameSelected && (!values.bankName || values.bankName.trim().length < 2)) {
      showBankNameError = true;
      errorMessagesArray.push(BankAccountLockBoxConstants.MIN_CHAR_ERROR);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (values.accountNameSelected && (!values.accountName || values.accountName.trim().length < 2)) {
      showAccountNameError = true;
      errorMessagesArray.push(BankAccountLockBoxConstants.MIN_CHAR_ERROR);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (values.lockBoxNameSelected && (!values.lockBoxName || values.lockBoxName.trim().length < 2)) {
      showLockBoxNameError = true;
      errorMessagesArray.push(BankAccountLockBoxConstants.MIN_CHAR_ERROR);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }

    if ((selectedDate && (selectedDate.toString() === 'Invalid Date'))) {
      showBeginDateError = true;
      setShowBeginDateErroMsg(BankAccountLockBoxConstants.INVALID_DATE_FORMAT);
      errorMessagesArray.push(BankAccountLockBoxConstants.INVALID_DATE_FORMAT);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (selectedDate && (selectedDate.toString() !== 'Invalid Date')) {
      let isDateValid = false;
      isDateValid = validateDateMinimumValue(selectedDate);
      if (isDateValid) {
        showBeginDateError = true;
        setShowBeginDateErroMsg(AppConstants.DATE_ERROR_1964);
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
        props.seterrorMessages(errorMessagesArray);
        props.setShowTable(false);
      }
    }
    if ((selectedEndDate && (selectedEndDate.toString() === 'Invalid Date'))) {
      showEndDateError = true;
      setShowEndDateErroMsg(BankAccountLockBoxConstants.INVALID_DATE_FORMAT);
      errorMessagesArray.push(BankAccountLockBoxConstants.INVALID_DATE_FORMAT);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (selectedEndDate && (selectedEndDate.toString() !== 'Invalid Date')) {
      let isDateValid = false;
      isDateValid = validateDateMinimumValue(selectedEndDate);
      if (isDateValid) {
        showEndDateError = true;
        setShowEndDateErroMsg(AppConstants.DATE_ERROR_1964);
        errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
        props.seterrorMessages(errorMessagesArray);
        props.setShowTable(false);
      }
    }
    if (selectedDate && selectedEndDate &&
      !showBeginDateError && !showEndDateError &&
      compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedDate)
    ) {
      showBeginDateError = true;
      setShowBeginDateErroMsg(BankAccountLockBoxConstants.ACCOUNT_BEGIN_DATE_ERROR);
      errorMessagesArray.push(BankAccountLockBoxConstants.ACCOUNT_BEGIN_DATE_ERROR);
      props.seterrorMessages(errorMessagesArray);
      props.setShowTable(false);
    }
    if (errorMessagesArray.length <= 0) {
      props.setShowTable(true);
      const searchCriteria = {
        lob: [AppConstants.DEFAULT_LOB_VALUE],
        bankRoutingNumber: values.routingBank || null,
        bankRoutingNumberStartsWith: routingStartsWith || false,
        bankName: values.bankName || null,
        bankNameStartsWithOrContains: modifyStartWithOrContains(values.bankNameSelected),
        bankAccountNumber: values.account || null,
        accountNumberStartsWith: accountStartsWith || false,
        accountName: values.accountName || null,
        accountNameStartsWithOrContains: modifyStartWithOrContains(values.accountNameSelected),
        beginDate: getDateInMMDDYYYYFormat(selectedDate) || null,
        endDate: getDateInMMDDYYYYFormat(selectedEndDate) || null,
        lockBoxNumber: values.lockBox || null,
        lockBoxNumberStartsWith: lockBoxStartsWith || false,
        lockBoxName: values.lockBoxName || null,
        lockBoxNameStartsWithOrContains: modifyStartWithOrContains(values.lockBoxNameSelected)
      };
      props.onSearch(searchCriteria);
      if(!props.history.state){
        props.history.state = {}
      }
      props.history.state.values = searchCriteria
      setIsSearch(true);
    }
    setShowError({
      showRoutingError: showRoutingError,
      showAccountError: showAccountError,
      showLockBoxError: showLockBoxError,
      showBankNameError: showBankNameError,
      showAccountNameError: showAccountNameError,
      showLockBoxNameError: showLockBoxNameError,
      showBeginDateError: showBeginDateError,
      showEndDateError: showEndDateError
    });
  };

  const ValuesFromBankAccount = props?.history?.state?.values;
  useEffect(() => {
    if (props.location.tableRender && !isSearch && !props.location.state?.isFromAdd) {
      if (ValuesFromBankAccount !== undefined) {
        props.setShowTable(true);
        const cancelSearchCriteria = {
          lob: [AppConstants.DEFAULT_LOB_VALUE],
          bankRoutingNumber: ValuesFromBankAccount.bankRoutingNumber || null,
          bankRoutingNumberStartsWith: ValuesFromBankAccount.bankRoutingNumberStartsWith || false,
          bankName: ValuesFromBankAccount.bankName || null,
          bankAccountNumber: ValuesFromBankAccount.bankAccountNumber || null,
          bankNameStartsWithOrContains: ValuesFromBankAccount.bankNameStartsWithOrContains,
          accountNumberStartsWith: ValuesFromBankAccount.accountNumberStartsWith || false,
          accountName: ValuesFromBankAccount.accountName || null,
          accountNameStartsWithOrContains: ValuesFromBankAccount.accountNameStartsWithOrContains,
          beginDate: ValuesFromBankAccount.beginDate || null,
          endDate: ValuesFromBankAccount.endDate || null,
          lockBoxNumber: ValuesFromBankAccount.lockBoxNumber || null,
          lockBoxNumberStartsWith: ValuesFromBankAccount.lockBoxNumberStartsWith || false,
          lockBoxName: ValuesFromBankAccount.lockBoxName || null,
          lockBoxNameStartsWithOrContains: ValuesFromBankAccount.lockBoxNameStartsWithOrContains
        };
        props.onSearch(cancelSearchCriteria);
        setValues({
          ...ValuesFromBankAccount,
          lockBox: ValuesFromBankAccount.lockBoxNumber,
          account: ValuesFromBankAccount.bankAccountNumber,
          routingBank: ValuesFromBankAccount.bankRoutingNumber,
          accountNameSelected: ValuesFromBankAccount.accountNameStartsWithOrContains === 1
            ? 'Starts With'
            : ValuesFromBankAccount.accountNameStartsWithOrContains === 0
              ? 'Contains'
              : null,
          bankNameSelected: ValuesFromBankAccount.bankNameStartsWithOrContains === 1
            ? 'Starts With'
            : ValuesFromBankAccount.bankNameStartsWithOrContains === 0
              ? 'Contains'
              : null,
          lockBoxNameSelected: ValuesFromBankAccount.lockBoxNameStartsWithOrContains === 1
            ? 'Starts With'
            : ValuesFromBankAccount.lockBoxNameStartsWithOrContains === 0
              ? 'Contains'
              : null
        });
        setSelectedDate(ValuesFromBankAccount.beginDate);
        setSelectedEndDate(ValuesFromBankAccount.endDate);
        setRoutingStartsWith(ValuesFromBankAccount.bankRoutingNumberStartsWith);
        setAccountStartsWith(ValuesFromBankAccount.accountNumberStartsWith);
        setLockBoxStartsWith(ValuesFromBankAccount.lockBoxNumberStartsWith);
      };
    }
  }, [props.location.tableRender]);

  const resetTable = () => {
    props.seterrorMessages([]);
    setRoutingStartsWith(false);
    setAccountStartsWith(false);
    setLockBoxStartsWith(false);
    setShowError({
      showRoutingError: false,
      showAccountError: false,
      showLockBoxError: false,
      showBankNameError: false,
      showAccountNameError: false,
      showLockBoxNameError: false
    });
    setValues(
      {
        lob: DEFAULT_DD_VALUE,
        bankName: '',
        routingBank: '',
        account: '',
        accountName: '',
        lockBox: '',
        lockBoxName: ''
      }
    );
    setSelectedDate(null);
    setSelectedEndDate(null);
    props.setShowTable(false);
  };
  const handleChangeRoutingStartsWith = name => event => {
    setRoutingStartsWith(!routingStartsWith);
  };

  const handleChangeAccountStartsWith = name => event => {
    setAccountStartsWith(!accountStartsWith);
  };

  const handleChangeLockBoxStartsWith = name => event => {
    setLockBoxStartsWith(!lockBoxStartsWith);
  };
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  const handleEndDateChange = date => {
    setSelectedEndDate(date);
  };
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  return (
    <form autoComplete="off">
      <div className="form-wrapper">
        <div className="mui-custom-form input-md">
          <TextField
            id="routing-bank"
            fullWidth
            label="Routing/Bank ABA #"
            value={values.routingBank}
            onChange={handleChange('routingBank')}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              maxLength: 9
            }}
            onInput={(e) => { e.target.value = e.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, ''); }}
            helperText={showRoutingError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
            error={showRoutingError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
          />
          <div className="sub-radio">
            <FormControlLabel
              control={
                <Checkbox checked={routingStartsWith} color="primary" onChange={handleChangeRoutingStartsWith('routingStartsWith')} value={routingStartsWith} />
              }
              label="Starts With"
            />
          </div>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="bank-name"
            fullWidth
            label="Bank Name"
            inputProps={{ maxLength: 60 }}
            data-testid='test_bankname'
            InputLabelProps={{
              shrink: true
            }}
            value={values.bankName}
            onChange={handleChange('bankName')}
            helperText={showBankNameError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
            error={showBankNameError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
          />
          <div className="sub-radio">
            <Radio
              value="Starts With"
              id="banknamestart"
              checked={values.bankNameSelected === 'Starts With'}
              onChange={handleChange('bankNameSelected')}
            /> <label className="text-black" htmlFor="banknamestart">Starts With </label>
            <Radio
              value="Contains"
              checked={values.bankNameSelected === 'Contains'}
              id="banknamecontain"
              onChange={handleChange('bankNameSelected')}
              className="ml-2"
            /><label className="text-black" htmlFor="banknamecontain">Contains</label>
          </div>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="Account"
            fullWidth
            label="Account #"
            inputProps={{ maxLength: 15 }}
            InputLabelProps={{
              shrink: true
            }}
            onInput={e => {
              e.target.value = e.target.value.replace(
                AppConstants.NUMBER_ONLY_REGEX,
                ''
              );
            }}
            value={values.account}
            onChange={handleChange('account')}
            helperText={showAccountError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
            error={showAccountError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
          />
          <div className="sub-radio">
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={accountStartsWith} onChange={handleChangeAccountStartsWith(accountStartsWith)} value={accountStartsWith} />
              }
              label="Starts With"
            />
          </div>
        </div>
        <div className="mui-custom-form input-md">
          <TextField
            id="account-name"
            label="Account Name"
            inputProps={{ maxLength: 60 }}
            data-testid='Account_name_test'
            value={values.accountName}
            onChange={handleChange('accountName')}
            InputLabelProps={{
              shrink: true
            }}
            helperText={showAccountNameError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
            error={showAccountNameError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
          />
          <div className="sub-radio">
            <Radio
              value="Starts With"
              id="accountstartname"
              checked={values.accountNameSelected === 'Starts With'}
              onChange={handleChange('accountNameSelected')}
            /><label className="text-black" htmlFor="accountstartname">Starts With </label>
            <Radio
              value="Contains"
              checked={values.accountNameSelected === 'Contains'}
              onChange={handleChange('accountNameSelected')}
              className="ml-2"
              id="accountcontain"
            /><label className="text-black" htmlFor="accountcontain">Contains </label>
          </div>
        </div>
      </div>
      <div className="form-wrapper">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form input-md">
            <KeyboardDatePicker
              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
              id="date-picker-inline"
              fullWidth
              label="Begin Date"
              name='begin_date'
              InputLabelProps={{
                shrink: true
              }}
              placeholder="mm/dd/yyyy"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              helperText={showBeginDateError ? showBeginDateErrorMsg : null}
              error={showBeginDateError ? showBeginDateErrorMsg : null}
            />
          </div>

          <div className="mui-custom-form input-md" >
            <KeyboardDatePicker
              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
              id="date-picker-dialog"
              name='end_date'
              label="End Date"
              format="MM/dd/yyyy"
              InputLabelProps={{
                shrink: true
              }}
              placeholder="mm/dd/yyyy"
              value={selectedEndDate}
              onChange={handleEndDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
              helperText={showEndDateError ? showEndDateErrorMsg : null}
              error={showEndDateError ? showEndDateErrorMsg : null}
            />
          </div>
        </MuiPickersUtilsProvider>
        <div className="mui-custom-form input-md" >
          <TextField
            id="lock-box"
            fullWidth
            label="LockBox #"
            inputProps={{ maxLength: 7 }}
            value={values.lockBox}
            onChange={handleChange('lockBox')}
            InputLabelProps={{
              shrink: true
            }}
            helperText={showLockBoxError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
            error={showLockBoxError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
          />
          <div className="sub-radio">
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={lockBoxStartsWith} onChange={handleChangeLockBoxStartsWith('lockBoxStartsWith')} value={lockBoxStartsWith} />
              }
              label="Starts With"
            />
          </div>
        </div>
        <div className="mui-custom-form" >
          <TextField
            id="lock-box-name"
            fullWidth
            label="LockBox Name"
            inputProps={{ maxLength: 40 }}
            value={values.lockBoxName}
            onChange={handleChange('lockBoxName')}
            InputLabelProps={{
              shrink: true
            }}
            helperText={showLockBoxNameError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
            error={showLockBoxNameError ? BankAccountLockBoxConstants.MIN_CHAR_ERROR : null}
          />
          <div className="sub-radio">
            <Radio
              value="Starts With"
              checked={values.lockBoxNameSelected === 'Starts With'}
              onChange={handleChange('lockBoxNameSelected')}
              id="lockboxstart"
            /><label className="text-black" htmlFor="lockboxstart">Starts With </label>
            <Radio
              value="Contains"
              checked={values.lockBoxNameSelected === 'Contains'}
              onChange={handleChange('lockBoxNameSelected')}
              className="ml-2"
              id="lockboxcontain"
            /><label className="text-black" htmlFor="lockboxcontain">Contains </label>
          </div>
        </div>
      </div>
      <div className="float-right mr-3 mb-2">
        <Button variant="outlined" color="primary" className='btn btn-primary ml-1'
          onClick={() => searchBankAccountAndLockBox()}
        >
          <i className="fa fa-search" aria-hidden="true"></i>
          Search
        </Button>
        <Button variant="outlined" color="primary" className='bt-reset btn-transparent  ml-1'
          onClick={() => resetTable()}
        >
          <i className="fa fa-undo" aria-hidden="true"></i>
          Reset
        </Button>
      </div>
      <div className="clearfix"></div>
    </form>

  );
};
BankAccountAndLockBoxSearchform.propTypes = {
  showTable: PropTypes.bool.isRequired,
  setShowTable: PropTypes.func.isRequired,
  seterrorMessages: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired
};

export default withRouter(BankAccountAndLockBoxSearchform);
