/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Bootstrap, { Button } from 'react-bootstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchClaimsReceivableAction,
  getPayeeIdType,
  getReasonCodeMapDataAction,
  searchPayeeAccountReceivableAction,
  resetPayeeSearchAccountReceivable,
  getDropdownDataAction,
  bulkViewTransfer,
  bulkViewReverse,
  bulkViewWriteOff,
  bulkViewWriteOffReest,
  bulkViewChangePayeeStatus,
  saveChangePayeeStatus
} from '../../Store/Actions/accountReceivable/accountReceivableActions';
import { searchSystemParameterAction9047 } from '../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import dropdownCriteria from '../../Components/ARInquiryUpdate/ARSearch/FinancialAccountSearch.json';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import {
  compareTwoDates,
  checkForValidDateString,
  getDateInMMDDYYYYFormat,
  validateDateMinimumValue
  , generateUUID
} from '../../../../SharedModules/DateUtilities/DateUtilities';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import BulkSearchTable from './BulkSearchTable';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  DatePicker
} from '@material-ui/pickers';
import * as ARSearchConstants from '../ARInquiryUpdate/ARSearch/ARSearchConstants';
import moment from 'moment';
import '../../AccountReceivables.scss';
import Swal from 'sweetalert2';
import Radio from '@material-ui/core/Radio';
import { Apple } from '@material-ui/icons';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown'

/* PayeeReceivable()
 * This component is used to search for Account Receivables Payee/Payer
 */
function BulkMaintenance (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  const onReset = () => dispatch(resetPayeeSearchAccountReceivable());
  
  const getDropdownData = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const dropdown = useSelector(state => state.accountReceivableState.dropdownData);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [lobData, setLobData] = React.useState([]);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  const [payeeStatusData, setPayeeStatusData] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const [initialReasonCodeArray, setInitialReasonCodeArray] = React.useState([]);
  const [showTable, setShowTable] = React.useState(false);
  const [outstandingBalanceCheck, setOutstandingBalanceCheck] = React.useState(true);
  let errorMessagesArray = [];
  const [errorMegFromDate, setErrorMegFromDate] = React.useState('');
  const [errorMegThruDate, setErrorMegThruDate] = React.useState('');
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedThruDate, setSelectedThruDate] = React.useState(null);
  const [selectedLastDate, setSelectedLastDate] = React.useState(null);
  const [fromDatePress, setFromDatePress] = React.useState(false);
  const [thruDatePress, setThruDatePress] = React.useState(false);
  const [lastDatePress, setLastDatePress] = React.useState(false);
  const [redirect, setRedirect] = React.useState(0);
  const [payeeTypeCodeData, setPayeeTypeCodeData] = React.useState([]);
  const [errorMeg, setErrorMeg] = React.useState('');
  const [errorTypeMeg, setPayeeTypeErrorMeg] = React.useState('');
  const [errorCodeMeg, setPayeeCodeErrorMeg] = React.useState('');
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  let payloadPayees = [];
  const payloadPayeesOutstanding = [];
  let payeeIdTypeDropdown = '';
  var activityValue = '';
  const getReasonCodeMapData = (value) => dispatch(getReasonCodeMapDataAction(value));
  const onSearch = values =>
    dispatch(searchPayeeAccountReceivableAction(values));
  const onSearchPayeeIdType = values => dispatch(getPayeeIdType(values));
  const viewTransferAction = (value) => dispatch(bulkViewTransfer(value));
  const viewReverseAction = (value) => dispatch(bulkViewReverse(value));
  const viewBulkWriteOff = (value) => dispatch(bulkViewWriteOff(value));
  const viewChangePayeeStatus = (value) => dispatch(bulkViewChangePayeeStatus(value));
  const viewBulkWriteOffReest = (value) => dispatch(bulkViewWriteOffReest(value));
  payloadPayees = useSelector(
    state => state.accountReceivableState.payloadPayees
  );

  payeeIdTypeDropdown = useSelector(
    state => state.accountReceivableState.payeeIdTypeDropdown
  );

  const reasonCodeMapdropdown = useSelector(
    state => state.accountReceivableState.reasonCodeMapdropdownData
  );

  const [selectedFcnObj, setSelectedFcnObj] = React.useState([]);

  const bulkViewTransferResult = useSelector(
    state => state.accountReceivableState.bulkViewTransfer
  );
  const bulkViewTransferError = useSelector(
    state => state.accountReceivableState.bulkViewErrorTransfer
  );

  const bulkViewReverseResult = useSelector(
    state => state.accountReceivableState.bulkViewReverse
  );

  const bulkViewReverseError = useSelector(
    state => state.accountReceivableState.bulkViewReverseError
  );

  const viewBulkWriteOffResult = useSelector(
    state => state.accountReceivableState.viewBulkWriteOff
  );

  const viewChangePayeeStatusResult = useSelector(
    state => state.accountReceivableState.changePayeeStatus
  );

  const viewBulkWriteOffError = useSelector(
    state => state.accountReceivableState.viewBulkWriteOffError
  );

  const viewChangePayeeStatusError = useSelector(
    state => state.accountReceivableState.changePayeeStatusError
  );

  const viewBulkWriteOffReestResult = useSelector(
    state => state.accountReceivableState.viewBulkWriteOffReest
  );

  const viewBulkWriteOffReestError = useSelector(
    state => state.accountReceivableState.viewBulkWriteOffReestError
  );

  useEffect(() => {
    showLoader(false);
    if (viewBulkWriteOffError && viewBulkWriteOffError.length > 0) {
      const errorMessages = [];
      viewBulkWriteOffError.map((value) => {
        const message = `${value.receivableFCN}: ${value.errorMessgaes.map((value) => {
          return value;
        })}`;
        errorMessages.push(message);
      });
      seterrorMessages(errorMessages);
    }
  }, [viewBulkWriteOffError]);

  useEffect(() => {
    showLoader(false);
    if (viewChangePayeeStatusError && viewChangePayeeStatusError.length > 0) {
      let errorMessages = [];
      viewChangePayeeStatusError.map((value) => {
        let message = `${value.originalFCN}: ${value.errorMessgaes.map((value) => {
          return value;
        })}`;
        errorMessages.push(message);
      });
      seterrorMessages(errorMessages);
    }

  }, [viewChangePayeeStatusError]);

  useEffect(() => {
    showLoader(false);
    if (viewBulkWriteOffReestError && viewBulkWriteOffReestError.length > 0) {
      const errorMessages = [];
      viewBulkWriteOffReestError.map((value) => {
        const message = `${value.receivableFCN}: ${value.errorMessgaes.map((value) => {
          return value;
        })}`;
        errorMessages.push(message);
      });
      seterrorMessages(errorMessages);
    }
  }, [viewBulkWriteOffReestError]);

  useEffect(() => {
    showLoader(false);
    if (bulkViewTransferError && bulkViewTransferError.length > 0) {
      const errorMessages = [];
      bulkViewTransferError.map((value) => {
        const message = `${value.originalFCN}: ${value.errorMessgaes.map((value) => {
          return value;
        })}`;
        errorMessages.push(message);
      });
      seterrorMessages(errorMessages);
    }
  }, [bulkViewTransferError]);

  useEffect(() => {
    showLoader(false);
    if (bulkViewTransferError && bulkViewTransferError.length > 0) {
      const errorMessages = [];
      bulkViewTransferError.map((value) => {
        const message = `${value.originalFCN}: ${value.errorMessgaes.map((value) => {
          return value;
        })}`;
        errorMessages.push(message);
      });
      seterrorMessages(errorMessages);
    }
  }, [bulkViewTransferError]);

  useEffect(() => {
    showLoader(false);
    if (viewBulkWriteOffResult && viewBulkWriteOffResult.length > 0) {
      props.history.push({
        pathname: '/BulkWriteOff',
        state: {
          viewBulkWriteOffResult,
          selectedFcnObj: selectedFcnObj,
          selectedPayee: {
            payee: values.payee,
            payeeType: selectedPayeeType,
            payeeTypeCode: selectedPayeeTypecd,
            payeeName: selectedFcnObj[0].entityName,
            lob: values.lob
          },
          values: { ...values, ...activityReasonCodeValues, selectedThruDate, selectedFromDate }
        }
      });
    }
  }, [viewBulkWriteOffResult]);

  useEffect(() => {
    showLoader(false);
    if (viewChangePayeeStatusResult && viewChangePayeeStatusResult.length > 0) {
      props.history.push({
        pathname: '/ChangePayStatus',
        state: {
          viewChangePayeeStatusResult, selectedFcnObj: selectedFcnObj, selectedPayee: {
            payee: values.payee,
            payeeType: selectedPayeeType,
            payeeTypeCode: selectedPayeeTypecd,
            payeeName: selectedFcnObj[0]?.entityName,
            lob: values.lob
          },
          values: { ...values, ...activityReasonCodeValues, selectedThruDate, selectedFromDate }
        }
      });
    }

  }, [viewChangePayeeStatusResult]);


  useEffect(() => {
    showLoader(false);
    if (viewBulkWriteOffReestResult && viewBulkWriteOffReestResult.length > 0) {
      props.history.push({
        pathname: '/BulkWriteOffReest',
        state: {
          viewBulkWriteOffReestResult,
          selectedFcnObj: selectedFcnObj,
          selectedPayee: {
            payee: values.payee,
            payeeType: selectedPayeeType,
            payeeTypeCode: selectedPayeeTypecd,
            payeeName: selectedFcnObj[0].entityName,
            lob: values.lob
          },
          values: { ...values, ...activityReasonCodeValues, selectedThruDate, selectedFromDate }
        }
      });
    }
  }, [viewBulkWriteOffReestResult]);

  useEffect(() => {
    showLoader(false);
    if (bulkViewTransferResult && bulkViewTransferResult.length > 0) {
      props.history.push({
        pathname: '/ARBulkTransfer',
        state: {
          bulkViewTransferResult,
          selectedFcnObj: selectedFcnObj,
          selectedPayee: {
            payee: values.payee,
            payeeType: selectedPayeeType,
            payeeTypeCode: selectedPayeeTypecd,
            payeeName: selectedFcnObj[0].entityName,
            lob: values.lob
          },
          values: { ...values, ...activityReasonCodeValues, selectedThruDate, selectedFromDate }
        }
      });
    }
  }, [bulkViewTransferResult]);

  useEffect(() => {
    showLoader(false);

    if (bulkViewReverseResult && bulkViewReverseResult.length > 0) {
      props.history.push({
        pathname: '/BulkReverse',
        state: {
          bulkViewReverseResult,
          selectedFcnObj: selectedFcnObj,
          selectedPayee: {
            lob: values.lob
          },
          values: { ...values, ...activityReasonCodeValues, selectedThruDate, selectedFromDate }
        }
      });
    }
  }, [bulkViewReverseResult]);

  useEffect(() => {
    showLoader(false);
    if (bulkViewReverseError && bulkViewReverseError.length > 0) {
      const errorMessages = [];
      bulkViewReverseError.map((value) => {
        const message = `${value.originalFCN}: ${value.errorMessgaes.map((value) => {
          return value;
        })}`;
        errorMessages.push(message);
      });
      seterrorMessages(errorMessages);
    }
  }, [bulkViewReverseError]);

  /* Setting all dropdown fields with response coming from API */
  useEffect(() => {
    if (reasonCodeMapdropdown && reasonCodeMapdropdown.reasonCodesMap && reasonCodeMapdropdown.reasonCodesMap.reasonCodeList) {
      const reasonCodeValue = JSON.parse(JSON.stringify(reasonCodeMapdropdown.reasonCodesMap.reasonCodeList.sort((a, b) => a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? -1 : 1)));
      setReasonCodeVal(reasonCodeValue);
    }
  }, [reasonCodeMapdropdown]);

  /* Closing loader if payee with outstanding value not found on click of Search button
   * Shwoing error message if payee not found
   * Displaying results in table if more than one payee found
   */
  useEffect(() => {
    if (
      payloadPayees != null &&
      payloadPayees.length === 0
    ) {
      errorMessagesArray.push(ARSearchConstants.NO_RECORDS_FOUND);
      seterrorMessages(errorMessagesArray);
      showLoader(false);
      setShowTable(false);
    }
    if (
      payloadPayees &&
      payloadPayees != null &&
      payloadPayees.length > 0
    ) {
      payloadPayees.map(data => {
        data.id = generateUUID();
        if (data.lobCode != null && data.lobCode !== undefined) {
          data.lob = data.lobCode;
          
        }

        if (
          data.reasonCode != null &&
          data.reasonCode !== undefined
        ) {
          data.reasonCode = data.reasonCode;
          data.resonCodeLongDesc = getLongDescription(reasonCodeData, data.reasonCode);
        }
        
        if (data.payeePayerID != null && data.payeePayerID !== undefined) {
          data.payeePayerType = data.payeePayerID;
        }

        if (
          data.financialCtrlNumber != null &&
          data.financialCtrlNumber !== undefined
        ) {
          data.receivableFCN = data.financialCtrlNumber;
        }

        if (data.lob != null && data.lob !== undefined) {
          const lob =
          addDropdowns &&
          addDropdowns['Reference#R_LOB_CD'] &&
          addDropdowns['Reference#R_LOB_CD'].filter(
            value => value.code === data.lob?.split("-")[0]
          );
        //updating the record with desc
        data.lobDesc =
          lob && lob.length > 0
            ? lob[0].description
            : data.lob ? data.lob : '';
        }

        if (data.payeeStatus != null && data.payeeStatus !== undefined) {
          const payeeStatus =
          addDropdowns &&
          addDropdowns['F1#G_PYE_PYR_STAT_CD'] &&
          addDropdowns['F1#G_PYE_PYR_STAT_CD'].filter(
            value => value.code === data.payeeStatus?.split("-")[0]
          );
        //updating the record with desc
        data.payeeStatusDesc =
          payeeStatus && payeeStatus.length > 0
            ? payeeStatus[0].description
            : data.payeeStatus ? data.payeeStatus : '';
        }

        if (
          data.payeePayerTypeCode != null &&
          data.payeePayerTypeCode !== undefined
        ) {
          const payeePayerTypeCode =
           addDropdowns &&
           addDropdowns['G1#G_CMN_ENTY_TY_CD'] &&
           addDropdowns['G1#G_CMN_ENTY_TY_CD'].filter(
             value => value.code === data.payeePayerTypeCode?.split("-")[0]
           );
         //updating the record with desc
         data.payeePayerTypeCodeDesc =
           payeePayerTypeCode && payeePayerTypeCode.length > 0
             ? payeePayerTypeCode[0].description
             : data.payeePayerTypeCode ? data.payeePayerTypeCode : '';
        }

        if (data.reasonCode != null && data.reasonCode !== undefined) {
          // seterrorMessages(errorMessagesArray);
          const reasonCode =
          addDropdowns &&
          addDropdowns['F1#F_RSN_CD'] &&
          addDropdowns['F1#F_RSN_CD'].filter(
            value => value.code === data.reasonCode?.split("-")[0]
          );
        //updating the record with desc
        data.reasonCodeDesc =
          reasonCode && reasonCode.length > 0
            ? reasonCode[0].description
            : data.reasonCode ? data.reasonCode : '';
        }
      });
      showLoader(false);
      setShowTable(true);
      errorMessagesArray = [];
      showFieldError(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      seterrorMessages(errorMessagesArray);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
      setTableData(payloadPayees);
    }

    if (
      payloadPayees != null &&
      payloadPayees.message !== undefined &&
      payloadPayees.message === ARSearchConstants.NETWORK_ERROR
    ) {
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      seterrorMessages(errorMessagesArray);
    }
    if (
      payloadPayees && payloadPayees.systemFailure 
    ) {
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      seterrorMessages(errorMessagesArray);
      showLoader(false);
      setShowTable(false);
    }
  }, [payloadPayees]);

  /* Closing loader if payee not found on click of Search button
   * Shwoing error message if payee not found
   * Displaying results in table if more than one payee found
   */

  useEffect(() => {
    setSelectedThruDate('12/31/9999');
    setShowTable(false);
    seterrorMessages([]);
    onDropdowns([
      Dropdowns.LOB_CODE,
      Dropdowns.F_RSN_CD,
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_STATUS_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
    ]);
    getDropdownData(dropdownCriteria);
    searchSystemParamForBulkMain(searchSystemParameterActionData)

  }, []);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1019']) {
        setLobData(dropdown.listObj['Reference#1019']);
      }
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#3013']) {
        setActivityTypeData(dropdown.listObj['Financial#3013']);
      }
      if (dropdown.listObj['Financial#6001']) {
        setReasonCodeVal(dropdown.listObj['Financial#6001']);
        setInitialReasonCodeArray(dropdown.listObj['Financial#6001']);
      }
      if (dropdown.listObj['Financial#3200']) {
        setFundCodeData(dropdown.listObj['Financial#3200']);
      }
      if (dropdown.listObj['Financial#6023']) {
        setPayeeStatusData(dropdown.listObj['Financial#6023']);
      }
    }
  }, [dropdown]);

  const setReasonCodeVal = reasonCodeVal => {
    setReasonCodeData(reasonCodeVal);
  };

  useEffect(() => {
    if (payeeIdTypeDropdown && payeeIdTypeDropdown.payeeIdTypeCode) {
      setPayeeTypeCodeData(payeeIdTypeDropdown.payeeIdTypeCode);
      setPayeeValues({
        payeeIdCode: DEFAULT_DD_VALUE,
        payeeType: values.payeeType
      });
      errorMessagesArray = [];
      seterrorMessages(errorMessagesArray);
    } else if (payeeIdTypeDropdown && payeeIdTypeDropdown.length === 0) {
      setPayeeTypeCodeData(payeeIdTypeDropdown.payeeIdTypeCode);
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      seterrorMessages(errorMessagesArray);
    } else if (payeeIdTypeDropdown && payeeIdTypeDropdown.systemFailure) {
      setPayeeTypeCodeData(payeeIdTypeDropdown.payeeIdTypeCode);
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      seterrorMessages(errorMessagesArray);
    }
  }, [payeeIdTypeDropdown]);
  const rowIdsSelected = data => {
    if (data.length > 0) {
      setFcnId(data);
    }
  };

  const continueTransfer = payloadPayees => {
    showLoader(true);
    seterrorMessages([]);
    const payees = payloadPayees;
    let fcnObjects = [];
    fcnObjects = payees.filter(item => fcnId.includes(item.id));
    const receivableFCN = [];
    setSelectedFcnObj(fcnObjects);
    if (fcnObjects.length > 0) {
      fcnObjects.map((fcn, index) => {
        receivableFCN.push(fcn.receivableFCN);
      });
    }
    if (values.actionToPerform === 'RT') {
      viewTransferAction(receivableFCN);
    } else if (values.actionToPerform === 'RR') {
      viewReverseAction(receivableFCN);
    } else if (values.actionToPerform === 'WO') {
      viewBulkWriteOff(receivableFCN);
    } else if (values.actionToPerform === 'WR') {
      viewBulkWriteOffReest(receivableFCN)
    }else if(values.actionToPerform === 'CP'){
      viewChangePayeeStatus(receivableFCN)
    }
  };
  /* This function is used to set established from date using date picker */
  const handleFromDateChange = date => {
    setSelectedFromDate(date);
    setFromDatePress(false);
  };

  /* This function is used to set established from date using keyboard or key press */
  const handleFromDateText = fromDateText => {
    setSelectedFromDate(fromDateText.target.value);
    setFromDatePress(true);
  };

  /* This function is used to set established thru date using date picker */
  const handleThruDateChange = date => {
    setSelectedThruDate(date);
    setThruDatePress(false);
  };

  /* This function is used to set established thru date using keyboard or key press */
  const handleThruDateText = thruDateText => {
    setSelectedThruDate(thruDateText.target.value);
    setThruDatePress(true);
  };

  /* This function is used to set last date using date picker */
  const handleLastDateChange = date => {
    setSelectedLastDate(date);
    setLastDatePress(false);
  };

  /* This function is used to set last date using keyboard or key press */
  const handleLastDateText = lastDateText => {
    setSelectedLastDate(lastDateText.target.value);
    setLastDatePress(true);
  };

  const [
    {
      showFCNError,
      showMCNError,
      showLOBError,
      showPayeeError,
      showThruDateError,
      showFromDateError,
      showPayeeIdTypeCodeError,
      showPayeeTypeError,
      showThDateError,
      showFrDateError
    },
    setShowError
  ] = React.useState(false);

  const showFieldError = (
    fcnVal,
    mcnVal,
    lobVal,
    payeeVal,
    thruDateVal,
    fromDateVal,
    lastDateVal,
    payeeTypeVal,
    payeeIdCodeVal,
    dateThVal,
    dateFrVal
  ) => {
    setShowError({
      showFCNError: fcnVal,
      showMCNError: mcnVal,
      showLOBError: lobVal,
      showPayeeError: payeeVal,
      showThruDateError: thruDateVal,
      showFromDateError: fromDateVal,
      showLastDateError: lastDateVal,
      showPayeeTypeError: payeeTypeVal,
      showPayeeIdTypeCodeError: payeeIdCodeVal,
      showThDateError:dateThVal,
      showFrDateError:dateFrVal
    });
  };

  /* Setting values array initially with some default inputs */
  const [values, setValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    payee: '',
    id: '',
    fundCode: DEFAULT_DD_VALUE,
    lastDate: '',
    payeeIdCode: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE,
    actionToPerform: 'RT'
  });

  /* Setting payee type and payee id code values array initially with some default inputs */
  const [payeeValues, setPayeeValues] = React.useState({
    payeeIdCode: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE
  });

  /* Setting payee type and payee id code values array initially with some default inputs */
  const [
    activityReasonCodeValues,
    setActivityReasonCodeValues
  ] = React.useState({
    activityCode: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE
  });

  const [fcnId, setFcnId] = React.useState([]);

  /* This function is used to handle and set the outstanding balance checkbox */
  const handleOutstandingBalanceCheck = () => {
    setOutstandingBalanceCheck(!outstandingBalanceCheck);
  };
  const [selectedPayeeType, setSelectedPayeetype] = React.useState('');
  const [selectedPayeeTypecd, setSelectedPayeeTypCd] = React.useState('');
  const [showPayeePaymentStatus,setShowPayeePaymentStatus]=React.useState('')
  const searchSystemParameterActionData = {
    parameterNumber: "9047", functionalArea: "F1"
  }
  const searchSystemParamForBulkMain = value => {
    dispatch(searchSystemParameterAction9047(value));
  };
  const systemParamaterPayload = useSelector(state => state.appConfigState?.systemParameterState?.data);
  /* This function is used to handle and set the values of fields selected by user */
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
    if (name === 'payeeType') {
      payeeTypeData.map((value) => {
        if (value.code === event.target.value) {
          setSelectedPayeetype(value.description);
        }
      });
    }
    if (name === 'payeeIdCode') {
      payeeTypeCodeData.map((value) => {
        if (value.code === event.target.value) {
          setSelectedPayeeTypCd(value.description);
        }
      });
    }
  };

  useEffect(() => {
    setValues({ ...values });
    if (values.payeeType && values.payeeType !== '' && values.payeeType !== DEFAULT_DD_VALUE) {
      onSearchPayeeIdType(values.payeeType);
    }
  }, [values.payeeType]);

  useEffect(()=>{
    if(systemParamaterPayload){
      setShowPayeePaymentStatus(systemParamaterPayload[0]?.systemParameterDetail[0]?.valueData)
    }
  },[systemParamaterPayload])

  /* This function is used to handle and set the values of payee id type code based on payee type selected by user */
  const handleChangePayeeType = name => event => {
    setPayeeValues({ ...values, [name]: event.target.value });

    if (name === ARSearchConstants.PAYEE_TYPE) {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        setShowTable(false);
        const valuetoredirect = redirect - 1;
        setRedirect(valuetoredirect);
        onSearchPayeeIdType(event.target.value);
      }
      if (event.target.value === DEFAULT_DD_VALUE) {
        setPayeeValues({
          payeeIdCode: DEFAULT_DD_VALUE,
          payeeType: DEFAULT_DD_VALUE
        });
        setPayeeTypeCodeData([]);
      }
    } else if (event.target.value === DEFAULT_DD_VALUE) {
      setPayeeValues({
        payeeIdCode: DEFAULT_DD_VALUE,
        payeeType: payeeValues.payeeType
      });
    } else {
      setPayeeValues({
        payeeIdCode: event.target.value,
        payeeType: payeeValues.payeeType
      });
    }
  };

  /* This function is used to handle and set the values of reason code based on activity type selected by user and vice versa */
  const handleChangeActivityType = name => event => {
    setActivityReasonCodeValues({ ...values, [name]: event.target.value });

    if (name === ARSearchConstants.ACTIVITY_CODE) {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        getReasonCodeMapData(event.target.value);
        setActivityReasonCodeValues({
          activityCode: event.target.value,
          reasonCode: DEFAULT_DD_VALUE
        });
        activityValue = event.target.value;
      } else {
        setActivityReasonCodeValues({
          activityCode: DEFAULT_DD_VALUE,
          reasonCode: DEFAULT_DD_VALUE
        });
        setReasonCodeVal(initialReasonCodeArray)
      }
    } else if (name === ARSearchConstants.REASON_CODE) {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        if (event.target.value === '107' || event.target.value === '108') {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[0].code,
            reasonCode: event.target.value
          });
        } else if (event.target.value === '119' || event.target.value === '117') {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[1].code,
            reasonCode: event.target.value
          });
        } else if (event.target.value === '116' || event.target.value === '145' || event.target.value === '147') {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[3].code,
            reasonCode: event.target.value
          });
        } else if (event.target.value === '120') {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[4].code,
            reasonCode: event.target.value
          });
        } else if (event.target.value === '146') {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[5].code,
            reasonCode: event.target.value
          });
        } else if (event.target.value === '400' || event.target.value === '401' || event.target.value === '402' ||
          event.target.value === '403' || event.target.value === '404' || event.target.value === '406' || event.target.value === '408') {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[6].code,
            reasonCode: event.target.value
          });
        } else if (event.target.value === '500' || event.target.value === '501' || event.target.value === '502' ||
          event.target.value === '503' || event.target.value === '504' || event.target.value === '505' || event.target.value === '506') {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[7].code,
            reasonCode: event.target.value
          });
        } else if (event.target.value === '550' || event.target.value === '551' || event.target.value === '552' ||
          event.target.value === '553' || event.target.value === '554' || event.target.value === '555' || event.target.value === '556') {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[8].code,
            reasonCode: event.target.value
          });
        } else {
          setActivityReasonCodeValues({
            activityCode: activityTypeData[2].code,
            reasonCode: event.target.value
          });
        }
      } else {
        setActivityReasonCodeValues({
          activityCode: activityReasonCodeValues.activityCode,
          reasonCode: DEFAULT_DD_VALUE
        });
      }
    }
  };

  /* This function is used to resetting values, error messages and search results to its default state */
  const resetPayee = () => {
    onReset();
    setOutstandingBalanceCheck(true);
    setValues({
      lob: DEFAULT_DD_VALUE,
      payee: '',
      id: '',
      fundCode: DEFAULT_DD_VALUE,
      lastDate: '',
      payeeIdCode: DEFAULT_DD_VALUE,
      payeeType: DEFAULT_DD_VALUE,
      actionToPerform: 'RT'
    });
    setPayeeValues({
      payeeIdCode: DEFAULT_DD_VALUE,
      payeeType: DEFAULT_DD_VALUE
    });
    setActivityReasonCodeValues({
      activityCode: DEFAULT_DD_VALUE,
      reasonCode: DEFAULT_DD_VALUE
    });

    setSelectedFromDate(null);
    setSelectedThruDate('12/31/9999');
    setSelectedLastDate(null);
    setPayeeTypeCodeData([]);
    setReasonCodeVal(initialReasonCodeArray)
    setShowTable(false);
    errorMessagesArray = [];
    showFieldError(
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    );
    seterrorMessages(errorMessagesArray);
    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };

  /* Check if text enetered is empty or contains only white spaces */
  const isNullOrEmpty = str => {
    return !str || !str.trim();
  };

  /* This function is used to validate all input fields and search payee/payer if validations passed */
  const searchPayee = () => {
    var showLOBError = false;
    var showPayeeError = false;
    var showPayeeIdTypeCodeError = false;
    var showPayeeTypeError = false;
    var fromDateTextFormat, thruDateTextFormat, lastDateTextFormat;
    var showFromDateError = false;
    var showThruDateError = false;
    var showLastDateError = false;
    var showThDateError  =false;
    var showFrDateError  =false;
    errorMessagesArray = [];
    payloadPayees = [];
    showLoader(false);
    setShowTable(false);

    if (!values.lob || values.lob === DEFAULT_DD_VALUE) {
      setShowTable(false);
      showLOBError = true;
      errorMessagesArray.push(ARSearchConstants.LOB_REQUIRED);
      seterrorMessages(errorMessagesArray);
    }
    if (values.actionToPerform === 'RT' || values.actionToPerform === 'WR' || values.actionToPerform === 'CP') {
      if (!values.payeeType || values.payeeType === DEFAULT_DD_VALUE) {
        setShowTable(false);
        showPayeeTypeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_TYPE_REQUIRED);
        setPayeeTypeErrorMeg(ARSearchConstants.PAYEE_TYPE_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
      if (
        !values.payeeIdCode ||
        values.payeeIdCode === DEFAULT_DD_VALUE
      ) {
        setShowTable(false);
        showPayeeIdTypeCodeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED);
        setPayeeCodeErrorMeg(ARSearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
      if (values.payee.trim() === null || values.payee.trim() === '') {
        setShowTable(false);
        showPayeeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_REQUIRED);
        setErrorMeg(ARSearchConstants.PAYEE_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
    } else if (
      (values.payeeType &&
        values.payeeType !== DEFAULT_DD_VALUE) ||
      (values.payeeIdCode &&
        values.payeeIdCode !== DEFAULT_DD_VALUE) ||
      (values.payee.trim() !== null && values.payee.trim() !== '')
    ) {
      if (
        !values.payeeType ||
        values.payeeType === DEFAULT_DD_VALUE
      ) {
        setShowTable(false);
        showPayeeTypeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_TYPE_REQUIRED);
        setPayeeTypeErrorMeg(ARSearchConstants.PAYEE_TYPE_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
      if (
        !values.payeeIdCode ||
        values.payeeIdCode === DEFAULT_DD_VALUE
      ) {
        setShowTable(false);
        showPayeeIdTypeCodeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED);
        setPayeeCodeErrorMeg(ARSearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
      if (values.payee.trim() === null || values.payee.trim() === '') {
        setShowTable(false);
        showPayeeError = true;
        errorMessagesArray.push(ARSearchConstants.PAYEE_REQUIRED);
        setErrorMeg(ARSearchConstants.PAYEE_REQUIRED);
        seterrorMessages(errorMessagesArray);
      }
    }

    /* if user enters any text in from date field it will validate that entered text for a valid date format */
    if (selectedFromDate) {
      if (fromDatePress) {
        fromDateTextFormat = selectedFromDate;
      }
    }

    /* if user enters any text in thru date field it will validate that entered text for a valid date format */
    if (selectedThruDate) {
      if (thruDatePress) {
        thruDateTextFormat = selectedThruDate;
      }

      let isDateInValid = false;
      if (checkForValidDateString(getDateInMMDDYYYYFormat(selectedThruDate))) {
        isDateInValid = validateDateMinimumValue(
          getDateInMMDDYYYYFormat(selectedThruDate)
        );
        if (isDateInValid) {
          showThruDateError = true;
          setErrorMegThruDate(AppConstants.DATE_ERROR_1964);
          errorMessagesArray.push(AppConstants.DATE_ERROR_1964);
          seterrorMessages(errorMessagesArray);
        }
      }
    }

    /* if user enters any text in last date field it will validate that entered text for a valid date format */
    if (selectedLastDate) {
      if (lastDatePress) {
        lastDateTextFormat = selectedLastDate;
      }
    }
    if (
      (selectedFromDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedFromDate))) ||
      (selectedThruDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedThruDate))) ||
      (selectedLastDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedLastDate)))
    ) {
      setErrorMegFromDate(AppConstants.INCORRECT_DATA_FORMAT);
      errorMessagesArray.push(AppConstants.INCORRECT_DATA_FORMAT);
      seterrorMessages(errorMessagesArray);
    }

    if (
     (selectedThruDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedThruDate)))
    ) {
      showThDateError= true;
    }
    if (
      (selectedFromDate && !checkForValidDateString(getDateInMMDDYYYYFormat(selectedFromDate)))) {
      showFrDateError= true;
    }

    /* if user selects from date and thru date, compare dates */
    if (selectedFromDate && selectedThruDate) {
      var fromDate = new Date(selectedFromDate);
      var thruDate = new Date(selectedThruDate);
      if (!compareTwoDates(fromDate, thruDate)) {
        setErrorMegFromDate(ARSearchConstants.DATE_GREATER_ERROR);
        setErrorMegThruDate(ARSearchConstants.DATE_GREATER_ERROR2);
        errorMessagesArray.push(ARSearchConstants.DATE_GREATER_ERROR);
        errorMessagesArray.push(ARSearchConstants.DATE_GREATER_ERROR2);
        seterrorMessages(errorMessagesArray);
        showFromDateError = true;
        showThruDateError = true;
      }
    }

    showFieldError(
      false,
      false,
      showLOBError,
      showPayeeError,
      showThruDateError,
      showFromDateError,
      showLastDateError,
      showPayeeTypeError,
      showPayeeIdTypeCodeError,
      showThDateError,
      showFrDateError
    );

    /* if all validations passed on click of Search button it will hit the API to search for a payee/payer */
    if (errorMessagesArray.length === 0) {
      setIsSearch(true);
      errorMessagesArray = [];
      seterrorMessages(errorMessagesArray);
      onReset();
      const searchCriteria = {
        lob:
          values.lob !== DEFAULT_DD_VALUE ? [values.lob] : null,
        payeePayerID: values.payee,
        activityType:
          activityReasonCodeValues.activityCode !==
            DEFAULT_DD_VALUE
            ? activityReasonCodeValues.activityCode
            : null,
        fundCode:
          values.fundCode !== DEFAULT_DD_VALUE
            ? values.fundCode
            : null,
        payeePayerTypeCode:
          values.payeeIdCode !== DEFAULT_DD_VALUE
            ? values.payeeIdCode
            : null,
        reasonCode:
          activityReasonCodeValues.reasonCode !== DEFAULT_DD_VALUE
            ? activityReasonCodeValues.reasonCode
            : null,
        payeePayerType:
          values.payeeType !== DEFAULT_DD_VALUE
            ? values.payeeType
            : null,
        establishedDateFrom:
          selectedFromDate
            ? moment(selectedFromDate).format('MM/DD/YYYY')
            : null,
        establishedDateTo:
          selectedThruDate
            ? moment(selectedThruDate).format('MM/DD/YYYY')
            : null,
        payeeSearch: true,
        outstandingBalanceIndicator: outstandingBalanceCheck,
        actionPerformed: values.actionToPerform
      };

      onSearch(removeLeadingTrailingSpaces(searchCriteria));
      showLoader(true);
    }
  };
  const showLoader = value => {
    setSpinnerLoader(value);
  };

  const rowDeselect = data => {
    setTableData({
      ...tableData,
      tableData: data
    });
  };

  const valuesFromBulkMaintenance = props.history && props.history.location && props.history.location.state && props.history.location.state.valuesFromBulkMaintenance;
  useEffect(() => {
     
    if (props.location.tableRender && !isSearch) {
      if (valuesFromBulkMaintenance !== undefined) {
        errorMessagesArray = [];
        seterrorMessages(errorMessagesArray);
        onReset();
        const searchCriteria = {
          lob:
            valuesFromBulkMaintenance.lob !== DEFAULT_DD_VALUE ? [valuesFromBulkMaintenance.lob] : null,
          payeePayerID: valuesFromBulkMaintenance.payee,
          activityType: valuesFromBulkMaintenance.activityCode === DEFAULT_DD_VALUE
            ? null : valuesFromBulkMaintenance.activityCode,
          fundCode:
            valuesFromBulkMaintenance.fundCode !== DEFAULT_DD_VALUE
            ? valuesFromBulkMaintenance.fundCode
            : null,
          payeePayerTypeCode:
            valuesFromBulkMaintenance.payeeIdCode !== DEFAULT_DD_VALUE
            ? valuesFromBulkMaintenance.payeeIdCode
            : null,
          reasonCode:
            valuesFromBulkMaintenance.reasonCode === DEFAULT_DD_VALUE ? null : valuesFromBulkMaintenance.reasonCode,
          payeePayerType:
            valuesFromBulkMaintenance.payeeType !== DEFAULT_DD_VALUE
            ? valuesFromBulkMaintenance.payeeType
            : null,
          establishedDateFrom:
          valuesFromBulkMaintenance.selectedFromDate
            ? moment(valuesFromBulkMaintenance.selectedFromDate).format('MM/DD/YYYY')
            : null,
          establishedDateTo:
          valuesFromBulkMaintenance.selectedThruDate
            ? moment(valuesFromBulkMaintenance.selectedThruDate).format('MM/DD/YYYY')
            : null,
          payeeSearch: true,
          outstandingBalanceIndicator: valuesFromBulkMaintenance.outstandingBalanceCheck,
          actionPerformed: valuesFromBulkMaintenance.actionToPerform
        };
        onSearch(removeLeadingTrailingSpaces(searchCriteria));
        showLoader(true);
        setValues(valuesFromBulkMaintenance);
        setActivityReasonCodeValues({
          activityCode: valuesFromBulkMaintenance.activityCode || DEFAULT_DD_VALUE,
          reasonCode: valuesFromBulkMaintenance.reasonCode || DEFAULT_DD_VALUE
        });
        setSelectedFromDate(valuesFromBulkMaintenance.selectedFromDate);
        setSelectedThruDate(valuesFromBulkMaintenance.selectedThruDate);
        setSelectedPayeetype(searchCriteria.payeePayerType);
        setSelectedPayeeTypCd(searchCriteria.payeePayerTypeCode);
      };
    }
  }, [props.location.tableRender]);

  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <div className="float-left tab-heading">
            Search Accounts Receivable for bulk AR Actions
          </div>
        </div>

        <div className="clearfix"></div>

        <form noValidate autoComplete="off">
          <div className="row">
            <div className="col-9">
              <div className="form-wrapper">
                <NativeDropDown
                  className='override-width-28'
                    id="lob"
                  isRequired
                    label="LOB"
                    value={values.lob}
                    onChange={handleChange('lob')}
                  errTxt={
                      showLOBError ? ARSearchConstants.LOB_REQUIRED : null
                    }
                  dropdownList={lobData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />

                <NativeDropDown
                  className='override-width-28'
                  id="payeeType"
                    label="Payee Type"
                    value={values.payeeType}
                    onChange={handleChange('payeeType')}
                  isRequired={values.actionToPerform === 'RT' || values.actionToPerform === 'WR' || values.actionToPerform === 'CP'}
                  errTxt={showPayeeTypeError ? errorTypeMeg : null}
                  dropdownList={payeeTypeData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />

                <NativeDropDown
                  className='override-width-28'
                    id="payeeIdCode"
                  isRequired={values.actionToPerform === 'RT' || values.actionToPerform === 'WR' || values.actionToPerform === 'CP'}
                    label="Payee ID Type Code"
                    value={values.payeeIdCode}
                  disabled={values.payeeType === DEFAULT_DD_VALUE}
                    onChange={handleChange('payeeIdCode')}
                  errTxt={showPayeeIdTypeCodeError ? errorCodeMeg : null}
                  dropdownList={payeeTypeCodeData ? payeeTypeCodeData.map(option => (
                    <option key={option.code} value={option.code}>
                        {option.description}
                    </option>
                    )) : null}
                />

              </div>
              <div className="form-wrapper">
                <NativeDropDown
                  className='override-width-28'
                  id="activityCode"
                  label="AR Group Type"
                  inputProps={{"data-testid":"test_activity_cd"}}
                    value={activityReasonCodeValues.activityCode}
                    onChange={handleChangeActivityType('activityCode')}
                  dropdownList={activityTypeData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />

                <NativeDropDown
                  className='override-width-28'
                  id="reasonCode"
                    label="Reason Code"
                    inputProps={{"data-testid":"test_reason_cd"}}
                    value={activityReasonCodeValues.reasonCode}
                  onChange={handleChangeActivityType('reasonCode')}
                    title={getLongDescription(reasonCodeData, activityReasonCodeValues.reasonCode)}
                  dropdownList={reasonCodeData.map((item, index) => (
                    <option key={index} value={item.code}>
                        {item.description}
                    </option>
                    ))}
                />

                <NativeDropDown
                  className='override-width-28'
                  id="fundCode"
                    label="Fund Code"
                    value={values.fundCode}
                  onChange={handleChange('fundCode')}
                    title={getLongDescription(fundCodeData, values.fundCode)}
                  dropdownList={fundCodeData
                      ? fundCodeData.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.description}
                        </option>
                      ))
                      : null}
                />

              </div>
              <div className="form-wrapper">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="mui-custom-form input-xxl with-date override-width-28">
                    <KeyboardDatePicker
                      maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                      id="fromDate"
                      label="Established From Date"
                      format="MM/dd/yyyy"
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="mm/dd/yyyy"
                      value={selectedFromDate}
                      onChange={handleFromDateChange}
                      onKeyUp={handleFromDateText}
                      helperText={showFromDateError ? errorMegFromDate : showFrDateError ? AppConstants.INCORRECT_DATA_FORMAT : null}
                      error={showFromDateError ? errorMegFromDate :showFrDateError ? AppConstants.INCORRECT_DATA_FORMAT : null}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="mui-custom-form input-xxl with-date override-width-28">
                    <KeyboardDatePicker
                      maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                      minDate={Date.parse('01 Jan 0001 00:00:00 GMT')}
                      id="thruDate"
                      label="Established Thru Date"
                      format="MM/dd/yyyy"
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="mm/dd/yyyy"
                      value={selectedThruDate}
                      onChange={handleThruDateChange}
                      onKeyUp={handleThruDateText}
                      helperText={showThruDateError ? errorMegThruDate : showThDateError ? AppConstants.INCORRECT_DATA_FORMAT : null}
                      error={showThruDateError ? errorMegThruDate : showThDateError ? AppConstants.INCORRECT_DATA_FORMAT : null}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
              <div>
              </div>
            </div>
            <div className='col-3'>
              <div className='form-wrapper'>

                <div className="mui-custom-form input-md w-100 mapsettablelist">
                  <TextField
                    id="payee"
                    fullWidth
                    label="Payee ID"
                    InputLabelProps={{
                      shrink: true
                    }}
                    required={values.actionToPerform === 'RT' || values.actionToPerform === 'WR' || values.actionToPerform === 'CP'}
                    value={values.payee}
                    onChange={handleChange('payee')}
                    inputProps={{ maxLength: 15 }}
                    helperText={showPayeeError ? errorMeg : null}
                    error={showPayeeError ? errorMeg : null}
                  />
                </div>
              </div>
              <div className="form-wrapper">
                <div className="mui-custom-form w-100 mapsettablelist">
                  <label className="MuiFormLabel-root small-label">
                    Action to perform
                  </label>
                  <div className="sub-radio">
                    <Radio
                      id="voidYesHeaderIdRT"
                      fullWidth
                      name="voidYesNoHeader"
                      value="RT"
                      checked={values.actionToPerform === 'RT'}
                      onChange={handleChange('actionToPerform')}
                    />
                    <label for="voidYesHeaderIdRT" className="text-black">
                      Transfer
                    </label>
                  </div>
                  <div className="sub-radio">
                    <Radio
                      id="voidYesHeaderIdRR"
                      fullWidth
                      name="reverse"
                      value="RR"
                      checked={values.actionToPerform === 'RR'}
                      onChange={handleChange('actionToPerform')}
                    />
                    <label for="voidYesHeaderIdRR" className="text-black">
                      Reverse
                    </label>
                  </div>
                  <div className="sub-radio">
                    <Radio
                      id="writeOffRadio"
                      fullWidth
                      name="writeOff"
                      value="WO"
                      checked={values.actionToPerform === 'WO'}
                      onChange={handleChange('actionToPerform')}
                    />
                    <label for="writeOffRadio" className="text-black">
                      Write-Off
                    </label>
                  </div>
                  <div className="sub-radio">
                    <Radio
                      id="writeOffReestablishmentRadio"
                      fullWidth
                      name="writeOffReestablishment"
                      value="WR"
                      checked={values.actionToPerform === 'WR'}
                      onChange={handleChange('actionToPerform')}
                    />
                    <label for="writeOffReestablishmentRadio" className="text-black">
                      Write-Off Re-Establishment
                    </label>
                  </div>
                  {showPayeePaymentStatus == 'N' ? (
                     <div className="sub-radio">
                     <Radio
                       id="changePayeeStatusRadio"
                       fullWidth
                       name="changePayeeStatus"
                       value="CP"
                       checked={values.actionToPerform === 'CP'}
                       onChange={handleChange('actionToPerform')}
                     />
                      <label for="changePayeeStatusRadio" className="text-black">
                       Change Payee Status
                     </label>
                   </div>
                  ) : null }
                </div>
              </div>
              <div className="float-right mr-3 mb-2">
                <Button style={{ width: '90.8px' }} onClick={() => searchPayee()}>
                  <i class="fa fa-search" aria-hidden="true"></i>
                  Search
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  data-testid='test_reset_btn'
                  className="bt-reset btn-transparent ml-1"
                  style={{ width: '79.69px' }}
                  onClick={() => resetPayee()}
                >
                  <i class="fa fa-undo" aria-hidden="true"></i>
                  Reset
                </Button>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </form>
        <div className='shadow-sm p-3 mb-5 bg-white rounded font-weight-bold' style={{ backgroundColor: '#274463' }}><i className="fa fa-info-circle mr-1" aria-hidden="true"></i>Payee details are mandatory in the search screen for Transfer or Write-Off Re-establishment AR action types.</div>
        {redirect === 1 && showTable ? (
          <div className="tab-holder my-3 p-0 tableNoWrap">
            <BulkSearchTable
              dropdowns={addDropdowns}
              tableData={tableData}
              rowIdsSelected={rowIdsSelected}
              values ={values}
              showPayeePaymentStatus={showPayeePaymentStatus}
            />

            <div className="float-right mt-2">
              <Button className='btn btn-success ml-1' onClick={() => continueTransfer(tableData)}>
                <i class="fa fa-check" aria-hidden="true"></i>
                Continue
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </div>

  );
}
export default withRouter(BulkMaintenance);
