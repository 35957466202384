/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FinancialBudgetSearchResultsTable from './FinancialBudgetSearchTable';
import '../../../App.scss';
import { withRouter } from 'react-router';
import { keySort } from '../../../SharedModules/Table/MultipleTableSort';
import Spinner from '../../../SharedModules/Spinner/Spinner';
import * as BudgetMaintenanceConstants from './BudgetMaintenanceConstants';
import { useDispatch, useSelector } from 'react-redux';
import { searchBudgetMaintenanceAction, resetSearchBudgetMaintenance, getDropdownDataAction } from '../Store/Actions/budgetMaintenance/budgetMaintenanceActions';
import dropdownCriteria from './FinancialBudgetSearch.json';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import { Checkprivileges } from '../../../SharedModules/store/Actions/SharedAction';
import Footer from '../../../SharedModules/Layout/footer';
import moment from 'moment';
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../SharedModules/CommonUtilities/commonUtilities';
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';
import { DEFAULT_LOB_VALUE } from '../../../SharedModules/AppConstants';

function FinancialBudgetSearch(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const dispatch = useDispatch();
  const onReset = () => dispatch(resetSearchBudgetMaintenance());
  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  const [isSearch, setIsSearch] = React.useState(false);
  const [redirect, setRedirect] = React.useState(0);
  const [showTable, setShowTable] = React.useState(false);
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  const [fundCodeStartsWith, setFundCodeStartsWith] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [showNoRecords, setShowNoRecords] = React.useState(false);
  const [fiscalYearData, setFiscalYearData] = React.useState([]);
  const [unitData, setUnitData] = React.useState([]);
  const [companyData, setCompanyData] = React.useState([]);
  const [agencyData, setAgencyData] = React.useState([]);
  const [cosData, setCosData] = React.useState([]);
  const [{ showFundCodeError }, setShowError] = React.useState(false);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [values, setValues] = React.useState({
    budget_maintainance_lob: DEFAULT_DD_VALUE,
    state_fiscal_year: DEFAULT_DD_VALUE,
    agency: DEFAULT_DD_VALUE,
    gl_company: DEFAULT_DD_VALUE,
    gl_accounting_unit: DEFAULT_DD_VALUE,
    fund_code: '',
    starts_with: '',
    cos: DEFAULT_DD_VALUE
  });
  let payload = [];
  let errorMessagesArray = [];
  
  const toPrintRef = useRef();  
  const onSearch = (values, fundCodeStartsWith) => {
    dispatch(searchBudgetMaintenanceAction(values, fundCodeStartsWith));
    setIsSearch(true); 
}
  payload = useSelector(state => state.budgetMaintenanceState.payload);
  const [searchData, setSearchData] = React.useState([])

  const payloadData = payload ? payload[0] : {};
  const dropdown = useSelector(state => state.budgetMaintenanceState.dropdownData);

  useEffect(() => {
    if (payload) {
      if (payload.systemFailure) {
        setSearchData([]);
        setSpinnerLoader(false);
        errorMessagesArray.push(budgetMaintenanceConstants.SYSTEM_ERROR);
        seterrorMessages(errorMessagesArray);
        setShowTable(false);
      } else if (payload === null || payload.length === 0) {
        setSearchData([]);
        errorMessagesArray.push(budgetMaintenanceConstants.NO_RECORD_FOUND);
        seterrorMessages(errorMessagesArray);
        setShowTable(false);
        setSpinnerLoader(false);
        setShowNoRecords(true);
      } else if (payload.length > 0) {
        if(payload.length ===1){
          payload[0].fundCode = setValuesFromBudgetM?.fund_code
          payload[0].cos = setValuesFromBudgetM?.cos === DEFAULT_DD_VALUE ? '' : setValuesFromBudgetM?.cos
          setSearchData(payload);
        }else{
          setSearchData(payload);
        }
        setSpinnerLoader(false);
        payload.map((data, index) => {
          if (fundCodeDD && fundCodeDD.length > 0 && data.fundCode) {
            fundCodeDD.map(fcdd => {
              if (fcdd.code === data.fundCode) {
                data.fundCodelongDesc = fcdd.longDescription
              }
            })
          }
          if (cosData && cosData.length > 0 && data.cos) {
            cosData.map(fcdd => {
              if (fcdd.code === data.cos) {
                data.COSCodelongDesc = fcdd.longDescription
              }
            })
          }
          if (data.lobCode != null && data.lobCode !== undefined) {
            // eslint-disable-next-line no-self-assign
            data.lob = data.lobCode;
          } 
          if (data.voidDate) {
            data.voidDate = moment(new Date(data.voidDate)).format('L');
          }
        });
        setShowTable(true);
        let valuetoredirect = 0;
        valuetoredirect = valuetoredirect + 1;
        setRedirect(valuetoredirect);
      }
    }
  }, [payload]);

  useEffect(() => {
    setSearchData([]);
    onReset();
    getDropdownData(dropdownCriteria);
    onDropdowns([
      Dropdowns.GL_CO_CD,
      Dropdowns.F_FSCL_YR_NUM,
      Dropdowns.AGNCY_CODE,
      Dropdowns.F_ORG_CD,
      Dropdowns.FUND_CODE,
      Dropdowns.COS,
      Dropdowns.LOB_CODE,
    ]);
  }, []);

  const [fundCodeDD, setFundCodeDD] = React.useState([]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1042']) {
        setFundCodeDD(dropdown.listObj['Reference#1042']);
      }
      if (dropdown.listObj['Financial#1021']) {
        setFiscalYearData(dropdown.listObj['Financial#1021'].sort((a, b) => a.code.toLocaleUpperCase() < b.code.toLocaleUpperCase() ? 1 : -1));
      }
      if (dropdown.listObj['Financial#1125']) {
        setAgencyData(dropdown.listObj['Financial#1125']);
      }
      if (dropdown.listObj['Financial#1124']) {
        setCompanyData(dropdown.listObj['Financial#1124']);
      }
      if (dropdown.listObj['Financial#1016']) {
        setUnitData(dropdown.listObj['Financial#1016']);
      }
      if (dropdown.listObj['Claims#1020']) {
        setCosData(dropdown.listObj['Claims#1020']);
      }
    }
  }, [dropdown]);

  if (redirect === 1) {
    if (payload != null) {
      if (payload.length === 1 && !props.location.tableRender) {
        props.history.push({
          pathname: '/BudgetDataUpdate',
          state: { payloadData, values: values }
        });
      }
    }
  }

  const setValuesFromBudgetM = props?.history?.state?.values;

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (setValuesFromBudgetM !== undefined) {
        seterrorMessages([]);
        setSpinnerLoader(true);
        const searchCriteria = {
          lob: DEFAULT_LOB_VALUE,
          fiscalYear: setValuesFromBudgetM.fiscalYear !== DEFAULT_DD_VALUE ? setValuesFromBudgetM.fiscalYear : null,
          agencyCode: setValuesFromBudgetM.agencyCode !== DEFAULT_DD_VALUE ? setValuesFromBudgetM.agencyCode : null,
          fundCode: setValuesFromBudgetM.fundCode !== '' ? setValuesFromBudgetM.fundCode : null,
          cos: setValuesFromBudgetM.cos !== DEFAULT_DD_VALUE ? setValuesFromBudgetM.cos : null,
          glCompany: setValuesFromBudgetM.glCompany !== DEFAULT_DD_VALUE ? setValuesFromBudgetM.glCompany : null,
          fundCodeStartsWith: setValuesFromBudgetM.fundCodeStartsWith,
          organizationCode: setValuesFromBudgetM.organizationCode !== DEFAULT_DD_VALUE ? setValuesFromBudgetM.organizationCode : null,
          componentCode: null,
          subAppropriationCode: null,
          classCode: null
        };
        setFundCodeStartsWith(setValuesFromBudgetM.fundCodeStartsWith)
        onSearch(removeLeadingTrailingSpaces(searchCriteria), fundCodeStartsWith);
        setValues(
          {
            budget_maintainance_lob: setValuesFromBudgetM.lob || DEFAULT_DD_VALUE,
            state_fiscal_year: setValuesFromBudgetM.fiscalYear || DEFAULT_DD_VALUE,
            agency: setValuesFromBudgetM.agencyCode || DEFAULT_DD_VALUE,
            gl_company: setValuesFromBudgetM.glCompany || DEFAULT_DD_VALUE,
            gl_accounting_unit: setValuesFromBudgetM.organizationCode || DEFAULT_DD_VALUE,
          fund_code: setValuesFromBudgetM.fundCode || '',
          starts_with: setValuesFromBudgetM.fundCodeStartsWith || '',
            cos: setValuesFromBudgetM.cos || DEFAULT_DD_VALUE
        });
        setShowTable(true);        
      }
    }
  }, [props.location.tableRender]);


  const budgetMaintenanceConstants = BudgetMaintenanceConstants;

  const handleChanges = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleChangeFundCode = () => {
    setFundCodeStartsWith(!fundCodeStartsWith);
    setValues({...values,starts_with:!fundCodeStartsWith})
  };

  const resetTable = () => {
    onReset();
    setShowNoRecords(false);
    seterrorMessages([]);
    setFundCodeStartsWith(false);
    setShowError({ showFundCodeError: false });
    setValues({
      budget_maintainance_lob: DEFAULT_DD_VALUE,
      state_fiscal_year: DEFAULT_DD_VALUE,
      agency: DEFAULT_DD_VALUE,
      gl_accounting_unit: DEFAULT_DD_VALUE,
      cos: DEFAULT_DD_VALUE,
      gl_company: DEFAULT_DD_VALUE,
      fund_code: ''
    });

    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };

  const searchBudgetData = () => {
    setSpinnerLoader(false);
    errorMessagesArray = [];
    seterrorMessages([]);
    var showFundCodeError = false;
    props.location.tableRender = false;
    payload = [];
    setShowTable(false);

    if (fundCodeStartsWith && (!values.fund_code || values.fund_code.length < 2)) {
      showFundCodeError = true;
      errorMessagesArray.push(budgetMaintenanceConstants.FUNDCODE_ERROR);
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
    } else if (errorMessagesArray.length === 0) {
      setSpinnerLoader(true);

      const searchCriteria = {
        lob: DEFAULT_LOB_VALUE,
        fiscalYear: values.state_fiscal_year !== DEFAULT_DD_VALUE ? values.state_fiscal_year : null,
        agencyCode: values.agency !== DEFAULT_DD_VALUE ? values.agency : null,
        fundCode: values.fund_code !== '' ? values.fund_code : null,
        cos: values.cos !== DEFAULT_DD_VALUE ? values.cos : null,
        glCompany: values.gl_company !== DEFAULT_DD_VALUE ? values.gl_company : null,
        fundCodeStartsWith: fundCodeStartsWith,
        organizationCode: values.gl_accounting_unit !== DEFAULT_DD_VALUE ? values.gl_accounting_unit : null,
        componentCode: null,
        subAppropriationCode: null,
        classCode: null
      };

      onSearch(removeLeadingTrailingSpaces(searchCriteria), fundCodeStartsWith);
      if(!props.history.state){
        props.history.state = {}
      }
      props.history.state.values = searchCriteria
    }
    setShowError({
      showFundCodeError: showFundCodeError
    });
  };

  const addFBudget = () => {
    props.history.push({
      pathname: '/BudgetDataAdd'
    });
  };


  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef} >
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Search Budget Data
          </h1>
          <div className="float-right mt-1 pt-1">
            <Button
              title="Add Budget Maintenance"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addFBudget()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>

          </div>
          <div className="clearfix"></div>
        </div>
        <div>
          <div className="tab-body">
            <div className='form-wrapper'>
              <NativeDropDown
                id="state_fiscal_year"
                  name='state_fiscal_year'
                  label="State Fiscal Year"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.state_fiscal_year}
                  onChange={handleChanges('state_fiscal_year')}
                dropdownList={fiscalYearData.map((item, index) => (
                  <option key={index} value={item.code}>
                      {item.description}
                  </option>
                  ))}
              />
              <NativeDropDown
                id="gl_company"
                  label="GL Company"
                  name='gl_company'
                  value={values.gl_company}
                  onChange={handleChanges('gl_company')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 4
                }}
                dropdownList={companyData ? companyData.map((item, index) => (
                  <option key={index} value={item.code}>
                      {item.description}
                  </option>
                  )) : null}
              />
              <NativeDropDown
                id="gl_accounting_unit"
                  name='gl_accounting_unit'
                  label="GL Accounting Unit"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.gl_accounting_unit}
                onChange={handleChanges('gl_accounting_unit')}
                dropdownList={unitData.map((item, index) => (
                  <option key={index} value={item.code}>
                      {item.description}
                  </option>
                  ))}
              />

              <div className="mui-custom-form input-sm">
                <TextField
                  id="fund_code"
                  fullWidth
                  label="Fund Code"
                  name='fund_code'
                  value={values.fund_code}
                  onChange={handleChanges('fund_code')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{
                    maxLength: 10
                  }}
                  title={getLongDescription(fundCodeDD, values.fund_code)}
                  helperText={showFundCodeError ? budgetMaintenanceConstants.FUNDCODE_ERROR : null}
                  error={showFundCodeError ? budgetMaintenanceConstants.FUNDCODE_ERROR : null}
                />
                <br />
                <div className="sub-radio">
                  <FormControlLabel
                    control={
                      <Checkbox color="primary" checked={fundCodeStartsWith || values.starts_with} value={fundCodeStartsWith || values.starts_with} onChange={handleChangeFundCode} />
                    }
                    label="Starts With"
                    id="starts_what"
                    value={values.starts_with}
                  />
                </div>

              </div>

              <NativeDropDown
                id="agency"
                  name='agency'
                  label="Agency"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.agency}
                onChange={handleChanges('agency')}
                dropdownList={agencyData ? agencyData.map((item, index) => (
                  <option key={index} value={item.code}>
                      {item.description}
                  </option>
                  )) : null}
              />
              <NativeDropDown
                id="cos"
                  name='cos'
                  label="COS"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.cos}
                  onChange={handleChanges('cos')}
                  title={getLongDescription(cosData, values.cos)}
                dropdownList={cosData ? cosData.map((item, index) => (
                  <option key={index} value={item.code}>
                      {item.description}
                  </option>
                  )) : null}
              />
            </div>
            <div className="float-right mb-2 mr-3">
              <Button variant="outlined" color="primary" className='btn btn-primary'
                onClick={() => searchBudgetData()}
              >  <i className="fa fa-search" aria-hidden="true"></i>
                Search
              </Button>
              <Button variant="outlined" color="primary" className='bt-reset btn-transparent ml-1'
                onClick={() => resetTable()}
              > <i className="fa fa-undo" aria-hidden="true"></i>
                Reset
              </Button>
            </div>
            <div className="clearfix"></div>

            {
              (redirect === 1 && showTable)
                ? <div
                  className="tab-holder tableNoWrap" >
                  <FinancialBudgetSearchResultsTable tableData={searchData ? searchData.keySort({ fiscalYear: 'desc', fundCode: 'asc' }) || [] : []} values = {values} dropdowns={addDropdowns}/>
                </div>
                : null
            }
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}

export default withRouter(FinancialBudgetSearch);
