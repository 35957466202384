/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { withRouter } from 'react-router';

import { systemParameterRowClickAction } from '../../Store/Actions/TextManagement/TextManagementActions';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCellsMultiRecord = [
  {
    id: 'eombFromCode',
    disablePadding: false,
    label: 'From Code',
    width: '20%',
    isVarchar: true
  },
  {
    id: 'procedureModifierCode1',
    disablePadding: false,
    label: 'Mod 1',
    width: '10%',
    isVarChar: true
  },
  {
    id: 'procedureModifierCode2',
    disablePadding: false,
    label: 'Mod 2',
    width: '10%',
    isVarChar: true
  },
  {
    id: 'eombThruCode',
    disablePadding: false,
    label: 'Thru Code',
    width: '20%',
    isVarchar: true
  },
  {
    id: 'thruProcedureModifierCode1',
    disablePadding: false,
    label: 'Mod 1',
    width: '10%',
    isVarChar: true
  },
  {
    id: 'thruProcedureModifierCode2',
    disablePadding: false,
    label: 'Mod 2',
    width: '10%',
    isVarChar: true
  },
  {
    id: 'eombDesc',
    disablePadding: false,
    label: 'Text',
    isProviderNotice: true,
    width: '20%'
  }
];

const headCellsSingleRecord = [
  {
    id: 'eombFromCode',
    disablePadding: false,
    label: 'From Code',
    width: '20%',
    isVarchar: true
  },
  {
    id: 'procedureModifierCode1',
    disablePadding: false,
    label: 'Mod 1',
    width: '10%',
    isVarChar: true
  },
  {
    id: 'procedureModifierCode2',
    disablePadding: false,
    label: 'Mod 2',
    width: '10%',
    isVarChar: true
  },
  {
    id: 'eombThruCode',
    disablePadding: false,
    label: 'Thru Code',
    width: '20%',
    isVarchar: true
  },
  {
    id: 'thruProcedureModifierCode1',
    disablePadding: false,
    label: 'Mod 1',
    width: '10%',
    isVarChar: true
  },
  {
    id: 'thruProcedureModifierCode2',
    disablePadding: false,
    label: 'Mod 2',
    width: '10%',
    isVarChar: true
  },
  {
    id: 'eombDesc',
    disablePadding: false,
    label: 'Text',
    isProviderNotice: true,
    width: '20%'
  }
];

function ProviderNoticeTable (props) {
  const [redirect, setRedirect] = React.useState(false);
  const { setspinnerLoader } = props;
  // API CAll
  const dispatch = useDispatch();
  const onRowClick = values => dispatch(systemParameterRowClickAction(values));
  const payloadViewData = useSelector(
    state => state.appConfigState.textManagementState.EOMBData
  );

  const payloadRecordData = payloadViewData || (props.tableData.length === 1 ? props.tableData : null);
  const payloadData = Array.isArray(payloadRecordData) ? payloadRecordData[0] : payloadRecordData;

  if (redirect === 1) {
    if (payloadData !== undefined && payloadData !== null) {
      props.history.push({
        pathname: '/TextUpdate',
        state: { row: payloadData, TextType: 'EOMB', EombType: 'hcpcs' }
      });
    }
  }

  // Spinner Functionality
  useEffect(() => {
    if (payloadData != null) {
      setspinnerLoader(false);
    }
  }, [payloadData]);
  // Spinner Functionality
  const formatSearchCriteria = _row => ({
    eombProcedureTypeCode: 'H',
    lobCode: [_row.lobCode || _row.lob],
    eombFromCode: _row.eombFromCode,
    eombthruCode: _row.eombThruCode,
    eombText: _row.eombDesc,
    eombTextStartsOrContains: null,
    fromModifier1: _row.procedureModifierCode1,
    fromModifier2: _row.procedureModifierCode2,
    fromModifier3: _row.procedureModifierCode3,
    fromModifier4: _row.procedureModifierCode4,
    thruModifier1: _row.thruProcedureModifierCode1,
    thruModifier2: _row.thruProcedureModifierCode2,
    thruModifier3: _row.thruProcedureModifierCode3,
    thruModifier4: _row.thruProcedureModifierCode4,
    eombSequenceNumber: _row.eombSequenceNumber
  });

  const editRow = row => event => {
    const searchCriteria = formatSearchCriteria(row);
    onRowClick(searchCriteria);
    setspinnerLoader(true);
    setRedirect(1);
  };

  const headCells = props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;

  const tableComp = (
    <TableComponent
      print={props.print}
      pathTo="/TextUpdate?data="
      formatSearchCriteria={formatSearchCriteria}
      fixedTable
      isSearch={true}
      headCells={headCells}
      tableData={props.tableData ? props.tableData : []}
      onTableRowClick={editRow}
      defaultSortColumn={headCells[0].id}
    />
  );
  return tableComp;
}
export default withRouter(ProviderNoticeTable);
