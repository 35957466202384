/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import {
  downloadFileAction
} from '../../../SharedModules/store/Actions/SharedAction';
import * as Errorconstants from '../../../SharedModules/Attachments/AttachmentsConstants';
import TableComponent from '../../../SharedModules/Table/Table';
import Footer from '../../../SharedModules/Layout/footer';
import * as AppConstants from '../../../SharedModules/AppConstants';
import { validateDateMinimumValue } from '../../../SharedModules/DateUtilities/DateUtilities';
import {
  getDropdownDataAction,
  searchDataUpload,
  getUniqueDetailsFileUpload,
  getUniqueUserIds,
  financeUploadAPI,
  resetSearchRecords
} from '../Store/Actions/DataUploadActions';
import dropdownCriteria from './DataUpload.json';
import * as ErrConstants from './ErrorConstants';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import moment from 'moment';
import Spinner from '../../../SharedModules/Spinner/Spinner';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';
import { keySort } from '../../../SharedModules/Table/MultipleTableSort';
import { GET_APP_DROPDOWNS } from '../../../SharedModules/Dropdowns/actions';
import * as Dropdowns from '../../../SharedModules/Dropdowns/dropdowns';
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';

function FinancialUpload (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const headCells = [
    {
      id: 'recievedDateStr',
      numeric: false,
      disablePadding: true,
      label: 'Date',
      width: '16%',
      isDate: true
    },
    {
      id: 'fileControlNum',
      numeric: false,
      disablePadding: false,
      label: 'File Control Number',
      width: '17%'
    },
    {
      id: 'fileControlTime',
      numeric: false,
      disablePadding: false,
      label: 'File Control Sequence',
      width: '17%'
    },
    {
      id: 'totalRecordsNum',
      numeric: false,
      disablePadding: false,
      label: 'Total Records',
      width: '15%'
    },
    {
      id: 'fileStatusCodeDesc',
      numeric: false,
      disablePadding: false,
      label: 'File Status',
      width: '16%'
    },
    {
      id: 'userID',
      numeric: false,
      disablePadding: false,
      label: 'Uploaded User',
      width: '19%'
    }
  ];
  const dispatch = useDispatch();
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const anchorRef = React.useRef();
  const loginUserID = useSelector(state => state.sharedState.logInUserID);
  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [fileStatusDD, setFileStatusDD] = useState([]);
  const [uploadedUserDD, setUploadedUserDD] = useState([]);
  const toPrintRef = useRef();
  const inputRef = useRef();
  const [isFromView, setisFromView] = React.useState(false);
  let propsData = props.history.location?.state
  const downloaFileClick = () => {
    dispatch(downloadFileAction('MASS_UPLOAD_OF_FIN_TRAN_SAMPLE', 'Financial Upload Template.xlsx'));
  };
  const [attachmentErrMsg, setAttachmentErrMsg] = React.useState('');
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [searchValues, setSearchValues] = useState({
    fileStatus: propsData?.searchCriteria?.searchValues?.fileStatus||DEFAULT_DD_VALUE,
    uploadedUser: propsData?.searchCriteria?.searchValues?.uploadedUser||null
  });
  const [showTable, setShowTable] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [uploadedDate, setUploadedDate] = useState(propsData?.searchCriteria?.uploadedDate||null);
  const [uploadedDateError, setUploadedDateError] = useState('');
  const searchDataUploadAPI = values => {
    dispatch(searchDataUpload(values));
  };

  const handleUploadedDateChange = date => {
    setUploadedDate(date);
  };

  const handleChange = name => event => {
    setSearchValues({ ...searchValues, [name]: event.target.value });
  };
useEffect(()=>{
  if(propsData?.isFromView){
    searchUpload(propsData?.searchCriteria?.uploadedDate,searchValues,true)
    setisFromView(true)
  }
},[propsData?.isFromView])

  const searchUpload = (uploadedDate, searchValues, onClickOfSearch) => {
    if (onClickOfSearch) {
      setSuccessMessages([]);
      setAttachmentErrMsg('');
    }
    setShowTable(false);
    let DateError = '';
    if (uploadedDate) {
      if (uploadedDate.toString() !== 'Invalid Date') {
        if (validateDateMinimumValue(uploadedDate)) {
          DateError = AppConstants.DATE_ERROR_1964;
        }
      } else {
        DateError = ErrConstants.UPLOADED_DATE_INVALID;
      }
    }
    if (DateError) {
      setErrorMessages([DateError]);
      setUploadedDateError(DateError);
    } else {
      const searchObject = {
        uploadedDate: null,
        fileStatus: null,
        uploadedUser: null
      };
      if (uploadedDate) {
        searchObject.uploadedDate = moment(new Date(uploadedDate)).format('MM/DD/YYYY');
      }
      if (searchValues.fileStatus !== DEFAULT_DD_VALUE) {
        searchObject.fileStatus = searchValues.fileStatus;
      }
      if (searchValues.uploadedUser !== DEFAULT_DD_VALUE) {
        searchObject.uploadedUser = searchValues.uploadedUser;
      }
      searchDataUploadAPI(searchObject);
      setErrorMessages([]);
      setUploadedDateError('');
      setSpinnerLoader(true);
    }
  };
  const reset = () => {
    setSearchValues({
      fileStatus: DEFAULT_DD_VALUE,
      uploadedUser: loginUserID
    });
    setShowTable(false);
    setUploadedDate(null);
    setErrorMessages([]);
    setUploadedDateError('');
    setSuccessMessages([]);
    setAttachmentErrMsg('');
  };

  const maxSelectFile = event => {
    const files = event.target.files;
    if (files.length > 1) {
      const msg = Errorconstants.ONLY_ONE_FILE_SELECT;
      event.target.value = null;
      const temErr = [];
      temErr.push(msg);
      setErrorMessages(temErr);
      setAttachmentErrMsg('');
      inputRef.current.value = '';
      return false;
    }
    return true;
  };

  const editRow = row => event => {
    setSpinnerLoader(true);
    dispatch(getUniqueDetailsFileUpload(row.ifaceControlSk));
    setisFromView(false)
  };

  const checkMimeType = event => {
    // getting file object
    const files = event.target.files;
    // define message container
    const err = [];
    // list allow mime type
    const types = process.env.REACT_APP_FILES_FOR_UPLOAD_TXN.split(' ');
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every(type => files[x].type !== type)) {
        // create error message and assign to container
        err[x] = Errorconstants.INVALID_TYPE_TXN;
      }
    }
    const temErr = [];
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      // toast.error(err[z]);
      event.target.value = null;
      temErr.push(err[z]);
    }
    if (temErr.length > 0) {
      setErrorMessages(temErr);
      setAttachmentErrMsg('');
      inputRef.current.value = '';
      return false;
    }
    return true;
  };
  const checkFileSize = event => {
    const files = event.target.files;
    const size = process.env.REACT_APP_FILE_SIZE;
    const err = [];
    for (var x = 0; x < files.length; x++) {
      if (files[x].size === 0) {
        err[x] = Errorconstants.ZERO_MB_FOR_TXN;
      }
      if (files[x].size > size) {
        err[x] = Errorconstants.MAX_FILE_SIZE_EXCEEDED;
      }
    }
    const tempErr = [];
    for (var z = 0; z < err.length; z++) {
      // if message not same old that mean has error
      // discard selected file
      // toast.error(err[z]);
      tempErr.push(err[z]);
      event.target.value = null;
    }
    if (tempErr.length > 0) {
      setErrorMessages(tempErr);
      setAttachmentErrMsg('');
      inputRef.current.value = '';
      return false;
    }
    return true;
  };
  const onChangeChooseFile = event => {
    if (maxSelectFile(event) && checkMimeType(event) && checkFileSize(event)) {
      const files = event.target.files[0];
      if (files) {
        setSelectedFile(files);
        setErrorMessages([]);
        setSuccessMessages([]);
      }
    } else {
      inputRef.current.value = '';
    }
  };

  const uploadFunction = () => {
    setSuccessMessages([]);
    const errorArray = [];
    if (!selectedFile) {
      setAttachmentErrMsg(Errorconstants.FILE_IS_REQUIRED);
      errorArray.push(Errorconstants.FILE_IS_REQUIRED);
    }
    if (errorArray.length === 0) {
      setAttachmentErrMsg('');
      setErrorMessages([]);
      setSpinnerLoader(true);
      if (selectedFile) {
        dispatch(financeUploadAPI({
          userID: loginUserID
        }, selectedFile));
      }
    } else {
      setErrorMessages(errorArray);
    }
  };

  const DropDownAPI = dropdownValues =>
    dispatch(getDropdownDataAction(dropdownValues));
  const dropDownData = useSelector(state => state.dataUploadState.dropdownData);
  let downloadeFile = useSelector(state => state.sharedState.downloadeFile);
  let searchRecords = useSelector(state => state.dataUploadState.searchData);
  let uniqueResult = useSelector(state => state.dataUploadState.uniqueData);
  const userDrop = useSelector(state => state.dataUploadState.uniqueDataUsers);
  let saveResponse = useSelector(state => state.dataUploadState.saveResponse);

  useEffect(() => {
    DropDownAPI(dropdownCriteria);
    searchRecords = null;
    uniqueResult = null;
    saveResponse = null;
    downloadeFile = null;
    dispatch(getUniqueUserIds());
  }, []);

  useEffect(() => {
    onDropdowns([Dropdowns.STATUS_TYPE, 
      Dropdowns.PAYEE_TYPE,
      Dropdowns.F_RSN_CD, 
      Dropdowns.TXN_CODE,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,]);
  }, []);

  useEffect(() => {
    if (userDrop && userDrop.length > 0) {
      setUploadedUserDD(userDrop);
    }
  }, [userDrop]);

  useEffect(() => {
    if (downloadeFile && !downloadeFile.systemFailure) {
      anchorRef.current.href = downloadeFile;
      anchorRef.current.download = 'Financial Upload Template.xlsx';
      anchorRef.current.click();
    }
    if (downloadeFile && downloadeFile.systemFailure) {
      const temerr = [];
      temerr.push(Errorconstants.ERROR_IN_DOWNLOADING_FILE);
      setErrorMessages(temerr);
    }
  }, [downloadeFile]);

  useEffect(() => {
    if (saveResponse && !saveResponse.systemFailure) {
      if (saveResponse.respcode === '01') {
        setSpinnerLoader(false);
        setErrorMessages([]);
        setSuccessMessages([ErrConstants.SUCCESS_MSG]);
        setSelectedFile(null);
        inputRef.current.value = '';
      }
      if (saveResponse.respcode === '02') {
        setSpinnerLoader(false);
        if (saveResponse.errorMessages && saveResponse.errorMessages.length > 0) {
          setSuccessMessages([]);
          setErrorMessages(saveResponse.errorMessages);
        }
      }
    }
    if (saveResponse && saveResponse.systemFailure) {
      setSpinnerLoader(false);
      setErrorMessages([AppConstants.ERR_PROCESSING_REQ]);
    }
  }, [saveResponse]);
  useEffect(() => {
    if (searchRecords && !searchRecords.systemFailure) {
      if (searchRecords.length > 0) {
        setShowTable(true);
        const tempTable = JSON.parse(JSON.stringify(searchRecords));
        tempTable.map(values => {
          fileStatusDD.map(value => {
            if (values.fileStatusCode === value.code) {
              values.fileStatusCode = value.description;
            }
          });
        });
        setTableData(tempTable);
        setSpinnerLoader(false);
      }
      if (searchRecords.length === 0) {
        setSpinnerLoader(false);
        setErrorMessages([ErrConstants.NO_RECORDS]);
      }
    }
    if (searchRecords && searchRecords.systemFailure) {
      setSpinnerLoader(false);
      setErrorMessages([AppConstants.ERR_PROCESSING_REQ]);
      setShowTable(false);
    }
  }, [searchRecords]);

useEffect(()=>{
  if (searchRecords?.length === 1  && !isFromView) {
    setSpinnerLoader(true); 
    dispatch(getUniqueDetailsFileUpload(searchRecords[0].ifaceControlSk));
  }
},[searchRecords,isFromView])

const apiRedirect = (uniqueResultApi)=>{
  if (uniqueResultApi && !uniqueResultApi.systemFailure) {
    setSpinnerLoader(false);
    const fileStatusCodeRecord =
    addDropdowns &&
    addDropdowns['F1#G_BATCH_TXN_STAT_CD'] &&
    addDropdowns['F1#G_BATCH_TXN_STAT_CD'].filter(
      value => value.code === uniqueResultApi?.enterpriseBaseVO?.fileStatusCode
    );
    props.history.push({
      pathname: '/FinancialUploadView',
      state: { dataFromSearch: uniqueResultApi.enterpriseBaseVO,
         fileStatusRecord: fileStatusCodeRecord, dropdowns:addDropdowns , searchCriteria: {uploadedDate, searchValues}}
    });
    dispatch(resetSearchRecords())
  }
  if (uniqueResultApi && uniqueResultApi.systemFailure) {
    setSpinnerLoader(false);
    setErrorMessages([AppConstants.ERR_PROCESSING_REQ]);
    setShowTable(false);
  }
}
  useEffect(() => {
    apiRedirect(uniqueResult)
  }, [uniqueResult]);

  useEffect(() => {
    if (dropDownData && dropDownData.listObj) {
      if (dropDownData.listObj['Financial#6018']) {
        setFileStatusDD(dropDownData.listObj['Financial#6018']);
      }
    }
  }, [dropDownData]);

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const fileStatusCode =
            addDropdowns &&
            addDropdowns['F1#G_BATCH_TXN_STAT_CD'] &&
            addDropdowns['F1#G_BATCH_TXN_STAT_CD'].filter(
              value => value.code === each.fileStatusCode
            );
            // updating respective record with description
          each.fileStatusCodeDesc =
            fileStatusCode && fileStatusCode.length > 0
              ? fileStatusCode[0].description
              : each.fileStatusCode ? each.fileStatusCode : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };

  return (
    <div className="pos-relative w-100 h-100">
      { spinnerLoader ? <Spinner /> : null }
      <div className="tabs-container" ref={ toPrintRef }>
        <ErrorMessages
          errorMessages={ errorMessages }
        />
        <SuccessMessages
          successMessages={ successMessages } />
        <div className="tab-header">
          <h1 className="tab-heading float-left">Financial Upload</h1>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body padTop-10">
          <div className="tab-panelbody">
            <div className="tab-holder my-3">
              <form autoComplete="off">
                <div className="form-wrapper">
                  <div className="input-group row" style={ { marginBottom: '12px' } }>
                    <div className="col-12 alerts">
                      <span
                        className="bg-white rounded font-weight-bold"
                        style={ {
                          backgroundColor: '#274463',
                          paddingTop: '0.5%'
                        } }
                      >
                        Template to upload the financial Transactions:{ ' ' }
                      </span>
                      <a ref={ anchorRef }></a>
                      <a onClick={ () => { downloaFileClick(); } } style={ { color: '#002bff', cursor: 'pointer' } }>
                        Financial Upload Template
                      </a>
                    </div>
                  </div>
                </div>
                <div className="form-wrapper">
                  <div
                    className="input-group row"
                    style={ { marginBottom: '0px' } }
                  >
                    <div className="col-12 alerts" style={ { display: 'flex' } }>
                      <span
                        className="bg-white rounded font-weight-bold"
                        style={ {
                          backgroundColor: '#274463',
                          paddingTop: '0.5%'
                        } }
                      >
                        Upload the document to create financial transactions:
                      </span>
                      <div
                        // className="mui-custom-form input-xl"
                        style={ {
                          border: 'none',
                          paddingLeft: '0px',
                          margin: '0px 2.5% 0px 6px',
                          display: 'flex'
                        } }
                      >
                        <div style={ { display: 'flex' } }>
                          <div
                            style={ {
                              maxWidth: '320px',
                              minWidth: '320px'
                            } }
                          >
                            <input
                              id="file_upload"
                              type="file"
                              name="file"
                              onChange={ onChangeChooseFile }
                              ref={ inputRef }
                              required
                              style={ { display: 'none' } }
                            />
                            <label for="file_upload">
                              <a class="btn btn-primary">Choose File</a>
                            </label>
                            <span
                              style={ {
                                margin: '5px'
                              } }
                            >
                              { selectedFile && selectedFile.name
                                ? selectedFile.name.length > 25
                                  ? selectedFile.name.substring(0, 11) + '...' + selectedFile.name.substring(selectedFile.name.length - 11, selectedFile.name.length)
                                  : selectedFile.name
                                : 'No file choosen' }
                            </span>
                            <p class="MuiFormHelperText-root MuiFormHelperText-filled danger-text">
                              { attachmentErrMsg || null }
                            </p>
                          </div>
                          <div
                            className="float-right mr-4 mb-3"
                            style={ { marginLeft: '47px' } }
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              className="btn btn-primary"
                              onClick={ () => {
                                uploadFunction();
                              } }
                            >
                              <i
                                class="fa fa-cloud-upload"
                                aria-hidden="true"
                              ></i>
                              Upload
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-wrapper">
                  <fieldset
                    className="custom-fieldset"
                    style={ { width: '100%' } }
                  >
                    <legend>Search:</legend>
                    <div className="form-wrapper">
                      <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                        <div
                          className="mui-custom-form with-date md"
                          style={ { marginLeft: '15px' } }
                        >
                          <KeyboardDatePicker
                            maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                            id="date-picker-begindate"
                            fullWidth
                            label="Uploaded Date"
                            InputLabelProps={ {
                              shrink: true
                            } }
                            helperText={
                              uploadedDateError || null
                            }
                            error={ uploadedDateError || null }
                            placeholder="mm/dd/yyyy"
                            format="MM/dd/yyyy"
                            value={ uploadedDate }
                            onChange={ handleUploadedDateChange }
                            KeyboardButtonProps={ {
                              'aria-label': 'change date'
                            } }
                          />
                        </div>
                      </MuiPickersUtilsProvider>
                        <NativeDropDown
                          id="standard-select-file"
                          label="File Status"
                          inputProps={ { maxLength: 1 } }
                          value={ searchValues.fileStatus }
                          onChange={ handleChange('fileStatus') }
                          InputLabelProps={ {
                            shrink: true
                          } }
                        dropdownList = { fileStatusDD
                            ? fileStatusDD.map((item, index) => (
                              <option key={ index } value={ item.code }>
                                { item.description }
                              </option>
                            ))
                            : null }
                        />
                        <NativeDropDown
                          id="standard-select-user"
                          label="Uploaded User"
                          inputProps={ { maxLength: 30 } }
                          value={ searchValues.uploadedUser }
                          onChange={ handleChange('uploadedUser') }
                          InputLabelProps={ {
                            shrink: true
                          } }
                          dropdownList = { uploadedUserDD
                            ? uploadedUserDD.map((item, index) => (
                              <option key={ index } value={ item }>
                                { item }
                              </option>
                            ))
                            : null }
                        />
                    </div>
                    <div
                      className="form-wrapper"
                      style={ { justifyContent: 'flex-end' } }
                    >
                      <div className="float-right mr-4 mb-3">
                        <Button
                          variant="outlined"
                          color="primary"
                          className="btn btn-primary"
                          onClick={ () => {
                            setisFromView(false)
                            searchUpload(uploadedDate, searchValues, true);
                          } }
                        >
                          <i class="fa fa-search" aria-hidden="true"></i> Search
                        </Button>
                        <Button
                          variant="outlined"
                          color="primary"
                          className="bt-reset btn-transparent  ml-1"
                          onClick={ () => reset() }
                        >
                          <i class="fa fa-undo" aria-hidden="true"></i> Reset
                        </Button>
                      </div>
                    </div>
                    <div className="tab-holder pb-1 tableNoWrap">
                      { showTable ? (
                        <TableComponent
                          fixedTable
                          isSearch={ true }
                          headCells={ headCells }
                          tableData={ getTableData(tableData) }
                          onTableRowClick={ editRow }
                          defaultSortColumn={ headCells[0].id }
                          order={ 'desc' }
                        />
                      ) : null }
                    </div>
                  </fieldset>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}
export default withRouter(FinancialUpload);
