import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TabPanel from "../../../../SharedModules/TabPanel/TabPanel";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../../SharedModules/Spinner/Spinner";
import { Button } from "react-bootstrap";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/actionTypes";
import * as ErrorConst from "../../../../SharedModules/Errors/ErrorMsgConstant";
import EventSearchForm from "./EventSearchForm";
import Footer from '../../../../SharedModules/Layout/footer';
import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import { resetSearchCriteria, EventLogInquiryAction,EventLogEventAction } from "../../../../SharedModules/Dropdowns/actions";
import EventSearchTableComponent from "./EventSearchTableComponent";
import InquirySearchForm from "./InquirySearchForm";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #f4f4f4",
    "&:focus": {
      backgroundColor: theme.palette.primary.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.black,
      },
    },
  },
}))(MenuItem);
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
function EventLogSearch(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  let errorMessagesArray = [];
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [spinnerLoader, setspinnerLoader] = useState(false);
  const [, setShowFNameError] = useState(false)
  const [
    {
      beginDateErr,
      endDateErr,
      beginDateInvalidErr,
      endDateInvalidErr,
      userIdNameErr,
      beginTimeErr,
      endTimeErr,
      beginDtGtEndDtErr
    },
    setShowError,
  ] = useState(false);
  const [
    {
      beginDateErrEvent,
      endDateErrEvent,
      beginDateInvalidErrEvent,
      endDateInvalidErrEvent,
      userIdNameErrEvent ,
      beginTimeErrEvent,
      endTimeErrEvent,
      beginDtGtEndDtErrEvent
    },
    setEventShowError,
  ] = useState(false);
  const defaultObjInquiry = {
    beginDate: "",
    endDate: "",
    beginTime: "00:00:00 AM",
    endTime: "11:59:59 PM",
    userID: "",
    userLastName: "",
    portletName: "",
    keyType1: "",
    keyValue1: "",
    keyType2: "",
    keyValue2: "",
    keyType3: "",
    keyValue3: "",
    keyType4: "",
    keyValue4: "",
    keyType5: "",
    keyValue5: "",
  };
  const defaultObjEvent = {
    beginDate: "",
    endDate: "",
    beginTime: "00:00:00 AM",
    endTime: "11:59:59 PM",
    portletName: "",
    userID: "",
    userLastName: "",
    eventType: "-1",
    eventCode: "",
    message: ""
  };
  const [showTable, setShowTable] = useState(false);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const onSearchInquiry = (searchvalues) => {
    return dispatch(EventLogInquiryAction(searchvalues));
  };
  const onSearchEvent = (searchvalues) => {
    return dispatch(EventLogEventAction(searchvalues));
  };
  const onReset = () => dispatch(resetSearchCriteria());
  const [showNoRecords, setShowNoRecords] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [, setnoResult] = useState(false);
  const [, setpendResult] = useState(false);
  const [successMessages,] = useState([]);
  const [inquiryValues, setInquiryValues] = useState(defaultObjInquiry);
  const [eventValues, setEventValues] = useState(defaultObjEvent);
  const logInquiryDataDetails = useSelector(state => state.eventLog.logInquiryData);
  const logEventDataDetails = useSelector(state => state.eventLog.logEventData);
  const handleChangesInquiry = (name) => (event) => {
    setInquiryValues({ ...inquiryValues, [name]: event.target.value });
  };
  const handleChangesEvent = (name) => (event) => {
    setEventValues({ ...eventValues, [name]: event.target.value });
  };
  const handleDCDtChange = (name, date) => {
    setInquiryValues({ ...inquiryValues, [name]: date });
  };
  const handleDCDtChangeEvent = (name, date) => {
    setEventValues({ ...eventValues, [name]: date });
  };
  const [errorMessages, seterrorMessages] = useState([]);
  const printRef = useRef();
  const handleTabChange = (event, newValue) => {
    setShowNoRecords(false);
    seterrorMessages([]);
    setTabValue(newValue);
    setnoResult(false);
    setpendResult(false);
  };
  const resetTable = () => {
    setShowNoRecords(false);
    seterrorMessages([]);
    setShowFNameError(false);
    setShowError(false);
    setEventShowError(false);
    setInquiryValues(defaultObjInquiry);
    setEventValues(defaultObjEvent);
    setShowTable(false);
    onReset();
  };
  const inquirySearchCheck = () => {
    setShowTable(false);
    setShowNoRecords(false);
    setspinnerLoader(false);
    errorMessagesArray = [];
    seterrorMessages([]);
    setShowError({});
    let errors = {};
    if(inquiryValues.beginDate===""){
      errors['beginDateErr']=true;
      errorMessagesArray.push(ErrorConst.Begin_Date_Error);
   }
    if(inquiryValues.endDate===""){
      errors['endDateErr']=true;
      errorMessagesArray.push(ErrorConst.End_Date_Error);
    }
    if( inquiryValues.beginDate !== '' && inquiryValues.beginDate.toString() === ErrorConst.INVALID_DATE){
      errors['beginDateInvalidErr']=true;
      errorMessagesArray.push(ErrorConst.Invalid_Begin_Date_Error);
    }
    if( inquiryValues.endDate !== '' && inquiryValues.endDate.toString() === ErrorConst.INVALID_DATE){
      errors['endDateInvalidErr']=true;
      errorMessagesArray.push(ErrorConst.Invalid_End_Date_Error);
    }
    if ( new Date(inquiryValues.beginDate) > new Date(inquiryValues.endDate)) {
      errors['beginDtGtEndDtErr']=true;
      errorMessagesArray.push(ErrorConst.EVENT_GT_DT_ERROR);
    }
    let regexp =/(?:[0-1][0-9]|[2][1-4]):[0-5][0-9]:[0-5][0-9]\s([A]|[P])([M])?/;
    if(inquiryValues.beginTime!==""&&!regexp.test(inquiryValues.beginTime)) {
      errors['beginTimeErr']=true;
      errorMessagesArray.push(ErrorConst.BEGIN_TIME_INVALID);
    }
    if(inquiryValues.endTime!==""&&!regexp.test(inquiryValues.endTime)) {
      errors['endTimeErr']=true;
      errorMessagesArray.push(ErrorConst.END_TIME_INVALID);
    }
    if(inquiryValues.userID!==""&&inquiryValues.userLastName!=="") {
      errors['userIdNameErr']=true;
      errorMessagesArray.push(ErrorConst.USER_ID_NAME_ERR);
    }
    if(inquiryValues.keyType1!=="" && inquiryValues.keyValue1===""||
        inquiryValues.keyType2!=="" && inquiryValues.keyValue2===""||
        inquiryValues.keyType3!=="" && inquiryValues.keyValue3===""||
        inquiryValues.keyType4!=="" && inquiryValues.keyValue4===""||
        inquiryValues.keyType5!=="" && inquiryValues.keyValue5===""
    ){
      errorMessagesArray.push(ErrorConst.KEY_VALUE_ERROR);
    }
    if(inquiryValues.keyType1==="" && inquiryValues.keyValue1!==""||
        inquiryValues.keyType2==="" && inquiryValues.keyValue2!==""||
        inquiryValues.keyType3==="" && inquiryValues.keyValue3!==""||
        inquiryValues.keyType4==="" && inquiryValues.keyValue4!==""||
        inquiryValues.keyType5==="" && inquiryValues.keyValue5!==""
    ){
      errorMessagesArray.push(ErrorConst.KEY_TYPE_ERROR);
    }
    if (errorMessagesArray?.length === 0) {
      setShowTable(true);
      setspinnerLoader(true);
     let searchCriteria = {};
     if(inquiryValues.beginDate!==""&&inquiryValues.beginDate!==null)
     {searchCriteria['beginDate']=inquiryValues.beginDate;}
     if(inquiryValues.endDate!==""&&inquiryValues.endDate!==null)
     {searchCriteria['endDate']=inquiryValues.endDate;}
     if(inquiryValues.beginTime!==""&&inquiryValues.beginTime!==null)
     {searchCriteria['beginTime']=inquiryValues.beginTime;}
     if(inquiryValues.endTime!==""&&inquiryValues.endTime!==null)
     {searchCriteria['endTime']=inquiryValues.endTime;}
     if(inquiryValues.userID!==""&&inquiryValues.userID!==null)
     {searchCriteria['userID']=inquiryValues.userID;}
     if(inquiryValues.portletName!==""&&inquiryValues.portletName!==null)
     {searchCriteria['portletName']=inquiryValues.portletName;}
     if(inquiryValues.keyType1!==""&&inquiryValues.keyType1!==null)
     {searchCriteria['keyType1']=inquiryValues.keyType1;}
     if(inquiryValues.keyType2!==""&&inquiryValues.keyType2!==null)
     {searchCriteria['keyType2']=inquiryValues.keyType2;}
     if(inquiryValues.keyType3!==""&&inquiryValues.keyType3!==null)
     {searchCriteria['keyType3']=inquiryValues.keyType3;}
     if(inquiryValues.keyType4!==""&&inquiryValues.keyType4!==null)
     {searchCriteria['keyType4']=inquiryValues.keyType4;}

     if(inquiryValues.keyType5!==""&&inquiryValues.keyType5!==null)
     {searchCriteria['keyType5']=inquiryValues.keyType5;}
     if(inquiryValues.keyValue1!==""&&inquiryValues.keyValue1!==null)
     {searchCriteria['keyValue1']=inquiryValues.keyValue1;}
     if(inquiryValues.keyValue2!==""&&inquiryValues.keyValue2!==null)
     {searchCriteria['keyValue2']=inquiryValues.keyValue2;}
     if(inquiryValues.keyValue3!==""&&inquiryValues.keyValue3!==null)
     {searchCriteria['keyValue3']=inquiryValues.keyValue3;}
     if(inquiryValues.keyValue4!==""&&inquiryValues.keyValue4!==null)
     {searchCriteria['keyValue4']=inquiryValues.keyValue4;}
     if(inquiryValues.keyValue5!==""&&inquiryValues.keyValue5!==null)
     {searchCriteria['keyValue5']=inquiryValues.keyValue5;}
     if(inquiryValues.userLastName!==""&&inquiryValues.userLastName!==null)
     {searchCriteria['userLastName']=inquiryValues.userLastName;}
      onSearchInquiry(searchCriteria);
      setRedirect(true);
    } else {
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
      setShowError(errors);
    }
  };
  const eventSearchCheck = () => {
    setShowTable(false);
    setShowNoRecords(false);
    setspinnerLoader(false);
    errorMessagesArray = [];
    seterrorMessages([]);
    setShowError({});
    setEventShowError({});
    let errors = {};
    if(eventValues.beginDate===""){
      errors['beginDateErrEvent']=true;
      errorMessagesArray.push(ErrorConst.Begin_Date_Error);
    }
    if(eventValues.endDate===""){
      errors['endDateErrEvent']=true;
      errorMessagesArray.push(ErrorConst.End_Date_Error);
    }
    if( eventValues.beginDate !== '' && eventValues.beginDate.toString() === ErrorConst.INVALID_DATE){
      errors['beginDateInvalidErrEvent']=true;
      errorMessagesArray.push(ErrorConst.Invalid_Begin_Date_Error);
    }
    if( eventValues.endDate !== '' && eventValues.endDate.toString() === ErrorConst.INVALID_DATE){
     errors['endDateInvalidErrEvent']=true;
     errorMessagesArray.push(ErrorConst.Invalid_End_Date_Error);
    }
    if ( new Date(eventValues.beginDate) > new Date(eventValues.endDate)) {
      errors['beginDtGtEndDtErrEvent']=true;
      errorMessagesArray.push(ErrorConst.EVENT_GT_DT_ERROR);
    }
    let regexp =/(?:[0-1][0-9]|[2][1-4]):[0-5][0-9]:[0-5][0-9]\s([A]|[P])([M])?/;
    if(eventValues.beginTime!==""&&!regexp.test(eventValues.beginTime)) {
      errors['beginTimeErrEvent']=true;
      errorMessagesArray.push(ErrorConst.BEGIN_TIME_INVALID);
    }
    if(eventValues.endTime!==""&&!regexp.test(eventValues.endTime)) {
      errors['endTimeErrEvent']=true;
      errorMessagesArray.push(ErrorConst.END_TIME_INVALID);
    }
    if(eventValues.userID!==""&&eventValues.userLastName!=="") {
      errors['userIdNameErrEvent']=true;
      errorMessagesArray.push(ErrorConst.USER_ID_NAME_ERR);
    }
    if (errorMessagesArray?.length === 0) {
      setShowTable(true);
      setspinnerLoader(true);
      let searchCriteria = {};
      if(eventValues.beginDate!==""&&eventValues.beginDate!==null)
     {searchCriteria['beginDate']=eventValues.beginDate;}
     if(eventValues.endDate!==""&&eventValues.endDate!==null)
     {searchCriteria['endDate']=eventValues.endDate;}
     if(eventValues.beginTime!==""&&eventValues.beginTime!==null)
     {searchCriteria['beginTime']=eventValues.beginTime;}
     if(eventValues.endTime!==""&&eventValues.endTime!==null)
     {searchCriteria['endTime']=eventValues.endTime;}
     if(eventValues.userID!==""&&eventValues.userID!==null)
     {searchCriteria['userID']=eventValues.userID;}
     if(eventValues.eventType!==""&&eventValues.eventType!=="-1")
     {searchCriteria['eventType']=eventValues.eventType;}
     if(eventValues.eventCode!==""&&eventValues.eventCode!==null)
     {searchCriteria['eventCode']=eventValues.eventCode;}
      onSearchEvent(searchCriteria);
      setRedirect(true);
    } else {
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
      setEventShowError(errors);
    }
  };
  useEffect(() => {
    if (logInquiryDataDetails != null &&logInquiryDataDetails.responseDesc=="Success"
    && (logInquiryDataDetails.responseEventInquiryLogDetails?.length > 0 ||
       logInquiryDataDetails.responseEventInquiryLogDetails?.length === 0)) {
      setspinnerLoader(false);
      setShowError(false);
    }
    if (logInquiryDataDetails && logInquiryDataDetails.responseDesc !="Success") {
      setspinnerLoader(false);
      errorMessagesArray.push(ErrorConst.ERROR_OCCURED_DURING_TRANSACTION);
      seterrorMessages(errorMessagesArray);
      setShowError(false);
    }
    if (logInquiryDataDetails != null &&
      logInquiryDataDetails.responseEventInquiryLogDetails?.length === 0) {
        setShowNoRecords(true);
        setShowFNameError(false);
        setShowError(false);
    }
  }, [logInquiryDataDetails]);
  useEffect(() => {
    if (logEventDataDetails != null && logEventDataDetails.responseDesc=="Success"&&(logEventDataDetails.responseEventsLogDetails?.length > 0 ||
      logEventDataDetails.responseEventsLogDetails?.length === 0)) {
      setspinnerLoader(false);
      setShowFNameError(false);
      setShowError(false);
    }
    if (logEventDataDetails && logEventDataDetails.responseDesc != "Success") {
      setspinnerLoader(false);
      errorMessagesArray.push(ErrorConst.ERROR_OCCURED_DURING_TRANSACTION);
      seterrorMessages(errorMessagesArray);
      setShowFNameError(false);
      setShowError(false);
    }
    if (logEventDataDetails != null && logEventDataDetails.responseEventsLogDetails?.length == 0) {
        setShowNoRecords(true);
        setShowFNameError(false);
        setShowError(false);
    }
  }, [logEventDataDetails]);
  useEffect(() => {
    onReset();
    onDropdowns([
      Dropdowns.EVENT_TYPE
    ]);
  }, []);
  return (
    <div className="pos-relative custom-page">
      {spinnerLoader ? <Spinner setspinnerLoader= {setspinnerLoader}/> : null}
      {errorMessages?.length > 0 ? (
        <div className="alert alert-danger custom-alert" role="alert">
          {errorMessages.map((message) => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {errorMessages?.length === 0 &&
              showNoRecords ? (
          <div className="alert alert-danger custom-alert" role="alert">
            <li>{ErrorConst.NO_RECORDS_WITHSEARCH}</li>
          </div>
        ) : null}
      {successMessages?.length > 0 ? (
        <div className="alert alert-success custom-alert" role="alert">
          {successMessages.map((message) => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      <div className="tabs-container" ref={printRef}>
        <div className="tab-header border-bottom">
          <h1 className="tab-heading page-heading float-left">
            {" "}
            Search Event Logging
          </h1>
          <div className="float-right th-btnGroup">
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem className="btn-menu-child">
                <ListItemText
                  title="TPL Policy"
                  onClick={() => {
                    props.history.push({ pathname: "/PolicyDetails" });
                  }}
                  primary="TPL Policy"
                />
              </StyledMenuItem>
              <StyledMenuItem className="btn-menu-child">
                <ListItemText
                  title="Medicare"
                  onClick={() => {
                    props.history.push({ pathname: "/MedicareDetails" });
                  }}
                  primary="Medicare"
                />
              </StyledMenuItem>
            </StyledMenu>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="custom-hr my-1 pb-1" />
        <div className="tab-body clm-correction-panel-1">
          <div className="tab-holder custom-tabber inline-th mt-3">
            <AppBar position="static" color="default">
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab title="Inquiries" label="Inquiries" {...a11yProps(1)} />
                <Tab title="Event" label="Event" {...a11yProps(0)} />  
              </Tabs>
            </AppBar>
            <TabPanel value={tabValue} index={0}>
              <div className="tab-body pb-0">
                <InquirySearchForm
                dropdowns={addDropdowns}
                  inquirySearchCheck={inquirySearchCheck}
                  handleChanges={handleChangesInquiry}
                  handleDCDtChange={handleDCDtChange}
                  errors={{
                    beginDateErr,
                    endDateErr,
                    beginDateInvalidErr,
                    endDateInvalidErr,
                    beginTimeErr,
                    endTimeErr,
                    userIdNameErr,
                    beginDtGtEndDtErr
                  }}
                  seterrorMessages={seterrorMessages}
                  inquiryValues={inquiryValues}
                  resetTable={resetTable}
                  history={props.history}
                  setRedirect={setRedirect}
                  privileges={props.privileges}
                />
                 {showTable && logInquiryDataDetails && logInquiryDataDetails.responseEventInquiryLogDetails?.length > 0 ? (
                  <div className="container-space">
                    <div className="search-result">Search Results</div>
                    <EventSearchTableComponent
                      tableData={logInquiryDataDetails.responseEventInquiryLogDetails}
                      setspinnerLoader={setspinnerLoader}
                      setRedirect={setRedirect}
                      redirect={redirect}
                      tabName = "Inquiry"
                      seterrorMessages={seterrorMessages}
                    />
                  </div>
                ) : null}
              </div>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <div className="tab-body pb-0">
                <EventSearchForm
                  dropdowns={addDropdowns}
                  eventSearchCheck={eventSearchCheck}
                  handleChanges={handleChangesEvent}
                  handleDCDtChangeEvent={handleDCDtChangeEvent}
                  errors={{
                      beginDateErrEvent,
                      endDateErrEvent,
                      beginDateInvalidErrEvent,
                      endDateInvalidErrEvent,
                      beginTimeErrEvent,
                      endTimeErrEvent,
                      userIdNameErrEvent,
                      beginDtGtEndDtErrEvent
                  }}
                  seterrorMessages={seterrorMessages}
                  eventValues={eventValues}
                  resetTable={resetTable}
                  history={props.history}
                  setRedirect={setRedirect}
                  privileges={props.privileges}
                />
                {showTable && logEventDataDetails && logEventDataDetails.responseEventsLogDetails?.length > 0 ? (
                  <div className="container-space">
                    <div className="search-result">Search Results</div>
                    <EventSearchTableComponent
                      tableData={logEventDataDetails.responseEventsLogDetails}
                      setspinnerLoader={setspinnerLoader}
                      setRedirect={setRedirect}
                      redirect={redirect}
                      seterrorMessages={seterrorMessages}
                    />
                  </div>
                ) : null}
              </div>
            </TabPanel>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}
export default withRouter(EventLogSearch);
