/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

export const ORIGINAL_CHECK = 'Original Check/EFT';
export const CHECK_REPLACEMENT = 'Receipt Check Replacement';
export const REPLACEMENT_DETAIL_TAB = 'Check Replacement Details';
export const REPLACEMENT_CHECK = 'Replacement Check';
export const CHECK_NUMBERS_EQUAL_ERROR = 'The Replacement Check Number cannot be the same as the Original Check Number.';
export const GENERIC_SYSTEM_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const FINANCIAL_REASON_CODE_REQUIRED = 'Financial Reason Code is required.';
export const CHECK_DATE_REQUIRED = 'Check Date is required.';
export const CHECK_NUMBER_REQUIRED = 'Check Number is required.';
export const CHECK_NUMBER_AND_DATE_REQUIRED = 'Check Number and Check Date are required for manual checks.';
export const TRANSACTION_ERROR = 'There was an error processing the request. Please retry the transaction.';
export const CHECK_DATE_INVALID = 'Check Date is invalid.';
export const NETWORK_ERROR = 'Network Error';
