/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import Bootstrap, { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import "react-phone-input-2/lib/style.css";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import Radio from "@material-ui/core/Radio";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";

const useStyles = makeStyles(theme => ({}));

export default function AddAddress(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const {
    AddressTypeDropdown,
    SignificanceTypeDropdown,
    StatusCodeDropdown,
    CountyDropdown,
    CountryDropdown,
    StateDropdown,
    ChangeReasonDropdown,
    payeeValues,
    disableFields,
    editRow,
    showAdditionalDetails1,
    setShowAdditionalDetails1,
    handleChangeUSPS,
    considerUSPS,
    userInquiryPrivileges
  } = props;

  const classes = useStyles();
  const [showAdditionalDetails, setShowAdditionalDetails] = React.useState(
    false
  );
  const [showContactDetails, setContactDetails] = React.useState(false);

  let statusCode = StatusCodeDropdown.find(value => value.code === props.addAddress.statusCode);
  let changeCode = ChangeReasonDropdown.find(value => value.code === props.addAddress.changeReasonCode);
  let signCode = SignificanceTypeDropdown.find(value => value.code === props.addAddress.addressUsageSigCode);
  let stateCode = StateDropdown.find(value => value.code === props.addAddress.state);

  return (
    <div className="form-border my-3">
    <form autoComplete="off">
      <div className="form-wrapper">
          <NativeDropDown
            id="addressUsageTypeCode"
            inputProps={{ maxlength: 2 }}
            label="Address Type"
            value={props.addAddress.addressUsageTypeCode}
            onChange={props.handleChangeAddAddress("addressUsageTypeCode")}
            isRequired
            placeholder=""
            disabled={editRow || !userInquiryPrivileges}
            errTxt={
              props.showAddressError.showaddressUsageTypeCodeError
                ? props.showAddressErrorMsg.showaddressUsageTypeCodeErrorMsg
                : null
            }
            dropdownList={AddressTypeDropdown
              ? AddressTypeDropdown.map(option => (
                <option key={option} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
          />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form with-date">
            <KeyboardDatePicker
              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
              id="beginDate"
              label="Begin Date"
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              required
              InputLabelProps={{
                shrink: true
              }}
              disabled={disableFields || !userInquiryPrivileges}
              value={props.selectedBeginDate}
              onChange={props.handleBeginDateChange}
              helperText={
                props.showAddressError.showBeginDateError
                  ? props.showAddressErrorMsg.showBeginDateErrorMsg
                  : null
              }
              error={
                props.showAddressError.showBeginDateError
                  ? props.showAddressErrorMsg.showBeginDateErrorMsg
                  : null
              }
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
            />
          </div>
          <div className="mui-custom-form with-date">
            <KeyboardDatePicker
              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
              id="endDate"
              label="End Date"
              format="MM/dd/yyyy"
              placeholder="mm/dd/yyyy"
              required
              InputLabelProps={{
                shrink: true
              }}
              value={props.selectedEndDate}
              onChange={props.handleEndDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              disabled={disableFields || !userInquiryPrivileges}
              helperText={
                props.showAddressError.showEndDateError
                  ? props.showAddressErrorMsg.showEndDateErrorMsg
                  : null
              }
              error={
                props.showAddressError.showEndDateError
                  ? props.showAddressErrorMsg.showEndDateErrorMsg
                  : null
              }
            />
          </div>
        </MuiPickersUtilsProvider>
          <NativeDropDown
            id="addressUsageSigCode"
            inputProps={{ maxlength: 2 }}
            isRequired
            label="Significance Type Code"
            value={signCode !== undefined ? props.addAddress.addressUsageSigCode : DEFAULT_DD_VALUE}
            onChange={props.handleChangeAddAddress("addressUsageSigCode")}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            errTxt={
              props.showAddressError.showSignificanceTypeCodeError
                ? props.showAddressErrorMsg.showSignificanceTypeCodeErrorMsg
                : null
            }
            dropdownList={SignificanceTypeDropdown
              ? SignificanceTypeDropdown.map(option => (
                <option key={option} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
          />
          <NativeDropDown
            id="changeReasonCode"
            inputProps={{ maxlength: 2 }}
            label="Change Reason"
            value={changeCode !== undefined ? props.addAddress.changeReasonCode : DEFAULT_DD_VALUE}
            onChange={props.handleChangeAddAddress("changeReasonCode")}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            errTxt={
              props.showAddressError.showChangeReasonCodeError
                ? props.showAddressErrorMsg.showChangeReasonCodeErrorMsg
                : null
            }
            dropdownList={ChangeReasonDropdown
              ? ChangeReasonDropdown.map(option => (
                <option key={option} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
          />

          <NativeDropDown
            id="statusCode"
            inputProps={{ maxlength: 2 }}
            label="Status"
            value={statusCode !== undefined ? props.addAddress.statusCode : DEFAULT_DD_VALUE}
            onChange={props.handleChangeAddAddress("statusCode")}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            errTxt={
              props.showAddressError.showStatusCodeError
                ? props.showAddressErrorMsg.showStatusCodeErrorMsg
                : null
            }
            dropdownList={StatusCodeDropdown
              ? StatusCodeDropdown.map(option => (
                <option key={option} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
          />
        <div className="mui-custom-form override-width-45">
          <TextField
            id="addressLine1"
            label="Address Line 1"
            required
            inputProps={{ maxlength: 64 }}
            value={props.addAddress.addressLine1}
            onChange={props.handleChangeAddAddress("addressLine1")}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            helperText={
              props.showAddressError.showAddressLine1Error
                ? props.showAddressErrorMsg.showAddressLine1ErrorMsg
                : null
            }
            error={
              props.showAddressError.showAddressLine1Error
                ? props.showAddressErrorMsg.showAddressLine1ErrorMsg
                : null
            }
          />
        </div>
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form override-width-45">
          <TextField
            id="addressLine2"
            label="Address Line 2"
            inputProps={{ maxlength: 64 }}
            value={props.addAddress.addressLine2}
            onChange={props.handleChangeAddAddress("addressLine2")}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            helperText={
              props.showAddressError.showAddressLine2Error
                ? props.showAddressErrorMsg.showAddressLine2ErrorMsg
                : null
            }
            error={
              props.showAddressError.showAddressLine2Error
                ? props.showAddressErrorMsg.showAddressLine2ErrorMsg
                : null
            }
          />
        </div>
        <div className="mui-custom-form">
          <TextField
            id="city"
            label="City"
            value={props.addAddress.city}
            required
            inputProps={{ maxlength: 30 }}
            onChange={props.handleChangeAddAddress("city")}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            helperText={
              props.showAddressError.showCityError
                ? props.showAddressErrorMsg.showCityErrorMsg
                : null
            }
            error={
              props.showAddressError.showCityError
                ? props.showAddressErrorMsg.showCityErrorMsg
                : null
            }
          />
        </div>
          <NativeDropDown
            id="state"
            inputProps={{ maxlength: 2 }}
            label="State"
            isRequired
            value={stateCode !== undefined ? props.addAddress.state : DEFAULT_DD_VALUE}
            onChange={props.handleChangeAddAddress("state")}
            placeholder=""
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            errTxt={
              props.showAddressError.showStateError
                ? props.showAddressErrorMsg.showStateErrorMsg
                : null
            }
            dropdownList={StateDropdown
              ? StateDropdown.map(option => (
                <option key={option} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
          />
      </div>
      <div className="form-wrapper">
        <div className="mui-custom-form ">
          <TextField
            id="zipCode5"
            label="Zip Code"
            required
            inputProps={{ maxlength: 5 }}
            value={props.addAddress.zipCode5}
            onChange={props.handleChangeAddAddress("zipCode5")}
            InputLabelProps={{
              shrink: true
            }}
            disabled={disableFields || !userInquiryPrivileges}
            helperText={
              props.showAddressError.showZipcodeError
                ? props.showAddressErrorMsg.showZipcodeErrorMsg
                : props.showAddressError.showZipcodeReqError
                ? props.showAddressErrorMsg.showZipcodeReqErrorMsg
                : null
            }
            error={
              props.showAddressError.showZipcodeError
                ? props.showAddressErrorMsg.showZipcodeErrorMsg
                : props.showAddressError.showZipcodeReqError
                ? props.showAddressErrorMsg.showZipcodeReqErrorMsg
                : null
            }
          />
        </div>
      </div>

      <div
        onClick={() => setShowAdditionalDetails(!showAdditionalDetails)}
        style={{
          backgroundColor: "#274463",
          color: "#fff",
          padding: "10px",
          margin: "10px 10px"
        }}
      >
        Additional Address Details{" "}
        <div className="pull-right">
          {showAdditionalDetails ? (
            <span>
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </span>
          ) : (
            <span>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          )}
        </div>
      </div>
      {(() => {
        if (showAdditionalDetails) {
          return (
            <div>
              <div className="form-wrapper">
                <NativeDropDown
                    id="county"
                  inputProps={{ maxlength: 5 }}
                    label="County"
                    value={props.addAddress.county}
                    onChange={props.handleChangeAddAddress("county")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu
                      }
                    }}
                    disabled={disableFields || !userInquiryPrivileges}
                  errTxt={
                      props.showAddressError.showCountyError
                        ? props.showAddressErrorMsg.showCountyErrorMsg
                        : null
                    }
                  dropdownList={CountyDropdown
                      ? CountyDropdown.map(option => (
                        <option key={option} value={option.code}>
                            {option.description}
                        </option>
                        ))
                      : null}
                />
                <div className="mui-custom-form">
                  <TextField
                    id="town"
                    label="Town"
                    inputProps={{ maxlength: 3 }}
                    value={props.addAddress.town}
                    onChange={props.handleChangeAddAddress("town")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={disableFields || !userInquiryPrivileges}
                    helperText={
                      props.showAddressError.showTownError
                        ? props.showAddressErrorMsg.showTownErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showTownError
                        ? props.showAddressErrorMsg.showTownErrorMsg
                        : null
                    }
                  />
                </div>
                <div className="mui-custom-form">
                  <TextField
                    id="addressLine3"
                    label="Address Line 3"
                    inputProps={{ maxlength: 64 }}
                    value={props.addAddress.addressLine3}
                    onChange={props.handleChangeAddAddress("addressLine3")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={disableFields || !userInquiryPrivileges}
                    helperText={
                      props.showAddressError.showAddressLine3Error
                        ? props.showAddressErrorMsg.showAddressLine3ErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showAddressLine3Error
                        ? props.showAddressErrorMsg.showAddressLine3ErrorMsg
                        : null
                    }
                  />
                </div>
                <NativeDropDown
                    id="country"
                  inputProps={{ maxlength: 3 }}
                    label="Country"
                    value={props.addAddress.country}
                    onChange={props.handleChangeAddAddress("country")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={disableFields || !userInquiryPrivileges}
                  errTxt={
                      props.showAddressError.showCountryError
                        ? props.showAddressErrorMsg.showCountryErrorMsg
                        : null
                    }
                  dropdownList={CountryDropdown
                      ? CountryDropdown.map(option => (
                        <option key={option} value={option.code}>
                            {option.description}
                        </option>
                        ))
                      : null}
                />
              </div>
              <div className="form-wrapper">
                <div className="mui-custom-form">
                  <TextField
                    id="addressLine4"
                    label="Address Line 4"
                    inputProps={{ maxlength: 64 }}
                    value={props.addAddress.addressLine4}
                    onChange={props.handleChangeAddAddress("addressLine4")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={disableFields || !userInquiryPrivileges}
                    helperText={
                      props.showAddressError.showAddressLine4Error
                        ? props.showAddressErrorMsg.showAddressLine4ErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showAddressLine4Error
                        ? props.showAddressErrorMsg.showAddressLine4ErrorMsg
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          );
        }
      })()}
      <div
        onClick={() => setContactDetails(!showContactDetails)}
        style={{
          backgroundColor: "#274463",
          color: "#fff",
          padding: "10px",
          margin: "10px 10px"
        }}
      >
        Contact Details{" "}
        <div className="pull-right">
          {showContactDetails ? (
            <span>
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </span>
          ) : (
            <span>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          )}
        </div>
      </div>
      {(() => {
        if (showContactDetails) {
          return (
            <div>
              <div className="form-wrapper">
                <div
                  className="mui-custom-form no-margin-label"
                  style={{ padding: "0px" }}
                >
                  <TextField
                    id="phone"
                    label="Phone"
                    inputProps={{ maxlength: 10 }}
                    value={props.addAddress.phone}
                    onChange={props.handleChangeAddAddress("phone")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={disableFields || !userInquiryPrivileges}
                    helperText={
                      props.showAddressError.showPhoneError
                        ? props.showAddressErrorMsg.showPhoneErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showPhoneError
                        ? props.showAddressErrorMsg.showPhoneErrorMsg
                        : null
                    }
                  />
                </div>
                <div className="mui-custom-form">
                  <TextField
                    id="Email"
                    label="Email"
                    inputProps={{ maxlength: 64 }}
                    value={props.addAddress.email}
                    onChange={props.handleChangeAddAddress("email")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled={disableFields || !userInquiryPrivileges}
                    helperText={
                      props.showAddressError.showEmailError
                        ? props.showAddressErrorMsg.showEmailErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showEmailError
                        ? props.showAddressErrorMsg.showEmailErrorMsg
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          );
        }
      })()}
      <div
        onClick={() => setShowAdditionalDetails1(!showAdditionalDetails1)}
        style={{
          backgroundColor: "#274463",
          color: "#fff",
          padding: "10px",
          margin: "10px 10px"
        }}
      >
        Address Verification
        <div className="pull-right">
          {showAdditionalDetails1 ? (
            <span>
              <i class="fa fa-angle-up" aria-hidden="true"></i>
            </span>
          ) : (
            <span>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </span>
          )}
        </div>
      </div>
      {(() => {
        if (showAdditionalDetails1) {
          return (
            <div>
              <div className="form-wrapper">
                <div className="mui-custom-form  with-select">
                  <TextField
                    id="uspsAddress1"
                    label="Address Line 1"
                    inputProps={{ maxlength: 64 }}
                    value={props.uspsAddres.addressLine1}
                    onChange={props.handleChangeAddAddress("uspsAddress1")}
                    placeholder=""
                    disabled
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={
                      props.showAddressError.showAddressVerificationError
                        ? props.showAddressErrorMsg
                            .showAddressVerificationErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showAddressVerificationError
                        ? props.showAddressErrorMsg
                            .showAddressVerificationErrorMsg
                        : null
                    }
                  />
                </div>
                <div className="mui-custom-form">
                  <TextField
                    id="addressLine2"
                    label="Address Line 2"
                    inputProps={{ maxlength: 64 }}
                    disabled
                    value={props.uspsAddres.addressLine2}
                    onChange={props.handleChangeAddAddress("addressLine2")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={
                      props.showAddressError.showAddressLine2Error
                        ? props.showAddressErrorMsg.showAddressLine2ErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showAddressLine2Error
                        ? props.showAddressErrorMsg.showAddressLine2ErrorMsg
                        : null
                    }
                  />
                </div>
                <div className="mui-custom-form">
                  <TextField
                    id="city"
                    label="City"
                    disabled
                    value={props.uspsAddres.city}
                    inputProps={{ maxlength: 30 }}
                    onChange={props.handleChangeAddAddress("city")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={
                      props.showAddressError.showCityError
                        ? props.showAddressErrorMsg.showCityErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showCityError
                        ? props.showAddressErrorMsg.showCityErrorMsg
                        : null
                    }
                  />
                </div>
                <NativeDropDown
                    id="state"
                  inputProps={{ maxlength: 2 }}
                    label="State"
                    value={props.uspsAddres.state}
                    onChange={props.handleChangeAddAddress("state")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    disabled
                  errTxt={
                      props.showAddressError.showStateError
                        ? props.showAddressErrorMsg.showStateErrorMsg
                        : null
                    }
                  dropdownList={StateDropdown
                      ? StateDropdown.map(option => (
                        <option key={option} value={option.code}>
                            {option.description}
                        </option>
                        ))
                      : null}
                />
                <div className="mui-custom-form  with-select ">
                  <TextField
                    id="uspsAddressVerifyCode"
                    label="USPS Verified Code"
                    inputProps={{ maxlength: 2 }}
                    value={props.uspsAddres.zipCode5}
                    onChange={props.handleChangeAddAddress(
                      "uspsAddressVerifyCode"
                    )}
                    placeholder=""
                    disabled
                    InputLabelProps={{
                      shrink: true
                    }}
                    helperText={
                      props.showAddressError.showUSPSVerifiedCodeError
                        ? props.showAddressErrorMsg.showUSPSVerifiedCodeErrorMsg
                        : null
                    }
                    error={
                      props.showAddressError.showUSPSVerifiedCodeError
                        ? props.showAddressErrorMsg.showUSPSVerifiedCodeErrorMsg
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          );
        }
      })()}
      <div className="mui-custom-form input-md">
        <label className="MuiFormLabel-root small-label" for="fiscalyes">
          Consider USPS
        </label>
        <div className="sub-radio">
          <Radio
            value="Y"
            name="void"
            id="fiscalyes"
            checked={considerUSPS === "Y"}
            disabled={disableFields || !userInquiryPrivileges}
            onChange={handleChangeUSPS("Flag")}
          />
          <span className="text-black"> Yes </span>
          <Radio
            defaultChecked="true"
            value="N"
            className="ml-2"
            name="void"
            id="fiscalyes"
            checked={considerUSPS === "N"}
            disabled={disableFields || !userInquiryPrivileges}
            onChange={handleChangeUSPS("Flag")}
          />
          <span className="text-black"> No </span>
        </div>
      </div>
    </form>
    </div>
  );
}
