/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Bootstrap, { Button } from 'react-bootstrap';
import * as ARSearchConstants from './ARSearchConstants';
import AccountRecevSearchResultsComponent from './AccountRecevSearchResultsComponent';
import { withRouter } from 'react-router';

import { useDispatch, useSelector } from 'react-redux';
import {
  searchFCNAccountReceivableAction,
  resetFCNSearchAccountReceivable
} from '../../../Store/Actions/accountReceivable/accountReceivableActions';
import * as AppConstants from '../../../../../SharedModules/AppConstants';
import ValidValueSearchTableComponent from '../../../../ApplicationConfiguration/Components/ValidValue/ValidValueSearchTableComponent';

/* FCNInquiry()
 * Used to search for FCN number
 */
function FCNInquiry (props) {
  const dispatch = useDispatch();
  const onReset = () => dispatch(resetFCNSearchAccountReceivable());

  let errorMessagesArray = [];
  const [errorMeg, setErrorMeg] = React.useState('');

  const [redirect, setRedirect] = React.useState(0);
  let payload = [];
  const [payeeTableData, setpayeeTableData] = React.useState([]);
  const [isSearch, setIsSearch] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);
  const showLoaderForTable = value => {
    props.showLoader(value);
  };

  const onSearch = values => dispatch(searchFCNAccountReceivableAction(values?.trim()));
  payload = useSelector(state => state.accountReceivableState.payload);
  useEffect(() => {
    payload = null;
  }, []);

  /* Show error messages if record not found for FCN number entered */
  useEffect(() => {
    if (payload && payload === []) {
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.NO_RECORDS_FOUND);
      props.showError(errorMessagesArray);
      props.showLoader(false);
    }
    if (payload && payload.respcode === '02' && payload.respdesc !== '') {
      errorMessagesArray = [];
      errorMessagesArray.push(payload.respdesc);
      props.showError(errorMessagesArray);
      props.showLoader(false);
    }
    if (payload && payload.systemFailure !== undefined) {
      errorMessagesArray.push(ARSearchConstants.TRANSACTION_ERROR);
      props.showError(errorMessagesArray);
      props.showLoader(false);
    }

    /* If FCN number found and only one record found redirecting to edit screen with search results
     * redirect to claims receivable if activity code is "CR"
     */
    if (
      payload != null &&
      payload !== '' &&
      payload.systemFailure === undefined
    ) {
      if (!props.location.tableRender) {
        if (payload.activityTypeCode === 'CR' || payload.activityTypeCode === 'CR-Claims Rec') {
          if (
            payload.payeePayerIDTypeCode != null &&
            payload.payeePayerIDTypeCode !== undefined
          ) {
            for (var payeeIdTypeCode in props.payeeTypeCodeDataInitial) {
              if (
                props.payeeTypeCodeDataInitial[payeeIdTypeCode].code ===
                payload.payeePayerIDTypeCode
              ) {
                payload.payeePayerIDTypeCode =
                  props.payeeTypeCodeDataInitial[payeeIdTypeCode].description;
              }
            }

            for (var lobCode in props.lobData) {
              if (props.lobData[lobCode].code === payload.lob) {
                payload.lob = props.lobData[lobCode].description;
              }
            }

            for (var payeeType in props.payeeTypeData) {
              if (
                props.payeeTypeData[payeeType].code === payload.payeePayerTypeCode
              ) {
                payload.payeePayerTypeCode =
                  props.payeeTypeData[payeeType].description;
              }
            }
          }
          payload.activityTypeCode = 'CR-Claims Rec';
          props.history.push({
            pathname: '/ClaimsReceivable',
            state: { payload, values: values }
          });
        } else if (payload.activityTypeCode === 'WL') {
          const withHoldSearchResult = payload;
          props.history.push({
            pathname: '/FinancialAccountLienLevyUpdate',
            state: { withHoldSearchResult, values: values }
          });
        } else if (payload.activityTypeCode === 'GL') {
          const claimsReceivableData = payload;
          props.history.push({
            pathname: '/FinancialAccountGrossLevelUpdate',
            state: { claimsReceivableData, values: values }
          });
        } else if (payload.activityTypeCode === 'AP') {
          const advancePaymentData = payload;
          props.history.push({
            pathname: '/AdvancePayment',
            state: { advancePaymentData, values: values }
          });
        } else {
          if (payload.activityTypeCode === undefined) {
            errorMessagesArray = [];
            errorMessagesArray.push(ARSearchConstants.NO_RECORDS_FOUND);
            props.showError(errorMessagesArray);
          } else {
            const claimsReceivableData = payload;
            props.history.push({
              pathname: '/FinancialAccountGrossLevelUpdate',
              state: { claimsReceivableData, values: values }
            });
          }
        }
      } else {
        setShowTable(true);
        const newPayload = [];
        newPayload.push(payload);
        setpayeeTableData(newPayload);
      }
    }

    props.showLoader(false);
  }, [payload]);
  const [values, setValues] = React.useState({
    fcn: ''
  });

  const valuesFromAREdit = props?.history?.state?.values;
  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (valuesFromAREdit !== undefined && typeof valuesFromAREdit === 'string') {
        props.showFieldError(
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false
        );
        errorMessagesArray = [];
        props.showError(errorMessagesArray);
        props.showLoader(true);
        onSearch(valuesFromAREdit);
        setValues({ fcn: valuesFromAREdit });
      };
    }
  }, [props.location.tableRender]);



  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  /* Restting error messgaes and FCN field value */
  const resetFCN = () => {
    setValues({ fcn: '' });
    errorMessagesArray = [];
    props.showFieldError(
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false
    );
    setShowTable(false);
    props.showError(errorMessagesArray);
    onReset();
    const valuetoredirect = redirect - 1;
    setRedirect(valuetoredirect);
  };

  /* Search FCN
   * Validate FCN field for empty and valid data enetered
   * If validations passed search for FCN number by hitting API
   */
  const searchFCN = () => {
    sessionStorage.setItem('tabValue', 0);
    if (isNullOrEmpty(values.fcn)) {
      setValues({ fcn: '' });
      props.showFieldError(
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      errorMessagesArray = [];
      errorMessagesArray.push(ARSearchConstants.FCN_REQUIRED);
      setErrorMeg(ARSearchConstants.FCN_REQUIRED);
      props.showError(errorMessagesArray);
    } else if (values.fcn.indexOf(' ') >= 0) {
      errorMessagesArray = [];
      errorMessagesArray.push(AppConstants.INCORRECT_DATA_FORMAT);
      setErrorMeg(AppConstants.INCORRECT_DATA_FORMAT);
      props.showError(errorMessagesArray);
    } else {
      props.showFieldError(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      );
      errorMessagesArray = [];
      props.showError(errorMessagesArray);
      props.showLoader(true);
      onSearch(values.fcn);
      if(!props.history.state){
        props.history.state = {}
      }
      props.history.state.values = values.fcn
      setIsSearch(true);
      props.location.tableRender = false;
    }
  };

  /* Check if text enetered is empty or contains only white spaces */
  const isNullOrEmpty = str => {
    return !str || !str.trim();
  };

  return (
    <div className="pos-relative w-100 h-100">
      <div>
        <div className="mui-custom-form input-md">
          <TextField
            id='ARSearch_0'
            required
            label={ARSearchConstants.FCN_TEXT}
            InputLabelProps={{
              shrink: true
            }}
            value={values.fcn}
            onChange={handleChange('fcn')}
            inputProps={{ maxLength: 14 }}
            helperText={props.showFCNError ? errorMeg : null}
            error={props.showFCNError ? errorMeg : null}
          />
        </div>
      </div>
      <div className="float-right mr-3 mb-2">
        <Button className="btn-tab-pannel" onClick={() => searchFCN()}>
          <i class="fa fa-search" aria-hidden="true"></i>Search
        </Button>

        <Button
          variant="outlined"
          color="primary"
          className="bt-tabreset btn-transparent ml-1"
          onClick={() => resetFCN()}
        >
          <i class="fa fa-undo" aria-hidden="true"></i>Reset
        </Button>
      </div>
      <div className="clearfix"></div>
      { showTable ? (
        <div className="tab-holder my-3 p-0 tableNoWrap">
          <AccountRecevSearchResultsComponent
            tableData={payeeTableData}
            payeeTypeCodeDataInitial={props.payeeTypeCodeDataInitial}
            showLoaderForTable={showLoaderForTable}
            values={values}
            dropdowns={props.dropdowns}
          />
        </div>
      ) : null}

    </div>
  );
}

export default withRouter(FCNInquiry);
