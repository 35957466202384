/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Bootstrap, { Button } from "react-bootstrap";

export default function ResetPassword() {
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const handleChanges = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const [showTable, setShowTable] = React.useState(false);
  const [values, setValues] = React.useState({
    NewPassword: "",
    ConfirmPassword: ""
  });
  const resetTable = () => {
    setValues({
      NewPassword: "",
      ConfirmPassword: ""
    });
    setShowTable(false);
  };

  const isPageValid = () => {
    return true;
  };
  if (!isPageValid()) {
    return (
      <div className="pos-relative w-100 h-100">

        <div className="tabs-container">
          <div className="tab-header">
            <h1 className="tab-heading float-left">No Access</h1>
          </div>
        </div>
      </div>
    );
  }
  return (

    <div className="pos-relative w-100 h-100">

      <div className="tabs-container">
        <div className="tab-header">
          <h1 className="tab-heading float-left">Reset Password</h1>
          <div className="float-right">
            <Button
              variant="outlined"
              color="primary"
              className="btn btn-secondary ml-1 mt-2"
            >
              <i class="fa fa-question-circle" aria-hidden="true"></i>
              Help
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form autoComplete="off">
            <div className="form-wrapper">
              <div>
                <ul style={{ marginTop: "15px" }} password-left-padding>
                  <li className="very-small-label">
                    Password must be minium of 10 characters
                  </li>
                  <li className="very-small-label">
                    Password must be different from last 5 passwords
                  </li>
                  <li className="very-small-label">
                    Password must contains 3 out of 4 following types
                    <ul password-left-padding>
                      <li className="very-small-label">Uppercase letters</li>
                      <li className="very-small-label">Lowercase letters</li>
                      <li className="very-small-label">Numbers</li>
                      <li className="very-small-label">
                        Non Alphanumerics (! $ # or %)
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-xl">
                <TextField
                  id="standard-code1"
                  label="New Password"
                  type="password"
                  value={values.NewPassword}
                  onChange={handleChanges("NewPassword")}
                  password="true"
                  autoFocus="true"
                  placeholder="New Password"
                  inputProps={{ maxLength: 15 }}
                  required="true"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-xl">
                <TextField
                  id="standard-code2"
                  label="Confrm Password"
                  type="password"
                  value={values.ConfirmPassword}
                  onChange={handleChanges("ConfirmPassword")}
                  placeholder="Confirm Password"
                  inputProps={{ maxLength: 15 }}
                  required="true"
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </div>
            </div>
            <div
              className="float-right mr-3 mb-2"
              style={{ marginTop: "20px" }}
            >
              <Button
                variant="outlined"
                color="primary"
                className="float-right bt-reset btn-transparent  ml-1"
                onClick={() => resetTable()}
              >
                {" "}
                <i class="fa fa-undo" aria-hidden="true"></i> Reset{" "}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="float-right btn btn-primary"
              >
                {" "}
                Update{" "}
              </Button>
            </div>
            <div className="clearfix"></div>
          </form>
        </div>
      </div>
    </div>
  );
}
