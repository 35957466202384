/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import InputAdornment from '@material-ui/core/InputAdornment';
import Spinner from '../../../SharedModules/Spinner/Spinner';
import * as AppConstants from '../../../SharedModules/AppConstants';
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../SharedModules/CommonUtilities/commonUtilities'
import {
  compareTwoDates,
  validateDateMinimumValue,
  getDateInMMDDYYYYFormat,
  getDateInYYYYMMDDFormatWithApendZero
} from '../../../SharedModules/DateUtilities/DateUtilities';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import FundCodeSearchTable from './FundCodeSearchTable';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import * as ErrorConstants from '../Components/FundCodeConstants';
import moment from 'moment';
import {
  getDropdownDataAction,
  searchCriteriaAction,
  resetFundCodeSearch,
  resetResponse
} from '../Store/Actions/FundCodeSearchActions';
import dropdownCriteria from './FundCodeSearch.json';
import { Checkprivileges } from '../../../SharedModules/store/Actions/SharedAction';

import Footer from '../../../SharedModules/Layout/footer';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_APP_DROPDOWNS } from "../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../SharedModules/Dropdowns/NativeDropDown';

const useStyles = makeStyles(theme => ({
  customTooltip: {
    backgroundColor: '#D3D3D3',
    color: '#2F2F2F',
    fontWeight: 'normal',
    fontSize: '1em',
    margin: '0px',
    marginRight: '0px'
  },
  customArrow: {
    color: '#D3D3D3',

  }
}))
function FundCodeSearch(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const classes = useStyles();
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  
  const onReset = () => dispatch(resetFundCodeSearch());
  const onResetResponse = () => dispatch(resetResponse());
  const getDropdownData = dropdownValues => {
    dispatch(getDropdownDataAction(dropdownValues));
  };
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  
  let searchResult = [];
  const [isSearch, setIsSearch] = React.useState(false);
  searchResult = useSelector(state => state.fundCodeState.searchResults);
  const [searchData, setSearchData] = React.useState([]);
  const searchError = useSelector(state => state.fundCodeState.searchError);
  const systemError = [];
  const [values, setValues] = React.useState({
    lobCode: DEFAULT_DD_VALUE,
    fundCode: DEFAULT_DD_VALUE,
    cosCode: DEFAULT_DD_VALUE,
    activityCode: DEFAULT_DD_VALUE,
    beginDate: '',
    endDate: ''
  });
  const [redirect, setRedirect] = React.useState(0);
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [fundCodeList, setFundCodeList] = React.useState([]);
  const [cosList, setCosList] = React.useState([]);
  const [agencyList, setAgencyList] = React.useState([]);
  const [beginDateError, setShowBeginDateError] = React.useState(false);
  const [endDateError, setShowEndDateError] = React.useState(false);
  const [beginDateErrorDes, setBeginDateErrorDes] = React.useState('');
  const [endDateErrorDes, setEndDateErrorDes] = React.useState('');
  const dropdown = useSelector(state => state.fundCodeState.dropdownData);  
  const onSearchCriteria = value => {
    dispatch(searchCriteriaAction(value));
  };
 
  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleDateChange = date => {
    setSelectedDate(date);
    setShowBeginDateError(false);
  };
  const handleEndDateChange = date => {
    setSelectedEndDate(date);
    setShowEndDateError(false);
  };
  const NoRecordError = () => {
    const searchErrorDes = [];
    searchErrorDes.push(ErrorConstants.NO_RECORD);
    seterrorMessages(searchErrorDes);
  };
  const fundCodeSearchReset = () => {
    onReset();
    setShowTable(false);
    setValues({
      lobCode: DEFAULT_DD_VALUE,
      fundCode: DEFAULT_DD_VALUE,
      cosCode: DEFAULT_DD_VALUE,
      activityCode: DEFAULT_DD_VALUE,
      beginDate: '',
      endDate: ''
    });
    setSelectedDate(null);
    setSelectedEndDate(null);
    seterrorMessages([]);
    setspinnerLoader(false);
    setShowBeginDateError(false);
    setShowEndDateError(false);
  };
  const fundCodeSearch = () => {
    setIsSearch(true);
    const tempErrorMessage = [];
    setBeginDateErrorDes(null);
    setEndDateErrorDes(null);
    setShowBeginDateError(false);
    setShowBeginDateError(false);
    if (selectedDate !== null) {
      if (selectedDate.toString() === 'Invalid Date') {
        tempErrorMessage.push(ErrorConstants.BEGIN_DATE_VALID);
        setBeginDateErrorDes(ErrorConstants.BEGIN_DATE_VALID);
        seterrorMessages(tempErrorMessage);
        setShowBeginDateError(true);
      } else if (validateDateMinimumValue(selectedDate)) {
        tempErrorMessage.push(AppConstants.DATE_ERROR_1964);
        setBeginDateErrorDes(AppConstants.DATE_ERROR_1964);
        seterrorMessages(tempErrorMessage);
        setShowBeginDateError(true);
      }
    }
    if (selectedEndDate !== null) {
      if (selectedEndDate.toString() === 'Invalid Date') {
        tempErrorMessage.push(ErrorConstants.END_DATE_VALID);
        setEndDateErrorDes(ErrorConstants.END_DATE_VALID);
        seterrorMessages(tempErrorMessage);
        setShowEndDateError(true);
      } else if (validateDateMinimumValue(selectedEndDate)) {
        tempErrorMessage.push(AppConstants.DATE_ERROR_1964);
        setEndDateErrorDes(AppConstants.DATE_ERROR_1964);
        seterrorMessages(tempErrorMessage);
        setShowEndDateError(true);
      }
    }
    if (
      selectedDate !== null &&
      selectedEndDate !== null &&
      !beginDateErrorDes &&
      !endDateErrorDes &&
      !compareTwoDates(new Date(selectedDate), new Date(selectedEndDate))
    ) {
      tempErrorMessage.push(ErrorConstants.BEGIN_DATE_GREATER_ERROR);
      setBeginDateErrorDes(ErrorConstants.BEGIN_DATE_GREATER_ERROR);
      seterrorMessages(tempErrorMessage);
      setShowBeginDateError(true);
    }
    if (tempErrorMessage.length === 0) {
      let tempbeginDate, tempendDate;
      if (selectedDate) {
        tempbeginDate =
          selectedDate.getFullYear() +
          '-' +
          ('0' + (selectedDate.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + selectedDate.getDate()).slice(-2);
      } else {
        tempbeginDate = '';
      }
      if (selectedEndDate) {
        tempendDate =
          selectedEndDate.getFullYear() +
          '-' +
          ('0' + (selectedEndDate.getMonth() + 1)).slice(-2) +
          '-' +
          ('0' + selectedEndDate.getDate()).slice(-2);
      } else {
        tempendDate = '';
      }
      const tempvalue = {
        lobCode: AppConstants.DEFAULT_LOB_VALUE,
        fundCode: values.fundCode,
        activityCode: values.activityCode,
        cosCode: values.cosCode,
        beginDate: selectedDate ? getDateInYYYYMMDDFormatWithApendZero(selectedDate) : '',
        endDate: selectedEndDate ? getDateInYYYYMMDDFormatWithApendZero(selectedEndDate) : ''
      };
      for (const key in tempvalue) {
        if (
          tempvalue[key].toString() === DEFAULT_DD_VALUE ||
          tempvalue[key].toString() === '0' + DEFAULT_DD_VALUE
        ) {
          tempvalue[key] = '';
        }
      }
      setShowBeginDateError(false);
      setShowEndDateError(false);
      seterrorMessages([]);
      setShowTable(false);
      setspinnerLoader(true);
      onSearchCriteria(removeLeadingTrailingSpaces(tempvalue));
      if(!props.history.state){
        props.history.state = {}
      }
      props.history.state.values = tempvalue
      props.location.isCancel=false
    }
  };

  const [fundCodeTitle, setFundCodeTitle] = useState(null)
  useEffect(() => {
    if (values.fundCode !== DEFAULT_DD_VALUE) {
      setFundCodeTitle(getLongDescription(fundCodeList, values.fundCode))
    }
  }, [values.fundCode])

  useEffect(() => {
    setSearchData([]);
    onReset();
    searchResult = null;
    getDropdownData(dropdownCriteria);
    onDropdowns([
      Dropdowns.FUND_CODE,
      Dropdowns.CATEGORY_OF_SERVICE_VALIDVALUE,
      Dropdowns.AGNCY_CODE,
    ]);
  }, []);

  useEffect(() => {
    if (
      searchResult != null &&
      (searchResult.length > 0 || searchResult.length === 0)
    ) {
      setspinnerLoader(false);
      setSearchData([]);
    }
    if (searchResult != null && searchResult.length === 0) {
      setSearchData([]);
      setspinnerLoader(false);
      NoRecordError();
      setShowTable(false);
    }
    if (searchResult != null && searchResult.length > 0) {
      setSearchData(searchResult);
      setShowTable(true);
      let valuetoredirect = 0;
      valuetoredirect = valuetoredirect + 1;
      setRedirect(valuetoredirect);
    }
    if (searchResult != null) {
      if (searchResult.searchError !== undefined) {
        systemError.push(ErrorConstants.RETRY_TRANSACTION);
        seterrorMessages(systemError);
        setShowTable(false);
        setspinnerLoader(false);
      }
    }
    if (searchResult != null && searchResult.length === 1 && searchResult.searchError === undefined && isSearch) {
     
      onResetResponse();
      props.history.push({
        pathname: '/FundCodeEdit',
        state: { fundCodeData: searchResult, editFlag: '1', values: {...values,selectedDate,selectedEndDate}},
      });
    }
    if (searchResult && searchResult.systemFailure) {
      systemError.push(ErrorConstants.RETRY_TRANSACTION);
      seterrorMessages(systemError);
      setShowTable(false);
      setspinnerLoader(false);
    }
  }, [searchResult]);

  const ValuesFromFuncdCode = props?.history?.state?.values;

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (ValuesFromFuncdCode !== undefined && ValuesFromFuncdCode !== null) {
        seterrorMessages([]);
        setspinnerLoader(true);
        const tempvalue = {
          lobCode: AppConstants.DEFAULT_LOB_VALUE,
          fundCode: ValuesFromFuncdCode.fundCode,
          activityCode: ValuesFromFuncdCode.activityCode,
          cosCode: ValuesFromFuncdCode.cosCode,
          beginDate: ValuesFromFuncdCode.selectedDate ? getDateInYYYYMMDDFormatWithApendZero(ValuesFromFuncdCode.selectedDate) : ValuesFromFuncdCode.beginDate,
          endDate: ValuesFromFuncdCode.selectedEndDate ? getDateInYYYYMMDDFormatWithApendZero(ValuesFromFuncdCode.selectedEndDate) : ValuesFromFuncdCode.endDate
        };
        for (const key in tempvalue) {
          if (
            tempvalue[key].toString() === DEFAULT_DD_VALUE ||
            tempvalue[key].toString() === '0' + DEFAULT_DD_VALUE
          ) {
            tempvalue[key] = '';
          }
        }
        onSearchCriteria(removeLeadingTrailingSpaces(tempvalue));
        const validValues = removeLeadingTrailingSpaces(tempvalue)
        setValues({
            lobCode: validValues.lobCode,
            fundCode: validValues.fundCode ,
            cosCode: validValues.cosCode ,
            activityCode: validValues.activityCode ,
            beginDate: validValues.beginDate,
            endDate: validValues.endDate,
        });
        setSelectedDate(new Date(validValues.beginDate).toString() === 'Invalid Date' ? validValues.beginDate : new Date (validValues.beginDate));
        setSelectedEndDate(new Date(validValues.endDate).toString() === 'Invalid Date' ? validValues.endDate : new Date (validValues.endDate));
        setShowTable(true);
      }
    }
  }, [props.location.tableRender]);

  const fundCodeDescriptionMap = fundCode => {
    const filteredValue = fundCodeList.filter(
      (fund, index) => fund.code === fundCode
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return fundCode;
  };

  const fundCodeLongDescriptionMap = fundCode => {
    const filteredValue = fundCodeList.filter(
      (fund, index) => fund.code === fundCode
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].longDescription;
    }
    return fundCode;
  };

  const cosCodeDescriptionMap = cosCode => {
    const filteredValue = cosList.filter((cos, index) => cos.code === cosCode);
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return cosCode;
  };

  const cosCodeLongDescriptionMap = cosCode => {
    const filteredValue = cosList.filter((cos, index) => cos.code === cosCode);
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].longDescription;
    }
    return cosCode;
  };

  const agencyCodeDescriptionMap = agencyCode => {
    const filteredValue = agencyList.filter((agency, index) =>
      agencyCode && agencyCode.startsWith('0')
        ? '0' + agency.code === agencyCode
        : agency.code === agencyCode
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return agencyCode;
  };

  if (searchResult && searchResult.length > 0) {    
    if(searchResult.length === 1){
      const data = searchResult[0].fundCodeDetailsVO
      searchResult[0] = {
        ...searchResult[0],
        lobCode : data.lobCode,
        fundCodeDesc : data.fundCode,
        fundCode: data.fundCode,
        activityCode: data.activityCode,
        categoryOfServiceCode : values?.cosCode,
        fundCodeLongDesc : data.fundCode,
        cosCodeDesc : values?.cosCode,
        cosCodeLongDesc : values?.cosCode,
        agencyCodeDesc : data.activityCode,
        endDateTemp : moment(new Date(data.endDate)).format('YYYY-MM-DD'),
        endDate : moment(new Date(data.endDate)).format('YYYY-MM-DD'),
        beginDateTemp : moment(new Date(data.beginDate)).format('YYYY-MM-DD'),
        beginDate : moment(new Date(data.beginDate)).format('YYYY-MM-DD'),
        voidDate : data.voidDate,
        rankNumber: data.rankNumber
        
      }
    }else{
        searchResult.map((data, index) => {
          data.fundCodeDesc = data.fundCode;
          data.fundCodeLongDesc = data.fundCode
          data.cosCodeDesc = data.categoryOfServiceCode;
          data.cosCodeLongDesc = data.categoryOfServiceCode;
          data.agencyCodeDesc = data.activityCode;
          if (data.endDate) {
    
            data.endDateTemp = moment.utc(new Date(data.endDate)).format('YYYY-MM-DD');
          }
          if (data.beginDate) {
            data.beginDateTemp = moment.utc(new Date(data.beginDate)).format('YYYY-MM-DD');
          }
        });
    }
  }

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1042']) {
        setFundCodeList(dropdown.listObj['Reference#1042']);
      }
      if (dropdown.listObj['Claims#1020']) {
        setCosList(dropdown.listObj['Claims#1020']);
      }
      if (dropdown.listObj['Financial#1125']) {
        setAgencyList(dropdown.listObj['Financial#1125']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (searchError !== 'undefined') {
      if (searchError === 'System Error') {
        systemError.push(ErrorConstants.RETRY_TRANSACTION);
        seterrorMessages(systemError);
        setShowTable(false);
        setspinnerLoader(false);
      }
    }
  }, [searchError]);

  const addFundCd = () => {
    props.history.push({
      pathname: '/FundCodeAdd'
    });
  };
  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <h1 className="tab-heading float-left">Search Fund Code</h1>
          <div className="float-right mt-1 pt-1">
            <Button
              title="Add Fund Code"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addFundCd()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body" style={{ overflow: 'hidden' }}>
          <form autoComplete="off">
            <div className="form-wrapper">
              <NativeDropDown
                id="fundCode"
                  label="Fund Code"
                value={values.fundCode || DEFAULT_DD_VALUE}
                onChange={handleChange('fundCode')}
                inputProps={{ maxlength: 8 }}
                  title={getLongDescription(fundCodeList, values.fundCode)}
                dropdownList={fundCodeList.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <NativeDropDown
                title={getLongDescription(cosList, values.cosCode)}
                id="cosCode"
                  label="COS"
                  onChange={handleChange('cosCode')}
                value={values.cosCode || DEFAULT_DD_VALUE}
                  inputProps={{ maxlength: 3 }}
                dropdownList={cosList.map(option => (
                  <option key={option.code} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <NativeDropDown
                id="activityCode"
                  label="Agency"
                  onChange={handleChange('activityCode')}
                value={values.activityCode || DEFAULT_DD_VALUE}
                  inputProps={{ maxlength: 3 }}
                dropdownList={agencyList.map(option => (
                  <option key={option.code} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
            </div>
            <div className="form-wrapper">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                    id="date-picker-dialog"
                    label="Begin Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    error={beginDateError ? beginDateErrorDes : null}
                    helperText={beginDateError ? beginDateErrorDes : null}
                    InputLabelProps={{
                      shrink: true
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </div>
                <div className="mui-custom-form with-date">
                  <KeyboardDatePicker
                    maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                    id="date-picker-dialog2"
                    fullWidth
                    label="End Date"
                    format="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    error={endDateError ? endDateErrorDes : null}
                    helperText={endDateError ? endDateErrorDes : null}
                    InputLabelProps={{
                      shrink: true
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </form>

          <div className="float-right mr-4 mb-3">
            <Button
              variant="outlined"
              color="primary"
              className="btn btn-primary ml-1"
              onClick={() => {
                fundCodeSearch();
              }}
            >
              <i class="fa fa-search" aria-hidden="true"></i>
              Search
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="bt-reset btn-transparent ml-1"
              onClick={() => {
                fundCodeSearchReset();
              }}
            >
              <i class="fa fa-undo" aria-hidden="true"></i> Reset
            </Button>
          </div>

          <div className="clearfix"></div>

          {redirect === 1 && showTable ? (
            <div className="tab-holder pb-1">
              <FundCodeSearchTable tableData={searchData.keySort({ fundCodeDesc: 'asc', rankNumber: 'asc' }) || null} values={{...values, selectedDate,selectedEndDate}} dropdowns={addDropdowns} agencyList={agencyList}/>
            </div>
          ) : null}

          <Footer print />
        </div>
      </div>
    </div>
  );
}

export default withRouter(FundCodeSearch);
