/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import Bootstrap, { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import PayeeJson from "./PayeType.json";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";

import axios from "axios";
import clsx from "clsx";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "date-fns";
import Grid from "@material-ui/core/Grid";
import NativeDropDown from "../../../../SharedModules/Dropdowns/NativeDropDown";
const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  dense: {
    marginTop: theme.spacing(2)
  },
  menu: {
    width: 200
  }
}));

export default function AddEditPayeeIdentifier(props) {

  const {
    rowEdit,
    IdentifierDetails,
    handlePayeeChange,
    beginDate,
    endDate,
    handleBeginDate,
    handleEndDate,
    IdentifierErr,
    IdentifierErrMsg,
    payeeIDTypeCodeDropdown,
    saveOrEditPopUpDetails,
    resetPopUp,
    showEditPage,
    disableFields,
    IsG16003,
    userInquiryPrivileges
  } = props;
  return (
    <div className="form-border my-3">    
    <form autoComplete="off">
      <div className="form-wrapper">
        <div className="mui-custom-form with-select">
          <TextField
            id="id-number"
            required
            label="ID Number"
            data-testid="test_id_number"
            inputProps={{ maxlength: 15 }}
            value={IdentifierDetails.idNumber}
            disabled={
              (showEditPage && rowEdit && !IdentifierDetails.id) ||
              IdentifierDetails.mainRecord ||
              disableFields ||
              (!IsG16003 && showEditPage)
            }
            onChange={handlePayeeChange("idNumber")}
            InputLabelProps={{
              shrink: true
            }}
            helperText={
              IdentifierErr.idNumber ? IdentifierErrMsg.idNumber : null
            }
            error={IdentifierErr.idNumber ? IdentifierErrMsg.idNumber : null}
          />
        </div>
          <NativeDropDown
            id="id-type"
            isRequired
            label="ID Type"
            testId="test_id_type"
            inputProps={{ maxlength: 3 }}
            disabled={
              (showEditPage && rowEdit && !IdentifierDetails.id) ||
              IdentifierDetails.mainRecord ||
              disableFields ||
              (!IsG16003 && showEditPage)
            }
            value={IdentifierDetails.idType}
            onChange={handlePayeeChange("idType")}
            InputLabelProps={{
              shrink: true
            }}
            errTxt={IdentifierErr.idType ? IdentifierErrMsg.idType : null}
            dropdownList={props.dropdowns && props.dropdowns['F1#P_ALT_ID_TY_CD'] && 
                props.dropdowns['F1#P_ALT_ID_TY_CD'].length > 0
                 ? props.dropdowns['F1#P_ALT_ID_TY_CD'].map(option => (
                   <option key={option.code} value={option.code}>
                       {option.description}
                   </option>
                   ))
              : null}
          />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form  with-date">
            <KeyboardDatePicker
              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
              id="begin-date"
              label="Begin Date"
              data-testid="test_begin_date"
              format="MM/dd/yyyy"
              required
              disabled={!userInquiryPrivileges || disableFields || (!IsG16003 && showEditPage)}
              placeholder="mm/dd/yyyy"
              value={beginDate}
              onChange={handleBeginDate}
              InputLabelProps={{
                shrink: true
              }}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              helperText={
                IdentifierErr.beginDate ? IdentifierErrMsg.beginDate : null
              }
              error={
                IdentifierErr.beginDate ? IdentifierErrMsg.beginDate : null
              }
            />
          </div>
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="mui-custom-form  with-date">
            <KeyboardDatePicker
              maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
              id="end-date"
              label="End Date"
              data-testid="test_end_date"
              format="MM/dd/yyyy"
              required
              disabled={!userInquiryPrivileges || disableFields || (!IsG16003 && showEditPage)}
              placeholder="mm/dd/yyyy"
              value={endDate}
              onChange={handleEndDate}
              InputLabelProps={{
                shrink: true
              }}
              KeyboardButtonProps={{
                "aria-label": "change date"
              }}
              helperText={
                IdentifierErr.endDate ? IdentifierErrMsg.endDate : null
              }
              error={IdentifierErr.endDate ? IdentifierErrMsg.endDate : null}
            />
          </div>
        </MuiPickersUtilsProvider>
      </div>
    </form>
    </div>
  );
}
