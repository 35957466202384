/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Bootstrap, { Button } from 'react-bootstrap';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useDispatch, useSelector } from 'react-redux';
import data from './DropData.json';
import './DropDownTree.scss';
import ReactDOM from 'react-dom';
import functionalAreas from './AddUserData.json';
import ErrorMessages from '../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../SharedModules/Errors/SuccessMessage';
import Spinner from '../../../SharedModules/Spinner/Spinner';
import { validateUserIdAction, addNewUserActioin, getWorkUnitListAction, getLobDDDataAction, updateExistingUserAction, getValidUserDetails } from './Store/Actions/SecurityActions';
import * as SecurityConstants from './SecurityConstants';
import lobDropdownCriteria from './AddUserLobInput.json';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import { Prompt } from 'react-router-dom';
import { getUTCTimeStamp } from '../../../SharedModules/DateUtilities/DateUtilities';
import * as AppConstants from '../../../SharedModules/AppConstants';
import CssBaseline from '@material-ui/core/CssBaseline';

import { purple } from '@material-ui/core/colors';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 200,
    minHeight: 90

  }
}));

const PurpleSwitch = withStyles(theme => ({

  root: {
    '& .MuiIconButton-root': {
    },
    width: 35,
    padding: 0,
    height: 17,

    display: 'flex'
  },

  switchBase: {
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(18px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: '#274463',
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  },
  checked: {}

}))(Switch);

export default function UserProfile(props) {
  const dispatch = useDispatch();
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [editFlag, setEditFlag] = React.useState(true);
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = React.useState([]);
  const [lobListDD, setLobListDD] = React.useState([]);
  const [workUnitListDD, setWorkUnitListDD] = React.useState([]);
  const [allRoles, setAllRoles] = React.useState(functionalAreas.Roles);
  const [availableRoles, setAvailableRoles] = React.useState(functionalAreas.Roles);
  const [mappedRoles, setMappedRoles] = React.useState([]);
  const [selectedMappdRoles, setSelectedMappdRoles] = React.useState([]);
  const [selectedAvailableRoles, setSelectedAvailableRoles] = React.useState([]);
  const [originalUserPreVi, setOriginalUserPreVi] = React.useState([]);
  const [tempEditObj, setTempEditObj] = React.useState(null);
  const [pwd, setPwd] = React.useState(true);
  const [userStatus, setUserStatus] = React.useState(true);
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  const lobDDList = useSelector(state => state.securityStae.lobDDList);
  const workUnitList = useSelector(state => state.securityStae.workUnitList);
  let validUserDetails = useSelector(state => state.securityStae.validUserDetails);
  let userPrivileges = useSelector(state => state.sharedState.userPrivileges);
  let addUSerResponse = useSelector(state => state.securityStae.addUSerResponse);
  let updateUser = useSelector(state => state.securityStae.updateUser);
  let validUserPrivileges = useSelector(state => state.securityStae.validUserPrivileges);
  const userInquiryPrivileges = !global.globalIsReadOnly();
  useEffect(() => {
    if (validUserPrivileges && validUserPrivileges.length > 0) {
      setMappedRoles(sortRolesArray(validUserPrivileges));
      setOriginalUserPreVi(validUserPrivileges);
      const _filterAvRoles = allRoles.filter((item) => { return !validUserPrivileges.includes(item); });
      setAvailableRoles(sortRolesArray(_filterAvRoles));
    } else {
      setMappedRoles([]);
      const _filterAvRoles = allRoles.filter((item) => { return ![].includes(item); });
      setAvailableRoles(sortRolesArray(_filterAvRoles));
    }
  }, [validUserPrivileges]);
  useEffect(() => {
    if (!editFlag) {
      setMappedRoles([]);
      const _filterAvRoles = allRoles.filter((item) => { return ![].includes(item); });
      setAvailableRoles(sortRolesArray(_filterAvRoles));
    }
  }, [editFlag]);
  const [uDetailsErr, setUDetaulsErr] = React.useState({
    fNameErr: false,
    lNameErr: false,
    eMailErr: false,
    pNumberErr: false,
    unameErr: false,
    lobDDErr: false,
    workUnitErr: false
  });
  const [uDetailsErrMsg, setUDetaulsErrMSg] = React.useState({
    fNameErrMsg: '',
    lNameErrMsg: '',
    eMailErrMsg: '',
    pNumberErrMsg: '',
    unameErrMsg: '',
    lobDDErrMsg: '',
    workunitErrMsg: ''
  });
  const getDDData = () => {
    dispatch(getLobDDDataAction(lobDropdownCriteria));
    dispatch(getWorkUnitListAction());
  };
  const handleChangePwd = event => {
    setAllowNavigation(true);
    setPwd(event.target.checked);
  };
  const handleChangeuserStatus = event => {
    setAllowNavigation(true);
    setUserStatus(event.target.checked);
  };
  const handleChangeMappedRoles = event => {
    setAllowNavigation(true);
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedMappdRoles(value);
  };
  const handleChangeAvailableRoles = event => {
    setAllowNavigation(true);
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setSelectedAvailableRoles(value);
  };
  const sortRolesArray = (rolesList) => {
    return rolesList.sort((a, b) => {
      if (a.toUpperCase() > b.toUpperCase()) return 1;
      else if (a.toUpperCase() < b.toUpperCase()) return -1;
      else return 0;
    });
  };
  const moveFromMap = () => {
    const tempErr = [];
    if (selectedMappdRoles && selectedMappdRoles.length > 0) {
      const tempArray = [...availableRoles];
      if (selectedMappdRoles && selectedMappdRoles.length > 0) {
        selectedMappdRoles.map((item) => {
          tempArray.push(item);
        });
      }
      const _mappedRoles = mappedRoles.filter((item) => { return !selectedMappdRoles.includes(item); });
      setMappedRoles(sortRolesArray(_mappedRoles));
      setAvailableRoles(sortRolesArray(tempArray));
      setSelectedMappdRoles([]);
    } else {
      tempErr.push(SecurityConstants.NO_ROLES_SELECTED_TO_MOVE_DEMAP);
    }
    setErrorMessages(tempErr);
  };
  const moveToMap = () => {
    const tempErr = [];
    if (selectedAvailableRoles && selectedAvailableRoles.length > 0) {
      const tempArray = [...mappedRoles];
      if (selectedAvailableRoles && selectedAvailableRoles.length > 0) {
        selectedAvailableRoles.map((item) => {
          tempArray.push(item);
        });
      }
      const _availableRoles = availableRoles.filter((item) => { return !selectedAvailableRoles.includes(item); });
      setAvailableRoles(sortRolesArray(_availableRoles));
      setMappedRoles(sortRolesArray(tempArray));
      setSelectedAvailableRoles([]);
    } else {
      tempErr.push(SecurityConstants.NO_ROLES_SELECTED_TO_MOVE_MAP);
    }
    setErrorMessages(tempErr);
  };
  const handleChanges = name => event => {
    if (name !== 'userid') {
      setAllowNavigation(true);
    }
    if (name === 'userid') {
      convertToUpperCase(event.target.value);
    }
    setValues({ ...values, [name]: event.target.value });
  };
  const allInquiryCBevt = name => event => {
    setValues({ ...values, [name]: event.target.checked });
    if (name === 'allInquiry') {
      if (event.target.checked) {
        setMappedRoles(sortRolesArray(functionalAreas.AllInquiryRoles));
        const _availableRoles = allRoles.filter((item) => { return !functionalAreas.AllInquiryRoles.includes(item); });
        setAvailableRoles(sortRolesArray(_availableRoles));
      } else {
        setMappedRoles(sortRolesArray(originalUserPreVi));
        const _availableRoles = allRoles.filter((item) => { return !originalUserPreVi.includes(item); });
        setAvailableRoles(sortRolesArray(_availableRoles));
      }
    }
  };

  useEffect(() => {
    userPrivileges = null;
    validUserDetails = null;
    addUSerResponse = null;
    updateUser = null;
    validUserPrivileges = null;
    setErrorMessages([]);
    setSuccessMessages([]);
    getDDData();
    setMappedRoles([]);
    setOriginalUserPreVi([]);
    validateUserBtnEvent();
  }, []);
  useEffect(() => {
    const tempErroArray = [];
    if (lobDDList && lobDDList.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      tempErroArray.push(SecurityConstants.GENERIC_SYSTEM_ERROR);
    }
    if (lobDDList && !lobDDList.systemFailue && lobDDList.listObj && lobDDList.listObj['Reference#1019']) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      setLobListDD(lobDDList.listObj['Reference#1019']);
    }
    if (tempErroArray.length > 0) {
      setErrorMessages(tempErroArray);
    }
  }, [lobDDList]);
  useEffect(() => {
    const tempErroArray = [];
    if (workUnitList && workUnitList.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      tempErroArray.push(SecurityConstants.GENERIC_SYSTEM_ERROR);
    }
    if (workUnitList && !workUnitList.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      if (workUnitList.deptList && workUnitList.deptList.length > 0) {
        setWorkUnitListDD(workUnitList.deptList);
      }
    }
    if (tempErroArray.length > 0) {
      setErrorMessages(tempErroArray);
    }
  }, [workUnitList]);
  const classes = useStyles();

  const [values, setValues] = React.useState({
    userid: '',
    prefix: 'Please Select One',
    firstName: '',
    password: '',
    lastName: '',
    middleName: '',
    suffix: '',
    phone: '',
    Email: '',
    lob: 'Please Select One',
    workUnitSK: 'Please Select One',
    allInquiry: false,
    datamaskField: false
  });
  const resetTable = () => {
    setErrorMessages([]);
    setSuccessMessages([]);
    if
      (tempEditObj) {
      const userDetails = tempEditObj;
      setUserStatus(userDetails.accountActiveIndicator);
      setPwd(true);
      setValues({
        ...values,
        prefix: userDetails.prefixName ? userDetails.prefixName : 'Please Select One',
        firstName: userDetails.firstName ? userDetails.firstName : '',
        password: '',
        lastName: userDetails.lastName ? userDetails.lastName : '',
        middleName: userDetails.middleName ? userDetails.middleName : '',
        suffix: userDetails.suffixName ? userDetails.suffixName : '',
        phone: userDetails.phoneNumber ? userDetails.phoneNumber : '',
        Email: userDetails.emailAddress ? userDetails.emailAddress : '',
        allInquiry: false,
        datamaskField: false,
        workUnitSK: userDetails.workUnit && userDetails.workUnit.department && userDetails.workUnit.department.workUnitSK ? userDetails.workUnit.department.workUnitSK : 'Please Select One',
        lob: userDetails.userLOBs && userDetails.userLOBs[0] && userDetails.userLOBs[0].lobCode ? userDetails.userLOBs[0].lobCode : 'Please Select One'
      });
      setMappedRoles(sortRolesArray(originalUserPreVi));
    } else {
      setMappedRoles([]);
      setUserStatus(false);
      setPwd(false);
      setValues(
        {
          userid: '',
          prefix: 'Please Select One',
          firstName: '',
          password: '',
          lastName: '',
          middleName: '',
          suffix: '',
          phone: '',
          Email: '',
          lob: 'Please Select One',
          workUnitSK: 'Please Select One',
          allInquiry: false,
          datamaskField: false
        });
    }
    setSelectedMappdRoles([]);
    setSelectedAvailableRoles([]);
    setUDetaulsErr({
      fNameErr: false,
      lNameErr: false,
      eMailErr: false,
      pNumberErr: false,
      unameErr: false,
      lobDDErr: false,
      workUnitErr: false
    });
    setUDetaulsErrMSg({
      fNameErrMsg: '',
      lNameErrMsg: '',
      eMailErrMsg: '',
      pNumberErrMsg: '',
      unameErrMsg: '',
      lobDDErrMsg: '',
      workunitErrMsg: ''
    });
  };
  const getWorkunitDes = (workUnitListDD, workUnitSK) => {
    var workUnitname = workUnitSK;
    if (workUnitListDD && workUnitListDD.length > 0) {
      var workUnitDesArray = workUnitListDD.filter((fValue) => { return fValue.workUnitSK === workUnitSK; }).map((mValue) => { return mValue.name; });
      if (workUnitDesArray && workUnitDesArray[0]) {
        workUnitname = workUnitDesArray[0];
      }
    }
    return workUnitname;
  };
  useEffect(() => {
    const tempErroArray = [];
    if (validUserDetails && validUserDetails.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      tempErroArray.push(SecurityConstants.GENERIC_SYSTEM_ERROR);
      setValues({ ...values, userid: '' });
    }
    if (validUserDetails && !validUserDetails.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      if (validUserDetails.respcode === '03') {
        tempErroArray.push(validUserDetails.respdesc);
        setValues({ ...values, userid: '' });
      }
      if (validUserDetails.respcode === '02') {
        tempErroArray.push(validUserDetails.respdesc);
        setValues({ ...values, userid: '' });
      }
      if (validUserDetails.respcode === '01') {
        const userDetails = validUserDetails.enterpriseSearchresultVO.searchResults[0];
        if (userDetails) {
          dispatch(getValidUserDetails(convertToUpperCase(logInUserID)));
          if (userDetails) {
            setTempEditObj(JSON.parse(JSON.stringify(userDetails)));
            setUserStatus(userDetails.accountActiveIndicator);
            setValues({
              ...values,
              userid: userDetails.userID ? userDetails.userID : '',
              prefix: userDetails.prefixName ? userDetails.prefixName : 'Please Select One',
              firstName: userDetails.firstName ? userDetails.firstName : '',
              lastName: userDetails.lastName ? userDetails.lastName : '',
              middleName: userDetails.middleName ? userDetails.middleName : '',
              suffix: userDetails.suffixName ? userDetails.suffixName : '',
              phone: userDetails.phoneNumber ? userDetails.phoneNumber : '',
              Email: userDetails.emailAddress ? userDetails.emailAddress : '',
              workUnitSK: userDetails.workUnit && userDetails.workUnit.department && userDetails.workUnit.department.workUnitSK ? userDetails.workUnit.department.workUnitSK : 'Please Select One',
              lob: userDetails.userLOBs && userDetails.userLOBs[0] && userDetails.userLOBs[0].lobCode ? userDetails.userLOBs[0].lobCode : 'Please Select One',
              allInquiry: false,
              datamaskField: false
            });
          }
        } else {
          tempErroArray.push(SecurityConstants.GENERIC_SYSTEM_ERROR);
        }
      }
    }
    if (tempErroArray.length > 0) {
      setErrorMessages(tempErroArray);
    }
  }, [validUserDetails]);
  useEffect(() => {
    const tempErroArray = [];
    const tempSucsArray = [];
    if (addUSerResponse && addUSerResponse.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      tempErroArray.push(SecurityConstants.GENERIC_SYSTEM_ERROR);
    }
    if (addUSerResponse && !addUSerResponse.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      if (addUSerResponse.respcode === '01') {
        tempSucsArray.push(addUSerResponse.respdesc);
        validateUserId();
      } else {
        tempErroArray.push(addUSerResponse.respdesc);
      }
    }
    if (tempSucsArray.length > 0) {
      setSuccessMessages(tempSucsArray);
    };
    if (tempErroArray.length > 0) {
      setErrorMessages(tempErroArray);
    }
  }, [addUSerResponse]);
  useEffect(() => {
    const tempErroArray = [];
    const tempSucsArray = [];
    if (updateUser && updateUser.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      tempErroArray.push(SecurityConstants.GENERIC_SYSTEM_ERROR);
    }
    if (updateUser && !updateUser.systemFailue) {
      setspinnerLoader(false);
      setAllowNavigation(false);
      if (updateUser.respcode === '01') {
        setTempEditObj(null);
        tempSucsArray.push(updateUser.respdesc);
        validateUserId();
      } else {
        tempErroArray.push(updateUser.respdesc);
      }
    }
    if (tempSucsArray.length > 0) {
      setSuccessMessages(tempSucsArray);
    };
    if (tempErroArray.length > 0) {
      setErrorMessages(tempErroArray);
    }
  }, [updateUser]);
  const validateUserId = () => {
    setErrorMessages([]);
    setSuccessMessages([]);
    var regex = /^[a-zA-Z0-9-_.]+$/;
    const tempErroArray = [];
    var _userIdErr = false;
    var _userIdErrMsg = '';
    if (logInUserID === '' || logInUserID === null || logInUserID === undefined) {
      _userIdErr = true;
      _userIdErrMsg = SecurityConstants.UNAME_REQUIRED;
      tempErroArray.push(SecurityConstants.UNAME_REQUIRED);
    } else {
      if (logInUserID.toString().length < 6) {
        _userIdErr = true;
        _userIdErrMsg = SecurityConstants.USER_ID_MIN_SIX_CHARACTERS;
        tempErroArray.push(SecurityConstants.USER_ID_MIN_SIX_CHARACTERS);
      } else {
        if (regex.test(logInUserID)) {
          // Call Api
          setAllowNavigation(false);
          dispatch(validateUserIdAction(convertToUpperCase(logInUserID)));
          setspinnerLoader(true);
        } else {
          _userIdErr = true;
          _userIdErrMsg = SecurityConstants.USER_ID_VALIDATION_ERROR;
          tempErroArray.push(SecurityConstants.USER_ID_VALIDATION_ERROR);
        }
      }
    }
    setUDetaulsErr({
      ...uDetailsErr,
      unameErr: _userIdErr
    });
    setUDetaulsErrMSg({
      ...uDetailsErrMsg,
      unameErrMsg: _userIdErrMsg
    });
    setErrorMessages(tempErroArray);
  };
  const validateUserBtnEvent = () => {
    validateUserId();
  };
  const validateUserDetails = () => {
    var _ipValid = true;
    const tempErroArray = [];
    var emailRegex = /^[\w-]+@([\w-]+\.)+[\w-]+$/;
    var _unameErr, _fNameErr, _lNameErr, _eMailErr, _pNumberErr, _lobDDErr, _wUErr;
    var _unameErrMsg, _fNameErrMsg, _lNameErrMsg, _emailErrMsg, _pNumberErrMsg, _lobDDErrMsg, _wUErrMsg;
    _unameErr = _fNameErr = _lNameErr = _eMailErr = _pNumberErr = _lobDDErr = _wUErr = false;
    _unameErrMsg = _fNameErrMsg = _lNameErrMsg = _emailErrMsg = _pNumberErrMsg = _lobDDErrMsg = _wUErrMsg = '';
    if (!editFlag) {
      if (values.userid === '' || values.userid === null || values.userid === undefined) {
        _ipValid = false;
        _unameErr = true;
        _unameErrMsg = SecurityConstants.UNAME_REQUIRED;
        tempErroArray.push(_unameErrMsg);
      } else {
        if (values.userid.toString().length < 6) {
          _ipValid = false;
          _unameErr = true;
          _unameErrMsg = SecurityConstants.USER_ID_MIN_SIX_CHARACTERS;
          tempErroArray.push(SecurityConstants.USER_ID_MIN_SIX_CHARACTERS);
        } else {
          var regex = /^[a-zA-Z0-9-_.]+$/;
          if (!regex.test(values.userid)) {
            _ipValid = false;
            _unameErr = true;
            _unameErrMsg = SecurityConstants.USER_ID_VALIDATION_ERROR;
            tempErroArray.push(SecurityConstants.USER_ID_VALIDATION_ERROR);
          }
        }
      }
    }
    if (values.firstName === '' || values.firstName === null || values.firstName === undefined) {
      _ipValid = false;
      _fNameErr = true;
      _fNameErrMsg = SecurityConstants.FNAME_REQUIRED;
      tempErroArray.push(_fNameErrMsg);
    }
    if (values.lastName === '' || values.lastName === null || values.lastName === undefined) {
      _ipValid = false;
      _lNameErr = true;
      _lNameErrMsg = SecurityConstants.LNAME_REQUIRED;
      tempErroArray.push(_lNameErrMsg);
    }
    if (values.Email === '' || values.Email === null || values.Email === undefined) {
      _ipValid = false;
      _eMailErr = true;
      _emailErrMsg = SecurityConstants.EMAIL_REQUIRED;
      tempErroArray.push(_emailErrMsg);
    }
    if (!_eMailErr && !emailRegex.test(values.Email)) {
      _ipValid = false;
      _eMailErr = true;
      _emailErrMsg = SecurityConstants.EMAIL_NOT_PROPER_FORMAT;
      tempErroArray.push(_emailErrMsg);
    }
    if (values.phone === '' || values.phone === null || values.phone === undefined) {
      _ipValid = false;
      _pNumberErr = true;
      _pNumberErrMsg = SecurityConstants.PHONENUMBER_IS_REQUIRED;
      tempErroArray.push(_pNumberErrMsg);
    }
    if (values.phone !== '' && values.phone !== null && values.phone !== undefined) {
      if (values.phone.toString().length !== 10) {
        _ipValid = false;
        _pNumberErr = true;
        _pNumberErrMsg = SecurityConstants.PNUMBER_NOT_PROPER_FORMAT;
        tempErroArray.push(_pNumberErrMsg);
      }
    }
    if (values.lob === 'Please Select One') {
      _ipValid = false;
      _lobDDErr = true;
      _lobDDErrMsg = SecurityConstants.LOB_IS_REQUIRED;
      tempErroArray.push(_lobDDErrMsg);
    }
    if (values.workUnitSK === 'Please Select One') {
      _ipValid = false;
      _wUErr = true;
      _wUErrMsg = SecurityConstants.WORKUNIT_IS_REQUIRED;
      tempErroArray.push(_wUErrMsg);
    }
    setUDetaulsErr({
      ...uDetailsErr,
      fNameErr: _fNameErr,
      lNameErr: _lNameErr,
      eMailErr: _eMailErr,
      pNumberErr: _pNumberErr,
      unameErr: _unameErr,
      lobDDErr: _lobDDErr,
      workUnitErr: _wUErr
    });
    setUDetaulsErrMSg({
      ...uDetailsErrMsg,
      fNameErrMsg: _fNameErrMsg,
      lNameErrMsg: _lNameErrMsg,
      eMailErrMsg: _emailErrMsg,
      pNumberErrMsg: _pNumberErrMsg,
      unameErrMsg: _unameErrMsg,
      lobDDErrMsg: _lobDDErrMsg,
      workunitErrMsg: _wUErrMsg
    });
    if (tempErroArray.length > 0) { setErrorMessages(tempErroArray); }
    return _ipValid;
  };
  const buildAddNewUserObj = () => {
    return {
      auditUserID: logInUserID,
      auditTimeStamp: getUTCTimeStamp(),
      addedAuditUserID: editFlag ? (tempEditObj.addedAuditUserID ? tempEditObj.addedAuditUserID : logInUserID) : logInUserID,
      addedAuditTimeStamp: editFlag ? (tempEditObj.addedAuditTimeStamp ? tempEditObj.addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
      versionNo: editFlag ? (tempEditObj.versionNo ? tempEditObj.versionNo : 0) : 0,
      userWorkUnitSK: editFlag ? tempEditObj.userWorkUnitSK : null,
      userID: convertToUpperCase(values.userid),
      prefixName: values.prefix === 'Please Select One' ? null : values.prefix,
      firstName: values.firstName,
      lastName: values.lastName,
      middleName: values.middleName,
      suffixName: values.suffix,
      phoneNumber: values.phone,
      supervisorReviewReqIndicator: false,
      accountActiveIndicator: editFlag ? userStatus : false,
      supervisorIndicator: false,
      emailAddress: values.Email,
      userRoles: [
        {
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: editFlag ? (tempEditObj.userRoles && tempEditObj.userRoles[0] && tempEditObj.userRoles[0].addedAuditUserID ? tempEditObj.userRoles[0].addedAuditUserID : logInUserID) : logInUserID,
          addedAuditTimeStamp: editFlag ? (tempEditObj.userRoles && tempEditObj.userRoles[0] && tempEditObj.userRoles[0].addedAuditTimeStamp ? tempEditObj.userRoles[0].addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
          versionNo: editFlag ? (tempEditObj.userRoles && tempEditObj.userRoles[0] && tempEditObj.userRoles[0].versionNo ? tempEditObj.userRoles[0].versionNo : 0) : 0,
          userWorkUnitSK: editFlag ? tempEditObj.userWorkUnitSK : null,
          securityRoleSK: {
            securityRoleSK: 60,
            securityRoleName: 'SuperUser'
          }
        }
      ],
      workUnit: {
        auditUserID: logInUserID,
        auditTimeStamp: getUTCTimeStamp(),
        addedAuditUserID: editFlag ? (tempEditObj.workUnit && tempEditObj.workUnit.addedAuditUserID ? tempEditObj.workUnit.addedAuditUserID : logInUserID) : logInUserID,
        addedAuditTimeStamp: editFlag ? (tempEditObj.workUnit && tempEditObj.workUnit.addedAuditTimeStamp ? tempEditObj.workUnit.addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
        versionNo: editFlag ? (tempEditObj.workUnit && tempEditObj.workUnit.versionNo ? tempEditObj.workUnit.versionNo : 0) : 0,
        workUnitSK: editFlag ? tempEditObj.userWorkUnitSK : null,
        department: {
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: editFlag ? (tempEditObj.workUnit && tempEditObj.workUnit.department && tempEditObj.workUnit.department.addedAuditUserID ? tempEditObj.workUnit.department.addedAuditUserID : logInUserID) : logInUserID,
          addedAuditTimeStamp: editFlag ? (tempEditObj.workUnit && tempEditObj.workUnit.department && tempEditObj.workUnit.department.addedAuditTimeStamp ? tempEditObj.workUnit.department.addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
          versionNo: editFlag ? (tempEditObj.workUnit && tempEditObj.workUnit.department && tempEditObj.workUnit.department.versionNo ? tempEditObj.workUnit.department.versionNo : 0) : 0,
          workUnitSK: values.workUnitSK,
          name: getWorkunitDes(workUnitListDD, values.workUnitSK)
        }
      },
      userLOBs: [
        {
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: editFlag ? (tempEditObj.userLOBs && tempEditObj.userLOBs[0] && tempEditObj.userLOBs[0].addedAuditUserID ? tempEditObj.userLOBs[0].addedAuditUserID : logInUserID) : logInUserID,
          addedAuditTimeStamp: editFlag ? (tempEditObj.userLOBs && tempEditObj.userLOBs[0] && tempEditObj.userLOBs[0].addedAuditTimeStamp ? tempEditObj.userLOBs[0].addedAuditTimeStamp : getUTCTimeStamp()) : getUTCTimeStamp(),
          versionNo: editFlag ? (tempEditObj.userLOBs && tempEditObj.userLOBs[0] && tempEditObj.userLOBs[0].versionNo ? tempEditObj.userLOBs[0].versionNo : 0) : 0,
          userWorkUnitSK: editFlag ? tempEditObj.userWorkUnitSK : null,
          lobCode: values.lob
        }
      ]

    };
  };
  const masterUserSave = () => {
    setAllowNavigation(false);
    setErrorMessages([]);
    setSuccessMessages([]);
    if (validateUserDetails()) {
      setspinnerLoader(true);
      if (editFlag) {
        dispatch(updateExistingUserAction(buildAddNewUserObj()));
      } else {
        dispatch(addNewUserActioin(buildAddNewUserObj()));
      }
    }
  };
  const convertToUpperCase = (input) => {
    var regex = /^[a-zA-Z]+$/;
    var tempUId = '';
    if (input !== null && input !== '' && input !== undefined) {
      for (var i = 0; i < input.length; i++) {
        if (regex.test(input.charAt(i))) {
          tempUId = tempUId + input.charAt(i).toUpperCase();
        } else {
          tempUId = tempUId + input.charAt(i);
        }
      }
    }
    setValues({ ...values, convUId: tempUId.trim() });
    return tempUId.trim();
  };
  return (
    <div className="pos-relative">
      <Prompt
        when={allowNavigation}
        message={location => `Are you sure you want to go to ${location.pathname}`}
      />
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container">
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? <SuccessMessages successMessages={successMessages} /> : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            {!editFlag ? 'Add New User' : 'Edit User'}
          </h1>
          <div className="float-right">
            <Button variant="outlined" color="primary" className='btn btn-success ml-1 mt-2' onClick={masterUserSave}>
              <i class="fa fa-check" aria-hidden="true"></i> Save
            </Button>
            <Button variant="outlined" color="primary" className='bt-reset btn-transparent  ml-1 mt-2'
              onClick={() => resetTable()} > <i class="fa fa-undo" aria-hidden="true"></i> Reset </Button>
            <Button variant="outlined" color="primary" className='btn btn-primary ml-1 mt-2'>
              <i class="fa fa-print" aria-hidden="true"></i>
              Print
            </Button>
            <Button variant="outlined" color="primary" className='btn btn-secondary ml-1 mt-2'>
              <i class="fa fa-question-circle" aria-hidden="true"></i>
              Help
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body" style={{ padding: '20px' }}>
          <form autoComplete="off">
            <div className="row" style={{ minHeight: '260px' }}>

              <div className="form-wrapper v-align-end">
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-code-uid"
                    fullWidth
                    label="User ID"
                    required
                    value={values.userid}
                    onChange={handleChanges('userid')}
                    type="text"
                    placeholder='User ID'
                    InputProps={{ readOnly: editFlag, className: editFlag ? 'Mui-disabled' : '' }}
                    inputProps={{ minLength: 6, maxLength: 16, style: { textTransform: 'uppercase' } }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={uDetailsErr.unameErr ? uDetailsErrMsg.unameErrMsg : ''}
                    helperText={uDetailsErr.unameErr ? uDetailsErrMsg.unameErrMsg : ''}
                  />
                </div>
                <div className="mui-custom-form input-md">
                  {!editFlag ? <Button className='btn btn-primary btn-sm' onClick={validateUserBtnEvent} disabled={!userInquiryPrivileges}>
                    Validate
                  </Button> : null}
                </div>
              </div>
              <div className="form-wrapper">

                <div className="mui-custom-form with-select input-sm">
                  <TextField
                    id="standard-select-functionalArea"
                    fullWidth
                    select
                    label="Prefix"
                    value={values.prefix}
                    inputProps={{ maxLength: 2 }}
                    onChange={handleChanges('prefix')}
                    placeholder="Prefix"
                    InputLabelProps={{
                      shrink: true
                    }}
                  >
                    <MenuItem
                      selected
                      key='Please Select One'
                      value='Please Select One'
                    >
                      Please Select One
                    </MenuItem>
                    {functionalAreas.preFix.map(name => (
                      <MenuItem key={name} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-code-fName"
                    fullWidth
                    label="First Name"
                    required
                    value={values.firstName}
                    type="text"
                    onChange={handleChanges('firstName')}
                    placeholder='First Name'
                    inputProps={{ maxLength: 25 }}
                    error={uDetailsErr.fNameErr ? uDetailsErrMsg.fNameErrMsg : ''}
                    helperText={uDetailsErr.fNameErr ? uDetailsErrMsg.fNameErrMsg : ''}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.replace(
                        AppConstants.CHARACTERS_NONNUMERIC_REGEX,
                        ''
                      );
                    }}
                  />
                </div>

                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-code-lName"
                    fullWidth
                    label="Last Name"
                    required
                    value={values.lastName}
                    type="text"
                    onChange={handleChanges('lastName')}
                    placeholder='Last Name'
                    inputProps={{ maxLength: 35 }}
                    error={uDetailsErr.lNameErr ? uDetailsErrMsg.lNameErrMsg : ''}
                    helperText={uDetailsErr.lNameErr ? uDetailsErrMsg.lNameErrMsg : ''}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.replace(
                        AppConstants.CHARACTERS_NONNUMERIC_REGEX,
                        ''
                      );
                    }}
                  />
                </div>
                <div className="mui-custom-form input-sm">
                  <TextField
                    id="standard-code-mInitial"
                    fullWidth
                    label="Middle Initial"
                    value={values.middleName}
                    type="text"
                    onChange={handleChanges('middleName')}
                    placeholder='Middle Initial'
                    inputProps={{ maxLength: 1 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.replace(
                        AppConstants.CHARACTERS_NONNUMERIC_REGEX,
                        ''
                      );
                    }}
                  />
                </div>

                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-code-suffix"
                    fullWidth
                    label="Suffix"
                    value={values.suffix}
                    type="text"
                    onChange={handleChanges('suffix')}
                    placeholder='Suffix'
                    inputProps={{ maxLength: 20 }}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.replace(
                        AppConstants.CHARACTERS_NONNUMERIC_REGEX,
                        ''
                      );
                    }}
                  />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-code-phone"
                    fullWidth
                    label="Phone"
                    value={values.phone}
                    required
                    placeholder='Phone'
                    onChange={handleChanges('phone')}
                    inputProps={{ maxLength: 10 }}
                    error={uDetailsErr.pNumberErr ? uDetailsErrMsg.pNumberErrMsg : ''}
                    helperText={uDetailsErr.pNumberErr ? uDetailsErrMsg.pNumberErrMsg : ''}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onInput={e => {
                      e.target.value = e.target.value.replace(
                        AppConstants.NUMBER_ONLY_REGEX,
                        ''
                      );
                    }}
                  />
                </div>
                <div className="mui-custom-form input-xll">
                  <TextField
                    id="standard-code-email"
                    fullWidth
                    label="Email"
                    required
                    value={values.Email}
                    type="email"
                    onChange={handleChanges('Email')}
                    placeholder='Email'
                    inputProps={{ maxLength: 99 }}
                    error={uDetailsErr.eMailErr ? uDetailsErrMsg.eMailErrMsg : ''}
                    helperText={uDetailsErr.eMailErr ? uDetailsErrMsg.eMailErrMsg : ''}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="standard-code-pwd"
                    fullWidth
                    label="Password"
                    value={values.password}
                    type="password"
                    onChange={handleChanges('password')}
                    placeholder='Password'
                    InputProps={{ readOnly: !userInquiryPrivileges, className: !userInquiryPrivileges ? 'Mui-disabled' : '' }}
                    inputProps={{ maxLength: 15 }}
                    InputLabelProps={{
                      shrink: true
                    }} />
                </div>

              </div>
            </div>
            <div className="clearfix"></div>
          </form>

          {editFlag
            ? <form autoComplete="off">
              <div className="row" style={{ padding: '15px', paddingLeft: '16px' }}>
                <div className="form-wrapper row">
                  <div className="mui-custom-form override-width-20" >
                    <TextField
                      id="standard-code-lLogin"
                      fullWidth
                      label="Last Login"
                      InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                      value={new Date()}
                      type="text"
                      placeholder='Last Login'
                      inputProps={{ minLength: 6, maxLength: 16 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                  <div className="mui-custom-form override-width-20" style={{ paddingLeft: '6px' }}>
                    <TextField
                      id="standard-code-pwdExp"
                      fullWidth
                      label="Password Expiry"
                      InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                      value={new Date()}
                      type="text"
                      placeholder='Password Expiry'
                      inputProps={{ maxLength: 15 }}
                      InputLabelProps={{
                        shrink: true
                      }} />
                  </div>
                  <div className="mui-custom-form input-md" >
                    <label className="MuiFormLabel-root small-label fiscal-label" style={{ marginLeft: '15px' }} >
                      Password<span>*</span >
                    </label>
                    <div>
                      <FormControlLabel
                        style={{ marginLeft: '15px' }}
                        control={
                          <PurpleSwitch
                            disabled={!userInquiryPrivileges}
                            id="switch-Password"
                            checked={pwd}
                            onChange={handleChangePwd}
                            name="pwd"
                            color="primary"
                          />
                        }
                        label={pwd ? 'Unlocked' : 'Locked'}
                      />
                    </div>
                  </div>
                  <div className="mui-custom-form override-width-20" style={{ marginBottom: '0.5px' }}>
                    <label className="MuiFormLabel-root small-label fiscal-label" style={{ marginLeft: '22px' }} >
                      Status<span>*</span >
                    </label>
                    <div>
                      <FormControlLabel
                        style={{ marginLeft: '20px' }}
                        control={
                          <PurpleSwitch
                            disabled={!userInquiryPrivileges}
                            id="switch-Status"
                            checked={userStatus}
                            onChange={handleChangeuserStatus}
                            name="pwd"
                            color="primary"
                          />
                        }
                        label={userStatus ? 'Active' : 'Inactive'}
                      />
                    </div>
                  </div>
                </div>

              </div>

            </form>
            : null}

          <div className="form-wrapper">
            <div className="mui-custom-form with-select input-sm" style={{ marginLeft: '14px' }}>
              <TextField
                id="lob"
                fullWidth
                select
                required
                label="LOB"
                inputProps={{ maxLength: 3 }}
                disabled={!userInquiryPrivileges}
                value={values.lob}
                onChange={handleChanges('lob')}
                InputLabelProps={{
                  shrink: true
                }}
                error={uDetailsErr.lobDDErr ? uDetailsErrMsg.lobDDErrMsg : ''}
                helperText={uDetailsErr.lobDDErr ? uDetailsErrMsg.lobDDErrMsg : ''}
              >
                <MenuItem
                  selected
                  key="Please Select One"
                  value="Please Select One"
                >
                  Please Select One
                </MenuItem>
                {lobListDD
                  ? lobListDD.map(option => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.description}
                    </MenuItem>
                  ))
                  : null}
              </TextField>
            </div>
            <div className="mui-custom-form with-select input-sm" style={{ marginLeft: '32px' }}>
              <TextField
                id="WorkUnit"
                fullWidth
                select
                required
                label="WorkUnit"
                inputProps={{ maxLength: 3 }}
                disabled={!userInquiryPrivileges}
                value={values.workUnitSK}
                onChange={handleChanges('workUnitSK')}
                InputLabelProps={{
                  shrink: true
                }}
                error={uDetailsErr.workUnitErr ? uDetailsErrMsg.workunitErrMsg : ''}
                helperText={uDetailsErr.workUnitErr ? uDetailsErrMsg.workunitErrMsg : ''}
              >
                <MenuItem
                  selected
                  key="Please Select One"
                  value="Please Select One"
                >
                  Please Select One
                </MenuItem>
                {workUnitListDD
                  ? workUnitListDD.map(option => (
                    <MenuItem key={option.workUnitSK} value={option.workUnitSK}>
                      {option.name}
                    </MenuItem>
                  ))
                  : null}
              </TextField>
            </div>
          </div>
          <div className="form-wrapper">
            <div className="mui-custom-form input-md" style={{ marginLeft: '20px' }}>
              <FormControl>
                <FormLabel component="legend">Access Roles</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    value="allInquiry"
                    control={<Checkbox color="primary" checked={values.allInquiry} disabled={!userInquiryPrivileges} />}
                    label="All Inquiry"
                    labelPlacement="end"
                    onChange={allInquiryCBevt('allInquiry')}
                  />

                </FormGroup>

              </FormControl>
            </div>
            <div className="mui-custom-form input-md" >
              <FormControlLabel style={{ marginTop: '23px', marginLeft: '5px' }}
                value="dmf"
                control={<Checkbox color="primary" checked={values.datamaskField} disabled={!userInquiryPrivileges} />}
                label="Data Mask Fields"
                labelPlacement="dmf"
                onChange={allInquiryCBevt('datamaskField')}
              />
            </div>

          </div>

          <div className="form-wrapper row" style={{ marginLeft: '10px' }}>
            <div style={{ width: '303px', height: '606px' }}>
              <InputLabel shrink htmlFor="select-multiple-native-aRoles">
                Available Roles
              </InputLabel>
              <FormControl>
                <Select
                  multiple
                  fullWidth="true"
                  native
                  onChange={handleChangeAvailableRoles}
                  disabled={!userInquiryPrivileges}
                  inputProps={{
                    id: 'select-multiple-native-aRoles',
                    classes: { select: classes.root }
                  }}
                >
                  {availableRoles.filter((item) => { return !mappedRoles.includes(item); }).map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <div style={{ marginTop: '20px' }}>
                <Button variant="outlined" color="primary" className='btn btn-success ml-1 mt-2' onClick={moveToMap} disabled={!userInquiryPrivileges}>
                  <i aria-hidden="true"></i> {'> >'}
                </Button>
              </div>
              <div>
                <Button variant="outlined" color="primary" className='btn btn-success ml-1 mt-2' onClick={moveFromMap} disabled={!userInquiryPrivileges}>
                  <i aria-hidden="true"></i> {'< <'}
                </Button>
              </div>
            </div>
            <div style={{ width: '303px', height: '606px', marginLeft: '20px' }}>
              <InputLabel shrink htmlFor="select-multiple-native-mRoles">
                Currently Mapped Roles
              </InputLabel>

              <FormControl >

                <Select
                  multiple
                  native
                  onChange={handleChangeMappedRoles}
                  disabled={!userInquiryPrivileges}
                  inputProps={{
                    id: 'select-multiple-native-mRoles',
                    classes: { select: classes.root }

                  }}
                >
                  {mappedRoles.map((name) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </Select>
              </FormControl>

            </div>

          </div>
        </div>
      </div>
    </div >

  );
};
