/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DialogContentText from '@material-ui/core/DialogContentText';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Spinner from '../../../../../SharedModules/Spinner/Spinner';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import SystemListAddTable from './SystemListAddTable';
import * as SystemListSearchConstants from '../Search/SystemListSearchConstants';
import * as SystemListConstants from '../systemListConstants';
import { addSystemListAction } from '../../../Store/Actions/systemList/systemListActions';
import moment from 'moment';
import { generateUUID, validateDateMinimumValue, compareTwoDatesGreaterThanOrEqual, getUTCTimeStamp } from '../../../../../SharedModules/DateUtilities/DateUtilities';
import { Prompt } from 'react-router-dom';
import TabPanel from '../../../../../SharedModules/TabPanel/TabPanel';
import { AppConfigDropdownActions, DataElementDropdownActions } from '../../../Store/Actions/AppConfigCommon/AppConfigActions';
import dropdownCriteria from '../Search/SystemListAddUpdate.json';
import * as AppConstants from '../../../../../SharedModules/AppConstants';
import NotesComponent from '../../../../../SharedModules/Notes/Notes';
import Footer from '../../../../../SharedModules/Layout/footer';
import ErrorMessages from '../../../../../SharedModules/Errors/ErrorMessages';
import NativeDropDown from '../../../../../SharedModules/Dropdowns/NativeDropDown';

const newData = [];
const id = 0;

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: 'left'
  },
  dense: {
    marginTop: 19
  },
  menu: {
  }
}));

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function SearchListAdd(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  let errorMessagesArray = [];
  
  const toPrintRef = useRef();
  const dispatch = useDispatch();
  const onAddSystemList = (value) => dispatch(addSystemListAction(value));
  const successMessageArray = [];
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);

  const [systemListAddTableData, setSystemListAddTableData] = React.useState([]);
  const classes = useStyles();
  const [tableData, setTableData] = React.useState([]);
  const [id, setId] = React.useState(0);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [functionalAreaData, setFunctionalAreaData] = React.useState([]);
  const [dataElementNameData, setDataElementData] = React.useState([]);
  const [listTypeData, setListTypeData] = React.useState([]);
  const [lobCodeData, setLobCodeData] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [values, setValues] = React.useState({
    functionalArea: DEFAULT_DD_VALUE,
    listNumber: '',
    businessName: '',
    dataElementName: DEFAULT_DD_VALUE,
    listType: DEFAULT_DD_VALUE,
    usedInMapSet: 'No Data',
    description: ''
  });
  const [retainEdit, setRetainEdit] = React.useState({});
  const [dataElementNameValue, setDataElementNameValue] = React.useState('');
  const [successMessage, setSuccessMessages] = React.useState([]);
  const [rowSystemListDetailData, setRowSystemListDetailData] = React.useState([]);
  const [LoadSpinner, setSpinnerLoader] = React.useState(false);
  const logInUserID = useSelector(state => state.sharedState.logInUserID);
  // notes

  const [notesTableData, setNotesTableData] = React.useState([]);
  const [notesInput, setNotesInput] = React.useState({

    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    tableName: null,
    noteSetSK: null,
    noteSourceName: null,
    notesList: notesTableData,
    globalNotesList: [],
    checkAll: null,
    addNewLinkRender: null,
    filterLinkRender: null,
    printLinkRender: null,
    completeNotesList: []

  });
  const [noteSetListInput, setNoteSetListInput] = React.useState({
    auditUserID: logInUserID,
    auditTimeStamp: getUTCTimeStamp(),
    addedAuditUserID: logInUserID,
    addedAuditTimeStamp: getUTCTimeStamp(),
    versionNo: 0,
    dbRecord: false,
    sortColumn: null,
    noteTextValue: null,
    userIdName: null,
    notesCexAuditUserID: null,
    notesCexAuditTimeStamp: null,
    notesCexAddedAuditUserID: null,
    notesCexAddedAuditTimeStamp: null,
    noteSetSK: null,
    usageTypeDesc: '',
    shortNotes: null,
    checked: false,
    renderNoHistoryMsg: false,
    noteSequenceNumber: null,
    currentNote: null,
    rowValue: null,
    usageTypeList: null,
    strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
    usageTypeCode: DEFAULT_DD_VALUE,
    tableName: null,
    noteText: '',
    commonEntityName: null,
    commonEntityTypeCode: null,
    commonEntityId: null,
    entityId: null,
    filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
    filterEndDate: null,
    userId: logInUserID,
    noteCexVersionNum: 0,
    saNoteSequenceNumber: null,
    notesCexnoteTextValue: 0,
    id: generateUUID()
  });
  const [usageTypeCodeData, setUsageTypeCodeData] = React.useState([]);
  const [editNoteData, setEditNoteData] = React.useState({});

  const dropDownDispatch = dropdownvalues => dispatch(AppConfigDropdownActions(dropdownvalues));
  const dataElemDropDownDispatch = () => dispatch(DataElementDropdownActions());

  useEffect(() => {
    dropDownDispatch(dropdownCriteria);
    dataElemDropDownDispatch();
    setErrorMessages([]);
  }, []);
  const dropdown = useSelector(state => state.appConfigState.AppConfigCommonState.appConfigDropdown);
  const datElemDropdown = useSelector(state => state.appConfigState.AppConfigCommonState.dataElementNameDropdown);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      let tempData = JSON.parse(JSON.stringify(values));
      if (dropdown.listObj['Reference#1017']) {
        setFunctionalAreaData(dropdown.listObj['Reference#1017']);
        if (dropdown.listObj['Reference#1017'].length === 1) {
          tempData.functionalArea = dropdown.listObj['Reference#1017'][0].code;
        }
      }
      if (dropdown.listObj['Reference#2']) {
        setListTypeData(dropdown.listObj['Reference#2']);
        if (dropdown.listObj['Reference#2'].length === 1) {
          tempData.listType = dropdown.listObj['Reference#2'][0].code;
        }
      }
      if (dropdown.listObj['Reference#1019']) {
        setLobCodeData(dropdown.listObj['Reference#1019']);
        if (dropdown.listObj['Reference#1019'].length === 1) {
          setSystemListValues({ ...systemListValues, lob: dropdown.listObj['Reference#1019'][0].code });
        }
      }
      if (dropdown.listObj['General#1012']) {
        setUsageTypeCodeData(dropdown.listObj['General#1012']);
      }
      setValues(tempData);
    }
  }, [dropdown]);

  useEffect(() => {
    if (datElemDropdown && datElemDropdown.listDataElements) {
      setDataElementData(datElemDropdown.listDataElements);
      if (datElemDropdown.listDataElements.length === 1) {
        setValues({ ...values, dataElementName: dropdown.listObj['Reference#1019'][0].code });
      }
    }
  }, [datElemDropdown]);

  const handleChanges = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setFocusCheck(true);
    setValues({ ...values, [name]: event.target.value });
    if (name === 'dataElementName') {
      setDataElementNameValue(event.target.value);
    }
  };

  const addResponse = useSelector(state => state.appConfigState.systemListState.systemListAddResponse);

  useEffect(() => {
    if (addResponse && !addResponse.systemFailure && addResponse.flag === 'success') {
      setSuccessMessages([SystemListSearchConstants.SAVED_SUCCESSFULLY]);
      setFocusCheck(false);
      successMessageArray.push(SystemListSearchConstants.SAVED_SUCCESSFULLY);
      const payloadData = {
        listNumber: values.listNumber,
        functionalArea: values.functionalArea,
        dataElementName: values.dataElementName,
        description: values.description,
        listType: values.listType,
        listBusinessName: values.businessName,
        SuccessMessages: successMessageArray
      };
      props.history.push({
        pathname: '/SystemListAddUpdate',
        state: { payloadData, isFromAdd: true }
      });
    } else if (addResponse && !addResponse.systemFailure && addResponse.flag !== undefined && addResponse.flag !== 'success') {
      if (addResponse.flag && typeof addResponse.flag === 'object') {
        setErrorMessages(addResponse.flag);
      } else {
        setErrorMessages([addResponse.flag]);
      }
      setSpinnerLoader(false);
    } else if (addResponse && addResponse.systemFailure) {
      setSpinnerLoader(false);
      errorMessagesArray.push(SystemListSearchConstants.ERR_PROCESSING_REQ);
      if (errorMessages.length === 0) {
        setErrorMessages(errorMessagesArray);
      }
    } else {
      setSpinnerLoader(false);
    }
  }, [addResponse]);

  const [openCrossReference, setOpenCrossReference] = React.useState(false);

  const [inputValues, setInputValues] = React.useState({
    beginValue: '',
    endValue: '',
    longDescription: '',
    sortOrder: ''

  });

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(()=>{
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/SystemList'
      });
    },100)
  };
  const majorCancel = () => {
    setErrorMessages([]);
    if (focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/SystemList'
      });
    }
  };

  const handleCloseCrossReference = () => {
    setFocusCheck(true);
    setFocusCheck(true);
    setOpnSysListDtl(false);
    setErrorMessages([]);
  };

  const [add, setAdd] = React.useState(true);
  const [{
    showFunctionalAreaError, showListNumberError, showDataElementNameError, showListTypeError,
    showDescriptionError, showBusinessNameError,
    showBeginValueError,
    showEndValueError, showBeginDateError,
    showEndDateError, showEndDateValidError,
    showOverLappingError, showTableDataError,
    showRevenueCodeBeginValueError,
    showRevenueCodeEndValueError,
    showBeginValueInvalidError,
    showDiagNumericBeginValueError,
    showDiagNumericEndValueError
  }, setShowError] = React.useState(false);

  const [showSystemlistValueTable, setShowSystemlistValueTable] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [dataElement, setDataElement] = React.useState({
    id: 0,
    dataElementName: '',
    businessName: '',
    description: ''

  });

  const [{
    showDescriptionErrorText,
    showListtypeErrorText,
    showListNumberErrorText,
    showFunctionalAreaErrorText,
    showDataElementNameErrorText,
    showBusinessNameErrorText,
    showBeginValueErrorText,
    showEndValueErrorText,
    showBeginDateErrorText,
    showEndDateErrorText,
    showRevenueCodeBeginValueErrorText,
    showRevenueCodeEndValueErrorText,
    showDiagNumericBeginValueErrorText,
    showDiagNumericEndValueErrorText,
    showTableDataText
  }, setShowErrorText] = React.useState('');

  const [editData, setEditData] = React.useState({});

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {  
    let showFunctionalAreaError; let showListTypeError; let showDataElementNameError; let showListNumberError;
    let showBusinessNameError; let showDescriptionError = false;
    let showFunctionalAreaErrorText; let showListtypeErrorText; let showDataElementNameErrorText;
    let showListNumberErrorText; let showBusinessNameErrorText; let showDescriptionErrorText = '';

    if (values.functionalArea === DEFAULT_DD_VALUE) {
      showFunctionalAreaError = true;
      setShowError({ showFunctionalAreaError: showFunctionalAreaError });
      errorMessagesArray.push(SystemListSearchConstants.FUNCTIONALAREA_REQUIRED);
      showFunctionalAreaErrorText = SystemListSearchConstants.FUNCTIONALAREA_REQUIRED;
    }
    if (values.listType === DEFAULT_DD_VALUE) {
      showListTypeError = true;
      setShowError({ showListTypeError: showListTypeError });
      errorMessagesArray.push(SystemListSearchConstants.LIST_TYPE_REQUIRED);
      showListtypeErrorText = SystemListSearchConstants.LIST_TYPE_REQUIRED;
    }
    if (values.dataElementName === DEFAULT_DD_VALUE) {
      showDataElementNameError = true;
      setShowError({ showDataElementNameError: showDataElementNameError });
      errorMessagesArray.push(SystemListSearchConstants.DATAELEMENT_NAME_REQUIRED);
      showDataElementNameErrorText = SystemListSearchConstants.DATAELEMENT_NAME_REQUIRED;
    }
    if (values.listNumber === '') {
      showListNumberError = true;
      setShowError({ showListNumberError: showListNumberError });
      errorMessagesArray.push(SystemListSearchConstants.LISTNUMBER_REQUIRED);
      showListNumberErrorText = SystemListSearchConstants.LISTNUMBER_REQUIRED;
    }
    if (values.businessName === '') {
      showBusinessNameError = true;
      showBusinessNameErrorText = SystemListSearchConstants.BUSINESS_NAME_REQUIRED_ERROR;
      errorMessagesArray.push(SystemListSearchConstants.BUSINESS_NAME_REQUIRED_ERROR);
    }
    if (values.description === '') {
      showDescriptionError = true;
      showDescriptionErrorText = SystemListSearchConstants.DESCRIPTION_REQUIRED;
      errorMessagesArray.push(SystemListSearchConstants.DESCRIPTION_REQUIRED);
    }

    setShowError({
      showFunctionalAreaError: showFunctionalAreaError,
      showListTypeError: showListTypeError,
      showDataElementNameError: showDataElementNameError,
      showListNumberError: showListNumberError,
      showBusinessNameError: showBusinessNameError,
      showDescriptionError: showDescriptionError
    });
    setShowErrorText({
      showFunctionalAreaErrorText: showFunctionalAreaErrorText,
      showListtypeErrorText: showListtypeErrorText,
      showDataElementNameErrorText: showDataElementNameErrorText,
      showListNumberErrorText: showListNumberErrorText,
      showBusinessNameErrorText: showBusinessNameErrorText,
      showDescriptionErrorText: showDescriptionErrorText
    });

    if (errorMessagesArray.length === 0) {
      setErrorMessages([]);
      setSelectedBeginDate(null);
      setSelectedEndDate(new Date('9999-12-31T13:00:00.000+0000'));
      setSystemListValues({
        lob: lobCodeData && lobCodeData.length && lobCodeData.length === 1 ? lobCodeData[0].code : DEFAULT_DD_VALUE,
        beginValue: '',
        endValue: ''
      });
      setAdd(true);
      setOpnSysListDtl(true);
      if (opnSysListDtl && document.getElementById('form_pop_up')) {
        document.getElementById('form_pop_up').scrollIntoView();
      }
    } else {
      setErrorMessages(errorMessagesArray);
    }
  };

  const [systemListValues, setSystemListValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    beginDate: null,
    endDate: null,
    beginValue: '',
    endValue: '',
    sortOrder: ''
  });

  const resetSystemListValues = () => {
    setErrorMessages([]);
    if (add) {
      setSystemListValues({
        lob: DEFAULT_DD_VALUE,
        beginDate: null,
        endDate: null,
        beginValue: '',
        endValue: '',
        sortOrder: ''
      });
      setSelectedBeginDate(null);
      setSelectedEndDate(new Date('9999-12-31T13:00:00.000+0000'));
    } else {
      setSystemListValues({
        lob: retainEdit.lineofbusiness,
        beginValue: retainEdit.startingValue,
        endValue: retainEdit.endingValue,
        sortOrder: retainEdit.sortOrder ? retainEdit.sortOrder : (retainEdit.sortNum ? retainEdit.sortNum : '')

      });
      setSelectedBeginDate(retainEdit.beginDate);
      setSelectedEndDate(retainEdit.endDate);
    }

    setShowError(false);
    setShowErrorText(false);
  };

  let systemListData = [];
  const addSystemListValuesToTable = () => {
    if (add) {
      if (checkPopupFormValidations()) {
        let count = 0;
        if (tableData.length > 0) {
          tableData.map((value, index) => {
            if ((value.startingValue).toString() === systemListValues.beginValue) {
              if (moment(value.beginDate).format('MM/DD/YYYY') === moment(selectedBeginDate).format('MM/DD/YYYY')) {
                count = count + 1;
              } else if ((moment(value.beginDate).format('MM/DD/YYYY') < moment(selectedBeginDate).format('MM/DD/YYYY')) && (moment(value.endDate).format('MM/DD/YYYY') >= moment(selectedEndDate).format('MM/DD/YYYY'))) {
                count = count + 1;
              } else if ((moment(value.beginDate).format('MM/DD/YYYY') > moment(selectedBeginDate).format('MM/DD/YYYY')) && (moment(selectedEndDate).format('MM/DD/YYYY') >= moment(value.beginDate).format('MM/DD/YYYY'))) {
                count = count + 1;
              }
            }
          });
        }
        let showDateOverlappingError = false;
        if (count > 0) {
          errorMessagesArray = [];
          setErrorMessages([]);
          showDateOverlappingError = true;
          errorMessagesArray.push(SystemListSearchConstants.OVERLAPPING_ERROR);
          setErrorMessages(errorMessagesArray);
          setShowError({ showOverLappingError: showDateOverlappingError });
          window.scrollTo(0, 0);
        }
        if (!showDateOverlappingError) {
          const value = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            deletedSystemListRanges: [],
            lineofbusiness: systemListValues.lob,
            voidIndicator: '',
            disableVoidIndCode: false,
            beginDate: moment(selectedBeginDate).format('MM/DD/YYYY'),
            endDate: moment(selectedEndDate).format('MM/DD/YYYY'),
            ranges: null,
            voidDate: null,
            tempVoidDate: null,
            showVoidRecord: true,
            listDetail: null,
            endingValue: systemListValues.endValue !== '' ? systemListValues.endValue : systemListValues.beginValue,
            startingValue: systemListValues.beginValue,
            sortNum: systemListValues.sortOrder,
            filterEndingValue: null,
            filterStartingValue: null,
            filterBeginDate: null,
            filterEndDate: null,
            filterLineofbusiness: null,
            lineofbusiness1: null,
            id: generateUUID()
          };
          systemListData = tableData;
          systemListData.push(value);
          setTableData(systemListData);
        }

        handleCloseCrossReference();
      }
    } else {
      if (checkPopupFormValidations()) {
        let count = 0;

        tableData.map(value => {
          if (tableData.indexOf(value) !== tableData.indexOf(editData)) {
            if (tableData.length > 0) {
              tableData.map((value, index) => {
                if ((value.startingValue).toString() === systemListValues.beginValue) {
                  if (moment(value.beginDate).format('MM/DD/YYYY') === moment(selectedBeginDate).format('MM/DD/YYYY')) {
                    count = count + 1;
                  } else if ((moment(value.beginDate).format('MM/DD/YYYY') < moment(selectedBeginDate).format('MM/DD/YYYY')) && (moment(value.endDate).format('MM/DD/YYYY') >= moment(selectedEndDate).format('MM/DD/YYYY'))) {
                    count = count + 1;
                  } else if ((moment(value.beginDate).format('MM/DD/YYYY') > moment(selectedBeginDate).format('MM/DD/YYYY')) && (moment(selectedEndDate).format('MM/DD/YYYY') >= moment(value.beginDate).format('MM/DD/YYYY'))) {
                    count = count + 1;
                  }
                }
              });
            }
          }
        });
        let showDateOverlappingError = false;
        if (count > 0) {
          errorMessagesArray = [];
          setErrorMessages([]);
          showDateOverlappingError = true;
          errorMessagesArray.push(SystemListSearchConstants.OVERLAPPING_ERROR);
          setErrorMessages(errorMessagesArray);
          setShowError({ showOverLappingError: showDateOverlappingError });
          window.scrollTo(0, 0);
        } else {
          tableData[tableData.indexOf(editData)].auditUserID = logInUserID;
          tableData[tableData.indexOf(editData)].auditTimeStamp = getUTCTimeStamp();
          tableData[tableData.indexOf(editData)].addedAuditUserID = logInUserID;
          tableData[tableData.indexOf(editData)].addedAuditTimeStamp = getUTCTimeStamp();
          tableData[tableData.indexOf(editData)].startingValue = systemListValues.beginValue;
          tableData[tableData.indexOf(editData)].endingValue = values.listType === 'UIS' ? systemListValues.beginValue : systemListValues.endValue;
          tableData[tableData.indexOf(editData)].lineofbusiness = systemListValues.lob;
          tableData[tableData.indexOf(editData)].beginDate = moment(selectedBeginDate).format('MM/DD/YYYY');
          tableData[tableData.indexOf(editData)].endDate = moment(selectedEndDate).format('MM/DD/YYYY');
          tableData[tableData.indexOf(editData)].sortNum = systemListValues.sortOrder;
        }

        handleCloseCrossReference();
        setAdd(true);
      }
    }
  };

  const handleSystemListChanges = name => event => {
    setFocusCheck(true);
    setSystemListValues({ ...systemListValues, [name]: event.target.value });
  };
  const [selectedBeginDate, setSelectedBeginDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date('9999-12-31T13:00:00.000+0000'));

  const checkFormValidations = () => {
    const errorMessagesArray = [];
    var showDescriptionError = false; var showListNumberError = false; var showListTypeError = false; var showBusinessNameError = false;
    var showFunctionalAreaError = false; var showDataElementNameError = false; var showLobCodeError; var showBeginDateError;
    var showEndDateError; var showEndDateValidError; var showBeginValueError; var showEndValueError;
    var showTableDataError = false;
    var tableErrorMsg = true;
    var showBusinessNameErrorText; var showDescriptionErrorText; var showListNumberErrorText; var showListtypeErrorText;
    var showFunctionalAreaErrorText; var showDataElementNameErrorText;
    var showBeginValueErrorText; var showEndValueErrorText; var showBeginDateErrorText;
    var showEndDateErrorText; var showTableDataText = '';
    setErrorMessages([]);

    if (values.businessName === '') {
      showBusinessNameError = true;
      showBusinessNameErrorText = SystemListSearchConstants.BUSINESS_NAME_REQUIRED_ERROR;
      errorMessagesArray.push(SystemListSearchConstants.BUSINESS_NAME_REQUIRED_ERROR);
      setErrorMessages(errorMessagesArray);
      tableErrorMsg = false;
    }

    if (values.description === '') {
      showDescriptionError = true;
      showDescriptionErrorText = SystemListSearchConstants.DESCRIPTION_REQUIRED;
      errorMessagesArray.push(SystemListSearchConstants.DESCRIPTION_REQUIRED);
      setErrorMessages(errorMessagesArray);
      tableErrorMsg = false;
    }

    if (values.listType === DEFAULT_DD_VALUE) {
      showListTypeError = true;
      setShowError({ showListTypeError: showListTypeError });
      errorMessagesArray.push(SystemListSearchConstants.LIST_TYPE_REQUIRED);
      showListtypeErrorText = SystemListSearchConstants.LIST_TYPE_REQUIRED;
      setErrorMessages(errorMessagesArray);
      tableErrorMsg = false;
    }
    if (values.dataElementName === DEFAULT_DD_VALUE) {
      showDataElementNameError = true;
      setShowError({ showDataElementNameError: showDataElementNameError });
      errorMessagesArray.push(SystemListSearchConstants.DATAELEMENT_NAME_REQUIRED);
      showDataElementNameErrorText = SystemListSearchConstants.DATAELEMENT_NAME_REQUIRED;
      setErrorMessages(errorMessagesArray);
      tableErrorMsg = false;
    }
    if (values.functionalArea === DEFAULT_DD_VALUE) {
      showFunctionalAreaError = true;
      setShowError({ showFunctionalAreaError: showFunctionalAreaError });
      errorMessagesArray.push(SystemListSearchConstants.FUNCTIONALAREA_REQUIRED);
      showFunctionalAreaErrorText = SystemListSearchConstants.FUNCTIONALAREA_REQUIRED;
      setErrorMessages(errorMessagesArray);
      tableErrorMsg = false;
    }
    if (values.listNumber === '') {
      showListNumberError = true;
      setShowError({ showListNumberError: showListNumberError });
      errorMessagesArray.push(SystemListSearchConstants.LISTNUMBER_REQUIRED);
      showListNumberErrorText = SystemListSearchConstants.LISTNUMBER_REQUIRED;
      setErrorMessages(errorMessagesArray);
      tableErrorMsg = false;
    }
    if (tableData.length === 0 && tableErrorMsg) {
      showTableDataError = true;
      setShowError({ showListNumberError: showListNumberError });
      errorMessagesArray.push(SystemListSearchConstants.DETAIL_ROW_REQUIRED);
      setErrorMessages(errorMessagesArray);
      setShowErrorText(errorMessagesArray);
    }

    setShowError({
      showDescriptionError: showDescriptionError,
      showListTypeError: showListTypeError,
      showListNumberError: showListNumberError,
      showBusinessNameError: showBusinessNameError,
      showFunctionalAreaError: showFunctionalAreaError,
      showDataElementNameError: showDataElementNameError,
      showEndDateValidError: showEndDateValidError,
      showTableDataError: showTableDataError
    });

    setShowErrorText({
      showDescriptionErrorText: showDescriptionErrorText,
      showListNumberErrorText: showListNumberErrorText,
      showListtypeErrorText: showListtypeErrorText,
      showDataElementNameErrorText: showDataElementNameErrorText,
      showFunctionalAreaErrorText: showFunctionalAreaErrorText,
      showBusinessNameErrorText: showBusinessNameErrorText,
      showTableDataText: showTableDataText
    });

    if (showTableDataError || showBusinessNameError || showDescriptionError || showListNumberError || showDataElementNameError || showListTypeError || showDescriptionError || showBusinessNameError) {
      return false;
    } else {
      return true;
    }
  };

  const checkPopupFormValidations = () => {
    var showLobCodeError; var showBeginDateError; var showEndDateError; var showEndDateValidError; var showBeginValueError;
    var showDiagNumericBeginValueError; var showDiagNumericEndValueError; var showEndValueError;
    var showRevenueCodeBeginValueError; var showRevenueCodeEndValueError = false;

    var showLobCodeErrorText; var showBeginValueErrorText; var showEndValueErrorText; var showBeginDateErrorText;
    var showDiagNumericBeginValueErrorText; var showEndDateErrorText;
    var showDiagNumericEndValueErrorText = '';
    let tempArrayMess = [];

    if (systemListValues.beginValue === '') {
      showBeginValueError = true;
      setShowError({ showBeginValueError: showBeginValueError });
      showBeginValueErrorText = SystemListSearchConstants.BEGINVALUE_REQUIRED;
      tempArrayMess.push(showBeginValueErrorText);
    } else {
      if (dataElementNameValue === 'R_REV_CD') {
        const numbers = /^[0-9]+$/;
        if (systemListValues.beginValue.length === 4) {
          showRevenueCodeBeginValueError = false;
          if (systemListValues.beginValue.match(numbers)) {
            showRevenueCodeBeginValueError = false;
            setShowError({ showRevenueCodeBeginValueError: showRevenueCodeBeginValueError });
          } else {
            showRevenueCodeBeginValueError = true;
            setShowError({ showRevenueCodeBeginValueError: showRevenueCodeBeginValueError });
            showBeginValueErrorText = SystemListSearchConstants.REVENUE_CODE_TEXT_BEGINVALUE;
            tempArrayMess.push(showBeginValueErrorText);
          }
        } else {
          showRevenueCodeBeginValueError = true;
          setShowError({ showRevenueCodeBeginValueError: showRevenueCodeBeginValueError });
          showBeginValueErrorText = SystemListSearchConstants.REVENUE_CODE_TEXT_BEGINVALUE;
          tempArrayMess.push(showBeginValueErrorText);
        }
      }
      if (dataElementNameValue === 'R_DIAG_CD') {
        const test = SystemListConstants.ADDVALUE_REGEX;
        if ((!isNaN(systemListValues.beginValue))) {
          if (systemListValues.beginValue.match(test)) {
            showDiagNumericBeginValueError = false;
            setShowError({ showDiagNumericBeginValueError: showDiagNumericBeginValueError });
            showDiagNumericBeginValueErrorText = SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
            tempArrayMess.push(showDiagNumericBeginValueErrorText);
          } else {
            showDiagNumericBeginValueError = true;
            setShowError({ showDiagNumericBeginValueError: showDiagNumericBeginValueError });
            showDiagNumericBeginValueErrorText = SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
            tempArrayMess.push(showDiagNumericBeginValueErrorText);
          }
        }
      }

      if (dataElementNameValue === 'R_SURG_PROC_CD') {
        const test = SystemListConstants.SURGICAL_REGEX;
        const ICD10 = SystemListConstants.SURGICAL_REGEX_ICD;
        if (systemListValues.beginValue.match(ICD10)) {
          showDiagNumericBeginValueError = false;
          setShowError({ showDiagNumericBeginValueError: showDiagNumericBeginValueError });
          showDiagNumericBeginValueErrorText = SystemListSearchConstants.INVALID_SURG_PROC_CODE;
          tempArrayMess.push(showDiagNumericBeginValueErrorText);
        } else {
          showDiagNumericBeginValueError = true;
          setShowError({ showDiagNumericBeginValueError: showDiagNumericBeginValueError });
          showDiagNumericBeginValueErrorText = SystemListSearchConstants.INVALID_SURG_PROC_CODE;
          tempArrayMess.push(showDiagNumericBeginValueErrorText);
        }
      }
      showBeginValueError = false;
    }

    if (systemListValues.endValue === '') {
      if (values.listType === 'UIS') {
        showEndValueError = false;
        setShowError({ showEndValueError: showEndValueError });
      } else {
        showEndValueError = true;
        setShowError({ showEndValueError: showEndValueError });
        showEndValueErrorText = SystemListSearchConstants.ENDVALUE_REQUIRED;
        tempArrayMess.push(showEndValueErrorText);
      }
    } else {
      if (dataElementNameValue === 'R_REV_CD') {
        const numbers = /^[0-9]+$/;
        // BEGIN VALUE CHECK
        if (systemListValues.beginValue.length === 4) {
          showRevenueCodeEndValueError = false;
          if (systemListValues.endValue.match(numbers)) {
            showRevenueCodeBeginValueError = false;
            setShowError({ showRevenueCodeBeginValueError: showRevenueCodeBeginValueError });
          } else {
            showRevenueCodeEndValueError = true;
            setShowError({ showRevenueCodeBeginValueError: showRevenueCodeBeginValueError });
            showBeginValueErrorText = SystemListSearchConstants.REVENUE_CODE_TEXT_BEGINVALUE;
            tempArrayMess.push(showBeginValueErrorText);
          }
        } else {
          showRevenueCodeEndValueError = true;
          setShowError({ showRevenueCodeBeginValueError: showRevenueCodeBeginValueError });
          showBeginValueErrorText = SystemListSearchConstants.REVENUE_CODE_TEXT_BEGINVALUE;
          tempArrayMess.push(showBeginValueErrorText);
        }

        if (systemListValues.endValue.length === 4) {
          showRevenueCodeEndValueError = false;
          if (systemListValues.endValue.match(numbers)) {
            showRevenueCodeBeginValueError = false;
            setShowError({ showRevenueCodeEndValueError: showRevenueCodeEndValueError });
          } else {
            showRevenueCodeEndValueError = true;
            setShowError({ showRevenueCodeEndValueError: showRevenueCodeEndValueError });
            showEndValueErrorText = SystemListSearchConstants.REVENUE_CODE_TEXT_ENDVALUE;
            tempArrayMess.push(showEndValueErrorText);
          }
        } else {
          showRevenueCodeEndValueError = true;
          setShowError({ showRevenueCodeEndValueError: showRevenueCodeEndValueError });
          showEndValueErrorText = SystemListSearchConstants.REVENUE_CODE_TEXT_ENDVALUE;
          tempArrayMess.push(showEndValueErrorText);
        }
      }
      if (dataElementNameValue === 'R_DIAG_CD') {
        const test = SystemListConstants.ADDVALUE_REGEX;
        if (!(isNaN(systemListValues.endValue))) {
          if (systemListValues.endValue.match(test)) {
            showDiagNumericEndValueError = false;
            setShowError({ showDiagNumericEndValueError: showDiagNumericEndValueError });
            showDiagNumericEndValueErrorText = SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
            tempArrayMess.push(showDiagNumericEndValueErrorText);
          } else {
            showDiagNumericEndValueError = true;
            setShowError({ showDiagNumericEndValueError: showDiagNumericEndValueError });
            showDiagNumericEndValueErrorText = SystemListSearchConstants.DIAG_BEGINVALUE_ERROR;
            tempArrayMess.push(showDiagNumericEndValueErrorText);

          }
        }
      }

      if (dataElementNameValue === 'R_SURG_PROC_CD') {
        const test = SystemListConstants.SURGICAL_REGEX;
        const ICD10 = SystemListConstants.SURGICAL_REGEX_ICD;
        if (systemListValues.endValue.match(ICD10)) {
          showDiagNumericEndValueError = false;
          setShowError({ showDiagNumericEndValueError: showDiagNumericEndValueError });
          showDiagNumericEndValueErrorText = SystemListSearchConstants.INVALID_SURG_PROC_CODE;
          tempArrayMess.push(showDiagNumericEndValueErrorText);

        } else {
          showDiagNumericEndValueError = true;
          setShowError({ showDiagNumericEndValueError: showDiagNumericEndValueError });
          showDiagNumericEndValueErrorText = SystemListSearchConstants.INVALID_SURG_PROC_CODE;
          tempArrayMess.push(showDiagNumericEndValueErrorText);
        }
      }
      showEndValueError = false;
    }

    // Begin Value validation
    if (systemListValues.beginValue !== '' && systemListValues.endValue !== '' && !isNaN(systemListValues.beginValue) &&
      !isNaN(systemListValues.endValue) && +systemListValues.beginValue > +systemListValues.endValue && !showRevenueCodeBeginValueError &&
      !showDiagNumericBeginValueError && !showBeginValueError && values.listType !== 'UIS') {
      showBeginValueError = true;
      setShowError({ showBeginValueError: showBeginValueError });
      showBeginValueErrorText = SystemListSearchConstants.BEGINVALUE_INVALID_TEXT;
      tempArrayMess.push(showBeginValueErrorText);

    }

    // date validations
    if (selectedBeginDate) {
      if (selectedBeginDate.toString() !== 'Invalid Date') {
        if (validateDateMinimumValue(selectedBeginDate)) {
          showBeginDateError = true;
          setShowError({ showBeginDateError: showBeginDateError });
          showBeginDateErrorText = AppConstants.DATE_ERROR_1964;
          tempArrayMess.push(showBeginDateErrorText);
        }
      } else {
        showBeginDateError = true;
        setShowError({ showBeginDateError: showBeginDateError });
        showBeginDateErrorText = SystemListSearchConstants.BEGIN_DATE_INVALID;
        tempArrayMess.push(showBeginDateErrorText);
      }
    } else {
      showBeginDateError = true;
      setShowError({ showBeginDateError: showBeginDateError });
      showBeginDateErrorText = SystemListSearchConstants.BEGIN_DATE_REQUIRED;
      tempArrayMess.push(showBeginDateErrorText);

    }
    if (selectedEndDate) {
      if (selectedEndDate.toString() !== 'Invalid Date') {
        if (validateDateMinimumValue(selectedEndDate)) {
          showEndDateError = true;
          setShowError({ showEndDateError: showEndDateError });
          showEndDateErrorText = AppConstants.DATE_ERROR_1964;
          tempArrayMess.push(showEndDateErrorText);

        }
      } else {
        showEndDateError = true;
        setShowError({ showEndDateError: showEndDateError });
        showEndDateErrorText = SystemListSearchConstants.END_DATE_INVALID;
        tempArrayMess.push(showEndDateErrorText);

      }
    } else {
      showEndDateError = true;
      setShowError({ showEndDateError: showEndDateError });
      showEndDateErrorText = SystemListSearchConstants.END_DATE_REQUIRED;
      tempArrayMess.push(showEndDateErrorText);

    }
    if (
      selectedBeginDate &&
      selectedEndDate &&
      !showBeginDateError &&
      !showEndDateError &&
      compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedBeginDate)
    ) {
      showBeginDateError = true;
      setShowError({ showBeginDateError: showBeginDateError });
      showBeginDateErrorText = SystemListSearchConstants.BEGIN_DATE_LESS;
      tempArrayMess.push(showBeginDateErrorText);

    }
    setErrorMessages(tempArrayMess);
    setShowError({
      showEndDateValidError: showEndDateValidError,
      showLobCodeError: showLobCodeError,
      showBeginValueError: showBeginValueError,
      showEndValueError: showEndValueError,
      showBeginDateError: showBeginDateError,
      showEndDateError: showEndDateError,
      showRevenueCodeBeginValueError: showRevenueCodeBeginValueError,
      showRevenueCodeEndValueError: showRevenueCodeEndValueError,
      showDiagNumericBeginValueError: showDiagNumericBeginValueError,
      showDiagNumericEndValueError: showDiagNumericEndValueError

    });

    setShowErrorText({
      showLobCodeErrorText: showLobCodeErrorText,
      showBeginValueErrorText: showBeginValueErrorText,
      showEndValueErrorText: showEndValueErrorText,
      showBeginDateErrorText: showBeginDateErrorText,
      showEndDateErrorText: showEndDateErrorText,
      showRevenueCodeBeginValueErrorText: showRevenueCodeBeginValueErrorText,
      showRevenueCodeEndValueErrorText: showRevenueCodeEndValueErrorText,
      showDiagNumericBeginValueErrorText: showDiagNumericBeginValueErrorText,
      showDiagNumericEndValueErrorText: showDiagNumericEndValueErrorText
    });

    if (showLobCodeError && showBeginValueError && showEndValueError && showBeginDateError && showEndDateError) {
      return false;
    } else {
      if (showLobCodeError) {
        return false;
      }
      if (showBeginValueError) {
        return false;
      }
      if (showRevenueCodeBeginValueError && showRevenueCodeEndValueError) {
        return false;
      }
      if (showRevenueCodeBeginValueError) {
        return false;
      }
      if (showRevenueCodeEndValueError) {
        return false;
      }
      if (showDiagNumericBeginValueError) {
        return false;
      }
      if (showDiagNumericEndValueError) {
        return false;
      }
      if (showDiagNumericEndValueError && showDiagNumericBeginValueError) {
        return false;
      }
      if (showEndValueError) {
        return false;
      }
      if (showBeginDateError) {
        return false;
      }
      if (showEndDateError) {
        return false;
      } else {
        return true;
      }
    }
  };

  const saveAddSystemList = () => {
    if (checkFormValidations()) {
      setErrorMessages([]);
      setSuccessMessages([]);
      if (tableData.length > 0) {
        const updatedData = tableData.map(value => ({ ...value, lineofbusiness: AppConstants.DEFAULT_LOB_VALUE }));
        const value = {
          auditUserID: logInUserID,
          auditTimeStamp: getUTCTimeStamp(),
          addedAuditUserID: logInUserID,
          addedAuditTimeStamp: getUTCTimeStamp(),
          dbRecord: false,
          sortColumn: null,
          auditKeyList: [],
          auditKeyListFiltered: false,
          listNumber: values.listNumber,
          deletedSystemListDetails: [],
          description: values.description,
          functionalArea: values.functionalArea,
          listBusinessName: values.businessName,
          listType: values.listType,
          systemListDetails: updatedData,
          dataElementName: values.dataElementName,
          groupAssignmentList: null,
          mapSetIds: [],
          noteSet: null,
          length: 0,
          format: null,
          columnName: null,
          sourceTableName: null,
          nameChange: null,
          columnScaleNumber: null,
          noteSetVO: notesInput
        };
        setAllowNavigation(false);
        onAddSystemList(value);
        setSpinnerLoader(true);
      }
    }
  };

  const onClickeditSystemList = data => event => {
    setEditData(data);
    setRetainEdit(data);
    setOpnSysListDtl(true);
    setSystemListValues({
      lob: data.lineofbusiness,
      beginValue: data.startingValue,
      endValue: data.endingValue,
      sortOrder: data.sortNum
    });
    setSelectedEndDate(data.endDate);
    setSelectedBeginDate(data.beginDate);
    setShowError(false);
    setAdd(false);
  };

  const rowDeleteSystemListDetails = data => {
    setRowSystemListDetailData({ ...rowSystemListDetailData, rowSystemListDetailData: data });
  };

  function systemListDetailRowDeleteClick() {
    let temNewDialogData = [...tableData];
    if (rowSystemListDetailData.rowSystemListDetailData) {
      for (let i = 0; i < rowSystemListDetailData.rowSystemListDetailData.length; i++) {
        if (rowSystemListDetailData.rowSystemListDetailData[i] !== undefined) {
          temNewDialogData = temNewDialogData.filter(payment => payment.id !== rowSystemListDetailData.rowSystemListDetailData[i]);
        }
      }
    };
    setTableData(temNewDialogData);
    setRowSystemListDetailData([]);
  }

  // notes
  let notesDataArray = [];
  const addNotes = (data) => {
    setAllowNavigation(true);

    const noteText = data;
    notesDataArray = notesTableData;
    notesDataArray.push(noteText);
    setNotesTableData(notesDataArray);
    setNotesInput({ ...notesInput, notesList: notesDataArray });
  };

  const [disableNotes, setDisableNotes] = React.useState(true);
  const validateParentForm = () => {
    if (
      (!(values.listType) || values.listType === DEFAULT_DD_VALUE) ||
      (!(values.functionalArea) || values.functionalArea === DEFAULT_DD_VALUE) ||
      (!(values.listNumber) || values.listNumber === '') ||
      (!(values.businessName) || values.businessName === '') ||
      (!(values.dataElementName) || values.dataElementName === DEFAULT_DD_VALUE) ||
      (!(values.description) || values.description === '')
    ) {
      return true;
    } else {
      return false;
    }
  };
  useEffect(() => {
    setDisableNotes(validateParentForm());
  }, [values]);

  // clear out success/error msgs on click of add notes
  const clearSuccessErrorMsgs = () => {
    setSuccessMessages([]);
    setErrorMessages([]);
    setShowError(false);
  };

  const [opnSysListDtl, setOpnSysListDtl] = React.useState(false);

  useEffect(() => {
    if (opnSysListDtl && document.getElementById('form_pop_up'))
      document.getElementById('form_pop_up').scrollIntoView();
  }, [opnSysListDtl])

  return (
    <div className="pos-relative w-100 h-100">
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      {LoadSpinner ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <div className="tab-heading float-left">
          {
            add ? 'Add System List Details' : 'Edit System List Details'
          }
          </div>
          <div className="float-right  mt-2">
            <Button variant="outlined" color="primary" className='btn btn-success' onClick={saveAddSystemList}
            >
              <i className="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>

            {window.location.pathname === '/SystemListAdd' && (
              <Button title="Cancel" onClick={majorCancel}>Cancel</Button>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <div className="MuiCollapse-container MuiCollapse-entered">
            <div className="MuiCollapse-wrapper">
            <div className="w-100">
              <form autoComplete="off">
              <div className="row">
                <div className="col-9">
                <div className="form-wrapper">
                    <NativeDropDown
                      id="standard-select-functionalArea"
                      className='with-select override-width-28'
                      isRequired
                      label="Functional Area"
                      value={values.functionalArea}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      onChange={handleChanges('functionalArea')}
                      placeholder=""

                      InputLabelProps={{
                        shrink: true
                      }}
                      errTxt={showFunctionalAreaError ? showFunctionalAreaErrorText : null}
                      dropdownList={functionalAreaData ? functionalAreaData.map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      )) : null}
                    />
                  <div className="mui-custom-form with-select with-select override-width-28" // style={{ marginLeft: '54px' }}
                  >
                    <TextField
                      id="standard-listNumber"
                      fullWidth
                      required
                      label="List Number"
                      type="number"
                      value={values.listNumber}
                      onChange={handleChanges('listNumber')}
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                      onInput={(e) => {
                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10);
                      }}
                      onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                      inputProps={{ maxLength: 10 }}
                      helperText={showListNumberError ? showListNumberErrorText : null}
                      error={showListNumberError ? showListNumberErrorText : null}
                    />
                  </div>

                  <NativeDropDown
                      id="standard-select-dataElementName"
                      isRequired
                      className='override-width-28'
                      label="Data Element Name"
                      value={values.dataElementName}
                      onChange={handleChanges('dataElementName')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      placeholder=""

                      InputLabelProps={{
                        shrink: true
                      }}
                      errTxt={showDataElementNameError ? showDataElementNameErrorText : null}
                      dropdownList={dataElementNameData ? dataElementNameData.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      )) : null}
                    />
                </div>
                <div className="form-wrapper">
                  <div className="mui-custom-form-description with-select override-width-62">
                    <TextField
                      id="standard-Description"
                      fullWidth
                      required
                      label="Description"
                      value={values.description}
                      onChange={handleChanges('description')}
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ maxLength: 320 }}
                      helperText={showDescriptionError ? showDescriptionErrorText : null}
                      error={showDescriptionError ? showDescriptionErrorText : null}
                    />
                  </div>
                    <NativeDropDown
                      id="standard-select-ListType"
                      className='override-width-28'
                      isRequired
                      label="List Type"
                      value={values.listType}
                      onChange={handleChanges('listType')}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu
                        }
                      }}
                      placeholder=""

                      InputLabelProps={{
                        shrink: true
                      }}
                      errTxt={showListTypeError ? showListtypeErrorText : null}
                      dropdownList={listTypeData ? listTypeData.map((option, index) => (
                        <option key={index} value={option.code}>
                          {option.description}
                        </option>
                      )) : null}
                    />
                  </div>
                  <div className= "form-wrapper">
                  <div className="mui-custom-form-description with-select override-width-62" // style={{ marginLeft: '30px' }}
                  >
                    <TextField
                      id="standard-businessName"
                      fullWidth
                      required
                      label="Business Name"
                      value={values.businessName}
                      onChange={handleChanges('businessName')}
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                      inputProps={{ maxLength: 50 }}
                      helperText={showBusinessNameError ? showBusinessNameErrorText : null}
                      error={showBusinessNameError ? showBusinessNameErrorText : null}

                    />
                  </div>
                  </div>
                </div>
                <div className = "col-3">
                  <div className = "form-wrapper">
                  <div  className="mui-custom-form with-select with-select w-100 mapsettablelist"
                        style={{ marginRight: "20px" }} // style={{ marginLeft: '216px' }}
                  >
                    <TextField
                      disabled
                      id="standard-usedin-mapset"
                      fullWidth
                      label="Used in Mapset"
                      value='Map ID'
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </div>
                  </div>
                </div>
                </div>
              </form>
              </div>
            </div>
          </div>
          <div>
          </div>
          <div className='tab-panelbody m-3'>
            <div className="tab-holder mb-2">
              <AppBar position="static">
                <Tabs value={value} onChange={handleChangeTabs} aria-label="simple tabs example">
                  <Tab label="System List Detail" />
                  <Tab label="Notes" />
                </Tabs>
              </AppBar>
              <TabPanel
                value={value}
                index={0}
              >

                <div className="tab-holder p-0">
                  {value === 0 && (
                    <>
                      <div className="float-right my-3">
                        <Button variant="outlined" color="primary" className='btn-text-main-delete btn-transparent  ml-1' onClick={systemListDetailRowDeleteClick}
                          title={'Delete'}>
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          Delete
                        </Button>
                        <Button variant="outlined" color="primary" className='btn-text-main-add btn-success ml-1'
                          onClick={() => handleClickOpen()}
                          title={'Add System List Detail'}
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>
                          Add</Button>
                      </div>
                    </>
                  )}
                  <div className="clearfix"></div>

                  {value === 0 && (
                    <>
                      <div className="tab-holder p-0">
                        <SystemListAddTable
                          tableData={tableData}
                          onEditSystemlistDetail={onClickeditSystemList}
                          rowDeleteSystemListDetails={rowDeleteSystemListDetails}
                        />
                         {
                      opnSysListDtl ?
                    
                    <div className="tabs-container" id='form_pop_up'>
                <div className="tab-header  mini-tab-header">
                  <div className="tab-heading float-left">
                  {
                    add ? 'Add System List Details' : 'Edit System List Details'
                  }
                  </div>
                  <div className="float-right mt-1">
                    {
                      add ?
                      <Button
                      variant="outlined" color="primary" className='btn btn-success ml-1'
                      onClick={addSystemListValuesToTable}
                    >
                       <i class="fa fa-check" aria-hidden="true"></i>
                      Add
                    </Button>
                    :
                    <Button
                    variant="outlined" color="primary" className='btn btn-success ml-1'
                    onClick={addSystemListValuesToTable}
                  >
                     <i class="fa fa-check" aria-hidden="true"></i>
                    update
                  </Button>
                    }
                   
                    <Button
                      variant="outlined" color="primary" className='bt-reset btn-transparent  ml-1'
                      onClick={resetSystemListValues}
                    >
                      <i className="fa fa-undo" aria-hidden="true"></i>
                      Reset
                      </Button>
                    <Button
                      variant="outlined" color="primary" className='btn btn-primary btn btn-outlined ml-1'
                      onClick={handleCloseCrossReference}
                    >
                      Cancel
                      </Button>
                  </div>
                </div>
                              <div className="form-wrapper">
                      {
                        values.listType === 'UIS'
                          ? <div className="mui-custom-form input-md">
                            <TextField
                              id="constant-text"
                              fullWidth
                              label="Sort Order"
                              value={systemListValues.sortOrder}
                              onChange={handleSystemListChanges('sortOrder')}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxLength: 10 }}
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  AppConstants.NUMBER_ONLY_REGEX,
                                  ''
                                );
                              }}
                            />
                          </div> : null
                      }

                      <div className="mui-custom-form input-md" >
                        <TextField
                          id="beginValue"
                          fullWidth
                          label="Begin Value"
                          required
                          inputProps={{ maxLength: 30 }}
                          value={systemListValues.beginValue}
                          onChange={handleSystemListChanges('beginValue')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          helperText={(showBeginValueError || showRevenueCodeBeginValueError) ? showBeginValueErrorText : (showDiagNumericBeginValueError ? showDiagNumericBeginValueErrorText : null)}
                          error={(showBeginValueError || showRevenueCodeBeginValueError) ? showBeginValueErrorText : (showDiagNumericBeginValueError ? showDiagNumericBeginValueErrorText : null)}
                        />
                      </div>
                      {
                        values.listType === 'PRC' || values.listType === 'XWK'
                          ? <div className="mui-custom-form input-md">
                            <TextField
                              key="sl_u_begv"
                              id="endValue"
                              fullWidth
                              required
                              label="End Value"
                              value={systemListValues.endValue}
                              inputProps={{ maxLength: 30 }}
                              onChange={handleSystemListChanges('endValue')}
                              InputLabelProps={{
                                shrink: true
                              }}
                              helperText={(showEndValueError || showRevenueCodeEndValueError) ? showEndValueErrorText : (showDiagNumericEndValueError ? showDiagNumericEndValueErrorText : null)}
                              error={(showEndValueError || showRevenueCodeEndValueError) ? showEndValueErrorText : (showDiagNumericEndValueError ? showDiagNumericEndValueErrorText : null)}
                            />
                          </div> : null
                      }
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="mui-custom-form with-date input-md" >
                          <KeyboardDatePicker
                            maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                            id="date-picker-dialog-begindate"
                            fullWidth
                            label="Begin Date"
                            required
                            format="MM/dd/yyyy"
                            InputLabelProps={{
                              shrink: true
                            }}
                            placeholder="mm/dd/yyyy"
                            value={selectedBeginDate}
                            onChange={setSelectedBeginDate}

                            helperText={showBeginDateError ? showBeginDateErrorText : null}
                            error={showBeginDateError ? showBeginDateErrorText : null}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />
                        </div>
                                </MuiPickersUtilsProvider>
                        {values.listType === 'PRC' || values.listType === 'XWK'
                          ? 
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>

                          <div className="mui-custom-form with-date input-md" >
                            <KeyboardDatePicker
                              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                              id="date-picker-dialog-enddate"
                              fullWidth
                              label="End Date"
                              required
                              helperText={null}
                              format="MM/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              maxDate={new Date('9999-12-31T13:00:00.000+0000')}
                              error={false}
                              placeholder="mm/dd/yyyy"
                              value={selectedEndDate}
                              onChange={setSelectedEndDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}

                              helperText={showEndDateError ? showEndDateErrorText : null}
                              error={showEndDateError ? showEndDateErrorText : null}
                            />
                          </div>
                          </MuiPickersUtilsProvider>
                          : 
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <div className="mui-custom-form with-date input-md">
                            <KeyboardDatePicker
                              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                              id="date-picker-dialog-enddate"
                              fullWidth
                              label="End Date"
                              required
                              helperText={null}
                              format="MM/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              maxDate={new Date('9999-12-31T13:00:00.000+0000')}
                              error={false}
                              placeholder="mm/dd/yyyy"
                              value={selectedEndDate}
                              onChange={setSelectedEndDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}

                              helperText={showEndDateError ? showEndDateErrorText : null}
                              error={showEndDateError ? showEndDateErrorText : null}
                            />
                          </div>
                          </MuiPickersUtilsProvider>
                                }
                    </div>
                </div>
                      : null}
                      </div>
                    </>
                  )}
                </div>
              </TabPanel>

              <TabPanel
                value={value}
                index={1}
              >
                {value === 1 && (
                  <div className="tab-holder my-3 p-0">
                    <NotesComponent
                      addNotes={addNotes}
                      setFocusCheck={setFocusCheck}
                      notesTableData={notesTableData}
                      noteSetListInput={noteSetListInput}
                      setNoteSetListInput={setNoteSetListInput}
                      usageTypeCodeData={usageTypeCodeData}
                      editNoteData={editNoteData}
                      setEditNoteData={setEditNoteData}
                      setSuccessMessages={clearSuccessErrorMsgs}
                      disableNotes={disableNotes}
                      setErrorMessages = {setErrorMessages}
                    />
                  </div>
                )}
              </TabPanel>
              {
                showSystemlistValueTable

                  ? <div>
                    <div className="tabs-container">
                      <div className="tab-header">
                        <div className="tab-heading float-left">
                          Add List Value
                        </div>
                        <form autoComplete="off">

                          <div className="clearfix"></div>

                        </form>
                      </div>
                    </div>
                  </div> : null
              }
              <Footer print />
              <Dialog className="custom-dialog"
                open={openCrossReference} fullWidth={'xxl'} disableBackdropClick>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseCrossReference}>
                  {
                    add ? 'Add System List Details' : 'Edit System List Details'
                  }
                </DialogTitle>
                <DialogContent dividers>
                  <form>
                    <div className="form-wrapper">
                      {
                        values.listType === 'UIS'
                          ? <div className="mui-custom-form override-width-30 m-2">
                            <TextField
                              id="constant-text"
                              fullWidth
                              label="Sort Order"
                              value={systemListValues.sortOrder}
                              onChange={handleSystemListChanges('sortOrder')}
                              InputLabelProps={{
                                shrink: true
                              }}
                              inputProps={{ maxLength: 10 }}
                              onInput={e => {
                                e.target.value = e.target.value.replace(
                                  AppConstants.NUMBER_ONLY_REGEX,
                                  ''
                                );
                              }}
                            />
                          </div> : null
                      }

                      <div className="mui-custom-form override-width-30 m-2" >
                        <TextField
                          id="beginValue"
                          fullWidth
                          label="Begin Value"
                          required
                          inputProps={{ maxLength: 30 }}
                          value={systemListValues.beginValue}
                          onChange={handleSystemListChanges('beginValue')}
                          InputLabelProps={{
                            shrink: true
                          }}
                          helperText={(showBeginValueError || showRevenueCodeBeginValueError) ? showBeginValueErrorText : (showDiagNumericBeginValueError ? showDiagNumericBeginValueErrorText : null)}
                          error={(showBeginValueError || showRevenueCodeBeginValueError) ? showBeginValueErrorText : (showDiagNumericBeginValueError ? showDiagNumericBeginValueErrorText : null)}
                        />
                      </div>
                      {
                        values.listType === 'PRC' || values.listType === 'XWK'
                          ? <div className="mui-custom-form override-width-31 m-2">
                            <TextField
                              key="sl_u_begv"
                              id="endValue"
                              fullWidth
                              required
                              label="End Value"
                              value={systemListValues.endValue}
                              inputProps={{ maxLength: 30 }}
                              onChange={handleSystemListChanges('endValue')}
                              InputLabelProps={{
                                shrink: true
                              }}
                              helperText={(showEndValueError || showRevenueCodeEndValueError) ? showEndValueErrorText : (showDiagNumericEndValueError ? showDiagNumericEndValueErrorText : null)}
                              error={(showEndValueError || showRevenueCodeEndValueError) ? showEndValueErrorText : (showDiagNumericEndValueError ? showDiagNumericEndValueErrorText : null)}
                            />
                          </div> : null
                      }
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <div className="mui-custom-form with-date override-width-31 m-2" >
                          <KeyboardDatePicker
                            maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                            id="date-picker-dialog-begindate"
                            fullWidth
                            label="Begin Date"
                            required
                            format="MM/dd/yyyy"
                            InputLabelProps={{
                              shrink: true
                            }}
                            placeholder="mm/dd/yyyy"
                            value={selectedBeginDate}
                            onChange={setSelectedBeginDate}

                            helperText={showBeginDateError ? showBeginDateErrorText : null}
                            error={showBeginDateError ? showBeginDateErrorText : null}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                          />
                        </div>
                        {values.listType === 'PRC' || values.listType === 'XWK'
                          ? <div className="mui-custom-form with-date override-width-28 m-2" >
                            <KeyboardDatePicker
                              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                              id="date-picker-dialog-enddate"
                              fullWidth
                              label="End Date"
                              required
                              helperText={null}
                              format="MM/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              maxDate={new Date('9999-12-31T13:00:00.000+0000')}
                              error={false}
                              placeholder="mm/dd/yyyy"
                              value={selectedEndDate}
                              onChange={setSelectedEndDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}

                              helperText={showEndDateError ? showEndDateErrorText : null}
                              error={showEndDateError ? showEndDateErrorText : null}
                            />
                          </div>
                          : <div className="mui-custom-form with-date override-width-28 m-2">
                            <KeyboardDatePicker
                              maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                              id="date-picker-dialog-enddate"
                              fullWidth
                              label="End Date"
                              required
                              helperText={null}
                              format="MM/dd/yyyy"
                              InputLabelProps={{
                                shrink: true
                              }}
                              maxDate={new Date('9999-12-31T13:00:00.000+0000')}
                              error={false}
                              placeholder="mm/dd/yyyy"
                              value={selectedEndDate}
                              onChange={setSelectedEndDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change date'
                              }}

                              helperText={showEndDateError ? showEndDateErrorText : null}
                              error={showEndDateError ? showEndDateErrorText : null}
                            />
                          </div>
                        }
                      </MuiPickersUtilsProvider>
                    </div>
                  </form>
                </DialogContent>

                <DialogActions>
                  {
                    add
                      ? <div>
                        <Button variant="outlined" color="primary" className="btn btn-success ml-1" onClick={addSystemListValuesToTable}>
                          <i className="fa fa-plus" aria-hidden="true"></i>
                          Add </Button>

                        <Button variant="outlined" color="primary" className='bt-reset btn-transparent  ml-1' onClick={resetSystemListValues}>
                          <i className="fa fa-undo" aria-hidden="true"></i> Reset </Button>
                      </div>
                      : <div>
                        <Button variant="outlined" color="primary" className='btn btn-primary mr-1' onClick={addSystemListValuesToTable}>
                          Update </Button>

                        <Button variant="outlined" color="primary" className='bt-reset btn-transparent  ml-1' onClick={resetSystemListValues}>
                          <i className="fa fa-undo" aria-hidden="true"></i>
                          Reset </Button>
                      </div>
                  }
                </DialogActions>
              </Dialog>
              <Dialog
              open={dialogOpen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="custom-alert-box"
      >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
                </>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
              setDialogOpen(false);
            }}
                  color="primary"
                  className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
                </Button>
                <Button
                  onClick={cancelMinorSection}
                  color="primary"
                  className="btn btn-success"
                  autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
                </Button>
              </DialogActions>
            </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
