/**

* � [2020] Conduent, Inc. All rights reserved. Conduent�and Conduent Agile

* Star�are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect, Fragment } from 'react';
import { Button } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField';
import Footer from '../../../../SharedModules/Layout/footer';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuItem from '@material-ui/core/MenuItem';
import TextTypeData from './TextTypeData.json';
import PropTypes from 'prop-types';
import MUIRichTextEditor from 'mui-rte';
import AppBar from '@material-ui/core/AppBar';
import { Prompt } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter } from "react-router";
import NoSaveMessage from '../../../../SharedModules/Errors/NoSaveMessage';
import { connect } from 'react-redux';
import * as DateUtils from '../../../../SharedModules/DateUtilities/DateUtilities';
import { DialogTitle, DialogContent, DialogActions } from '../../../../SharedModules/Dialog/DialogUtilities';
import Dialog from '@material-ui/core/Dialog';
import { Editor, EditorState } from 'draft-js';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import { Form } from 'react-bootstrap';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import {
    TextManagementUpdateProviderNoticeAction, ResetData, SetSpinner, TextManagementUpdateRAEOBAction, TextManagementUpdateRemarkTextAction,
    TextManagementUpdateAdjustmentReasonAction, TextManagementUpdateNCPDPAction, TextManagementUpdateEOMBAction,
    TextManagementDeleteEOMBAction, TextManagementDeleteRAEOBAction, EOMBGetRecordAction, RaeobGetRecordAction,
    providerNoticeGetRecordAction, RemarkTextGetRecordAction, AdjustmentReasonRecordAction, NCPDPGetRecordAction
} from '../../Store/Actions/TextManagement/TextManagementAddUpdateActions';
import {
    ProviderSpecialtyDataAction, systemParameterRowClickAction, ResetTextSearchReducer
} from '../../Store/Actions/TextManagement/TextManagementActions';
import { AppConfigDropdownActions, ModifierDropdownActions } from '../../Store/Actions/AppConfigCommon/AppConfigActions';
import DateFnsUtils from '@date-io/date-fns';
import * as TextManagementErrors from './TextManagementErrors';
import moment from 'moment';
import TextEditor from '../../../../SharedModules/RichTextEditor/TextEditorEdit';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import dropdownCriteria from './TextManagementAddUpdate.json';
import NavHOC from "../../../../SharedModules/Navigation/NavHOC";
import Notes from '../../../../SharedModules/Notes/Notes';
import TabPanel from '../../../../SharedModules/TabPanel/TabPanel';
import Radio from '@material-ui/core/Radio';
import { getAuditLogDetailsAction,setFocus } from '../../Store/Actions/AppConfigCommon/AppConfigActions';
import AuditTableComponent from '../../../../SharedModules/AuditLog/AuditTableComponent';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

function dateCheck(date1, date2) {
    date1 = new Date(date1);
    date2 = new Date(date2);
    var dateone = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0);
    var datetwo = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0);
    if (dateone < datetwo) {
        return 0;
    } else if (dateone > datetwo) {
        return 1;
    } else {
        return 2;
    }
}

const mapStateToProps = state => ({
    functionalDropDown: state.appConfigState.AppConfigCommonState.appConfigDropdown,
    isFocusCheck: state.appConfigState.AppConfigCommonState.isFocusCheck,
    ProviderSpecialtyData: state.appConfigState.providerSpecialtyState.ProviderSpecialtyData,
    ModifierData: state.appConfigState.AppConfigCommonState.modifierDropdown,
    Result: (state.appConfigState.textManagementState.payload ? state.appConfigState.textManagementState.payload : null),
    Record: state.appConfigState.TextSearchState.PNText,
    newRecord: state.appConfigState.TextSearchState.Text,
    DelResult: state.appConfigState.textManagementState.DelResult,
    showSpinner: state.appConfigState.SetSpinnerState.showSpinner,
    userInquiryPrivileges: !global.globalIsReadOnly(),
    logInUserID: state.sharedState.logInUserID,
    auditLogData: state.textAuditState.auditData
});


class TextUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.toPrintRef = React.createRef();
        this.DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
        this.eomb_ref = React.createRef();
        this.raeob_ref = React.createRef();
        this.provider_notice_ref = React.createRef();
        this.remark_code_ref = React.createRef();
        this.adjustment_reason_ref = React.createRef();
        this.ncpdp_ref = React.createRef();
        this.state = {
            showLogTable: false,
            auditRowData: [],
            TextType: this.props.location.state.TextType,
            error_messages: [],
            allowNavigation: false,
            dialogOpen: false,
            focusCheck: false,
            showMsg: false,
            showAddSuccess: true,
            tabValue: 0,
            value: 0,
            tabOne: false,
            notesTableData: [],
            notesInput: this.props.location.state.row?.noteSetVO ? this.props.location.state.row?.noteSetVO :
                {
                    auditUserID: this.props.logInUserID,
                    auditTimeStamp: DateUtils.getUTCTimeStamp(),
                    addedAuditUserID: this.props.logInUserID,
                    addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
                    versionNo: 0,
                    dbRecord: false,
                    sortColumn: null,
                    tableName: null,
                    noteSetSK: null,
                    noteSourceName: null,
                    notesList: [],
                    globalNotesList: [],
                    checkAll: null,
                    addNewLinkRender: null,
                    filterLinkRender: null,
                    printLinkRender: null,
                    completeNotesList: []
                },
            noteSetListInput: {
                auditUserID: this.props.logInUserID,
                auditTimeStamp: DateUtils.getUTCTimeStamp(),
                addedAuditUserID: this.props.logInUserID,
                addedAuditTimeStamp: DateUtils.getUTCTimeStamp(),
                versionNo: 0,
                dbRecord: false,
                sortColumn: null,
                noteTextValue: null,
                userIdName: null,
                notesCexAuditUserID: null,
                notesCexAuditTimeStamp: null,
                notesCexAddedAuditUserID: null,
                notesCexAddedAuditTimeStamp: null,
                noteSetSK: null,
                usageTypeDesc: '',
                shortNotes: null,
                checked: false,
                renderNoHistoryMsg: false,
                noteSequenceNumber: null,
                currentNote: null,
                rowValue: null,
                usageTypeList: null,
                strBeginDate: moment(new Date()).format('MM/DD/YYYY hh:mm:ss'),
                usageTypeCode: this.DEFAULT_DD_VALUE,
                tableName: null,
                noteText: '',
                commonEntityName: null,
                commonEntityTypeCode: null,
                commonEntityId: null,
                entityId: null,
                filterbeginDate: moment(new Date()).format('YYYY-MM-DD'),
                filterEndDate: null,
                userId: this.props.logInUserID,
                noteCexVersionNum: 0,
                saNoteSequenceNumber: null,
                notesCexnoteTextValue: 0,
                id: DateUtils.generateUUID()
            },
            usageTypeCodeData: [],
            editNoteData: {},
            searchData: {}
        }
        this.props.dispatch(SetSpinner(false));
        this.getProviderSpecialty = this.getProviderSpecialty.bind(this);
        this.props.dispatch(AppConfigDropdownActions(dropdownCriteria));
        this.props.dispatch(ResetTextSearchReducer());
    };

    MappingTextType(textType) {
        switch (textType) {
            case '2-EOMB':
                return 'EOMB';
            case '3-RA EOB':
                return 'RA EOB';
            case '4-ProvNotice':
                return 'Provider Notice';
            case '5-Remark':
                return 'Remark Code';
            case '6-Adj Reason':
                return 'Adjustment Reason';
            case '8-NCPDP':
                return 'NCPDP Rejection Codes';
            default:
                return 'EOMB';
        }
    }

    handleChanges = event => {
        this.props.dispatch(setFocus(true));
        this.props.dispatch(ResetData());
        this.setState({ error_messages: [], TextType: event.target.value }, () => {
        })
        this.setState({ TextType: event.target.value, showMsg: false, allowNavigation: true });
    };

    setAllowNavigation = event => {
        this.setState({ allowNavigation: true }, () => {
            this.setState({ showAddSuccess: false })
        });
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.DelResult && nextProps.DelResult.respcode && (nextProps.DelResult.respcode === '01')) {
            this.setState({ showMsg: false });
        }
               
        if (nextProps.auditLogData && nextProps.auditLogData.length > 0) {
            this.setState({
                auditRowData: nextProps.auditLogData,
            })
        }
        if (nextProps.newRecord && nextProps.newRecord[0]) {
            if (nextProps.newRecord[0].noteSetVO) {
                const noteSetVO = nextProps.newRecord[0].noteSetVO;
                const notesTable = nextProps.newRecord[0].noteSetVO.notesList;
                this.setNotesTableData(notesTable);
                this.setNotesinputfirst(nextProps.newRecord[0]);
                this.setState({ notesInput: noteSetVO });
            }
        }
    }
    setRedirect = event => {
        setTimeout(
            function () {
                this.setState({ showMsg: false }, () => {
                    if (this.props.DelResult && this.props.DelResult.respcode === '01') {
                        this.setState({ allowNavigation: false }, () => {
                            this.props.history.push({
                                pathname: '/TextManagementSearch',
                                state: { message: 'System successfully deleted the information.' }
                            });
                        })
                    }
                });
            }
                .bind(this),
            2000
        );
    };

    OnLoadMappingTextType(textType) {
        switch (textType) {
            case 'EOMB':
                return '2-EOMB';
            case 'RA EOB':
                return '3-RA EOB';
            case 'Provider Notice':
                return '4-ProvNotice';
            case 'Remark Code':
                return '5-Remark';
            case 'Adjustment Reason':
                return '6-Adj Reason';
            case 'NCPDP Rejection Codes':
                return '8-NCPDP';
            default:
                return '2-EOMB';
        }
    }
    handleChangeTabs = (event, newValue) => {
        this.props.dispatch(setFocus(true));
        this.setState({ value: newValue });
        if (newValue === 0) {
            this.setState({ tabOne: true });
        }
    };
    componentDidMount() {
        let TextType = this.OnLoadMappingTextType(this.props.location.state.TextType);
        this.setState({ spinnerLoader: false, TextType: TextType, searchData: this.props.location.state.row,
            showLogTable: false,
            auditRowData: [] })
        if (this.props.location.state.row && this.props.location.state.row?.noteSetVO) {
            const noteSetVO = this.props.location.state.row?.noteSetVO;
            const notesTable = this.props.location.state.row?.noteSetVO.notesList;
            this.setNotesTableData(notesTable);
            this.setNotesinputfirst(this.props.location.state.row);
            this.setState({ notesInput: this.props.location.state.row?.noteSetVO });
        }
    }

    //set component did mount notes input

    setNotesinputfirst = data => {
        const defaultSet = data;

        this.setState({
            notesInput: data
        });
    }

    //set note set list input 
    setNoteSetListInput = data => {

        const noteSetListInput = data;
                this.setState({
            noteSetListInput: noteSetListInput
        })
    }

    setFocusCheck = data => {
        this.props.dispatch(setFocus(data));
    }

    // // set notes table data
    setNotesTableData = data => {
        let notesTableData = this.state.notesTableData;
        notesTableData = data;
        this.setState({
            notesTableData: notesTableData
        });
    }
    // add notes
    addNotes = (data) => {
        let notesDataArray = [];
        this.props.dispatch(setFocus(true));
        this.setAllowNavigation();
        const noteText = data;
        let tempNoteSet = this.state.notesInput.notesList;
        tempNoteSet.push(noteText);
        const notesInput = this.state.notesInput;
        notesInput.notesList = tempNoteSet;
        notesDataArray = this.state.notesTableData;
        this.setState({
            notesInput: notesInput,
            notesTableData: notesInput.notesList
        })
    };

    setErrorMessages = data => {
        this.setState({error_messages: data })
    }

    setErrorNull = event => {
        this.setState({ error_messages: [], showMsg: false, showAddSuccess: false });
        this.props.dispatch(ResetData());
        let textType = this.MappingTextType(this.state.TextType);
        switch (textType) {
            case 'EOMB':
                this.eomb_ref.current.resetFieldError();
                break;
            case 'RA EOB':
                this.raeob_ref.current.resetFieldError();
                break;
            case 'Provider Notice':
                this.provider_notice_ref.current.resetFieldError();
                this.provider_notice_ref.current.setErrObject();
                break;
            case 'Remark Code':
                this.remark_code_ref.current.resetFieldError();
                this.remark_code_ref.current.setErrObject();
                break;
            case 'Adjustment Reason':
                this.adjustment_reason_ref.current.resetFieldError();
                this.adjustment_reason_ref.current.setErrObject();
                break;
            case 'NCPDP Rejection Codes':
                this.ncpdp_ref.current.resetFieldError();
                this.ncpdp_ref.current.setErrObject();
                break;
        };
    };
    componentDidUpdate(previousProps, previousState) {
        if (previousProps.Result !== this.props.Result) {
            if (this.props.Result && this.props.Result.respcode === '01') {
                let textType = this.MappingTextType(this.state.TextType);
                switch (textType) {
                    case 'EOMB':
                        this.eomb_ref.current.getRecord();
                        break;
                    case 'RA EOB':
                        this.raeob_ref.current.getRecord();
                        break;
                    case 'Provider Notice':
                        this.provider_notice_ref.current.getRecord();
                        break;
                    case 'Remark Code':
                        this.remark_code_ref.current.getRecord();
                        break;
                    case 'Adjustment Reason':
                        this.adjustment_reason_ref.current.getRecord();
                        break;
                    case 'NCPDP Rejection Codes':
                        this.ncpdp_ref.current.getRecord();
                        break;
                }
            }
        }
        if (previousState.showLogTable !== this.state.showLogTable) {
            if (this.state.showLogTable && document.getElementById('audit-table')) {
                document.getElementById('audit-table').scrollIntoView();
            }
        }
    }
    //Audit Log Implementation starts

    onClickAuditLog = () => {
        let tableName = "r_text_proc_eomb_tb";
        let keyValue = {
            R_EOMB_PROC_TY_CD: '',
            R_LOB_CD: '',
            R_EOMB_SEQ_NUM: ''
        }
        let textType = this.MappingTextType(this.state.TextType);
        switch (textType) {
            case 'EOMB':
                tableName = 'r_text_proc_eomb_tb';
                keyValue = {                    
                    r_lob_cd: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.lob : '',
                    r_eomb_proc_ty_cd: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.eombType : '',
                    r_eomb_seq_num: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.eombSequenceNumber : '',
                }
                break;
            case 'RA EOB':
                tableName = 'r_ra_eob_tb';
                keyValue = {
                    r_lob_cd: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.lobCode : '',
                    r_clm_eob_cd: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.claimEOBCode : ''
                }
                break;
            case 'Provider Notice':
                tableName = 'r_text_ra_ltr_tb';
                keyValue = {
                    r_lob_cd: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.lobCode : '',
                    r_text_ra_ltr_sk: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.textRALetterSK : ''
                }
                break;
            case 'Remark Code':
                tableName = 'r_text_rmk_cd_tb';
                keyValue = {
                    r_rmk_cd: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.remarkCode : '',
                    r_text_rmk_seq_num: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.remarkCodeSeqNo : ''
                }
                break;
            case 'Adjustment Reason':
                tableName = 'r_text_adj_rsn_tb';
                keyValue = {
                    r_adj_rsn_cd: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.adjustmentReasonCode : '',
                    r_adj_rsn_seq_num: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.adjstResnSeqNo : ''
                }
                break;
            case 'NCPDP Rejection Codes':
                tableName = 'r_text_ncpdp_rjct_cd_tn';
                keyValue = {
                    r_ncpdp_rjct_cd: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.ncpdpRejectCode : '',
                    r_ncpdp_rjct_seq_num: this.props.location.state && this.props.location.state.row ? this.props.location.state.row.ncpdpRejectCodeSeqNo : ''
                }
                break;
        }
        this.props.dispatch(getAuditLogDetailsAction(tableName, keyValue, "auditLogData"));
    }
    setAuditRowData = (value) => {
        this.setState({ auditRowData: value })
    }
    setShowLogTable = (value) => {
        this.setState({ showLogTable: value });
    }
    //Audit Log Implementation ends
    handleSave = event => {
        this.props.dispatch(setFocus(false));
        this.setState({ allowNavigation: false });
        if (!this.state.allowNavigation) {
            NoSaveMessage();
        } else {
            this.setState({ error_messages: [], showMsg: true }, () => {
                let data = null;
                let textType = this.MappingTextType(this.state.TextType);
                switch (textType) {
                    case 'EOMB':
                        data = this.eomb_ref.current.searchCheck();
                        if (data.length === 0) {
                            data = this.eomb_ref.current.onSave();
                            if (data) {
                                data = { ...data, noteSetVO: this.state.notesInput }
                            }
                            this.props.dispatch(TextManagementUpdateEOMBAction(data));

                        }
                        else {
                            this.setState({ showMsg: false, error_messages: [...this.state.error_messages, ...data] });
                        }
                        break;
                    case 'RA EOB':
                        data = this.raeob_ref.current.searchCheck();
                        if (data.length === 0) {
                            data = this.raeob_ref.current.onSave();
                            if (data) {
                                data = { ...data, noteSetVO: this.state.notesInput }
                            }
                            this.props.dispatch(TextManagementUpdateRAEOBAction(data));
                        }
                        else {
                            this.setState({ showMsg: false, error_messages: [...this.state.error_messages, ...data] });
                        }
                        break;
                    case 'Provider Notice':
                        data = this.provider_notice_ref.current.searchCheck();
                        if (data.length === 0
                        ) {
                            this.provider_notice_ref.current.setErrObject();
                            data = this.provider_notice_ref.current.onSave();
                            if (data) {
                                data = { ...data, noteSetVO: this.state.notesInput }
                            }
                            this.props.dispatch(TextManagementUpdateProviderNoticeAction(data));
                        }
                        
                        else {
                            this.setState({ showMsg: false, error_messages: [...this.state.error_messages, ...data] });
                        }
                        break;
                    case 'Remark Code':
                        data = this.remark_code_ref.current.searchCheck();
                        if (data.length === 0) {
                            this.remark_code_ref.current.setErrObject();
                            data = this.remark_code_ref.current.onSave();
                            if (data) {
                                data = { ...data, noteSetVO: this.state.notesInput }
                            }
                            this.props.dispatch(TextManagementUpdateRemarkTextAction(data));
                        }
                        else {
                            this.setState({ showMsg: false, error_messages: [...this.state.error_messages, ...data] });
                        }
                        break;
                    case 'Adjustment Reason':
                        data = this.adjustment_reason_ref.current.searchCheck();
                        if (data.length === 0) {
                            this.adjustment_reason_ref.current.setErrObject();
                            data = this.adjustment_reason_ref.current.onSave();
                            if (data) {
                                data = { ...data, noteSetVO: this.state.notesInput }
                            }
                            this.props.dispatch(TextManagementUpdateAdjustmentReasonAction(data));
                        }
                        else {
                            this.setState({ showMsg: false, error_messages: [...this.state.error_messages, ...data] });
                        }
                        break;
                    case 'NCPDP Rejection Codes':
                        data = this.ncpdp_ref.current.searchCheck();
                        if (data.length === 0) {
                            this.ncpdp_ref.current.setErrObject();
                            data = this.ncpdp_ref.current.onSave();
                            if (data) {
                                data = { ...data, noteSetVO: this.state.notesInput }
                            }
                            this.props.dispatch(TextManagementUpdateNCPDPAction(data));
                        }
                        else {
                            this.setState({ showMsg: false, error_messages: [...this.state.error_messages, ...data] });
                        }
                        break;
                }
            })
        }
    }
    getProviderSpecialty(value) {
        if (value !== this.DEFAULT_DD_VALUE) {
            this.props.dispatch(ProviderSpecialtyDataAction(value));
        }
    }
    setSuccessMessagesForNotes = (notesFlag) => {
        this.setState({
            showAddSuccess: false,
            showMsg: false
        })
    }

    cancelMinorSection =() => {
        this.setState({ allowNavigation: false });
        setTimeout(()=>{
            this.setState({dialogOpen:false});
            this.props.dispatch(setFocus(false));
              this.props.history.push({
                pathname: '/TextManagementSearch',
                tableRender: !this.props.history.location?.state?.isFromAdd
              });
        },100)
        };
        
         majorCancel =()=> {
          if (this.props.isFocusCheck) {
            this.setState({dialogOpen:true});
          } else {
            this.setState({dialogOpen:false});
            this.props.history.push({
              pathname: '/TextManagementSearch',
              tableRender: !this.props.history.location?.state?.isFromAdd
            });
          }
        };

    render() {

        const { classes } = this.props
        return (

            <div>

                <div className="tabs-container" ref={this.toPrintRef}>
                    <Prompt
                        when={this.state.allowNavigation}
                        message={location => `Are you sure you want to go to ${location.pathname}`}
                    />
                    {this.props.showSpinner ? <Spinner /> : null}
                    {this.state.showAddSuccess && this.state.error_messages.length === 0 &&
                        this.props.location.state.message && !this.state.showMsg ?
                        (<div class="alert alert-success custom-alert" role="alert" >
                            <li>{TextManagementErrors.SUCCESS_MSG
                            }</li></div>) : null
                    }
                    {this.state.error_messages.length > 0 ? <div class="alert alert-danger custom-alert" role="alert" >
                        {this.state.error_messages.map(message =>
                            <li>{message}</li>
                        )
                        }
                    </div> : null
                    }
                    {this.state.showMsg ?
                        this.props.Result && this.props.Result.respcode ? this.props.Result.respcode === '01' ? (<div class="alert alert-success custom-alert" role="alert" >
                            {this.props.Result.respdesc
                            }</div>) : (<div class="alert alert-danger custom-alert" role="alert" >
                                {this.props.Result.errorMessages !== null ? this.props.Result.errorMessages.map(message =>
                                    <li>{message}</li>
                                ) : <li>{this.props.Result.respdesc}</li>
                                }</div>) :
                            this.props.Result && this.props.Result.systemFailure ? (<div class="alert alert-danger custom-alert" role="alert" >
                                {
                                    TextManagementErrors.ERR_PROCESSING_REQ
                                }</div>) : null : null
                    }
                    {this.state.showMsg === false ?
                        (this.props.DelResult && this.props.DelResult !== null) ?
                            this.props.DelResult.respcode ? this.props.DelResult.respcode !== '01' ?
                                <div class="alert alert-danger custom-alert" role="alert" >
                                    {<li>{this.props.DelResult.respdesc}</li>
                                    }</div> : <div class="alert alert-success custom-alert" role="alert" >
                                    <li>{"System successfully deleted the information."}</li> </div>
                                : this.props.DelResult && this.props.DelResult.errorCode && this.props.DelResult.message ?
                                    <div class="alert alert-danger custom-alert" role="alert" >
                                        <li>{this.props.DelResult.message}</li>
                                    </div>
                                    : (<div class="alert alert-danger custom-alert" role="alert" >
                                        <li>{
                                            TextManagementErrors.ERR_PROCESSING_REQ
                                        }</li></div>) : null : null
                    }
                    <div className="tab-header">
                        <div className="tab-heading float-left" style={{ marginLeft: "15px" }}>
                            Edit Text
                        </div>
                        <div className="float-right mt-1 pt-1">

                            <Button className='btn btn-success' onClick={this.handleSave} disabled={!this.props.userInquiryPrivileges}>
                                <i class="fa fa-check" aria-hidden="true"></i>

                                Save
                        </Button>
                            <Button color="primary" className={
                                    this.state.showLogTable
                                    ? "btn btn-primary ml-1"
                                    : "btn btn-text1 btn-transparent ml-1"
                                } onClick={() => {
                                this.setState({ showLogTable: !this.state.showLogTable, auditRowData: [] });
                                this.onClickAuditLog();
                            }}>
                                <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                                Audit Log
                            </Button>
                        {window.location.pathname === '/TextUpdate' && (
                    <Button title="Cancel" className='btn btn-primary' onClick={this.majorCancel}>Cancel</Button>
            )}
                        </div>
                        <div className="clearfix"></div>
                    </div>

                    {(() => {
                        if (this.state.TextType) {
                            return (
                                <div>
                                    <div className="tab-body">
                                        <form autoComplete="off">
                                            <div className="form-wrapper" >
                                                    <NativeDropDown
                                                        id="text-type"
                                                        isRequired
                                                        disabled
                                                        className='input-xl'
                                                        label="Text Type"
                                                    inputProps={{ 'data-testid': 'text_type_id' }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        value={this.state.TextType}
                                                        onChange={this.handleChanges}
                                                        dropdownList={this.props.functionalDropDown && this.props.functionalDropDown.listObj &&
                                                            this.props.functionalDropDown.listObj['Reference#1130'] ?
                                                            this.props.functionalDropDown.listObj['Reference#1130'].map(option => {
                                                                if (option.code !== '1' && option.code !== '7') {
                                                                    return (<option key={option.code} value={option.description}>
                                                                        {option.description.split('-')[1]}
                                                                    </option>)
                                                                }
                                                            }) : null}
                                                    />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="tab-body">
                                        <div className="collapsable-panel">
                                            <div className="MuiExpansionPanel-root">
                                                <div className="panel-header">
                                                    <div className="MuiExpansionPanelSummary-content ">
                                                        <p className="MuiTypography-root">
                                                            Text Detail
                                        </p>
                                                    </div>
                                                </div>

                                                <div className="MuiCollapse-container MuiCollapse-entered">
                                                </div>

                                                <div className="MuiCollapse-wrapper">
                                                    <div className="w-100" style={{ padding: '0px 4px' }}>



                                                        {(() => {
                                                            let textType = this.MappingTextType(this.state.TextType);
                                                            switch (textType) {
                                                                case 'EOMB':
                                                                    return <EOMB setRedirect={this.setRedirect} data={this.props.location.state} dropDown={this.props.functionalDropDown ? this.props.functionalDropDown : null} ref={this.eomb_ref} setErrorNull={this.setErrorNull} setAllowNavigation={this.setAllowNavigation} userInquiryPrivileges={this.props.userInquiryPrivileges} />;
                                                                case 'RA EOB':
                                                                    return <RAEOB setRedirect={this.setRedirect} data={this.props.location.state.row} ref={this.raeob_ref} dropDown={this.props.functionalDropDown ? this.props.functionalDropDown : null} setErrorNull={this.setErrorNull} setAllowNavigation={this.setAllowNavigation} userInquiryPrivileges={this.props.userInquiryPrivileges} />
                                                                case 'Provider Notice':
                                                                    return <ProviderNotice data={this.props.location.state.row} ref={this.provider_notice_ref} getProviderSpecialty={this.getProviderSpecialty} ProviderSpecialtyData={this.props.ProviderSpecialtyData} dropDown={this.props.functionalDropDown ? this.props.functionalDropDown : null} setErrorNull={this.setErrorNull} setAllowNavigation={this.setAllowNavigation} userInquiryPrivileges={this.props.userInquiryPrivileges} />
                                                                case 'Remark Code':
                                                                    return <RemarkCode data={this.props.location.state.row} ref={this.remark_code_ref}
                                                                        setErrorNull={this.setErrorNull} setAllowNavigation={this.setAllowNavigation} userInquiryPrivileges={this.props.userInquiryPrivileges} />
                                                                case 'Adjustment Reason':
                                                                    return <AdjustmentReason data={this.props.location.state.row} ref={this.adjustment_reason_ref} setAllowNavigation={this.setAllowNavigation} userInquiryPrivileges={this.props.userInquiryPrivileges} />
                                                                case 'NCPDP Rejection Codes':
                                                                    return <NCPDP data={this.props.location.state.row} ref={this.ncpdp_ref} setErrorNull={this.setErrorNull} setAllowNavigation={this.setAllowNavigation} userInquiryPrivileges={this.props.userInquiryPrivileges} />
                                                                default:
                                                                    return null;
                                                            }
                                                        })()}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )
                        }
                    })()}
   <Dialog
                open={this.state.dialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="custom-alert-box"
      >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <>
                      Unsaved changes will be lost. <br />
                      Are you sure you want to continue?
                    </>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
              this.setState({dialogOpen:false});
            }}
                    color="primary"
                    className="btn btn-transparent"
          >
                    STAY ON THIS PAGE!
                  </Button>
                  <Button
                    onClick={this.cancelMinorSection}
                    color="primary"
                    className="btn btn-success"
                    autoFocus
          >
                    CONTINUE <i className="fa fa-arrow-right ml-1" />
                  </Button>
                </DialogActions>
              </Dialog>

                    <div className='tab-panelbody' style={{ marginTop: "-11px" }}>
                        <div className="tab-holder my-3">
                            <AppBar position="static">
                                <Tabs
                                    value={this.state.value}
                                    aria-label="simple tabs example"
                                    className="tabChange">
                                    <Tab label="Notes" />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={this.state.tabValue} index={0}>

                                <Notes
                                    addNotes={this.addNotes}
                                    onChange={this.handleChangeTabs}
                                    notesTableData={this.state.notesTableData}
                                    noteSetListInput={this.state.noteSetListInput}
                                    setNoteSetListInput={this.setNoteSetListInput}
                                    usageTypeCodeData={this.props.functionalDropDown && this.props.functionalDropDown.listObj && this.props.functionalDropDown.listObj['General#1012'] ? this.props.functionalDropDown.listObj['General#1012'] : []}
                                    editNoteData={this.state.editNoteData}
                                    setSuccessMessages={this.setErrorNull}
                                    isNotesTable={true}
                                    setErrorMessages={this.setErrorMessages}
                                    setFocusCheck={this.setFocusCheck}
                                    userInquiryPrivileges = {this.props.userInquiryPrivileges}
                                />

                            </TabPanel>
                        </div>
                        {
                            this.state.showLogTable ?
                                (<div id='audit-table'>
                                    <AuditTableComponent
                                        print
                                        auditRowData={this.state.auditRowData}
                                        setAuditRowData={this.setAuditRowData}
                                        setShowLogTable={this.setShowLogTable}
                                    /> </div>) : null
                        }
                    </div>

                    <Footer print />
                </div>
            </div>)
    }
}

class EOMB_ extends React.Component {
    constructor(props) {
        super(props);
        this.DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
        this.total_characters = 320;
        this.state = {
            eombLob: props.data.row.lob,
            disableFrom: '',
            disableThru: '',
            eombFromCode: props.data.row.eombFromCode,
            eombThruCode: props.data.row.eombThruCode,
            text: props.data.row.eombDesc ? props.data.row.eombDesc : props.data.row.eombText,
            wordCount: props.data.row.eombDesc ? this.total_characters - props.data.row.eombDesc.length : this.total_characters,
            eombType: props.data.row.eombType === 'H' ? 'hcpcs' :
                props.data.row.eombType === 'I' ? 'surgical-procedure' :
                    props.data.row.eombType === 'R' ? 'revenue' : null,
            mod1: props.data.row.fromModifier1 ? props.data.row.fromModifier1 : this.DEFAULT_DD_VALUE,
            mod2: props.data.row.fromModifier2 ? props.data.row.fromModifier2 : this.DEFAULT_DD_VALUE,
            mod3: props.data.row.fromModifier3 ? props.data.row.fromModifier3 : this.DEFAULT_DD_VALUE,
            mod4: props.data.row.fromModifier4 ? props.data.row.fromModifier4 : this.DEFAULT_DD_VALUE,
            modt1: props.data.row.thruModifier1 ? props.data.row.thruModifier1 : this.DEFAULT_DD_VALUE,
            modt2: props.data.row.thruModifier2 ? props.data.row.thruModifier2 : this.DEFAULT_DD_VALUE,
            modt3: props.data.row.thruModifier3 ? props.data.row.thruModifier3 : this.DEFAULT_DD_VALUE,
            modt4: props.data.row.thruModifier4 ? props.data.row.thruModifier4 : this.DEFAULT_DD_VALUE,
            disableFrom: props.data.row.eombFromCode ? true : false,
            disableThru: props.data.row.eombThruCode ? true : false,
            versionNo: props.data.row.versionNo,
            openDialog: false,
            dialogOpen: false,
            errorObj: {
                eombLob: false,
                eombFromCode: false,
                eombThruCode: false,
                text: false
            },
            error_msg: {
                eombLob: '',
                eombFromCode: '',
                eombThruCode: '',
                text: '',
            },
            showDropdown: true
        }
        this.props.dispatch(ModifierDropdownActions());
    }
    resetFieldError = () => {
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        errorObj.eombLob = false;
        errorObj.eombFromCode = false;
        errorObj.eombThruCode = false;
        errorObj.text = false;
        error_msg.eombLob = "";
        error_msg.eombFromCode = "";
        error_msg.eombThruCode = "";
        error_msg.text = ""
        this.setState({ errorObj: errorObj, error_msg: error_msg });
    }
    componentDidMount() {
        this.dropDown = this.props.functionalDropDown ? this.props.functionalDropDown : null;
    }
    getRecord = () => {
        let eombType;
        switch (this.state.eombType) {
            case 'hcpcs':
                eombType = 'H';
                break;
            case 'surgical-procedure':
                eombType = 'I';
                break;
            case 'revenue':
                eombType = 'R';
                break;
            default:
                eombType = 'H';
        }
        this.props.dispatch(EOMBGetRecordAction(
            {
                "eombProcedureTypeCode": eombType,
                "lobCode": [AppConstants.DEFAULT_LOB_VALUE],
                "eombFromCode": this.state.eombFromCode,
                "eombthruCode": this.state.eombThruCode,
                "eombText": this.state.text,
                "fromModifier1": this.state.mod1 !== this.DEFAULT_DD_VALUE ? this.state.mod1 : null,
                "fromModifier2": this.state.mod2 !== this.DEFAULT_DD_VALUE ? this.state.mod2 : null,
                "fromModifier3": this.state.mod3 !== this.DEFAULT_DD_VALUE ? this.state.mod3 : null,
                "fromModifier4": this.state.mod4 !== this.DEFAULT_DD_VALUE ? this.state.mod4 : null,
                "thruModifier1": this.state.modt1 !== this.DEFAULT_DD_VALUE ? this.state.modt1 : null,
                "thruModifier2": this.state.modt2 !== this.DEFAULT_DD_VALUE ? this.state.modt2 : null,
                "thruModifier3": this.state.modt3 !== this.DEFAULT_DD_VALUE ? this.state.modt3 : null,
                "thruModifier4": this.state.modt4 !== this.DEFAULT_DD_VALUE ? this.state.modt4 : null
            }
        ))
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.newRecord && nextProps.newRecord[0] && (nextProps.newRecord[0].versionNo !== this.state.versionNo)) {
            this.setState({ versionNo: nextProps.newRecord[0].versionNo });
        }
    }
    searchCheck = () => {
        let errorMessagesArray = [];
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        if (!this.state.eombFromCode) {
            errorObj.eombFromCode = true
            error_msg.eombFromCode = "From Code" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.eombFromCode);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (!this.state.eombThruCode) {
            if (this.state.eombFromCode) {
                this.setState({ eombThruCode: this.state.eombFromCode }, () => {
                    this.handleModifiers();
                })
                errorObj.eombThruCode = false
                error_msg.eombThruCode = "";
                this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
                })
            }
            else {
                errorObj.eombThruCode = true
                error_msg.eombThruCode = "Thru Code" + TextManagementErrors.Required_Field_Error;
                errorMessagesArray.push(error_msg.eombThruCode);
                this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
                })
            }
        }
        else if (this.state.eombThruCode < this.state.eombFromCode) {
            errorObj.eombFromCode = true
            if (this.state.eombType === 'revenue') {
                error_msg.eombFromCode = TextManagementErrors.From_Thru_Equality_Error;
            } else if (this.state.eombType === 'surgical-procedure') {
                error_msg.eombFromCode = TextManagementErrors.FROM_THRU_EQUAL_ERROR_SP;
            } else {
                error_msg.eombFromCode = TextManagementErrors.FROM_THRU_EQUAL_ERROR_HCPCS;
            }
            errorMessagesArray.push(error_msg.eombFromCode);
            this.setState({ errorObj: errorObj, error_msg: error_msg });
        }
        if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
            errorObj.text = true
            error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.text);
            this.setState({ errorObj: errorObj, error_msg: error_msg });
        }
        this.eombSort();
        return errorMessagesArray;
    }
    onSave = e => {
        let eombType = '';
        switch (this.state.eombType) {
            case 'hcpcs':
                eombType = 'H';
                break;
            case 'surgical-procedure':
                eombType = 'I';
                break;
            case 'revenue':
                eombType = 'R';
                break;
            default:
                eombType = 'H';
        }

        //sorting Modifiers for saving 

        let Array1 = [this.state.mod1 !== this.DEFAULT_DD_VALUE ? this.state.mod1 : ''
            , this.state.mod2 !== this.DEFAULT_DD_VALUE ? this.state.mod2 : ''
            , this.state.mod3 !== this.DEFAULT_DD_VALUE ? this.state.mod3 : ''
            , this.state.mod4 !== this.DEFAULT_DD_VALUE ? this.state.mod4 : ''];
        let Array2 = [this.state.modt1 !== this.DEFAULT_DD_VALUE ? this.state.modt1 : ''
            , this.state.modt2 !== this.DEFAULT_DD_VALUE ? this.state.modt2 : ''
            , this.state.modt3 !== this.DEFAULT_DD_VALUE ? this.state.modt3 : ''
            , this.state.modt4 !== this.DEFAULT_DD_VALUE ? this.state.modt4 : ''];
        Array1.sort();
        Array2.sort();
        let A1 = []; let A2 = [];
        for (let i = 0; i < 4; i++) {
            if (Array1[i] !== '') {
                A1.push(Array1[i]);
            }
            if (Array2[i] !== '') {
                A2.push(Array2[i]);
            }
        }
        this.props.dispatch(ResetData());
        this.props.dispatch(SetSpinner(true));
        return {
            "eombSequenceNumber": this.props.data.row.eombSequenceNumber,
            "auditUserID": this.props.logInUserID ? this.props.logInUserID : null,
            "auditTimeStamp": DateUtils.getUTCTimeStamp(),
            "addedAuditUserID": this.props.data.row.addedAuditUserID ? this.props.data.row.addedAuditUserID : this.props.logInUserID,
            "addedAuditTimeStamp": this.props.data.row.addedAuditTimeStamp ? this.props.data.row.addedAuditTimeStamp : DateUtils.getUTCTimeStamp(),
            "versionNo": this.state.versionNo,
            "dbRecord": false,
            "sortColumn": this.props.data.row.sortColumn,
            "auditKeyList": [],
            "auditKeyListFiltered": false,
            "eombFromCode": this.state.eombFromCode,
            "eombThruCode": this.state.eombThruCode,
            "eombType": eombType,
            "lob": AppConstants.DEFAULT_LOB_VALUE,
            "eombDesc": this.state.text,
            "noteSet": this.props.data.row.noteSet,
            "fromModifier1": eombType === 'H' ? A1.length < 0 ? null : A1[0] : null,
            "fromModifier2": eombType === 'H' ? A1.length < 0 ? null : A1[1] : null,
            "fromModifier3": eombType === 'H' ? A1.length < 0 ? null : A1[2] : null,
            "fromModifier4": eombType === 'H' ? A1.length < 0 ? null : A1[3] : null,
            "thruModifier1": eombType === 'H' ? A2.length < 0 ? null : A2[0] : null,
            "thruModifier2": eombType === 'H' ? A2.length < 0 ? null : A2[1] : null,
            "thruModifier3": eombType === 'H' ? A2.length < 0 ? null : A2[2] : null,
            "thruModifier4": eombType === 'H' ? A2.length < 0 ? null : A2[3] : null
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data !== this.props.data) {
            this.dropDown = this.props.dropDown
            this.setState({ showDropdown: true })
        }
    }
    handleEombType = event => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        var errorObj = this.state.errorObj
        if (event.target.name === 'eombFromCode') {
            errorObj.eombFromCode = false;
            this.setState({ errorObj: errorObj, eombFromCode: event.target.value.toUpperCase() }, () => {
                this.handleModifiers()
            })
        }
        if (event.target.name === 'eombThruCode') {
            errorObj.eombThruCode = false;
            this.setState({ errorObj: errorObj, eombThruCode: event.target.value.toUpperCase() }, () => {
                this.handleModifiers()
            })
        }
    }
    eombSort = () => {
        let Array1 = [this.state.mod1 !== this.DEFAULT_DD_VALUE ? this.state.mod1 : ''
            , this.state.mod2 !== this.DEFAULT_DD_VALUE ? this.state.mod2 : ''
            , this.state.mod3 !== this.DEFAULT_DD_VALUE ? this.state.mod3 : ''
            , this.state.mod4 !== this.DEFAULT_DD_VALUE ? this.state.mod4 : ''];
        let Array2 = [this.state.modt1 !== this.DEFAULT_DD_VALUE ? this.state.modt1 : ''
            , this.state.modt2 !== this.DEFAULT_DD_VALUE ? this.state.modt2 : ''
            , this.state.modt3 !== this.DEFAULT_DD_VALUE ? this.state.modt3 : ''
            , this.state.modt4 !== this.DEFAULT_DD_VALUE ? this.state.modt4 : ''];
        Array1.sort();
        Array2.sort();
        let A1 = []; let A2 = [];
        for (let i = 0; i < 4; i++) {
            let c1 = 0; let c2 = 0;
            if (Array1[i] !== '') {
                A1.push(Array1[i]);
            }
            if (Array2[i] !== '') {
                A2.push(Array2[i]);
            }
        }
        this.setState({
            mod1: A1.length < 0 ? '' : A1[0],
            mod2: A1.length < 1 ? '' : A1[1],
            mod3: A1.length < 2 ? '' : A1[2],
            mod4: A1.length < 3 ? '' : A1[3],
            modt1: A2.length < 0 ? '' : A2[0],
            modt2: A2.length < 1 ? '' : A2[1],
            modt3: A2.length < 2 ? '' : A2[2],
            modt4: A2.length < 3 ? '' : A2[3]
        });
    }
    handleChanges = name => event => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        var errorObj = this.state.errorObj;
        errorObj.eombLob = false;
        errorObj.text = false;
        this.setState({ eombType: event.target.value, errorObj: errorObj })
    }
    handleModifiers = e => {        
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        if (!this.state.eombFromCode) {
            this.setState({ disableFrom: false })
        } else {
            this.setState({ disableFrom: true })
        }
        if (!this.state.eombThruCode) {
            this.setState({ disableThru: false })
        } else {
            this.setState({ disableThru: true })
        }
    }
    handleField = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        var errorObj = this.state.errorObj;
        let name = e.target.name;
        let value = e.target.value;
        errorObj[name] = false;
        if (name === 'eombFromCode' || name === 'eombThruCode') {
            this.setState({ [name]: value.toUpperCase(), errorObj: errorObj });
        }
        else {
            this.setState({ [name]: value, errorObj: errorObj });
        }
    }
    checkWordCount = e => {
        let value = e.target.value;
        let left_Chars = this.total_characters - value.length;
        this.setState({ wordCount: left_Chars, text: value });
    }
    deleteRecord = () => {
        let eombType = '';
        switch (this.state.eombType) {
            case 'hcpcs':
                eombType = 'H';
                break;
            case 'surgical-procedure':
                eombType = 'I';
                break;
            case 'revenue':
                eombType = 'R';
                break;
            default:
                eombType = 'H';
        }
        this.props.setErrorNull();
        let searchCriteria = {
            "eombSequenceNumber": this.props.data.row.eombSequenceNumber,
            "auditUserID": this.props.logInUserID ? this.props.logInUserID : null,
            "auditTimeStamp": DateUtils.getUTCTimeStamp(),
            "addedAuditUserID": this.props.data.row.addedAuditUserID,
            "addedAuditTimeStamp": this.props.data.row.addedAuditTimeStamp,
            "versionNo": this.props.data.row.versionNo,
            "dbRecord": false,
            "sortColumn": this.props.data.row.sortColumn,
            "auditKeyList": [],
            "auditKeyListFiltered": false,
            "eombFromCode": this.state.eombFromCode,
            "eombThruCode": this.state.eombThruCode,
            "eombType": eombType,
            "lob": AppConstants.DEFAULT_LOB_VALUE,
            "eombDesc": this.state.text,
            "noteSet": this.props.data.row.noteSet,
            "fromModifier1": eombType === 'H' ? this.state.mod1 != this.DEFAULT_DD_VALUE ? this.state.mod1 : null : null,
            "fromModifier2": eombType === 'H' ? this.state.mod2 != this.DEFAULT_DD_VALUE ? this.state.mod2 : null : null,
            "fromModifier3": eombType === 'H' ? this.state.mod3 != this.DEFAULT_DD_VALUE ? this.state.mod3 : null : null,
            "fromModifier4": eombType === 'H' ? this.state.mod4 != this.DEFAULT_DD_VALUE ? this.state.mod4 : null : null,
            "thruModifier1": eombType === 'H' ? this.state.modt1 != this.DEFAULT_DD_VALUE ? this.state.modt1 : null : null,
            "thruModifier2": eombType === 'H' ? this.state.modt2 != this.DEFAULT_DD_VALUE ? this.state.modt2 : null : null,
            "thruModifier3": eombType === 'H' ? this.state.modt3 != this.DEFAULT_DD_VALUE ? this.state.modt3 : null : null,
            "thruModifier4": eombType === 'H' ? this.state.modt4 != this.DEFAULT_DD_VALUE ? this.state.modt4 : null : null
        }
        this.props.dispatch(TextManagementDeleteEOMBAction(searchCriteria));
        this.setState({ openDialog: false }, () => {
            this.props.setRedirect();
        })
    }
    openDeletePopUpDialog = () => {
        this.setState({ openDialog: true });
    };
    closeDeletePopUpDialog = () => {
        this.setState({ openDialog: false });
    };
    renderSwitch = (eombType) => {
        switch (eombType) {
            case 'hcpcs':
                return <div className="w-100">
                    <div className="form-wrapper">
                        <div className="mui-custom-form override-width-19 override-m-05">
                            <TextField
                                id='eombFromCode'
                                fullWidth
                                name='eombFromCode'
                                value={this.state.eombFromCode}
                                onChange={this.handleEombType}
                                label="From Code"

                                required
                                InputProps={{ readOnly: !this.props.userInquiryPrivileges, className: !this.props.userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 10
                                }}
                                helperText={this.state.errorObj.eombFromCode ? this.state.error_msg.eombFromCode : null}
                                error={this.state.errorObj.eombFromCode ? this.state.error_msg.eombFromCode : null}

                            />
                        </div>
                            <NativeDropDown
                                id="mod1"
                                className='override-width-18 override-m-05'
                                label="Modifier 1"
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.disableFrom ? false : true)}
                                name='mod1'
                                value={this.state.mod1}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                dropdownList={this.props.ModifierData && this.props.ModifierData.modifierList ? this.props.ModifierData.modifierList.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )) : null}
                            />
                            <NativeDropDown
                                id="mod2"
                                className='override-width-18 override-m-05'
                                label="Modifier 2"
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.disableFrom ? false : true)}
                                name='mod2'
                                value={this.state.mod2}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                dropdownList={this.props.ModifierData && this.props.ModifierData.modifierList ? this.props.ModifierData.modifierList.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )) : null}
                            />
                            <NativeDropDown
                                id="mod3"
                                className='override-width-18 override-m-05'
                                label="Modifier 3"
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.disableFrom ? false : true)}
                                name='mod3'
                                value={this.state.mod3}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                dropdownList={this.props.ModifierData && this.props.ModifierData.modifierList ? this.props.ModifierData.modifierList.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )) : null}
                            />
                            <NativeDropDown
                                id="mod4"
                                className='override-width-18 override-m-05'
                                label="Modifier 4"
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.disableFrom ? false : true)}
                                name='mod4'
                                value={this.state.mod4}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                dropdownList={this.props.ModifierData && this.props.ModifierData.modifierList ? this.props.ModifierData.modifierList.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )) : null}
                            />
                    </div>
                    <div className="form-wrapper">
                        <div className="mui-custom-form override-width-19 override-m-05">
                            <TextField
                                id="eombThruCode"
                                fullWidth
                                name='eombThruCode'
                                value={this.state.eombThruCode}
                                onChange={this.handleEombType}
                                label="Thru Code"
                                required
                                InputProps={{ readOnly: !this.props.userInquiryPrivileges, className: !this.props.userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 10
                                }}
                                helperText={this.state.errorObj.eombThruCode ? this.state.error_msg.eombThruCode : null}
                                error={this.state.errorObj.eombThruCode ? this.state.error_msg.eombThruCode : null}

                            />
                        </div>
                            <NativeDropDown
                                id="modt1"
                                className='override-width-18 override-m-05'
                                label="Modifier 1"
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.disableThru ? false : true)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name='modt1'
                                value={this.state.modt1}
                                onChange={this.handleField}
                                dropdownList={this.props.ModifierData && this.props.ModifierData.modifierList ? this.props.ModifierData.modifierList.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )) : null}
                        />
                            <NativeDropDown
                                id="modt2"
                                label="Modifier 2"
                                className='override-width-18 override-m-05'
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.disableThru ? false : true)}
                                name='modt2'
                                value={this.state.modt2}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                dropdownList={this.props.ModifierData && this.props.ModifierData.modifierList ? this.props.ModifierData.modifierList.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )) : null}
                        />
                            <NativeDropDown
                                id="modt3"
                                label="Modifier 3"
                                className='override-width-18 override-m-05'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.disableThru ? false : true)}
                                name='modt3'
                                value={this.state.modt3}
                                onChange={this.handleField}
                                dropdownList={this.props.ModifierData && this.props.ModifierData.modifierList ? this.props.ModifierData.modifierList.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )) : null}
                            />
                            <NativeDropDown
                                id="modt4"
                                label="Modifier 4"
                                className='override-width-18 override-m-05'
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.disableThru ? false : true)}
                                name='modt4'
                                value={this.state.modt4}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                dropdownList={this.props.ModifierData && this.props.ModifierData.modifierList ? this.props.ModifierData.modifierList.map(option => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                )) : null}
                            />
                    </div>
                    <div className="row">
                        <div className="mui-custom-form with-textarea override-width-95" >
                            <Form.Group>
                                <Form.Label for="eomb-textarea-hcpcs" className='MuiFormLabel-root small-label'>Text <span>*</span></Form.Label>
                                <Form.Control id="eomb-textarea-hcpcs" as="textarea" rows="4" name='text' value={this.state.text} onChange={this.handleField} onKeyUp={this.checkWordCount} maxLength={this.total_characters} style={{ width: '100%' }} disabled={!this.props.userInquiryPrivileges} />
                                {this.state.errorObj.text > 0 ? <p class="MuiFormHelperText-root Mui-error Mui-required" role="alert" >
                                    {this.state.error_msg.text
                                    }
                                </p> : null
                                }
                            </Form.Group>
                            <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.wordCount}</div>
                        </div>
                    </div>
                </div>
            case 'surgical-procedure':
                return (
                    <div className="w-100">
                        <div className="form-wrapper">
                            <div className="mui-custom-form override-m-1">
                                <TextField
                                    id='eombSPFromCode'
                                    fullWidth
                                    name='eombFromCode'
                                    value={this.state.eombFromCode}
                                    onChange={this.handleField}
                                    label="From Code"
                                    required
                                    InputProps={{ readOnly: !this.props.userInquiryPrivileges, className: !this.props.userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                    helperText={this.state.errorObj.eombFromCode ? this.state.error_msg.eombFromCode : null}
                                    error={this.state.errorObj.eombFromCode ? this.state.error_msg.eombFromCode : null}

                                />
                            </div>
                            <div className="mui-custom-form override-m-1" >
                                <TextField
                                    max={10}
                                    id="eombSPThruCode"
                                    fullWidth
                                    name='eombThruCode'
                                    value={this.state.eombThruCode}
                                    onChange={this.handleField}
                                    required
                                    InputProps={{ readOnly: !this.props.userInquiryPrivileges, className: !this.props.userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                    label="Thru Code"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                    helperText={this.state.errorObj.eombThruCode ? this.state.error_msg.eombThruCode : null}
                                    error={this.state.errorObj.eombThruCode ? this.state.error_msg.eombThruCode : null}

                                />
                            </div>
                        </div>
                        <div className="mui-custom-form with-textarea override-width-95 override-m-1">
                            <Form.Group>
                                <Form.Label for="eomb-textarea-surgical" className='MuiFormLabel-root small-label'>Text <span>*</span></Form.Label>
                                <Form.Control id="eomb-textarea-surgical" as="textarea" rows="4" value={this.state.text} onKeyUp={this.checkWordCount} name='text' onChange={this.handleField} style={{ width: '100%' }} maxLength={this.total_characters} disabled={!this.props.userInquiryPrivileges} />
                                {this.state.errorObj.text > 0 ? <p class="MuiFormHelperText-root Mui-error Mui-required" role="alert" >
                                    {this.state.error_msg.text
                                    }
                                </p> : null
                                }
                            </Form.Group>
                            <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.wordCount}</div>
                        </div>
                    </div>
                )
            case 'revenue':
                return (
                    <div className="w-100">
                        <div className="form-wrapper">
                            <div className="mui-custom-form override-m-1">
                                <TextField
                                    id='eombRFromCode'
                                    fullWidth
                                    name='eombFromCode'
                                    value={this.state.eombFromCode}
                                    onChange={this.handleField}
                                    label="From Code"
                                    InputProps={{ readOnly: !this.props.userInquiryPrivileges, className: !this.props.userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                    required
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                    helperText={this.state.errorObj.eombFromCode ? this.state.error_msg.eombFromCode : null}
                                    error={this.state.errorObj.eombFromCode ? this.state.error_msg.eombFromCode : null}

                                />
                            </div>
                            <div className="mui-custom-form override-m-1" >
                                <TextField
                                    id="eombRThruCode"
                                    fullWidth
                                    name='eombThruCode'
                                    value={this.state.eombThruCode}
                                    onChange={this.handleField}
                                    required
                                    InputProps={{ readOnly: !this.props.userInquiryPrivileges, className: !this.props.userInquiryPrivileges ? 'Mui-disabled' : '' }}
                                    label="Thru Code"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        maxLength: 10
                                    }}
                                    helperText={this.state.errorObj.eombThruCode ? this.state.error_msg.eombThruCode : null}
                                    error={this.state.errorObj.eombThruCode ? this.state.error_msg.eombThruCode : null}

                                />
                            </div>
                        </div>
                        <div className="mui-custom-form with-textarea override-width-95 override-m-1">
                            <Form.Group>
                                <Form.Label for="eomb-textarea-revenue" className='MuiFormLabel-root small-label'>Text <span>*</span></Form.Label>
                                <Form.Control id="eomb-textarea-revenue" as="textarea" rows="4" value={this.state.text} name='text' onChange={this.handleField} maxLength={this.total_characters} onKeyUp={this.checkWordCount} style={{ width: '100%' }} disabled={!this.props.userInquiryPrivileges} />
                                {this.state.errorObj.text > 0 ? <p class="MuiFormHelperText-root Mui-error Mui-required" role="alert" >
                                    {this.state.error_msg.text
                                    }
                                </p> : null
                                }
                            </Form.Group>
                            <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.wordCount}</div>
                        </div>
                    </div>)
            default:
                return null;
        }
    }
    render() {
        return (
            <form className="w-100">
                <div className="text-align-right">
                    {this.props.userInquiryPrivileges ?
                        <a style={{ textDecorationLine: 'underline', color: 'blue', cursor: 'pointer' }} onClick={this.openDeletePopUpDialog} >Delete Text</a> :
                        <a style={{ textDecorationLine: 'underline', cursor: 'pointer' }} >Delete Text</a>}

                </div>
                <div className="form-wrapper">
                    {this.state.eombType === 'hcpcs' ? <div className="mui-custom-form with-select override-width-40 override-m-1" //style={{ marginLeft: '146px' }}
                    >
                        <label className='MuiFormLabel-root small-label' >EOMB Type<span>*</span></label>
                        <div className='sub-radio' style={{ paddingTop: '5px', marginLeft: '0px' }}>
                            <Radio
                                id="eomb-type-hcpcs"
                                value="hcpcs"
                                name="eombType"
                                disabled={!this.props.userInquiryPrivileges}
                                checked={this.state.eombType === 'hcpcs'}
                                onChange={this.handleChanges('selectedOption')}
                                style={{ marginLeft: '0px' }}
                            /><label for="eomb-type-hcpcs" className="text-black" style={{ marginLeft: '-2px' }}> HCPCS</label>
                            <Radio
                                id="eomb-type-surgical"
                                value="surgical-procedure"
                                className="ml-2"
                                name="eombType"
                                checked={this.state.eombType === 'surgical-procedure'}
                                disabled={!this.props.userInquiryPrivileges}
                                defaultChecked={this.props.data.row.eombType === 'I' ? true : false}
                                onChange={this.handleChanges('selectedOption')}
                            /><label for="eomb-type-surgical" className="text-black"> Surgical Procedure</label>
                            <Radio
                                id="eomb-type-revenue"
                                value="revenue"
                                className="ml-2"
                                name="eombType"
                                checked={this.state.eombType === 'revenue'}
                                disabled={!this.props.userInquiryPrivileges}
                                defaultChecked={this.props.data.row.eombType === 'R' ? true : false}
                                onChange={this.handleChanges('selectedOption')}
                            /><label for="eomb-type-revenue" className="text-black"> Revenue</label>
                        </div>
                    </div> :
                        <div className="mui-custom-form with-select override-width-40 override-m-1" //style={{ marginLeft: '20px' }}
                        >
                            <label className='MuiFormLabel-root small-label' >EOMB Type<span>*</span></label>
                            <div className='sub-radio' style={{ paddingTop: '5px', marginLeft: '0px' }}>
                                <Radio
                                    id="eombtype-hcpcs"
                                    defaultChecked={this.props.data.row.eombType === 'H' ? true : false}
                                    value="hcpcs"
                                    name="eombType"
                                    disabled={!this.props.userInquiryPrivileges}
                                    checked={this.state.eombType === 'hcpcs'}
                                    onChange={this.handleChanges('selectedOption')}
                                    style={{ marginLeft: '0px' }}
                                /><label for="eombtype-hcpcs" className="text-black"> HCPCS</label>
                                <Radio
                                    id="eombtype-surgical"
                                    value="surgical-procedure"
                                    className="ml-2"
                                    checked={this.state.eombType === 'surgical-procedure'}
                                    name="eombType"
                                    disabled={!this.props.userInquiryPrivileges}
                                    defaultChecked={this.props.data.row.eombType === 'I' ? true : false}
                                    onChange={this.handleChanges('selectedOption')}
                                /><label for="eombtype-surgical" className="text-black"> Surgical Procedure</label>
                                <Radio
                                    id="eombtype-revenue"
                                    value="revenue"
                                    className="ml-2"
                                    name="eombType"
                                    checked={this.state.eombType === 'revenue'}
                                    disabled={!this.props.userInquiryPrivileges}
                                    defaultChecked={this.props.data.row.eombType === 'R' ? true : false}
                                    onChange={this.handleChanges('selectedOption')}
                                /><label for="eombtype-revenue" className="text-black"> Revenue</label>
                            </div>
                        </div>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    {this.renderSwitch(this.state.eombType)}
                </div>
                <Dialog className="custom-dialog dialog-500 with-padding" fullWidth={true} maxWidth={'sm'} open={this.state.openDialog} disableBackdropClick>
                    <DialogContent dividers>
                        <div>Are you sure you want to Delete the Record?</div>
                    </DialogContent>

                    <DialogActions>
                        <Button className="btn btn-primary ml-1" onClick={() => this.deleteRecord()} disabled={!this.props.userInquiryPrivileges} >
                            OK
                </Button>
                        <Button className="btn btn-primary ml-1" onClick={this.closeDeletePopUpDialog} disabled={!this.props.userInquiryPrivileges}>
                            Cancel
                </Button>
                    </DialogActions>
                </Dialog>
            </form>
        )
    }

}

class RAEOB_ extends React.Component {
    constructor(props) {
        super(props);
        this.total_characters = 320;
        this.DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
        this.state = {
            raeobLob: props.data.lobCode,
            eobCode: props.data.claimEOBCode,
            text: props.data.eobDesc ? props.data.eobDesc : null,
            wordCount: props.data.eobDesc ? (this.total_characters - props.data.eobDesc.length) : this.total_characters,
            versionNo: props.data.versionNo,
            openDialog: false,
            dialogOpen: false,
            errorObj: {
                raeobLob: false,
                eobCode: false,
                text: false
            },
            error_msg: {
                raeobLob: '',
                eobCode: '',
                text: ''
            }
        }
        this.dropDown = (props.dropDown != undefined && props.dropDown != null) ? props.dropDown : null
    }
    resetFieldError = () => {
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        errorObj.raeobLob = false;
        errorObj.eobCode = false;
        errorObj.text = false;
        error_msg.raeobLob = "";
        error_msg.eobCode = "";
        error_msg.text = ""
        this.setState({ errorObj: errorObj, error_msg: error_msg });
    }
    getRecord = () => {
        this.props.dispatch(RaeobGetRecordAction(
            {
                "eobText": this.state.text,
                "lobCode": [AppConstants.DEFAULT_LOB_VALUE],
                "claimEOBCode": this.state.eobCode,
            }
        ))
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.newRecord && nextProps.newRecord[0] && (nextProps.newRecord[0].versionNo !== this.state.versionNo)) {
            this.setState({ versionNo: nextProps.newRecord[0].versionNo });
        }
    }
    searchCheck = () => {
        let errorMessagesArray = [];
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        if (!this.state.eobCode || (this.state.eobCode && !this.state.eobCode.trim())) {
            errorObj.eobCode = true;
            error_msg.eobCode = "EOB Code" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.eobCode);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
            errorObj.text = true;
            error_msg.text = "Text" + (TextManagementErrors.Required_Field_Error);
            errorMessagesArray.push(error_msg.text);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        return errorMessagesArray;
    }
    onSave = e => {
        this.props.dispatch(ResetData());
        this.props.dispatch(SetSpinner(true));
        return {
            "auditUserID": this.props.logInUserID ? this.props.logInUserID : null,
            "auditTimeStamp": DateUtils.getUTCTimeStamp(),
            "addedAuditUserID": this.props.data.addedAuditUserID ? this.props.data.addedAuditUserID : this.props.logInUserID,
            "addedAuditTimeStamp": this.props.data.addedAuditTimeStamp ? this.props.data.addedAuditTimeStamp : DateUtils.getUTCTimeStamp(),
            "versionNo": this.state.versionNo,
            "dbRecord": this.props.data.dbRecord,
            "sortColumn": this.props.data.sortColumn,
            "auditKeyList": [],
            "auditKeyListFiltered": false,
            "claimEOBCode": this.state.eobCode,
            "checkClmExc": this.props.data.checkClmExc,
            "eobDesc": this.state.text,
            "eobType": this.props.data.eobType,
            "eobBeginDate": this.props.data.eobBeginDate,
            "eobEndDate": this.props.data.eobEndDate,
            "lobCode": AppConstants.DEFAULT_LOB_VALUE,
            "noteSet": this.props.data.noteSet
        }
    }
    checkWordCount = e => {
        let value = e.target.value;
        let left_Chars = this.total_characters - value.length;
        this.setState({ wordCount: left_Chars, text: value });
    }
    handleField = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let value = e.target.value;
        this.setState({ [e.target.name]: value });
    }
    deleteRecord = () => {
        this.props.setErrorNull();
        let searchCriteria = {
            "auditUserID": this.props.logInUserID ? this.props.logInUserID : null,
            "auditTimeStamp": DateUtils.getUTCTimeStamp(),
            "addedAuditUserID": "C5104832",
            "addedAuditTimeStamp": this.props.data.addedAuditTimeStamp,
            "versionNo": this.props.data.versionNo,
            "dbRecord": this.props.data.dbRecord,
            "sortColumn": this.props.data.sortColumn,
            "auditKeyList": [],
            "auditKeyListFiltered": false,
            "claimEOBCode": this.state.eobCode,
            "checkClmExc": this.props.data.checkClmExc,
            "eobDesc": this.state.text,
            "eobType": this.props.data.eobType,
            "eobBeginDate": this.props.data.eobBeginDate,
            "eobEndDate": this.props.data.eobEndDate,
            "lobCode": AppConstants.DEFAULT_LOB_VALUE,
            "noteSet": this.props.data.noteSet
        }
        this.props.dispatch(TextManagementDeleteRAEOBAction(searchCriteria));
        this.setState({ openDialog: false }, () => {
            this.props.setRedirect();
        })
    }
    openDeletePopUpDialog = () => {
        this.setState({ openDialog: true });
    };
    closeDeletePopUpDialog = () => {
        this.setState({ openDialog: false });
    };
    render() {
        return (
            <div style={{ width: '100%' }}>
                <div className="text-align-right">
                    {this.props.userInquiryPrivileges ?
                        <a style={{ textDecorationLine: 'underline', color: 'blue', cursor: 'pointer' }} onClick={this.openDeletePopUpDialog}>Delete Text</a> :
                        <a style={{ textDecorationLine: 'underline', cursor: 'pointer' }}>Delete Text</a>
                    }

                </div>
                <form autoComplete="off" style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-evenly' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                        <div className="mui-custom-form input-sm">
                            <TextField
                                required
                                id="eobCode"
                                label="EOB Code"
                                name='eobCode'
                                InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                                value={this.state.eobCode}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 6
                                }}
                                helperText={this.state.errorObj.eobCode ? this.state.error_msg.eobCode : null}
                                error={this.state.errorObj.eobCode ? this.state.error_msg.eobCode : null}
                            >
                            </TextField>
                        </div>
                    </div>
                    <div className="mui-custom-form with-textarea" style={{ width: '95%' }}>
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label for="raeob-textarea" className='MuiFormLabel-root small-label'>Text <span>*</span></Form.Label>
                            <Form.Control id="raeob-textarea" as="textarea" rows="4" maxLength={this.total_characters} onKeyUp={this.checkWordCount} onChange={this.handleField} name='text' value={this.state.text} style={{ width: '100%', overflowWrap: ' break-word', overflow: 'hidden' }} disabled={!this.props.userInquiryPrivileges} />
                            {this.state.errorObj.text > 0 ? <p class="MuiFormHelperText-root Mui-error Mui-required" role="alert" >
                                {this.state.error_msg.text
                                }
                            </p> : null
                            }
                        </Form.Group>
                        <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.wordCount}</div>
                    </div>
                </form>
                <Dialog className="custom-dialog dialog-500 with-padding" fullWidth={true} maxWidth={'sm'} open={this.state.openDialog} disableBackdropClick>
                    <DialogContent dividers>
                        <div>Are you sure you want to Delete the Record?</div>
                    </DialogContent>

                    <DialogActions>
                        <Button className="btn btn-primary ml-1" onClick={() => this.deleteRecord()} disabled={!this.props.userInquiryPrivileges} >
                            OK
                </Button>
                        <Button className="btn btn-primary ml-1" onClick={this.closeDeletePopUpDialog} disabled={!this.props.userInquiryPrivileges}>
                            Cancel
                </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

class ProviderNotice_ extends React.Component {
    constructor(props) {
        super(props);
        this.DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
        this.total_characters = 4000;
        this.state = {
            pn_lob: props.data.lobCode,
            provider_type: props.data.providerType ? props.data.providerType : this.DEFAULT_DD_VALUE,
            provider_specialty: props.data.providerSpeciality ? props.data.providerSpeciality : this.DEFAULT_DD_VALUE,
            begin_date: props.data.beginDate,
            end_date: props.data.endDate,
            editorData: props.data.providerNoticeText,
            text: props.data.providerNoticeText,
            void_value: props.data.providerVoid !== 'false' && props.data.providerVoid !== false ? true : false,
            provider_specialty_value: null,
            provider_type_value: null,
            word_count: props.data.providerNoticeText ? (this.total_characters - props.data.providerNoticeText.replace(/<\/?[^>]+(>|$)/g, '').length) : this.total_characters,
            setShow: true,
            providerNoticeSk: (props.data && props.data.textRALetterSK) ? props.data.textRALetterSK : '',
            providerSpecialtyDropdown: [],
            versionNo: props.data.versionNo,
            disableVoid: props.data.providerVoid !== 'false' && props.data.providerVoid !== false ? true : false,
            errorObj: {
                pn_lob: false,
                begin_date: false,
                end_date: false,
                text: false
            },
            error_msg: {
                pn_lob: '',
                begin_date: '',
                end_date: '',
                text: ''
            }
        }
        this.dropDown = (props.dropDown != undefined && props.dropDown != null) ? props.dropDown : null
        this.editorData = this.editorData.bind(this);
    }
    resetFieldError = () => {
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        errorObj.pn_lob = false;
        errorObj.begin_date = false;
        errorObj.end_date = false;
        errorObj.text = false;
        error_msg.pn_lob = false;
        error_msg.begin_date = false;
        error_msg.end_date = false;
        error_msg.text = false;
        this.setState({ errorObj: errorObj, error_msg: error_msg });
    }
    setErrObject() {
        var errorObj = this.state.errorObj;
        errorObj.pn_lob = false;
        errorObj.begin_date = false;
        errorObj.end_date = false;
        errorObj.text = false;
        this.setState({ errorObj: errorObj });
    }

    componentDidMount() {
        if (this.props.data.providerType !== null) {
            let data = { code: this.props.data.providerType, description: this.props.data.providerNoticeTypeDesc };
            this.props.getProviderSpecialty(data);
        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.newRecord && nextProps.newRecord[0] && (nextProps.newRecord[0].versionNo !== this.state.versionNo)) {
            this.setState({ versionNo: nextProps.newRecord[0].versionNo, providerNoticeSk: nextProps.newRecord[0].textRALetterSK });

        }
        if (nextProps && nextProps.newRecord && nextProps.newRecord[0] && (nextProps.newRecord[0].providerVoid === true || nextProps.newRecord[0].providerVoid === 'true')) {
            this.setState({ disableVoid: true });
        }
        if (nextProps.ProviderSpecialtyData) {
            this.setState({ providerSpecialtyDropdown: nextProps.ProviderSpecialtyData }, () => {
            });
        } else {
            if (nextProps.functionalDropDown && nextProps.functionalDropDown.listObj && nextProps.functionalDropDown.listObj['Provider#1054']) {
                this.setState({ providerSpecialtyDropdown: nextProps.functionalDropDown.listObj['Provider#1054'] });
            }
        }
    }
    searchCheck = () => {
        let errorMessagesArray = [];
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        var beginDateErr = false; var endDateErr = false; var textErr = false; var lobErr = false;
        if (!this.state.begin_date) {
            beginDateErr = true;
            error_msg.begin_date = "Begin Date" + TextManagementErrors.Required_Field_Error
            errorMessagesArray.push("Begin Date" + TextManagementErrors.Required_Field_Error);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (this.state.begin_date.toString() === 'Invalid Date') {
            beginDateErr = true;
            error_msg.begin_date = TextManagementErrors.Invalid_Format_Error_BeginDate;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (DateUtils.validateDateMinimumValue(new Date(this.state.begin_date))) {
            beginDateErr = true;
            error_msg.begin_date = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (!this.state.end_date) {
            endDateErr = true;
            error_msg.end_date = "End Date" + TextManagementErrors.Required_Field_Error
            errorMessagesArray.push("End Date" + TextManagementErrors.Required_Field_Error);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (this.state.end_date.toString() === 'Invalid Date') {
            endDateErr = true;
            error_msg.end_date = TextManagementErrors.Invalid_Format_Error_EndDate;
            errorMessagesArray.push(error_msg.end_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (DateUtils.validateDateMinimumValue(new Date(this.state.end_date))) {
            endDateErr = true;
            error_msg.end_date = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(error_msg.end_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if ((!this.state.disableVoid) && this.state.end_date && DateUtils.compareTwoDatesGreaterThanOrEqual(new Date(this.state.end_date), new Date())) {
            endDateErr = true;
            error_msg.end_date = TextManagementErrors.PROVIDER_NOTICE_TEXT_END_DATE_ERROR;
            errorMessagesArray.push(error_msg.end_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (this.state.end_date && this.state.begin_date &&
            !endDateErr && !beginDateErr &&
            dateCheck(new Date(this.state.begin_date), new Date(this.state.end_date)) === 1) {
            beginDateErr = true;
            error_msg.begin_date = TextManagementErrors.Begin_End_Date_Error;
            errorMessagesArray.push(TextManagementErrors.Begin_End_Date_Error);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if ((this.state.text && !this.state.text.replace(/<\/?[^>]+(>|$)/g, '').trim()) || !this.state.text) {
            textErr = true;
            error_msg.text = "Text" + (TextManagementErrors.Required_Field_Error);
            errorMessagesArray.push(error_msg.text);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        errorObj.begin_date = beginDateErr;
        errorObj.end_date = endDateErr;
        errorObj.text = textErr;
        errorObj.pn_lob = lobErr;
        this.setState({ errorObj: errorObj });
        return errorMessagesArray;
    }
    getRecord = () => {
        this.props.dispatch(providerNoticeGetRecordAction(
            {
                providerTextSk: this.state.providerNoticeSk
            }
        ))
    }
    onSave = e => {
        let providertype = null;
        let providerSpeciality = null
        if (this.state.provider_type !== null && this.state.provider_type !== this.DEFAULT_DD_VALUE) {
            providertype = this.props.functionalDropDown.listObj['Provider#11'].filter(
                data => data.code === this.state.provider_type
            )
        }
        if (this.state.provider_specialty !== null && this.state.provider_specialty !== this.DEFAULT_DD_VALUE && this.props.ProviderSpecialtyData) {
            providerSpeciality = this.props.ProviderSpecialtyData.filter(
                data => this.state.provider_specialty === data.code
            )
        }
        this.props.dispatch(ResetData());
        this.props.dispatch(SetSpinner(true));
        return {
            "auditUserID": this.props.logInUserID ? this.props.logInUserID : null,
            "auditTimeStamp": DateUtils.getUTCTimeStamp(),
            "addedAuditUserID": this.props.data.addedAuditUserID ? this.props.data.addedAuditUserID : this.props.logInUserID,
            "addedAuditTimeStamp": this.props.data.addedAuditTimeStamp ? this.props.data.addedAuditTimeStamp : DateUtils.getUTCTimeStamp(),
            "versionNo": this.state.versionNo,
            "dbRecord": this.props.data.dbRecord,
            "sortColumn": this.props.data.sortColumn,
            "auditKeyList": [],
            'voidNoteInd': this.state.disableVoid ? this.state.disableVoid : false,
            "auditKeyListFiltered": this.props.data.auditKeyListFiltered,
            "beginDate": moment(this.state.begin_date).format('MM/DD/YYYY'),
            "endDate": moment(this.state.end_date).format('MM/DD/YYYY'),
            "voidDate": this.state.disableVoid ? this.props.data.voidDate : moment(new Date()).format('MM/DD/YYYY'),
            "textRALetterSK": this.props.data.textRALetterSK ? this.props.data.textRALetterSK : '50000902',
            "providerNoticeText": this.state.text ? this.state.text : this.state.editorData,
            "providerNoticeTypeDesc": this.state.provider_type !== this.DEFAULT_DD_VALUE ? providertype && providertype[0] ? providertype[0].description : null : null,
            "providerNoticeSpecialityDesc": this.state.provider_specialty !== this.DEFAULT_DD_VALUE ? providerSpeciality && providerSpeciality[0] ? providerSpeciality[0].description : null : null,
            "providerSpeciality": this.state.provider_specialty !== this.DEFAULT_DD_VALUE ? providerSpeciality && providerSpeciality[0] ? providerSpeciality[0].code : null : null,
            "providerType": this.state.provider_type != this.DEFAULT_DD_VALUE ? providertype && providertype[0] ? providertype[0].code : null : null,
            "lobCode": AppConstants.DEFAULT_LOB_VALUE,
            "noteSet": this.props.data.noteSet,
            "disableVoidFlag": this.props.data.disableVoidFlag,
            "providerVoid": this.props.data.providerVoid !== true ? this.state.void_value ? true : false : true,
            "inputtaxtFlag": this.props.data.inputtaxtFlag
        }
    }
    handleChanges = name => event => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        this.setState({ void_value: event.target.value });
    }
    handleChange = () => {
        this.props.dispatch(setFocus(true)); 
    }
    getProviderSpecialty = (value) => {
        this.setState({ setShow: false });
        let providertype = this.props.functionalDropDown.listObj['Provider#11'].filter(
            data => data.code === value
        )
        this.setState({ provider_specialty: this.DEFAULT_DD_VALUE });
        this.props.getProviderSpecialty(providertype[0]);
        setTimeout(
            function () {
                this.setState({ setShow: true });
            }
                .bind(this),
            2500
        );
    }
    handleField = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let value = e.target.value;
        let name = e.target.name;

        let errorObj = this.state.errorObj;
        errorObj[name] = false;
        if (name === 'provider_type') {
            if (value !== this.DEFAULT_DD_VALUE) {
                this.getProviderSpecialty(value)
            } else {
                this.setState({ provider_specialty: this.DEFAULT_DD_VALUE })
            }
        }
        this.setState({ [name]: value, errorObj: errorObj })
    }
    handleDate = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let errorObj = this.state.errorObj;
        errorObj.begin_date = false;
        this.setState({ begin_date: e, errorObj: errorObj })
    }
    handleEndDate = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let errorObj = this.state.errorObj;
        errorObj.end_date = false;
        this.setState({ end_date: e, errorObj: errorObj })
    }
    editorData = (data, editorData , onLoad) => {
        let errorObj = this.state.errorObj;
        errorObj.text = false;
        this.setState({ text: editorData, errorObj: errorObj }, () => {
            let value = data;
            let left_Chars = this.total_characters - value.length;
            this.setState({ word_count: left_Chars, text: editorData });
            if (onLoad){
                this.props.setAllowNavigation();
            }
        })
    }

    render() {
        const { classes } = this.props
        const providerType = this.props?.functionalDropDown?.listObj['Provider#11'].find(value => value.code === this.state?.provider_type);
        const providerSpcltyType = this.state?.providerSpecialtyDropdown.find(value => value.code === this.state?.provider_specialty);
        const lobCode = this.props?.functionalDropDown?.listObj['Reference#1019'].find(value => value.code === this.state?.pn_lob);

        return (
            <div className="row">
                <form autoComplete="off" style={{ padding: '0px 5px' }} className="override-width-100">
                    <div className="form-wrapper">
                        <NativeDropDown
                                id="provider_type"
                                label="Provider Type"
                                name='provider_type'
                                className='override-width-18 override-m-2'
                                value={providerType !== undefined ? this.state.provider_type : this.DEFAULT_DD_VALUE}
                                onChange={this.handleField}
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (((this.props.data.providerVoid !== 'false' && this.props.data.providerVoid !== false) || this.state.disableVoid) ? true : false)}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                dropdownList={(this.props.functionalDropDown && this.props.functionalDropDown.listObj && this.props.functionalDropDown.listObj['Provider#11']) ? this.props.functionalDropDown.listObj['Provider#11'].map((item, index) => (
                                    <option key={index} value={item.code}>
                                        {item.description}
                                    </option>
                                )) : null}
                            />
                            <NativeDropDown
                                id="provider_specialty"
                                label="Provider Specialty"
                                className='override-width-18 override-m-2'
                                disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (this.state.provider_type === this.DEFAULT_DD_VALUE || (this.props.data.providerVoid !== 'false' && this.props.data.providerVoid !== false) || this.state.disableVoid ? true : false)}
                                name='provider_specialty'
                                value={providerSpcltyType !== undefined ? this.state.provider_specialty : this.DEFAULT_DD_VALUE}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                dropdownList={this.state.setShow ? this.state.providerSpecialtyDropdown && this.state.providerSpecialtyDropdown.length ? this.state.providerSpecialtyDropdown.map((item, index) => (
                                    <option key={index} value={item.code}>
                                        {item.description}
                                    </option>
                                )) : null : null}
                            />
                        <div className="mui-custom-form with-textarea input-sm" >
                            <div style={{}}>
                                <label className='MuiFormLabel-root small-label'>Void</label>
                                <div className='sub-radio'>
                                    <Radio
                                        id="selected-option-yes"
                                        value={true}
                                        name="void_value"
                                        disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (((this.props.data.providerVoid !== 'false' && this.props.data.providerVoid !== false) || this.state.disableVoid) ? true : false)}
                                        onChange={this.handleChanges('selectedOption')}
                                        style={{ marginLeft: '0px' }}
                                        checked={(this.state.void_value === "true" || this.state.void_value === true)}
                                    /><label for="selected-option-yes" className="text-black"> Yes</label>
                                    <Radio
                                        id="selected-option-no"
                                        value={false}
                                        disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (((this.props.data.providerVoid !== 'false' && this.props.data.providerVoid !== false) || this.state.disableVoid) ? true : false)}
                                        className="ml-2"
                                        name="void_value"
                                        checked={(this.state.void_value === "false" || this.state.void_value === false)}
                                        onChange={this.handleChanges('selectedOption')}
                                    /><label for="selected-option-no" className="text-black"> No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-wrapper">
                        <div className="mui-custom-form with-date override-width-18 override-m-2">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                    id="begin_date"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    required
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder='mm/dd/yyyy'
                                    name='begin_date'
                                    value={this.state.begin_date}
                                    onChange={this.handleDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (((this.props.data.providerVoid !== 'false' && this.props.data.providerVoid !== false) || this.state.disableVoid) ? true : false)}
                                    helperText={this.state.errorObj.begin_date ? this.state.error_msg.begin_date : null}
                                    error={this.state.errorObj.begin_date ? this.state.error_msg.begin_date : null}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="mui-custom-form with-date override-width-18 override-m-2" >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                    id="end_date"
                                    label="End Date"
                                    format="MM/dd/yyyy"
                                    placeholder='mm/dd/yyyy'
                                    required
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    name='end_date'
                                    value={this.state.end_date}
                                    onChange={this.handleEndDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    disabled={!this.props.userInquiryPrivileges ? !this.props.userInquiryPrivileges : (((this.props.data.providerVoid !== 'false' && this.props.data.providerVoid !== false) || this.state.disableVoid) ? true : false)}
                                    helperText={this.state.errorObj.end_date ? this.state.error_msg.end_date : null}
                                    error={this.state.errorObj.end_date ? this.state.error_msg.end_date : null}
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                    </div >
                    <div className="form-wrapper">
                        <div className="mui-custom-form with-textarea override-width-100" >
                            <label className='MuiFormLabel-root small-label' id="text-01-label">Text<span>*</span></label>
                            <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem' }}>
                                <TextEditor ariaDescribedBy="text-01-label" total_characters={this.total_characters}
                                onChange={this.handleChange}
                                 editorData={this.editorData} editorState={this.state.editorData} showVoid={this.state.disableVoid} />
                            </div>
                            {this.state.errorObj.text > 0 ? <p class="MuiFormHelperText-root Mui-error Mui-required" role="alert" >
                                {this.state.error_msg.text
                                }
                            </p> : null
                            }
                        </div>

                        <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', marginLeft: '27px', marginBottom: '10px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.word_count}</div>
                    </div>
                </form>
            </div>
        )
    }
}

class RemarkCode_ extends React.Component {
    constructor(props) {
        super(props);
        this.total_characters = 4000;
        this.state = {
            remark_code: props.data.remarkCode,
            begin_date: props.data.beginDate,
            termination_date: props.data.terminationDate,
            claim_exception: 'no',
            versionNo: props.data.versionNo,
            text: props.data.remarkText,
            word_count: props.data.remarkText ? this.total_characters - props.data.remarkText.length : this.total_characters,
            errorObj: {
                remark_code: false,
                begin_date: false,
                termination_date: false,
                text: false
            },
            error_msg: {
                remark_code: '',
                begin_date: '',
                termination_date: '',
                text: ''
            }
        }
    }
    resetFieldError = () => {
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        errorObj.remark_code = false;
        errorObj.begin_date = false;
        errorObj.text = false;
        errorObj.termination_date = false;
        error_msg.termination_date = '';
        error_msg.remark_code = '';
        error_msg.begin_date = '';
        error_msg.text = '';
        this.setState({ errorObj: errorObj, error_msg: error_msg });
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.newRecord && nextProps.newRecord[0] && (nextProps.newRecord[0].versionNo !== this.state.versionNo)) {
            this.setState({ versionNo: nextProps.newRecord[0].versionNo });
        }
    }
    getRecord = () => {
        this.props.dispatch(RemarkTextGetRecordAction(
            {
                "remarkCode": this.state.remark_code,
                "remarkText": this.state.text,
            }
        ))
    }
    setErrObject() {
        var errorObj = this.state.errorObj;
        errorObj.remark_code = false;
        errorObj.begin_date = false;
        errorObj.termination_date = false;
        errorObj.text = false;
        this.setState({ errorObj: errorObj });
    }
    searchCheck = () => {
        let errorMessagesArray = [];
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        var beginDateErr = false; var endDateErr = false; var remarkCodeErr = false; var textErr = false;
        if ((this.state.remark_code && !this.state.remark_code.trim()) || !this.state.remark_code) {
            remarkCodeErr = true;
            error_msg.remark_code = "Remark Code" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.remark_code);
            this.setState({ errorObj: errorObj }, () => {
            })
        }
        if (!this.state.begin_date) {
            beginDateErr = true;
            error_msg.begin_date = "Begin Date" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (this.state.begin_date.toString() === 'Invalid Date') {
            beginDateErr = true;
            error_msg.begin_date = TextManagementErrors.Invalid_Format_Error_BeginDate;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (DateUtils.validateDateMinimumValue(new Date(this.state.begin_date))) {
            beginDateErr = true;
            error_msg.begin_date = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (!this.state.termination_date) {
            endDateErr = true;
            error_msg.termination_date = "Termination Date" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg })
        }
        else if (this.state.termination_date.toString() == 'Invalid Date') {
            endDateErr = true;
            error_msg.termination_date = TextManagementErrors.Invalid_Format_Error_TerminationDate;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (DateUtils.validateDateMinimumValue(new Date(this.state.termination_date))) {
            endDateErr = true;
            error_msg.termination_date = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (this.state.termination_date && this.state.begin_date &&
            !endDateErr && !beginDateErr &&
            dateCheck(new Date(this.state.begin_date), new Date(this.state.termination_date)) === 1) {
            beginDateErr = true;
            error_msg.begin_date = TextManagementErrors.Begin_End_Date_Error;
            errorMessagesArray.push(TextManagementErrors.Begin_End_Date_Error);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
            textErr = true;
            error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push("Text" + TextManagementErrors.Required_Field_Error);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        errorObj.begin_date = beginDateErr;
        errorObj.termination_date = endDateErr;
        errorObj.remark_code = remarkCodeErr;
        errorObj.text = textErr;
        this.setState({ errorObj: errorObj });
        return errorMessagesArray;
    }
    onSave = e => {
        this.props.dispatch(ResetData());
        this.props.dispatch(SetSpinner(true));
        return {
            "auditUserID": this.props.logInUserID ? this.props.logInUserID : null,
            "auditTimeStamp": DateUtils.getUTCTimeStamp(),
            "addedAuditUserID": this.props.data.addedAuditUserID ? this.props.data.addedAuditUserID : this.props.logInUserID,
            "addedAuditTimeStamp": this.props.data.addedAuditTimeStamp ? this.props.data.addedAuditTimeStamp : DateUtils.getUTCTimeStamp(),
            "versionNo": this.state.versionNo,
            "dbRecord": this.props.data.dbRecord,
            "sortColumn": this.props.data.sortColumn,
            "auditKeyList": [],
            "auditKeyListFiltered": this.props.data.auditKeyListFiltered,
            "beginDate": moment(this.state.begin_date).format('MM/DD/YYYY'),
            "remarkCode": this.props.data.remarkCode,
            "remarkText": this.state.text,
            "checkClmExc": this.props.data.checkClmExc,
            "terminationDate": moment(this.state.termination_date).format('MM/DD/YYYY'),
            "tempBeginDate": this.props.data.tempBeginDate,
            "tempTermDate": this.props.data.tempTermDate,
            "remarkCodeSeqNo": this.props.data.remarkCodeSeqNo
        }
    }
    checkWordCount = e => {
        let value = e.target.value;
        value = value.split(' ').join(' ')
        this.setState({ word_count: this.total_characters - value.length });
    }
    handleField = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let value = e.target.value;
        let name = e.target.name;
        this.setState({ [name]: value });
    }
    handleDate = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let errorObj = this.state.errorObj;
        errorObj.begin_date = false;
        this.setState({ begin_date: e, errorObj: errorObj })
    }
    handleEndDate = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let errorObj = this.state.errorObj;
        errorObj.termination_date = false;
        this.setState({ termination_date: e, errorObj: errorObj })
    }
    render() {
        return (
            <div className="w-100">
                <div className="row">
                    <div style={{ display: 'flex' }} className="override-width-100">
                        <div className="mui-custom-form input-sm">
                            <TextField
                                id="remark_code"
                                label="Remark Code"
                                required
                                InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                                name='remarkCode'
                                value={this.state.remark_code}
                                onChange={this.handleField}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                helperText={this.state.errorObj.remark_code ? this.state.error_msg.remark_code : null}
                                error={this.state.errorObj.remark_code ? this.state.error_msg.remark_code : null}
                            />
                        </div>
                        <div className="mui-custom-form with-date">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                    id="begin_date"
                                    label="Begin Date"
                                    format="MM/dd/yyyy"
                                    required
                                    disabled={!this.props.userInquiryPrivileges}
                                    placeholder='mm/dd/yyyy'
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    name='begin_date'
                                    value={this.state.begin_date}
                                    onChange={this.handleDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    helperText={this.state.errorObj.begin_date ? this.state.error_msg.begin_date : null}
                                    error={this.state.errorObj.begin_date ? this.state.error_msg.begin_date : null}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="mui-custom-form with-date">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                    id="termination_date"
                                    label="Termination Date"
                                    format="MM/dd/yyyy"
                                    placeholder="mm/dd/yyyy"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    required
                                    disabled={!this.props.userInquiryPrivileges}
                                    name='termination_date'
                                    value={this.state.termination_date}
                                    onChange={this.handleEndDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    helperText={this.state.errorObj.termination_date ? this.state.error_msg.termination_date : null}
                                    error={this.state.errorObj.termination_date ? this.state.error_msg.termination_date : null}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                </div>
                <div className="mui-custom-form with-textarea" style={{ width: '95%' }}>
                    <Form.Group>
                        <Form.Label for="remarkcode-textarea" className='MuiFormLabel-root small-label'>Text <span>*</span></Form.Label>
                        <Form.Control id="remarkcode-textarea" as="textarea" value={this.state.text} onChange={this.handleField} name='text' rows="4" maxLength={this.total_characters} onKeyUp={this.checkWordCount} style={{ width: '95%' }} disabled={!this.props.userInquiryPrivileges} />
                        {this.state.errorObj.text > 0 ? <p class="MuiFormHelperText-root Mui-error Mui-required" role="alert" >
                            {this.state.error_msg.text
                            }
                        </p> : null
                        }
                    </Form.Group>
                    <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.word_count}</div>
                </div>
            </div >
        )
    }
}

class AdjustmentReason_ extends React.Component {
    constructor(props) {
        super(props);
        this.total_characters = 4000;
        this.state = {
            adjustment_reason_code: props.data.adjustmentReasonCode,
            begin_date: props.data.beginDate,
            termination_date: props.data.terminationDate,
            text: props.data.adjustmentReasonText,
            word_count: props.data.adjustmentReasonText ? this.total_characters - props.data.adjustmentReasonText.length : this.total_characters,
            versionNo: props.data.versionNo,
            errorObj: {
                adjustment_reason_code: false,
                begin_date: false,
                termination_date: false,
                text: false
            },
            error_msg: {
                adjustment_reason_code: '',
                begin_date: "",
                termination_date: '',
                text: ""
            }
        }
    }
    resetFieldError = () => {
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        errorObj.adjustment_reason_code = false;
        errorObj.begin_date = false;
        errorObj.text = false;
        errorObj.termination_date = false;
        error_msg.termination_date = '';
        error_msg.adjustment_reason_code = '';
        error_msg.begin_date = '';
        error_msg.text = '';
        this.setState({ errorObj: errorObj, error_msg: error_msg });
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.newRecord && nextProps.newRecord[0] && (nextProps.newRecord[0].versionNo !== this.state.versionNo)) {
            this.setState({ versionNo: nextProps.newRecord[0].versionNo });
        }
    }
    getRecord = () => {
        this.props.dispatch(AdjustmentReasonRecordAction(
            {
                "adjustmentReasonCode": this.state.adjustment_reason_code,
                "adjustmentReasonText": this.state.text,
            }
        ))
    }
    setErrObject() {
        var errorObj = this.state.errorObj;
        errorObj.adjustment_reason_code = false;
        errorObj.begin_date = false;
        errorObj.termination_date = false;
        errorObj.text = false;
        this.setState({ errorObj: errorObj });
    }

    searchCheck = () => {
        let errorMessagesArray = [];
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        var beginDateErr = false;
        var endDateErr = false;
        var adjustmentCodeErr = false;
        var textErr = false;
        if ((this.state.adjustment_reason_code && !this.state.adjustment_reason_code.trim()) && !this.state.adjustment_reason_code) {
            adjustmentCodeErr = true;
            error_msg.adjustment_reason_code = "Adjustment Reason code" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.adjustment_reason_code);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (!this.state.begin_date) {
            beginDateErr = true;
            error_msg.begin_date = "Begin Date" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (this.state.begin_date.toString() === 'Invalid Date') {
            beginDateErr = true;
            error_msg.begin_date = TextManagementErrors.Invalid_Format_Error_BeginDate;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (DateUtils.validateDateMinimumValue(new Date(this.state.begin_date))) {
            beginDateErr = true;
            error_msg.begin_date = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (!this.state.termination_date) {
            endDateErr = true;
            error_msg.termination_date = "Termination Date" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg })
        }
        else if (this.state.termination_date.toString() === 'Invalid Date') {
            endDateErr = true;
            error_msg.termination_date = TextManagementErrors.Invalid_Format_Error_TerminationDate;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })

        }
        else if (DateUtils.validateDateMinimumValue(new Date(this.state.termination_date))) {
            endDateErr = true;
            error_msg.termination_date = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (this.state.termination_date && this.state.begin_date &&
            !endDateErr && !beginDateErr &&
            dateCheck(new Date(this.state.begin_date), new Date(this.state.termination_date)) === 1) {
            beginDateErr = true;
            error_msg.begin_date = TextManagementErrors.Begin_End_Date_Error;
            errorMessagesArray.push(TextManagementErrors.Begin_End_Date_Error);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
            textErr = true;
            error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.text);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        errorObj.begin_date = beginDateErr;
        errorObj.termination_date = endDateErr;
        errorObj.adjustment_reason_code = adjustmentCodeErr;
        errorObj.text = textErr;
        this.setState({ errorObj: errorObj });
        return errorMessagesArray;
    }
    onSave = e => {
        this.props.dispatch(ResetData());
        this.props.dispatch(SetSpinner(true));
        return {
            "auditUserID": this.props.logInUserID ? this.props.logInUserID : null,
            "auditTimeStamp": DateUtils.getUTCTimeStamp(),
            "addedAuditUserID": this.props.data.addedAuditUserID ? this.props.data.addedAuditUserID : this.props.logInUserID,
            "addedAuditTimeStamp": this.props.data.addedAuditTimeStamp ? this.props.data.addedAuditTimeStamp : DateUtils.getUTCTimeStamp(),
            "versionNo": this.state.versionNo,
            "dbRecord": this.props.data.dbRecord,
            "sortColumn": this.props.data.sortColumn,
            "auditKeyList": [],
            "auditKeyListFiltered": this.props.data.auditKeyListFiltered,
            "adjustmentReasonCode": this.props.data.adjustmentReasonCode,
            "adjustmentReasonText": this.state.text,
            "noteSet": this.props.data.noteSet,
            "checkClmExc": this.props.data.checkClmExc,
            "terminationDate": moment(this.state.termination_date).format('MM/DD/YYYY'),
            "beginDate": moment(this.state.begin_date).format('MM/DD/YYYY'),
            "adjstResnSeqNo": this.props.data.adjstResnSeqNo
        }
    }
    checkWordCount = e => {
        let value = e.target.value;
        value = value.split(' ').join(' ')
        this.setState({ word_count: this.total_characters - value.length });
    }
    handleField = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let value = e.target.value;
        let name = e.target.name;
        this.setState({ [name]: value });
    }
    handleDate = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let errorObj = this.state.errorObj;
        errorObj.begin_date = false;
        this.setState({ begin_date: e, errorObj: errorObj })
    }
    handleEndDate = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let errorObj = this.state.errorObj;
        errorObj.termination_date = false;
        this.setState({ termination_date: e, errorObj: errorObj })
    }
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div className="mui-custom-form input-md">
                        <TextField
                            id="adjustment_reason_code"
                            label="Adjustment Reason Code"
                            name='adjustment_reason_code'
                            value={this.state.adjustment_reason_code}
                            onChange={this.handleField}
                            required
                            InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: 5
                            }}
                            helperText={this.state.errorObj.adjustment_reason_code ? this.state.error_msg.adjustment_reason_code : null}
                            error={this.state.errorObj.adjustment_reason_code ? this.state.error_msg.adjustment_reason_code : null}
                        />
                    </div>
                    <div className="mui-custom-form with-date">

                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                id="begin_date"
                                label="Begin Date"
                                format="MM/dd/yyyy"
                                placeholder='mm/dd/yyyy'
                                name='begin_date'
                                required
                                disabled={!this.props.userInquiryPrivileges}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                value={this.state.begin_date}
                                onChange={this.handleDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                helperText={this.state.errorObj.begin_date ? this.state.error_msg.begin_date : null}
                                error={this.state.errorObj.begin_date ? this.state.error_msg.begin_date : null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="mui-custom-form with-date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                id="termination_date"
                                label="Termination Date"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                required
                                disabled={!this.props.userInquiryPrivileges}
                                name='termination_date'
                                value={this.state.termination_date}
                                onChange={this.handleEndDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                helperText={this.state.errorObj.termination_date ? this.state.error_msg.termination_date : null}
                                error={this.state.errorObj.termination_date ? this.state.error_msg.termination_date : null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="mui-custom-form with-textarea" style={{ width: '95%' }}>
                    <Form.Group>
                        <Form.Label for="adjreason-textarea" className='MuiFormLabel-root small-label'>Text <span>*</span></Form.Label>
                        <Form.Control id="adjreason-textarea" as="textarea" onChange={this.handleField} name='text' value={this.state.text} rows="4" maxLength={this.total_characters} onKeyUp={this.checkWordCount} style={{ width: '95%' }} disabled={!this.props.userInquiryPrivileges} />
                        {this.state.errorObj.text > 0 ? <p class="MuiFormHelperText-root Mui-error Mui-required" role="alert" >
                            {this.state.error_msg.text
                            }
                        </p> : null
                        }
                    </Form.Group>
                    <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.word_count}</div>
                </div>
            </div>
        )
    }
}

class NCPDP_ extends React.Component {
    constructor(props) {
        super(props);
        this.total_characters = 4000;
        this.state = {
            ncpdp_code: props.data.ncpdpRejectCode,
            begin_date: props.data.beginDate,
            termination_date: props.data.terminationDate,
            claim_exception: 'no',
            text: props.data.ncpdpRejectCodeText,
            word_count: props.data.ncpdpRejectCodeText ? this.total_characters - props.data.ncpdpRejectCodeText.length : this.total_characters,
            versionNo: props.data.versionNo,
            errorObj: {
                ncpdp_code: false,
                begin_date: false,
                text: false,
                termination_date: false
            },
            error_msg: {
                ncpdp_code: '',
                begin_date: "",
                text: '',
                termination_date: ''
            }
        }
    }
    resetFieldError = () => {
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        errorObj.ncpdp_code = false;
        errorObj.begin_date = false;
        errorObj.text = false;
        errorObj.termination_date = false;
        error_msg.termination_date = '';
        error_msg.ncpdp_code = '';
        error_msg.begin_date = '';
        error_msg.text = '';
        this.setState({ errorObj: errorObj, error_msg: error_msg });
    }
    setErrObject() {
        var errorObj = this.state.errorObj;
        errorObj.ncpdp_code = false;
        errorObj.begin_date = false;
        errorObj.termination_date = false;
        errorObj.text = false;
        this.setState({ errorObj: errorObj });
    }

    searchCheck = () => {
        let errorMessagesArray = [];
        var errorObj = this.state.errorObj;
        var error_msg = this.state.error_msg;
        var beginDateErr = false;
        var endDateErr = false;
        var textErr = false;
        var ncpdpCodeErr = false;
        if ((this.state.ncpdp_code && !this.state.ncpdp_code.trim()) || !this.state.ncpdp_code) {
            ncpdpCodeErr = true;
            error_msg.ncpdp_code = "NCPDP Reject Code" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.ncpdp_code);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (!this.state.begin_date) {
            beginDateErr = true;
            error_msg.begin_date = "Begin Date" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        else if (this.state.begin_date.toString() === 'Invalid Date') {
            beginDateErr = true;
            error_msg.begin_date = TextManagementErrors.Invalid_Format_Error_BeginDate;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })

        }
        else if (DateUtils.validateDateMinimumValue(new Date(this.state.begin_date))) {
            beginDateErr = true;
            error_msg.begin_date = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(error_msg.begin_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (!this.state.termination_date) {
            endDateErr = true;
            error_msg.termination_date = "Termination Date" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg })
        }
        else if (this.state.termination_date.toString() === 'Invalid Date') {
            endDateErr = true;
            error_msg.termination_date = TextManagementErrors.Invalid_Format_Error_TerminationDate;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })

        }
        else if (DateUtils.validateDateMinimumValue(new Date(this.state.termination_date))) {
            endDateErr = true;
            error_msg.termination_date = AppConstants.DATE_ERROR_1964;
            errorMessagesArray.push(error_msg.termination_date);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if (this.state.termination_date && this.state.begin_date &&
            !endDateErr && !beginDateErr &&
            dateCheck(new Date(this.state.begin_date), new Date(this.state.termination_date)) === 1) {
            beginDateErr = true;
            error_msg.begin_date = TextManagementErrors.Begin_End_Date_Error;
            errorMessagesArray.push(TextManagementErrors.Begin_End_Date_Error);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        if ((this.state.text && !this.state.text.trim()) || !this.state.text) {
            errorObj.text = true;
            error_msg.text = "Text" + TextManagementErrors.Required_Field_Error;
            errorMessagesArray.push(error_msg.text);
            this.setState({ errorObj: errorObj, error_msg: error_msg }, () => {
            })
        }
        errorObj.begin_date = beginDateErr;
        errorObj.termination_date = endDateErr;
        errorObj.ncpdp_code = ncpdpCodeErr;
        this.setState({ errorObj: errorObj });
        return errorMessagesArray;
    }
    getRecord = () => {
        this.props.dispatch(NCPDPGetRecordAction(
            {
                "ncpdpRejectCode": this.state.ncpdp_code,
                "ncpdpRejectCodeText": this.state.text,
            }
        ))
        }
    onSave = e => {
        this.props.dispatch(ResetData());
        this.props.dispatch(SetSpinner(true));
        return {
            "auditUserID": this.props.logInUserID ? this.props.logInUserID : null,
            "auditTimeStamp": DateUtils.getUTCTimeStamp(),
            "addedAuditUserID": this.props.data.addedAuditUserID ? this.props.data.addedAuditUserID : this.props.logInUserID,
            "addedAuditTimeStamp": this.props.data.addedAuditTimeStamp ? this.props.data.addedAuditTimeStamp : DateUtils.getUTCTimeStamp(),
            "versionNo": this.state.versionNo,
            "dbRecord": this.props.data.dbRecord,
            "sortColumn": this.props.data.sortColumn,
            "auditKeyList": [],
            "auditKeyListFiltered": this.props.data.auditKeyListFiltered,
            "ncpdpRejectCode": this.props.data.ncpdpRejectCode,
            "beginDate": moment(this.state.begin_date).format('MM/DD/YYYY'),
            "terminationDate": moment(this.state.termination_date).format('MM/DD/YYYY'),
            "claimException": this.props.data.claimException,
            "ncpdpRejectCodeText": this.state.text,
            "ncpdpRejectCodeSeqNo": this.props.data.ncpdpRejectCodeSeqNo
        }
    }
    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.newRecord && nextProps.newRecord[0] && (nextProps.newRecord[0].versionNo !== this.state.versionNo)) {
            this.setState({ versionNo: nextProps.newRecord[0].versionNo });
        }
    }
    checkWordCount = e => {
        let value = e.target.value;
        value = value.split(' ').join(' ')
        this.setState({ word_count: this.total_characters - value.length });
    }
    handleField = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let value = e.target.value;
        let name = e.target.name;
        let errorObj = this.state.errorObj;
        errorObj[name] = false;
        this.setState({ [name]: value, errorObj: errorObj });
    }
    handleDate = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let errorObj = this.state.errorObj;
        errorObj.begin_date = false;
        this.setState({ begin_date: e, errorObj: errorObj })
    }
    handleEndDate = e => {
        this.props.dispatch(setFocus(true));
        this.props.setAllowNavigation();
        let errorObj = this.state.errorObj;
        errorObj.termination_date = false;
        this.setState({ termination_date: e, errorObj: errorObj })
    }
    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                    <div className="mui-custom-form input-sm6">
                        <TextField
                            id="ncpdp_code"
                            label="NCPDP Reject Code"
                            name='ncpdp_code'
                            InputProps={{ readOnly: true, className: 'Mui-disabled' }}
                            value={this.state.ncpdp_code}
                            onChange={this.handleField}
                            required
                            InputLabelProps={{
                                shrink: true
                            }}
                            inputProps={{
                                maxLength: 3
                            }}
                            helperText={this.state.errorObj.adjustment_reason_code ? this.state.error_msg.adjustment_reason_code : null}
                            error={this.state.errorObj.adjustment_reason_code ? this.state.error_msg.adjustment_reason_code : null}
                        />
                    </div>
                    <div className="mui-custom-form with-date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                id="begin_date"
                                label="Begin Date"
                                format="MM/dd/yyyy"
                                placeholder='mm/dd/yyyy'
                                InputLabelProps={{
                                    shrink: true
                                }}
                                name='begin_date'
                                value={this.state.begin_date}
                                onChange={this.handleDate}
                                required
                                disabled={!this.props.userInquiryPrivileges}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                helperText={this.state.errorObj.begin_date ? this.state.error_msg.begin_date : null}
                                error={this.state.errorObj.begin_date ? this.state.error_msg.begin_date : null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="mui-custom-form with-date">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                maxDate={Date.parse('31 Dec 9999 00:00:00 GMT')}
                                id="termination_date"
                                label="Termination Date"
                                format="MM/dd/yyyy"
                                placeholder="mm/dd/yyyy"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                required
                                disabled={!this.props.userInquiryPrivileges}
                                name='termination_date'
                                value={this.state.termination_date}
                                onChange={this.handleEndDate}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                helperText={this.state.errorObj.termination_date ? this.state.error_msg.termination_date : null}
                                error={this.state.errorObj.termination_date ? this.state.error_msg.termination_date : null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="mui-custom-form with-textarea" style={{ width: '95%', marginTop: '20px' }}>
                    <Form.Group>
                        <Form.Label for="ncpdp-textarea" className='MuiFormLabel-root small-label'>NCPDP Reject Code Text <span>*</span></Form.Label>
                        <Form.Control id="ncpdp-textarea" as="textarea" onChange={this.handleField} name='text' value={this.state.text} rows="4" maxLength={this.total_characters} onKeyUp={this.checkWordCount} style={{ width: '95%' }} disabled={!this.props.userInquiryPrivileges} />
                        {this.state.errorObj.text > 0 ? <p class="MuiFormHelperText-root Mui-error Mui-required" role="alert" >
                            {this.state.error_msg.text
                            }
                        </p> : null
                        }
                    </Form.Group>
                    <div style={{ border: '1px solid rgb(169, 169, 169)', borderRadius: '.25rem', paddingLeft: '5px', width: '200px', alignText: 'center' }}>Characters Remaining:{this.state.word_count}</div>
                </div>
            </div>
        )
    }
}

TextUpdate.propTypes = {
    classes: PropTypes.object.isRequired,
};



export const NCPDP = connect(mapStateToProps, null, null, { forwardRef: true })(NCPDP_);
export const AdjustmentReason = connect(mapStateToProps, null, null, { forwardRef: true })(AdjustmentReason_);
export const RemarkCode = connect(mapStateToProps, null, null, { forwardRef: true })(RemarkCode_);
export const RAEOB = connect(mapStateToProps, null, null, { forwardRef: true })(RAEOB_);
export const ProviderNotice = connect(mapStateToProps, null, null, { forwardRef: true })(ProviderNotice_);
export const EOMB = connect(mapStateToProps, null, null, { forwardRef: true })(EOMB_);
export const TextUpdate_ = connect(mapStateToProps, null, null, { forwardRef: true })(TextUpdate);
export default NavHOC({
    Wrapped: TextUpdate_,
    url: '/TextUpdate',
    action: systemParameterRowClickAction,
    selector: "appConfigState.textManagementState.payload",
    actionKey: 'textUpdate'
});
