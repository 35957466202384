/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import TableComponent from "../../../SharedModules/Table/Table";
import moment from "moment";
import { searchLockBoxDetailsAction } from "../Store/Actions/BankAccountAndLockBoxSearchActions";
import { DEFAULT_LOB_VALUE } from "../../../SharedModules/AppConstants";

const headCellsMultiRecord = [
  {
    id: "accountNumber",
    numeric: false,
    disablePadding: false,
    label: "Account #",
    enableHyperLink: true,
    isDate: false,
    width: "20%",
  },
  {
    id: "beginDate",
    numeric: false,
    disablePadding: false,
    label: "Begin Date",
    enableHyperLink: false,
    isDate: true,
    width: "20%",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
    enableHyperLink: false,
    isDate: true,
    width: "20%",
  },
  {
    id: "lockBoxNumber",
    numeric: false,
    disablePadding: false,
    label: "LockBox #",
    enableHyperLink: false,
    isDate: false,
    width: "20%",
  },
  {
    id: "lockBoxName",
    numeric: false,
    disablePadding: false,
    label: "LockBox Name",
    enableHyperLink: false,
    isDate: false,
    width: "20%",
    isVarChar: true,
  },
];
const headCellsSingleRecord = [
  {
    id: "accountNumber",
    numeric: false,
    disablePadding: false,
    label: "Account #",
    enableHyperLink: true,
    isDate: false,
    width: "20%",
  },
  {
    id: "beginDate",
    numeric: false,
    disablePadding: false,
    label: "Begin Date",
    enableHyperLink: false,
    isDate: true,
    width: "20%",
  },
  {
    id: "endDate",
    numeric: false,
    disablePadding: false,
    label: "End Date",
    enableHyperLink: false,
    isDate: true,
    width: "18%",
  },
  {
    id: "lockBoxNumber",
    numeric: false,
    disablePadding: false,
    label: "LockBox #",
    enableHyperLink: false,
    isDate: false,
    width: "20%",
  },
  {
    id: "lockBoxName",
    numeric: false,
    disablePadding: false,
    label: "LockBox Name",
    enableHyperLink: false,
    isDate: false,
    width: "20%",
    isVarChar: true,
  },
];

function BankAccountAndLockBoxTable(props) {
  const [redirect, setRedirect] = React.useState(0);
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [showTable, setShowTable] = React.useState(false);
  const [payload, setPayload] = React.useState([]);
  const payloadViewData = props.tableData; // load this data from axios
  // Spinner Functionality
  useEffect(() => {
    if (
      payloadViewData != null &&
      payloadViewData &&
      payloadViewData.length === 0
    ) {
      setShowTable(false);
    }
    if (
      payloadViewData != null &&
      payloadViewData &&
      payloadViewData.length === 1
    ) {
      if (!props.location.tableRender) {
        setShowTable(false);
        props.history.push({
          pathname: "/BankAccountLockBoxEdit",
          state: { payload: payloadViewData, values: props.values },
        });
      } else {
        setShowTable(true);
      }
    }
    if (
      payloadViewData != null &&
      payloadViewData &&
      payloadViewData.length > 1
    ) {
      setShowTable(true);
    }
    setspinnerLoader(false);
  }, [payloadViewData]);
  const dispatch = useDispatch();
  const onRowClick = (values) => dispatch(searchLockBoxDetailsAction(values));
  let payloadData = useSelector(
    (state) => state.bankAccountState.rowGetBankAccount
  );
  useEffect(() => {
    payloadData = null;
  }, []);
  // Spinner Functionality
  useEffect(() => {
    if (redirect === 1) {
      if (payloadData) {
        props.history.push({
          pathname: "/BankAccountLockBoxEdit",
          state: { payloadData, values: props.values },
        });
      }
    }
  }, [payloadData]);

  const formatSearchCriteria = (_row) => {
    return {
      lob: [DEFAULT_LOB_VALUE],
      bankRoutingNumber: _row.bankRoutingNumber,
      bankRoutingNumberStartsWith: false,
      bankName: null,
      bankNameStartsWithOrContains: null,
      bankAccountNumber: _row.accountNumber,
      accountNumberStartsWith: false,
      accountName: null,
      accountNameStartsWithOrContains: null,
      lockBoxNumber: _row.lockBoxNumber,
      lockBoxNumberStartsWith: false,
      lockBoxName: _row.lockBoxName,
      lockBoxNameStartsWithOrContains: null,
    };
  };

  const editRow = (values) => (event) => {
    props.location.tableRender = false;
  const searchCriteria = formatSearchCriteria(values);
    onRowClick(searchCriteria);
    setspinnerLoader(true);
    setRedirect(1);
  };
  const headCells = props.tableData && props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord
  const displayTable = (
    <div className="tab-holder">
      <TableComponent
        print={props.print}
        pathTo="/BankAccountLockBoxEdit?data="
        formatSearchCriteria={formatSearchCriteria}
        fixedTable
        isSearch={true}
        headCells={headCells}
        tableData={props.tableData ? props.tableData : []}
        onTableRowClick={editRow}
        spinnerLoader={spinnerLoader}
        defaultSortColumn={headCells[1].id}
        order={"asc"}
      />
    </div>
  );
  return showTable ? displayTable : null;
}

export default withRouter(BankAccountAndLockBoxTable);
