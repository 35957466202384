/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import TableComponent from '../../../../../SharedModules/Table/Table';

const headCells = [
    {
        id: 'receivableFCN',
        numeric: false,
        disablePadding: true,
        label: 'FCN',
        width: '20%',
        enableHyperLink: true
    },
    {
        id: 'reasonCode',
        numeric: false,
        disablePadding: false,
        label: 'Original A/R Reason Code',
        width: '18%',
        isTooltip: true,
        isTitle: 'resonCodeLongDesc'
    },
    {
        id: 'establishedDateStr',
        numeric: false,
        disablePadding: false,
        label: 'Established Date',
        width: '18%',
        isDate: true
    },
    {
        id: 'originalAmount',
        numeric: false,
        disablePadding: false,
        label: 'Original A/R Amount',
        width: '18%'
    },
    {
        id: 'balanceAmount',
        numeric: false,
        disablePadding: false,
        label: 'Balance Amount',
        width: '18%'
    },
    {
        id: 'transferreasonCode',
        numeric: false,
        disablePadding: false,
        isSelectBox: true,
        label: 'Transfer Reason Code',
        width: '28%',
        dropDownData: []
    }
];
const tableData = [{
    listNumber: '1010',
    description: '200',
    listType: '200',
    dataElementName: '122',
    functionalAreaDesc: '233',
    reasonCode: ''
}]
function BulkTransferTable(props) {
    const { setspinnerLoader } = props;
    const formatSearchCriteria = _row => ({
        listNumber: _row.listNumber,
        functionalArea: _row.functionalArea,
        dataElementName: _row.dataElementName,
        description: _row.description,
        listType: _row.listType,
        listBusinessName: _row.listBusinessName,
        funcAreaDesc: _row.funcAreaDesc
    });

    const editRow = row => event => {
        const payloadData = formatSearchCriteria(row);
        props.history.push({
            pathname: '/ARBulkTransfer',
            state: { payloadData }
        });
    };
    const [headCellsState, setHeadCells] = React.useState(headCells);
    React.useEffect(() => {
        if (props.reasonCodeDropDown) {
            headCells[6].dropDownData = props.reasonCodeDropDown
        }
    }, [props.reasonCodeDropDown])
    const tableComp = (
        <TableComponent
            print={ props.print }
            pathTo="/ARBulkTransfer?data="
            formatSearchCriteria={ formatSearchCriteria }
            fixedTable
            fixedView
            headCells={ headCells }
            isSearch={ true }
            dropDownData={ props.reasonCodeDropdown }
            handleDropDown={ props.handleTableReasonCode }
            tableData={ props.tableData ? props.tableData : [] }
            onTableRowClick={ editRow }
            defaultSortColumn={ headCells[0].id }
        />
    );
    return tableComp;
}
export default withRouter(BulkTransferTable);
