/* eslint-disable no-unused-vars */
/**

* © [2024] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React from "react";
import Spinner from "../../SharedModules/Spinner/Spinner";

const Iframe = ({ source }) => {
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  
  const src = source;
 
  if (!source) {
    return <div>Loading...</div>;
  }
  return (
    <div> 
      {spinnerLoader ? <Spinner /> : null}
      <div>
      <iframe
        src={src}
        style={{ width: "100%", height: "500px", scrollBehavior: "auto", border: "0" }}
      ></iframe>
   </div>
    </div>
  );
};
export default Iframe;
