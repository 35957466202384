import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import SearchForm from "./SearchForm";
import { Button } from "react-bootstrap";
import Spinner from "../../../../SharedModules/Spinner/Spinner";
import SearchTableComponent from "./SearchTableComponent";
import { resetSearchCriteria, GlobalAuditSearchAction } from "../actions";
import * as ErrorConst from "../../../../SharedModules/Messages/ErrorMsgConstants";
import Footer from "../../../../SharedModules/Layout/footer";

function GlobalAuditSearch(props) {
  let errorMessagesArray = [];
  const dispatch = useDispatch();
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [showNoRecords, setShowNoRecords] = useState(false);
  const [
    {
      beginDateInvalidErr,
      endDateInvalidErr,
      beginDtGtEndDtErr,
      beginTimeErr,
      endTimeErr,
      userIDError,
    },
    setShowError,
  ] = React.useState(false);
  const payload = useSelector(
    (state) => state.GlobalAuditSearch.auditSearchData
  );

  const fieldPayload = useSelector(
    (state) => state.GlobalAuditSearch.fieldValues
  );
  const [showTable, setShowTable] = useState(false);
  const onSearch = (searchvalues) => {
    return dispatch(GlobalAuditSearchAction(searchvalues));
  };
  const onReset = () => dispatch(resetSearchCriteria());
  const [errorMessages, seterrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const printRef = useRef();

  const defaultObj = {
    userID: null,
    lastName: null,
    beginDate: null,
    fullName:null,
    endDate: null,
    beginTime: "00:00:00 AM",
    endTime: "11:59:59 PM",
    tableName: null,
    fieldName: '',
    keyType: null,
    keyValue: null,
  };

  const [values, setValues] = useState(defaultObj);

  // values change function
  const handleChanges = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };
  const handleDCDtChange = (name, date) => {
    setValues({ ...values, [name]: date });
  };
  useEffect(() => {
    if (payload != null && payload.responseAuditLogDetails.length > 0) {
      setspinnerLoader(false);
      setShowTable(true);
      setShowNoRecords(false);
      setValues(fieldPayload);
      if (payload.responseAuditLogDetails.length === 1 && redirect) {
        props.history.push({
          pathname: `/GlobalAuditSearch`,
        });
      }
    } else {
      setShowNoRecords(true);
      setspinnerLoader(false);
    }
  }, [payload]);

  const resetTable = () => {
    setShowNoRecords(false);
    seterrorMessages([]);
    setShowError(false);

    setValues(defaultObj);
    setShowTable(false);
    onReset();
  };
  const searchCheck = () => {
    setShowTable(false);
    setspinnerLoader(false);
    errorMessagesArray = [];
    seterrorMessages([]);
    setShowError(false);
    let userIDError;
    let tableFieldKeyTypeError;
    let beginDateInvalidErr;
    let endDateInvalidErr;
    let beginDtGtEndDtErr;
    let beginTimeErr;
    let endTimeErr;
    if (
      !values.userID  &&
      !values.lastName &&
      !values.tableName &&
      !values.fieldName &&
      !values.keyType &&
      !values.keyValue
    ) {
      userIDError = true;
      errorMessagesArray.push(ErrorConst.GLOBAL_AUDIT_SEARCH_ERR);
    }
    if ((values.tableName && !values.fieldName) || (!values.tableName && values.fieldName) || (values.keyType && !values.keyValue) || (!values.keyType && values.keyValue)) {
      tableFieldKeyTypeError = true;
      errorMessagesArray.push(ErrorConst.GLOBAL_AUDIT_SEARCH_ERR);
    }
    if (
      values.beginDate != null &&
      values.beginDate.toString() === "Invalid Date"
    ) {
      beginDateInvalidErr = true;
      errorMessagesArray.push(ErrorConst.GLOBAL_DATE_INVALID);
    }
    if (
      values.endDate != null &&
      values.endDate.toString() === "Invalid Date"
    ) {
      endDateInvalidErr = true;
      errorMessagesArray.push(ErrorConst.GLOBAL_DATE_INVALID);
    }
    if (new Date(values.beginDate) > new Date(values.endDate)) {
      beginDtGtEndDtErr = true;
      errorMessagesArray.push(ErrorConst.EVENT_GT_DT_ERROR);
    }
    let regexp =
      /(?:[0-1][0-9]|[2][1-4]):[0-5][0-9]:[0-5][0-9]\s([A]|[P])([M])?/;

    if (values.beginTime !== "" && !regexp.test(values.beginTime)) {
      beginTimeErr = true;
      errorMessagesArray.push(ErrorConst.BEGIN_TIME_INVALID);
    }

    if (values.endTime !== "" && !regexp.test(values.endTime)) {
      endTimeErr = true;
      errorMessagesArray.push(ErrorConst.END_TIME_INVALID);
    }
    if (errorMessagesArray.length === 0) {
      setShowTable(true);
      setspinnerLoader(true);
      let searchCriteria = {};
      searchCriteria = {
        userID: values.userID === "" ? null : values.userID,
        beginDate: values.beginDate === "" ? null : values.beginDate,
        endDate: values.endDate === "" ? null : values.endDate,
        beginTime: values.beginTime === "" ? null : values.beginTime,
        endTime: values.endTime === "" ? null : values.endTime,
        tableName: values.tableName === "" ? null : values.tableName,
        fieldName: values.fieldName === "" ? null : values.fieldName,
        keyType: values.keyType === "" ? null : values.keyType,
        keyValue: values.keyValue === "" ? null : values.keyValue,
      };
      onSearch(searchCriteria);
      setRedirect(true);
    } else {
      seterrorMessages(errorMessagesArray);
      setShowTable(false);
      setShowError({
        userIDError,
        beginDateInvalidErr,
        endDateInvalidErr,
        beginDtGtEndDtErr,
        beginTimeErr,
        endTimeErr,
      });
    }
  };

  return (
    <div className="pos-relative custom-page">
      {spinnerLoader ? <Spinner setspinnerLoader={setspinnerLoader} /> : null}
      {errorMessages.length > 0 ? (
        <div
          className="alert alert-danger custom-alert"
          role="alert"
        >
          {errorMessages.map((message) => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      {errorMessages.length === 0 &&
      payload &&
      ((payload.data && payload.data.searchResults == null) ||
        payload.data == null) &&
      showNoRecords ? (
        <div className="alert alert-danger custom-alert" role="alert">
          <li>{ErrorConst.NO_RECORDS_WITHSEARCH}</li>
        </div>
      ) : null}
      {successMessages.length > 0 ? (
        <div className="alert alert-success custom-alert" role="alert">
          {successMessages.map((message) => (
            <li>{message}</li>
          ))}
        </div>
      ) : null}
      <div className="tabs-container" ref={printRef}>
        <div className="page-header">
          <h1 className="page-heading float-left">Search Filters</h1>
          <div className="clearfix" />
        </div>

        <div className="tab-body pb-2">
          <SearchForm
            values={values}
            handleChanges={handleChanges}
            handleDCDtChange={handleDCDtChange}
            searchCheck={searchCheck}
            errors={{
              userIDError,
              beginDateInvalidErr,
              beginDtGtEndDtErr,
              endDateInvalidErr,
              beginTimeErr,
              endTimeErr,
            }}
            seterrorMessages={seterrorMessages}
            resetTable={resetTable}
          />
          {showTable &&
          payload &&
          payload.responseAuditLogDetails.length > 0 ? (
            <div className="container-space">
              <div className="search-result">Search Results</div>
              <SearchTableComponent
                tableData={payload.responseAuditLogDetails}
                setspinnerLoader={setspinnerLoader}
                values={values}
                setRedirect={setRedirect}
                redirect={redirect}
          
              />
            </div>
          ) : null}
          <Footer print />
        </div>
      </div>
    </div>
  );
}
export default withRouter(GlobalAuditSearch);
