/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { withRouter } from 'react-router';
import EnhancedTableHead from './EnhancedTableHead';
import TablePaginationActions from './TablePagination';
import { desc, stableSort, getSorting } from './TableSort';
import Bootstrap, { Button } from 'react-bootstrap';
import Spinner from '../../SharedModules/Spinner/Spinner';
import * as AppConstants from '../../SharedModules/AppConstants';
import Moment from 'react-moment';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../SharedModules/services/service';
import numeral from 'numeral';
import moment from 'moment';
import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { generateUUID, getDateInYYYYMMDDtoMMDDYYYY } from '../DateUtilities/DateUtilities';
import { redirectGrossLevelFCN, redirectReceiptFCN, redirectPayoutFCN } from '../TabRedirections/TabRedirections';
import { resetPDFReviewError,downloadPDFPreview } from '../../SharedModules/store/Actions/SharedAction';
import { Link } from 'react-router-dom';
import dashboard from '../Dashboard/dashboard';
import { getLongDescription } from '../CommonUtilities/commonUtilities';
import NativeDropDown from '../Dropdowns/NativeDropDown';
function TableComponent (props) {
  const { setDeleteDisable } = props;
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
    const dispatch = useDispatch();
  const [order, setOrder] = React.useState(props.order ? props.order : 'asc');
  const [orderBy, setOrderBy] = React.useState(props.defaultSortColumn);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [varCharFlag, setvarCharFlag] = React.useState(false);
  
  const [showHeaderCheckBox, setShowHeaderCheckBox] = React.useState(false);
  const [disableHeaderCheckBox, setDisableHeaderCheckBox] = React.useState(false);
  
  useEffect(() => {
    setPage(page);
    if(selected.length>0){
      if(props.tableData?.filter(row=> selected.indexOf(row.id)>-1).length == 0) {
        setSelected([]) ;
        setDeleteDisable && setDeleteDisable(true);
      }
    } 
 
    if (props.sortOrder !== undefined) {
      setOrder(props.sortOrder);
    }
    props.headCells.map(var1 => {
      if (var1.id === props.defaultSortColumn) {
        if (var1.isVarChar) {
          setvarCharFlag(true);
        } else {
          setvarCharFlag(false);
        }
      }
    });
    if (props.defaultSortColumnDesc) {
      setOrder('desc');
    }
  //  comment for DF 311103 fix  if(!props.showHeaderAttachment){
    let countWithID = 0;
    Array.isArray(props.tableData) && props.tableData.map((values) => {
      if (values.id) {
        countWithID++;
      }
    });
    if (countWithID > 0) {
      setShowHeaderCheckBox(true);
    } else {
      setShowHeaderCheckBox(false);
    }
    if(Array.isArray(props.tableData) && props.tableData.some(data => data?.enableCheckbox !== undefined)){
      if(props.tableData.some(data => data?.enableCheckbox === false)){
      setDisableHeaderCheckBox(false)
    } else {
      setDisableHeaderCheckBox(true)
    }}
 //  comment for DF 311103 fix }
  }, [props.tableData]);

  useEffect(() => {
//  comment for DF 311103 fixif(!props.showHeaderAttachment){
    let countWithID = 0;
    Array.isArray(props.tableData) && props.tableData.map((values) => {
      if (values.id) {
        countWithID++;
      }
    });
    if (countWithID > 0) {
      setShowHeaderCheckBox(true);
    } else {
      setShowHeaderCheckBox(false);
    }
 //  comment for DF 311103 fix }
  }, [props.tableData.length]);

  useEffect(() => {
        if (!props.isSearch && props.isSearch !== undefined) {
            props.onTableRowDelete(selected);
    }
  }, [selected]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
    props.headCells.map(var1 => {
      if (var1.id === property) {
        if (var1.isVarChar) {
          setvarCharFlag(true);
        } else {
          setvarCharFlag(false);
        }
      }
    });
  };

  const handleSelectAllClick = event => {
    if ((event.target.dataset.indeterminate).toLowerCase() === 'false' && event.target.checked) {  
      var newSelecteds =[];
      if(Array.isArray(props.tableData) && props.tableData.some(tcn => tcn?.tcnstatCdO !== undefined)) {
        newSelecteds = props.tableData.filter(tcn => tcn?.tcnstatCdO).map(n => n.id);
      }else{   
       newSelecteds = Array.isArray(props.tableData) && props.tableData.map(n => n.id);
      }
      const tempSelects = JSON.parse(JSON.stringify(newSelecteds));
      setSelected(tempSelects);
      setDeleteDisable && newSelecteds.length && setDeleteDisable(false);
      return;
    }
    setSelected([]);
    setDeleteDisable && setDeleteDisable(true);
  };
  const handleClick = (event, name) => {
    setDeleteDisable && setDeleteDisable(false);
        const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      setDeleteDisable && setDeleteDisable(true);
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const dataToUrlParam = (rowData) => {
    const objStr = JSON.stringify(rowData);
    const toBase64 = btoa(unescape(encodeURIComponent(objStr)));

    return toBase64;
  };

  const handleDropDownRsnCode = (row) => e => {
    props.handleDropDown(row, e.target.value);
    const rsnCdtable = props.tableData;
    rsnCdtable.map((record) => {
      if ((record.id === row.id)) {
        record.transferreasonCode = e.target.value;
        if (e.target.value !== 'Please Select One') {
          record.errorKey = false;
        } else {
          record.errorKey = true;
        }
      }
    });
    props.setRsnCdData(rsnCdtable);
  };

  const handleDropDownPayeeStatus = (row) => e => {
    props.setFocusCheck(true);
    props.setAllowNavigation(true);
    props.handleDropDown(row, e.target.value);
    const payeeStatusTable = props.tableData;
    payeeStatusTable.map((record) => {
      if ((record.id === row.id)) {
        record.payeeStatus = e.target.value;
        if (e.target.value !== 'Please Select One') {
          record.errorKey = false;
        } else {
          record.errorKey = true;
        }
      }
    });
    props.setPayeeStatusData(payeeStatusTable);
  };

  const formatTableCell = (row, name, index, labelId, rowIndex) => {
    const rowclick = props.onTableRowClick ? props.onTableRowClick(row, rowIndex) : null;

    if(name.fcnLink) {
      const urlData = props.fcnSearch ? dataToUrlParam(props.fcnSearch(row)) : dataToUrlParam(row);
      const hyperLink = props.location.pathname === '/PaymentStatusInquirySearch'
      return props.fcnPathTo
        ? (
          <TableCell
            style={ { width: name.width } }
            key={ index || '02' + rowIndex || '02' }
            id={ labelId }
            scope="row"
            padding="default"
            className="has-link"
          >
            { row[name.id] ? props.pagination || hyperLink ? <Link title={ row[name.isTitle] } to={ props.fcnPathTo + urlData } target="_blank">{ row[name.id]} </Link> : <Link title={ row[name.isTitle] } to={ props.fcnPathTo + urlData }>{ row[name.id]} </Link> : <span hidden>Link</span> }
          </TableCell>
        )
        : (<TableCell
          onClick={ rowclick }
          style={ { width: name.width } }
          id={ labelId }
          scope="row"
          padding="default"
          className="has-link"
          key={ index || '03' + labelId || '03' }
        >
          { row[name.id] ? <a title={ row[name.isTitle] }>{ row[name.id] }</a> : <span title={ row[name.isTitle] } hidden>Link</span> }
          
        </TableCell>);
    }


    if (name.isNewHyperlink) {
      const urlData = dataToUrlParam(row[name.id]);
            return props.pathTo
        ? (
          <TableCell
            style={ { width: name.width } }
            key={ index || '02' + rowIndex || '02' }
            id={ labelId }
            scope="row"
            padding="default"
            className="has-link"
          >
            { row[name.id] ? props.pagination ? <Link to={ props.pathTo + urlData } target="_blank">{ row[name.id] } </Link> : <Link to={ props.pathTo + urlData }>{ row[name.id] } </Link> : <span hidden>Link</span> }
          </TableCell>
        )
        : (<TableCell
          onClick={ rowclick }
          style={ { width: name.width } }
          id={ labelId }
          scope="row"
          padding="default"
          className="has-link"
          key={ index || '03' + labelId || '03' }
        >
          { row[name.id] ? <a>{ row[name.id] }</a> : <span hidden>Link</span> }
        </TableCell>);
    }

    if (name.enableHyperLink) {
      const urlData = props.formatSearchCriteria ? dataToUrlParam(props.formatSearchCriteria(row)) : dataToUrlParam(row);

      const hyperLink = props.location.pathname === '/BulkReverse' || 
      props.location.pathname === '/ARBulkTransfer' ||
       props.location.pathname === '/BulkWriteOff' ||
        props.location.pathname === '/BulkWriteOffReest' ||
         props.location.pathname === '/ChangePayStatus' ||
         props.location.pathname === '/FinancialReceiptUpdate' ||
         props.location.pathname === '/FinancialEntityUpdate' ||
         props.location.pathname === '/PaymentStatusInquirySearch'
      return props.pathTo
        ? (
          <TableCell
            style={ { width: name.width } }
            key={ index || '02' + rowIndex || '02' }
            id={ labelId }
            scope="row"
            padding="default"
            className="has-link"
          >
            { row[name.id] ? props.pagination || hyperLink ? <Link title={ row[name.isTitle] } to={ props.pathTo + urlData } target="_blank">{ row[name.id]} </Link> : <Link title={ row[name.isTitle] } to={ props.pathTo + urlData }>{ row[name.id]} </Link> : <span hidden>Link</span> }
          </TableCell>
        )
        : (<TableCell
          onClick={ rowclick }
          style={ { width: name.width } }
          id={ labelId }
          scope="row"
          padding="default"
          className="has-link"
          key={ index || '03' + labelId || '03' }
        >
          { row[name.id] ? <a title={ row[name.isTitle] }>{ row[name.id] }</a> : <span title={ row[name.isTitle] } hidden>Link</span> }
          
        </TableCell>);
    }
    if (name.previewPDF) {
      return (
        <TableCell
          style={ { width: name.width } }
          id={ labelId }
          data-testid='test_previewPDF'
          scope="row"
          padding="default"
          className="has-link"
          key={ index || '03' + labelId || '03' }
        >  
          { row[name.id] ? <a onClick={ () => {dispatch(downloadPDFPreview(`${process.env.REACT_APP_PAPER_RA_FOLDER}`, row[name.id] + '.pdf')); props.setErrorMessages([]);dispatch(resetPDFReviewError())} }>{ row[name.id] }</a> : null }
        </TableCell>
      );
    }
    if (name.isDate && row[name.id] !== null) {
      return (
        <TableCell key={ index || '04' + labelId || '04' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id] !== ''
            ? <Moment format="MM/DD/YYYY" date={ row[name.id] } /> : row[name.id] }
        </TableCell>
      );
    }
    if (name.isDateUTC && row[name.id] !== null) {
      return (
        <TableCell key={ index || '05' + labelId || '05' } onClick={ rowclick } style={ { width: name.width } }>
          <Moment utc format="MM/DD/YYYY" date={ row[name.id] } />
        </TableCell>
      );
    }
    if (name.isBalance) {
      return (
        <TableCell
          key={ index || '06' + labelId || '06' }
          onClick={ rowclick }
        >
          { numeral(row[name.id]).format('$0,0.00') }
        </TableCell>
      );
    }
    if (name.isDataSize) {
      return (
        <TableCell
          key={ index || '15' + labelId || '15' }
          onClick={ rowclick }
        >
          { row[name.id] }
        </TableCell>
      );
    }
    if (name.isSPBalance) {
      return (
        <TableCell key={ index || '07' + labelId || '07' } onClick={ rowclick } style={ { width: name.width } }>
          { numeral(Number((row[name.id] ? row[name.id].toString().replace(/,/g, '') : row[name.id]))).format('$0,0.00') }
        </TableCell>
      );
    }
    if (name.isPercent) {
      return (
        <TableCell key={ index || '08' + labelId || '08' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id] }%
        </TableCell>
      );
    }
    if (name.isPayeeName) {
      return (<TableCell onClick={ rowclick } style={ { width: name.width } }>{ row[name.id] ? row[name.id].replace(',', ' ') : '' }</TableCell>);
    }
    if (name.isDefaultIndicator) {
      return (
        <TableCell key={ index || '09' + labelId || '09' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id] === 0 || row[name.id] === '0' ? 'No' : 'Yes' }
        </TableCell>
      );
    }
    if (name.isDropDown) {
      return (
        <TableCell key={ index || '10' + labelId || '10' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id] !== 'Please Select One' ? row[name.id] : '' }
        </TableCell>
      );
    }
    if (row[name.id] && name.isText && row[name.id] !== null) {
      return (
        <TableCell key={ index || '11' + labelId || '11' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id]
            ? row[name.id].replace(/<\/?[^>]+(>|$)/g, '').substring(0, 35)
            : null }{ ' ' }
        </TableCell>
      );
    }
    if (row[name.id] && name.isDescription && row[name.id] !== null) {
      return (
        <TableCell key={ index || '12' + labelId || '12' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id]
            ? row[name.id].replace(/<\/?[^>]+(>|$)/g, '').substring(0, 30)
            : null }{ ' ' }
        </TableCell>
      );
    }

    if (name.isDateTime && row[name.id] !== null) {
      return (
        <TableCell key={ index || '16' + labelId || '16' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id] !== ''
            ? <Moment format="MM/DD/YYYY HH:mm:ss" date={ row[name.id] } /> : row[name.id] }
        </TableCell>
      );
    }

    if (name.isBoolean) {
      return (
        <TableCell key={ index || '13' + labelId || '13' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id] === false ||
            row[name.id] === 'No' ||
            row[name.id] === undefined
            ? 'N'
            : 'Y' }
        </TableCell>
      );
    }
    if (name.isDateTimeWithLink && row[name.id] !== null) {
      return (
        <TableCell
          onClick={ rowclick }
          style={ { width: name.width } }
          id={ labelId }
          scope="row"
          padding="default"
          className="has-link"
          key={ index || '15' + labelId || '15' }
        >
          <a><Moment format="MM/DD/YYYY HH:mm:ss" date={ row[name.id] } /></a>
        </TableCell>
      );
    }
    if (row[name.id] && name.isNote && row[name.id] !== null) {
      return (
        <TableCell key={ index || '16' + labelId || '16' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id]
            ? row[name.id].substring(0, 100)
            : null }{ ' ' }
        </TableCell>
      );
    }
    if (name.isButton) {
      return (
        <TableCell key={ index || '21' + labelId || '21' } style={ { width: name.width } }>
          <Button color="primary" className='btn btn-success ml-1' data-testid='test_tableCellButton'
            onClick={ () => {
              if (props.onTableCellButtonClick) {
                props.onTableCellButtonClick(row);
              }
            } }
          >
            <i class="fa fa-question-circle" aria-hidden="true"></i>
            { (name.buttonLabel)
              ? name.buttonLabel.substring(0, 100)
              : null }
          </Button>
        </TableCell>
      );
    }
    if (name.isSelectBox) {
      return (
        <TableCell key={ index || '21' + labelId || '21' } style={ { width: name.width, padding: '0px' } }>
          <NativeDropDown
            className='w-97'
              key={ index || '21' + labelId || '21' }
            id={'payeeType-bulkTransfer' + labelId}
            inputProps={{ "data-testid": "test_reasonCode_DD" }}
              label="Reason code"
            isRequired
              value={ row[name.id] }
              onChange={ handleDropDownRsnCode(row) }
            errTxt={row[name.errorKey] || row.errorKey ? 'Reason code is required.' : null}
              title={ getLongDescription(props.dropDownData, row[name.id]) }
            dropdownList={props.dropDownData ? props.dropDownData.map((item, index) => (
              <option key={index} value={item.code} aria-hidden={true} id={index}
                  aria-invalid={ false }
                >
                  { item.description }
              </option>
              ))
                : null }
          />
        </TableCell>
      );
    }
    if (name.isPayeeStatus) {
      return (
        <TableCell key={ index || '21' + labelId || '21' } style={ { width: name.width, paddingLeft: '8px' } }>
          <div style={{ marginBottom: '24px', marginRight: '20px' }}>
            <NativeDropDown
              className='w-97'
              key={ index || '21' + labelId || '21' }
              id={ 'payeestatus' + labelId }
              inputProps={{ "data-testid": "test_payeestatus_DD" }}
              isRequired
              label="Payee Status"
              value={row[name.id] || DEFAULT_DD_VALUE}
              onChange={ handleDropDownPayeeStatus(row) }
              errTxt={row[name.errorKey] || row.errorKey ? 'Payee status is required.' : null}
              title={ getLongDescription(props.dropDownData, row[name.id]) }
              dropdownList={props.dropDownData ? props.dropDownData.map((item, index) => (
                <option key={index} value={item.code} aria-hidden={true} id={index}
                  aria-invalid={ false }
                >
                  { item.description }
                </option>
              ))
                : null }
            />
          </div>
        </TableCell>
      );
    }
    if (name.isRadio) {
      if (!name.showRadio) {
        if (!row.audit_flag) {
          if (!row.id && !row.uuId) {
            return (
              <TableCell key={ index || '21' + labelId || '21' } style={ { width: name.width } }>
                <label aria-hidden={ true } hidden for={ props.uniqueKey ? props.print ? row[props.uniqueKey] + 'onScreen' : row[props.uniqueKey] : (index || '21' + labelId || '21') }>Audit Log</label>
                <input
                  name="auditRadio"
                  type='radio'
                  className='radio-table'
                  value={ false }
                  id={ props.uniqueKey ? props.print ? row[props.uniqueKey] + 'onScreen' : row[props.uniqueKey] : (index || '21' + labelId || '21') }
                  data-testid='test_auditRadio_noShowRadio'
                  checked={ (!props.close) && selectedID === row[props.uniqueKey] }
                  onClick={ () => {
                    if (props.uniqueKey) {
                      setSelectedID(row[props.uniqueKey]);
                      if (props.setClose) {
                        props.setClose(false);
                      }
                    }
                    if (props.onTableCellButtonClick) {
                      props.onTableCellButtonClick(row);
                    }
                  } }
                />
              </TableCell >
            );
          }
        }
      } else {
        if (!row.audit_flag) {
          return (
            <TableCell key={ index || '21' + labelId || '21' } style={ { width: name.width } }>
              <label aria-hidden={ true } hidden for={ props.uniqueKey ? props.print ? row[props.uniqueKey] + 'onScreen' : (index || '21' + labelId || '21') : row[props.uniqueKey] }>Audit Log</label>
              <input
                name="auditRadio"
                type='radio'
                className='radio-table'
                value={ false }
                id={ props.uniqueKey ? props.print ? row[props.uniqueKey] + 'onScreen' : row[props.uniqueKey] : (index || '21' + labelId || '21') }
                data-testid='test_auditRadio_showRadio'
                checked={ (!props.close) && selectedID === row[props.uniqueKey] }
                onClick={ () => {
                  if (props.uniqueKey) {
                    setSelectedID(row[props.uniqueKey]);
                    if (props.setClose) {
                      props.setClose(false);
                    }
                  }
                  if (props.onTableCellButtonClick) {
                    props.onTableCellButtonClick(row);
                  }
                } }
              />
            </TableCell >
          );
        }
      }
    }
    if (name.isTooltip) {
      return (
        <TableCell key={ index || '20' + labelId || '20' } onClick={ rowclick } style={ { width: name.width } }>
          <span id={ 'longDescription' + index } title={ row[name.isTitle] }>{ row[name.id] }</span>
        </TableCell >
      );
    }
    if (name.isDateYYYYMMDD) {
      return (
        <TableCell key={ index || '22' + labelId || '22' } onClick={ rowclick } style={ { width: name.width } }>
          { row[name.id] !== '' && row[name.id] ? moment(row[name.id], 'MM/DD/YYYY', true).isValid()
            ? <Moment format="MM/DD/YYYY" date={ row[name.id] } /> : getDateInYYYYMMDDtoMMDDYYYY(row[name.id]) : row[name.id] }
        </TableCell>
      );
    }
    if ((name.isRedirectable && !row.id && row[name.id]) || (name.isRedirectable && row.hasOwnProperty('tcnstatCdO'))) {
      if (row.activityType === 'Payout') {
                return (
          <TableCell
            style={ { width: name.width } }
            key={ index || '17' + rowIndex || '17' }
            id={ labelId }
            scope="row"
            padding="default"
            className="has-link"
          >
            <a href={ redirectPayoutFCN(row[name.id]) } target='_blank'>{ row[name.id] } </a>
          </TableCell>
        );
      }
      if (row.activityType === 'Receipt') {
                return (
          <TableCell
            style={ { width: name.width } }
            key={ index || '17' + rowIndex || '17' }
            id={ labelId }
            scope="row"
            padding="default"
            className="has-link"
          >
            <Link to={ redirectReceiptFCN(row[name.id]) } target='_blank'>{ row[name.id] } </Link>
          </TableCell>
        );
      }
      if (row.activityType === 'AccountR') {
                return (
          <TableCell
            style={ { width: name.width } }
            key={ index || '17' + rowIndex || '17' }
            id={ labelId }
            scope="row"
            padding="default"
            className="has-link"
          >{name.openInNewTab ? <Link to={ redirectGrossLevelFCN(row[name.id])} target='_blank'>{ row[name.id] } </Link>
              : <Link to={ redirectGrossLevelFCN(row[name.id]) }>{ row[name.id] } </Link>}

          </TableCell>
        );
      }
      if (row.activityType === 'FTTCN') {
        return (
          <TableCell
            style={ { width: name.width } }
            key={ index || '17' + rowIndex || '17' }
            id={ labelId }
            scope="row"
            padding="default"
            className="has-link"
          >
            <a href={ CLAIMS_INQUIRY_TCN_LINK + row[name.id] } target={ '_blank' }>{ row[name.id] } </a>
          </TableCell>
        );
      }
      if (name.isSystemParamDate && row[name.id] !== null) {
        return (
          <TableCell key={ index || '20' + labelId || '20' } onClick={ rowclick } style={ { width: name.width } }>
            { row[name.id] }
          </TableCell>
        );
      }
    }
    return (
      <TableCell key={ index || '14' + labelId || '14' } onClick={ rowclick } style={ { width: name.width } }>
        { row[name.id] }
      </TableCell>
    );
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.tableData.length - page * rowsPerPage);
  const [selectedID, setSelectedID] = React.useState(null);
  const sortedTableData = stableSort(props.tableData, getSorting(order, orderBy, varCharFlag, props.isTrueSort, props.sortColumns, props.multiColumnSortingEnabled))
    return (
    <div className={ 'pos-relative' }>
      { props.spinnerLoader ? <Spinner /> : null }
      <div className="table-wrapper">
        <Table
          className={ `customDataTable with-link ${props.fixedTable ? 'fixedTable' : ''
            }` }
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={ selected.length }
            order={ order }
            orderBy={ orderBy }
            onSelectAllClick={ handleSelectAllClick }
            onRequestSort={ handleRequestSort }
            rowCount={ props.tableData.length }
            headCells={ props.headCells }
            isSearch={ props.isSearch }
            showHeaderCheckBox={ /*//  comment for DF 311103 fix props.showHeaderAttachment?props.showHeaderAttachment: */showHeaderCheckBox }
            disableHeaderCheckBox={disableHeaderCheckBox}
          />
            <TableBody className={props.wraptableData ? "contacts-table-content" : ""}>
            { props.tableData && sortedTableData && sortedTableData
              .slice(...[page * rowsPerPage, page * rowsPerPage + rowsPerPage])
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  (props.isNotesTable) ? (<TableRow
                    hover
                    role="checkbox"
                    aria-checked={ isItemSelected }
                    tabIndex={ -1 }
                    selected={ isItemSelected }
                  >
                    { !props.isSearch && (row.id || row.uuId) && !row.isChild ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={ isItemSelected }
                          color="primary"
                          inputProps={ { 'aria-labelledby': labelId } }
                          onClick={ event =>
                            handleClick(event, row.id ? row.id : index)
                          }
                        />
                      </TableCell>
                    ) : !props.isSearch ? (
                      <TableCell padding="checkbox"></TableCell>
                    ) : null }
                    <TableCell
                      id={ labelId }
                      hidden={ true }
                      scope="row"
                      padding="default"
                    >
                      { generateUUID() }
                    </TableCell>
                    { row.id ? (
                      <TableCell
                        id={ labelId }
                        hidden={ true }
                        scope="row"
                        padding="default"
                      >
                        { row.id }
                      </TableCell>
                    ) : null }
                    { props.headCells.map((name, colindex) =>
                      formatTableCell(row, name, colindex, labelId, index)
                    ) }
                  </TableRow>) : (<TableRow
                    hover
                    role="checkbox"
                    aria-checked={ isItemSelected }
                    tabIndex={ -1 }
                    key={ row.id || '01' + index || '01' }
                    selected={ isItemSelected }
                  >
                    { !props.isSearch && (row.id || row.uuId) && !row.isChild ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={ isItemSelected }
                          disabled = {row?.enableCheckbox}
                          color="primary"
                          inputProps={ {readOnly: row?.enableCheckbox, 'aria-labelledby': labelId, 'data-testid': 'test_notesChecbox' } }
                          onClick={ event =>
                            handleClick(event, row.id ? row.id : index)
                          }
                        />
                      </TableCell>
                    ) : !props.isSearch ? (
                      <TableCell padding="checkbox"></TableCell>
                    ) : null }
                    <TableCell
                      id={ labelId }
                      hidden={ true }
                      scope="row"
                      padding="default"
                    >
                      { generateUUID() }
                    </TableCell>
                    { row.id ? (
                      <TableCell
                        id={ labelId }
                        hidden={ true }
                        scope="row"
                        padding="default"
                      >
                        { row.id }
                      </TableCell>
                    ) : null }
                    { props.headCells.map((name, colindex) =>
                      formatTableCell(row, name, colindex, labelId, index)
                    ) }
                  </TableRow>)
                );
              }) }
            { emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={ props.headCells.length } />
              </TableRow>
            ) }
          </TableBody>
          <TableFooter>
            <TableRow >
              <TableCell className="table-pagination">Page: <span>{ page + 1 }</span></TableCell>
              <TablePagination
                rowsPerPageOptions={ props.pagination ? [5] : [5, 10, 25] }
                colSpan={ props.headCells.length }
                count={ props.tableData.length }
                rowsPerPage={ rowsPerPage }
                page={ page }
                SelectProps={ {
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                } }
                onChangePage={ handleChangePage }
                onChangeRowsPerPage={ handleChangeRowsPerPage }
                ActionsComponent={ TablePaginationActions }
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
}
export default withRouter(TableComponent);
