/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/
import { removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import { searchSingleRecordFinancialEntity } from "../../Store/Actions/FinancialEntityActions";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import SystemPayeeTable from '../../../../SharedModules/SystemIDTable/SystemPayeeTableEntity';
import { checkValueExists } from "../../../../SharedModules/CommonUtilities/commonUtilities";
import {
  getDropdownDataAction, getNewPayoutResponse, getPayeeIdType, validatePayeeDetails, validateMemberDetails,
  financialPayoutAdd, getFinancialPayoutDetails, financialPayoutUpdate, financialPayOutSearchAction
} from '../../../FinancialPayOut/Store/Actions/financialPayout/financialPayoutActions';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

export default function Common(props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE
  const dispatch = useDispatch();
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const {
    payeeValues,
    handlePayeeFormChanges,
    validatePayee,
    payeeTypeDataFrmState6003,
    payeeTypeDataFrmState6002,
    payeeIDTypeCodeDropdown,
    showPayeeError,
    payeeErrorMsg,
    IsG16003,
    systemPayeeTableData,
    setSystemPayeeTableData,
    showEditPage,
    disableFields
  } = props;

  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState('');
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState('');
  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);

  // for the close functionality in pop up
  const handleCloseSysPayeeTable = (e) => {
    setOpenSysPayeeTable(false);
    props.setHyperLinkClick(false);

  };

  // for the radio button functionality in pop up
  const onRadioButtonClick = (row) => {
    setCurrentSysID(row);
  };
  const [currentSysID, setCurrentSysID] = React.useState(null);

  // functionality after clicking on continue
  const onContinueHyper = (currentSysIDcriteria) => {
    if (currentSysIDcriteria) {
      setspinnerLoader(true);
      props.setHyperLinkClick(true)
      dispatch(searchSingleRecordFinancialEntity(currentSysIDcriteria));
      setRedirect(1);
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  const onContinue = () => {
    if (currentSysID) {
      setspinnerLoader(true);
      props.setHyperLinkClick(true)
      dispatch(searchSingleRecordFinancialEntity(currentSysID));
      setRedirect(1);
      setCurrentSysID(null);
      setOpenSysPayeeTable(false);
    }
  };
  let onValidatePayeeResponse = {};

  const [flagHyper,setFlagHyper]=useState(false) // flag for to check whter hyperlick is clicked or not
  const validatePayoutSystemDetails = () => {
    props.setHyperLinkClick(true)

    setFlagHyper(true)

    
  };
  useEffect(()=>{
    // useeffect for to fetch with updated payee details

    if(flagHyper)    {    let validatePayee={entityType: payeeValues.payeePayerType,
          entityIDTypeCode: payeeValues.entityIDTypeCD,
          entityID:payeeValues.entityID,
          lineOfBusiness: "MED"}
        onValidatePayeeDetails(removeLeadingTrailingSpaces(validatePayee));
      setFlagHyper(false)}
      },[flagHyper,payeeValues])

  const searchSingleFinancialEntityRes = useSelector(
    state => state.financialEntityState.singleSearchResults
  );
  const [redirect, setRedirect] = React.useState(0);
  React.useEffect(() => {
    setspinnerLoader(false);
    if (
      redirect === 1 &&
      searchSingleFinancialEntityRes &&
      !searchSingleFinancialEntityRes.systemFailue
    ) {
      
      props.setUpdatedRecord(searchSingleFinancialEntityRes)
/*       props.history.push({
        pathname: "/FinancialEntityUpdate",
        state: { searchSingleFinancialEntityRes }
      }); */
    }
  }, [searchSingleFinancialEntityRes]);
  const formatSearchCriteria = _row => _row;
  const onValidatePayeeDetails = (value) => dispatch(validatePayeeDetails(value));
  // dispatch for to fetch the muliple sys ids
   onValidatePayeeResponse = useSelector(state => state.financialPayoutState.payoutDetails);
 
  useEffect(() => {
    //after fetching the results from verifypayeepayoit service
    if (onValidatePayeeResponse && onValidatePayeeResponse !== undefined && onValidatePayeeResponse.respcode !== '02' &&
      onValidatePayeeResponse.enterpriseSearchresultVO && onValidatePayeeResponse.enterpriseSearchresultVO.searchResults) {
      const onValidateResult = onValidatePayeeResponse.enterpriseSearchresultVO.searchResults[0];

      if (onValidateResult.multiplePayeeDetails && onValidateResult.multiplePayeeDetails.length > 1) {
        setOpenSysPayeeTable(true)
        setSystemPayeeTableData(onValidateResult.multiplePayeeDetails);
      } else {
        setOpenSysPayeeTable(false)
        setSystemPayeeTableData([])
      }
    } else if (onValidatePayeeResponse && onValidatePayeeResponse.respcode && onValidatePayeeResponse.respcode === '02') {
/*       errorMessagesArray.push(onValidatePayeeResponse.errorMessages);
      seterrorMessages(errorMessagesArray);
      setValues({
        ...values,
        payeePayerName: '',
        payeeId: '',
        systemPayeeId: ''
      }); */
    } else if (onValidatePayeeResponse && onValidatePayeeResponse.systemFailure) {
/*       errorMessagesArray.push(ErrorConstants.SYSTEM_ERROR);
      seterrorMessages(errorMessagesArray); */
    }
  }, [onValidatePayeeResponse]);
  const [tableSYS,setTableSYS]=useState({
    payeeType:"",
    payeeIdTypeCode:""
  })
  useEffect(()=>{
    //useEffect for to map full description
    let payeeType,payeeIdTypeCode
if(showEditPage){
  payeeTypeDataFrmState6002.map((item)=>{
    if(item.code == payeeValues.payeePayerType ){
      payeeType=item.description
    }
  })
}
else{
  payeeTypeDataFrmState6003.map((item)=>{
    if(item.code == payeeValues.payeePayerType ){
      payeeType=item.description
    }
  })
}
    payeeIDTypeCodeDropdown.map((item)=>{
      if(item.code == payeeValues.entityIDTypeCD ){
        payeeIdTypeCode=item.description
      }
    })
    setTableSYS({
      payeeType: payeeType,
      payeeIdTypeCode:payeeIdTypeCode
    })
  },[payeeTypeDataFrmState6002,payeeTypeDataFrmState6003,showEditPage,payeeIDTypeCodeDropdown])

  return (
    <form noValidate autoComplete="off">
      <div className="form-wrapper">
        {showEditPage ?
          <NativeDropDown
            id="payee-type"
            isRequired
            disabled={showEditPage}
            label="Payee Type"
            value={payeeValues.payeePayerType}
            onChange={handlePayeeFormChanges("payeePayerType")}
            InputLabelProps={{
              shrink: true
            }}
            errTxt={
              showPayeeError.showPTypeErr ? payeeErrorMsg.pTypeErrMsg : null
            }
            dropdownList={payeeTypeDataFrmState6002
              ? payeeTypeDataFrmState6002.map(option => (
                <option key={option.code} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
          /> :
          <NativeDropDown
            id="payee-type"
            isRequired
            label="Payee Type"
            value={payeeValues.payeePayerType}
            onChange={handlePayeeFormChanges("payeePayerType")}
            InputLabelProps={{
              shrink: true
            }}
            errTxt={
              showPayeeError.showPTypeErr ? payeeErrorMsg.pTypeErrMsg : null
            }
            dropdownList={payeeTypeDataFrmState6003
              ? payeeTypeDataFrmState6003.map(option => (
                <option key={option.code} value={option.code}>
                    {option.description}
                </option>
                ))
              : null}
          />
         }
         {showEditPage ?
          <NativeDropDown
            id="payee-type-code"
            isRequired
            label="Payee ID Type Code"
            value={payeeValues.entityIDTypeCD}
            disabled={
              showEditPage || payeeValues.payeePayerType === DEFAULT_DD_VALUE
            }
            onChange={handlePayeeFormChanges("entityIDTypeCD")}
            InputLabelProps={{
              shrink: true
            }}
            errTxt={
              showPayeeError.showPIDTypeErr ? payeeErrorMsg.pIDTypeErrMsg : null
            }
            dropdownList={payeeValues.payeePayerType === 'M' ?
             props.dropdowns && props.dropdowns['F1#B_ALT_ID_TY_CD'] && 
             props.dropdowns['F1#B_ALT_ID_TY_CD'].length > 0
              ? props.dropdowns['F1#B_ALT_ID_TY_CD'].map(option => (
                <option key={option.code} value={option.code}>
                    {option.description}
                </option>
                )) : null
            : payeeValues.payeePayerType === 'P' ?
                props.dropdowns && props.dropdowns['F1#P_ALT_ID_TY_CD'] && 
                props.dropdowns['F1#P_ALT_ID_TY_CD'].length > 0
                 ? props.dropdowns['F1#P_ALT_ID_TY_CD'].map(option => (
                   <option key={option.code} value={option.code}>
                       {option.description}
                   </option>
                   )) : null
             : payeeValues.payeePayerType === 'O' ?
                   props.dropdowns && props.dropdowns['F1#G_ENTY_EXTL_ID_TY_CD'] && 
                   props.dropdowns['F1#G_ENTY_EXTL_ID_TY_CD'].length > 0
                    ? props.dropdowns['F1#G_ENTY_EXTL_ID_TY_CD'].map(option => (
                      <option key={option.code} value={option.code}>
                          {option.description}
                      </option>
                      )) : null
              : payeeValues.payeePayerType === 'TC' ?
                      props.dropdowns && props.dropdowns['F1#DM_G_CARR_ENTY_ID_TY_CD'] && 
                      props.dropdowns['F1#DM_G_CARR_ENTY_ID_TY_CD'].length > 0
                       ? props.dropdowns['F1#DM_G_CARR_ENTY_ID_TY_CD'].map(option => (
                         <option key={option.code} value={option.code}>
                             {option.description}
                         </option>
                         )): null
              : null}
          />
          : <NativeDropDown
          id="payee-type-code"
            isRequired
          label="Payee ID Type Code"
          value={payeeValues.entityIDTypeCD}
          disabled={
            showEditPage || payeeValues.payeePayerType === DEFAULT_DD_VALUE
          }
          onChange={handlePayeeFormChanges("entityIDTypeCD")}
          InputLabelProps={{
            shrink: true
          }}
            errTxt={
            showPayeeError.showPIDTypeErr ? payeeErrorMsg.pIDTypeErrMsg : null
          }
            dropdownList={payeeIDTypeCodeDropdown
            ? payeeIDTypeCodeDropdown.map(option => (
              <option key={option.code} value={option.code}>
                  {option.description}
              </option>
              ))
            : null}
          />
  }
        <div className="mui-custom-form">
          <TextField
            id="payee-id"
            type="string"
            label="Payee ID"
            required
            disabled={showEditPage}
            inputProps={{ maxLength: 15 }}
            value={payeeValues.entityID}
            onBlur={() => validatePayee(false)}
            onChange={handlePayeeFormChanges("entityID")}
            InputLabelProps={{
              shrink: true
            }}
            helperText={
              showPayeeError.showPIDErr ? payeeErrorMsg.pIDErrMsg : null
            }
            error={showPayeeError.showPIDErr ? payeeErrorMsg.pIDErrMsg : null}
          ></TextField>
        </div>
        <div className="mui-custom-form">
          <TextField
            id="payee-name"
            label="Payee Name"
            disabled = {showEditPage ? ((showEditPage && !IsG16003) || disableFields ) : false}
            required
            inputProps={{ maxLength: 60 }}
            // InputProps={{
            //   readOnly: true,
            //   className: "Mui-disabled"
            // }}
            value={payeeValues.payeePayerName}
            onChange={handlePayeeFormChanges("payeePayerName")}
            InputLabelProps={{
              shrink: true
            }}
            helperText={
              showPayeeError.showPayeeNameErr
                ? payeeErrorMsg.showPayeeNameErrMsg
                : null
            }
            error={
              showPayeeError.showPayeeNameErr
                ? payeeErrorMsg.showPayeeNameErrMsg
                : null
            }
          ></TextField>
        </div>
      </div>
      <div className="form-wrapper">        
        <div
          className="mui-custom-form input-md"
        >
          <TextField
            id="FinancialEntitySystemPayeeID"
            fullWidth
            label="System Payee ID"
            InputProps={{
              readOnly: true,
              className: "Mui-disabled"
            }}
           // onClick={validatePayoutSystemDetails}
            value={payeeValues.sysPayeeId}
            InputLabelProps={{
              shrink: true
            }}
          />
        </div>
        {showEditPage ? (
          <div
            className="mui-custom-form with-textarea input-sm"
          >
            <label className="MuiFormLabel-root small-label">Void</label>
            <div className="sub-radio">
              <Radio
                id="selected-option-yes"
                value={true}
                name="void_value"
                disabled={!IsG16003 || disableFields}
                onClick={handlePayeeFormChanges("voidInd")}
                className="ml-0"
                checked={
                  payeeValues.voidInd === true || payeeValues.voidInd === "true"
                }
              />
              <label for="selected-option-yes" className="text-black">
                {" "}
                Yes
              </label>
              <Radio
                id="selected-option-no"
                value={false}
                disabled={disableFields || !IsG16003}
                className="ml-2"
                name="void_value"
                onClick={handlePayeeFormChanges("voidInd")}
                checked={
                  payeeValues.voidInd === false ||
                  payeeValues.voidInd === "false"
                }
              />
              <label for="selected-option-no" className="text-black">
                {" "}
                No
              </label>
            </div>
          </div>
        ) : null}
      </div>
      
{//pop up table
 props.isHyperLinkClick?<>     <SystemPayeeTable 
        payeeType={tableSYS.payeeType}
        payeeIdTypeCode={tableSYS.payeeIdTypeCode}
        payeeId={payeeValues.entityID}
        openSysPayeeTable={openSysPayeeTable}
        handleCloseSysPayeeTable={handleCloseSysPayeeTable}
        uniqueKey={'sysID'}
        onTableCellButtonClick={onRadioButtonClick}
        tableData={systemPayeeTableData}
        setUpdatedRecord={props.setUpdatedRecord}
        currentSysID={currentSysID}
        onContinue={onContinue}
        onContinueHyper={onContinueHyper}
      /></>:null}
    </form>
  );
}
