/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import TableComponent from '../../../../SharedModules/Table/Table';

const headCellsMultiRecord = [
  {
    id: 'lobDesc',
    disablePadding: false,
    label: 'LOB',
    enableHyperLink: false,
    width: '8%'
  },
  {
    id: 'fcn',
    disablePadding: false,
    label: 'FCN',
    enableHyperLink: true,
    width: '16%'
  },
  {
    id: 'establishedDate',
    disablePadding: false,
    label: 'Established Date',
    enableHyperLink: false,
    isDateYYYYMMDD: true,
    width: '15%'
  },
  {
    id: 'reasonCodeDesc',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    width: '12%',
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: 'payeeTypeDesc',
    disablePadding: false,
    label: 'Payee Type',
    enableHyperLink: false,
    width: '11%'
  },
  {
    id: 'payeeID',
    disablePadding: false,
    label: 'Payee ID',
    enableHyperLink: false,
    width: '17%'
  },
  {
    id: 'payeeName',
    disablePadding: false,
    label: 'Payee Name',
    enableHyperLink: false,
    width: '16%'
  },
  {
    id: 'amount',
    disablePadding: false,
    label: 'Payout / Original Amount',
    enableHyperLink: false,
    isBalance: true,
    width: '13%'
  },
  {
    id: 'userID',
    disablePadding: false,
    label: 'User ID',
    enableHyperLink: false,
    width: '16%'
  },
];

const headCellsSingleRecord = [
  {
    id: 'lineOfBusiness',
    disablePadding: false,
    label: 'LOB',
    enableHyperLink: false,
    width: '8%'
  },
  {
    id: 'fcn',
    disablePadding: false,
    label: 'FCN',
    enableHyperLink: true,
    width: '16%'
  },
  {
    id: 'establishedDateStr',
    disablePadding: false,
    label: 'Established Date',
    enableHyperLink: false,
    isDateYYYYMMDD: true,
    width: '15%'
  },
  {
    id: 'reasonCodeDesc',
    disablePadding: false,
    label: 'Reason Code',
    enableHyperLink: false,
    width: '12%',
    isTooltip: true,
    isTitle: 'resonCodeLongDesc'
  },
  {
    id: 'payeeTypeDesc',
    disablePadding: false,
    label: 'Payee Type',
    enableHyperLink: false,
    width: '11%'
  },
  {
    id: 'payeePayerID',
    disablePadding: false,
    label: 'Payee ID',
    enableHyperLink: false,
    width: '11%'
  },
  {
    id: 'payeePayerName',
    disablePadding: false,
    label: 'Payee Name',
    enableHyperLink: false,
    width: '16%'
  },
  {
    id: 'amount',
    disablePadding: false,
    label: 'Payout / Original Amount',
    enableHyperLink: false,
    isBalance: true,
    width: '13%'
  },
  {
    id: 'auditUserID',
    disablePadding: false,
    label: 'User ID',
    enableHyperLink: false,
    width: '14%'
  }
];
const formatSearchCriteria = (_row) => {
  return ({
    fcn: _row.fcn,
    searchBy: 'searchByFCN',
    lob: [_row.lob],
    fcnSearch: 1,
    payeeSearch: 0,
    payeeType: null,
    payeeID: null,
    payeeIDTypeCode: null
  });
};

function FinancialPayoutSearchTable(props) {
  const headCells = props.tableData.length === 1 ? headCellsSingleRecord : headCellsMultiRecord;

  const getTableData = d => {
    if (d && d.length) {
      let tData = JSON.stringify(d);
      tData = JSON.parse(tData);
      tData.map((each, index) => {
        if (each !== null) {
          each.index = index;
          // getting respective record from dropdown list which also has desc
          const payeeType =
            props.dropdowns &&
            props.dropdowns['G1#G_CMN_ENTY_TY_CD'] &&
            props.dropdowns['G1#G_CMN_ENTY_TY_CD'].filter(
              value => (value.code === each.payeeType || value.code === each.entityType)
            );
          //updating the record with desc
          each.payeeTypeDesc =
            payeeType && payeeType.length > 0
              ? payeeType[0].description
              : each.payeeType ? each.payeeType : '';
          const reasonType =
            props.dropdowns &&
            props.dropdowns['F1#F_RSN_CD'] &&
            props.dropdowns['F1#F_RSN_CD'].filter(
              value => (value.code === each.reasonCode)
          );
          each.reasonCodeDesc = 
            reasonType && reasonType.length > 0
            ? reasonType[0].description
            : each.reasonCode ? each.reasonCode : '';
        }
        return each;
      });
      return tData;
    }
    return [];
  };
  return (
    <div className="mt-3 table-no-wrap table-adjust">
      <TableComponent
        print={props.print}
        fixedTable
        pathTo='/FinancialPayoutUpdate?data='
        formatSearchCriteria={formatSearchCriteria}
        headCells={headCells}
        tableData={getTableData(props.tableData)}
        onTableRowClick={props.onRowClick}
        defaultSortColumn={headCells[2].id}
        order={'desc'}
        isSearch={true}
      />
    </div>
  );
}

export default FinancialPayoutSearchTable;
