/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as AppConstants from '../../../SharedModules/AppConstants';
import * as Constants from './WriteOffContants';
import '../WriteOff.scss';
import { redirectGrossLevelFCN } from '../../../SharedModules/TabRedirections/TabRedirections';
import { getLongDescription } from '../../../SharedModules/CommonUtilities/commonUtilities'
import { LinkOfFcn, LinkOfTcn } from '../../../SharedModules/TabRedirections/customInputLink';
import { Link } from 'react-router-dom';
import { CLAIMS_INQUIRY_TCN_LINK } from '../../../SharedModules/services/service';

export default function WriteOffRestablismentTabDetail(props) {
  return (
    <div>
      <form noValidate autoComplete="off">
        <div className="tab-holder">
          <div className="row mb-2">
            <div className="col-6" style={ { marginTop: '10px', marginBottom: '90px' } }>
              <fieldset className="custom-fieldset">
                <legend>Write-Off</legend>
                <div className="form-wrapper">
                  <div className="mui-custom-form input-md with-link MuiTextField-root override-width-45 override-m-3 override-margin-rgt1 has-link">
                    <TextField
                      id="fcn-code-writeoff"
                      label="FCN"
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      InputProps={ {
                        startAdornment: (
                          <InputAdornment position="start">
                            <Link to={ redirectGrossLevelFCN(props.values.fcn) }>
                              { props.values.fcn }
                            </Link>
                          </InputAdornment>
                        )
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="established-date-writeoff"
                      label="Established Date"
                      value={ props.values.establishedDate }
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3 override-margin-rgt1 with-link has-link">
                    <TextField
                      id="related-ft-tcn-writeoff"
                      label="Related FT TCN"
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      InputProps={ {
                        startAdornment: (
                          <InputAdornment position="start">
                            <a href={ CLAIMS_INQUIRY_TCN_LINK + props.values.relatedFtTcn } target='_blank'>
                              { props.values.relatedFtTcn }
                            </a>
                          </InputAdornment>
                        )
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3" >
                    <TextField
                      disabeld={ true }
                      id="agingcode-writeoff"
                      label="A/R Aging"
                      InputLabelProps={ {
                        shrink: true
                      } }
                      disabled={ true }
                      value={ props.values.agingCode }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form  override-width-42 override-m-3 override-margin-rgt2">
                    <TextField
                      id="original-ar-writeoff"
                      label="Original A/R Amount"
                      value={ props.values.originalArAmount }
                      disabled={ true }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form  override-width-42 override-m-3">
                    <TextField
                      id="balance-amt-writeoff"
                      label="Balance Amount"
                      value={ props.values.balanceAmount }
                      disabled={ true }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-42 override-m-3 override-margin-rgt2">
                    <TextField
                      id="adjustedamount-writeoff"
                      label="Adjusted Original Amount"
                      value={ props.values.adjustedAmount }
                      disabled={ true }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form  override-width-42 override-m-3 ">
                    <TextField
                      id="penality-writeoff"
                      label="Penality"
                      value={ props.values.penalty }
                      disabled={ true }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form  override-width-45 override-m-3 override-margin-rgt1">
                    <TextField
                      id="interest-writeoff"
                      label="Interest"
                      value={ props.values.interest }
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="fees-writeoff"
                      label="Fees"
                      value={ props.values.fees }
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>
                  <div className="mui-custom-form  override-width-45 override-m-3 override-margin-rgt1">
                    <TextField
                      id='ATRnumber'
                      fullWidth
                      label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                      value={props.values.atrNumber}
                      disabled={ true }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    ></TextField>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="col-6" style={ { marginTop: '10px', marginBottom: '90px' } }>
              <fieldset className="custom-fieldset">
                <legend>Re-Establishment</legend>
                <div className="form-wrapper">
                  <div className={ props.saveFlag ? "mui-custom-form with-select override-width-45 override-m-3 with-link has-fcn-link" : "mui-custom-form with-select override-width-45 override-m-3 with-link" }>
                    <TextField
                      id="fcn-code-reestablishment"
                      label="FCN"
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      InputProps={ {
                        inputComponent: LinkOfFcn,
                        inputProps: { value: props.values.fcnRest, redirection: redirectGrossLevelFCN, saveFlag: props.saveFlag }
                      } }
                    >
                    </TextField>
                  </div>
                  <div className="mui-custom-form with-select override-width-45 override-m-3">
                    <TextField
                      id="payeetype-reestablishment"
                      select
                      label="Payee Type"
                      required
                      value={ props.payeeValues.payeeTypeRest }
                      onChange={ props.handleChangePayeeType('payeeTypeRest') }
                      helperText={ props.errors[1] ? Constants.PAYEE_TYPE_REQUIRED : null }
                      error={ props.errors[1] ? Constants.PAYEE_TYPE_REQUIRED : null }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                      <MenuItem selected key={ AppConstants.PLEASE_SELECT_ONE } value={ AppConstants.PLEASE_SELECT_ONE }>
                        { AppConstants.PLEASE_SELECT_ONE }
                      </MenuItem>
                      { props.payeeList.map((item, index) => (
                        <MenuItem key={ index } value={ item.code }>
                          { item.description }
                        </MenuItem>
                      )) }
                    </TextField>
                  </div>

                  <div className="mui-custom-form with-select override-width-45 override-m-3">
                    <TextField
                      id="payeetypecode-reestablishment"
                      select
                      label="Payee Id Type Code"
                      required
                      value={ props.payeeValues.payeeIdCodeRest }
                      onChange={ props.handleChangePayeeType('payeeIdCodeRest') }
                      helperText={ props.errors[2] ? Constants.PAYEE_TYPE_ID_CODE_REQUIRED : null }
                      error={ props.errors[2] ? Constants.PAYEE_TYPE_ID_CODE_REQUIRED : null }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >

                      <MenuItem selected key={ AppConstants.PLEASE_SELECT_ONE } value={ AppConstants.PLEASE_SELECT_ONE }>
                        { AppConstants.PLEASE_SELECT_ONE }
                      </MenuItem>
                      { props.payeeIdCodeListDropdown.map((item, index) => (
                        <MenuItem key={ index } value={ item.code }>
                          { item.description }
                        </MenuItem>
                      )) }
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="payeeID-reestablishment"
                      required
                      label="Payee ID"
                      inputRef={props.inputRefCursor}
                      inputProps={ { maxlength: 15 } }
                      value={ props.values.payeeIdRest }
                      onChange={ props.handleChange('payeeIdRest') }
                      onBlur={ props.validate }
                      helperText={ props.errors[4] ? props.errorText[4] : null }
                      error={ props.errors[4] ? props.errorText[4] : null }
                      onInput={ (e) => { e.target.value = e.target.value.replace(AppConstants.NUMBER_ONLY_REGEX, ''); } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>
                  <div className="mui-custom-form with-select override-width-45 override-m-3">
                    <TextField
                      id="reasoncode-reestablishment"
                      select
                      label="Reason Code"
                      required
                      value={ props.values.reasonCodeRest }
                      onChange={ props.handleChange('reasonCodeRest') }
                      helperText={ props.errors[0] ? Constants.REASON_CODE_REQUIRED : null }
                      error={ props.errors[0] ? Constants.REASON_CODE_REQUIRED : null }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      title={ getLongDescription(props.reasonCodeList, props.values.reasonCodeRest) }
                    >
                      <MenuItem selected key={ AppConstants.PLEASE_SELECT_ONE } value={ AppConstants.PLEASE_SELECT_ONE }>
                        { AppConstants.PLEASE_SELECT_ONE }
                      </MenuItem>
                      { props.reasonCodeList.map((item, index) => (
                        <MenuItem key={ index } value={ item.code }>
                          { item.description }
                        </MenuItem>
                      )) }
                    </TextField>
                  </div>

                  <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                    <div className="mui-custom-form input-date with-date override-width-45 override-m-3">
                      <KeyboardDatePicker
                        maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                        id="date-picker-dialog-reestablishment"
                        label="Established Date"
                        format="MM/dd/yyyy"
                        value={ props.selectedEstablishDate }
                        onChange={ props.handleEstablishDateChange }
                        onKeyUp={ props.handleEstablishDateText }
                        helperText={ props.errors[3] ? Constants.ESTABLISH_DATE_INVALID : null }
                        error={ props.errors[3] ? Constants.ESTABLISH_DATE_INVALID : null }
                        InputLabelProps={ {
                          shrink: true
                        } }
                        disabled
                        KeyboardButtonProps={ {
                          'aria-label': 'change date'
                        } }
                      />
                    </div>
                  </MuiPickersUtilsProvider>

                  <div className="mui-custom-form with-select override-width-45 override-m-3 with-link has-link">
                    <TextField
                      id="related-ft-tcn-reestablishment"
                      label="Related FT TCN"
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                      InputProps={ {
                        inputComponent: LinkOfTcn,
                        inputProps: { value: props.values.relatedFtTcnRest, redirection: redirectGrossLevelFCN, saveFlag: props.saveFlag }
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="payeename-reestablishment"
                      label="Payee Name"
                      value={ props.payeeNameRest }
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="original-ar-reestablishment"
                      label="Original A/R Amount"
                      value={ props.values.originalArAmountRest }
                      disabled={ true }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="balance-amt-reestablishment"
                      label="Balance Amount"
                      value={ props.values.balanceAmountRest }
                      disabled={ true }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                </div>
                <div className="form-wrapper">

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="adjustedamount-reestablishment"
                      label="Adjusted Original Amount"
                      value={ props.values.adjustedOriginalAmountRest }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="penality-reestablishment"
                      label="Penality"
                      value={ props.values.penaltyRest }
                      disabled={ true }
                      InputProps={ {
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      } }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form with-select override-width-45 override-m-3">
                    <TextField
                      id="interest-reestablishment"
                      label="Interest"
                      value={ props.values.interestRest }
                      disabled={ true }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form with-select override-width-45 override-m-3">
                    <TextField
                      id="fees-reestablishment"
                      label="Fees"
                      value={ props.values.feesRest }
                      disabled={ true }
                      onChange={ props.handleChange('feesRest') }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                    </TextField>
                  </div>

                  <div className="mui-custom-form with-select override-width-45 override-m-3">
                    <TextField
                      disabled
                      id="arlettersent-reestablishment"
                      select
                      label="A/R Letter Sent"
                      value={ props.values.arLetterSentRest }
                      onChange={ props.handleChange('arLetterSentRest') }
                      InputLabelProps={ {
                        shrink: true
                      } }
                    >
                      <MenuItem selected key={ AppConstants.PLEASE_SELECT_ONE } value={ AppConstants.PLEASE_SELECT_ONE }>
                        { AppConstants.PLEASE_SELECT_ONE }
                      </MenuItem>
                      { props.arLetterSentList.map((item, index) => (
                        <MenuItem key={ index } value={ item.code }>
                          { item.description }
                        </MenuItem>
                      )) }
                    </TextField>
                  </div>

                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      disabled={ true }
                      id="systempayeeid-reestablishment"
                      label="System Payee ID"
                      InputLabelProps={ {
                        shrink: true
                      } }
                      value={ props.systemPayeeIdRest }
                    >
                    </TextField>
                  </div>
                  <div className="mui-custom-form override-width-45 override-m-3">
                    <TextField
                      id="textATRNumber"
                      fullWidth
                      label={process.env.REACT_APP_TRANSACTION_NUMBER_LABEL }
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={props.values.atrNumberData}
                      onChange={props.handleChange('atrNumberData')}
                      inputProps={{ maxLength: 18 }}
                      helperText={props.errors[5] ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${Constants.TRANSACTION_NUMBER_FORMAT}` : null}
                      error={props.errors[5] ? `${process.env.REACT_APP_TRANSACTION_NUMBER_LABEL} ${Constants.TRANSACTION_NUMBER_FORMAT}` : null}
                    />
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
