/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
//../SharedModules/services/service
import * as serviceEndPoint from '../../SharedModules/services/service';
import Axios from 'axios'
import EnhancedTableHead from './EnhancedTableHead';
import TablePaginationActions from './TablePagination';
import { desc, stableSort, getSorting } from './TableSort';
import Spinner from '../../SharedModules/Spinner/Spinner';
import Moment from 'react-moment';
import numeral from 'numeral';
import Checkbox from '@material-ui/core/Checkbox';
import { generateUUID } from '../DateUtilities/DateUtilities';

function TableApprovalComponent(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(props.defaultSortColumn);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedForApproval, setSelectedForApproval] = React.useState([]);
  const [multiSelectedForApproval, setMultiSelectedForApproval] = React.useState([]);
  const [selectedForDelete, setSelectedForDelete] = React.useState([]);
  

  useEffect(() => {
    setPage(0);
    setMultiSelectedForApproval([]);
    setSelected([]);
    if (props.sortOrder !== undefined) {
      setOrder(props.sortOrder);
    }
  }, [props.tableData]);

  useEffect(() => {
    if (!props.isSearch && props.isSearch !== undefined) {
      props.onTableRowDelete(selected);
    }
  }, [selected]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = props.tableData.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleApproveAllClick = event => {
    if ((event.target.dataset.indeterminate).toLowerCase() === 'false' && event.target.checked ) {
      const newApproved = [];
      const newApproveSelecteds = props.tableData.map(n => {
        if(n.deleteIndicator){
          n.approvalIndicator = false;
          n.approvedIndicator = false;
        }else
        {
          if(n.checkApproveFlag){
            n.approvalIndicator = false;
            n.approvedIndicator = false;
          }
          else{
           if(!n.approvalCode){
            n.approvalIndicator = true;
            n.approvedIndicator = true;
            newApproved.push(n.uid1);
          }
          }

        }
        return n
      })
      setMultiSelectedForApproval(newApproveSelecteds);
      setSelectedForApproval(newApproved);
      return;
    }
    else {
      const newApproveSelecteds = props.tableData.map(n => { 
        n.approvalIndicator = false; 
        n.approvedIndicator = false; 
        return n ;
      });
      setMultiSelectedForApproval(newApproveSelecteds);
      setSelectedForApproval([]);
      return;
    }
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleClickApproval = (event, name, row) => {
    if(props.validate){
      let validateValues=[
        {
            providerID: row.providerID,
            commonEntitySK: row.commonEntitySK,
            categoryCode: row.categoryCode,
            lobCode: row.lobCode,
            timePeriodCode: row.timePeriodCode,
            taxYear: row.taxYear,
            approvedIndicator: true,
            approvalDisableCheck: false,
            deleteIndicator: false,
            claimProvSumSk: row.claimProvSumSk,
            claimCorrSeqNum: row.claimCorrSeqNum,
            auditUserID: row.auditUserID,
            auditTimeStamp: row.auditTimeStamp,
            addedAuditUserID: row.addedAuditUserID,
            addedAuditTimeStamp:row.addedAuditTimeStamp,
            noteSetVO: null
        }
    ]
    props.setSpinnerLoader(true);
    Axios.post(`${serviceEndPoint.CORRECTION_APPROVAL_VALIDATE}`, validateValues).then(
      res =>{
        if(res){
          props.setSpinnerLoader(false)
          if (!row.approvedIndicator) {
            row.approvedIndicator = true;
          }else{
            row.approvedIndicator = false;
          }
          const selectedIndex = selectedForApproval.indexOf(name);
          let newSelected = [];
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedForApproval, name);
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedForApproval.slice(1));
          } else if (selectedIndex === selectedForApproval.length - 1) {
            newSelected = newSelected.concat(selectedForApproval.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selectedForApproval.slice(0, selectedIndex),
              selectedForApproval.slice(selectedIndex + 1)
            );
          }
          setSelectedForApproval(newSelected);
          if (props.allowNavigation) {
            props.allowNavigation();
          }
        }
      }
    ).catch(err=>{
      if(err){
        props.setSpinnerLoader(false);
        props.seterrorMessages([err.response?.data?.respdesc]);
      }
    })
    }else{
      if (!row.approvedIndicator) {
      row.approvedIndicator = true;
      row.approvalIndicator= true;  
    }else{
      row.approvedIndicator = false;
      row.approvalIndicator= false;
    }
    const selectedIndex = selectedForApproval.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedForApproval, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedForApproval.slice(1));
    } else if (selectedIndex === selectedForApproval.length - 1) {
      newSelected = newSelected.concat(selectedForApproval.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedForApproval.slice(0, selectedIndex),
        selectedForApproval.slice(selectedIndex + 1)
      );
    }
    if(selectedIndex === -1){
      multiSelectedForApproval.push(row);
    }
    else{
      multiSelectedForApproval.splice(selectedIndex,1);
    }
    setMultiSelectedForApproval(multiSelectedForApproval);
    setSelectedForApproval(newSelected);
    if (props.allowNavigation) {
      props.allowNavigation();
    }}
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClickDelete = (event, name, row) => {
    if(props.validate){
      let validateValues=[
        {
            providerID: row.providerID,
            commonEntitySK: row.commonEntitySK,
            categoryCode: row.categoryCode,
            lobCode: row.lobCode,
            timePeriodCode: row.timePeriodCode,
            taxYear: row.taxYear,
            approvedIndicator: false,
            approvalDisableCheck: false,
            deleteIndicator: true,
            claimProvSumSk: row.claimProvSumSk,
            claimCorrSeqNum: row.claimCorrSeqNum,
            auditUserID: row.auditUserID,
            auditTimeStamp: row.auditTimeStamp,
            addedAuditUserID: row.addedAuditUserID,
            addedAuditTimeStamp:row.addedAuditTimeStamp,
            noteSetVO: null
        }
    ]
    props.setSpinnerLoader(true);
    Axios.post(`${serviceEndPoint.CORRECTION_APPROVAL_VALIDATE}`, validateValues).then(
      res =>{
        if(res){
          props.setSpinnerLoader(false);
          if (!row.deleteIndicator) {
            row.deleteIndicator = true;
          }else{
            row.deleteIndicator = false;
          }
          const selectedIndex = selectedForDelete.indexOf(name);
          let newSelected = [];
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedForDelete, name);
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedForDelete.slice(1));
          } else if (selectedIndex === selectedForDelete.length - 1) {
            newSelected = newSelected.concat(selectedForDelete.slice(0, -1));
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
              selectedForDelete.slice(0, selectedIndex),
              selectedForDelete.slice(selectedIndex + 1)
            );
          }
          setSelectedForDelete(newSelected);
          if (props.allowNavigation) {
            props.allowNavigation();
          }

        }}).catch(err=>{
          if(err){
            props.setSpinnerLoader(false);
            props.seterrorMessages([err.response?.data?.respdesc]);
          }
        })
  }else{
    if (!row.deleteIndicator) {
      row.deleteIndicator = true;
    }else{
      row.deleteIndicator = false;
    }
    const selectedIndex = selectedForDelete.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedForDelete, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedForDelete.slice(1));
    } else if (selectedIndex === selectedForDelete.length - 1) {
      newSelected = newSelected.concat(selectedForDelete.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedForDelete.slice(0, selectedIndex),
        selectedForDelete.slice(selectedIndex + 1)
      );
    }
    setSelectedForDelete(newSelected);
    if (props.allowNavigation) {
      props.allowNavigation();
    }}
  };

  useEffect(() => {
    if (
      selectedForApproval &&
      (selectedForApproval.length > 0 || selectedForApproval.length === 0)
    ) {
      props.newSelectedArrayForApproval(selectedForApproval);
      const tableDataMock = [...props.tableData];
      for (let i = 0; i < tableDataMock.length; i++) {
        if (selectedForApproval.includes(tableDataMock[i].uid1)) {
          tableDataMock[i].approvedIndicator = true;
        } 
      }
    }
  }, [selectedForApproval]);

  useEffect(() => {
    if (
      selectedForDelete &&
      (selectedForDelete.length > 0 || selectedForDelete.length === 0)
    ) {
      props.newSelectedArrayForDelete(selectedForDelete);
      const tableDataMock = [...props.tableData];
      for (let i = 0; i < tableDataMock.length; i++) {
        if (selectedForDelete.includes(tableDataMock[i].uid2)) {
          tableDataMock[i].deleteIndicator = true;
        } 
      }
    }
  }, [selectedForDelete]);
  const dataToUrlParam = (rowData) => {
    const objStr = JSON.stringify(rowData);
    const toBase64 = btoa(unescape(encodeURIComponent(objStr)));

    return toBase64;
  };

  const formatTableCell = (
    row,
    name,
    index,
    labelId,
    rowIndex,
    isItemSelectedApprovalCode,
    isItemSelectedDeleteCode,
    canApprove,
    preApproved
  ) => {
    const rowclick = props.onTableRowClick
      ? name.isLink
        ? props.onLinkClick(row, rowIndex)
        : props.onTableRowClick(row, rowIndex)
      : null;
    if (name.enableHyperLink) {
      const urlData = props.formatSearchCriteria ? dataToUrlParam(props.formatSearchCriteria(row)) : dataToUrlParam(row);
      return props.pathTo
        ? (
          <TableCell
            style={{ width: name.width }}
            key={index || '02' + rowIndex || '02'}
            id={labelId}
            scope="row"
            padding="default"
            className="has-link"
          >
            <Link to={props.pathTo + urlData} >{row[name.id]} </Link>
          </TableCell>
        )
        : (<TableCell
          onClick={rowclick}
          style={{ width: name.width }}
          id={labelId}
          scope="row"
          padding="default"
          className="has-link"
          key={index || '03' + labelId || '03'}
        >
          <a>{row[name.id]}</a>
        </TableCell>);
    }
    if (name.isDate && row[name.id] !== null) {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          <Moment utc format="MM/DD/YYYY" date={row[name.id]} />
        </TableCell>
      );
    }
    if (name.isTooltip) {
      return (
        <TableCell key={index || '20' + labelId || '20'} onClick={rowclick} style={{ width: name.width }}>
          <span id={'longDescription' + index} title={row[name.isTitle]}>{row[name.id]}</span>
        </TableCell >
      )
    };
    if (name.isBalance) {
      return (
        <TableCell
          onClick={rowclick}
          style={{ textAlign: 'left', width: name.width }}
        >
          {numeral(row[name.id]).format('$0,0.00')}
        </TableCell>
      );
    }
    if (name.isSPBalance) {
      return (
        <TableCell
          onClick={rowclick}
          style={{ textAlign: 'left', width: name.width }}
        >
          {numeral(row[name.id]).format('$0,0.0000')}
        </TableCell>
      );
    }
    if (name.isPercent) {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          {row[name.id]}%
        </TableCell>
      );
    }
    if (name.isDefaultIndicator) {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          {row[name.id] === 0 || row[name.id] === '0' ? 'No' : 'Yes'}
        </TableCell>
      );
    }
    if (name.isDropDown) {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          {row[name.id] !== 'Please Select One' ? row[name.id] : ''}
        </TableCell>
      );
    }
    if (name.isBoolean) {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          {row[name.id] === false ||
            row[name.id] === 'No' ||
            row[name.id] === undefined
            ? 'N'
            : 'Y'}
        </TableCell>
      );
    }
    if (name.isApproval) {
      if (props.approvalArray[props.tableData.indexOf(row)]) {
        return (
          <TableCell padding="checkbox">
             <span title='Approved'> 
            <Checkbox
              checked={true}
              color="primary"
              disabled={true}
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={event =>
                handleClickApproval(event, row.uid1 ? row.uid1 : index, row)
              }
            />
            </span> 
          </TableCell>
        );
      } else {
        return (
          <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelectedApprovalCode}
              disabled={isItemSelectedDeleteCode || canApprove}
              color="primary"
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={event =>
                handleClickApproval(event, row.uid1 ? row.uid1 : index, row)
              }
            />
          </TableCell>
        );
      }
    }
    if (name.isDelete) {
      if (props.deleteArray[props.tableData.indexOf(row)]) {
        return (
          <TableCell padding-left="0px">
            <Checkbox
              checked={true}
              color="primary"
              disabled={true}
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={event =>
                handleClickDelete(event, row.uid2 ? row.uid2 : index, row)
              }
            />
          </TableCell>
        );
      } else {
        return (
          <TableCell padding-left="0px">
            <Checkbox
              checked={isItemSelectedDeleteCode}
              disabled={props?.calledFrom === "1099Correction" ? isItemSelectedApprovalCode : isItemSelectedApprovalCode && !preApproved}
              color="primary"
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={event =>
                handleClickDelete(event, row.uid2 ? row.uid2 : index, row)
              }
            />
          </TableCell>
        );
      }
    }
    if (name.isNull) {
      return (
        <TableCell onClick={rowclick} style={{ width: name.width }}>
          {row[name.id] === null ||
            row[name.id] === 'null' ||
            row[name.id] === ''
            ? ''
            : row[name.id]}
        </TableCell>
      );
    }
    if (name.isIcon) {
     if (row[name.id]){
      return(
        <TableCell padding="checkbox" onClick={rowclick}>
          <i class="fa fa-comment ca-comment" id='Image'></i>
        </TableCell>
       )
     }
    }
    return (
      <TableCell onClick={rowclick} style={{ width: name.width }}>
        {row[name.id]}
      </TableCell>
    );
  };
  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, props.tableData.length - page * rowsPerPage);
  const tableRows = props.tableRows;
  return (
    <div className={'pos-relative'}>
      {props.spinnerLoader ? <Spinner /> : null}
      <div className="table-wrapper table-p-10">
        <Table
          className={`customDataTable with-link ${props.fixedTable ? 'fixedTable' : ''
            }`}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onApproveSelect={handleApproveAllClick}
            approvedNum={multiSelectedForApproval.filter(e=>e.approvedIndicator).length}
            approved={props.tableData.filter(e=>e.approvalCode).length}
            approvalCreator={props.tableData.filter(e=>e.checkApproveFlag).length}
            values={props.values}
            onRequestSort={handleRequestSort}
            rowCount={props.tableData.length}
            headCells={props.headCells}
            isSearch={props.isSearch}
          />
          <TableBody>
            {stableSort(props.tableData, getSorting(order, orderBy))
              .slice(
                ...([page * rowsPerPage, page * rowsPerPage + rowsPerPage])
              )
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                // const isItemSelectedApprovalCode = row.approvedIndicator ? row.approvedIndicator : row.approvedIndicator;
                const isItemSelectedApprovalCode = row.approvedIndicator || row.approvalIndicator === true;
                const isItemSelectedDeleteCode = row.deleteIndicator;
                const canApprove = row.checkApproveFlag;
                const preApproved = row.approvalCode
                return (
                  <TableRow
                    hover
                    // onClick={props.onTableRowClick(row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                  >
                    {!props.isSearch && (row.id || row.uuId) ? (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={event =>
                            handleClick(event, row.id ? row.id : index)
                          }
                        />
                      </TableCell>
                    ) : !props.isSearch ? (
                      <TableCell padding="checkbox"></TableCell>
                    ) : null}
                    <TableCell
                      id={labelId}
                      hidden={true}
                      scope="row"
                      padding="default"
                    >
                      {generateUUID()}
                    </TableCell>
                    <TableCell
                      id={labelId}
                      hidden={true}
                      scope="row"
                      padding="default"
                    >
                      {row.id}
                    </TableCell>
                    {props.headCells.map((name, colindex) =>
                      formatTableCell(
                        row,
                        name,
                        colindex,
                        labelId,
                        index,
                        isItemSelectedApprovalCode,
                        isItemSelectedDeleteCode,
                        canApprove,
                        preApproved
                      )
                    )}
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={props.headCells.length} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className="table-pagination">
                Page: <span>{page + 1}</span>
              </TableCell>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={props.headCells.length}
                count={props.tableData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    </div>
  );
}
export default withRouter(TableApprovalComponent);
