/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef } from 'react';
import Footer from '../../../../SharedModules/Layout/footer';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import SuccessMessages from '../../../../SharedModules/Errors/SuccessMessage';
import { useDispatch, useSelector } from 'react-redux';
import * as dropdowns from '../../../../SharedModules/Dropdowns/dropdowns'
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import * as  ErrorConstants from '../PaymentCriteriaConstant';
import {PaymentCriteriaSearchAction,resetPaymentCriteriaSearch,resetResponse} from '../../Store/Actions/PaymentCriteriaAction'
import { getDateInMMDDYYYYFormat } from '../../../../SharedModules/DateUtilities/DateUtilities';
import PaymentCriteriaSearchTable from './PaymentCriteriaSearchTable';
import { systemListActions } from '../../../ApplicationConfiguration/Store/Actions/systemList/systemListActions';
import { removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

function PaymentCriteriaSearch(props){
    const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
    const toPrintRef = useRef();
  const dispatch = useDispatch();
    const [spinnerLoader, setspinnerLoader] = React.useState(false);
    const [errorMessages, seterrorMessages] = React.useState([]);
    const [successMessages, setSuccessMessages] = React.useState([]);
    const [paymentCriteriaBgnDate,setPaymentCriteriaBgnDate] = React.useState(null);
    const [paymentCriteriaEndDate,setPaymentCriteriaEndDate] = React.useState(null);
    const [startWithOrContainsErr, setStartWithOrContainsErr] = React.useState(false);
    const [showTable, setShowTable]  = React.useState(false);
    const [searchData, setSearchData] = React.useState([]);
    const [systemList, setSystemList] = React.useState([]);
    const [isSearch, setIsSearch] = React.useState(false);
    const [redirect,setRedirect] = React.useState(0);
    const onReset =() =>  dispatch(resetPaymentCriteriaSearch());
    const onResetResponse = () => dispatch(resetResponse());
    const paymentCriteriaSts = (value) => dispatch(systemListActions(value));
    let hasBeenProcessedSts = useSelector(state => state.appConfigState.systemListState.payload);
    const [
      {
        showBgnDtInvalidErr,
        showEndDtInvalidErr,
        showStartWithOrContainsErr
      },
      setSearchFormShowErr,
    ] = React.useState(false);
    let errorMessagesArray =[];
    const systemError = [];
    const [values,setValues] = React.useState({
        criteriaId:'',
        beginDate:null,
        endDate:null,
        hasBeenProcessed:DEFAULT_DD_VALUE,
        description:'',
        descriptionStartsWithOrContains:''
    });
    useEffect(()=>{
      let deleteMessage = [];
      seterrorMessages([])
      setSuccessMessages([])
      onReset();
      onResetResponse();
      paymentCriteriaSts(dropdowns.PAYMENT_CRITERIA_STS_CD);      
      if (props?.history?.location?.state?.isDelete === true) {
        deleteMessage = [props.history?.location?.state?.deleteMessage];
        setSuccessMessages(deleteMessage)
      }else {
        deleteMessage = []
      }
    },[]);

    useEffect(()=>{
      if(hasBeenProcessedSts && hasBeenProcessedSts.length >0){
       let payload = hasBeenProcessedSts[0]?.systemListDetails;
       setSystemList(payload);
      }
    },[hasBeenProcessedSts]);

    const addPaymentCriteria = ()=> {
        props.history.push({
        pathname:'/PaymentCriteriaAdd'
        });
    };
    const onSearchCriteria = (value)=>{
        dispatch(PaymentCriteriaSearchAction(value));       
    }
    const handleChange = name => event => {        
        if(name === 'description' && event.target.value !== DEFAULT_DD_VALUE){
            const description = event.target.value;
        }
        if(name === 'hasBeenProcessed'){
          const hasBeenProcessed = event.target.value;
        }
        if(name === 'criteriaId'){
          const reg = /^[0-9\b]+$/
          if (event.target.value === '' || reg.test(event.target.value)) {
            setValues({ ...values, [name]: event.target.value });
          }
        }
        setValues({ ...values, [name]: event.target.value });
    };
    const handlePaymentBgnDate = (date) => {
        setPaymentCriteriaBgnDate(date);
      };
      const handlePaymentEndDate = (date) => {
        setPaymentCriteriaEndDate(date);
      };
      const paymentCriteriaReset = () =>{
        onReset();
        setValues({
            criteriaId:'',
            description:'',
            hasBeenProcessed:DEFAULT_DD_VALUE,
            descriptionStartsWithOrContains:''
        });
        setPaymentCriteriaBgnDate(null);
        setPaymentCriteriaEndDate(null);
        seterrorMessages([]);
        setSuccessMessages([]);
        setShowTable(false)
        setSearchFormShowErr(false);
        setStartWithOrContainsErr(false);
      }
      const PaymentCriteriaSearch = () =>{   
        seterrorMessages([])     
        setSuccessMessages([]); 
        let showBgnDtInvalidErr = false;
        let showEndDtInvalidErr = false;
        let showStartWithOrContainsErr = false;
        
        if (
         paymentCriteriaBgnDate &&
         paymentCriteriaBgnDate.toString() == "Invalid Date"
       ) {
         showBgnDtInvalidErr = true;
         errorMessagesArray.push(ErrorConstants.BEGIN_DATE_IS_INVALID);
         seterrorMessages(errorMessagesArray);
       }
  
       if (
         paymentCriteriaEndDate &&
         paymentCriteriaEndDate.toString() == "Invalid Date"
       ) {
         showEndDtInvalidErr = true;
         errorMessagesArray.push(ErrorConstants.END_DATE_IS_INVALID);
         seterrorMessages(errorMessagesArray);
       }
  
       // Descrption Contains or Starts
         if(values.descriptionStartsWithOrContains){
             if(values.description.length < 2){
             setStartWithOrContainsErr(true);
             showStartWithOrContainsErr = true;
             errorMessagesArray.push(ErrorConstants.START_WITH_OR_CONTIANS);
             seterrorMessages(errorMessagesArray);
         }}
        
 //payload
         const searchCriteria = {
           criteriaId:values.criteriaId ? values.criteriaId : null,
           beginDate:getDateInMMDDYYYYFormat(paymentCriteriaBgnDate)? getDateInMMDDYYYYFormat(paymentCriteriaBgnDate) :null,
           endDate:getDateInMMDDYYYYFormat(paymentCriteriaEndDate) ? getDateInMMDDYYYYFormat(paymentCriteriaEndDate): null,
           paymentProcessInd:values.hasBeenProcessed === DEFAULT_DD_VALUE ? null : values.hasBeenProcessed,
           description:values.description,
           descStartsOrContains:values.descriptionStartsWithOrContains === 'StartsWith' ? 1 : values.descriptionStartsWithOrContains === 'Contains' ? 0 : null,
       };
       setspinnerLoader(false);
     setSearchFormShowErr({
       showBgnDtInvalidErr: showBgnDtInvalidErr,
       showEndDtInvalidErr: showEndDtInvalidErr,
       showStartWithOrContainsErr:showStartWithOrContainsErr
     });
         if(errorMessagesArray.length === 0){
           setspinnerLoader(true);
           setStartWithOrContainsErr(false);
             onSearchCriteria(searchCriteria);
             setIsSearch(true);
         }
         if(!props.history.state){
           props.history.state = {}
         }
         props.history.state.values = searchCriteria;
         props.location.isCancel=false;
       
      }
      
      const NoRecordError = ()=>{
        const searchErrorDes = [];          
        setSuccessMessages([]);
        searchErrorDes.push(ErrorConstants.NO_RECORD);
        seterrorMessages(searchErrorDes);
      }
      const searchResults = useSelector(state => state.paymentCriteriaState?.paymentCriteriaSearchResult);
      useEffect(()=>{
        if(searchResults && searchResults.length === 0) {
          setspinnerLoader(false);  
          setSearchData([]);   
          setSuccessMessages([]);
            NoRecordError();
            setShowTable(false);
        }
        if(searchResults?.searchResults !== null && (searchResults?.searchResults?.length >0 || searchResults?.searchResults?.length === 0)){
          setspinnerLoader(false);
          setSearchData([]);
        }
        if(searchResults?.searchResults !== null && searchResults?.searchResults?.length === 0){
          setspinnerLoader(false);  
          setSearchData([]);   
            NoRecordError();
            setShowTable(false);
        }
        if(searchResults?.searchResults !== null && searchResults?.searchResults?.length > 0){
            setSearchData(searchResults.searchResults);
            setShowTable(true);
            let valuetoredirect = 0;
                valuetoredirect = valuetoredirect + 1;
            setRedirect(valuetoredirect);
        }
        if (searchResults?.searchResults != null) {
          if (searchResults?.searchResults.searchError !== undefined) {
            systemError.push(ErrorConstants.RETRY_TRANSACTION);
            seterrorMessages(systemError);
            setShowTable(false);
            setspinnerLoader(false);
          }
        }
        if (searchResults?.searchResults != null && searchResults?.searchResults.length === 1 && searchResults?.searchResults.searchError === undefined && !props.location.isCancel) {
          if (isSearch) {
            onResetResponse();
            props.history.push({
              pathname: '/PaymentCriteriaEdit',
              state: { paymentCriteriaData: searchResults?.searchResults, editFlag: '1', values: {...values, beginDate:paymentCriteriaBgnDate,endDate:paymentCriteriaEndDate}},
            });
          }else {
            setSearchData(searchResults?.searchResults);
          }
        }
        if (searchResults?.searchResults && searchResults?.searchResults.systemFailure) {
          systemError.push(ErrorConstants.RETRY_TRANSACTION);
          seterrorMessages(systemError);
          setShowTable(false);
          setspinnerLoader(false);
        }
        if ((searchResults && searchResults.systemFailure) ) {         
          systemError.push(ErrorConstants.GENERIC_SYSTEM_ERROR);
          seterrorMessages(systemError);
          setShowTable(false);
          setspinnerLoader(false);
        }

      },[searchResults]);

      const ValuesFromPaymentCriteria = props?.history?.location.state?.values || props?.history?.state?.values || props.state?.values;
   
      useEffect(() => {
        if (props?.history?.location?.tableRender && !isSearch) {
          if (ValuesFromPaymentCriteria !== undefined && ValuesFromPaymentCriteria !== null) {
            seterrorMessages([]);
            setspinnerLoader(true);
            const tempvalue = {
              criteriaId: ValuesFromPaymentCriteria.criteriaId ? ValuesFromPaymentCriteria.criteriaId: null,
              beginDate: ValuesFromPaymentCriteria.beginDate ? getDateInMMDDYYYYFormat(ValuesFromPaymentCriteria.beginDate) : null,
              endDate: ValuesFromPaymentCriteria.endDate ? getDateInMMDDYYYYFormat(ValuesFromPaymentCriteria.endDate) : null,
              description:ValuesFromPaymentCriteria.description  === DEFAULT_DD_VALUE ? null :  ValuesFromPaymentCriteria.description,
              descStartsOrContains:ValuesFromPaymentCriteria.descriptionStartsWithOrContains === 'StartsWith' ? 1 : ValuesFromPaymentCriteria.descriptionStartsWithOrContains === 'Contains' ? 0 : null,
              paymentProcessInd: ValuesFromPaymentCriteria.hasBeenProcessed  === DEFAULT_DD_VALUE ? null : ValuesFromPaymentCriteria.hasBeenProcessed
            };
            onSearchCriteria(removeLeadingTrailingSpaces(tempvalue));
            const validValues = removeLeadingTrailingSpaces(tempvalue)
            setValues({
              criteriaId:ValuesFromPaymentCriteria.criteriaId,
              beginDate:ValuesFromPaymentCriteria.beginDate,
              endDate:ValuesFromPaymentCriteria.endDate,
              hasBeenProcessed:ValuesFromPaymentCriteria.hasBeenProcessed === null ? DEFAULT_DD_VALUE : ValuesFromPaymentCriteria.hasBeenProcessed,
              description:ValuesFromPaymentCriteria.description,
              descriptionStartsWithOrContains:ValuesFromPaymentCriteria.descriptionStartsWithOrContains === 'StartsWith' ? 0 : ValuesFromPaymentCriteria.descriptionStartsWithOrContains === 'Contains' ? 1 : null,
            });
            setPaymentCriteriaBgnDate(ValuesFromPaymentCriteria.beginDate);
            setPaymentCriteriaEndDate(ValuesFromPaymentCriteria.endDate);
            setShowTable(true);
            setIsSearch(false);
          }
        }
      }, [props?.history?.location?.tableRender]);   
    
      return(
        <>
         <div className="pos-relative w-100 h-100">
        {spinnerLoader ? <Spinner /> : null}
        <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <SuccessMessages successMessages={successMessages} />
        <div className="tab-header">
          <h1 className="tab-heading float-left">Search Payment Criteria</h1>
          <div className="float-right mt-1 pt-1">
            <Button
              title="Add Payment Criteria"
              variant="outlined"
              color="primary"
              className="btn btn-ic btn-add btn-add-new"
              onClick={() => addPaymentCriteria()}
              disabled={global.globalIsReadOnly()}
            >
              Add
            </Button>           
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body" style={{ overflow: 'hidden' }}>
          <form autoComplete="off">
            <div className="form-wrapper">
              <div className="mui-custom-form with-select input-md">
              <TextField
                  id="criteriaId"
                  fullWidth                 
                  label="Criteria ID"
                  value={values.criteriaId}                            
                  placeholder=""
                  inputProps={{ maxLength: 10 }}
                  onChange= {handleChange('criteriaId')}         
                  InputLabelProps={{
                    shrink: true
                  }}
                  type="number"
                  onInput={e => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                 onKeyDown={evt =>
                    (evt.key === '.' 
                    || evt.key === 'e' 
                    || evt.key === 'E' 
                    || evt.key === '-' 
                    || evt.key  === '+') &&
                    evt.preventDefault()
                  }
                />
                </div>                
                <MuiPickersUtilsProvider utils={DateFnsUtils}>                  
                        <div
                          className="mui-custom-form with-date input-md"
                          style={ { marginLeft: '15px' } }
                        >
                          <KeyboardDatePicker
                            maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                            id="beginDate"
                            fullWidth
                            label="Begin Date"
                            InputLabelProps={ {
                              shrink: true
                            } }
                            placeholder="mm/dd/yyyy"
                            format="MM/dd/yyyy"
                            value={paymentCriteriaBgnDate}
                            onChange={ handlePaymentBgnDate }
                            KeyboardButtonProps={ {
                              'aria-label': 'change date'
                            } }
                            helperText={
                              showBgnDtInvalidErr
                               ? ErrorConstants.BEGIN_DATE_IS_INVALID
                               : null
                             }
                             error={
                              showBgnDtInvalidErr
                                 ? ErrorConstants.BEGIN_DATE_IS_INVALID
                                 : null
                             }
                          />
                        </div>
                        <div
                          className="mui-custom-form with-date input-md"
                          style={ { marginLeft: '15px' } }
                        >
                          <KeyboardDatePicker
                            maxDate={ Date.parse('31 Dec 9999 00:00:00 GMT') }
                            id="endDate"
                            fullWidth
                            label="End Date"
                            InputLabelProps={ {
                              shrink: true
                            } }
                            placeholder="mm/dd/yyyy"
                            format="MM/dd/yyyy"
                            value={paymentCriteriaEndDate}
                            onChange={ handlePaymentEndDate }
                            KeyboardButtonProps={ {
                              'aria-label': 'change date'
                            } }
                            helperText={
                              showEndDtInvalidErr
                              ? ErrorConstants.END_DATE_IS_INVALID
                              : null
                            }
                            error={
                                showEndDtInvalidErr
                                ? ErrorConstants.END_DATE_IS_INVALID
                                : null
                            }
                          />
                        </div>
                </MuiPickersUtilsProvider>
                <NativeDropDown
                id="hasBeenProcessed"
                label="Has been processed"
                onChange= {handleChange('hasBeenProcessed')}
                value={values.hasBeenProcessed }
                placeholder=""
                inputProps={{ maxlength: 3 }}
                InputLabelProps={{
                    shrink: true
                }}
                dropdownList={systemList.map(option => (
                    <option key={option.startingValue} value={option.startingValue}>
                    {option.startingValue}
                    </option>
                ))}
                />
                </div>
                <div className='form-wrapper'>
                <div className="mui-custom-form with-select input-md" >
                    <TextField
                      id="description"
                      fullWidth
                      value={values.description}
                      label="Description"
                      name='payeeName'
                      helperText={startWithOrContainsErr ? ErrorConstants.START_WITH_OR_CONTIANS : null}
                      error={startWithOrContainsErr ? ErrorConstants.START_WITH_OR_CONTIANS : null}
                      onChange={handleChange('description')}
                      inputProps={{ maxLength: 60 }}
                      placeholder=""
                      InputLabelProps={{
                        shrink: true
                      }}
                    >
                    </TextField>
                    <div className="sub-radio">
                      <Radio
                        value="StartsWith"
                        id="descriptionStarts"
                        checked={values.descriptionStartsWithOrContains === 'StartsWith' || values.descriptionStartsWithOrContains === 0}
                        inputProps={{ maxLength: 1 }}
                        onChange={handleChange('descriptionStartsWithOrContains')}
                      /><label className="text-black" for="descriptionStarts">Starts With</label>
                      <Radio
                        value="Contains"
                        id="descriptionContains"
                        inputProps={{ maxLength: 1 }}
                        checked={values.descriptionStartsWithOrContains === 'Contains' || values.descriptionStartsWithOrContains === 1}
                        onChange={handleChange('descriptionStartsWithOrContains')}
                        className="ml-2"
                      /><label for="descriptionContains" className="text-black">Contains</label>
                    </div>
                  </div>
                </div>
            </form>
            <div className="float-right mr-4 mb-3">
            <Button
              variant="outlined"
              color="primary"
              className="btn btn-primary ml-1"
              onClick={()=>{
                PaymentCriteriaSearch()
              }}
            >
              <i class="fa fa-search" aria-hidden="true"></i>
              Search
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="bt-reset btn-transparent ml-1"
              onClick={()=>{paymentCriteriaReset()}}
            >
              <i class="fa fa-undo" aria-hidden="true"></i> Reset
            </Button>
          </div>
          <div className="clearfix"></div>
                {showTable ? (<div><PaymentCriteriaSearchTable tableData={searchData} values={{ ...values, beginDate: paymentCriteriaBgnDate, endDate: paymentCriteriaEndDate }} />  </div>) : null}
        </div>
        <Footer print />
        </div>
        </div></>
    )
}

export default withRouter(PaymentCriteriaSearch);