import React, { useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import NavigationPrompt from "react-router-navigation-prompt";
import TextField from "@material-ui/core/TextField";
import Footer from "../../../../SharedModules/Layout/footer";

function GlobalAuditDetails(props) {
  const [details,] = React.useState(props.location && props.location.auditDetails ? props.location.auditDetails : {});

  const dispatch = useDispatch();
  const [confirm, ] = useState(false);
  const [, setspinnerLoader] = React.useState(false);
  const [prompt, setPrompt] = React.useState(false);
  const [cancelType, setCancelType] = React.useState(false);
  var mystring = details && details.recordKey ? details.recordKey : "";
  mystring = mystring.replace("{", "");
  mystring = mystring.replace("}", "");

  var mySubStrArray = mystring.split(", ");
  var finalArray = [];
  mySubStrArray.forEach(function (val, index) {
    finalArray.push(val.split("="));
  });

  var keyType = finalArray[0];
  var keyValue = finalArray[1];

  const handelPromptSet = () => {
    setPrompt(true);
  };

  const printRef = useRef();

  return (
    // pos-relative div start
    <div className="pos-relative custom-page">
      <div className="tabs-container" ref={printRef}>
        <div className="page-header">
          <h1 className="page-heading float-left">Audit Details</h1>
          <div className="float-right th-btnGroup">
            <NavigationPrompt
              when={(crntLocation, nextLocation) => {
                handelPromptSet(nextLocation);
                return true;
              }}
            >
              {({ onConfirm, onCancel }) => (
                <Dialog
                  open={prompt}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className="custom-alert-box"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {
                        <>
                          Unsaved changes will be lost. <br />
                          Are you sure you want to continue?
                        </>
                      }
                    </DialogContentText>
                  </DialogContent>
                  {
                    <DialogActions>
                      <Button
                      data-testid="popupcancel"
                        onClick={() => {
                          setPrompt(false);
                          setCancelType(false);
                          onCancel();
                        }}
                        color="primary"
                        className="btn btn-transparent"
                      >
                        STAY ON THIS PAGE!
                      </Button>
                      <Button
                        onClick={() => {
                          setPrompt(true);
                          onConfirm();}}
                        color="primary"
                        data-testid="continueClick"
                        className="btn btn-success"
                        autoFocus
                      >
                        CONTINUE <i className="fa fa-arrow-right ml-1"></i>
                      </Button>
                    </DialogActions>
                  }
                </Dialog>
              )}
            </NavigationPrompt>
            <Button
              title="Cancel"
              data-testid="cancelMinor"
              variant="outlined"
              color="primary"
              className="btn btn-cancel"
              onClick={() =>
                props.history.push({ pathname: "/GlobalAuditSearch" })
              }
            >
              CANCEL
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="tab-body-bordered mt-2">
          <div className="form-wrapper">
            <div className="mui-custom-form input-md">
              <TextField
                disabled
                id="table-name"
                data-testid='TableNames'
                label="Table Name"
                value={ details && details.tableName ? details.tableName : ""}
                inputProps={{ maxLength: 30 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="mui-custom-form input-md">
              <TextField
                disabled
                id="field-id"
                data-testid='FieldNames'
                label="Field"
                value={details && details.field ? details.field : null}
                inputProps={{ maxLength: 30 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="mui-custom-form input-md">
              <TextField
                disabled
                id="types-of-operation"
                data-testid="types-of-operation"
                label="Type Of Operation"
                value={details && details.operationType ? details.operationType : null}
                inputProps={{ maxLength: 2 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="clearfix line-break-div" />
            <div className="mui-custom-form input-md">
              <TextField
                disabled
                id="before-id"
                data-testid="before-id"
                label="Before"
                value={details && details.beforeValue ? details.beforeValue : null}
                inputProps={{ maxLength: 30 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="mui-custom-form input-md">
              <TextField
                disabled
                id="after-id"
                data-testid="after-id"
                label="After"
                value={details && details.afterValue ? details.afterValue : null}
                inputProps={{ maxLength: 30 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="clearfix line-break-div" />
            <div className="mui-custom-form input-md">
              <TextField
                disabled
                id="key-type-0"
                data-testid="key-type-0"
                label="Key Type"
                value={keyType && keyType[0]}
                inputProps={{ maxLength: 30 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="mui-custom-form input-md">
              <TextField
                disabled
                id="key-0"
                data-testid="key-0"
                label="Key"
                value={keyType && keyType[1]}
                inputProps={{ maxLength: 30 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="clearfix line-break-div" />

            <div className="mui-custom-form input-md">
            <label class="visuallyhidden" for="key-type-1">key Type 1</label>
              <TextField
                className="set-no-margin"
                disabled
                id="key-type-1"
                data-testid="key-type-1"
                //label="Key Type"
                value={keyValue && keyValue[0]}
                inputProps={{ maxLength: 30 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div className="mui-custom-form input-md">
            <label class="visuallyhidden" for="key-1">key 1</label>
              <TextField
                className="set-no-margin"
                disabled
                id="key-1"
                data-testid="key-1"
                //label="Key Type"
                value={keyValue && keyValue[1]}
                inputProps={{ maxLength: 30 }}
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </div>
        </div>
        <Footer print />
      </div>
    </div>
  );
}
export default withRouter(GlobalAuditDetails);
