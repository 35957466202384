/* eslint-disable no-unused-vars */
/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useState, useEffect, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from 'react-bootstrap';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as FISearchConstants from './FISearchConstants';
import ErrorMessages from '../../../../SharedModules/Errors/ErrorMessages';
import Spinner from '../../../../SharedModules/Spinner/Spinner';
import { useSelector, useDispatch } from 'react-redux';
import { keySort } from '../../../../SharedModules/Table/MultipleTableSort';
import { getDropdownDataAction, FinancialInquirySearchAction, GetPayeeIdTypeCodeAction, FinancialClaimDetails } from '../../Store/Actions/FinancialInquiry/FinancialInquiryActions';
import FinancialInquirySearchTable from './FinancialInquirySearchTable';
import dropdownCriteria from '../ClaimSummary.json';
import Footer from '../../../../SharedModules/Layout/footer';
import * as AppConstants from '../../../../SharedModules/AppConstants';
import { isArray, isObject } from 'util';
import { removeLeadingTrailingSpaces } from '../../../../SharedModules/CommonUtilities/commonUtilities';

import { GET_APP_DROPDOWNS } from "../../../../SharedModules/Dropdowns/actions";
import * as Dropdowns from "../../../../SharedModules/Dropdowns/dropdowns";
import NativeDropDown from '../../../../SharedModules/Dropdowns/NativeDropDown';

function ClaimFinancialInquirySearch (props) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const [showTable, setShowTable] = React.useState(false);
  const [{ showPayeeIdTypeCodeError, showPayeeIdError, showPayeeNameError, showPayeeTypeError }, setShowError] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [inquiryTableData, setInquiryTableData] = React.useState([]);
  const [spinnerLoader, setSpinnerLoader] = React.useState(false);
  const [claimsRedirect, setClaimsRedirect] = React.useState(0);
  const [isSearch, setIsSearch] = React.useState(false);
  const [values, setValues] = React.useState({
    lob: DEFAULT_DD_VALUE,
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE,
    payeeId: '',
    payeeName: '',
    checkStartsWith: false,
    ssn: '',
    fein: ''
  });
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  
  let errorMessagesArray = [];
  const [searchDetails, setSearchDetails] = React.useState({});
  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  const dropdown = useSelector(state => state.financialInquiryState.dropdownData);
  const onSearchView = searchValues => dispatch(FinancialInquirySearchAction(removeLeadingTrailingSpaces(searchValues)));
  const inquiryViewData = useSelector(state => state.financialInquiryState.searchRecord);
  const onChangePayeeType = (payeeType) => dispatch(GetPayeeIdTypeCodeAction(payeeType));
  const payeeTypeIDDropdown = useSelector(state => state.financialInquiryState.payeeIDTypeCodeDetails);
  const getSummaryDetails = (data) => dispatch(FinancialClaimDetails(data));
  let summaryDetails = useSelector(state => state.financialInquiryState.summaryDetails);
  const onDropdowns = (values) => dispatch(GET_APP_DROPDOWNS(values));
  const addDropdowns = useSelector(state => state.appDropDowns.appdropdowns);
  const [PayeeTypeReference, setPayeeTypeReference] = React.useState([]);
  const [payeeTypeIDDropdownReference, setpayeeTypeIDDropdownReference] = React.useState([]);
  const [payeeIdTypeMemberCode, setPayeeIdTypeMemberCode] = React.useState([]);
  const [payeeIdTypeProviderCode, setPayeeIdTypeProviderCode] = React.useState([]);
  const [payeeIdTypeOtherCode, setPayeeIdTypeOtherCode] = React.useState([]);
  const [payeeIdTypeTPLCode, setPayeeIdTypeTPLCode] = React.useState([]);

  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeReference(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#2']) {
        setPayeeIdTypeMemberCode(dropdown.listObj['Financial#2']);
      }
      if (dropdown.listObj['Financial#3']) {
        setPayeeIdTypeProviderCode(dropdown.listObj['Financial#3']);
      }
      if (dropdown.listObj['Financial#1024']) {
        setPayeeIdTypeOtherCode(dropdown.listObj['Financial#1024']);
      }
      if (dropdown.listObj['Financial#1023']) {
        setPayeeIdTypeTPLCode(dropdown.listObj['Financial#1023']);
      }
    }
  }, [dropdown]);

  useEffect(() => {
    if (values.payeeType || values.payeeType === DEFAULT_DD_VALUE) {
      setValues({ ...values, payeeIdTypeCode: values.payeeIdTypeCode || DEFAULT_DD_VALUE });
      setpayeeTypeIDDropdownReference([]);
      if (values.payeeType !== DEFAULT_DD_VALUE) {
        onChangePayeeType(values.payeeType);
      }
    }
  }, [values.payeeType]);

  useEffect(() => {
    if (payeeTypeIDDropdown && payeeTypeIDDropdown.length > 0) {
      setpayeeTypeIDDropdownReference(payeeTypeIDDropdown);
    }
  }, [payeeTypeIDDropdown]);

  useEffect(() => {
    getDropdownData(dropdownCriteria);
  }, []);

  const handleChange = name => event => {
    if (name === 'checkStartsWith') {
      setValues({ ...values, checkStartsWith: !values.checkStartsWith });
    } else {
      setValues({ ...values, [name]: event.target.value });
    }
  };

  const searchInquiry = () => {
    props.location.tableRender = false;
    var showPayeeIdTypeCodeError = false;
    var showPayeeIdError = false;
    var showPayeeNameError = false;
    var showPayeeTypeError = false;
    var showPayeeError = false;

    errorMessagesArray = [];
    setErrorMessages(errorMessagesArray);
    setShowTable(false);
    setShowError(false);
    setSpinnerLoader(false);

    if (
      (values.payeeType &&
        values.payeeType !== DEFAULT_DD_VALUE) ||
      (values.payeeIdCode &&
        values.payeeIdTypeCode !== DEFAULT_DD_VALUE) ||
      (values.payeeId !== null && values.payeeId !== '')
    ) {
      if (
        !values.payeeType ||
        values.payeeType === DEFAULT_DD_VALUE
      ) {
        showPayeeTypeError = true;
        errorMessagesArray.push(FISearchConstants.PAYEE_TYPE_REQUIRED);
      }
      if (
        !values.payeeIdTypeCode ||
        values.payeeIdTypeCode === DEFAULT_DD_VALUE
      ) {
        showPayeeIdTypeCodeError = true;
        errorMessagesArray.push(FISearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED);
      }
      if (values.payeeId === null || values.payeeId === '') {
        showPayeeIdError = true;
        errorMessagesArray.push(FISearchConstants.PAYEE_REQUIRED);
      }
    }

    if (values.checkStartsWith && (!values.payeeName || values.payeeName.trim().length < 2)) {
      showPayeeNameError = true;
      errorMessagesArray.push(FISearchConstants.PAYEE_NAME_ERROR);
      setErrorMessages(errorMessagesArray);
    }

    if (errorMessagesArray.length === 0) {
      setSpinnerLoader(true);
      setIsSearch(true);
      const searchCriteria = {
        lob: AppConstants.DEFAULT_LOB_VALUE,
        payeeType: values.payeeType !== '' && values.payeeType !== DEFAULT_DD_VALUE ? values.payeeType : null,
        payeeIdTypeCode: values.payeeIdTypeCode !== '' && values.payeeIdTypeCode !== DEFAULT_DD_VALUE ? values.payeeIdTypeCode : null,
        payeeID: values.payeeId,
        payeeName: values.payeeName,
        startsWith: !!values.checkStartsWith,
        ssn: values.ssn,
        fein: values.fein,
        payee_ID: values.payeeId
      }
      onSearchView(searchCriteria);
      if(!props.history.state){
        props.history.state = {}
      }
      props.history.state.values = searchCriteria;
      const valuetoredirect = 1;
      setClaimsRedirect(valuetoredirect);
    }

    setShowError({ showPayeeIdTypeCodeError, showPayeeIdError, showPayeeNameError, showPayeeError, showPayeeTypeError });
  };

  const valuesFromFinInq = props?.history?.state?.values;

  useEffect(()=>{
    onDropdowns([
      Dropdowns.PAYEE_TYPE,
      Dropdowns.PAYEE_ID_TYPE_CD_PROVIDER,
      Dropdowns.PAYEE_ID_TYPE_CD_MEMBER,
      Dropdowns.PAYEE_ID_TYPE_CD_OTHER,
      Dropdowns.PAYEE_ID_TYPE_CD_TPL,
      Dropdowns.F_RSN_CD
    ]);
  }, []);

  useEffect(() => {
    if (props.location.tableRender && !isSearch) {
      if (valuesFromFinInq !== undefined) {
        setClaimsRedirect(1);
        onSearchView({
          lob: AppConstants.DEFAULT_LOB_VALUE,
          payeeType: valuesFromFinInq.payeeType !== '' && valuesFromFinInq.payeeType !== DEFAULT_DD_VALUE ? valuesFromFinInq.payeeType : null,
          payeeIdTypeCode: valuesFromFinInq.payeeIdTypeCode !== '' && valuesFromFinInq.payeeIdTypeCode !== DEFAULT_DD_VALUE ? valuesFromFinInq.payeeIdTypeCode : null,
          payeeID: valuesFromFinInq.payeeID,
          payeeName: valuesFromFinInq.payeeName,
          startsWith: valuesFromFinInq.startsWith,
          ssn: valuesFromFinInq.ssn,
          fein: valuesFromFinInq.fein,
          payee_ID: valuesFromFinInq.payee_ID
        });
        setValues({ ...valuesFromFinInq,
          lob: AppConstants.DEFAULT_LOB_VALUE,
          payeeType: valuesFromFinInq.payeeType || DEFAULT_DD_VALUE,
          payeeIdTypeCode: valuesFromFinInq.payeeIdTypeCode || DEFAULT_DD_VALUE,
          payeeId: valuesFromFinInq.payeeID || '',
          payeeName: valuesFromFinInq.payeeName || '',
          checkStartsWith: valuesFromFinInq.startsWith || false,
          ssn: valuesFromFinInq.ssn || '',
          fein: valuesFromFinInq.fein || ''
        });
        setSpinnerLoader(true);
      }
    }
  }, [props.location.tableRender]);

  const resetInquiry = () => {
    setValues(
      {
        lob: DEFAULT_DD_VALUE,
        payeeType: DEFAULT_DD_VALUE,
        payeeIdTypeCode: DEFAULT_DD_VALUE,
        payeeId: '',
        payeeName: '',
        checkStartsWith: false,
        ssn: '',
        fein: ''
      }
    );
    setShowTable(false);
    setShowError(false);
    setErrorMessages([]);
  };

  useEffect(() => {     
    if (inquiryViewData && claimsRedirect === 1) {
      setSpinnerLoader(false);
      if (inquiryViewData.systemFailure) {
        errorMessagesArray.push(FISearchConstants.GENERIC_SYSTEM_ERROR);
        setErrorMessages(errorMessagesArray);
      } else
      if (inquiryViewData.length === 0) {
        errorMessagesArray.push(FISearchConstants.NO_RECODRS_FOUND);
        setErrorMessages(errorMessagesArray);
      } else if (inquiryViewData.length === 1 && isObject(inquiryViewData[0])) {
        for (const [key, value] of Object.entries(inquiryViewData[0])) {
          if (isArray(value)) {
            value.map((item) => {
              item.auditUserID = undefined;
              item.addedAuditUserID = undefined;
              item.auditTimeStamp = undefined;
              item.addedAuditTimeStamp = undefined;
              item.dbRecord = undefined;
              item.sortColumn = undefined;
              item.versionNo = undefined;
            });
          }
        }
        const inquiryView = JSON.parse(JSON.stringify(inquiryViewData));

        inquiryView.searchDetails = {
          lob: values.lob,
          payeeType: inquiryView[0].payeeType || (values.payeeType !== '' && values.payeeType !== DEFAULT_DD_VALUE ? values.payeeType : null),
          payeeIdTypeCode: inquiryView[0].payeeIdTypeCode || (values.payeeIdTypeCode !== '' && values.payeeIdTypeCode !== DEFAULT_DD_VALUE ? values.payeeIdTypeCode : null),
          payeeID: values.payeeId,
          payeeName: inquiryView[0].payeeName || values.payeeName,
          ssn: values.ssn,
          fein: values.fein
        };

        if (!props.location.tableRender) {
          props.history.push({
            pathname: '/ClaimSummary',
            state: { summaryDetails: inquiryView, values: values, addDropdowns:addDropdowns }
          });
        } else {
          setShowTable(true);
          setInquiryTableData(inquiryViewData);
        }
      } else if (inquiryViewData.length > 1 && !inquiryViewData.systemFailure) {
        setShowTable(true);
        const tempInquiryData = inquiryViewData;
        tempInquiryData.map((data) => {
          PayeeTypeReference.filter(payee => {
            if (payee.code === data.payeeType) {
              data.payeeType = payee.description;
              return data.payeeType;
            }
          });
          if (data.payeeType === 'M-Member') {
            payeeIdTypeMemberCode.filter(pTypeId => {
              if (pTypeId.code === data.payeeIdTypeCode) {
                data.payeeIdTypeCode = pTypeId.description;
                return data.payeeIdTypeCode;
              }
            });
          } else if (data.payeeType === 'P-Provider') {
            payeeIdTypeProviderCode.filter(pTypeId => {
              if (pTypeId.code === data.payeeIdTypeCode) {
                data.payeeIdTypeCode = pTypeId.description;
                return data.payeeIdTypeCode;
              }
            });
          } else if (data.payeeType === 'O-Other') {
            payeeIdTypeOtherCode.filter(pTypeId => {
              if (pTypeId.code === data.payeeIdTypeCode) {
                data.payeeIdTypeCode = pTypeId.description;
                return data.payeeIdTypeCode;
              }
            });
          } else if (data.payeeType === 'TC-TPLCarrier') {
            payeeIdTypeTPLCode.filter(pTypeId => {
              if (pTypeId.code === data.payeeIdTypeCode) {
                data.payeeIdTypeCode = pTypeId.description;
                return data.payeeIdTypeCode;
              }
            });
          }
          data.fein = (data.fein !== null && data.fein !== '') ? data.fein.substr(0, 2) + '-' + data.fein.substr(2, data.fein.length) : '';
        });

        setInquiryTableData(tempInquiryData.keySort({ payeeType: 'asc', payeeIdTypeCode: 'asc', payeeID: 'asc' }));
      }
    }
  }, [inquiryViewData]);

  const onRowClick = row => event => {
     
    setSpinnerLoader(true);
    getSummaryDetails(row);
    // let payeeType = '';
    let payeeIdTCode = '';
         //  addDropdowns['G1#G_CMN_ENTY_TY_CD'] && addDropdowns['G1#G_CMN_ENTY_TY_CD'].length >0 &&  addDropdowns['G1#G_CMN_ENTY_TY_CD'].filter(payee => {
      //   if (payee.description === row.payeeType || (payee.description === row.payeeTypeDesc) ) {
      //     payeeType = payee.code; return payeeType;
      //     } });
    // PayeeTypeReference.filter(payee => { if (payee.description === row.payeeType) { payeeType = payee.code; return payeeType; } });
    if (row.payeeType === 'M-Member') {
      payeeIdTypeMemberCode.filter(pTypeId => {
        if (pTypeId.description === row.payeeIdTypeCode) {
          payeeIdTCode = pTypeId.code;
          return payeeIdTCode;
        }
      });
    } else if (row.payeeType === 'P-Provider') {
      payeeIdTypeProviderCode.filter(pTypeId => {
        if (pTypeId.description === row.payeeIdTypeCode) {
          payeeIdTCode = pTypeId.code;
          return payeeIdTCode;
        }
      });
    } else if (row.payeeType === 'O-Other') {
      payeeIdTypeOtherCode.filter(pTypeId => {
        if (pTypeId.description === row.payeeIdTypeCode) {
          payeeIdTCode = pTypeId.code;
          return payeeIdTCode;
        }
      });
    } else if (row.payeeType === 'TC-TPLCarrier') {
      payeeIdTypeTPLCode.filter(pTypeId => {
        if (pTypeId.description === row.payeeIdTypeCode) {
          payeeIdTCode = pTypeId.code;
          return payeeIdTCode;
        }
      });
    }
     
    setSearchDetails({
      lob: row.lob,
      payeeType: row.payeeTypeDesc,
      payeeIdTypeCode: payeeIdTCode ? payeeIdTCode : row.payeeIdTypeCode || row.payeeIDTypeCode,
      payeeID: row.payeeID,
      payeeName: row.payeeName,
      ssn: row.ssn,
      fein: row.fein
    });
  };

  useEffect(() => {
         if (summaryDetails) {
      setSpinnerLoader(false);
      if (isArray(summaryDetails) && summaryDetails.length === 1 && isObject(summaryDetails[0])) {
        for (const [key, value] of Object.entries(summaryDetails[0])) {
          if (isArray(value)) {
            value.map((item) => {
              item.auditUserID = undefined;
              item.addedAuditUserID = undefined;
              item.auditTimeStamp = undefined;
              item.addedAuditTimeStamp = undefined;
              item.dbRecord = undefined;
              item.sortColumn = undefined;
              item.versionNo = undefined;
            });
          }
        }
        summaryDetails = JSON.parse(JSON.stringify(summaryDetails));
        summaryDetails.searchDetails = searchDetails;
        props.history.push({
          pathname: '/ClaimSummary',
          state: { summaryDetails, values: values ,addDropdowns:addDropdowns}
        });
      } else {
        setErrorMessages([FISearchConstants.GENERIC_SYSTEM_ERROR]);
      }
    }
  }, [summaryDetails]);

  return (
    <div className="pos-relative w-100 h-100">
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container" ref={toPrintRef}>
        <ErrorMessages errorMessages={errorMessages} />
        <div className="tab-header">
          <h1 className="tab-heading float-left">
            Financial Inquiry
          </h1>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
            <div className='form-wrapper'>
              <NativeDropDown
                id="payeeType"
                  label="Payee Type"
                  value={values.payeeType}
                  onChange={handleChange('payeeType')}
                  InputLabelProps={{
                    shrink: true
                  }}
                errTxt={showPayeeTypeError && (values.payeeType === DEFAULT_DD_VALUE || values.payeeType === '') ? FISearchConstants.PAYEE_TYPE_REQUIRED : null}
                dropdownList={PayeeTypeReference.map(option => (
                  <option key={option.code} value={option.code}>
                      {option.description}
                  </option>
                  ))}

              />
              <NativeDropDown
                id="payeeIdCode"
                  label="Payee ID Type Code"
                  value={values.payeeIdTypeCode}
                disabled={values.payeeType === DEFAULT_DD_VALUE}
                  onChange={handleChange('payeeIdTypeCode')}
                  InputLabelProps={{
                    shrink: true
                  }}
                errTxt={showPayeeIdTypeCodeError && (values.payeeIdTypeCode === DEFAULT_DD_VALUE || values.payeeIdTypeCode === '') ? FISearchConstants.PAYEE_ID_TYPE_CODE_REQUIRED : null}
                dropdownList={payeeTypeIDDropdownReference ? payeeTypeIDDropdownReference.map(option => (
                  <option key={option.code} value={option.code}>
                      {option.description}
                  </option>
                  )) : null}

              />
              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeId"
                  fullWidth
                  label="Payee ID"
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={values.payeeId}
                  onChange={handleChange('payeeId')}
                  inputProps={{ maxLength: 15 }}
                  helperText={showPayeeIdError && (values.payeeId === DEFAULT_DD_VALUE || values.payeeId === '') ? FISearchConstants.PAYEE_REQUIRED : null}
                  error={showPayeeIdError && (values.payeeId === DEFAULT_DD_VALUE || values.payeeId === '') ? FISearchConstants.PAYEE_REQUIRED : null}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="payeeName"
                  fullWidth
                  label="Payee Name"
                  value={values.payeeName}
                  onChange={handleChange('payeeName')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 60 }}
                  helperText={showPayeeNameError ? FISearchConstants.PAYEE_NAME_ERROR : null}
                  error={showPayeeNameError ? FISearchConstants.PAYEE_NAME_ERROR : null}
                />
                <div className="sub-radio">
                  <FormControlLabel
                    control={<Checkbox color="primary" />}
                    checked={values.checkStartsWith} value={values.checkStartsWith} onChange={handleChange('checkStartsWith')}
                    label="Starts With"
                    labelPlacement="end"
                  />
                </div>
              </div>
            </div>
            <div className="form-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="ssn"
                  fullWidth
                  label="SSN"
                  value={values.ssn}
                  onChange={handleChange('ssn')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 11 }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="fein"
                  fullWidth
                  label="FEIN"
                  value={values.fein}
                  onChange={handleChange('fein')}
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 10 }}
                />
              </div>
            </div>
            <div className="float-right mr-3 mb-2">
              <Button className='btn btn-primary' onClick={() => searchInquiry()}>
                <i class="fa fa-search" aria-hidden="true"></i>
                Search </Button>
              <Button className='bt-reset btn-transparent  ml-1' onClick={() => resetInquiry()}>
                <i class="fa fa-undo" aria-hidden="true"></i>
                Reset </Button>
            </div>

            <div className="clearfix"></div>
          </form>

          <div className={'mt-3 table-p-5 override-width-100'} style={{ padding: '0px 29px' }}>
            {
              showTable ? <FinancialInquirySearchTable values={values} dropdowns= {addDropdowns}
                tableData={inquiryTableData} onRowClick={onRowClick} /> : null
            }
          </div>
        </div>
        <Footer print />
      </div>
    </div>

  );
}

export default ClaimFinancialInquirySearch;
