/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
/**

* Â© [2020] Conduent, Inc. All rights reserved. ConduentÂ®and Conduent Agile

* StarÂ®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { Component, useEffect, useState,useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import {
  DialogTitle,
  DialogContent
} from '../../../../../../SharedModules/Dialog/DialogUtilities';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Bootstrap, { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { getLongDescription, removeLeadingTrailingSpaces } from '../../../../../../SharedModules/CommonUtilities/commonUtilities';
import * as errorConstants from '../ErrorCheck';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessages from '../../../../../../SharedModules/Errors/ErrorMessages';
import { getDropdownDataAction, getReasonCodeMapDataAction, getPayeeIdType,
  validatePayeeDetails, goToNextPageAction } from '../../../../Store/Actions/accountReceivable/accountReceivableActions';
import { searchSingleRecordFinancialEntity } from "../../../../../FinancialEntities/Store/Actions/FinancialEntityActions";
import { searchSystemParameterAction9047 } from '../../../../../ApplicationConfiguration/Store/Actions/systemParameter/systemParameterActions';
import dropdownCriteria from './EntryLayout.json';
import * as AppConstants from '../../../../../../SharedModules/AppConstants';
import Spinner from '../../../../../../SharedModules/Spinner/Spinner';
import SystemPayeeTable from '../../../../../../SharedModules/SystemIDTable/SystemPayeeIDTable';
import { Prompt } from 'react-router-dom';
import NativeDropDown from '../../../../../../SharedModules/Dropdowns/NativeDropDown';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    textAlign: 'left'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  container2: {
    textAlign: 'left',
    float: 'left',
    marginTop: '10px'
  },
  resultfield: {
    borderTop: '2px'
  }
}));

function Original (props, { onChangeValue }) {
  const DEFAULT_DD_VALUE = process.env.REACT_APP_DROPDOWN_DEFAULT_VALUE;
  const classes = useStyles();
  const [showTable, setShowTable] = React.useState(false);
 const initialValues={
   lob: DEFAULT_DD_VALUE,
   payeeType: DEFAULT_DD_VALUE,
   payeeIdTypeCode: DEFAULT_DD_VALUE,
    payeeId: ''
  }
  const [values, setValues] = React.useState(initialValues);
  const initialpayeeValues = {
    payeeType: DEFAULT_DD_VALUE,
    payeeIdTypeCode: DEFAULT_DD_VALUE
  }

  const [payeeValues, setPayeeValues] = React.useState(initialpayeeValues);
  const initialactivityReasonCodeValues={
    activityCode: DEFAULT_DD_VALUE,
    reasonCode: DEFAULT_DD_VALUE
  }

  const [activityReasonCodeValues, setActivityReasonCodeValues] = React.useState(initialactivityReasonCodeValues);
  const [spinnerLoader, setspinnerLoader] = React.useState(false);
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const[isContinue, setIsContinue] =React.useState(false);
  const [lobCodeData, setLobCodeData] = React.useState([]);
  const [payeeTypeData, setPayeeTypeData] = React.useState([]);
  const [activityTypeData, setActivityTypeData] = React.useState([]);
  const [reasonCodeData, setReasonCodeData] = React.useState([]);
  const [payeeTypeIdCodeData, setPayeeTypeIdCodeData] = React.useState([]);
  const [initialReasonCodeArray, setInitialReasonCodeArray] = React.useState([]);
  const [redirect, setRedirect] = React.useState(0);
  const [payeeTypeDesc, setPayeeTypeDesc] = React.useState('');
  const [payeeTypeIdCodeDesc, setPayeeTypeIdCodeDesc] = React.useState('');
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [focusCheck, setFocusCheck] = React.useState(false);

  const dispatch = useDispatch();
  var activityValue = '';
  const getDropdownData = (dropdownValues) => dispatch(getDropdownDataAction(dropdownValues));
  const getReasonCodeMapData = (value) => dispatch(getReasonCodeMapDataAction(value));

  const onPayeeTypeIdDropDown = (value) => dispatch(getPayeeIdType(value));
  const onGotoNextPage = (value) => dispatch(goToNextPageAction(removeLeadingTrailingSpaces(value)));

  let dropdown = [];
  useEffect(() => {
    getDropdownData(dropdownCriteria);
    searchSystemParamForFinEntity(searchSystemParameterActionData)
  }, []);

  const errorMessagesArray = [];
  const [{
    showLobError, showPayeeTypeError,
    showPayeeIdTypeCodeError, showPayeeIdError, showActivityTypeError, showReasonCodeError
  }, setShowError] = React.useState(false);
  const [{
    showLobErrorText, showPayeeTypeErrorText,
    showPayeeIdTypeCodeErrorText, showPayeeIdErrorText,
    showActivityTypeErrorText, showReasonCodeErrorText
  }, setShowErrorText] = React.useState('');
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [showPayeePaymentStatus,setShowPayeePaymentStatus]=React.useState('');
  const [payeeIDErrorMessages, setPayeeIDErrorMessages] = React.useState([]);
  dropdown = useSelector(state => state.accountReceivableState.dropdownData);
  const reasonCodeMapdropdown = useSelector(state => state.accountReceivableState.reasonCodeMapdropdownData);
  const searchSingleFinancialEntityRes = useSelector(state => state.financialEntityState.singleSearchResults);
  const systemParamaterPayload = useSelector(state => state.appConfigState?.systemParameterState?.data);
  const searchSystemParameterActionData = {
    parameterNumber: "9047", functionalArea: "F1"
  }
  const searchSystemParamForFinEntity = value => {
    dispatch(searchSystemParameterAction9047(value));
  };
  useEffect(() => {
    if (dropdown && dropdown.listObj) {
      if (dropdown.listObj['Reference#1019']) {
        setLobCodeData(dropdown.listObj['Reference#1019']);
      }
      if (dropdown.listObj['General#6002']) {
        setPayeeTypeData(dropdown.listObj['General#6002']);
      }
      if (dropdown.listObj['Financial#1131']) {
        setActivityTypeData(dropdown.listObj['Financial#1131']);
      }
    }
  }, [dropdown]);

  useEffect(()=>{
    if(systemParamaterPayload){
      setShowPayeePaymentStatus(systemParamaterPayload[0]?.systemParameterDetail[0]?.valueData)
    }
  },[systemParamaterPayload])
  useEffect(() => {
    if (searchSingleFinancialEntityRes) {
      setspinnerLoader(false)
      let payeeStatus = '';
      if (showPayeePaymentStatus === 'Y') {
        payeeStatus = searchSingleFinancialEntityRes.payeePaymentStatus;
      }
      if (activityReasonCodeValues.activityCode === 'GL') {
        props.history.push({
          pathname: '/FinancialAccountGrossLevel',
          state: { continueResults: { ...continueResults, grossLevelDetails: { ...continueResults.grossLevelDetails, payeeStatus } } }
        });
      }
      if (activityReasonCodeValues.activityCode === 'WL') {
        props.history.push({
          pathname: '/FinancialAccountLienLevy',
          state: { continueResults: { ...continueResults, grossLevelDetails: { ...continueResults.grossLevelDetails, payeeStatus } } }
        });
      }
    }
  }, [searchSingleFinancialEntityRes]);

  useEffect(() => {
    if (reasonCodeMapdropdown && reasonCodeMapdropdown.reasonCodesMap && reasonCodeMapdropdown.reasonCodesMap.reasonCodeList) {
      // reason code data will get set with sorted codes
      setReasonCodeData(reasonCodeMapdropdown.reasonCodesMap.reasonCodeList.sort((a, b) => (a.code - b.code)));
    }
  }, [reasonCodeMapdropdown]);

  const handleChange = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setValues({ ...values, [name]: event.target.value });
  };

  const fieldDescription = (dropdownFilter, Code) => {
    const filteredValue = dropdownFilter.filter(
      (rCode, index) => rCode.code === Code
    );
    if (filteredValue && filteredValue.length > 0) {
      return filteredValue[0].description;
    }
    return Code;
  };

  useEffect(() => {
    if (payeeValues.payeeType !== '' && payeeValues.payeeTpe !== DEFAULT_DD_VALUE) {
      const payeeTypeDesc = fieldDescription(payeeTypeData, payeeValues.payeeType);
      setPayeeTypeDesc(payeeTypeDesc);
    }
  }, [payeeValues]);

  useEffect(() => {
    if (payeeValues.payeeIdTypeCode !== '' && payeeValues.payeeIdTypeCode !== DEFAULT_DD_VALUE) {
      const payeeIDTypecodeDesc = fieldDescription(payeeTypeIdCodeData, payeeValues.payeeIdTypeCode);
      setPayeeTypeIdCodeDesc(payeeIDTypecodeDesc);
    }
  }, [payeeValues]);

  const handleChangePayeeType = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setPayeeValues({ ...values, [name]: event.target.value });

    if (name === 'payeeType') {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        setShowTable(false);
        const valuetoredirect = redirect - 1;
        setRedirect(valuetoredirect);
        onPayeeTypeIdDropDown(event.target.value);
      }
      if (event.target.value === DEFAULT_DD_VALUE) {
        setPayeeTypeIdCodeData([]);
        setPayeeValues({
          payeeType: DEFAULT_DD_VALUE,
          payeeIdTypeCode: DEFAULT_DD_VALUE
        });
      }
    } else if (event.target.value === DEFAULT_DD_VALUE) {
      setPayeeValues({
        payeeType: payeeValues.payeeType,
        payeeIdTypeCode: DEFAULT_DD_VALUE
      });
    } else {
      setPayeeValues({
        payeeType: payeeValues.payeeType,
        payeeIdTypeCode: event.target.value
      });
    }
  };

  let payeeTypeIdDropDown = [];
  payeeTypeIdDropDown = useSelector(state => state.accountReceivableState.payeeIdTypeDropdown);

  useEffect(() => {
    if (payeeTypeIdDropDown && payeeTypeIdDropDown.payeeIdTypeCode) {
      setPayeeTypeIdCodeData(payeeTypeIdDropDown.payeeIdTypeCode);
      const error = [];
      setErrorMessages(error);
    } else if (payeeTypeIdDropDown && payeeTypeIdDropDown.systemFailure) {
      const error = [];
      error.push(AppConstants.ERR_PROCESSING_REQ);
      setErrorMessages(error);
    }
  }, [payeeTypeIdDropDown]);
  const [isMultiple,setIsMultiple]=useState(false)
  const continueResults = useSelector(state => state.accountReceivableState.goToNextPagesData);
  const multipleSys = useSelector(state => state.accountReceivableState.payoutDetails )
  const [singleRecord, setSingleRecord] = React.useState({payeePayerName: '',
  payeeId: '',
  systemPayeeId: ''});
  useEffect(()=>{
    if (multipleSys && multipleSys !== undefined && multipleSys.respcode !== '02' &&
    multipleSys.enterpriseSearchresultVO && multipleSys.enterpriseSearchresultVO.searchResults) {
    const onValidateResult = multipleSys.enterpriseSearchresultVO.searchResults[0];

    if (onValidateResult.multiplePayeeDetails && onValidateResult.multiplePayeeDetails.length > 1) {
      setOpenSysPayeeTable(true);
      setSystemPayeeTableData(onValidateResult.multiplePayeeDetails);
      setIsMultiple(true)
     
    } else {
      if (onValidateResult) {//impo
        setSingleRecord({
          ...singleRecord,
          payeePayerName: onValidateResult.payeePayerName,
          payeeId: onValidateResult.payeePayerID,
          systemPayeeId: onValidateResult.sysID
        });
        setIsMultiple(false)
      }
    }
    setspinnerLoader(false);
    setErrorMessages([]);
    setPayeeIDErrorMessages([]);
  } else if (multipleSys && multipleSys.respcode && multipleSys.respcode === '02') {
    errorMessagesArray.push(multipleSys.errorMessages);
    setErrorMessages(errorMessagesArray);
    setPayeeIDErrorMessages(multipleSys.errorMessages);
    setSingleRecord({
      ...singleRecord,
      payeePayerName: '',
      payeeId: '',
      systemPayeeId: ''
    });
    setspinnerLoader(false)
  } else if (multipleSys && multipleSys.systemFailure) {
    errorMessagesArray.push('There was an error processing the request. Please retry the transaction.');
    setErrorMessages(errorMessagesArray);
    setspinnerLoader(false)
  }
  },[multipleSys])
   useEffect(() => {
    if (continueResults ) {
        if (currentSysID || singleRecord) {
          setCurrentSysID(null);
          setOpenSysPayeeTable(false);
          if (continueResults) {
            if (continueResults.payeeDetails) {
              continueResults.payeeDetails.systemPayeeId = currentSysID&&currentSysID.sysID && isMultiple?
              currentSysID.sysID:singleRecord.systemPayeeId;
              continueResults.payeeDetails.payeePayerName = currentSysID&&currentSysID.payeeName && isMultiple?
              currentSysID.payeeName:singleRecord.payeePayerName;
            }
            if (activityReasonCodeValues.activityCode === 'GL') {
              {
                props.history.push({
                  pathname: '/FinancialAccountGrossLevel',
                  state: { continueResults,values: {...values,...payeeValues,...activityReasonCodeValues
                    ,reasonCodeData,
                    cPaysysID:currentSysID&&currentSysID.sysID,
                    cPayeeName:currentSysID&&currentSysID.payeeName,
                  sPayName:singleRecord&&singleRecord.payeePayerName,
                sPaySysId:singleRecord&&singleRecord.systemPayeeId}
               }
                });
              }
            }
            if (activityReasonCodeValues.activityCode === 'WL') {
              {
                props.history.push({
                  pathname: '/FinancialAccountLienLevy',
                  state: { continueResults, values: {...values,...payeeValues
                    ,...activityReasonCodeValues,reasonCodeData,
                    cPaysysID:currentSysID&&currentSysID.sysID,
                    cPayeeName:currentSysID&&currentSysID.payeeName,
                  sPayName:singleRecord&&singleRecord.payeePayerName,
                sPaySysId:singleRecord&&singleRecord.systemPayeeId} }
                });
              }
            }
          }
        }
    }
  }, [continueResults]); 
  
  const continueAddGrossLevel = () => {
    setIsContinue(true);
    var showLobError; var showPayeeTypeError; var showPayeeIdTypeCodeError; var showPayeeIdError;
    var showActivityTypeError; var showReasonCodeError = false;
    var showLobErrorText; var showPayeeTypeErrorText; var showPayeeIdTypeCodeErrorText;
    var showActivityTypeErrorText; var showPayeeIdErrorText; var showReasonCodeErrorText = '';
    if (values.lob === '' || values.lob === DEFAULT_DD_VALUE) {
      showLobError = true;
      showLobErrorText = errorConstants.LOB_REQUIRED;
      errorMessagesArray.push(showLobErrorText);
    }
    if (payeeValues.payeeType === '' || payeeValues.payeeType === DEFAULT_DD_VALUE) {
      showPayeeTypeError = true;
      showPayeeTypeErrorText = errorConstants.PAYEE_TYPE_REQUIRED;
      errorMessagesArray.push(showPayeeTypeErrorText);
    }

    if (payeeValues.payeeIdTypeCode === '' || payeeValues.payeeIdTypeCode === DEFAULT_DD_VALUE) {
      showPayeeIdTypeCodeError = true;
      showPayeeIdTypeCodeErrorText = errorConstants.PAYEE_TYPE_ID_CODE_REQUIRED;
      errorMessagesArray.push(showPayeeIdTypeCodeErrorText);
    }

    if (values.payeeId === '') {
      showPayeeIdError = true;
      showPayeeIdErrorText = errorConstants.PAYEE_ID_REQUIRED;
      errorMessagesArray.push(showPayeeIdErrorText);
    }

    if (activityReasonCodeValues.activityCode === '' || activityReasonCodeValues.activityCode === DEFAULT_DD_VALUE) {
    showActivityTypeError = true;
    showActivityTypeErrorText = errorConstants.ACTIVITY_TYPE_REQUIRED;
    errorMessagesArray.push(showActivityTypeErrorText);
    }

    if (activityReasonCodeValues.reasonCode === '' || activityReasonCodeValues.reasonCode === DEFAULT_DD_VALUE) {
    showReasonCodeError = true;
    showReasonCodeErrorText = errorConstants.REASON_CODE_REQUIRED;
    errorMessagesArray.push(showReasonCodeErrorText);
    }

    setErrorMessages(errorMessagesArray);

    setShowError({
      showLobError: showLobError,
      showActivityTypeError: showActivityTypeError,
      showPayeeTypeError: showPayeeTypeError,
      showPayeeIdTypeCodeError: showPayeeIdTypeCodeError,
      showPayeeIdError: showPayeeIdError,
      showReasonCodeError: showReasonCodeError
    });

    setShowErrorText({
      showLobErrorText: showLobErrorText,
      showActivityTypeErrorText: showActivityTypeErrorText,
      showPayeeIdErrorText: showPayeeIdErrorText,
      showPayeeTypeErrorText: showPayeeTypeErrorText,
      showPayeeIdTypeCodeErrorText: showPayeeIdTypeCodeErrorText,
      showReasonCodeErrorText: showReasonCodeErrorText
    });

    const nextPageValue = {
      lob: values.lob,
      payeePayerTypeCode: payeeValues.payeeType,
      payeePayerIDTypeCode: payeeValues.payeeIdTypeCode,
      payeePayerID: values.payeeId,
      activityTypeCode: activityReasonCodeValues.activityCode,
      accountReceivableReasonCode: activityReasonCodeValues.reasonCode
    };
    if (errorMessagesArray.length === 0 && payeeIDErrorMessages.length === 0 && nextPageValue) {
      setAllowNavigation(false);
      setspinnerLoader(true);
      onGotoNextPage(removeLeadingTrailingSpaces(nextPageValue));
    }
  };
  const validatePD = ()=>{
    var showLobError; var showPayeeTypeError; var showPayeeIdTypeCodeError; var showPayeeIdError;
    var showLobErrorText; var showPayeeTypeErrorText; var showPayeeIdTypeCodeErrorText;
    var showPayeeIdErrorText;
    if (values.lob === '' || values.lob === DEFAULT_DD_VALUE) {
      showLobError = true;
      showLobErrorText = errorConstants.LOB_REQUIRED;
      errorMessagesArray.push(showLobErrorText);
    }
    if (payeeValues.payeeType === '' || payeeValues.payeeType === DEFAULT_DD_VALUE) {
      showPayeeTypeError = true;
      showPayeeTypeErrorText = errorConstants.PAYEE_TYPE_REQUIRED;
      errorMessagesArray.push(showPayeeTypeErrorText);
    }

    if (payeeValues.payeeIdTypeCode === '' || payeeValues.payeeIdTypeCode === DEFAULT_DD_VALUE) {
      showPayeeIdTypeCodeError = true;
      showPayeeIdTypeCodeErrorText = errorConstants.PAYEE_TYPE_ID_CODE_REQUIRED;
      errorMessagesArray.push(showPayeeIdTypeCodeErrorText);
    }
    setErrorMessages(errorMessagesArray);

    setShowError({
      showLobError: showLobError,
      showPayeeTypeError: showPayeeTypeError,
      showPayeeIdTypeCodeError: showPayeeIdTypeCodeError,
      showPayeeIdError: showPayeeIdError, 
    });

    setShowErrorText({
      showLobErrorText: showLobErrorText,
      showPayeeIdErrorText: showPayeeIdErrorText,
      showPayeeTypeErrorText: showPayeeTypeErrorText,
      showPayeeIdTypeCodeErrorText: showPayeeIdTypeCodeErrorText,
    });
    let payLoadvalues ={
      "entityType": payeeValues.payeeType,
      "entityIDTypeCode": payeeValues.payeeIdTypeCode,
      "entityID":values.payeeId,
      "lineOfBusiness": values.lob
    }
    if(errorMessagesArray.length === 0){
    setspinnerLoader(true)
    dispatch(validatePayeeDetails(payLoadvalues))
    }
  }
  const handleChangeActivityType = name => event => {
    setFocusCheck(true);
    setAllowNavigation(true);
    setActivityReasonCodeValues({ ...values, [name]: event.target.value });
    if (name === 'activityCode') {
      if (event.target.value !== DEFAULT_DD_VALUE) {
        getReasonCodeMapData(event.target.value);
        setActivityReasonCodeValues({
          activityCode: event.target.value,
          reasonCode: DEFAULT_DD_VALUE
        });
        activityValue = event.target.value;
      } else {
        setActivityReasonCodeValues({
          activityCode: DEFAULT_DD_VALUE,
          reasonCode: DEFAULT_DD_VALUE
        });
        setReasonCodeData(initialReasonCodeArray);
      }
    } else if (name === 'reasonCode') {
      setActivityReasonCodeValues({
        activityCode: activityReasonCodeValues.activityCode,
        reasonCode: event.target.value
      });
    } else {
      setActivityReasonCodeValues({
        activityCode: activityReasonCodeValues.activityCode,
        reasonCode: DEFAULT_DD_VALUE
      });
    }
  };
  // Payee Popup Implementation Starts
  const [systemPayeeTableData, setSystemPayeeTableData] = React.useState([]);
  const [currentSysID, setCurrentSysID] = React.useState(null);

  const [openSysPayeeTable, setOpenSysPayeeTable] = React.useState(false);
  const [canCel,setCancel]=useState(false)
  const handleCloseSysPayeeTable = (e) => {
    setOpenSysPayeeTable(false);
    setCancel(true)
    setValues({
      ...values,
      payeeId: '',
    });
      setCurrentSysID(null);
  }
  const inputRefCursor=useRef(null)
  useEffect(()=>{
    if(inputRefCursor.current && canCel) {
      inputRefCursor.current.focus();
    }
  },[canCel])
  const onRadioButtonClick = (row) => {
    setCurrentSysID(row);
  };
  const onContinue = () => {
setOpenSysPayeeTable(false)
  };
  // Payee popup Implementation ends
  const valuesFromARAdd =  props?.history?.location?.state?.valuesFromARAdd;
    useEffect(() => {
    if (props.location.addRender && !isContinue) {
      if (valuesFromARAdd !== undefined) {  
        setspinnerLoader(true);
        setValues({...valuesFromARAdd});
        setCurrentSysID({...currentSysID,
          sysID:valuesFromARAdd.cPaysysID,
          payeeName:valuesFromARAdd.cPayeeName
        })
        setSingleRecord({...singleRecord,
          systemPayeeId:valuesFromARAdd.sPaySysId,
          payeePayerName:valuesFromARAdd.sPayName
        })
        getReasonCodeMapData(valuesFromARAdd.activityCode)        
        onPayeeTypeIdDropDown(valuesFromARAdd.payeeType) 
        setPayeeValues(    {
          payeeType: valuesFromARAdd.payeeType,
          payeeIdTypeCode: valuesFromARAdd.payeeIdTypeCode
        });   
        setReasonCodeData(valuesFromARAdd.reasonCodeData);    
        setActivityReasonCodeValues({
          activityCode: valuesFromARAdd.activityCode,
          reasonCode: valuesFromARAdd.reasonCode
        });     };
      setspinnerLoader(false);
    }
  }, [props.location.addRender]);

  const cancelMinorSection = () => {
    setAllowNavigation(false);
    setTimeout(()=>{
      setDialogOpen(false);
      setFocusCheck(false);
      props.history.push({
        pathname: '/FinancialAccountInquiry',
        tableRender: false
      });
    },100)
    };

  const majorCancel = () => {
    setErrorMessages([]);
    if (focusCheck || props.location?.focusCheck) {
      setDialogOpen(true);
    } else {
      setDialogOpen(false);
      props.history.push({
        pathname: '/FinancialAccountInquiry',
        tableRender: false
      });
    }
  };
  return (
    <div>
      <Prompt
          when={allowNavigation}
          message={location =>
            `Are you sure you want to go to ${location.pathname}`
          }
        />
      {spinnerLoader ? <Spinner /> : null}
      <div className="tabs-container">
      <ErrorMessages errorMessages={errorMessages.length > 0 ? errorMessages : payeeIDErrorMessages} />
        <div className="tab-header">
          <h1 className="float-left tab-heading">
            Accounts Receivable
          </h1>

          <div className="float-right mt-2">
            {window.location.pathname === '/GrossLevelAddPayeeComponent' && (
              <Button title="Cancel" onClick={majorCancel}>{AppConstants.CANCEL}</Button>
            )}

          </div>
          <div className="clearfix">
          </div>
        </div>
        <form autoComplete="off">
          <div className="tab-body">
            <div className="form-wrapper">
              <NativeDropDown
                isRequired
                id="standard-FCN"
                  label="LOB"
                  value={values.lob}
                onChange={handleChange('lob')}
                  inputProps={{ maxlength: 3 }}
                errTxt={showLobError ? showLobErrorText : null}
                dropdownList={lobCodeData !== null ? lobCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  )) : ''}

              />
              <NativeDropDown
                isRequired
                  id="standard-payee-type"
                  label="Payee Type"
                  value={payeeValues.payeeType}
                onChange={handleChangePayeeType('payeeType')}
                  inputProps={{ maxlength: 3 }}
                errTxt={showPayeeTypeError ? showPayeeTypeErrorText : null}
                dropdownList={payeeTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <NativeDropDown
                isRequired
                  id="standard-payeeid-type-code"
                  label="Payee ID Type Code"
                  value={payeeValues.payeeIdTypeCode}
                disabled={payeeValues.payeeType === DEFAULT_DD_VALUE}
                onChange={handleChangePayeeType('payeeIdTypeCode')}
                  inputProps={{ maxlength: 3 }}
                errTxt={showPayeeIdTypeCodeError ? showPayeeIdTypeCodeErrorText : null}
                dropdownList={payeeTypeIdCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <div className="mui-custom-form input-md">
                <TextField
                  required
                  id="standard-payee-id"
                  label="Payee ID"
                  inputRef={inputRefCursor
                  }
                  value={values.payeeId}
                  onChange={handleChange('payeeId')}
                  inputProps={{ maxlength: 15 }}
                  onBlur={() => validatePD()}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={showPayeeIdError ? showPayeeIdErrorText : null}
                  error={showPayeeIdError ? showPayeeIdErrorText : null}
                >
                </TextField>
              </div>
              <NativeDropDown
                isRequired
                  id="standard-activity-type"
                  label="Activity Type"
                  value={activityReasonCodeValues.activityCode}
                onChange={handleChangeActivityType('activityCode')}
                  inputProps={{ maxlength: 3 }}
                errTxt={showActivityTypeError ? showActivityTypeErrorText : null}
                dropdownList={activityTypeData.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
              <NativeDropDown
                isRequired
                  id="standard-reason-code"
                  label="Reason Code"
                  value={activityReasonCodeValues.reasonCode}
                disabled={activityReasonCodeValues.activityCode === DEFAULT_DD_VALUE}
                onChange={handleChangeActivityType('reasonCode')}
                  inputProps={{ maxlength: 3 }}
                errTxt={showReasonCodeError ? showReasonCodeErrorText : null}
                  title={getLongDescription(reasonCodeData, activityReasonCodeValues.reasonCode)}
                dropdownList={reasonCodeData.map((option, index) => (
                  <option key={index} value={option.code}>
                      {option.description}
                  </option>
                  ))}
              />
            </div>
          </div>
        </form>

        <div className="tab-body" style={{ overflow: 'auto' }}>
          <div className="float-right mb-2 mr-2">
            <Button variant="outlined" color="primary" className='btn btn-success'
              onClick={() => continueAddGrossLevel()}
            >
              <i class="fa fa-arrow-right ml-1"></i> Continue
            </Button>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="custom-alert-box"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>
                    Unsaved changes will be lost. <br />
                    Are you sure you want to continue?
            </>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDialogOpen(false);
            }}
			                 color="primary"
            className="btn btn-transparent"
          >
                  STAY ON THIS PAGE!
          </Button>
          <Button
            onClick={cancelMinorSection}
            color="primary"
            className="btn btn-success"
            autoFocus
          >
                  CONTINUE <i className="fa fa-arrow-right ml-1" />
          </Button>
        </DialogActions>
      </Dialog>
        {openSysPayeeTable &&  
        <SystemPayeeTable
        payeeType={payeeTypeDesc}
        payeeIdTypeCode={payeeTypeIdCodeDesc}
        payeeId={values.payeeId}
        openSysPayeeTable={openSysPayeeTable}
        handleCloseSysPayeeTable={handleCloseSysPayeeTable}
        uniqueKey={'sysID'}
        onTableCellButtonClick={onRadioButtonClick}
        tableData={systemPayeeTableData}
        currentSysID={currentSysID}
        onContinue={onContinue}
        /> }
      </div>
  );
}
export default withRouter((Original));
