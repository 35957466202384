/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/

import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../SharedModules/Spinner/Spinner";
import ErrorMessages from "../../../SharedModules/Errors/ErrorMessages";
import SuccessMessages from "../../../SharedModules/Errors/SuccessMessage";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import moment from 'moment';
import NoSaveMessage from '../../../SharedModules/Errors/NoSaveMessage';
import dropDownCriteria from '../CMS64DropDownCriteria.json';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  getDropdownDataAction,
  searchCMS64Action,
  createCMS64Action,
  updateCMS64Action,
  RESET_CMS64
} from "../Store/Actions/CMS64Actions";
import * as CMS64Constants from "./CMS64Constants";
import {
  getDateInMMDDYYYYFormat,
  getUTCTimeStamp,
  compareTwoDatesGreaterThanOrEqual
} from "../../../SharedModules/DateUtilities/DateUtilities";
import { Prompt } from 'react-router-dom';

function EMARAdd(props) {
  const dispatch = useDispatch();
  const toPrintRef = useRef();
  
  const [spinnerLoader, setspinnerLoader] = useState(false);
  const [errorMessages, seterrorMessages] = useState([]);
  const [CMS64State, setCMS64State] = useState({
    comments: "",
    famPlanInd: false,
    endMbrCOECd: "",
    begMbrCOECd: "",
    endMbrBPID: "Please Select One",
    begMbrBPID: "Please Select One",
    EndFundSrcCdDesc: "",
    begFundSrcCd: "",
    endFundTyCd: "",
    begFundTyCd: "",
    priorityNum: 0,
    CMSColCdDesc: "",
    CMSFormCdDesc: "",
    CMSColCd: "",
    CMSFormCd: "",
    cms64SK: null
  });
  const [allowNavigation, setAllowNavigation] = React.useState(false);
  const [searchObj, setSearchObj] = useState({});
  const [showError, setShowError] = useState({
    CMSFormCd: false,
    CMSColCd: false,
    priorityNum: false,
    beginDate: false,
    endDate: false
  });
  const [showErrorMessage, setShowErrorMessage] = useState({
    CMSFormCd: '',
    CMSColCd: '',
    priorityNum: '',
    beginDate: '',
    endDate: ''
  });
  const [successMessages, setSuccessMessages] = useState([]);
  const [editPage, setEditPage] = useState(false);
  const createCMS64 = values => dispatch(createCMS64Action(values));
  const updateCMS64 = values => dispatch(updateCMS64Action(values));
  let createSuccess = useSelector(state => state.cms64State.createPayload);
  let updateSuccess = useSelector(state => state.cms64State.updatePayload);
  const dropDownData = useSelector(state => state.cms64State.dropdownData);

  const searchCMS64 = values => dispatch(searchCMS64Action(values));
  const getDropdownData = values => dispatch(getDropdownDataAction(values));
  let searchSuccess = {};
  searchSuccess = useSelector(state => state.cms64State.searchPayload);
  const [selectedBeginDate, setSelectedBeginDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  let sucessArray = [];
  const handleBeginDate = date => {
    setAllowNavigation(true);
    setSelectedBeginDate(date);
  };
  const handleEndDate = date => {
    setAllowNavigation(true);
    setSelectedEndDate(date);
  };

  let successMessagesArray = [];
  useEffect(() => {
    setspinnerLoader(false);
    if (createSuccess && createSuccess.respcode === "01") {
      successMessagesArray.push(createSuccess.respdesc);
      setSuccessMessages(successMessagesArray);
      sucessArray = successMessagesArray;
      let searchEmarOb = {
        "cms64FormCd": CMS64State.CMSFormCd,
        "cms64ColCd": CMS64State.CMSColCd,
        "priority_Number": CMS64State.priorityNum
      };
      searchCMS64(searchEmarOb);
    } else if (createSuccess && createSuccess.systemFailure) {
      let ErrorMessages = [];
      ErrorMessages.push(CMS64Constants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(ErrorMessages);
    }
  }, [createSuccess]);
  useEffect(() => {
    setspinnerLoader(false);
    if (updateSuccess && updateSuccess.respcode === "01") {
      successMessagesArray.push(updateSuccess.respdesc);
      setSuccessMessages(successMessagesArray);
      sucessArray = successMessagesArray;
      let searchEmarOb = {
        cms64SK: searchObj.cms64SeqNo
      };
      searchCMS64(searchEmarOb);
    } else if (updateSuccess && updateSuccess.systemFailure) {
      let ErrorMessages = [];
      ErrorMessages.push(CMS64Constants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(ErrorMessages);
    }
  }, [updateSuccess]);
  let searchObject = {};
  useEffect(() => {
    if (props.location.state && props.location.state.searchData) {
      setEditPage(true);
      searchObject = props.location.state.searchData[0];
      setSearchObj(searchObject);
      setCMS64State({
        comments: searchObject.comments,
        famPlanInd: searchObject.fp_Ind === 'Y' ? true : false,
        endMbrCOECd: searchObject.endMbrCoeCd,
        begMbrCOECd: searchObject.begMbrCoeCd,
        endMbrBPID: searchObject.endMbrBpId,
        begMbrBPID: searchObject.begMbrBpId,
        EndFundSrcCdDesc: searchObject.endFundSrcCd,
        begFundSrcCd: searchObject.begFundSrcCd,
        endFundTyCd: searchObject.endFundTyCd,
        begFundTyCd: searchObject.begFundTyCd,
        priorityNum: searchObject.priority_Number,
        CMSColCdDesc: searchObject.cms64ColCdDesc,
        CMSFormCdDesc: searchObject.cms64FormCdDesc,
        CMSColCd: searchObject.cms64ColCd,
        CMSFormCd: searchObject.cms64FormCd
      });
      setSuccessMessages(props.location.state.successMessages);
      setSelectedBeginDate(searchObject.begDt);
      setSelectedEndDate(searchObject.endDt);
    }
    if (
      props.location.state &&
      props.location.state.successMessages &&
      updateSuccess &&
      !updateSuccess.systemFailure
    ) {
      setSuccessMessages(props.location.state.successMessages);
    }
    if (props.location.state && props.location.state.successMessages && createSuccess) {
      let successArray = [];
      successArray.push("System successfully saved the information.");
      setSuccessMessages(successArray);
    }
  }, [props.location.state]);

  useEffect(() => {
    RESET_CMS64('searchPayload');
    RESET_CMS64('createPayload');
    RESET_CMS64('updatePayload');
    searchSuccess = null;
    updateSuccess = null;
    createSuccess = null;
    seterrorMessages([]);
    getDropdownData(dropDownCriteria)
    if (!props.location.state) {
      setEditPage(false);
    }
    else if (props.location.state) {
      if (props.location.state.successMessages && !props.location.state.fromSearch) {
        const tempVar = JSON.parse(JSON.stringify(props.location.state.successMessages));
        setSuccessMessages(tempVar);
      } else {
        setSuccessMessages([]);
      }
    }
  }, []);
  const [benfitPlanData, setBenfitPlanData] = React.useState([]);
  const [fundCodeData, setFundCodeData] = React.useState([]);
  useEffect(() => {
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Financial#3458']) {
      setBenfitPlanData(dropDownData.listObj['Financial#3458']);
    }
    if (dropDownData && dropDownData.listObj && dropDownData.listObj['Reference#1042']) {
      setFundCodeData(dropDownData.listObj['Reference#1042']);
    }
  }, [dropDownData])

  useEffect(() => {
    setspinnerLoader(false);
    if (searchSuccess && !searchSuccess.systemFailure && searchSuccess.length === 1) {
      let searchData = searchSuccess;
      props.history.push({
        pathname: "/CMS64Update",
        state: { searchData: searchData, successMessages: successMessages, editPage: true }
      });
    } else if (searchSuccess && searchSuccess.systemFailure) {
      let ErrorMessages = [];
      ErrorMessages.push(CMS64Constants.GENERIC_SYSTEM_ERROR);
      seterrorMessages(ErrorMessages);
    }
  }, [searchSuccess]);
  const handleChange = name => event => {
    setAllowNavigation(true);
    if (name === "famPlanInd") {
      setCMS64State({ ...CMS64State, famPlanInd: !CMS64State.famPlanInd });
    } else {
      setCMS64State({
        ...CMS64State,
        [name]: event.target.value
      });
    }
  };

  const logInUserID = useSelector(state => state.sharedState.logInUserID);

  const saveCMS64 = () => {
    setSuccessMessages([]);
    seterrorMessages([]);
    let priorityNumErr = false;
    let CMSColCdErr = false;
    let CMSFormCdErr = false;
    let beginDateErr = false;
    let endDateErr = false;
    let beginDateErrMsg = '';
    let endDateErrMsg = '';
    let priorityNumErrMsg = "";
    let CMSColCdErrMsg = "";
    let CMSFormCdErrMsg = "";
    let errorMessages = [];
    if (CMS64State.CMSColCd === "") {
      CMSColCdErr = true;
      CMSColCdErrMsg = CMS64Constants.CMS_COL_CD_REQ;
      errorMessages.push(CMSColCdErrMsg);
    }
    if (CMS64State.priorityNum === "") {
      priorityNumErr = true;
      priorityNumErrMsg = CMS64Constants.PRIORITY_NUMBER_REQ;
      errorMessages.push(priorityNumErrMsg);
    }
    if (CMS64State.CMSFormCd === "") {
      CMSFormCdErr = true;
      CMSFormCdErrMsg = CMS64Constants.CMS_FORM_CD_REQ;
      errorMessages.push(CMSFormCdErrMsg);
    }
    if (selectedEndDate && selectedEndDate.toString() === 'Invalid Date') {
      endDateErr = true;
      endDateErrMsg = CMS64Constants.INVALID_DATE;
      errorMessages.push(endDateErrMsg);
    }
    if (selectedBeginDate && selectedBeginDate.toString() === 'Invalid Date') {
      beginDateErr = true;
      beginDateErrMsg = CMS64Constants.INVALID_DATE;
      errorMessages.push(beginDateErrMsg);
    }
    if (selectedBeginDate && selectedEndDate && !beginDateErr && !endDateErr &&
      compareTwoDatesGreaterThanOrEqual(selectedEndDate, selectedBeginDate)) {
      endDateErr = true;
      endDateErrMsg = CMS64Constants.END_DATE_GREATER_THAN_BG_DT;
      errorMessages.push(endDateErrMsg);
    }
    seterrorMessages(errorMessages);
    setShowError({
      CMSFormCd: CMSFormCdErr,
      CMSColCd: CMSColCdErr,
      priorityNum: priorityNumErr,
      beginDate: beginDateErr,
      endDate: endDateErr
    });
    setShowErrorMessage({
      CMSFormCd: CMSFormCdErrMsg,
      CMSColCd: CMSColCdErrMsg,
      priorityNum: priorityNumErrMsg,
      beginDate: beginDateErrMsg,
      endDate: endDateErrMsg
    });
    if (!allowNavigation && editPage) {
      NoSaveMessage();
    } else {
      if (errorMessages.length === 0) {
        setAllowNavigation(false);
        setspinnerLoader(true);
        if (!editPage) {
          let EmarAdd = {
            "comments": CMS64State.comments,
            "cms64FormCd": CMS64State.CMSFormCd,
            "cms64ColCd": CMS64State.CMSColCd,
            "cms64FormCdDesc": CMS64State.CMSFormCdDesc,
            "cms64ColCdDesc": CMS64State.CMSColCdDesc,
            "priority_Number": CMS64State.priorityNum,
            "begFundTyCd": CMS64State.begFundTyCd,
            "endFundTyCd": CMS64State.endFundTyCd,
            "begFundSrcCd": CMS64State.begFundSrcCd,
            "endFundSrcCd": CMS64State.EndFundSrcCdDesc,
            "begMbrBpId": CMS64State.begMbrBPID !== "Please Select One" ? CMS64State.begMbrBPID : '',
            "endMbrBpId": CMS64State.endMbrBPID !== "Please Select One" ? CMS64State.endMbrBPID : '',
            "begMbrCoeCd": CMS64State.begMbrCOECd,
            "endMbrCoeCd": CMS64State.endMbrCOECd,
            "fp_Ind": CMS64State.famPlanInd === false ? 'N' : 'Y',
            "begDt": selectedBeginDate ? moment(selectedBeginDate).format('MM/DD/YYYY') : null,
            "endDt": selectedEndDate ? moment(selectedEndDate).format('MM/DD/YYYY') : null,
            "auditUserID": logInUserID,
            "auditTimeStamp": getUTCTimeStamp(),
            "addedAuditUserID": logInUserID,
            "addedAuditTimeStamp": getUTCTimeStamp(),
          };
          createCMS64(EmarAdd);
        } else if (editPage) {
          let EmarUpdate = {
            auditUserID: logInUserID,
            auditTimeStamp: getUTCTimeStamp(),
            addedAuditUserID: logInUserID,
            addedAuditTimeStamp: getUTCTimeStamp(),
            versionNo: searchObj.versionNo,
            dbRecord: false,
            sortColumn: null,
            auditKeyList: [],
            auditKeyListFiltered: false,
            cms64SeqNo: searchObj.cms64SeqNo,
            priority_Number: CMS64State.priorityNum,
            cms64FormCd: CMS64State.CMSFormCd,
            cms64FormCdDesc: CMS64State.CMSFormCdDesc,
            cms64ColCd: CMS64State.CMSColCd,
            cms64ColCdDesc: CMS64State.CMSColCdDesc,
            begFundTyCd: CMS64State.begFundTyCd,
            endFundTyCd: CMS64State.endFundTyCd,
            begFundSrcCd: CMS64State.begFundSrcCd,
            endFundSrcCd: CMS64State.EndFundSrcCdDesc,
            begMbrBpId: CMS64State.begMbrBPID !== "Please Select One" ? CMS64State.begMbrBPID : '',
            endMbrBpId: CMS64State.endMbrBPID !== "Please Select One" ? CMS64State.endMbrBPID : '',
            begMbrCoeCd: CMS64State.begMbrCOECd,
            endMbrCoeCd: CMS64State.endMbrCOECd,
            fp_Ind: CMS64State.famPlanInd === false ? 'N' : 'Y',
            begDt: selectedBeginDate ? moment(selectedBeginDate).format('MM/DD/YYYY') : null,
            endDt: selectedEndDate ? moment(selectedEndDate).format('MM/DD/YYYY') : null,
            comments: CMS64State.comments
          };
          updateCMS64(EmarUpdate);
        }
      }
    }
  };

  return (
    <div className="pos-relative w-100 h-100">
      <div className="tabs-container" ref={toPrintRef}>
        {spinnerLoader ? <Spinner /> : null}
        <Prompt
          when={allowNavigation}
          message={location => `Are you sure you want to go to ${location.pathname}`}
        />
        <ErrorMessages errorMessages={errorMessages} />
        {errorMessages.length === 0 ? <SuccessMessages successMessages={successMessages} /> : null}
        <div className="tab-header">
          <h1 className="tab-heading float-left">{editPage ? "Update CMS64" : "Add CMS64"}</h1>

          <div className="float-right mt-1 pt-1">
            <Button className="btn btn-success ml-1" onClick={() => saveCMS64()}>
              <i class="fa fa-check" aria-hidden="true"></i>
              Save
            </Button>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="tab-body">
          <form noValidate autoComplete="off">
            <div className="form-wrapper horizontal-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  required
                  id="CMSFormCd"
                  label="CMS Form Code"
                  value={CMS64State.CMSFormCd}
                  onChange={handleChange("CMSFormCd")}
                  inputProps={{ maxlength: 2 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={showError.CMSFormCd ? showErrorMessage.CMSFormCd : null}
                  error={showError.CMSFormCd ? showErrorMessage.CMSFormCd : null}
                ></TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  required
                  id="CMSColCd"
                  label="CMS Column Code"
                  value={CMS64State.CMSColCd}
                  onChange={handleChange("CMSColCd")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 1 }}
                  helperText={showError.CMSColCd ? showErrorMessage.CMSColCd : null}
                  error={showError.CMSColCd ? showErrorMessage.CMSColCd : null}
                />
              </div>
              <div className="mui-custom-form-description">
                <TextField
                  id="CMSFormCdDesc"
                  label="CMS Form Cd Description"
                  value={CMS64State.CMSFormCdDesc}
                  onChange={handleChange("CMSFormCdDesc")}
                  inputProps={{ maxlength: 50 }}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                ></TextField>
              </div>
              <div className="mui-custom-form-description">
                <TextField
                  id="CMSColCdDesc"
                  fullWidth
                  label="CMS Column Cd Description"
                  value={CMS64State.CMSColCdDesc}
                  onChange={handleChange("CMSColCdDesc")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 50 }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  required
                  id="priorityNum"
                  fullWidth
                  label="Priority Number"
                  value={CMS64State.priorityNum}
                  onChange={handleChange("priorityNum")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 3 }}
                  helperText={showError.priorityNum ? showErrorMessage.priorityNum : null}
                  error={showError.priorityNum ? showErrorMessage.priorityNum : null}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="begFundTyCd"
                  label="Begin Fund Type Code"
                  value={CMS64State.begFundTyCd}
                  onChange={handleChange("begFundTyCd")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 1 }}
                  helperText={showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null}
                  error={showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null}
                >
                </TextField>
              </div>
            </div>
            <div className="form-wrapper horizontal-wrapper">
              <div className="mui-custom-form input-md">
                <TextField
                  id="endFundTyCd"
                  label="End Fund Type Code"
                  value={CMS64State.endFundTyCd}
                  onChange={handleChange("endFundTyCd")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 1 }}
                >
                </TextField>
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="begFundSrcCd"
                  label="Begin Fund Source Code"
                  value={CMS64State.begFundSrcCd}
                  onChange={handleChange("begFundSrcCd")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 10 }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="EndFundSrcCdDesc"
                  label="End Fund Source Code"
                  value={CMS64State.EndFundSrcCdDesc}
                  onChange={handleChange("EndFundSrcCdDesc")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 10 }}
                />
              </div>
              <div className="mui-custom-form input-md">
                <TextField
                  id="begMbrBPID"
                  select
                  label="Begin Benefit Plan ID"
                  value={CMS64State.begMbrBPID}
                  onChange={handleChange("begMbrBPID")}
                  placeholder=""
                  InputLabelProps={{
                    shrink: true
                  }}
                  inputProps={{ maxLength: 10 }}
                >
                  <MenuItem
                    selected
                    key="Please Select One"
                    value="Please Select One"
                  >
                    Please Select One
                  </MenuItem>
                  {benfitPlanData ? benfitPlanData.map(option => (
                    <MenuItem key={option.code} value={option.code}>
                      {option.description}
                    </MenuItem>
                  )) : null}
                </TextField>
              </div>
              <div className="form-wrapper horizontal-wrapper">

                <div className="mui-custom-form input-md">
                  <TextField
                    id="endMbrBPID"
                    select
                    label="End Benefit Plan ID"
                    value={CMS64State.endMbrBPID}
                    onChange={handleChange("endMbrBPID")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 10 }}
                  /*  helperText={showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null}
                error={showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null} */
                  >
                    <MenuItem
                      selected
                      key="Please Select One"
                      value="Please Select One"
                    >
                      Please Select One
                </MenuItem>
                    {benfitPlanData ? benfitPlanData.map(option => (
                      <MenuItem key={option.code} value={option.code}>
                        {option.description}
                      </MenuItem>
                    )) : null}
                  </TextField>
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="begMbrCOECd"
                    label="Begin Category of Eligibility Code"
                    value={CMS64State.begMbrCOECd}
                    onChange={handleChange("begMbrCOECd")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 10 }}
                  />
                </div>
                <div className="mui-custom-form input-md">
                  <TextField
                    id="endMbrCOECd"
                    label="End Category of Eligibility Code"
                    value={CMS64State.endMbrCOECd}
                    onChange={handleChange("endMbrCOECd")}
                    placeholder=""
                    InputLabelProps={{
                      shrink: true
                    }}
                    inputProps={{ maxLength: 10 }}
                  /*  helperText={showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null}
                error={showError.tosLineError ? showErrorMessage.tosLineErrorMsg : null} */
                  />
                </div>
                <div className="mui-custom-form override-width" style={{ marginTop: "10px" }}>
                  <label className="MuiFormLabel-root small-label" for="Exclude">
                    Family Planning Indicator
                  </label>
                  <div className="sub-radio">
                    <Checkbox
                      color="primary"
                      checked={CMS64State.famPlanInd}
                      value={CMS64State.famPlanInd}
                      onChange={handleChange("famPlanInd")}
                    />
                  </div>
                </div>
              </div>
              <div className="form-wrapper form-3-column">

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="mui-custom-form with-date">
                    <KeyboardDatePicker
                      maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                      id="begDt"
                      label="Begin Date"
                      format="MM/dd/yyyy"
                      placeholder="mm/dd/yyyy"
                      minDate={"01/01/1964"}
                      value={selectedBeginDate}
                      onChange={handleBeginDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      helperText={showError.beginDate ? showErrorMessage.beginDate : null}
                      error={showError.beginDate ? showErrorMessage.beginDate : null}
                    />
                  </div>
                  <div className="mui-custom-form with-date">
                    <KeyboardDatePicker
                      maxDate={Date.parse("31 Dec 9999 00:00:00 GMT")}
                      id="endDt"
                      label="End Date"
                      format="MM/dd/yyyy"
                      placeholder="mm/dd/yyyy"
                      minDate={"01/01/1964"}
                      value={selectedEndDate}
                      onChange={handleEndDate}
                      InputLabelProps={{
                        shrink: true
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date"
                      }}
                      helperText={showError.endDate ? showErrorMessage.endDate : null}
                      error={showError.endDate ? showErrorMessage.endDate : null}
                    />
                  </div>
                </MuiPickersUtilsProvider>
              </div>
            </div>
            <div className="form-wrapper wrap-form-label">
              <div className="mui-custom-form">
                <label className="MuiFormLabel-root small-label no-margin" for="w3review">
                  Comments
                </label>
                <textarea
                  id="w3review"
                  name="w3review"
                  rows="4"
                  cols="100"
                  maxLength='1000'
                  value={CMS64State.comments}
                  onChange={handleChange("comments")}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default withRouter(EMARAdd);
