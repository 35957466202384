/**

* © [2020] Conduent, Inc. All rights reserved. Conduent®and Conduent Agile

* Star®are trademarks of Conduent, Inc. and/or its subsidiaries in the United

* States and/or other countries.

*/


import React from 'react';
import { withRouter } from 'react-router-dom';
import { getDateInMMDDYYYYFormat } from '../../../../SharedModules/DateUtilities/DateUtilities';
import TableComponent from '../../../../SharedModules/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { paymentCriteriaRowAction } from '../../Store/Actions/PaymentCriteriaAction';

const headCellsSingle = [
    {
        id: 'paymentReqSk',
        disablePadding: false,
        label: 'Criteria ID',
        enableHyperLink: true,
        isDate: false,
        width: '20%',
        fontSize: 14
      },
      {
        id: 'beginDate',
        disablePadding: false,
        label: 'Criteria Begin Date',
        enableHyperLink: false,
        isDate: false,
        width: '20%',
        fontSize: 14
      },
      {
        id: 'endDate',
        disablePadding: false,
        label: 'Criteria End Date',
        enableHyperLink: false,
        isDate: false,
        width: '20%',
        fontSize: 14
      },
      {
        id: 'hasProcessedInd',
        disablePadding: false,
        label: 'Has been processed ',
        enableHyperLink: false,
        isDate: false,
        width: '40%',
        fontSize: 14
      }
];

const headCellsMultiRecord =[
    {
        id: 'criteriaId',
        disablePadding: false,
        label: 'Criteria ID',
        enableHyperLink: true,
        isDate: false,
        width: '20%',
        fontSize: 14
      },
      {
        id: 'beginDate',
        disablePadding: false,
        label: 'Criteria Begin Date',
        enableHyperLink: false,
        isDate: false,
        width: '20%',
        fontSize: 14
      },
      {
        id: 'endDate',
        disablePadding: false,
        label: 'Criteria End Date',
        enableHyperLink: false,
        isDate: false,
        width: '20%',
        fontSize: 14
      },
      {
        id: 'paymentProcessInd',
        disablePadding: false,
        label: 'Has been processed ',
        enableHyperLink: false,
        isDate: false,
        width: '40%',
        fontSize: 14
      }
];

function PaymentCriteriaSearchTable(props){
    const headCells = props.tableData?.length === 1 ? headCellsSingle : headCellsMultiRecord;
    const [redirect, setRedirect] = React.useState(0);
    const [spinnerLoader, setSpinnerLoader] = React.useState(false);
    const dispatch = useDispatch();
    const onRowClick = (value) => dispatch(paymentCriteriaRowAction(value))
  const paymentCriteriaRowData = useSelector(state => state.paymentCriteriaState?.rowSearchResults);

    React.useEffect(() => {
      if (redirect === 1) {
        if (paymentCriteriaRowData && paymentCriteriaRowData.respcode === '01'
         && (paymentCriteriaRowData !== null || paymentCriteriaRowData !==undefined)
        ) {
          props.history.push({
            pathname: '/PaymentCriteriaEdit',
            state: { paymentCriteriaRowData: paymentCriteriaRowData, 
              editFlag: '1', 
              values: props.values }
          });
        } else if (paymentCriteriaRowData && paymentCriteriaRowData.length === 0) {
          setSpinnerLoader(false)
        }
      }
    }, [paymentCriteriaRowData]);
    
  const editRow = row => event => {
        setSpinnerLoader(true);
        onRowClick(row.criteriaId || row.paymentReqSk);
        const valuetoredirect = redirect + 1;
        setRedirect(valuetoredirect);
    }
    const getTableData = d => {
        if (d && d.length) {
          let tData = JSON.stringify(d);
          tData = JSON.parse(tData);
          tData.map((each, index) => {
            if (each !== null) {
              each.index = index;
               each.beginDate = getDateInMMDDYYYYFormat(each.beginDate);
               each.endDate = getDateInMMDDYYYYFormat(each.endDate);
            }
            return each;
          });
          return tData;
        }
        return [];
      };
    return(
        <div className="table-no-wrap">
            <TableComponent
            print={props.print}
            headCells={headCells}
            tableData={getTableData(props.tableData ? props.tableData : [])}
            isSearch={true}
            onTableRowClick={editRow}
            spinnerLoader={spinnerLoader}
            defaultSortColumnDesc={true}
            defaultSortColumn={"criteriaId"}
           />

        </div>
    )
}

export default withRouter(PaymentCriteriaSearchTable)
